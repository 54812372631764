export const BANK_INFO_FIELDS = [
	{
		accessor: 'bank_name',
		label: 'common.fields.bank_name',
	},
	{
		accessor: 'iban',
		label: 'common.fields.iban',
	},
	{
		accessor: 'card_holder_name',
		label: 'common.fields.card_holder',
	},
];
