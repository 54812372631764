import { BOOKING_COMPENSATION_STATES, convertFormDates } from 'helpers';

export const initialValues = {
	urgent_assistance_status: 'all',
	compensation_status: BOOKING_COMPENSATION_STATES.APPROVED,
	company_uuid: ['all'],
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
	compensation_created_at_date: null,
	compensation_created_at_date_to: null,
};

const convertComapnyValues = (companies) => companies.join(' ');

export const formatValues = (values) => ({
	...values,
	company_uuid: convertComapnyValues(values.company_uuid),
	urgent_assistance_status: values.urgent_assistance_status,
	compensation_status: values.compensation_status,
	...convertFormDates(values, [
		'pick_date',
		'drop_date',
		'compensation_created_at_date',
	]),
});
