import services from 'services/services';

// Import store
import { fetchBusinessManagerProfileUrl } from 'store/paths';

export const getCompanyProfile = async (cancelToken) => {
	const { data } = await services.get(
		fetchBusinessManagerProfileUrl,
		cancelToken
	);
	return data.data;
};

export const getCompanyUuid = async (cancelToken) => {
	const {
		belonging: {
			company: { uuid },
		},
	} = await getCompanyProfile(cancelToken);

	return uuid;
};

export const getHolidays = async ({ uuid, cancelToken }) => {
	const companyUuid = uuid || (await getCompanyUuid(cancelToken));
	const url = `/dashboard/company/public-holidays/list?company_uuids[0]=${companyUuid}`;
	const { data } = await services.get(url, cancelToken);

	const convertedResponse =
		data &&
		Object.entries(data).map(([name, values]) => ({
			...values,
			name,
		}));

	return convertedResponse;
};

export const refreshHolidays = async (
	type,
	companyUuid,
	dispatch,
	cancelToken
) => {
	const payload = await getHolidays({ uuid: companyUuid, cancelToken });

	dispatch({
		type,
		payload,
	});
};
