import React from 'react';
import PropTypes from 'prop-types';
import {
	TableContainer,
	TableCell,
	TableBody,
	TableHead,
	TableRow,
	Table,
	Paper,
	Typography,
} from '@material-ui/core';

// Import helpers utilities
import { useTranslations } from 'components/utilities';
import { columns } from './columns';

// Import components
import { TableBodyCell } from './components';

// Import styles
import { useStyles } from './HistoryTable.styles';

export const HistoryTable = ({ rows }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<TableContainer component={Paper} className={classes.table}>
			<Table>
				<TableHead className={classes.tabledHead}>
					<TableRow>
						{columns.map(({ accessor, header }) => (
							<TableCell key={accessor}>
								<Typography variant="h5" noWrap className={classes.header}>
									{header ? t(header) : ''}
								</Typography>
							</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					{rows.map((row) => (
						<TableRow key={row.id}>
							{columns.map((column) => (
								<TableBodyCell
									key={column.accessor}
									row={row}
									column={column}
								/>
							))}
						</TableRow>
					))}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

HistoryTable.propTypes = {
	rows: PropTypes.array,
};
