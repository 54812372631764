import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	green: {
		color: theme.palette.primary.main,
		fontWeight: 600,
	},
	red: {
		color: theme.palette.error.medium,
		fontWeight: 600,
	},
	carIcon: {
		height: '16px',
		width: '16px',
	},
	iconWrapper: {
		display: 'flex',
		alignItems: 'center',
	},
	carLogHistoryIcon: {
		color: theme.palette.primary.main,
		height: '20px',
		width: '20px',
	},
}));
