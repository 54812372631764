import React from 'react';
import { Box, FormControl, Select, MenuItem } from '@material-ui/core';

//Import helpers
import { useCarFilters } from './useCarFilters';

// import styles
import { useStyles } from './CarFilters.styles';

export const CarFilters = () => {
	const { FILTERS } = useCarFilters();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			{FILTERS.map(
				({
					label,
					onChange,
					options,
					disabled,
					valueExtractor,
					labelExtractor,
					value,
				}) => (
					<FormControl key={label} className={classes.input} variant="outlined">
						<Select
							onChange={onChange}
							defaultValue=""
							displayEmpty
							disabled={disabled}
							value={value || ''}
						>
							<MenuItem value="" disabled>
								{label}
							</MenuItem>
							{options.map((option) => (
								<MenuItem
									value={valueExtractor(option)}
									key={valueExtractor(option)}
								>
									{labelExtractor(option)}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				)
			)}
		</Box>
	);
};
