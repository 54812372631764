import React from 'react';
import { Button } from '@material-ui/core';

// Import components
import { Grid } from 'components/elements';

// Import utils and context
import { useTranslations } from 'components/utilities';
import { useQuickFinderProvider } from 'views/Company/CompaniesList/context';

// Import assets
import { AddCarIcon } from 'assets/icons';

export const DialogActions = () => {
	const { t } = useTranslations();

	const {
		selectedGoal,
		goalComponent,
		handleAddNewCar,
		handleCloseDialog,
		isAddButtonVisible,
		isEditButtonVisible,
	} = useQuickFinderProvider();

	if (!selectedGoal) return null;

	const { isButtonDisabled, actionButtonText, action } = goalComponent || {};

	return (
		<Grid container justifyContent="space-between">
			<Grid item>
				{isAddButtonVisible && (
					<Button
						color="primary"
						startIcon={<AddCarIcon />}
						onClick={handleAddNewCar}
					>
						{t('common.buttons.add_new_car')}
					</Button>
				)}
			</Grid>
			{isEditButtonVisible && (
				<Grid item>
					<Grid container spacing={2} alignItems="center">
						<Grid item>
							<Button onClick={handleCloseDialog} size="large">
								{t('common.buttons.cancel')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								variant="contained"
								color="primary"
								size="large"
								disabled={isButtonDisabled}
								onClick={action}
							>
								{t(actionButtonText)}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			)}
		</Grid>
	);
};
