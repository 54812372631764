import i18next from 'i18next';
import { makeStyles } from '@material-ui/styles';

// Import helpers
import { AR } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
	headerWrapper: {
		display: 'flex',
		alignItems: 'flex-start',
		flexDirection: 'column',
		gap: '8px',
	},
	tableHeader: {
		color: theme.palette.text[2],
		letterSpacing: i18next.language === AR ? 0 : 1.3,
		fontSize: '12px',
		fontWeight: 600,
		textTransform: 'capitalize',
		width: '100%',
	},
	arrow: {
		color: theme.palette.text[2],
	},
}));
