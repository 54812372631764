import { bool, number, oneOfType, string } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';

// Import queries
import { useManufacturers } from 'queries';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const MultiManufacturerFilter = ({
	label = '',
	name,
	xs = 3,
	md = 3,
	disabled = false,
	required = false,
	smallFieldStyle = false,
	showEmptyLabel = false,
}) => {
	const { values } = useFormState();

	const { renderFields } = useCommonFields();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data: manufacturers } = useManufacturers();

	if (!manufacturers) {
		return null;
	}

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[language];

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name,
		label,
		options: manufacturers,
		showAllOption: true,
		multiSelectLabelExtractor,
		currentMultiSelectValues: values?.[name],
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[language],
		required,
		md,
		xs,
		smallFieldStyle,
		emptyLabel: showEmptyLabel ? t('common.fields.manufacturer') : '',
		searchExtractor: 'children[1]',
		disabled,
	});
};

MultiManufacturerFilter.propTypes = {
	name: string.isRequired,
	label: string,
	showFetching: bool,
	disabled: bool,
	required: bool,
	md: oneOfType([string, number]),
	xs: oneOfType([string, number]),
	smallFieldStyle: oneOfType([bool, string]),
	showEmptyLabel: bool,
};
