import React from 'react';
import { node, object, string } from 'prop-types';
import { TableCell as MuiTableCell } from '@material-ui/core';

/**
 * @typedef {object} TableCellProps
 *
 * @property {React.ReactNode} children
 * @property {string} className
 * @property {object} style
 * @property {object} cell
 * @returns {JSX.Element}
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLTableCellElement, TableCellProps>}
 */

export const TableCell = ({ cell, children, className, style }) => (
	<MuiTableCell
		className={className || ''}
		style={style}
		{...cell.getCellProps()}
	>
		{children}
	</MuiTableCell>
);

TableCell.propTypes = {
	children: node.isRequired,
	className: string,
	style: object,
	cell: object,
};
