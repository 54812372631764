// Import helpers
import { CHIP_COLORS } from 'helpers';

export const CAR_TYPES = {
	TAKEN_ACCEPTED: 'taken_accepted',
	AVAILABLE: 'available',
	TAKEN: 'taken',
};

export const activeList = [
	{
		label: 'common.filters.active',
		color: CHIP_COLORS.SUCCESS,
		status: 'active',
	},
	{
		label: 'common.filters.inactive',
		color: CHIP_COLORS.ERROR,
		status: 'inactive',
	},
];
