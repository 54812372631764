// Import helpers
import { FIELD_TYPES } from 'helpers';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';

export const FIELDS = [
	{
		name: 'city',
		inputLabel: `${transPrefix}.city`,
		type: FIELD_TYPES.TEXT,
		disabled: true,
		md: 4,
	},
	{
		name: 'company',
		inputLabel: `${transPrefix}.company`,
		type: FIELD_TYPES.TEXT,
		disabled: true,
		md: 4,
	},
	{
		name: 'office',
		inputLabel: `${transPrefix}.office`,
		type: FIELD_TYPES.TEXT,
		disabled: true,
		md: 4,
	},
];
