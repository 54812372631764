import React from 'react';

// Import store
import { fetchCarsAvailability } from 'store/actions';

// Import helpers
import { COLUMNS_ACCESSORS, companyColumns } from './columns';

// Import components
import { TableBodyRow, Filters, Export } from './components';

// Import utils
import { useCarsAvailability } from './useCarsAvailability';
import { TableRPC } from 'components/utilities';

// Import styles
import { useStyles } from './CarsAvailability.styles';

export const CarsAvailability = () => {
	const { userCompanyUuid } = useCarsAvailability();

	const classes = useStyles();

	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={companyColumns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={(options) =>
				fetchCarsAvailability({ ...options, userCompanyUuid })
			}
			title="nav.operations.cars_availability"
			filtersBar={<Filters />}
			searchBarPlaceholder="common.fields.car_name"
			containerStyles={classes.root}
			outerToolbarElements={<Export />}
		/>
	);
};
