// Import helpers
import { getCampaignType } from './helpers';
import { ACTION_MENU_POPUP_FIELD_TYPES, replaceEditCampaignUrl } from 'helpers';

// Import assets
import { BaseEditPenIcon } from 'assets/icons';

// Import store
import { updateActiveCampaign } from 'store/actions';

const { ACTIVE, REDIRECT } = ACTION_MENU_POPUP_FIELD_TYPES;

export const useCell = ({ cell }) => {
	const {
		campaign_type: compaignType,
		uuid: campaignUuid,
		active: isActive,
	} = cell.row.original;

	const handleOnActive = () =>
		updateActiveCampaign({ isActive, id: campaignUuid });

	const allowedCampaignType = getCampaignType(compaignType);
	const editUrl = replaceEditCampaignUrl(campaignUuid);

	const actionMenuFields = [
		{
			type: ACTIVE,
			activeAction: handleOnActive,
			activeState: isActive,
		},
	];

	if (allowedCampaignType) {
		actionMenuFields.push({
			type: REDIRECT,
			redirectText: 'common.buttons.edit',
			redirectUrl: editUrl,
			redirectIcon: BaseEditPenIcon,
		});
	}

	return {
		actionMenuFields,
	};
};
