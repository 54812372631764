import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import utils
import { useTranslations } from 'components/utilities';
import { useOldAndNewCar } from './useOldAndNewCar';

// Import styles
import { useStyles } from './OldAndNewCar.styles';

export const OldAndNewCar = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		oldCarName,
		oldCompanyAndOffice,
		newFullCarName,
		newCompanyAndOffice,
		differencePrice,
		isPriceDecreased,
		isCarChanged,
	} = useOldAndNewCar();

	return (
		<Grid container spacing={2} direction="column">
			<Grid item>
				<Typography variant="body1" className={classes.title}>
					{`${t(
						`bookings.edit_booking.${isCarChanged ? 'old_car' : 'current_car'}`
					)}:`}
				</Typography>
				<Typography variant="body1" className={classes.content}>
					{oldCarName}
				</Typography>
				<Typography variant="body1" className={classes.content}>
					{oldCompanyAndOffice}
				</Typography>
			</Grid>
			{isCarChanged && (
				<Grid item>
					<Typography variant="body1" className={classes.title}>
						{`${t('bookings.edit_booking.new_car')}:`}
					</Typography>
					<Typography variant="body1" className={classes.content}>
						{newFullCarName}
					</Typography>
					<Typography variant="body1" className={classes.content}>
						{newCompanyAndOffice}
					</Typography>
				</Grid>
			)}
			<Grid item>
				<Typography variant="body1" className={classes.title}>
					{`${t('common.fields.difference')}:`}
				</Typography>
				<Typography
					variant="body1"
					className={clsx({
						[classes.differencePrice]: true,
						[classes.differencePriceDecreased]: isPriceDecreased,
					})}
				>
					{differencePrice}
				</Typography>
				<Typography variant="body1" className={classes.monitorLimit}>
					{t('bookings.edit_booking.monitor_your_limit_message')}
				</Typography>
			</Grid>
		</Grid>
	);
};
