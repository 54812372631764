export const getDialogTitle = ({ isSuccess, isReset, transKey }) => {
	if (isSuccess)
		return isReset
			? `${transKey}.rating_reset_successfully`
			: `${transKey}.customer_rating_submitted`;

	return isReset
		? `${transKey}.reset_rating_confirmation`
		: `${transKey}.confirm_customer_rating`;
};

export const getDialogSubtitle = ({ isSuccess, isReset, transKey }) => {
	if (isSuccess)
		return isReset
			? `${transKey}.rating_reset_successfully_subtitle`
			: `${transKey}.customer_rating_submitted_subtitle`;

	return isReset
		? `${transKey}.reset_rating_confirmation_subtitle`
		: `${transKey}.confirm_customer_rating_subtitle`;
};
