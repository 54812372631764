import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import utils
import { useAvailabilityTableCellProvider } from '../../../../context';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CarInfo.styles';

// Import components
import { Grid } from 'components/elements';

export const CarInfo = () => {
	const { carData } = useAvailabilityTableCellProvider();
	const { t } = useTranslations();
	const classes = useStyles();

	const { image, name, modelAndYear, availability } = carData;

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			className={classes.container}
		>
			<Grid item>
				<Grid container alignItems="center" spacing={2}>
					<Grid item>
						<Box className={classes.imageWrapper}>
							<img src={image} className={classes.image} alt={name} />
						</Box>
					</Grid>
					<Grid item>
						<Typography className={classes.carName}>{name}</Typography>
						<Typography className={classes.carModel}>{modelAndYear}</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<Box className={classes.availabilityBox}>
					<Typography className={classes.availability}>
						{availability}
					</Typography>
					<Typography className={classes.totalAvailability}>
						{t('common.fields.total_available')}
					</Typography>
				</Box>
			</Grid>
		</Grid>
	);
};
