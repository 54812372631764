import { useEffect } from 'react';
import { useQuery } from 'react-query';
import usePrevious from '@rooks/use-previous';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useCarModels = ({ manufacturerUuid }) => {
	const prevManufacturerUuid = usePrevious(manufacturerUuid);

	const body = {
		vehicle_maker: {
			uuid: manufacturerUuid,
		},
	};

	const { data, isFetching, refetch } = useQuery(
		QUERY_KEYS.CARS.SUPPLIER_MODELS(manufacturerUuid),
		async () => {
			return (await services.post(ENDPOINTS.CARS.MODELS, body)).data.data;
		},
		{ refetchOnWindowFocus: false, enabled: !!manufacturerUuid }
	);

	const fetchedData = data ? data : [];

	useEffect(() => {
		if (manufacturerUuid !== prevManufacturerUuid) {
			if (manufacturerUuid) {
				refetch();
			}
		}
	}, [manufacturerUuid, prevManufacturerUuid, refetch]);

	return { data: fetchedData, isFetching };
};

export default useCarModels;
