import { useDispatch } from 'react-redux';

// Import utils
import { useTableContextProvider } from 'components/context';
import { useToggle } from 'components/utilities';

// Import store
import { resetBookingPreviewItem } from 'store/actions';

export const useNewActionsMenuTableCell = ({
	extraActions,
	bookingUuid,
	props,
	cell,
}) => {
	const { fetchData } = useTableContextProvider();
	const dispatch = useDispatch();

	const booking = cell.row.original;
	const { uuid, urgentAssistance } = booking || {};
	const rowUuid = bookingUuid || uuid;

	const { on: isBookingHistoryOpen, toggle: toggleBookingHistory } =
		useToggle();
	const { on: isTransactionsHistoryOpen, toggle: toggleTransactionsHistory } =
		useToggle();
	const { on: isSubscriptionHistoryOpen, toggle: toggleSubscriptionsHistory } =
		useToggle();
	const { on: isCompensationDialogOpen, toggle: toggleCompensationDialog } =
		useToggle();
	const { on: isEditPriceDialogOpen, toggle: toggleEditPriceDialog } =
		useToggle();
	const { on: isAssignOfficesDialogOpen, toggle: toggleAssignOfficesDialog } =
		useToggle();
	const { on: isCarsLogHistoryDialogOpen, toggle: toggleCarsLogHistoryDialog } =
		useToggle();
	const {
		on: isCarAvailabilityDialogOpen,
		toggle: toggleCarAvailabilityDialog,
	} = useToggle();

	const { on: isCloseContractDialogOpen, toggle: toggleCloseContractDialog } =
		useToggle();

	const { on: isExtendBookingDialogOpen, toggle: toggleExtendBookingDialog } =
		useToggle();

	const handleOnCompensationClose = () => {
		resetBookingPreviewItem({ dispatch });
		fetchData();
	};

	const previewPopupProps = {
		...props,
		rowUuid,
		toggleBookingHistory,
		toggleEditPriceDialog,
		toggleTransactionsHistory,
		toggleSubscriptionsHistory,
		toggleAssignOfficesDialog,
		toggleCarsLogHistoryDialog,
		toggleCarAvailabilityDialog,
		toggleCloseContractDialog,
		toggleExtendBookingDialog,
	};

	const extraActionsProps = {
		toggleSubscriptionsHistory,
		toggleCompensationDialog,
		toggleBookingHistory,
		extraActions,
		isHovered: props.isHovered,
	};

	return {
		handleOnCompensationClose,
		toggleCarAvailabilityDialog,
		toggleCarsLogHistoryDialog,
		toggleSubscriptionsHistory,
		toggleTransactionsHistory,
		toggleCompensationDialog,
		toggleAssignOfficesDialog,
		toggleBookingHistory,
		toggleEditPriceDialog,
		toggleCloseContractDialog,
		toggleExtendBookingDialog,
		isExtendBookingDialogOpen,
		isCarAvailabilityDialogOpen,
		isCarsLogHistoryDialogOpen,
		isAssignOfficesDialogOpen,
		isSubscriptionHistoryOpen,
		isTransactionsHistoryOpen,
		isCompensationDialogOpen,
		isEditPriceDialogOpen,
		isBookingHistoryOpen,
		isCloseContractDialogOpen,
		previewPopupProps,
		extraActionsProps,
		urgentAssistance,
		fetchData,
		rowUuid,
		booking,
		uuid,
	};
};
