import React from 'react';

// Import assets
import {
	NavigateFirstIcon,
	NavigateLastIcon,
	NavigateNextIcon,
	NavigatePrevIcon,
} from 'icons';

// Import helpers
import { PAGINATION_BUTTON_TYPES } from 'helpers';

const { FIRST, LAST, NEXT, PREV } = PAGINATION_BUTTON_TYPES;

export const renderIcon = (type) => {
	switch (type) {
		case FIRST:
			return <NavigateFirstIcon />;
		case PREV:
			return <NavigatePrevIcon />;
		case NEXT:
			return <NavigateNextIcon />;
		case LAST:
			return <NavigateLastIcon />;
		default:
			return;
	}
};
