import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	dialogHeader: {
		fontSize: '26px',
		marginBottom: '10px',
		fontWeight: '600',
	},
	strong: {
		margin: theme.spacing(1),
	},
	icon: {
		width: 15,
		height: 15,
		display: 'flex',
		alignItems: 'center',
		marginRight: theme.spacing(0.5),
	},
	checkboxWrapper: ({ isChecked }) => ({
		backgroundColor: isChecked ? theme.palette.success.light : 'transparent',
		width: '100%',
	}),
	formControl: {
		width: '100%',
	},
	formControlLabel: {
		width: '100%',
		padding: '4px 16px',
	},
	dialogActions: {
		padding: '20px',
	},
}));
