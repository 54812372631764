export const initialValues = {
	active: 'all',
	company_uuid: ['all'],
};

export const formatValues = (values) => ({
	...values,
	active: values.active === 'all' ? null : values.active,
	uuid:
		values.company_uuid?.[0] === 'all'
			? null
			: `${values.company_uuid.join(',')}`,
});
