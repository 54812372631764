// Import helpers
import { CAMPAIGN_TYPES, FIELD_TYPES } from 'helpers';

const radioGroupOptions = [
	{
		value: CAMPAIGN_TYPES.BOOKING_CASHBACK,
		label: 'campaigns.types.booking_cashback',
		disabled: true,
	},
	{
		value: CAMPAIGN_TYPES.PARTNERSHIP,
		label: 'campaigns.types.partnership',
		disabled: true,
	},
	{
		value: CAMPAIGN_TYPES.CUSTOMER_REGISTERED,
		label: 'campaigns.types.customer_registered',
	},
	{
		value: CAMPAIGN_TYPES.CUSTOMER_EXPERIENCE_SHARED,
		label: 'campaigns.types.customer_experience_shared',
	},
];

export const RADIO_FIELDS = [
	{
		type: FIELD_TYPES.RADIO_GROUP,
		name: 'campaign_type',
		md: 12,
		xs: 12,
		options: radioGroupOptions,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => label,
	},
];
