import React from 'react';
import { bool, func } from 'prop-types';
import { Button, Grid } from '@material-ui/core';

// Import components
import { ButtonProgress, Alert } from 'components/elements';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import helpers
import { CLEAR_DIALOG_FIELDS } from './helpers';

export const FormContent = ({ toggle, isLoading }) => {
	const { t } = useTranslations();

	const { reasons, isDescriptionInputVisible, isError } = useFormContent();

	const { renderFields } = useCommonFields();

	if (isError) {
		return <Alert title={t('common.messages.error_message')} />;
	}

	return (
		<Grid container direction="column" spacing={1}>
			<Grid container item spacing={1}>
				{CLEAR_DIALOG_FIELDS({ isDescriptionInputVisible, reasons }).map(
					renderFields
				)}
			</Grid>
			<Grid container item justifyContent="flex-end" spacing={1}>
				<Grid item>
					<Button variant="outlined" color="primary" onClick={toggle}>
						{t('common.buttons.close')}
					</Button>
				</Grid>
				<Grid item>
					<ButtonProgress
						circularProgressSize={24}
						isLoading={isLoading}
						disabled={isLoading}
						variant="contained"
						color="primary"
						type="submit"
					>
						{t('common.buttons.clear')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func,
	isLoading: bool,
};
