import { useSelector } from 'react-redux';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { getCompensationTypeLabel, getCompensationStatusLabel } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useCompensationsRecords = () => {
	const { t } = useTranslations();
	const booking = useSelector(previewBookingSelector);
	const { compensations = [] } = booking || {};

	const formattedCompensations = compensations?.reduce((acc, curr) => {
		const { compensation_amount: amount, method, type, status } = curr || {};

		const newCompensation = {
			...curr,
			amountWithMethod: `${amount?.nett} / ${t(
				`bookings.compensation_methods.${method}`
			)}`,
			type: getCompensationTypeLabel({ type }),
			status: getCompensationStatusLabel({ status, type }),
		};

		return [...acc, newCompensation];
	}, []);

	return { formattedCompensations };
};
