import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PaperPin = (props) => (
	<SvgIcon width="20" height="22" viewBox="0 0 20 22" fill="none" {...props}>
		<path
			d="M13.8752 8.87625L8.38919 14.3623C7.56319 15.1883 7.56319 16.5282 8.38919 17.3542V17.3542C9.21519 18.1802 10.5552 18.1802 11.3812 17.3542L18.6132 10.1223C20.1282 8.60725 20.1282 6.15125 18.6132 4.63625V4.63625C17.0982 3.12125 14.6422 3.12125 13.1272 4.63625L5.89519 11.8682C3.69119 14.0722 3.69119 17.6442 5.89519 19.8482V19.8482C8.09919 22.0522 11.6712 22.0522 13.8752 19.8482L18.2642 15.4593"
			stroke="#555F6A"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
