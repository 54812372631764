import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useDeliveryLocationQuery = ({ deliveryUuid }) => {
	const path = `/v2/delivery/${deliveryUuid}/location`;

	const { data, isFetching, isError, refetch, isFetched } = useQuery(
		QUERY_KEYS.DELIVERY.LOCATION(deliveryUuid),
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !!deliveryUuid,
		}
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching, isError, isFetched, refetch };
};
