import React from 'react';
import { useFormState } from 'react-final-form';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { POINTS_FIELDS } from './helpers';

// Import components
import { Typography, Grid } from 'components/elements';

// Import styles
import { useStyles } from './styles';

export const GratificationPointsField = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const {
		values: {
			gratification: { point },
		},
	} = useFormState();
	const { renderFields } = useCommonFields();

	return (
		<>
			{POINTS_FIELDS({ t }).map(renderFields)}

			{!!point && (
				<Grid item xs={4} className={classes.pointsContainer}>
					<Typography component="h5" fontWeight={600} color="primary">
						= {+point / 10} {t('currency.sar')}
					</Typography>
				</Grid>
			)}
		</>
	);
};
