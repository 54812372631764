import { debounce } from 'lodash';

// Import helpers
import { getCustomerDetails } from 'store/actions';

export const debouncedGetCustomer = debounce(({ phone, change, message }) => {
	if (phone) {
		getCustomerDetails({
			phoneNumber: '+' + phone,
			successCallback: (resp) => {
				const birthDate = resp.data.birth_date
					? new Date(resp.data.birth_date)
					: '';
				const expDate = resp.data.license_expire_date
					? new Date(resp.data.license_expire_date)
					: '';
				change('customerDetails.isNew', false);
				change('customerDetails.license_number', resp.data.license_number);
				change('customerDetails.full_name', resp.data.full_name);
				change('customerDetails.birth_date', birthDate);
				change('customerDetails.license_expire_date', expDate);
				change('booking_owner_uuid', resp.data.user_uuid);
				change('licenseFileLink', resp.data.license_photo);
			},
			errorCallback: (err) => {
				if (err.response.status === 404) {
					change('customerDetails.isNew', true);
					change('customerDetails.license_number', '');
					change('customerDetails.full_name', '');
					change('customerDetails.birth_date', '');
					change('customerDetails.license_expire_date', '');
				} else {
					message.error(err.response.data.message);
				}
			},
		});
	}
}, 2000);
