import { useFormState } from 'react-final-form';
import { bool, number, oneOfType, string } from 'prop-types';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { multiSelectLabelExtractor } from './helpers';
import { useAllCities } from 'queries';
import { FIELD_TYPES } from 'helpers';

export const CitiesFilter = ({
	label = '',
	name,
	emptyLabel = 'common.filters.city',
	smallFieldStyle = false,
	xs,
	md,
	showEmptyLabel = false,
}) => {
	const { data: cities } = useAllCities();
	const { renderFields } = useCommonFields();
	const { values } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	if (!cities) {
		return null;
	}

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		currentMultiSelectValues: values?.[name],
		labelExtractor: ({ names }) => names[language],
		keyExtractor: ({ uuid }) => uuid,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor,
		showAllOption: true,
		options: cities,
		smallFieldStyle,
		emptyLabel: showEmptyLabel ? t(emptyLabel) : '',
		label,
		name,
		xs,
		md,
	});
};

CitiesFilter.propTypes = {
	label: string,
	name: string.isRequired,
	smallFieldStyle: oneOfType([bool, string]),
	emptyLabel: string,
	md: oneOfType([string, number]),
	xs: oneOfType([string, number]),
	showEmptyLabel: bool,
};
