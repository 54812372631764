import React from 'react';
import { bool, object } from 'prop-types';
import { Box, Divider } from '@material-ui/core';

// Import component
import {
	CarAndCompanyInfo,
	CarPriceSection,
	ServicesSection,
} from './components';

// Import styles
import { useStyles } from './CarCard.styles';

// Import helpers
import { useCarCard } from './useCarCard';

export const CarCard = ({ car, preview = false }) => {
	const { handleCardClick, isSelected } = useCarCard({ car, preview });
	const classes = useStyles({ isSelected, preview })();

	return (
		<Box className={classes.container} onClick={handleCardClick}>
			<Box className={classes.border}>
				<CarAndCompanyInfo car={car} />

				<CarPriceSection car={car} />

				<Divider className={classes.divider} />

				<ServicesSection car={car} />
			</Box>
		</Box>
	);
};

CarCard.propTypes = {
	car: object.isRequired,
	preview: bool,
};
