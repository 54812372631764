import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCorporation = (corporateUuid) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.B2B.CORPORATION(corporateUuid),
		async () => await services.get(ENDPOINTS.B2B.CORPORATE({ corporateUuid })),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError };
};
