import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const LargeSearchBook = (props) => (
	<SvgIcon
		width="100"
		height="100"
		viewBox="0 0 100 100"
		fill="none"
		{...props}
	>
		<g clipPath="url(#clip0_12521_213403)">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M89.5873 -2.16287C89.5873 -0.660659 88.3695 0.55708 86.8681 0.55708C85.3659 0.55708 84.1484 -0.660659 84.1484 -2.16287C84.1484 -3.66507 85.3659 -4.88281 86.8681 -4.88281C88.3695 -4.88281 89.5873 -3.66507 89.5873 -2.16287Z"
				fill="#DCDFE3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M89.5873 -2.16287C89.5873 -0.660659 88.3695 0.55708 86.8681 0.55708C85.3659 0.55708 84.1484 -0.660659 84.1484 -2.16287C84.1484 -3.66507 85.3659 -4.88281 86.8681 -4.88281C88.3695 -4.88281 89.5873 -3.66507 89.5873 -2.16287Z"
				fill="#D5D9DE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M51.4261 67.8389H11.6707C8.87249 67.8389 6.58203 65.5417 6.58203 62.7341V6.04115C6.58203 3.23357 8.87249 0.9375 11.6707 0.9375H51.4261C54.2254 0.9375 56.5158 3.23357 56.5158 6.04115V62.7341C56.5158 65.5417 54.2254 67.8389 51.4261 67.8389Z"
				fill="#DCDFE3"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M51.4261 67.8389H11.6707C8.87249 67.8389 6.58203 65.5417 6.58203 62.7341V6.04115C6.58203 3.23357 8.87249 0.9375 11.6707 0.9375H51.4261C54.2254 0.9375 56.5158 3.23357 56.5158 6.04115V62.7341C56.5158 65.5417 54.2254 67.8389 51.4261 67.8389Z"
				fill="#D5D9DE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.7336 72.1791H4.89603C2.09207 72.1791 -0.203125 69.8914 -0.203125 67.0954V10.6372C-0.203125 7.84125 2.09207 5.55469 4.89603 5.55469H44.7336C47.5387 5.55469 49.8339 7.84125 49.8339 10.6372V67.0954C49.8339 69.8914 47.5387 72.1791 44.7336 72.1791Z"
				fill="#F3F4F5"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M44.7336 72.1791H4.89603C2.09207 72.1791 -0.203125 69.8914 -0.203125 67.0954V10.6372C-0.203125 7.84125 2.09207 5.55469 4.89603 5.55469H44.7336C47.5387 5.55469 49.8339 7.84125 49.8339 10.6372V67.0954C49.8339 69.8914 47.5387 72.1791 44.7336 72.1791Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.9882 22.7525H9.56332C8.723 22.7525 8.03516 22.0981 8.03516 21.2987C8.03516 20.4981 8.723 19.8438 9.56332 19.8438H38.9882C39.8285 19.8438 40.5164 20.4981 40.5164 21.2987C40.5164 22.0981 39.8285 22.7525 38.9882 22.7525Z"
				fill="#E8EBED"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.9882 31.4791H9.56332C8.723 31.4791 8.03516 30.8247 8.03516 30.0252C8.03516 29.2247 8.723 28.5703 9.56332 28.5703H38.9882C39.8285 28.5703 40.5164 29.2247 40.5164 30.0252C40.5164 30.8247 39.8285 31.4791 38.9882 31.4791Z"
				fill="#E8EBED"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.9882 40.2056H9.56332C8.723 40.2056 8.03516 39.5512 8.03516 38.7518C8.03516 37.9524 8.723 37.2969 9.56332 37.2969H38.9882C39.8285 37.2969 40.5164 37.9524 40.5164 38.7518C40.5164 39.5512 39.8285 40.2056 38.9882 40.2056Z"
				fill="#E8EBED"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M38.9882 48.9322H9.56332C8.723 48.9322 8.03516 48.2778 8.03516 47.4784C8.03516 46.6778 8.723 46.0234 9.56332 46.0234H38.9882C39.8285 46.0234 40.5164 46.6778 40.5164 47.4784C40.5164 48.2778 39.8285 48.9322 38.9882 48.9322Z"
				fill="#E8EBED"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M24.9226 57.6588H9.56986C8.72594 57.6588 8.03516 57.0044 8.03516 56.2049C8.03516 55.4044 8.72594 54.75 9.56986 54.75H24.9226C25.7665 54.75 26.4573 55.4044 26.4573 56.2049C26.4573 57.0044 25.7665 57.6588 24.9226 57.6588Z"
				fill="#E8EBED"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M55.9142 46.3818C50.0304 52.2646 40.9215 52.9282 34.3086 48.3863C33.4634 47.7955 32.659 47.1318 31.9089 46.3818C31.5227 45.997 31.1588 45.5998 30.8158 45.1816C30.1298 44.3465 29.529 43.4559 29.0367 42.5356C28.1694 40.9814 27.5796 39.3321 27.2576 37.6273C26.2188 32.2812 27.7721 26.5267 31.9089 22.3918C36.0556 18.2446 41.8111 16.7015 47.1582 17.7303C48.862 18.0621 50.5128 18.6517 52.0673 19.509C52.9889 20.0123 53.8674 20.613 54.7038 21.2989C55.1209 21.6406 55.5181 22.0057 55.9031 22.3918C56.6544 23.1418 57.3281 23.9449 57.9079 24.7911C62.4519 31.4016 61.7869 40.5101 55.9142 46.3818Z"
				fill="white"
				fillOpacity="0.1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M54.2719 45.2272C48.1506 51.3496 38.2306 51.3484 32.1081 45.2272C25.9937 39.1127 25.9937 29.1915 32.1161 23.0702C38.2306 16.9558 48.1506 16.9558 54.2651 23.0702C60.3864 29.1915 60.3864 39.1127 54.2719 45.2272ZM57.4126 19.9242C49.5554 12.0669 36.823 12.0669 28.9658 19.9242C21.1097 27.7802 21.1029 40.5206 28.959 48.3778C36.11 55.5265 47.3183 56.1712 55.2017 50.3016C55.9717 49.7275 56.7132 49.084 57.4206 48.3778C58.1268 47.6704 58.7704 46.9289 59.3433 46.1589C65.2129 38.2743 64.5625 27.074 57.4126 19.9242Z"
				fill="#34BFD1"
			/>
			<path
				opacity="0.3"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M54.2643 45.2271C48.143 51.3495 38.2229 51.3484 32.1005 45.2271C25.986 39.1127 25.986 29.1915 32.1084 23.0702C38.2229 16.9558 48.143 16.9558 54.2574 23.0702C60.3787 29.1915 60.3787 39.1127 54.2643 45.2271Z"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M50.4099 20.4741L29.7075 41.1755C28.9278 39.7782 28.3977 38.2954 28.1094 36.7628L45.997 18.875C47.5286 19.1744 49.0125 19.7034 50.4099 20.4741Z"
				fill="white"
				fillOpacity="0.5"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M56.7175 25.8839L34.336 46.9974C33.5345 46.4698 32.7716 45.8759 32.0601 45.2048C31.6939 44.8593 31.3476 44.5049 31.0234 44.1307L53.6776 22.7578C54.0743 23.0647 54.4511 23.3914 54.8161 23.7369C55.5275 24.4081 56.1676 25.1267 56.7175 25.8839Z"
				fill="white"
				fillOpacity="0.5"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M88.3337 77.9959L88.0038 78.3258C85.8215 80.5093 82.2479 80.5093 80.0644 78.3258L59.9141 58.1755L68.1834 49.9062L88.3337 70.0566C90.5172 72.24 90.5172 75.8125 88.3337 77.9959Z"
				fill="#15AABE"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M59.7808 45.5391L65.7354 51.4936L61.508 55.7197L55.5547 49.7652C56.3404 49.1794 57.0959 48.5227 57.8189 47.8021C58.5395 47.0814 59.1962 46.3248 59.7808 45.5391Z"
				fill="#34BFD1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M88.0318 69.8085L79.8152 78.024L78.3359 76.5436L86.5515 68.3281L88.0318 69.8085Z"
				fill="#8ADBE5"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M68.6373 50.1916L60.1956 58.6334L58.9414 57.3793L67.3831 48.9375L68.6373 50.1916Z"
				fill="#8ADBE5"
			/>
		</g>
		<defs>
			<clipPath id="clip0_12521_213403">
				<rect
					width="90"
					height="80"
					fill="white"
					transform="translate(0 0.5)"
				/>
			</clipPath>
		</defs>
	</SvgIcon>
);
