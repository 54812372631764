import React from 'react';
import { Chip } from '@material-ui/core';
import { string } from 'prop-types';
import clsx from 'clsx';

// Import components
import { PreviewField } from 'components/elements';

// Import styles
import { useStyles } from './UrgentRequestStatusField.styles';

export const UrgentRequestStatusField = ({
	chipLabel,
	label,
	labelClassName,
}) => {
	const classes = useStyles();

	const renderChip = () => (
		<Chip
			label={chipLabel}
			className={clsx(classes.chip, classes[chipLabel])}
		/>
	);

	return (
		<>
			{label ? (
				<PreviewField label={label} labelClassName={labelClassName}>
					{renderChip()}
				</PreviewField>
			) : (
				renderChip()
			)}
		</>
	);
};

UrgentRequestStatusField.propTypes = {
	chipLabel: string,
	label: string,
	labelClassName: string,
};
