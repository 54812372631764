import React from 'react';
import {
	DialogContent,
	FormControl,
	Typography,
	FormGroup,
	Grid,
	Box,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { bool, array, func, string } from 'prop-types';

// Import components
import { LoadingWrapper } from 'components/elements';
import { OfficeItem } from '../components';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './styles';

export const Content = ({
	isLoading,
	isDataExist,
	officesData,
	checkedOffices,
	setCheckedOffices,
	userName,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<DialogContent>
			<Typography variant="body1" align="left" gutterBottom>
				<Box component="span">
					{t('company_managers.modal.before_username')}
				</Box>
				<Box component="strong" className={classes.strong}>
					{userName}
				</Box>
				<Box component="span">{t('company_managers.modal.after_username')}</Box>
			</Typography>

			{isLoading ? (
				<LoadingWrapper />
			) : isDataExist ? (
				<FormControl component="fieldset" className={classes.formControl}>
					<FormGroup>
						<Grid container spacing={2}>
							{officesData.map((office) => (
								<OfficeItem
									key={office.uuid}
									office={office}
									checkedOffices={checkedOffices}
									setCheckedOffices={setCheckedOffices}
								/>
							))}
						</Grid>
					</FormGroup>
				</FormControl>
			) : (
				<Alert severity="warning">
					<strong>{t('company_managers.modal.no_data')}</strong>
				</Alert>
			)}
		</DialogContent>
	);
};

Content.propTypes = {
	setCheckedOffices: func.isRequired,
	checkedOffices: array.isRequired,
	officesData: array.isRequired,
	isDataExist: bool.isRequired,
	userName: string.isRequired,
	isLoading: bool.isRequired,
};
