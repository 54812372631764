import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiPaper-root': {
			minWidth: '90%',
		},
	},
	dialogTitle: {
		color: theme.palette.text.inputLabel,
		fontSize: '20px',
		fontWeight: 600,
		marginBottom: '24px',
	},
	divider: {
		marginLeft: '-29px',
		marginRight: '-29px',
	},
	contentTitle: {
		color: theme.palette.text.inputLabel,
		fontSize: '18px',
		fontWeight: 600,
		marginBottom: '24px',
	},
	availableCars: {
		color: theme.palette.text[3],
		fontSize: '16px',
		fontWeight: 400,
	},
	emptyIconContainer: {
		display: 'flex',
		justifyContent: 'center',
	},
	emptyIcon: {
		height: '100px',
		width: '100px',
	},
}));
