import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useCarsLogHistory = ({ uuid }) => {
	const { data, isFetching, isError, error } = useQuery(
		QUERY_KEYS.CARS.HISTORY_LOGS(uuid),
		async () => await services.get(ENDPOINTS.CARS.LOG_HISTORY({ uuid })),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError, error };
};

export default useCarsLogHistory;
