import React from 'react';
import {
	Box,
	Button,
	CircularProgress,
	Grid,
	Typography,
} from '@material-ui/core';
import { array, bool, func, object } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import helpers and utils
import { UPDATE_REASON_FIELDS } from './helpers';
import { useCommonFields, useTranslations } from 'components/utilities';

// Import components
import { Alert, ButtonProgress } from 'components/elements';
import { OldAndNewReasons } from '../../../OldAndNewReasons';

// Import context
import { useUrgentFormsProvider } from '../../../../../../context';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = ({
	cancelReasons = [],
	isReasonsLoading,
	isError,
	error = {},
	getReasonName,
	isLoading,
}) => {
	const classes = useStyles();
	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const {
		toggleUpdateReasonOpen,
		toggleSubmitUpdateReasonClicked,
		isSubmitUpdateReasonClicked,
		cancelReasonBooking,
	} = useUrgentFormsProvider();

	const { name: currentCancelReason } = cancelReasonBooking || {};

	const { renderFields } = useCommonFields();

	const {
		values: { cancellationReason: newCancellationReason },
	} = useFormState();

	if (isError) {
		return <Alert message={error.message} />;
	}

	const renderUpdateReasonFields = () => (
		<>
			{isReasonsLoading ? (
				<Box>
					<CircularProgress size={24} />
				</Box>
			) : (
				UPDATE_REASON_FIELDS({ reasons: cancelReasons, lang }).map(renderFields)
			)}
		</>
	);

	const renderConfirmUpdateReasonFields = () => (
		<Grid container direction="column" spacing={2}>
			<Grid item>
				<Typography variant="body1" className={classes.updateConfirmDesc}>
					{t(
						'bookings.validate_cancellation.confirm_update_cancel_reason_desc'
					)}
				</Typography>
			</Grid>
			<OldAndNewReasons
				newReason={
					newCancellationReason && getReasonName(newCancellationReason)
				}
				oldReason={currentCancelReason?.[lang]}
			/>
		</Grid>
	);

	return (
		<>
			{isSubmitUpdateReasonClicked
				? renderConfirmUpdateReasonFields()
				: renderUpdateReasonFields()}
			<Grid
				container
				justifyContent="flex-end"
				alignItems="center"
				spacing={2}
				className={classes.buttonsContainer}
			>
				<Grid item>
					<Button
						onClick={() => {
							toggleUpdateReasonOpen();
							isSubmitUpdateReasonClicked && toggleSubmitUpdateReasonClicked();
						}}
						color="default"
						type="button"
						size="large"
					>
						{t('common.buttons.cancel')}
					</Button>
				</Grid>
				<Grid item>
					{isSubmitUpdateReasonClicked ? (
						<ButtonProgress
							color="primary"
							variant="contained"
							size="large"
							type="submit"
							disabled={isLoading}
							isLoading={isLoading}
						>
							{t('common.buttons.confirm')}
						</ButtonProgress>
					) : (
						<Button
							color="primary"
							variant="contained"
							size="large"
							type="button"
							disabled={!newCancellationReason}
							onClick={toggleSubmitUpdateReasonClicked}
						>
							{t('common.buttons.submit')}
						</Button>
					)}
				</Grid>
			</Grid>
		</>
	);
};

FormContent.propTypes = {
	isLoading: bool.isRequired,
	isReasonsLoading: bool.isRequired,
	cancelReasons: array,
	isError: bool,
	error: object,
	getReasonName: func.isRequired,
};
