import React from 'react';
import { bool, string } from 'prop-types';

// Import components
import { CustomSwitch } from 'components/elements';

// Import utils
import { useActiveButton } from './useActiveButton';

export const ActiveButton = ({ isActive = false, carUuid }) => {
	const { handleOnSwitchChange } = useActiveButton({ carUuid });

	return (
		<CustomSwitch
			color="primary"
			defaultChecked={isActive}
			onChange={handleOnSwitchChange}
		/>
	);
};

ActiveButton.propTypes = {
	isActive: bool,
	carUuid: string.isRequired,
};
