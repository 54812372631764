import React from 'react';

// Import components
import { IconButton } from 'components/elements';

// Import assets
import { DownloadIcon } from 'assets/icons';

export const ReceiptDetailsTableCell = () => {
	return (
		<>
			<IconButton onClick={() => {}}>
				<DownloadIcon />
			</IconButton>
		</>
	);
};
