import { get } from 'lodash';

// Import helpers
import { BOOKING_STATES } from 'helpers';

const { ACCEPTED, CANCELED, EARLY_RETURN } = BOOKING_STATES;

export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'bookings.booking_states.accepted',
		status: ACCEPTED,
	},
	{
		label: 'bookings.booking_states.canceled',
		status: CANCELED,
	},
	{
		label: 'bookings.booking_states.early_return',
		status: EARLY_RETURN,
	},
	{
		label: 'bookings.booking_states.completed',
		status: 'completed',
	},
];

export const handleChangeStatus = ({
	setFilters,
	status,
	filters,
	filterPath,
}) =>
	setFilters({ ...filters, [filterPath]: status === 'all' ? null : status });

const getFilterStatus = ({ defaultFilterState, filterPath, filters }) => {
	if (!filters) {
		return defaultFilterState || 'all';
	}
	return get(filters, filterPath)?.toString() || 'all';
};

export const isButtonActive = ({
	defaultFilterState,
	filterPath,
	filters,
	status,
}) => {
	const filterStatus = getFilterStatus({
		defaultFilterState,
		filterPath,
		filters,
	});

	return status.toString() === filterStatus;
};
