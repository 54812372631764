import React from 'react';

// Import components
import { CompanyFilter, DateRangeInput, Grid } from 'components/elements';

export const AdditionalFilterFields = () => (
	<>
		<CompanyFilter
			label=""
			name="company_uuid"
			smallFieldStyle
			withSearch
			xs="auto"
			md="auto"
			showEmptyLabel
		/>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="common.fields.pick_date"
				combinedInputs
				isToggleButton
			/>
		</Grid>

		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="cancellation_date"
				nameUntil="cancellation_date_to"
				label="common.fields.cancellation_date"
				combinedInputs
				isToggleButton
			/>
		</Grid>
	</>
);
