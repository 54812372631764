import React from 'react';
import { Drawer, Box, Button } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { string, bool, func } from 'prop-types';
import clsx from 'clsx';

// Import helpers and utils
import { nav_links } from './helpers/nav_links';
import { useProfile, useTranslations } from 'components/utilities';
import { useSidebar } from './useSidebar';

// Import assets
import { WindowResizeLeft } from 'assets/icons';

// Import components
import { LogoWrapper } from 'components/elements';
import { SidebarNav } from './components';

// Import styles
import { useStyles } from './Sidebar.styles';

export const Sidebar = ({ open, variant, onClose, ...props }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { seoTypes } = useSelector((store) => store.defaultSeo.seoTypes);
	const { companyUuid } = useProfile();

	const { handleCollapseDrawer } = useSidebar(onClose);

	return (
		<Drawer
			variant={variant}
			onClose={onClose}
			anchor="left"
			open={open}
			className={clsx(classes.drawer, {
				[classes.drawerOpen]: open,
				[classes.drawerClose]: !open,
			})}
			classes={{
				paper: clsx({
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				}),
			}}
		>
			<Box className={classes.root} {...props}>
				<LogoWrapper isOpen={open} className={classes.logoWrapper} />
				<SidebarNav
					pages={nav_links(seoTypes, companyUuid)}
					isOpen={open}
					toggleDrawer={onClose}
				/>

				<Button
					className={clsx([classes.button, classes.collapseButton])}
					onClick={handleCollapseDrawer}
				>
					<Box className={classes.icon}>
						<WindowResizeLeft />
					</Box>
					{open && t('nav.collapse')}
				</Button>
			</Box>
		</Drawer>
	);
};

Sidebar.propTypes = {
	onClose: func,
	open: bool.isRequired,
	variant: string.isRequired,
};
