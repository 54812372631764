import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		width: '100%',
		minHeight: '450px',
		maxHeight: '450px',
		padding: '20px 24px 20px 0',
		borderRight: '1px solid',
		borderRightColor: theme.palette.background[3],
	},
	list: {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		listStyle: 'none',
		overflow: 'auto',
		height: 'calc(450px - 2 * 20px)',
	},
	loadingWrapper: {
		minHeight: '450px',
		maxHeight: '450px',
	},
	cityText: {
		fontSize: '14px',
		fontWeight: '600',
		color: theme.palette.text[4],
	},
	cityWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		position: 'relative',
		padding: '8px 0 8px 24px ',
	},
	selectedCityWrapper: {
		'&:before': {
			content: '""',
			position: 'absolute',
			width: '4px',
			left: 0,
			top: 0,
			height: '100%',
			backgroundColor: theme.palette.primary.main,
		},
	},
	selectedCityText: {
		color: theme.palette.primary.main,
	},
	unsavedCityText: {
		color: theme.palette.warning.mid,
	},
	unsaved: {
		color: theme.palette.text[1],
	},
}));
