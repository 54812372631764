import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 9999,
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	container: {
		position: 'relative',
		justifyContent: 'space-between',
		width: `calc(100% - 335px)`,
		padding: theme.spacing(4),
	},
	middleContent: {
		width: '100%',
	},
}));
