import React, { createRef, useEffect, useRef } from 'react';
import { number, object } from 'prop-types';
import {
	Box,
	TableCell,
	TableRow,
	TableSortLabel,
	Typography,
} from '@material-ui/core';
import _ from 'lodash';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { SESSION_STORAGE_MAIN_TABLE_HEADER_REF } from 'helpers';

// Import styles
import { useStyles } from './TableHeadRow.styles';
import { TriangleRoundedDownArrowIcon } from 'assets/icons';

const TableHeadRow = ({ headerGroup, currentPageRowsCount = 0 }) => {
	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const classes = useStyles();

	const tableHeaderCellsRef = useRef(
		headerGroup.headers.map(() => createRef())
	);

	useEffect(() => {
		if (currentPageRowsCount) {
			const headerCellWidths = tableHeaderCellsRef.current.map(
				(ref) => `${ref?.current?.getBoundingClientRect().width}px`
			);
			window.sessionStorage.setItem(
				SESSION_STORAGE_MAIN_TABLE_HEADER_REF,
				JSON.stringify(headerCellWidths)
			);
		}
	}, [currentPageRowsCount, lang]);

	return (
		<TableRow {...headerGroup.getHeaderGroupProps()}>
			{headerGroup.headers.map(
				(column, index) =>
					!column.isHidden && (
						<TableCell
							key={column.id}
							{...column.getHeaderProps(column.getSortByToggleProps())}
							ref={tableHeaderCellsRef.current[index]}
						>
							<Box className={classes.container}>
								<Box className={classes.headerWrapper}>
									<Typography noWrap className={classes.tableHeader}>
										{t(column.render('Header'))}
									</Typography>
									{_.isFunction(column.renderSubHeader) && (
										<Box>{column.renderSubHeader(column.subHeader)}</Box>
									)}
								</Box>

								{!column.disableSortBy && column.id !== 'selection' ? (
									<TableSortLabel
										// react-table has a unsorted state which is not treated here
										active={column.isSorted}
										direction={column.isSortedDesc ? 'desc' : 'asc'}
										className={classes.tableSortLabel}
										hideSortIcon={false}
										IconComponent={TriangleRoundedDownArrowIcon}
									/>
								) : null}
							</Box>
						</TableCell>
					)
			)}
		</TableRow>
	);
};

TableHeadRow.propTypes = {
	headerGroup: object.isRequired,
	currentPageRowsCount: number.isRequired,
};

export default TableHeadRow;
