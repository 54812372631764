import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	by: {
		fontSize: 14,
	},
	datesContainer: {
		marginTop: '24px',
	},
}));
