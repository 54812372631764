import React, { useEffect } from 'react';
import { Button, Grid } from '@material-ui/core';
import clsx from 'clsx';

//Import utils and helpers
import { useTableContextProvider } from 'components/context';
import { useTranslations } from 'components/utilities';
import { navFilters } from './helpers';

// Import styles
import { useStyles } from './InnerNavbarElements.styles';

export const InnerNavbarElements = () => {
	const { filters, setFilters } = useTableContextProvider();
	const { t } = useTranslations();
	const classes = useStyles({ period: filters?.period });

	const isNavActive = (value) => {
		if (null == filters && value === null) {
			return true;
		}

		return filters?.period === value;
	};

	const handleOnButtonChange = (value) => {
		const newFilters = { ...filters, period: value };
		setFilters(newFilters);
	};

	useEffect(() => {
		setFilters({ period: null });
		// eslint-disable-next-line
	}, []);

	return (
		<Grid container spacing={3}>
			{navFilters.map(({ label, value }) => (
				<Grid item key={label}>
					<Button
						onClick={() => handleOnButtonChange(value)}
						className={clsx({
							[classes.active]: isNavActive(value),
							[classes.button]: true,
							[classes[value]]: true,
						})}
					>
						{t(label)}
					</Button>
				</Grid>
			))}
		</Grid>
	);
};
