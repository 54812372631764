import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		padding: '16px 29px',
		minHeight: '150px',
		justifyContent: 'space-between',
	},
	icon: {
		color: theme.palette.primary.main,
		width: '24px',
		height: '24px',
	},
	button: {
		color: theme.palette.primary.main,
		fontSize: '13px',
		fontWeight: 600,
	},
}));
