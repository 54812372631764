import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { CAR_INFO_FIELDS, convertCarDetails } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';
import { LoadingWrapper } from 'components/elements';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import context
import { useCarAndCompanyProvider } from '../../context';

// Import styles
import { useStyles } from '../../CarAndCompany.styles';

export const Car = ({ isHistoryDialog = false }) => {
	const classes = useStyles();

	const { t } = useTranslations();
	const booking = useSelector(previewBookingSelector);

	const { editBookingHistory, isFetching } = useCarAndCompanyProvider();

	if (isFetching) return <LoadingWrapper />;

	const carDetails = convertCarDetails({
		booking: isHistoryDialog ? editBookingHistory : booking,
		t,
	});

	return (
		<>
			<Typography variant="h5" className={classes.title}>
				{t('common.car')}
			</Typography>
			<PreviewFields fields={CAR_INFO_FIELDS} customData={carDetails} />
		</>
	);
};

Car.propTypes = {
	isHistoryDialog: bool,
};
