import React from 'react';
import { arrayOf, string, object, func } from 'prop-types';
import { Field, useForm } from 'react-final-form';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = ({
	fields,
	groupedFields,
	onClear,
	transKey = 'bookings.additional_info',
}) => {
	const { submitting } = useFormState();
	const { reset, submit } = useForm();
	const { t } = useTranslations();
	const classes = useStyles();

	// eslint-disable-next-line
	const renderField = (fieldName) => (
		<Grid key={fieldName} item md={3}>
			<Field
				component={CheckboxFieldAdapter}
				disabled={submitting}
				labelText={t(`${transKey}.${fieldName}`)}
				type="checkbox"
				color="primary"
				name={fieldName}
			/>
		</Grid>
	);

	// eslint-disable-next-line
	const renderGroupedField = ({ label, innerFields = [] }) => (
		<Grid key={label} item xs={12}>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<Typography className={classes.groupTitle}>{t(label)}</Typography>
				</Grid>
				<Grid item xs={12}>
					<Grid container spacing={3}>
						{innerFields.map((fieldName) => (
							<Grid key={fieldName} item xs={4}>
								<Field
									component={CheckboxFieldAdapter}
									disabled={submitting}
									labelText={t(`${transKey}.${fieldName}`)}
									type="checkbox"
									color="primary"
									name={fieldName}
								/>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

	return (
		<Box paddingY={3} paddingX={2}>
			<Grid container spacing={groupedFields ? 6 : 3}>
				{groupedFields
					? groupedFields.map(renderGroupedField)
					: fields.map(renderField)}
				<Grid item xs={12}>
					<Grid
						container
						alignItems="center"
						justifyContent="flex-end"
						spacing={3}
					>
						<Grid item>
							<Button
								onClick={() => onClear(reset)}
								className={classes.clearAllButton}
							>
								{t('common.buttons.clear_all')}
							</Button>
						</Grid>
						<Grid item>
							<Button onClick={submit} className={classes.submitButton}>
								{t('common.buttons.submit')}
							</Button>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</Box>
	);
};

FormContent.propTypes = {
	groupedFields: arrayOf(object),
	fields: arrayOf(string),
	onClear: func.isRequired,
	transKey: string,
};
