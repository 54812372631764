import React from 'react';
import { func, number, object, oneOfType, string } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './TextWithIconTableCell.styles';

export const TextWithIconTableCell = ({ cell, icon: Icon, text }) => {
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container alignItems="center" spacing={1}>
				<Grid item>
					<Icon className={classes.icon} />
				</Grid>
				<Grid item>
					<Typography className={classes.text}>{text}</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

TextWithIconTableCell.propTypes = {
	cell: object.isRequired,
	icon: oneOfType([func, object]),
	text: oneOfType([string, number]).isRequired,
};
