import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils and helpers
import { COLUMNS_ACCESSORS } from 'views/Faqs/FaqsList/columns';
import { useTranslations } from 'components/utilities';
import { getActionMenuFields } from './helpers';

// Import components
import { ActiveTableCell, NewActionsMenuTableCell } from 'components/elements';

// Import store
import { updateActiveFaq } from 'store/actions';

const { ACTIONS, NAME, ACTIVE } = COLUMNS_ACCESSORS;

export const Cell = ({ cell }) => {
	const {
		i18n: { language: lng },
	} = useTranslations();

	const renderCell = (cell) => {
		const {
			column: { id: columnId, isHidden },
			row: {
				original: { uuid, is_active: isActive, name },
			},
			getCellProps,
		} = cell || {};

		if (isHidden) return null;

		switch (columnId) {
			case NAME:
				return <TableCell key={columnId}>{name[lng]}</TableCell>;

			case ACTIVE:
				return (
					<ActiveTableCell
						key={columnId}
						activeAction={updateActiveFaq}
						cell={cell}
						isActive={isActive}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={getActionMenuFields({ uuid })}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={columnId} {...getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
};
