import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	alertContainer: {
		display: 'flex',
		padding: '16px',
		backgroundColor: theme.palette.purple[10],
		border: 'solid 1px',
		borderColor: theme.palette.purple[30],
		borderRadius: '8px',
		maxWidth: '70%',
		gap: '20px',
	},
	icon: {
		color: theme.palette.purple[60],
	},
	alert: {
		color: theme.palette.text.inputLabel,
		fontSize: '16px',
		fontWeight: 400,
	},
	mapsContainer: {
		display: 'flex',
		justifyContent: 'space-between',
	},
}));
