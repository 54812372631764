// Import helpers and utils
import { ACTION_MENU_POPUP_FIELD_TYPES } from 'helpers';

export const useCell = () => {
	const {
		TRANSACTIONS_HISTORY,
		TRANSFER_BOOKINGS,
		BOOKINGS_HISTORY,
		ADD_RATING,
		DOWNLOAD_INVOICE,
		ACCEPT_BOOKING,
		CANCEL_BOOKING,
		CANCEL_PAYMENT,
	} = ACTION_MENU_POPUP_FIELD_TYPES;

	const getActionMenuFields = ({
		showPaymentCancelOption,
		paymentLinkUuid,
	}) => {
		const actions = [
			{ type: ACCEPT_BOOKING },
			{ type: CANCEL_BOOKING },
			{ type: TRANSFER_BOOKINGS },
			{ type: BOOKINGS_HISTORY },
			{ type: TRANSACTIONS_HISTORY },
			{ type: ADD_RATING },
			{ type: DOWNLOAD_INVOICE },
		];

		if (showPaymentCancelOption)
			actions.unshift({
				type: CANCEL_PAYMENT,
				paymentLinkUuid,
			});

		return actions;
	};

	return { getActionMenuFields };
};
