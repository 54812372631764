import React from 'react';
import { object } from 'prop-types';

// Import components
import { Button, Grid, TableCell, Typography } from 'components/elements';
import { CampaignConsumersModal } from './components';

// Import utilities
import { useToggle } from 'components/utilities';

export const TotalDepositCounterTableCell = ({ cell }) => {
	const { on: isOpen, toggle } = useToggle();

	const campaign = cell.row.original;
	const { total_deposit_counter: totalDepositsCounter } = campaign;

	return (
		<TableCell cell={cell} key={cell.column.id}>
			<Grid container justifyContent="center">
				<Grid item>
					{totalDepositsCounter > 0 ? (
						<Button
							text={totalDepositsCounter.toString()}
							onClick={toggle}
							type="dark_text"
							size="small"
						/>
					) : (
						<Typography fontSize={14} align="center">
							{totalDepositsCounter}
						</Typography>
					)}
				</Grid>
			</Grid>

			{isOpen && (
				<CampaignConsumersModal
					campaign={campaign}
					onClose={toggle}
					open={isOpen}
				/>
			)}
		</TableCell>
	);
};

TotalDepositCounterTableCell.propTypes = {
	cell: object.isRequired,
};
