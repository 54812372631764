import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useContractFormPrivacyPolicy = () => {
	const path = '/econtract/policies';

	const { data, isFetching } = useQuery(
		QUERY_KEYS.CONTRACTS.PRIVACY_POLICY_FORM,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching };
};
