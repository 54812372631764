import React, { useEffect, useState, createContext } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useParams } from 'react-router-dom';
import { node } from 'prop-types';
import _ from 'lodash';

// Import utils and helpers
import { useCitiesMutation, useCompanyDropCitiesQuery } from 'queries';
import { convertDropCities, groupDropCities } from './helpers';

export const PickupCitiesCardContext = createContext();

export const PickupCitiesCardProvider = ({ children }) => {
	const { values } = useFormState();
	const companyUuid = values.company_uuid;

	const [filteredCities, setFilterCities] = useState([]);
	const { id: companyUuidFromParams } = useParams();
	const { change } = useForm();
	const { data: dropCities } = useCompanyDropCitiesQuery(companyUuid);
	const { data: cities, isLoading: citiesLoading } = useCitiesMutation({
		companyUuid,
	});

	useEffect(() => {
		if (companyUuidFromParams) {
			change('company_uuid', companyUuidFromParams);
		}
		// eslint-disable-next-line
	}, [companyUuidFromParams]);

	const isCityUnsaved = (uuid) => {
		const { price, drop_cities: dropCities } = values.out_data[uuid] || {};

		return _.has(values.out_data, uuid) && (!price || !dropCities?.length);
	};

	const citiesWithoutSelectedCity = cities.filter(
		({ uuid }) => uuid !== values.selected_city_in
	);
	const convertedDropCities = convertDropCities(dropCities);

	const sortedCities = cities.sort((a, b) =>
		isCityUnsaved(a.uuid) === isCityUnsaved(b.uuid)
			? 0
			: isCityUnsaved(a.uuid)
			? -1
			: 1
	);

	useEffect(() => {
		if (sortedCities.length) {
			setFilterCities(sortedCities);
		}
		// eslint-disable-next-line
	}, [sortedCities]);

	useEffect(() => {
		const grouppedDropCities = groupDropCities(convertedDropCities);
		change('out_data', grouppedDropCities);

		// eslint-disable-next-line
	}, [dropCities.length]);

	const handleOnSearch = (e) => {
		const searchValue = e.target.value;
		const filteredRecords = cities?.filter(({ name }) =>
			name?.toLowerCase()?.includes(searchValue.toLowerCase())
		);

		setFilterCities(filteredRecords);
	};

	const setNewEmptyCity = (selectedCityIn) => {
		const uuidAlreadyExist = _.has(values.out_data, selectedCityIn);

		if (!uuidAlreadyExist) {
			const emptyObject = {
				[selectedCityIn]: {
					drop_cities: [],
					price: null,
				},
			};

			change('out_data', { ...values.out_data, ...emptyObject });
		}
	};

	const selectCity = (uuid) => {
		change('selected_city_in', uuid);
		setNewEmptyCity(uuid);
	};

	return (
		<PickupCitiesCardContext.Provider
			value={{
				dropCities: convertedDropCities,
				filteredCities,
				citiesLoading,
				handleOnSearch,
				citiesWithoutSelectedCity,
				selectCity,
				isCityUnsaved,
				cities,
			}}
		>
			{children}
		</PickupCitiesCardContext.Provider>
	);
};

PickupCitiesCardProvider.propTypes = {
	children: node,
};
