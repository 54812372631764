import React from 'react';

// Import components
import { ActionConfirmDialog } from 'components/elements';
import { OldAndNewCar } from './components';

// Import context
import { useEditBookingFormProvider } from '../../context';

// Import utils
import { useTranslations } from 'components/utilities';

export const ChangeConfirmationDialog = () => {
	const { t } = useTranslations();

	const {
		toggleConfirmChangeDialog,
		isConfirmChangeDialogOpen,
		handleSubmitTransferBooking,
		isSubmitLoading,
	} = useEditBookingFormProvider();

	return (
		<ActionConfirmDialog
			isOpen={isConfirmChangeDialogOpen}
			toggle={toggleConfirmChangeDialog}
			title={t('bookings.edit_booking.change_to_new_car')}
			description={t('bookings.edit_booking.confirm_change_car')}
			actionButtonText="common.buttons.change"
			actionButtonVariant="contained"
			isLoading={isSubmitLoading}
			confirmAction={handleSubmitTransferBooking}
			extraContent={<OldAndNewCar />}
		/>
	);
};
