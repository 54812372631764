export const COLUMNS_ACCESSORS = {
	ID: 'id',
	CUSTOMER_NAME: 'customer_name',
	URGENT_REASON: 'urgent_reason',
	COMPANY: 'company',
	PICK_DATE: 'pick_date',
	STATUS: 'status',
	ACTIONS: 'actions',
};

export const getColumns = (isAll) => [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.reason',
		accessor: COLUMNS_ACCESSORS.URGENT_REASON,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.marked_by',
		accessor: 'urgent_assistance_marked_by',
		disableSortBy: true,
		isHidden: !isAll,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
