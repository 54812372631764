import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { object } from 'prop-types';

// Import utils
import { useLocationContainer } from './useLocationContainer';
import { useTranslations } from 'components/utilities';

// Import components
import { Stepper, DeliveryLocationMap } from '../../components';
import { Alert } from 'components/elements';

// Import styles
import { useStyles } from '../../Steps.styles';

export const LocationContainer = ({ outerDeliveryData }) => {
	const { isFetched, isError, states, deliveryLocationData } =
		useLocationContainer({
			customStepsData: outerDeliveryData
				? { ...outerDeliveryData.steps, isFetched: true }
				: null,
		});

	const { t } = useTranslations();

	const classes = useStyles();

	if (!isFetched) {
		return <CircularProgress size={28} />;
	}

	if (isError) {
		return <Alert message={t('common.messages.error_message')} />;
	}

	if (states?.length <= 1) {
		return null;
	}

	return (
		<Box className={classes.locationContainer}>
			<Stepper deliveryData={deliveryLocationData} />
			<DeliveryLocationMap deliveryData={deliveryLocationData} />
		</Box>
	);
};

LocationContainer.propTypes = {
	outerDeliveryData: object,
};
