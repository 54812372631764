import React from 'react';
import { func, shape, string } from 'prop-types';

// Import utils and helpers
import { useOfficesDialogContent } from './useOfficesDialogContent';
import { useTranslations } from 'components/utilities';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { Actions, Content } from '../../components';

export const OfficesDialogContent = ({
	user: { uuid: userUuid, full_name: userName },
	toggleDialog,
}) => {
	const { t } = useTranslations();

	const {
		setCheckedOffices,
		checkedOffices,
		officesData,
		isDataExist,
		isLoading,
	} = useOfficesDialogContent({ userUuid });

	return (
		<>
			<ClosableDialogTitle
				title={t('company_managers.modal.assign_offices')}
				onClose={toggleDialog}
			/>
			<Content
				isLoading={isLoading}
				isDataExist={isDataExist}
				officesData={officesData}
				checkedOffices={checkedOffices}
				setCheckedOffices={setCheckedOffices}
				userName={userName}
			/>
			<Actions
				isLoading={isLoading}
				isDataExist={isDataExist}
				dialogClose={toggleDialog}
				checkedOffices={checkedOffices}
				userId={userUuid}
			/>
		</>
	);
};

OfficesDialogContent.propTypes = {
	user: shape({
		uuid: string,
		full_name: string,
	}),
	toggleDialog: func,
};
