import React from 'react';
import { string } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import components
import { OptionField } from '../OptionField';
import { DropCityField } from './components';

export const DropCityOptionField = ({ label }) => {
	return (
		<Grid container alignItems="center" justifyContent="space-between">
			<Grid item xs="auto">
				<OptionField type="drop_city" label={label} />
			</Grid>
			<Grid item xs={3}>
				<DropCityField />
			</Grid>
		</Grid>
	);
};

DropCityOptionField.propTypes = {
	label: string,
};
