export const COLUMNS_ACCESSORS = {
	ID: 'id',
	CUSTOMER: 'customer',
	COMPANY: 'company',
	PERIOD: 'period',
	SETTLEMENT: 'settlement',
	NEXT_PAYMENT: 'next_payment',
	STATUS: 'status',
	ACTIONS: 'actions',
	EARLY_RETURN_DATE: 'early_return_date',
	EARLY_RETURN_FEES: 'early_return_fees',
	CANCELLATION_REASON: 'cancellation_reason',
	NUMBER_OF_MONTHS: 'number_of_months',
	DROPOFF_DATE: 'dropoff_date',
	CREATED_AT: 'created_at',
	MONTHLY_PAYMENT_AMOUNT: 'monthly_payment_amount',
	TOTAL_AMOUNT: 'total_amount',
	NUMBER_OF_TRIES: 'no_of_tries',
	OUTSTANDING_BALANCE: 'outstanding_balance',
	IS_SETTLED: 'is_settled',
	PERIOD_DURATION: 'period_duration',
	IS_ACTIVE: 'is_active',
};

export const getColumns = (isAdmin) => [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},

	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
		isHidden: !isAdmin,
	},
	{
		Header: 'bookings.table.headers.office',
		accessor: 'office_name',
		disableSortBy: true,
		isHidden: isAdmin,
		minWidth: 180,
	},
	{
		Header: 'customer_balance.table.headers.early_return_date',
		accessor: COLUMNS_ACCESSORS.EARLY_RETURN_DATE,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.period_duration_months',
		accessor: COLUMNS_ACCESSORS.PERIOD_DURATION,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.no_of_tries',
		accessor: COLUMNS_ACCESSORS.NUMBER_OF_TRIES,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.out_standing_balance',
		accessor: COLUMNS_ACCESSORS.OUTSTANDING_BALANCE,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.is_settled',
		accessor: COLUMNS_ACCESSORS.IS_SETTLED,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.period',
		accessor: COLUMNS_ACCESSORS.PERIOD,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.settlement',
		accessor: COLUMNS_ACCESSORS.SETTLEMENT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.next_payment',
		accessor: COLUMNS_ACCESSORS.NEXT_PAYMENT,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'customer_balance.table.headers.is_active',
		accessor: COLUMNS_ACCESSORS.IS_ACTIVE,
		disableSortBy: true,
		isHidden: isAdmin,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
		minWidth: '200px',
	},

	// Additional

	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.pickup_date',
		accessor: 'pickup_date',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.dropoff_date',
		accessor: COLUMNS_ACCESSORS.DROPOFF_DATE,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.early_return_fees',
		accessor: COLUMNS_ACCESSORS.EARLY_RETURN_FEES,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.number_of_months',
		accessor: COLUMNS_ACCESSORS.NUMBER_OF_MONTHS,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.cancellation_fees',
		accessor: 'cancellation_fees',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.cancellation_reason',
		accessor: 'cancellation_reason',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.total_amount',
		accessor: COLUMNS_ACCESSORS.TOTAL_AMOUNT,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.monthly_payment_amount',
		accessor: COLUMNS_ACCESSORS.MONTHLY_PAYMENT_AMOUNT,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.city',
		accessor: 'city',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.vehicle',
		accessor: 'vehicle',
		disableSortBy: true,
		isHidden: true,
	},
];
