import { ADMIN, COMPANY_MANAGER, OFFICE_MANAGER, ADMINS } from 'helpers';

export const PERMISSION_RATE_OFFICE = [ADMIN];
export const PERMISSION_CUSTOMER_COMPENSATION = [ADMIN];
export const PERMISSION_URGENT_ASSIGN = [ADMIN];
export const PERMISSION_DELIVERY_INFORMATION = [
	ADMIN,
	COMPANY_MANAGER,
	OFFICE_MANAGER,
];

export const PERMISSION_OLD_DELIVERY_INFORMATION = [ADMIN];

export const PERMISSION_RATE_CUSTOMER = [
	ADMIN,
	COMPANY_MANAGER,
	OFFICE_MANAGER,
];

export const PERMISSION_ADD_ADMIN_COMMENT = ADMINS;

export const PERMISSION_PARTIAL_PAYMENT = [ADMIN];

export const PERMISSION_CUSTOMER_WALLET_PREVIEW = [
	ADMIN,
	COMPANY_MANAGER,
	OFFICE_MANAGER,
];
