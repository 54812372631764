// Import helpers
import { FIELD_TYPES } from 'helpers';
import {
	transPrefix,
	SERVICE_TYPES,
} from 'views/Bookings/CreateBooking/helpers';

export const FIELDS = [
	{
		type: FIELD_TYPES.RADIO_GROUP,
		name: 'service_type',
		options: [
			{ label: `${transPrefix}.pick_up`, value: SERVICE_TYPES.PICKUP },
			{
				label: `${transPrefix}.delivery_and_return`,
				value: SERVICE_TYPES.DELIVERY_AND_RETURN,
			},
		],
		labelExtractor: ({ label }) => label,
		keyExtractor: ({ value }) => value,
		md: 4,
	},
];
