export const ADDITIONAL_FIELDS = [
	'created_at',
	'pickup_date',
	'dropoff_date',
	'early_return_date',
	'early_return_fees',
	'number_of_months',
	'cancellation_fees',
	'cancellation_reason',
	'total_amount',
	'monthly_payment_amount',
	'city',
	'vehicle',
];
