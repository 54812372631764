import { useFormState } from 'react-final-form';

// Import components
import {
	CustomerDetails,
	ServiceType,
	CustomerLocation,
	SelectOffice,
	DateAndTime,
	SelectCar,
	AdditionalServices,
	PaymentMethod,
} from './components';

// import helpers
import { BOOKING_STEPS } from '../../helpers';
import { SELECT_CAR_TITLES } from './helpers';

export const useFormContent = () => {
	const {
		values: { step, item },
	} = useFormState();

	const isStepCreate = step === BOOKING_STEPS.CREATE;

	const isCarPreviewed = !!item.uuid;

	const SelectCarTitles = isCarPreviewed
		? SELECT_CAR_TITLES.PREVIEWED
		: SELECT_CAR_TITLES.DEFAULT;

	const formContent = [
		{
			id: 1,
			component: CustomerDetails,
			isDivider: true,
			title: 'bookings.create_booking.customer_details',
		},
		{
			id: 2,
			component: ServiceType,
			isDivider: true,
			title: 'bookings.create_booking.service_type',
		},
		{
			id: 3,
			component: CustomerLocation,
			isDivider: true,
			title: 'bookings.create_booking.customer_location',
		},
		{
			id: 4,
			component: DateAndTime,
			isDivider: true,
			title: 'bookings.create_booking.date_and_time',
		},
		{
			id: 5,
			component: SelectCar,
			isDivider: true,
			...SelectCarTitles,
		},
		{
			id: 6,
			component: SelectOffice,
			isDivider: true,
			title: 'bookings.create_booking.select_office',
			info: 'bookings.create_booking.select_office_info',
		},
		{
			id: 7,
			component: AdditionalServices,
			isDivider: true,
			title: 'bookings.create_booking.additional_services',
		},
		{
			id: 8,
			component: PaymentMethod,
			isDivider: false,
			title: 'bookings.create_booking.payment_method',
		},
	];

	return { formContent, isStepCreate };
};
