import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EditPenOutlinedIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<g id="Content, Edit/Pen, Edit">
			<g id="Group">
				<path
					id="Path"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M4.41091 13.0158L13.0159 4.41083C13.3409 4.08583 13.8684 4.08583 14.1934 4.41083L15.5901 5.8075C15.9151 6.1325 15.9151 6.66 15.5901 6.985L6.98425 15.5892C6.82841 15.7458 6.61675 15.8333 6.39591 15.8333H4.16675V13.6042C4.16675 13.3833 4.25425 13.1717 4.41091 13.0158Z"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
				<path
					id="Path_2"
					d="M11.4583 5.96667L14.0333 8.54167"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
			</g>
		</g>
	</SvgIcon>
);
