import React from 'react';
import {
	Box,
	Button,
	Chip,
	Divider,
	Menu,
	MenuItem,
	Tooltip,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';

// Import utilities
import { useBookingStatusChip } from './useBookingStatusChip';

// Import translations
import { useTranslations } from 'components/utilities';

// Import icons
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

// Import styles
import { useStyles } from './styles';

export const BookingStatusChip = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		statusType,
		status,
		statusDescription,
		isRejectedOrCanceled,
		rejectedMenuEl,
		handleCloseRejectedMenu,
		rejectionReasons,
		setRejectedMenuEl,
	} = useBookingStatusChip();

	return (
		<>
			{isRejectedOrCanceled ? (
				<>
					<Button
						endIcon={rejectedMenuEl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
						onClick={(event) => setRejectedMenuEl(event.currentTarget)}
						aria-controls="more-menu"
						aria-haspopup="true"
						className={clsx(
							classes.chip,
							classes.rejectedButton,
							classes[statusType]
						)}
					>
						{t(`bookings.booking_states.${status}`)}
					</Button>
					<Menu
						id="rejected-menu"
						className={classes.menuList}
						anchorEl={rejectedMenuEl}
						open={Boolean(rejectedMenuEl)}
						onClose={handleCloseRejectedMenu}
						getContentAnchorEl={null}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
					>
						{rejectionReasons.map(({ title, createdAt, reason, by }, index) => (
							<Box key={title}>
								{reason && (
									<MenuItem key={title} className={classes.menuItem}>
										<Box className={classes.reasonWrapper}>
											<Typography
												className={classes.reasonTitle}
												variant="body1"
											>
												{t(title)}
											</Typography>
											<Typography
												className={classes.reasonText}
												variant="body1"
											>
												{createdAt}
											</Typography>
										</Box>
										<Typography variant="body1" className={classes.reason}>
											{reason}
										</Typography>
										<Typography variant="body1" className={classes.reasonText}>
											{t('common.by')} {by}
										</Typography>
									</MenuItem>
								)}
								{reason && index !== rejectionReasons.length - 1 && (
									<Divider className={classes.divider} />
								)}
							</Box>
						))}
					</Menu>
				</>
			) : (
				<Tooltip title={statusDescription}>
					<Chip
						label={t(`bookings.booking_states.${status}`)}
						className={clsx(classes.chip, classes[statusType])}
					/>
				</Tooltip>
			)}
		</>
	);
};
