import React from 'react';
import { node } from 'prop-types';
import { useSelector } from 'react-redux';

// Import styles
import { useStyles } from './styles';

// Import components
import { Loader } from 'components/elements';
import { Box } from '@material-ui/core';

const DashboardTemplate = ({ children }) => {
	const classes = useStyles();
	const isUserProfileLoaded = useSelector(
		(state) => state.auth.isUserProfileLoaded
	);

	return isUserProfileLoaded ? (
		children
	) : (
		<Box className={classes.wrapper}>
			<Loader />
		</Box>
	);
};

DashboardTemplate.propTypes = {
	children: node,
};

export default DashboardTemplate;
