import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

const useRatingReasons = ({ enabled = true, rating_type = '' }) => {
	const path = '/v2/booking/rating-reasons';

	const { data, isFetching } = useQuery(
		QUERY_KEYS.RATINGS.REASONS(rating_type),
		async () => await services.post(path, { rating_type }),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useRatingReasons;
