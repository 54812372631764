import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const usePromoCodesLookup = () => {
	const path = `/v2/coupon/list`;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.PROMO_CODES.LIST,
		async () => await services.post(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
