// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FILTER_FIELDS = [
	{
		type: FIELD_TYPES.SWITCH,
		label: 'common.fields.delivery',
		name: 'is_delivery',
		md: 'auto',
		xs: 'auto',
	},
	{
		type: FIELD_TYPES.SWITCH,
		label: 'common.fields.rejected',
		name: 'is_rejected',
		md: 'auto',
		xs: 'auto',
	},
	{
		type: FIELD_TYPES.SWITCH,
		label: 'common.fields.paid_online',
		name: 'is_payment_online',
		md: 'auto',
		xs: 'auto',
	},
];
