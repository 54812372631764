import { useFormState } from 'react-final-form';
import { useCarSelectionDialogProvider } from '../../context';

export const useCarCard = ({ car, preview }) => {
	const { chosenCar, setChosenCar } = useCarSelectionDialogProvider();
	const {
		values: {
			item: { uuid },
		},
	} = useFormState();

	const isSelected = (uuid === car.id || chosenCar?.id === car.id) && !preview;

	const handleCardClick = () => {
		if (!preview) {
			setChosenCar(car);
		}
	};

	return { handleCardClick, isSelected };
};
