import React from 'react';
import { bool, func, string } from 'prop-types';
import { DialogContent, Dialog } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	ClosableDialogTitle,
	CustomerWalletContent,
} from 'components/elements';

export const CustomerWalletDialog = ({ on, toggle, customerUuid }) => {
	const { t } = useTranslations();

	return (
		<Dialog open={on} onClose={toggle} maxWidth="md" fullWidth>
			<ClosableDialogTitle
				title={t('bookings.compensation_history.title')}
				onClose={toggle}
			/>
			<DialogContent>
				<CustomerWalletContent customerUuid={customerUuid} />
			</DialogContent>
		</Dialog>
	);
};

CustomerWalletDialog.propTypes = {
	customerUuid: string.isRequired,
	toggle: func.isRequired,
	on: bool.isRequired,
};
