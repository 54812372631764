import i18next from 'i18next';

// Import helpers
import { ACTIVE_CELL_STATES, AR } from 'helpers';

const { ACTIVE, INACTIVE, BLOCKED } = ACTIVE_CELL_STATES;

export const columns = [
	{
		Header: 'customers.table.headers.name',
		accessor: 'name',
		valueExtractor: ({ name }) => name || '',
	},
	{
		Header: 'customers.table.headers.phone_number',
		accessor: 'phone',
	},
	{
		Header: 'customers.table.headers.location',
		accessor: 'location',
	},
	{
		Header: 'customers.table.headers.age',
		accessor: 'age',
	},
	{
		Header: 'customers.preview.customer.birth_date',
		accessor: 'birth_date',
	},
	{
		Header: 'customers.table.headers.rating',
		accessor: 'rating',
		valueExtractor: ({ rating }) => rating.toString(),
	},
	{
		Header: 'customers.table.headers.total_no_of_booking',
		accessor: 'accepted_booking_counter',
		valueExtractor: ({ accepted_booking_counter }) =>
			accepted_booking_counter.toString(),
	},
	{
		Header: 'customers.table.headers.approve_organization',
		accessor: 'organization_verified',
		valueExtractor: ({ organization_verified }) =>
			i18next.t(`common.${organization_verified ? 'yes' : 'no'}`),
	},
	{
		Header: 'table.headers.active',
		accessor: 'state',
		valueExtractor: ({ state }) => {
			switch (state) {
				case ACTIVE:
					return i18next.t('table.actions.active');
				case INACTIVE:
					return i18next.t('table.actions.inactive');
				case BLOCKED:
					return i18next.t('table.actions.blocked');
				default:
					return state;
			}
		},
	},
	{
		Header: 'customers.table.headers.gender',
		accessor: 'gender_name',
		valueExtractor: ({ gender_name }) =>
			i18next.language === AR ? gender_name?.ar : gender_name?.en,
	},
	{
		Header: 'customers.table.headers.driver_license',
		accessor: 'license_number',
	},
	{
		Header: 'customers.table.headers.organization',
		accessor: 'organization_name',
		valueExtractor: ({ organization_name }) =>
			i18next.language === AR ? organization_name?.ar : organization_name?.en,
	},
	{
		Header: 'customers.table.headers.type',
		accessor: 'type',
	},
	{
		Header: 'common.fields.sign_up_date_time',
		accessor: 'created_at',
	},
];
