import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	fieldsContainer: {
		display: 'flex',
		alignItems: 'center',
		'& .MuiGrid-root': {
			maxWidth: 'fit-content',
		},
		'& .MuiFormControlLabel-root': {
			marginRight: '5px',
			'& p': {
				color: theme.palette.text.inputLabel,
				fontSize: '14px',
				fontWeight: 600,
			},
		},
	},
	price: {
		color: theme.palette.text[5],
		fontSize: '14px',
		fontWeight: 600,
	},
	selectedPrice: {
		color: theme.palette.primary.main,
		fontSize: '14px',
		fontWeight: 600,
	},
}));
