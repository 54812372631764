import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useCompanyActiveCollection = () => {
	const path = 'v2/supplier/business/company/collection-active';

	const { data, isFetched } = useQuery(
		QUERY_KEYS.COMPANIES.ACTIVE_COLLECTION,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetched };
};
