import React from 'react';
import { Box, ListItem, Typography } from '@material-ui/core';

// Import components
import { OptionFieldFactory } from './components';

// Import utilities
import { useOptionsField } from './useOptionsField';

// Import styles
import { useStyles } from './OptionsField.styles';

export const OptionsField = () => {
	const classes = useStyles();
	const { available_options, convertedErrors } = useOptionsField();

	return (
		!!available_options.length && (
			<Box className={classes.container}>
				{available_options.map((option) => (
					<ListItem key={option.type}>
						<OptionFieldFactory option={option} />
					</ListItem>
				))}

				{convertedErrors?.map((error) => (
					<Box key={error} m={2} mt={0}>
						<Typography color="error">{error}</Typography>
					</Box>
				))}
			</Box>
		)
	);
};
