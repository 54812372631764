export const COLUMNS_ACCESSORS = {
	CUSTOMER: 'customer',
	APPROVED: 'approved',
	ACTIONS: 'actions',
	STATUS: 'status',
	OFFICE: 'office',
	PERIOD: 'period',
	CAR: 'car',
	BY: 'by',
	ID: 'id',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car',
		accessor: COLUMNS_ACCESSORS.CAR,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.office',
		accessor: COLUMNS_ACCESSORS.OFFICE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.period',
		accessor: COLUMNS_ACCESSORS.PERIOD,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.no_of_booking',
		accessor: 'customer_no_booking',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: 'created_at',
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.by',
		accessor: COLUMNS_ACCESSORS.BY,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},

	// Additional
	{
		Header: 'bookings.table.headers.approved',
		accessor: COLUMNS_ACCESSORS.APPROVED,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.gender',
		accessor: 'gender',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.number_of_days',
		accessor: 'number_of_days',
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'bookings.table.headers.price',
		accessor: 'price',
		disableSortBy: true,
		isHidden: true,
	},
];
