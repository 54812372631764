import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';

// Import utils helpers
import { useCustomerRatingForm } from './useCustomerRatingForm';
import { useTranslations } from 'components/utilities';

export const CustomerRatingForm = () => {
	const { onSubmit, isLoading, initialValues } = useCustomerRatingForm();
	const { t } = useTranslations();

	return (
		<Grid container>
			<Grid item xs={12} md={8}>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h5" gutterBottom>
							{t('bookings.preview_booking.ratings.company_rating_of_customer')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Form
							initialValues={initialValues}
							onSubmit={onSubmit}
							render={({ handleSubmit }) => (
								<form onSubmit={handleSubmit}>
									<FormContent isLoading={isLoading} />
								</form>
							)}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
