import React from 'react';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Button, Grid, Collapse, Box, Typography } from '@material-ui/core';
import { number, object } from 'prop-types';

// Import components
import { PreviewField } from 'components/elements';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

// Import helpers
import { EXTENSION_INVOICE_FIELDS } from './helpers';
import { convertDate } from 'helpers';

// Import styles
import { useStyles } from './SubSection.styles';

export const SubSection = ({ data, index }) => {
	const { on: isOpen, toggle } = useToggle();

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Button
				onClick={toggle}
				color="primary"
				disableTouchRipple
				className={classes.button}
				endIcon={
					isOpen ? (
						<ExpandLess color="primary" />
					) : (
						<ExpandMore color="primary" />
					)
				}
			>
				<Typography variant="h5" color="primary">
					{t('bookings.preview_booking.invoice.extension_no', {
						number: index + 1,
					})}
				</Typography>
			</Button>
			<Collapse in={isOpen}>
				<Grid container spacing={3}>
					{EXTENSION_INVOICE_FIELDS.map(({ accessor, label }) => (
						<Grid xs={12} key={accessor} item>
							<PreviewField label={label}>
								{accessor === 'created_at'
									? convertDate(data[accessor])
									: data[accessor]}
							</PreviewField>
						</Grid>
					))}
				</Grid>
			</Collapse>
		</Box>
	);
};

SubSection.propTypes = {
	data: object.isRequired,
	index: number.isRequired,
};
