import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { Export } from '../Export';
import { AddNewButton } from 'components/elements';
import { QuickFinderForm } from '../QuickFinderForm';

// Import utils and context
import { URLS } from 'components/routes';

export const OuterToolbarElements = () => {
	return (
		<Grid container spacing={2}>
			<Grid item>
				<QuickFinderForm />
			</Grid>
			<Grid item>
				<Export />
			</Grid>
			<Grid item>
				<AddNewButton
					text="company.toolbar.add_company_btn"
					to={URLS.createCompanyUrl}
				/>
			</Grid>
		</Grid>
	);
};
