import { useQuery } from 'react-query';

// Import helpers
import { QUERY_KEYS } from 'helpers';
import myAxios from 'services/myAxiosFactory';

export const useContractPdf = (contractNumber) => {
	const path = `/econtract/${contractNumber}/pdf`;

	const request = async () =>
		await myAxios().get(path, {
			responseType: 'blob',
		});

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CONTRACTS.PDF(contractNumber),
		request,
		{ refetchOnWindowFocus: false, enabled: !!contractNumber }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError };
};
