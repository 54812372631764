import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	durationText: {
		color: theme.palette.text.inputLabel,
		fontSize: '14px',
		fontWeight: 600,
		marginTop: '38px',
		marginBottom: '16px',
	},
	duration: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.medium,
		borderRadius: '8px',
		padding: '6px 12px',
	},
	noteContainer: {
		display: 'flex',
		gap: '12px',
	},
	icon: {
		color: theme.palette.text[2],
	},
	note: {
		color: theme.palette.text[2],
		fontSize: '14px',
		fontWeight: 400,
	},
}));
