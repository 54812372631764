import React from 'react';

// Impor store
import { fetchCampaigns } from 'store/actions';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, OuterToolbarElements } from './components';
import { TableRPC } from 'components/utilities';
import { Filters } from './components';

export const Campaigns = () => (
	<TableRPC
		outerToolbarElements={<OuterToolbarElements />}
		fetchDataTableAction={fetchCampaigns}
		columnsAccessor={COLUMNS_ACCESSORS}
		title="nav.campaigns.campaign"
		tableBodyRow={TableBodyRow}
		filtersBar={<Filters />}
		columns={columns}
	/>
);
