import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableRow } from '@material-ui/core';

// Import helpers and utils
import {
	BookingStatusActionsProvider,
	useTableContextProvider,
} from 'components/context';

// Import styles
import { useStyles } from './TableBodyRow.styles';

// Import components
import { Cell } from './components';

export const TableBodyRow = ({
	onMouseLeave,
	onMouseEnter,
	isHovered,
	row,
	ref,
}) => {
	const { fetchData } = useTableContextProvider();
	const classes = useStyles();

	return (
		<TableRow
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
			{...row.getRowProps()}
		>
			{row.cells.map((cell) => (
				<BookingStatusActionsProvider
					booking={cell.row.original}
					onChange={fetchData}
					key={cell.column.id}
				>
					<Cell cell={cell} isHovered={isHovered} />
				</BookingStatusActionsProvider>
			))}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
