import React from 'react';
import { useParams } from 'react-router-dom';

// Import components
import { AlertMessage, LoadingWrapper, Steps } from 'components/elements';

// Import utils and helpers
import { convertOfficeRatingHistory } from './helpers';
import { useDialogContent } from './useDialogContent';
import { useOfficeRatingHistory } from 'queries';

export const DialogContent = () => {
	const { id: bookingUuid } = useParams();
	const {
		data: officeRatingHistory,
		isFetching,
		isError,
	} = useOfficeRatingHistory(bookingUuid);

	const convertedOfficeRatingHistory =
		convertOfficeRatingHistory(officeRatingHistory);
	const { steps, stepIconClassName } = useDialogContent(
		convertedOfficeRatingHistory
	);

	if (isFetching) return <LoadingWrapper />;
	if (isError) return <AlertMessage />;

	return <Steps steps={steps} stepIconClassName={stepIconClassName} />;
};
