import React from 'react';

// Import components
import { MultiCompaniesFilter } from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<>
			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					showEmptyLabel
					xs="auto"
					md="auto"
				/>
			)}
		</>
	);
};
