import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SpeedMeter = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 16 16" fill="none" {...props}>
		<circle
			cx="8.00309"
			cy="8.83414"
			r="1.16715"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.99753 4.83398V5.50093"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.17193 6.00591L5.64346 6.47744"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.82812 8.00936L10.8316 6.00586"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.66797 11.8364H10.3366"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.0029 8.83543H11.3359"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4.66694 8.83543H4"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.81124 2.8313C4.58944 2.96607 2.0355 5.59675 1.99612 8.82113C1.99022 10.7555 2.91902 12.5736 4.48986 13.7025C4.93452 14.0131 5.46544 14.1764 6.00782 14.1694H9.99481C10.5249 14.1773 11.0441 14.0194 11.4801 13.7178C13.6376 12.185 14.5357 9.41942 13.6905 6.91142C12.8453 4.40342 10.4564 2.7456 7.81124 2.8313Z"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
