export const COLUMNS_ACCESSORS = {
	CODE: 'code',
	PERIOD: 'period',
	STATUS: 'status',
	CREATED_AT: 'created_at',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'common.fields.promo_code',
		accessor: COLUMNS_ACCESSORS.CODE,
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.discount',
		accessor: 'discount',
		disableSortBy: true,
	},
	{
		Header: 'promo_codes.table.headers.max_discount',
		accessor: 'max_discount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.period',
		accessor: COLUMNS_ACCESSORS.PERIOD,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
