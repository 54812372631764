import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

// Import utils and helpers
import { useCustomerDetails as useCustomerDetailsQuery } from 'queries';
import { convertCustomerDetails } from './helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { replaceEditCustomerUrl } from 'helpers';

export const useCustomerDetails = () => {
	const { push } = useHistory();
	const {
		customer: { uuid: customerUuid },
	} = useSelector(previewBookingSelector);
	const {
		data: customerDetails,
		isFetching,
		isError,
	} = useCustomerDetailsQuery(customerUuid);

	const convertedCustomerDetails = convertCustomerDetails(customerDetails);

	const { license_exists: licenseExists, file_path: filePath } =
		convertedCustomerDetails?.details?.license || {};

	const goToCustomerEditPage = () => push(replaceEditCustomerUrl(customerUuid));

	return {
		convertedCustomerDetails,
		goToCustomerEditPage,
		licenseExists,
		customerUuid,
		filePath,
		isFetching,
		isError,
	};
};
