import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3),

		'& .MuiCard-root': {
			padding: 0,
			borderRadius: 0,
		},
	},
	title: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text[3],
	},
	header: {
		padding: 0,
		marginBottom: theme.spacing(2),

		'& svg': {
			color: theme.palette.text[3],
		},
		'& button': {
			marginTop: '12px',
			'&:hover': {
				backgroundColor: 'transparent',
			},
		},
	},
	noComments: {
		fontSize: 14,
		color: theme.palette.gray.medium,
	},
	content: {
		padding: '0 0 24px 0',
	},
	scrollContainer: {
		maxHeight: '350px',
		width: '100%',
	},
	card: {
		boxShadow: 'none',
	},
}));
