import React from 'react';
import { arrayOf, object, shape, string } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';

// Import components
import { ChipLabel } from 'components/elements';

export const CommonStatusTableCell = ({ cell, statusList, currentStatus }) => {
	const { t } = useTranslations();

	const { color, label } =
		statusList.find(({ status }) => status === currentStatus) || {};

	return (
		<TableCell
			style={{ minWidth: '200px' }}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			<ChipLabel label={t(label)} color={color} />
		</TableCell>
	);
};

CommonStatusTableCell.propTypes = {
	currentStatus: string.isRequired,
	cell: object.isRequired,
	statusList: arrayOf(
		shape({
			status: string.isRequired,
			label: string.isRequired,
			color: string.isRequired,
		})
	).isRequired,
};
