import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		fontSize: 12,
		fontWeight: 600,
		padding: '10px 20px',

		'&.MuiButton-outlined': {
			borderColor: theme.palette.error.darkBorder,
		},
	},

	icon: {
		background: 'transparent',
	},

	container: {
		padding: '14px 24px',
		backgroundColor: theme.palette.error.lighter,
		marginTop: theme.spacing(4),
	},

	info: {
		gridGap: 5,
	},

	labels: {
		gridGap: 0,
	},
}));
