import React, { createContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { node } from 'prop-types';
import axios from 'axios';

// Import utilities and helpers
import { useMessage, useToggle, useTranslations } from 'components/utilities';
import {
	BOOKING_COMPENSATION_STATES,
	BOOKING_COMPENSATION_TYPES,
	LOCATIONS,
} from 'helpers';
import { INITIAL_VALUES } from './helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';
import {
	calculateCompensation,
	fetchBookingPreview,
	submitCustomerCompensationForm,
} from 'store/actions';

export const UseCompensationFormContext = createContext(null);

export const CompensationFormProvider = ({ children }) => {
	const { t } = useTranslations();

	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);
	const [isEdit, setIsEdit] = useState(false);

	const { message } = useMessage();

	const { COMPENSATED } = BOOKING_COMPENSATION_STATES;
	const { AUTO_LOW_RATING, CANCELLATION } = BOOKING_COMPENSATION_TYPES;

	const dispatch = useDispatch();

	const { on: isCompensationFormOpen, toggle: toggleCompensationForm } =
		useToggle();

	const { on: isCompensationHistoryOpen, toggle: toggleCompensationHistory } =
		useToggle();

	const booking = useSelector(previewBookingSelector);

	const {
		compensation: defaultCompensation,
		compensations,
		operation_topics: operationTopics,
		item,
		uuid,
		can_add_admin_manual_compensation: canAddCompensation,
	} = booking || {};

	const [compensation, setCompensation] = useState(defaultCompensation);

	const [compensationFormInitialValues, setCompensationFormInitialValues] =
		useState(INITIAL_VALUES);

	const { total_discount_gross_price: totalDiscountGrossPrice } = item || {};

	const { status: compensationStatus, type: compensationType } =
		compensation || {};

	const showAddCompensationButton =
		(compensationStatus !== COMPENSATED ||
			compensationType === AUTO_LOW_RATING) &&
		canAddCompensation;

	const isCancellation = !!operationTopics?.includes(CANCELLATION);

	const compensationAmountMax = isCancellation
		? null
		: +totalDiscountGrossPrice;

	const handleError = (error) => {
		setError(error);
		setIsError(true);
	};

	const callbackSuccess = async () => {
		toggleCompensationForm();
		message.success(t('common.messages.successfully_done'));
		await fetchBookingPreview({ id: uuid })(dispatch);
	};

	const handleOnSubmitCompensation = async (values) => {
		const options = {
			values,
			callbackSuccess,
			errorCallback: handleError,
			compensation: compensation,
			booking,
			location: isEdit ? '' : LOCATIONS.BOOKING_PREVIEW,
		};
		setIsLoading(true);
		await submitCustomerCompensationForm(options)(dispatch);
		setIsLoading(false);
	};

	const isHistory = !!compensations?.length;

	const editCompensation = (compensation) => {
		setCompensation(compensation);
		toggleCompensationForm();
		setIsEdit(true);
	};

	useEffect(() => {
		setCompensationFormInitialValues({
			...INITIAL_VALUES,
			...compensation,
			compensation_amount: compensation?.compensation_amount?.nett || 0,
		});
	}, [compensation]);

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const getCalculatedAmount = async (values = {}) => {
		setIsLoading(true);

		const { gross } = await calculateCompensation({
			values,
			booking,
			cancelToken,
		});

		setIsLoading(false);

		return gross;
	};

	const AddCompensation = async () => {
		setIsEdit(false);
		const compensation_amount = await getCalculatedAmount();
		setCompensationFormInitialValues({
			...INITIAL_VALUES,
			compensation_amount,
		});
		toggleCompensationForm();
	};

	const compensationFormTitle = !isEdit
		? 'common.buttons.add_compensation'
		: 'common.buttons.edit_compensation';

	return (
		<UseCompensationFormContext.Provider
			value={{
				isCompensationFormOpen,
				toggleCompensationForm,
				showAddCompensationButton,
				compensationAmountMax,
				handleOnSubmitCompensation,
				isError,
				error,
				isLoading,
				isHistory,
				isCompensationHistoryOpen,
				toggleCompensationHistory,
				compensations,
				editCompensation,
				compensationFormInitialValues,
				AddCompensation,
				compensationFormTitle,
				isEdit,
			}}
		>
			{children}
		</UseCompensationFormContext.Provider>
	);
};

CompensationFormProvider.propTypes = {
	children: node,
};
