import React from 'react';
import { useEffect, useState } from 'react';
import { useFormState, useForm } from 'react-final-form';
import moment from 'moment';

// Import components
import { LinkPreview } from './components';

// import utils and  helpers
import {
	getBookingInfoFields,
	getCarAndCompanyFields,
	getCostSummaryFields,
	getSelectedExtraServicesStr,
	getExtraCostSummaryFields,
} from './helpers';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';
import { useTranslations, useMessage } from 'components/utilities';
import {
	DMY_DASH_FORMAT,
	TIME_FORMAT_AM_PM,
	YMD_DASH_FORMAT,
	TIME_FORMAT,
} from 'helpers';
import { getCarBookingDetails } from 'store/actions';
import { SERVICE_TYPES } from 'views/Bookings/CreateBooking/helpers';
import { BOOKING_STEPS } from 'views/Bookings/CreateBooking/helpers';

export const useReviewBooking = () => {
	const [bookingData, setBookingData] = useState({});
	const [loading, setLoading] = useState(false);
	const {
		values: {
			customerDetails: {
				full_name: fullName,
				phone_number: phoneNumber,
				license_number: licenseNumber,
				license_date: licenseDate,
				license_file: licenseFile,
			},
			service_type: serviceType,
			period: { pick_date: pickDate, drop_date: dropDate, pick_time: pickTime },
			city,
			company,
			office,
			item: { uuid },
			delivery,
			location,
			additional_services: additionalServices,
			licenseFileLink,
		},
	} = useFormState();

	const { change } = useForm();

	const { message } = useMessage();

	const isPickup = serviceType === SERVICE_TYPES.PICKUP;

	const getPayload = () => {
		const payload = {
			period: {
				pick_date: moment(pickDate).format(YMD_DASH_FORMAT),
				drop_date: moment(dropDate).format(YMD_DASH_FORMAT),
				pick_time: moment(pickTime).format(TIME_FORMAT),
			},
			options: {
				...(!isPickup && {
					delivery: true,
					delivery_drop: true,
					delivery_location: {
						delivery_lat: delivery.lat,
						delivery_lng: delivery.lng,
					},
				}),
				...additionalServices,
			},
			...(isPickup && { location }),
		};
		return payload;
	};

	useEffect(() => {
		setLoading(true);
		const data = getPayload();
		getCarBookingDetails({
			uuid,
			data,
			successCallback: (resp) => {
				setBookingData(resp.data?.data);
				setLoading(false);
			},
			errorCallback: (error) => {
				if (error?.response?.data?.message) {
					message.error(error.response.data.message);
				} else {
					message.error(t('common.messages.error_message'));
				}
				setLoading(false);
				change('step', BOOKING_STEPS.CREATE);
			},
		});
		// eslint-disable-next-line
	}, []);

	const dropDateObj = moment(dropDate);
	const pickDateObj = moment(pickDate);
	const pickTimeObj = moment(pickTime);
	const licenseDateobj = moment(licenseDate);

	const days = bookingData?.number_of_days;

	const createLocationLink = ({ lat, lng }) =>
		`https://www.google.com/maps?q=${lat},${lng}`;

	const customerLocationLabel = isPickup ? location.address : delivery.address;

	const customerLocationLink = isPickup
		? createLocationLink(location)
		: createLocationLink(delivery);

	const licensePhotoLink = licenseFile
		? URL.createObjectURL(licenseFile)
		: licenseFileLink;

	const selectedServicesStr = getSelectedExtraServicesStr(
		bookingData?.services
	);

	const dailyPrice = bookingData?.car?.discount_price;

	const totalRentPrice = bookingData?.prices?.period_nett_price;

	const vat = bookingData?.prices?.period_tax_price;

	const totalCost = bookingData?.prices?.total_discounted_gross_price;

	const { t } = useTranslations();

	const bookingInfoFields = getBookingInfoFields();

	const carAndCompanyFields = getCarAndCompanyFields();

	const { summaryExtraFields, summaryExtraFieldsData } =
		getExtraCostSummaryFields({ services: bookingData?.services });

	const costSummaryFields = getCostSummaryFields(summaryExtraFields);

	const data = {
		rental_type: t(`${transPrefix}.daily_weekly`),
		service_method: t(`${transPrefix}.${serviceType}`),
		start_date: pickDateObj.format(DMY_DASH_FORMAT),
		end_date: dropDateObj.format(DMY_DASH_FORMAT),
		pick_and_drop_time: pickTimeObj.format(TIME_FORMAT_AM_PM),
		_period: `${days} ${t('common.days')}`,
		customer_name: fullName,
		mobile_number: phoneNumber,
		license_number: licenseNumber,
		license_expiray_date: licenseDateobj.format(DMY_DASH_FORMAT),
		license_photo: (
			<LinkPreview link={licensePhotoLink} label={t(`${transPrefix}.photo`)} />
		),
		customer_location: (
			<LinkPreview link={customerLocationLink} label={customerLocationLabel} />
		),
		payment_method: t(`${transPrefix}.payment_link`),
		rental_company: company,
		office: office,
		city: city,
		car: bookingData.car?.name,
		additional_services: selectedServicesStr,
		_daily_rent_price: `${dailyPrice} x ${days}`,
		total_rent_price: totalRentPrice,
		_vat: vat,
		total_cost: totalCost,
		...summaryExtraFieldsData,
	};

	return {
		bookingInfoFields,
		carAndCompanyFields,
		costSummaryFields,
		data,
		loading,
	};
};
