import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { navigationDropdownTabsSelector, roleSelector } from 'store/selectors';
import { updateDropdownTabs } from 'store/actions';
import clsx from 'clsx';

// Import styles
import useStyles from './styles';

const useSidebarNav = ({ pages, toggleDrawer, isOpen }) => {
	const [currentNavTitle, setCurrentNavTitle] = useState(null);

	const { pathname } = useLocation();

	const dispatch = useDispatch();

	const navigationDropdownTabs = useSelector(navigationDropdownTabsSelector);

	const roles = useSelector(roleSelector);

	const classes = useStyles();

	const collapsedTabs = pages.filter((page) => !!page.collapsed);

	useEffect(() => {
		!isOpen && navigationDropdownTabs?.length > 0 && toggleDrawer();
	}, [isOpen, toggleDrawer, navigationDropdownTabs]);

	const isNestedItem = (title) =>
		collapsedTabs.some(({ collapsed }) =>
			collapsed
				.filter(({ collapsed }) => collapsed)
				.map(({ collapsed }) => collapsed)
				.flat()
				.some((el) => el.title === title)
		);

	const isItemWithoutCollapsed = (title) => {
		const noCollapsedTabs = pages.filter((page) => !page.collapsed);
		const found = noCollapsedTabs.some((tab) => tab.title === title);
		return found && title === currentNavTitle;
	};

	const clearDropdownTabs = (title) => {
		const clear = pages
			.filter((tab) => !tab.collapsed)
			.map((tab) => tab.title)
			.includes(title);

		clear && updateDropdownTabs([])(dispatch);
	};

	const hasAccess = (role) =>
		roles.some((roleItem) => role?.includes(roleItem));

	const getTabTitle = useCallback(
		(tab) => {
			const tabWithoutCollapsed = tab.collapsed.filter(
				(property) => !property.collapsed
			);

			return tabWithoutCollapsed.find(
				(el) => el.href === pathname && hasAccess(el.role)
			)?.title;
		},
		// eslint-disable-next-line
		[pathname]
	);

	const getNestedTabTitle = useCallback(
		(tab) => {
			const tabWithCollapsed = tab.collapsed.filter(
				(property) => property.collapsed
			);

			return tabWithCollapsed.find((subtab) =>
				subtab.collapsed.map((el) => el.href).includes(pathname)
			)?.title;
		},
		[pathname]
	);

	const setCurrentNav = useCallback(() => {
		const setArray = (tab) => {
			const title = getTabTitle(tab);
			const nestedTabTitle = getNestedTabTitle(tab);

			title && isOpen && updateDropdownTabs([tab?.title])(dispatch);
			nestedTabTitle &&
				isOpen &&
				updateDropdownTabs([tab?.title, nestedTabTitle])(dispatch);
		};

		collapsedTabs.forEach((tab) => {
			setArray(tab);
		});

		// eslint-disable-next-line
	}, [pages, dispatch, getNestedTabTitle, getTabTitle]);

	const setCurrentTitle = useCallback(() => {
		const setArray = (tab) => {
			if (tab.collapsed) {
				const title = getTabTitle(tab);
				const nestedTabTitle = getNestedTabTitle(tab);

				const nestedSubTitle = tab.collapsed
					.filter((property) => property.collapsed)
					.map((subtab) => subtab.collapsed)
					.flat()
					.find((el) => el.href === pathname)?.title;

				title && setCurrentNavTitle(title);
				nestedTabTitle && setCurrentNavTitle(nestedSubTitle);
			} else {
				tab.href === pathname && setCurrentNavTitle(tab.title);
			}
		};

		pages.forEach((tab) => {
			setArray(tab);
		});
	}, [pages, pathname, getNestedTabTitle, getTabTitle]);

	const handleClick = (title) => () => {
		const newState = navigationDropdownTabs.includes(title)
			? navigationDropdownTabs.filter((item) => item !== title)
			: [...navigationDropdownTabs, title];

		updateDropdownTabs(newState)(dispatch);
	};

	const listItemButtonClasses = (isIcon) =>
		isIcon ? classes.button : clsx(classes.button, classes.buttonPadding);

	const collapsedHeadButtonClasses = (title) =>
		navigationDropdownTabs.includes(title)
			? clsx(
					classes.collapsedHeadButton,
					classes.horizontalBar,
					classes.button,
					classes.active
					// eslint-disable-next-line no-mixed-spaces-and-tabs
			  )
			: classes.button;

	const collapsedHeadListItemClasses = (title) =>
		navigationDropdownTabs.includes(title)
			? clsx(classes.item, classes.collapsedHeadListItem, classes.active)
			: classes.item;

	const titleClass = (title) =>
		currentNavTitle === title && classes.activeTitle;

	useEffect(() => {
		setCurrentTitle();
	}, [setCurrentTitle]);

	useEffect(() => {
		setCurrentNav();
	}, [setCurrentNav]);

	return {
		collapsedHeadListItemClasses,
		collapsedHeadButtonClasses,
		isItemWithoutCollapsed,
		listItemButtonClasses,
		clearDropdownTabs,
		isNestedItem,
		handleClick,
		titleClass,
		hasAccess,
		classes,
		navigationDropdownTabs,
	};
};

export default useSidebarNav;
