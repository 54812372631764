import { useFormState } from 'react-final-form';

// Import helpers
import { SERVICE_TYPES } from 'views/Bookings/CreateBooking/helpers';

export const useCustomerLocation = () => {
	const { PICKUP } = SERVICE_TYPES;
	const {
		values: { service_type: serviceType },
	} = useFormState();

	const isPickup = serviceType === PICKUP;

	return { isPickup };
};
