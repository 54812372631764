import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useUrgentBookingsStats = (options = {}) => {
	const { data, isLoading, isStale, isError, error, refetch } = useQuery(
		QUERY_KEYS.BOOKINGS.URGENT_STATES(JSON.stringify(options)),
		async () =>
			await services.post(ENDPOINTS.BOOKINGS.URGENT_BOOKINGS_STATS, options),
		{ refetchOnWindowFocus: false, staleTime: 1000 }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isLoading, isError, error, refetch, isStale };
};
