import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: '32px',
		justifyContent: 'space-between',
		width: '100%',
	},
	topBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '16px',
		minHeight: '40px',
	},
	editIcon: {
		width: '24px',
		height: '24px',
	},
}));
