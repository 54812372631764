import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import components
import { Filters, OuterToolbarElements, TableBodyRow } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

// Import store
import { setCompanyContext, fetchManagersList } from 'store/actions';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { BUTTONS } from './helpers';

export const CompanyManagers = () => {
	const dispatch = useDispatch();
	const { id: companyUuid } = useParams();

	useEffect(() => {
		setCompanyContext(dispatch, companyUuid);
	}, [dispatch, companyUuid]);

	return (
		<TableRPC
			fetchDataTableAction={fetchManagersList}
			title="nav.companies.company_managers"
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			filtersBar={<Filters />}
			columns={columns}
			outerToolbarElements={<OuterToolbarElements />}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="active" buttons={BUTTONS} />
			}
		/>
	);
};
