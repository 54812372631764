import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SubscriptionCarIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M16.9922 4.50371C13.4942 2.17471 8.72621 2.55071 5.64021 5.63471C3.52421 7.74971 2.68721 10.6537 3.11721 13.3977"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M13.6 14.2008H10.4V14.6008C10.4 15.0426 10.0418 15.4008 9.6 15.4008H8.8C8.35817 15.4008 8 15.0426 8 14.6008V13.2008C8 12.4276 8.6268 11.8008 9.4 11.8008H14.6C15.3732 11.8008 16 12.4276 16 13.2008V14.6008C16 15.0426 15.6418 15.4008 15.2 15.4008H14.4C13.9582 15.4008 13.6 15.0426 13.6 14.6008V14.2008Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.91406 11.8926L9.37398 9.92666C9.50096 9.38387 9.98499 9 10.5424 9H13.4628C14.0203 9 14.5043 9.38387 14.6313 9.92665L15.0912 11.8926"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.9913 11.4967L8 10.9987"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M15.0078 11.4967L15.9991 10.9987"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.8984 10.6016C21.3284 13.3456 20.4914 16.2506 18.3754 18.3646C15.2894 21.4496 10.5214 21.8256 7.02344 19.4966"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.6412 4.37116C20.3402 5.06916 20.3402 6.20116 19.6412 6.89916C18.9422 7.59716 17.8092 7.59716 17.1102 6.89916C16.4112 6.20116 16.4112 5.06916 17.1102 4.37116C17.8092 3.67316 18.9422 3.67316 19.6412 4.37116"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M4.37581 19.6296C3.67681 18.9316 3.67681 17.7996 4.37581 17.1016C5.07481 16.4036 6.20781 16.4036 6.90681 17.1016C7.60581 17.7996 7.60581 18.9316 6.90681 19.6296C6.20681 20.3276 5.07481 20.3276 4.37581 19.6296"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
