import React from 'react';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import styles
import { useStyles } from './PublicHolidays.styles';

// Import components
import { Card, Grid, Typography } from 'components/elements';
import { FormContent } from './components';

// Import utils and helpers
import { usePublicHolidays } from './usePublicHolidays';
import { useTranslations } from 'components/utilities';
import { INITIAL_VALUES } from './helpers';
import { yupValidator } from 'helpers';
import { validationSchema } from './validation';

export const PublicHolidays = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { handleOnSubmit } = usePublicHolidays();

	return (
		<Box className={classes.container}>
			<Grid container spacing={7}>
				<Grid item xs={12}>
					<Typography fontWeight="500" fontSize={28}>
						{t('public_holidays.form.title')}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Card lg={12}>
						<Form
							initialValues={INITIAL_VALUES}
							onSubmit={handleOnSubmit}
							validate={(values) =>
								yupValidator({
									values,
									validationSchema: validationSchema({
										values,
									}),
								})
							}
							render={({ handleSubmit }) => (
								<Box component="form" onSubmit={handleSubmit}>
									<FormContent />
								</Box>
							)}
						/>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};
