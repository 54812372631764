import { useDispatch } from 'react-redux';

// Import utils
import { useToggle } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

// Import store
import { resetBookingPreviewItem } from 'store/actions';

export const useActionsMenuTableCell = ({
	cell,
	bookingUuid,
	props,
	extraActions,
}) => {
	const dispatch = useDispatch();
	const { fetchData } = useTableContextProvider();

	const booking = cell.row.original;

	const { uuid } = booking;

	const rowUuid = bookingUuid || uuid;

	const { on: isBookingHistoryOpen, toggle: toggleBookingHistory } =
		useToggle();
	const { on: isTransactionsHistoryOpen, toggle: toggleTransactionsHistory } =
		useToggle();

	const { on: isSubscriptionHistoryOpen, toggle: toggleSubscriptionsHistory } =
		useToggle();

	const { on: isCompensationDialogOpen, toggle: toggleCompensationDialog } =
		useToggle();

	const handleOnCompensationClose = () => {
		resetBookingPreviewItem({ dispatch });
		fetchData();
	};

	const previewPopupProps = {
		...props,
		rowUuid,
		toggleBookingHistory,
		toggleTransactionsHistory,
		toggleSubscriptionsHistory,
	};

	const extraActionsProps = {
		toggleSubscriptionsHistory,
		toggleBookingHistory,
		toggleCompensationDialog,
		extraActions,
	};

	return {
		handleOnCompensationClose,
		isCompensationDialogOpen,
		toggleCompensationDialog,
		isBookingHistoryOpen,
		toggleBookingHistory,
		isTransactionsHistoryOpen,
		toggleTransactionsHistory,
		isSubscriptionHistoryOpen,
		toggleSubscriptionsHistory,
		fetchData,
		rowUuid,
		uuid,
		booking,
		previewPopupProps,
		extraActionsProps,
	};
};
