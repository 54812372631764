import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		marginBottom: theme.spacing(4),
		fontWeight: 500,
	},
	oldCarCompanyCard: {
		padding: 0,
		marginTop: theme.spacing(4),
	},
	oldCarCompanyHeader: {
		'& .MuiGrid-item, button': {
			padding: 0,
		},
	},
	oldCarCompanyContent: {
		padding: theme.spacing(5),
	},
	divider: {
		marginTop: theme.spacing(5),
	},
}));
