import React from 'react';
import { Box, Button, Grid } from '@material-ui/core';

// Import utilities
import { useExtraActions } from './useExtraActions';
import { useTranslations } from 'components/utilities';

// Import components
import { BookingHistory, BookingHistoryProvider } from 'components/elements';

// Import styles
import { useStyles } from './ExtraActions.styles';

export const ExtraActions = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { actions, bookingUuid, isHistoryOpen, toggleHistoryDialog } =
		useExtraActions();

	return (
		<Box className={classes.container}>
			{actions.map(({ label, Icon, onClick }) => (
				<Grid container key={label}>
					<Grid item xs={12}>
						<Button className={classes.button} onClick={onClick}>
							<Icon className={classes.icon} />
							{t(label)}
						</Button>
					</Grid>
				</Grid>
			))}

			<BookingHistoryProvider bookingId={bookingUuid}>
				<BookingHistory open={isHistoryOpen} onClose={toggleHistoryDialog} />
			</BookingHistoryProvider>
		</Box>
	);
};
