import { useEffect, useState } from 'react';

// import utils and helpers
import { getClosePaymentDetails } from 'store/actions';
import { multiplication, getPaymentDetails } from './helpers';

export const useClosePaymentDetails = ({ booking }) => {
	const [details, setDetails] = useState({});
	const {
		additional_days: additionalDays,
		extra_day_price: additionalDaysPrice,
		additional_hours: additionalHours,
		additional_hour_price: additionalHoursPrice,
		additional_km_number: additionalKMs,
		additional_km_price: additionalKMsPrice,
		car_damage_price: carDamagePrice,
		full_refill_price: fuelRefillPrice,
		violations_price: violationsPrice,
	} = details;

	useEffect(() => {
		getClosePaymentDetails({
			uuid: booking?.uuid,
			successCallback: (resp) => setDetails(resp.data),
			errorCallback: () => {},
		});
		// eslint-disable-next-line
	}, []);

	const prefix = 'bookings.preview_booking.invoice';

	const daysCharges = multiplication(additionalDays, additionalDaysPrice);
	const hoursCharges = multiplication(additionalHours, additionalHoursPrice);
	const kmCharges = multiplication(additionalKMs, additionalKMsPrice);

	const fields = [
		{ label: `${prefix}.total_charges`, accessor: 'total_charges' },
		{ label: `${prefix}.vat`, accessor: '_vat' },
	];

	daysCharges &&
		fields.push({
			label: `${prefix}.additional_days_charges`,
			accessor: 'additional_days_charges',
		});

	carDamagePrice &&
		fields.push({
			label: `${prefix}.car_damage_charges`,
			accessor: 'car_damage_charges',
		});

	fuelRefillPrice &&
		fields.push({
			label: `${prefix}.fuel_refill_charges`,
			accessor: 'fuel_refill_charges',
		});

	additionalHoursPrice &&
		fields.push({
			label: `${prefix}.additional_hours_charges`,
			accessor: 'additional_hours_charges',
		});

	violationsPrice &&
		fields.push({ label: `${prefix}.violations`, accessor: 'violations' });

	additionalKMsPrice &&
		fields.push({
			label: `${prefix}.additional_km_charges`,
			accessor: 'additional_km_charges',
		});

	const { totalGross, vat } = getPaymentDetails(
		daysCharges,
		hoursCharges,
		kmCharges,
		violationsPrice,
		carDamagePrice,
		fuelRefillPrice
	);

	const data = {
		total_charges: totalGross,
		_vat: vat,
		additional_days_charges: daysCharges,
		car_damage_charges: carDamagePrice,
		fuel_refill_charges: fuelRefillPrice,
		additional_hours_charges: hoursCharges,
		violations: violationsPrice,
		additional_km_charges: kmCharges,
	};

	return { details, fields, data };
};
