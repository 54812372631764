import React from 'react';
import { string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

export const DateBody = ({ date, time }) => {
	return (
		<Grid container direction="column">
			<Grid item>
				<Typography noWrap>{date}</Typography>
			</Grid>
			{time && (
				<Grid item>
					<Typography noWrap>{time}</Typography>
				</Grid>
			)}
		</Grid>
	);
};

DateBody.propTypes = {
	date: string.isRequired,
	time: string,
};
