import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useCustomerDetails = (customerUuid) => {
	const path = `v2/customer/${customerUuid}/show`;

	const { data, isFetching, isError, error } = useQuery(
		QUERY_KEYS.CUSTOMERS.DETAILS(customerUuid),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, staleTime: 60 * 60 * 1000 }
	);

	const fetchedData = data?.data.data || null;

	return { data: fetchedData, isFetching, isError, error };
};
