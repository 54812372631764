// Import utils
import { useTabsProvider } from 'components/context';
import { useNoteStates } from 'components/utilities';

export const useOptionLabels = () => {
	const { data: booking } = useTabsProvider();
	const { isChangedLabel, isVIP, isDiscount, isDriverAssigned } =
		useNoteStates(booking);

	const labels = [];

	if (isChangedLabel) {
		labels.push('bookings.table.notes.changed');
	}

	if (isVIP) {
		labels.push('bookings.table.notes.vip');
	}

	if (isDiscount) {
		labels.push('bookings.table.notes.discount');
	}

	if (isDriverAssigned) {
		labels.push('bookings.table.notes.driver');
	}

	return {
		labels,
	};
};
