import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useTabsProvider } from 'components/context';
import { useComments } from './useComments';

export const Comments = () => {
	const { data: booking } = useTabsProvider();
	const { commentsSections, noCommentsMessage } = useComments(booking);
	const { t } = useTranslations();

	return (
		<Grid container direction="column" spacing={3}>
			{commentsSections.map(({ title, comments, isVisible, isEmpty }) =>
				isVisible ? (
					<Grid key={title} item xs={12}>
						<Grid item>
							<Typography variant="h5">{t(title)}</Typography>
						</Grid>
						<Grid item>
							{!isEmpty ? (
								<Grid container>
									{comments.map(({ full_name: author, content }, index) => (
										<Grid key={index} xs={12}>
											<Grid
												container
												justifyContent="space-between"
												alignItems="center"
												spacing={3}
											>
												<Grid item>
													<Typography variant="h6">{author}</Typography>
												</Grid>
												<Grid item>
													<Typography>{content}</Typography>
												</Grid>
											</Grid>
										</Grid>
									))}
								</Grid>
							) : (
								<Typography>{t(noCommentsMessage)}</Typography>
							)}
						</Grid>
					</Grid>
				) : null
			)}
		</Grid>
	);
};
