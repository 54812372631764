import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	contentBox: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		gap: '16px',
	},
	driverBox: {
		display: 'flex',
		flexDirection: 'column',
	},
	chip: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.primary.main,
	},
	editIcon: {
		color: theme.palette.primary.main,
	},
}));
