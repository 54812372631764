import { useQuery } from 'react-query';
import axios from 'axios';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCars = ({ payload }) => {
	const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.BOOKINGS.CARS}`;
	const { data, isFetching } = useQuery(
		QUERY_KEYS.BOOKINGS.CARS(JSON.stringify(payload)),
		async () => await axios.post(url, payload),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data ?? [];

	return { data: fetchedData, isFetching };
};
