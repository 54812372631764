import { useParams } from 'react-router-dom';

// Import queries
import { useBookingInvoiceUuidQuery } from 'queries';

export const useReceipts = () => {
	const { id: bookingUuid } = useParams();

	const { data, isLoading, isError } = useBookingInvoiceUuidQuery({
		bookingUuid,
	});

	const { uuid: invoiceUuid } = data || {};

	return { invoiceUuid, isLoading, isError };
};
