import React, { useEffect } from 'react';
import { bool, object } from 'prop-types';

// Import components
import { TableRow } from 'components/elements';
import { Cell } from './components';

// Import utils
import { useTableContextProvider } from 'components/context';

export const TableBodyRow = (props) => {
	const { isHovered, row } = props;
	const { filters, setFilters } = useTableContextProvider();

	useEffect(() => {
		if (null == filters) {
			setFilters({ show_in: 'website' });
		}
		// eslint-disable-next-line
	}, [filters]);

	return (
		<TableRow {...props}>
			{row.cells.map((cell) => (
				<Cell key={cell.column.id} cell={cell} isHovered={isHovered} />
			))}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	isHovered: bool,
};
