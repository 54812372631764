import React from 'react';
import { CircularProgress, Grid } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useBookingDetails } from './useBookingDetails';

// Import contexts
import { MarkUrgentProvider, TabsProvider } from 'components/context';

// Import components
import { AlertMessage } from 'components/elements';
import {
	BottomBar,
	CommentsMenu,
	BookingsTabs,
	TopBar,
	UrgentAttentionWarningCard,
} from './components';

// Import styles
import { useStyles } from './BookingDetails.styles';

export const BookingDetails = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { item, error, isLoaded, isError } = useBookingDetails({ t });

	const renderContent = () =>
		isLoaded &&
		!isError && (
			<Grid container spacing={3} className={classes.container}>
				<Grid item className={classes.middleContent}>
					<TabsProvider>
						<MarkUrgentProvider bookingUuid={item?.uuid}>
							<TopBar />
							<UrgentAttentionWarningCard />
							<BookingsTabs />
						</MarkUrgentProvider>
					</TabsProvider>
					<BottomBar />
				</Grid>
				<Grid item>
					<CommentsMenu />
				</Grid>
			</Grid>
		);

	if (!isLoaded) {
		return (
			<div className={classes.loader}>
				<CircularProgress size={70} color="primary" />
			</div>
		);
	}

	return (
		<div className={classes.root}>
			{isError && isLoaded && (
				<AlertMessage
					title={t('common.error')}
					variant="error"
					description={error.error_header || error.message}
				/>
			)}

			<Form onSubmit={() => {}} initialValues={item} render={renderContent} />
		</div>
	);
};
