import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BanknoteIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M16.5032 15.1781V15.1781C14.416 14.7607 12.2622 14.8119 10.1972 15.3282L10 15.3775C7.80519 15.9262 5.51592 15.9806 3.2975 15.537L3.16993 15.5114C2.7804 15.4336 2.5 15.0915 2.5 14.6943V5.66201C2.5 5.41235 2.61193 5.17584 2.80501 5.01756C2.99808 4.85927 3.25195 4.79589 3.49677 4.84486V4.84486C5.58397 5.2623 7.73783 5.21104 9.80282 4.6948L10.1972 4.59621C12.2622 4.07997 14.416 4.02873 16.5032 4.44618L16.8301 4.51155C17.2196 4.58945 17.5 4.93145 17.5 5.32868V14.361C17.5 14.6106 17.3881 14.8471 17.195 15.0054C17.0019 15.1637 16.748 15.2271 16.5032 15.1781Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<circle
				cx="9.9974"
				cy="10.0003"
				r="2.08333"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.1641 12.4997H14.9974"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5 7.49967H5.83333"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	</SvgIcon>
);
