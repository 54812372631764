import React from 'react';
import { Box } from '@material-ui/core';
import { object } from 'prop-types';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useOptionLabels } from './useOptionLabels';
import { CHIP_COLORS } from 'helpers';

// Import components
import { ChipLabel } from 'components/elements/atoms';

// Import styles
import { useStyles } from './OptionLabels.styles';

export const OptionLabels = () => {
	const { labels } = useOptionLabels();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			{labels.map((label) => (
				<ChipLabel key={label} label={t(label)} color={CHIP_COLORS.GRAY} />
			))}
		</Box>
	);
};

OptionLabels.propTypes = {
	booking: object,
};
