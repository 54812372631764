import { useEffect } from 'react';

// Import utilities
import { useTranslations } from 'components/utilities';
import {
	useCustomerWalletBalanceHistory,
	useCustomerWalletBalance,
} from 'queries';

export const useCustomerWalletContent = ({ customerUuid, setCardActions }) => {
	const { t } = useTranslations();

	const {
		data: history,
		isFetched: isHistoryFetched,
		error: historyError,
		isError: isHistoryError,
	} = useCustomerWalletBalanceHistory({ customerUuid });

	const {
		data: balance,
		isFetched: isBalanceFetched,
		error: balanceError,
		isError: isBalanceError,
	} = useCustomerWalletBalance({ customerUuid });

	const getBalance = () =>
		t('customers.preview.wallet.total', {
			amount: (+balance?.amount.gross).toFixed(2),
			currency: balance?.amount.currency,
		});

	const getHistoryError = () =>
		historyError?.response?.data.error || historyError?.message;
	const getBalanceError = () =>
		balanceError?.response?.data.error || balanceError?.message;

	const isLoaded = isBalanceFetched && isHistoryFetched;

	const balanceLabel = getBalance();

	const isBalanceVisible = typeof setCardActions !== 'function';

	useEffect(() => {
		if (balance && typeof setCardActions === 'function') {
			setCardActions(balanceLabel);
		}
	}, [balance, balanceLabel, setCardActions]);

	return {
		balanceError: getBalanceError(),
		historyError: getHistoryError(),
		balance: balanceLabel,
		isBalanceError,
		isBalanceVisible,
		isHistoryError,
		isLoaded,
		history,
	};
};
