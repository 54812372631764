// Import helpers
import { BOOKING_COMPENSATION_STATES, CHIP_COLORS } from 'helpers';

export const getChipColor = (compensationStatus) => {
	switch (compensationStatus) {
		case BOOKING_COMPENSATION_STATES.COMPENSATED:
		case BOOKING_COMPENSATION_STATES.APPROVED:
			return CHIP_COLORS.SUCCESS;

		case BOOKING_COMPENSATION_STATES.REJECTED:
		case BOOKING_COMPENSATION_STATES.WAITING:
		case BOOKING_COMPENSATION_STATES.INVALID:
			return CHIP_COLORS.ERROR;

		case BOOKING_COMPENSATION_STATES.REVIEW:
			return CHIP_COLORS.WARNING;

		default:
			return CHIP_COLORS.GRAY;
	}
};
