import React from 'react';
import { useParams } from 'react-router-dom';

// Import components
import { BottomBar, PickupCitiesCard } from '../../components';
import { CompanyFilter, Grid } from 'components/elements';

export const FormContent = () => {
	const { id: companyUuidFromParams } = useParams();

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<Grid container>
					<CompanyFilter
						disabled={!!companyUuidFromParams}
						label="common.fields.company"
						name="company_uuid"
						showAll={false}
						withSearch
						isActive
						md={6}
					/>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<PickupCitiesCard />
			</Grid>
			<Grid item xs={12}>
				<BottomBar />
			</Grid>
		</Grid>
	);
};
