import React from 'react';
import { object } from 'prop-types';

// Import components
import { TableCell, Typography } from 'components/elements';

// import helpers
import { useBookingLabelsTableCell } from './useBookingLabelsTableCell';

// Import styles
import { useStyles } from './BookingLabelsTableCell.styles';

export const BookingLabelsTableCell = ({ cell }) => {
	const { partnerLabel, isAgent, corporateLabel } = useBookingLabelsTableCell({
		cell,
	});

	const classes = useStyles();

	return (
		<TableCell cell={cell} {...cell.getCellProps()}>
			{partnerLabel && (
				<Typography className={classes.label}>{partnerLabel}</Typography>
			)}
			{isAgent && <Typography className={classes.label}>A</Typography>}
			{corporateLabel && (
				<Typography className={classes.label}>{corporateLabel}</Typography>
			)}
		</TableCell>
	);
};

BookingLabelsTableCell.propTypes = {
	cell: object.isRequired,
};
