import React, { createContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

// Import utilities and helpers
import { useToggle, useTranslations } from 'components/utilities';
import {
	BOOKING_CANCELLATION_STATES,
	BOOKING_COMPENSATION_STATES,
	BOOKING_DETAILS_TABS,
	convertApiFormErrors,
	getTabIndexBookingDetails,
	MAX_URGENT_REASONS,
} from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';
import {
	assignUrgentAssistanceReason,
	changeUrgentReasonStatus,
	fetchBookingPreview,
} from 'store/actions';

// Import context
import { useMarkUrgentProvider, useTabsProvider } from 'components/context';

export const UseUrgentFormsContext = createContext(null);

export const UrgentFormProvider = ({ children }) => {
	const { t } = useTranslations();

	const { APPROVED } = BOOKING_CANCELLATION_STATES;
	const { COMPENSATION } = BOOKING_DETAILS_TABS;

	const { toggleMarkUrgent } = useMarkUrgentProvider();

	const booking = useSelector(previewBookingSelector);
	const dispatch = useDispatch();

	const { setCurrentTab } = useTabsProvider();

	const {
		urgentReasons,
		cancel_reason: cancelReasonBooking,
		uuid: bookingUuid,
	} = booking || {};

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const [assignError, setAssignError] = useState([]);
	const [markError, setMarkError] = useState(null);

	const [assignLoading, setAssignLoading] = useState(false);
	const [markAsLoading, setMarkAsLoading] = useState(false);

	const [markStatus, setMarkStatus] = useState(false);

	// Urgent assign
	const { on: isAssignConfirmOpen, toggle: toggleAssignConfirm } = useToggle();
	const { on: isMarkConfirmOpen, toggle: toggleMarkConfirm } = useToggle();
	const { on: isSuccessAssign, toggle: toggleSuccessAssign } = useToggle();
	const { on: isUrgentHistoryOpen, toggle: toggleUrgentHistory } = useToggle();
	const { on: isSuccessMark, toggle: toggleSuccessMark } = useToggle();

	// Validate cancellation
	const { on: isUpdateReasonOpen, toggle: toggleUpdateReasonOpen } =
		useToggle();
	const {
		on: isSubmitUpdateReasonClicked,
		toggle: toggleSubmitUpdateReasonClicked,
	} = useToggle();
	const { on: isApproveRejectionOpen, toggle: toggleApproveRejection } =
		useToggle();

	const submitAssignUrgent = async ({ urgentReasonId }) => {
		setAssignLoading(true);
		try {
			const options = {
				bookingId: bookingUuid,
				urgentReasonId,
			};

			await assignUrgentAssistanceReason(options);
			toggleAssignConfirm();
			toggleSuccessAssign();
		} catch (error) {
			setAssignError(convertApiFormErrors(error.data.errors));
		}
		setAssignLoading(false);
	};

	const handleCloseSuccess = async (toggle) => {
		toggle();
		try {
			const cancelToken = axios.CancelToken.source().token;
			await fetchBookingPreview({ id: bookingUuid, cancelToken })(dispatch);
		} catch (error) {
			setAssignError(convertApiFormErrors(error.data.errors));
		}
	};

	const isMaxUrgentReasonsSize = urgentReasons?.length === MAX_URGENT_REASONS;

	const isSomeUrgentReason = !!urgentReasons?.length;
	const isSomeReviewStatus = !!urgentReasons?.filter(
		({ status }) => status === BOOKING_COMPENSATION_STATES.REVIEW
	)?.length;

	const markUrgentAsSolvedOrUnsolved = async (status) => {
		setMarkAsLoading(true);
		try {
			await changeUrgentReasonStatus({
				bookingId: bookingUuid,
				status,
				cancelToken,
			});

			toggleMarkConfirm();
			toggleSuccessMark();
		} catch (e) {
			setMarkError(e);
		}
		setMarkAsLoading(false);
	};

	const handleSolveAndUnSolveClick = (status) => {
		toggleMarkConfirm();
		setMarkStatus(status);
	};

	useEffect(() => {
		isMarkConfirmOpen && toggleMarkUrgent();
		// eslint-disable-next-line
	}, [isMarkConfirmOpen]);

	const goToCompensationTab = () => {
		toggleMarkUrgent();
		setCurrentTab(getTabIndexBookingDetails(COMPENSATION));
	};

	const updateReasonTitle = isSubmitUpdateReasonClicked
		? t('bookings.validate_cancellation.confirm_update_cancel_reason_title')
		: t('bookings.validate_cancellation.update_cancel_reason_title');

	const isCancelReasonApproved = cancelReasonBooking?.status === APPROVED;

	return (
		<UseUrgentFormsContext.Provider
			value={{
				isAssignConfirmOpen,
				toggleAssignConfirm,
				isSuccessAssign,
				toggleSuccessAssign,
				submitAssignUrgent,
				assignError,
				assignLoading,
				handleCloseSuccess,
				isSomeUrgentReason,
				isSomeReviewStatus,
				urgentReasons,
				isMaxUrgentReasonsSize,
				isUrgentHistoryOpen,
				toggleUrgentHistory,
				markAsLoading,
				markUrgentAsSolvedOrUnsolved,
				markError,
				isMarkConfirmOpen,
				toggleMarkConfirm,
				markStatus,
				handleSolveAndUnSolveClick,
				isSuccessMark,
				toggleSuccessMark,
				goToCompensationTab,
				isUpdateReasonOpen,
				toggleUpdateReasonOpen,
				updateReasonTitle,
				toggleSubmitUpdateReasonClicked,
				isSubmitUpdateReasonClicked,
				cancelReasonBooking,
				isCancelReasonApproved,
				isApproveRejectionOpen,
				toggleApproveRejection,
				bookingUuid,
			}}
		>
			{children}
		</UseUrgentFormsContext.Provider>
	);
};

UrgentFormProvider.propTypes = {
	children: node,
};
