import React from 'react';

// Import store
import { fetchScheduledBookings } from 'store/actions';

// Import utils and hepers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { toolbarContent } from './helpers';

// Import components
import {
	InnerNavbarElements,
	TableBodyRow,
	Filters,
	Export,
} from './components';

export const ScheduledBookings = () => (
	<TableRPC
		columnsAccessor={COLUMNS_ACCESSORS}
		columns={columns}
		tableBodyRow={TableBodyRow}
		fetchDataTableAction={fetchScheduledBookings}
		title="nav.customer_care.scheduled_booking"
		outerToolbarElements={<Export />}
		filtersBar={<Filters />}
		tableNavigation={<InnerNavbarElements />}
		toolBarContent={toolbarContent}
	/>
);
