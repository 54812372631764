import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useExternalPartnerCars = (officeUuid, enabled) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CARS.EXTERNAL_PARTNERS_CARS(officeUuid),
		async () =>
			await services.get(ENDPOINTS.CARS.EXTERNAL_PARTNERS_CARS({ officeUuid })),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : {};

	return { data: fetchedData, isFetching, isError };
};
