import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	tableCell: {
		minWidth: '160px',
	},
	text: {
		fontSize: '12px',
		fontWeight: 400,
		color: theme.palette.text[4],
	},
	icon: {
		width: '16px',
		height: '16px',
		color: theme.palette.text[3],
	},
	bottomBox: {
		display: 'flex',
		gap: '8px',
		alignItems: 'center',
	},
	oneLine: {
		display: 'flex',
		gap: '8px',
	},
}));
