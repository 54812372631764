import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { object, string } from 'prop-types';

// Import components
import { PreviewField } from 'components/elements';

// Import assets
import { PhoneNumberIcon } from 'assets/icons';

// Import styles
import { useStyles } from './PhonePreviewField.styles';

export const PhonePreviewField = ({ customData, label }) => {
	const classes = useStyles();

	const { phoneNumber } = customData || {};

	return (
		<PreviewField label={label}>
			<a target="_top" href={`tel:${phoneNumber}`}>
				<Grid container spacing={1} alignItems="center">
					<Grid item>
						<PhoneNumberIcon color="primary" className={classes.icon} />
					</Grid>
					<Grid item>
						<Typography
							variant="body1"
							color="primary"
							className={classes.phone}
						>
							{phoneNumber}
						</Typography>
					</Grid>
				</Grid>
			</a>
		</PreviewField>
	);
};

PhonePreviewField.propTypes = {
	customData: object,
	label: string,
};
