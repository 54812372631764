import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	dropoffDate: {
		fontSize: 14,
		fontWeight: 600,
	},
	date: {
		fontSize: 14,
		color: theme.palette.text[2],
	},
	dropoffDateContainer: {
		margin: theme.spacing(2, 0),
	},
	dateChanged: {
		backgroundColor: theme.palette.primary.medium,
		color: theme.palette.primary.main,
		padding: '6px 12px',
		borderRadius: 8,
		fontWeight: 600,
		width: 'fit-content',
	},
	clearButton: {
		color: theme.palette.text[2],
	},
	historyButton: {
		borderColor: theme.palette.background[3],
		padding: '12px 24px',
		minWidth: 'fit-content',
	},
	actionsContainer: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
	},
	title: {
		fontSize: 18,
		fontWeight: 500,
		margin: theme.spacing(2, 1, 4, 1),
	},
	container: {
		scrollMarginTop: '150px',
	},
}));
