import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		marginBottom: '48px',
	},
	label: {
		fontSize: '12px',
		fontWeight: 600,
		color: theme.palette.text[4],
		fontFamily: 'inter',
	},
	value: {
		fontSize: '14px',
		fontWeight: 400,
		color: theme.palette.text[2],
		fontFamily: 'inter',
	},
}));
