import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useCancellationReasons = (enabled = true) => {
	const { data, isFetched, isLoading } = useQuery(
		QUERY_KEYS.CANCELLATIONS.REASONS,
		async () => await services.get(ENDPOINTS.CANCELLATION.CANCEL_REASONS),
		{ refetchOnWindowFocus: false, enabled, staleTime: 60 * 60 * 1000 }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetched, isLoading };
};

export default useCancellationReasons;
