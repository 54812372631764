import React from 'react';
import { bool } from 'prop-types';

// Import helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { TableFiltersToolbar } from 'components/elements';
import { AdditionalFilterFields } from './components';
import { FilterFields } from '../../components';

export const Filters = ({ isExtended }) => (
	<TableFiltersToolbar
		initialValues={initialValues}
		formatValues={formatValues}
		dialogFilters={<FilterFields isExtended={isExtended} />}
		prevAdditionalFilters={<AdditionalFilterFields />}
	/>
);

Filters.propTypes = {
	isExtended: bool,
};
