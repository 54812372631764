import { useFormState } from 'react-final-form';

// Import utilities
import { useCustomerWalletReasons } from 'queries';

// import helpers
import { DEFAULT_REASON_UUID } from 'helpers';

export const useFormContent = () => {
	const { data: reasons, isError } = useCustomerWalletReasons();
	const { values } = useFormState();

	const isDescriptionInputVisible = values.reason_uuid === DEFAULT_REASON_UUID;

	return {
		reasons,
		isDescriptionInputVisible,
		isError,
	};
};
