import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	loadingBox: {
		display: 'flex',
		justifyContent: 'center',
		width: '100%',
		padding: '24px 0',
	},
	fileUploaderButton: {
		height: '56px',
		backgroundColor: theme.palette.background[6],
		color: theme.palette.text[3],
		'&:hover': {
			backgroundColor: theme.palette.background[3],
		},
	},
}));
