import React from 'react';
import { any, bool, func, object } from 'prop-types';

// Import utilities and helpers
import {
	BookingStatusActionsProvider,
	useTableContextProvider,
} from 'components/context';

// Import components
import { Cell } from './components';

// Import styles
import { TableRow } from 'components/elements';

export const TableBodyRow = (props) => {
	const { fetchData } = useTableContextProvider();
	const { row, isHovered } = props;

	return (
		<TableRow {...props}>
			{row.cells.map((cell) => (
				<BookingStatusActionsProvider
					booking={cell.row.original}
					onChange={fetchData}
					key={cell.column.id}
				>
					<Cell cell={cell} isHovered={isHovered} />
				</BookingStatusActionsProvider>
			))}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
