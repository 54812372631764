import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeInput, ServiceTypeFilter } from 'components/elements';

export const PrevAdditionalFilterFields = () => (
	<>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
				isToggleButton
			/>
		</Grid>
		<ServiceTypeFilter
			name="service_type"
			xs="auto"
			md="auto"
			smallFieldStyle
			withSearch
			allLabel="common.fields.service_type"
		/>
	</>
);
