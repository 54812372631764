import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TriangleRoundedDownArrowIcon = (props) => (
	<SvgIcon width="12" height="13" viewBox="0 0 12 13" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.50051 5L6.00001 7.5005L3.49951 5"
			fill="currentColor"
		/>
		<path
			d="M8.50051 5L6.00001 7.5005L3.49951 5L8.50051 5Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
