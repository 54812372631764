import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useEditBookingHistory = ({ uuid, enabled = true }) => {
	const { data, isFetching, isError, error, isLoading } = useQuery(
		QUERY_KEYS.BOOKINGS.EDIT_HISTORY(uuid),
		async () =>
			await services.get(ENDPOINTS.BOOKINGS.EDIT_BOOKING_HISTORY({ uuid })),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError, error, isLoading };
};

export default useEditBookingHistory;
