import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import {
	SelectFieldAdapter,
	SelectFieldWithSearchAdapter,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useCompanies } from 'queries';

// Import helpers
import { sortByName } from '../helpers';

const CompanyFilter = ({
	label = 'bookings.table.headers.company',
	name,
	xs = 3,
	md = 3,
	showFetching = false,
	showAll = true,
	isActive = false,
	required = false,
	smallFieldStyle = false,
	withSearch = false,
	showEmptyLabel = false,
	disabled = false,
	...rest
}) => {
	const { submitting } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data, isFetching } = useCompanies(isActive);

	const isDisabled = submitting || disabled;

	if (!data) {
		return null;
	}

	const convertCompanies = () => {
		const sortedCompanies = sortByName(data, language);
		return [
			...(showAll ? [{ uuid: 'all', name: 'common.all' }] : []),
			...sortedCompanies,
		];
	};

	const convertedCompanies = convertCompanies();

	return (
		<Grid item md={md} xs={xs}>
			{isFetching && showFetching ? (
				<CircularProgress size={28} />
			) : (
				<Field
					fullWidth
					component={
						withSearch ? SelectFieldWithSearchAdapter : SelectFieldAdapter
					}
					label={t(label)}
					name={name}
					variant="outlined"
					margin="dense"
					disabled={isDisabled}
					required={required}
					smallfieldstyle={smallFieldStyle}
					emptyLabel={showEmptyLabel ? t('common.company') : ''}
					{...rest}
				>
					{convertedCompanies.map(({ uuid, name }) => (
						<MenuItem key={uuid} value={uuid}>
							{uuid === 'all' ? t(name) : name[language]}
						</MenuItem>
					))}
				</Field>
			)}
		</Grid>
	);
};

CompanyFilter.propTypes = {
	name: string.isRequired,
	label: string,
	smallFieldStyle: bool,
	showFetching: bool,
	withSearch: bool,
	isActive: bool,
	required: bool,
	showAll: bool,
	md: oneOfType([number, string]),
	xs: oneOfType([number, string]),
	showEmptyLabel: bool,
	disabled: bool,
};

export default CompanyFilter;
