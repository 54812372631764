import React from 'react';
import { bool, number } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { EMPTY_VALUE } from 'helpers';

export const SubscriptionPeriodDuration = ({
	periodDuration,
	hasEarlyReturnState,
	oldSubscriptionPeriod,
	newSubscriptionPeriod,
}) => {
	const { t } = useTranslations();

	return (
		<TableCell>
			{hasEarlyReturnState ? (
				<Grid container>
					<Grid item xs={12}>
						<Typography>
							{t('common.previous')}: {oldSubscriptionPeriod || EMPTY_VALUE}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography>
							{t('common.new')}: {newSubscriptionPeriod || EMPTY_VALUE}
						</Typography>
					</Grid>
				</Grid>
			) : (
				<Typography>{periodDuration}</Typography>
			)}
		</TableCell>
	);
};

SubscriptionPeriodDuration.propTypes = {
	oldSubscriptionPeriod: number,
	newSubscriptionPeriod: number,
	periodDuration: number,
	hasEarlyReturnState: bool.isRequired,
};
