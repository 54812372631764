import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	reasonLabel: {
		fontSize: 14,
	},
	assignedByLabel: {
		fontWeight: 400,
		fontSize: 12,
		color: theme.palette.text[2],
	},
}));
