import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'flex',
		flexDirection: 'column',
		padding: '8px',
		gap: '16px',
	},
}));
