// Import helpers
import { DRIVER } from 'helpers';

export const INITIAL_VALUES = (companyUuid) => ({
	details: {
		full_name: '',
		email: '',
		phone_prefix: '',
		phone: '',
	},
	license_expiry_date: '',
	id_number: '',
	company_uuid: companyUuid,
	offices_uuid: [],
	password: '',
	password_confirmation: '',
});

export const getConvertedUserData = (values) => ({
	...values,
	details: {
		...values.details,
		role: DRIVER,
	},
});
