import React from 'react';
import { object } from 'prop-types';
import { Chip, TableCell } from '@material-ui/core';

// Import styles
import { useStyles } from './StatusChip.styles';

// Import utils
import { useTranslations } from 'components/utilities';
import clsx from 'clsx';

export const StatusChip = ({ cell }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { cancel_reason_status: cancelReasonStatus } = cell.row.original || {};

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Chip
				label={t(`common.filters.${cancelReasonStatus}`)}
				className={clsx([classes[cancelReasonStatus || null]], classes.chip)}
			/>
		</TableCell>
	);
};

StatusChip.propTypes = {
	cell: object,
};
