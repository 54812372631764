import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { bool, string } from 'prop-types';

// Import components
import { DriverDialog, PreviewField } from 'components/elements';

// Import styles
import { useStyles } from './AssignDriver.styles';

export const AssignDriver = ({
	driverName = '',
	deliveryType,
	label,
	hideButton = false,
}) => {
	const classes = useStyles();

	return (
		<PreviewField label={label}>
			<Grid container spacing={1} alignItems="center">
				<Grid item>
					<Typography className={classes.valueText} variant="body1">
						{driverName?.toUpperCase()}
					</Typography>
				</Grid>
				<Grid item>
					<DriverDialog
						deliveryType={deliveryType}
						buttonClassName={classes.assignButton}
						buttonVariant="outlined"
						hideButton={hideButton}
					/>
				</Grid>
			</Grid>
		</PreviewField>
	);
};

AssignDriver.propTypes = {
	driverName: string,
	deliveryType: string.isRequired,
	label: string.isRequired,
	hideButton: bool,
};
