import React from 'react';

// Import helpers and utils
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';

// Import components
import {
	AdditionalInfo,
	Filters,
	TableBodyRow,
	OuterToolbarElements,
} from '../components';
import { InnerNavbarElements } from 'components/elements';

// Import store
import { fetchBookings } from 'store/actions';

export const BookingsList = () => (
	<TableRPC
		columnsAccessor={COLUMNS_ACCESSORS}
		columns={columns}
		tableBodyRow={TableBodyRow}
		fetchDataTableAction={fetchBookings}
		title="nav.bookings_list"
		outerToolbarElements={<OuterToolbarElements />}
		filtersBar={<Filters />}
		tableNavigation={<InnerNavbarElements />}
		additionalNavigationElements={
			<AdditionalInfo
				showAssignedDriverAdditional
				showDeliveryStatusAdditional
			/>
		}
	/>
);
