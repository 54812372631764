import React from 'react';
import { Box, Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { array } from 'prop-types';

// Import components
import {
	ActionConfirmDialog,
	ButtonProgress,
	ClosableDialogTitle,
	SuccessDialog,
	UrgentStatuses,
} from 'components/elements';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { BOOKING_URGENT_STATES } from 'helpers';
import { useMarkUrgentProvider } from 'components/context';

// Import styles
import { useStyles } from './MarkUrgentDialog.styles';

export const MarkUrgentDialog = ({ urgentReasons }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		markStatus,
		isMarkConfirmOpen,
		handleSolveAndUnSolveClick,
		markUrgentAsSolvedOrUnsolved,
		markAsLoading,
		isSuccessMark,
		handleCloseSuccess,
		toggleMarkConfirm,
		toggleSuccessMark,
		goToCompensationTab,
		toggleMarkUrgent,
		isMarkUrgentOpen,
	} = useMarkUrgentProvider();

	return (
		<>
			<Dialog
				open={isMarkUrgentOpen}
				onClose={toggleMarkUrgent}
				maxWidth="sm"
				fullWidth
			>
				<ClosableDialogTitle
					title={t('bookings.urgent_tab.mark_urgent')}
					onClose={toggleMarkUrgent}
				/>
				<DialogContent>
					<Box className={classes.statusBox}>
						<UrgentStatuses urgentReasons={urgentReasons} />
					</Box>
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						className={classes.buttonsContainer}
					>
						<Grid item>
							<Button
								onClick={goToCompensationTab}
								color="default"
								className={clsx([classes.button])}
							>
								{t('common.buttons.compensations')}
							</Button>
						</Grid>
						<Grid item>
							<Grid container spacing={2}>
								<Grid item>
									<Button
										onClick={() =>
											handleSolveAndUnSolveClick(BOOKING_URGENT_STATES.UNSOLVED)
										}
										color="default"
										className={clsx([classes.button, classes.unsolvedButton])}
									>
										{t('common.buttons.unsolved')}
									</Button>
								</Grid>
								<Grid item>
									<ButtonProgress
										color="primary"
										variant="outlined"
										className={clsx([classes.button, classes.solvedButton])}
										onClick={() =>
											handleSolveAndUnSolveClick(BOOKING_URGENT_STATES.SOLVED)
										}
									>
										{t('common.buttons.solved')}
									</ButtonProgress>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>

			<ActionConfirmDialog
				isOpen={isMarkConfirmOpen}
				toggle={toggleMarkConfirm}
				title={t('bookings.urgent_tab.confirm_marking_as_title', {
					status: markStatus,
				})}
				description={t('bookings.urgent_tab.confirm_marking_as_desc', {
					status: markStatus,
				})}
				actionButtonText={`common.buttons.mark_as_${markStatus}`}
				isLoading={markAsLoading}
				confirmAction={() => markUrgentAsSolvedOrUnsolved(markStatus)}
				actionButtonClassName={clsx({
					[classes.unsolvedButton]:
						markStatus === BOOKING_URGENT_STATES.UNSOLVED,
				})}
			/>

			<SuccessDialog
				isOpen={isSuccessMark}
				toggle={toggleSuccessMark}
				handleOk={() => handleCloseSuccess(toggleSuccessMark)}
				title={t('bookings.urgent_tab.urgent_marked_as_title', {
					status: markStatus,
				})}
				description={t('bookings.urgent_tab.urgent_marked_as_desc', {
					status: markStatus,
				})}
			/>
		</>
	);
};

MarkUrgentDialog.propTypes = {
	urgentReasons: array,
};
