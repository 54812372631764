import React from 'react';
import { bool, object } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import images
import { calendar } from 'assets/images';

// Import styles
import { useStyles } from './styles';

// Import utilities
import { useTranslations } from 'components/utilities';
import { BOOKING_EXTENSION_STATUS } from 'helpers';

export const DropDateTableCell = ({ cell, withTime = false }) => {
	const { drop_date, extension_state, pick_time } = cell.row.original;

	const isExtended = extension_state === BOOKING_EXTENSION_STATUS.ACCEPTED;

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			className={classes.dateColumn}
			{...cell.getCellProps()}
		>
			<Grid container spacing={1}>
				{isExtended && (
					<Grid item xs={2}>
						<img
							src={calendar}
							className={classes.smallImage}
							alt={t('bookings.table.common.calendar')}
						/>
					</Grid>
				)}
				<Grid container direction="column">
					<Grid item>
						<Typography noWrap>{drop_date}</Typography>
					</Grid>
					{withTime && (
						<Grid item>
							<Typography noWrap>{pick_time}</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</TableCell>
	);
};

DropDateTableCell.propTypes = {
	cell: object.isRequired,
	withTime: bool,
};
