import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useModelYears = ({ modelUuid }) => {
	const body = {
		vehicle_model: {
			uuid: modelUuid,
		},
	};

	const { data, isFetching } = useQuery(
		QUERY_KEYS.CARS.MODEL_YEARS(JSON.stringify(body)),
		async () => await services.post(ENDPOINTS.CARS.MODEL_YEARS, body),
		{ refetchOnWindowFocus: false, enabled: !!modelUuid }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
