import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	reasonTitle: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(1),
	},
	reason: {
		fontSize: 16,
		color: theme.palette.text[2],
	},
	validation: {
		color: theme.palette.text[2],
		fontSize: 14,
	},
}));
