import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const EXTENSION_FIELDS = [
	{
		accessor: 'extension.extendedAt',
		label: 'common.fields.extended_at',
	},
	{
		accessor: 'extension.amount',
		label: 'common.fields.amount',
	},
	{
		accessor: 'extension.numberOfDays',
		label: 'common.fields.number_of_days',
	},
	{
		accessor: 'extension.numberOfExtensions',
		label: 'common.fields.number_of_extensions',
	},
];

export const getExtensionCustomData = (booking) => {
	const {
		extension_gross_amount: amount,
		extension_no_days: numberOfExtensions,
		number_of_days: numberOfDays,
		extension,
	} = booking || {};

	const { extension_request_date: extensionRequestDate } = extension || {};

	return {
		extension: {
			extendedAt: format(new Date(extensionRequestDate), DEFAULT_DATE_FORMAT),
			amount,
			numberOfDays,
			numberOfExtensions,
		},
	};
};
