import { useDispatch } from 'react-redux';

// Import helpers and actions
import {
	setBookingPreviewItem,
	setValidationTableChangedState,
} from 'store/actions';

export const useExtraActions = ({ cell, toggleCompensationDialog }) => {
	const dispatch = useDispatch();

	const handleOnOpenCompensationModal = () => {
		const booking = cell.row.original;
		setBookingPreviewItem({ booking, dispatch });

		setValidationTableChangedState({ isTableChanged: false, dispatch });

		toggleCompensationDialog();
	};

	return { handleOnOpenCompensationModal };
};
