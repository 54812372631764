import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	clearIconButton: {
		border: '1px solid',
		borderColor: theme.palette.background[3],
		borderRadius: '8px',
		width: '38px',
		height: '36.5px',
	},
	icon: {
		width: '24px',
		height: '24px',
	},
	clearButton: {
		fontSize: '14px',
		fontWeight: 600,

		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
}));
