import React from 'react';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

//Import components
import { BottomActions, FormContent } from './components';

// Import styles
import { useStyles } from './CreateBooking.styles';

// Import utilities and helpers
import { INITIAL_VALUES, handleSubmit } from './helpers';
import { useMessage } from 'components/utilities';

export const CreateBooking = () => {
	const classes = useStyles();
	const history = useHistory();
	const { message } = useMessage();

	return (
		<Form
			onSubmit={(values) => handleSubmit({ values, history, message })}
			initialValues={INITIAL_VALUES}
			render={({ handleSubmit }) => (
				<Box
					component="form"
					onSubmit={handleSubmit}
					className={classes.container}
				>
					<FormContent />
					<BottomActions />
				</Box>
			)}
		/>
	);
};
