import { t } from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const options = [
	{
		value: true,
		label: 'common.yes',
	},
	{
		value: false,
		label: 'common.no',
	},
];

export const STATION_FIELDS = ({ cities, isCityFetching }) => [
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.name_en',
		name: 'name_en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'common.fields.name_ar',
		name: 'name_ar',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.active',
		name: 'active',
		required: true,
		md: 6,
		options,
		keyExtractor: ({ value }) => value,
		labelExtractor: ({ label }) => t(label),
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'common.fields.city',
		name: 'city_uuid',
		required: true,
		md: 6,
		options: cities,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name,
		isLoading: isCityFetching,
	},
];
