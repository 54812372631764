import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

// Import components
import { ClosableDialogTitle, Steps } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';
import { useUrgentHistoryDialog } from './useUrgentHistoryDialog';

// Import context
import { useUrgentFormsProvider } from '../../../../context';

export const UrgentHistoryDialog = () => {
	const { t } = useTranslations();
	const { isUrgentHistoryOpen, toggleUrgentHistory } = useUrgentFormsProvider();

	const { steps } = useUrgentHistoryDialog();

	return (
		<Dialog
			open={isUrgentHistoryOpen}
			onClose={toggleUrgentHistory}
			maxWidth="sm"
			fullWidth
		>
			<ClosableDialogTitle
				title={t('common.buttons.urgent_history')}
				onClose={toggleUrgentHistory}
			/>
			<DialogContent>
				<Steps steps={steps} />
			</DialogContent>
		</Dialog>
	);
};
