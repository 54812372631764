import { pickBy } from 'lodash';

// Import helpers
import {
	BOOKING_INVOICE_STATUS,
	CHIP_COLORS,
	reverseAndSplitString,
} from 'helpers';

export const getInvoiceStatusChipColor = (status) => {
	switch (status) {
		case BOOKING_INVOICE_STATUS.CANCELED:
			return CHIP_COLORS.ERROR;

		default:
			return CHIP_COLORS.SUCCESS;
	}
};

const items = {
	insurance: {
		metadata: {
			type: 'insurance',
		},
		quantity: 6,
		sum_price: {
			gross: 'SAR 117.30',
			nett: 'SAR 102.00',
		},
		type: 'car_option',
		fee_type: 'per_period_unit',
		period_unit: 'day',
		unit_price: {
			gross: 'SAR 19.55',
			nett: 'SAR 17.00',
			rate: 15,
		},
	},
	child_seat: {
		metadata: {
			type: 'child_seat',
		},
		quantity: 6,
		sum_price: {
			gross: 'SAR 903.90',
			nett: 'SAR 786.00',
		},
		type: 'car_option',
		fee_type: 'per_period_unit',
		period_unit: 'day',
		unit_price: {
			gross: 'SAR 150.65',
			nett: 'SAR 131.00',
			rate: 15,
		},
	},
	chauffeur: {
		metadata: {
			type: 'chauffeur',
		},
		quantity: 6,
		sum_price: {
			gross: 'SAR 1,876.80',
			nett: 'SAR 1,632.00',
		},
		type: 'car_option',
		fee_type: 'per_period_unit',
		period_unit: 'day',
		unit_price: {
			gross: 'SAR 312.80',
			nett: 'SAR 272.00',
			rate: 15,
		},
	},
	no_smoking_car: {
		metadata: {
			type: 'no_smoking_car',
		},
		quantity: 6,
		sum_price: {
			gross: 'SAR 96.60',
			nett: 'SAR 84.00',
		},
		type: 'car_option',
		fee_type: 'per_period_unit',
		period_unit: 'day',
		unit_price: {
			gross: 'SAR 16.10',
			nett: 'SAR 14.00',
			rate: 15,
		},
	},
	pickup_delivery: {
		metadata: {
			delivery_distance: 6.63,
			delivery_latitude: 24.7135517,
			delivery_longitude: 46.6752957,
			delivery_address: 'At Taawun, Riyadh Saudi Arabia',
			type: 'pickup_delivery',
		},
		quantity: 1,
		sum_price: {
			gross: 'SAR 69.00',
			nett: 'SAR 60.00',
		},
		type: 'car_option',
		fee_type: 'per_period',
		period_unit: 'day',
		unit_price: {
			gross: 'SAR 69.00',
			nett: 'SAR 60.00',
			rate: 15,
		},
	},
	booking: {
		metadata: {
			car_uuid: '85e43f29-d044-46e0-9bdb-d66adf27da7b',
			pickup_datetime: '2024-12-01 12:00:00',
			drop_date: '2024-12-07',
		},
		quantity: 6,
		sum_price: {
			gross: 'SAR 16,077.00',
			nett: 'SAR 13,980.00',
		},
		type: 'booking',
		fee_type: 'per_period_unit',
		period_unit: 'day',
		unit_price: {
			gross: 'SAR 2,679.50',
			nett: 'SAR 2,330.00',
			rate: 15,
		},
	},
};

export const getAdditionalServices = () =>
	Object.values(pickBy(items, ({ type }) => type === 'car_option')).map(
		({ metadata, sum_price }) => ({
			type: metadata.type,
			price: reverseAndSplitString({ string: sum_price.nett })?.[1],
		})
	);
