import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	primary: {
		color: theme.palette.primary.main,
	},

	error: {
		color: theme.palette.error.medium,
	},
	icon: {
		width: '20px',
		height: '20px',
		marginTop: theme.spacing(1),
	},
	text: {
		fontSize: '14px',
		fontWeight: 600,
	},
}));
