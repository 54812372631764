import * as types from '../../types';

export const setCompanyContext = (dispatch, companyUuid) => {
	dispatch({
		type: types.SET_COMPANY_UUID_CONTEXT,
		payload: companyUuid,
	});
};

export const setOfficeContext = (dispatch, officeUuid) => {
	dispatch({
		type: types.SET_OFFICE_UUID_CONTEXT,
		payload: officeUuid,
	});
};
export const setVehicleContext = (dispatch, vehicleUuid) => {
	dispatch({
		type: types.SET_VEHICLE_UUID_CONTEXT,
		payload: vehicleUuid,
	});
};
