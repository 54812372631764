import { useFormState } from 'react-final-form';
import { bool, number, oneOfType, string } from 'prop-types';

// Import utilities and helpers
import { useCommonFields } from 'components/utilities';
import { multiSelectLabelExtractor } from './helpers';
import { usePartnersCollection } from 'queries';
import { FIELD_TYPES } from 'helpers';

export const PartnersFilter = ({
	label = '',
	name,
	smallFieldStyle = false,
	xs,
	md,
}) => {
	const { data: partners } = usePartnersCollection();
	const { renderFields } = useCommonFields();
	const { values } = useFormState();

	if (!partners.length) return null;

	const formattedPartners = partners.map((partner) => Object.keys(partner)[0]);

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		currentMultiSelectValues: values?.[name],
		labelExtractor: (partner) => partner,
		keyExtractor: (partner) => partner,
		searchExtractor: 'children[1]',
		multiSelectLabelExtractor,
		showAllOption: true,
		options: formattedPartners,
		smallFieldStyle,
		label,
		name,
		xs,
		md,
	});
};

PartnersFilter.propTypes = {
	label: string,
	name: string.isRequired,
	smallFieldStyle: oneOfType([bool, string]),
	md: oneOfType([string, number]),
	xs: oneOfType([string, number]),
};
