import React from 'react';
import { bool, func } from 'prop-types';
import { Box, IconButton } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';

// Import styles
import { useStyles } from './CustomArrows.styles';

export const CustomArrows = ({ onClick, isNext, disabled, callback }) => {
	const classes = useStyles();

	const handleOnClick = () => {
		onClick();
		callback();
	};

	return (
		<Box className={classes.wrapper}>
			<IconButton
				disabled={disabled}
				onClick={handleOnClick}
				className={clsx([
					classes.arrowButton,
					disabled ? classes.disabled : {},
					isNext ? classes.arrowNext : classes.arrowPrev,
				])}
			>
				{isNext ? (
					<ArrowForwardIosIcon className={classes.arrowIcon} />
				) : (
					<ArrowBackIosIcon className={classes.arrowIcon} />
				)}
			</IconButton>
		</Box>
	);
};

CustomArrows.propTypes = {
	onClick: func,
	isNext: bool,
	disabled: bool,
	callback: func,
};

CustomArrows.defaultProps = {
	onClick: () => {},
	isNext: false,
	disabled: false,
	callback: () => {},
};
