import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	labelClassName: {
		fontSize: '16px',
		fontWeight: 'bold',
	},
	accessorClassName: {
		fontWeight: 'bold',
	},
}));
