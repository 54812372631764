import React from 'react';

// Import store
import { fetchLeasingCars } from 'store/actions';

// Import components
import { OuterToolbarElements, TableBodyRow } from './components';

// Import utils and helpers
import { TableActionsProvider } from './context';
import { TableRPC } from 'components/utilities';
import { columns, COLUMNS_ACCESSORS, customColumnsSettings } from './columns';

export const LeasingCars = () => {
	return (
		<TableActionsProvider>
			<TableRPC
				fetchDataTableAction={fetchLeasingCars}
				title="nav.companies.cars.leasing_cars"
				columnsAccessor={COLUMNS_ACCESSORS}
				tableBodyRow={TableBodyRow}
				columns={columns}
				customColumnsSettings={customColumnsSettings}
				isPaginationVisible={false}
				isDataTable={false}
				outerToolbarElements={<OuterToolbarElements />}
				stopFilteringUpdates={true}
			/>
		</TableActionsProvider>
	);
};
