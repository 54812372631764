// Import helpers
import { FIELD_TYPES } from 'helpers';

const multiSelectLabelExtractor = (options, currentUuid) =>
	options.find(({ uuid }) => uuid === currentUuid)?.value;

export const FIELDS = ({
	companies,
	selectedCompanies,
	selectedOffices,
	offices,
	isOfficesSelectDisabled,
	onCompanySelectClose,
	isLoading,
}) => [
	{
		type: FIELD_TYPES.MULTI_SELECT,
		label: 'campaigns.form.supplier.company',
		name: 'condition.supplier.company',
		options: companies,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ value }) => value,
		md: 4,
		currentMultiSelectValues: selectedCompanies,
		multiSelectLabelExtractor,
		onClose: onCompanySelectClose,
		isLoading,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT,
		label: 'campaigns.form.supplier.office',
		name: 'condition.supplier.office',
		options: offices,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ value }) => value,
		md: 4,
		currentMultiSelectValues: selectedOffices,
		multiSelectLabelExtractor,
		disabled: isOfficesSelectDisabled,
		isLoading,
	},
];
