import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	balanceWrapper: {
		margin: '16px 0',
	},
	loadingWrapper: {
		padding: '16px 0 32px',
	},
}));
