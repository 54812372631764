import React from 'react';

// Import components
import { Grid } from 'components/elements';

// Import utilities and helpers
import { useCommonFields } from 'components/utilities';
import { FIELDS } from './helpers';

export const SelectOffice = () => {
	const { renderFields } = useCommonFields();

	return (
		<Grid container spacing={3}>
			{FIELDS.map(renderFields)}
		</Grid>
	);
};
