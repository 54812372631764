import { makeStyles } from '@material-ui/styles';

export const useStyles = ({ isSelected, preview }) =>
	makeStyles((theme) => ({
		container: {
			border: 'solid 1px',
			borderColor: isSelected ? 'transparent' : theme.palette.background[8],
			borderRadius: '12px',
			minWidth: '370px',
			cursor: 'pointer',
			height: 'fit-content',
			'&:hover': {
				borderColor: !preview && 'transparent',
			},
		},
		border: {
			border: 'solid 3px',
			borderColor: isSelected ? theme.palette.primary.main : 'transparent',
			padding: '16px',
			borderRadius: '12px',
			width: '100%',
			'&:hover': {
				borderColor: !preview && theme.palette.primary.main,
			},
		},
		oldPrice: {
			fontSize: '14px',
			fontWeight: 510,
			color: theme.palette.text[1],
			textDecoration: 'line-through',
		},
		price: {
			color: theme.palette.primary.main,
			fontSize: '20px',
			fontWeight: 500,
			'& span': {
				fontSize: '14px',
				color: theme.palette.primary.main,
				fontWeight: 500,
			},
		},
		carImgContainer: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			margin: '24px 0px',
		},
		divider: {
			marginLeft: '-16px',
			marginRight: '-16px',
		},
		bottomIcon: {
			width: '16px',
			color: theme.palette.gray[1],
		},
		text: {
			fontSize: '14px',
			fontWeight: 400,
		},
	}));
