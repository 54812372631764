import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EditColumnIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M21 11V6C21 4.34315 19.6569 3 18 3H6C4.34315 3 3 4.34315 3 6V18C3 19.6569 4.34315 21 6 21H11"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18.2931 20.707L21.7072 17.2929C22.0977 16.9024 22.0977 16.2694 21.7072 15.8789L20.1212 14.2928C19.7307 13.9024 19.0976 13.9024 18.7072 14.2928L15.293 17.7069C15.1054 17.8945 15 18.149 15 18.4143V20.5C15 20.7762 15.2239 21 15.5 21H17.5857C17.851 21 18.1055 20.8946 18.2931 20.707Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15 3V12"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M9 3V21"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
