import React from 'react';
import { Button } from '@material-ui/core';
import clsx from 'clsx';
import { arrayOf, bool, oneOfType, shape, string } from 'prop-types';

// Import styles
import { useStyles } from './InnerNavbarElementsButtons.styles';

//Import utils
import { useTableContextProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import helpers
import { handleChangeStatus, isButtonActive, BUTTONS } from './helpers';

// Import components
import { Grid } from 'components/elements';

export const InnerNavbarElementsButtons = ({
	filterPath,
	buttons = BUTTONS,
	defaultFilterState = null,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { setFilters, filters } = useTableContextProvider();

	return (
		<Grid container spacing={3}>
			{buttons.map(({ label, status }) => (
				<Grid item key={label}>
					<Button
						onClick={() =>
							handleChangeStatus({ setFilters, status, filters, filterPath })
						}
						className={clsx({
							[classes.activeButton]: isButtonActive({
								defaultFilterState,
								filterPath,
								filters,
								status,
							}),
							[classes.button]: true,
						})}
					>
						{t(label)}
					</Button>
				</Grid>
			))}
		</Grid>
	);
};

InnerNavbarElementsButtons.propTypes = {
	defaultFilterState: string,
	filterPath: string,
	buttons: arrayOf(
		shape({
			label: string,
			status: oneOfType([bool, string]),
		})
	),
};
