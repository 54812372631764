import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		borderColor: theme.palette.background[3],
		padding: '14px 24px',
	},
	buttonsContainer: {
		marginBottom: '16px',
		alignItems: 'center',
	},
}));
