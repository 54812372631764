import React from 'react';
import {
	Button,
	CircularProgress,
	Divider,
	Grid,
	Typography,
} from '@material-ui/core';
import { object } from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';
import { useActionsPreviewPopupContent } from './useCustomActions';

// Import assets
import { BaseEditPenIcon, HistoryIcon } from 'assets/icons';

// Import components
import { CustomSwitch } from 'components/elements';

// Import styles
import { useStyles } from './CustomActions.styles';

export const CustomActions = ({ cell }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { handleOnEdit, active, handleOnSwitchChange, isStatusLoading } =
		useActionsPreviewPopupContent({ cell });

	return (
		<Grid container spacing={1} className={classes.container}>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between" alignItems="center">
					<Grid item>
						<Typography className={classes.text}>
							{t(`common.filters.${active ? 'active' : 'inactive'}`)}
						</Typography>
					</Grid>
					<Grid item>
						{isStatusLoading ? (
							<CircularProgress size={20} />
						) : (
							<CustomSwitch
								color="primary"
								defaultChecked={active}
								onChange={handleOnSwitchChange}
							/>
						)}
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12}>
				<Divider className={classes.divider} />
			</Grid>
			<Grid item xs={12}>
				<Button
					className={classes.button}
					fullWidth
					startIcon={<BaseEditPenIcon className={classes.icon} />}
					onClick={handleOnEdit}
				>
					{t('common.buttons.edit')}
				</Button>
			</Grid>
			<Grid item xs={12}>
				{/* Should be updated after BE with promo code history */}
				<Button
					className={classes.button}
					fullWidth
					startIcon={<HistoryIcon className={classes.icon} />}
					onClick={() => {}}
				>
					{t('common.buttons.history')}
				</Button>
			</Grid>
		</Grid>
	);
};

CustomActions.propTypes = {
	cell: object.isRequired,
};
