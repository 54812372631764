import React from 'react';
import { bool, func, string } from 'prop-types';
import { DialogContent, Box } from '@material-ui/core';

// Import components
import { ClosableDialogTitle } from 'components/elements/organisms/Booking';
import { Typography, Button, Dialog } from 'components/elements';

// Import assets
import { ExclamationMarkWithCircleIcon } from 'assets/icons';

// Import styles
import { useStyles } from './ConfirmationDialog.styles';

// Import utils and helpers
import { useConfirmationDialog } from './useConfirmationDialog';
import { useCommonFields, useTranslations } from 'components/utilities';

export const ConfirmationDialog = ({ isOpen, toggle, surchargePrice }) => {
	const classes = useStyles();
	const { info, CHECKBOX_FIELD, submitting } = useConfirmationDialog({
		surchargePrice,
	});
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	return (
		<Dialog open={isOpen} onClose={toggle} className={classes.dialog}>
			<ClosableDialogTitle
				onClose={toggle}
				title={t('return_page.extended_date')}
				titleClassName={classes.title}
			/>
			<Typography className={classes.subtitle}>
				{t('return_page.are_you_sure_you_want_to_proceed_with_this_extension')}
			</Typography>
			<DialogContent>
				<Box>
					{info.map(({ text, value }) => (
						<Box className={classes.infoContainer} key={value}>
							<Typography className={classes.text}>{text}</Typography>
							<Typography className={classes.value}>{value}</Typography>
						</Box>
					))}
				</Box>
				<Box className={classes.fieldsContainer}>
					{renderFields(CHECKBOX_FIELD)}
					<Box className={classes.iconContainer}>
						<ExclamationMarkWithCircleIcon className={classes.icon} />
						<Typography className={classes.iconText}>
							{t(
								'return_page.the_system_will_sms_a_payment_link_to_the_customer'
							)}
						</Typography>
					</Box>
				</Box>

				<Box className={classes.btnsContainer}>
					<Button
						className={classes.cancel}
						onClick={toggle}
						text={t('common.buttons.cancel')}
					/>

					<Button
						className={classes.send}
						buttonType="submit"
						form="extend-form"
						text={t('common.buttons.send_payment_link')}
						disabled={submitting}
						isLoading={submitting}
					/>
				</Box>
			</DialogContent>
		</Dialog>
	);
};

ConfirmationDialog.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired,
	surchargePrice: string.isRequired,
};
