import React from 'react';
import { object, string } from 'prop-types';
import { TextField, Typography } from '@material-ui/core';

// Import component
import { InputError } from 'components/elements';

// Import styles
import { useStyles } from './TextFieldAdapter.styles';

export const TextFieldAdapter = ({
	input,
	meta,
	additionalText = '',
	placeholder = '',
	inputLabel = '',
	...rest
}) => {
	const classes = useStyles();
	const error = !!(meta.touched && (meta.error || meta.submitError));

	return (
		<>
			{inputLabel && (
				<Typography className={classes.inputLabel}>{inputLabel}</Typography>
			)}
			<TextField
				error={error}
				helperText={additionalText}
				placeholder={placeholder}
				{...input}
				{...rest}
				className={classes.root}
				InputLabelProps={{
					className: classes.label,
				}}
			/>
			<InputError meta={meta} />
		</>
	);
};

TextFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	additionalText: string,
	placeholder: string,
	inputLabel: string,
};
