import React from 'react';
import { Button, Grid } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

// Import assets
import { HistoryIcon } from 'assets/icons';

// Import context
import { useCompensationFormProvider } from '../../context';

// Import components
import { ButtonProgress } from 'components/elements';

// Import styles
import { useStyles } from './CompensationsActions.styles';

export const CompensationsActions = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const {
		isHistory,
		showAddCompensationButton,
		AddCompensation,
		toggleCompensationHistory,
		isLoading,
	} = useCompensationFormProvider();

	if (!isHistory) return null;

	return (
		<Grid
			container
			justifyContent="flex-end"
			spacing={2}
			className={classes.buttonsContainer}
		>
			<Grid item>
				<Button
					color="primary"
					variant="outlined"
					className={classes.button}
					startIcon={<HistoryIcon className={classes.icon} />}
					size="large"
					onClick={toggleCompensationHistory}
				>
					{t('common.buttons.compensation_history')}
				</Button>
			</Grid>
			{showAddCompensationButton && (
				<Grid item>
					<ButtonProgress
						color="primary"
						variant="contained"
						className={classes.button}
						type="submit"
						form="compensation-form"
						size="large"
						onClick={AddCompensation}
						isLoading={isLoading}
						disabled={isLoading}
					>
						{t('common.buttons.add_compensation')}
					</ButtonProgress>
				</Grid>
			)}
		</Grid>
	);
};
