export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.buttons.compensations',
		status: 'compensations',
	},
	{
		label: 'common.buttons.cancellations',
		status: 'cancellations',
	},
];
