import React from 'react';
import { bool, string } from 'prop-types';
import { Typography as MuiTypography, Button } from '@material-ui/core';

// Import components
import ImageFromApiPreview from '../../Previews/ImageFromApiPreview';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './ImagePreviewLabelAdapter.styles';

// Import assets
import { AttachmentIcon } from 'assets/icons';

const ImagePreviewLabelAdapter = ({
	imgUrl,
	label,
	labelWidth = '105px',
	justifySpaceBetween = false,
}) => {
	const classes = useStyles(justifySpaceBetween);
	const { on, toggle } = useToggle();
	const { t } = useTranslations();

	return (
		<div className={classes.root}>
			{label && (
				<MuiTypography
					style={{ minWidth: labelWidth }}
					component="p"
					className={classes.label}
				>
					{label}
				</MuiTypography>
			)}
			<>
				<Button
					onClick={toggle}
					color="primary"
					className={classes.button}
					startIcon={<AttachmentIcon color="primary" />}
				>
					{t('common.buttons.preview')}
				</Button>
				<ImageFromApiPreview imgUrl={imgUrl} on={on} close={toggle} />
			</>
		</div>
	);
};

ImagePreviewLabelAdapter.propTypes = {
	imgUrl: string.isRequired,
	label: string,
	labelWidth: string,
	justifySpaceBetween: bool,
};

export default ImagePreviewLabelAdapter;
