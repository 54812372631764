import { useState } from 'react';

// Import helpers
import { BUTTON_ACTION_TYPES } from 'helpers';

export const useQuantityChanger = ({ defaultQuantity }) => {
	const { INCREMENT } = BUTTON_ACTION_TYPES;

	const [value, setValue] = useState(defaultQuantity);

	const onQuantityChange = (e) => {
		const { value } = e.target;
		setValue(value);
	};

	const changeQuantity = (type) =>
		type === INCREMENT
			? setValue((prev) => +prev + 1)
			: setValue((prev) => (prev > 0 ? +prev - 1 : 0));

	return { changeQuantity, value, onQuantityChange };
};
