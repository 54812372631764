import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

const multiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name[i18next.language];

export const FIELDS = ({
	allCompaniesCheckboxDisabled,
	handleOnCheckboxClick,
	companiesDisabled,
	companies,
	values,
}) => [
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		labelExtractor: ({ name }) => name[i18next.language],
		keyExtractor: ({ uuid }) => uuid,
		currentMultiSelectValues: values.company_uuids,
		label: i18next.t('common.fields.company'),
		searchExtractor: 'children[1]',
		disabled: companiesDisabled,
		multiSelectLabelExtractor,
		name: 'company_uuids',
		options: companies,
		required: true,
		md: 6,
		xs: 6,
	},
	{
		type: FIELD_TYPES.CHECKBOX,
		label: i18next.t('common.fields.apply_to_all_companies'),
		name: 'all_companies_selected',
		disabled: allCompaniesCheckboxDisabled,
		handleOnCheckboxClick,
		md: 6,
		xs: 6,
	},
];
