// Import assets
import {
	DeliveryDropIcon,
	CarDeliveryIcon,
	UnlimitedKmIcon,
	ExtraDriverIcon,
	AuthOutKSAIcon,
	ChildSeatIcon,
	InsuranceIcon,
	NoSmokingIcon,
	DropCityIcon,
	DriverIcon,
} from 'assets/icons';

export const services = [
	{ type: 'delivery_drop', icon: DeliveryDropIcon },
	{ type: 'no_smoking_car', icon: NoSmokingIcon },
	{ type: 'extra_driver', icon: ExtraDriverIcon },
	{ type: 'unlimitedKm', icon: UnlimitedKmIcon },
	{ type: 'auth_out_ksa', icon: AuthOutKSAIcon },
	{ type: 'child_seat', icon: ChildSeatIcon },
	{ type: 'delivery', icon: CarDeliveryIcon },
	{ type: 'insurance', icon: InsuranceIcon },
	{ type: 'dropCity', icon: DropCityIcon },
	{ type: 'driver', icon: DriverIcon },
	{ type: 'tam', icon: InsuranceIcon },
];

export const specialOfferServiceTypes = [
	'pickup',
	'airport',
	'delivery',
	'subscription',
];

export const ADDITIONAL_SERVICES = {
	ADDITIONAL_DRIVER: 'extra_driver',
	TRAVEL_ABROAD: 'auth_out_ksa',
	CHAUFFEUR: 'chauffeur',
	CHILD_SEAT: 'child_seat',
	PICKUP_DELIVERY: 'pickup_delivery',
	DROP_DELIVERY: 'drop_delivery',
	DROP_CITY: 'drop_city',
	INSURANCE: 'insurance',
	NO_SMOKING_CAR: 'no_smoking_car',
	TAMM_SERVICE: 'tam',
	UNLIMITED_KM: 'unlimited_km',
	SECURITY_DEPOSIT: 'security_deposit',
	FULL_TANK: 'full_tank',
	FAST_DELIVERY_AT_AIRPORT: 'fast_delivery_at_airport',
};
