import React from 'react';
import { string } from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Field } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Import helpers
import useMapLocation from 'components/utilities/hooks/useMapLocation';

// Import components
import {
	LocationAutocompleteFieldAdapter,
	GoogleMapsScriptLoader,
} from 'components/elements';

// Import styles
import { useGoogleMapsStyles } from 'theme/styles/googleMaps';

export const Location = ({ section }) => {
	const classes = useGoogleMapsStyles();
	const {
		t,
		fieldDisabled,
		markerLocation,
		setAutocompleteValue,
		onChange,
		onDragEnd,
	} = useMapLocation({ section });

	return (
		<Grid container spacing={3} alignItems="center">
			<Grid item md={6} xs={12}>
				<Field
					fullWidth
					component={LocationAutocompleteFieldAdapter}
					type="text"
					name={`${section}.address`}
					label={t('kiosk.airports.form.location.address')}
					margin="dense"
					variant="outlined"
					setAutocompleteValue={setAutocompleteValue}
					onChange={onChange}
					placeholder={t('kiosk.airports.form.location.address')}
					disabled={fieldDisabled()}
					required
				/>
			</Grid>
			<Grid item md={12} xs={12}>
				<GoogleMapsScriptLoader>
					<GoogleMap
						mapContainerClassName={classes.mapContainer}
						center={markerLocation}
						zoom={15}
					>
						<Marker position={markerLocation} draggable onDragEnd={onDragEnd} />
					</GoogleMap>
				</GoogleMapsScriptLoader>
			</Grid>
		</Grid>
	);
};

Location.propTypes = {
	section: string.isRequired,
};
