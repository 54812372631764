import React from 'react';
import { bool, object, string } from 'prop-types';
import {
	DialogContent,
	Button,
	Dialog,
	DialogActions,
} from '@material-ui/core';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { BOOKING_DETAILS_DIALOG_TABS, getTab } from './helpers';
import { TabsProvider } from 'components/context';

// Import components
import { Tabs } from 'components/elements/molecules';
import { BottomBar } from './components';
import {
	ClosableDialogTitle,
	BookingStatusChip,
} from 'components/elements/organisms/Booking';

// Import styles
import { useStyles } from './BookingDetailsDialog.styles';
import { BOOKING_STATES } from 'helpers';
import { useCustomerSummaryData } from 'queries';
import { useBookingRatingSummary } from 'queries/Booking/useBookingRatingSummary';

export const BookingDetailsDialog = ({
	cell,
	isExtended = false,
	uuid = '',
}) => {
	const { on: isOpen, toggle } = useToggle();
	const { t } = useTranslations();
	const classes = useStyles();

	const booking = cell.row.original;

	const {
		individual_number: bookingId,
		customer_uuid: customerUuid,
		uuid: bookingUuid,
		state,
	} = booking;

	const showRejectTooltip = state === BOOKING_STATES.REJECTED;

	const tabsNames = isExtended
		? { ...BOOKING_DETAILS_DIALOG_TABS, EXTENSION: 'extension' }
		: BOOKING_DETAILS_DIALOG_TABS;

	const { data: customerSummaryData } = useCustomerSummaryData({
		customerUuid,
		isOpen,
	});
	const { data: bookingRatingSummaryData } = useBookingRatingSummary({
		bookingUuid,
		isOpen,
	});

	return (
		<>
			<Button onClick={toggle} color="primary">
				{bookingId}
			</Button>
			<Dialog open={isOpen} onClose={toggle} fullWidth maxWidth="md">
				<ClosableDialogTitle
					title={`${t('common.booking')} #${bookingId}`}
					titleClassName={classes.title}
					actions={
						<BookingStatusChip
							booking={booking}
							showRejectTooltip={showRejectTooltip}
						/>
					}
					onClose={toggle}
				/>
				<DialogContent>
					<TabsProvider
						storeTabInUrl={false}
						data={{
							...booking,
							customerSummary: customerSummaryData,
							ratingSummary: bookingRatingSummaryData,
						}}
					>
						<Tabs
							transKey="bookings.table.booking_details_dialog"
							tabs={tabsNames}
							getTab={getTab}
							cardClassName={classes.card}
							tabClassName={classes.tab}
						/>
					</TabsProvider>
				</DialogContent>
				<DialogActions>
					<BottomBar bookingUuid={uuid || bookingUuid} />
				</DialogActions>
			</Dialog>
		</>
	);
};

BookingDetailsDialog.propTypes = {
	cell: object,
	isExtended: bool,
	uuid: string,
};
