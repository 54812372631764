import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useContractData = (contractNumber) => {
	const path = `/econtract/${contractNumber}/collection`;

	const { data, error, isError, isFetching } = useQuery(
		QUERY_KEYS.CONTRACTS.COLLECTION(contractNumber),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: contractNumber }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError, error };
};
