/* eslint-disable react/prop-types */
import React from 'react';
import { t } from 'i18next';

// Import components
import {
	CompanyTableCellQuery,
	DateTimeTableCell,
	ReceiptIdTableCell,
} from 'components/elements';
import { DEFAULT_DATE_TIME_COMMA_FORMAT } from 'helpers';
import { ActionsTableCell, AmountTableCell } from './components';

export const getColumns = (isAdmin) => [
	{
		header: 'common.fields.invoice_number',
		accessor: 'number',
		component: ({ number }) => (
			<ReceiptIdTableCell text={number} color="text.4" fontWeight={400} />
		),
	},
	{
		header: 'common.fields.type',
		accessor: 'purpose',
		component: ({ purpose }) =>
			t(`bookings.preview_booking.booking_purpose.${purpose}`),
	},
	isAdmin && {
		header: 'common.fields.company',
		accessor: 'company',
		component: (row) => <CompanyTableCellQuery row={row} />,
	},
	!isAdmin && {
		header: 'common.fields.office',
		accessor: 'office',
		component: ({ office_name }) => office_name,
	},
	{
		header: 'common.fields.date',
		accessor: 'date',
		component: ({ created_at }) => (
			<DateTimeTableCell
				date={created_at}
				formula={DEFAULT_DATE_TIME_COMMA_FORMAT}
			/>
		),
	},
	{
		header: 'common.fields.booking_id',
		accessor: 'booking_id',
		component: ({ booking_id }) => <ReceiptIdTableCell text={booking_id} />,
	},
	{
		header: 'common.fields.customer',
		accessor: 'customer',
		component: ({ customer }) => (
			<>
				{customer.name} <br />
				{customer.phone}
			</>
		),
	},
	{
		header: 'common.fields.amount',
		accessor: 'amount',
		component: (row) => <AmountTableCell row={row} />,
	},
	{
		header: 'common.fields.actions',
		accessor: 'actions',
		component: (row) => <ActionsTableCell row={row} />,
	},
];
