import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckMarkDoneIcon = (props) => (
	<SvgIcon width="40" height="40" viewBox="0 0 40 40" fill="none" {...props}>
		<path
			d="M32.4465 11.1431L20.0013 23.5432L14.9375 18.4795"
			stroke="currentColor"
			strokeWidth="2.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M33.8129 16.8603C35.3262 23.5147 31.8822 30.3027 25.6185 33.0114C19.3548 35.7201 12.0501 33.5803 8.23795 27.92C4.42583 22.2597 5.1889 14.6863 10.0536 9.90026C14.9183 5.1142 22.503 4.47459 28.1005 8.37841"
			stroke="currentColor"
			strokeWidth="2.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
