import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
	},
	officeNameContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '12px',
		color: theme.palette.text[2],
		marginBottom: theme.spacing(4),
	},
	officeName: {
		fontWeight: 600,
	},
	hoursContainer: {
		marginBottom: theme.spacing(2),
	},
	day: {
		fontSize: 14,
		color: theme.palette.text[1],
		fontWeight: 600,
	},
	hours: {
		fontSize: 14,
		color: theme.palette.text[1],
	},
	today: {
		color: theme.palette.text[4],
		fontWeight: 600,
	},
	dialogContent: {
		marginBottom: theme.spacing(2),
	},
}));
