export const AMOUNT_FIELDS = ({ booking, t, classes }) => {
	const { options, item } = booking || {};

	const { total_discount_vat_rate: vatRate } = item || {};

	const vatLabel = t(`common.fields.vat`, { percentage: `${vatRate}%` });

	const fields = [
		{
			accessor: 'period.days',
			label: 'common.fields.total_days',
		},
		{
			accessor: 'item.total_discount_vat_price',
			label: vatLabel,
		},
		{
			accessor: 'item.total_discount_gross_price',
			label: 'common.fields.total_paid',
			labelClassName: classes.boldLabel,
			accessorClassName: classes.boldLabel,
		},
	];

	if (options?.length) {
		options.map((option, index) => {
			const { type } = option || {};

			return fields.splice(1, 0, {
				accessor: `options.${index}.nett_price`,
				label: `common.services.${type}`,
			});
		});
	}

	return fields;
};
