import React from 'react';
import { number, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { OPTIONS } from './helpers';

export const EditedByCustomerFilter = ({ name, label, xs = 3, md = 3 }) => {
	const { t } = useTranslations();
	const { submitting } = useFormState();

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{OPTIONS.map((option) => (
					<MenuItem key={option} value={option}>
						{option.toUpperCase()}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

EditedByCustomerFilter.propTypes = {
	name: string,
	label: string,
	xs: number,
	md: number,
};
