import React from 'react';
import { Grid, Card, Box } from '@material-ui/core';

// Import components
import { AdditionalFilters, PeriodItem } from './components';

// Import helpers
import { PERIODS } from './helpers';

// Import styles
import { useStyles } from './Legend.styles';

export const Legend = () => {
	const classes = useStyles();

	return (
		<Box width="100%">
			<Card className={classes.legendContainer}>
				<Grid
					container
					alignItems="center"
					spacing={2}
					justifyContent="space-between"
				>
					<AdditionalFilters />
					<Grid item xs="auto">
						<Grid container spacing={2}>
							{PERIODS.map((period) => (
								<PeriodItem key={period.label} period={period} />
							))}
						</Grid>
					</Grid>
				</Grid>
			</Card>
		</Box>
	);
};
