import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchDefaultSeoTypes } from 'store/actions';
import { useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

// Import Helpers
import { EN, ADMINS } from 'helpers';

//Import styles
import { useStyles } from './Main.styles';

export const useMain = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const { i18n } = useTranslations();

	const isDesktop = useMediaQuery(theme.breakpoints.up('lg'), {
		defaultMatches: true,
	});

	const shouldOpenSidebarByDefault = !!isDesktop;
	const { on: isOpen, toggle } = useToggle(shouldOpenSidebarByDefault);

	const classes = useStyles({ isOpen });

	const roles = useSelector((state) => state.auth.authenticate.data.role);
	const isSignOutLoading = useSelector((state) => state.auth.signOut.loading);
	const { isLoaded: isDefaultSeoLoaded } = useSelector(
		(state) => state.defaultSeo.seoTypes
	);

	const lng = i18n.language;

	const isAdmin = roles.some((roleItem) => ADMINS.includes(roleItem));
	const isLoaded = isAdmin ? isDefaultSeoLoaded : true;
	const directions = theme.direction;
	const shiftContentClass =
		lng === EN ? classes.shiftContentLeft : classes.shiftContentRight;

	useEffect(() => {
		isAdmin && !isLoaded && fetchDefaultSeoTypes()(dispatch);
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		document.body.style.direction = directions;
	}, [directions]);

	return {
		shiftContentClass,
		toggle,
		isSignOutLoading,
		isDesktop,
		isLoaded,
		isAdmin,
		isOpen,
		classes,
	};
};
