import i18next from 'i18next';

// Import helpers
import {
	BOOKING_COMPENSATION_STATES,
	BOOKING_COMPENSATION_TYPES,
	CHIP_COLORS,
} from 'helpers';

const { APPROVED, VALIDATE } = BOOKING_COMPENSATION_STATES;
const { CUSTOMER_SHORTEN_PERIOD, AUTO_LOW_RATING, ADMIN_MANUAL } =
	BOOKING_COMPENSATION_TYPES;

export const getCompensationTypeLabel = ({ type, removeCancelState }) => {
	const translationKey = 'bookings.compensation_history.compensation_types';

	switch (type) {
		case CUSTOMER_SHORTEN_PERIOD:
		case AUTO_LOW_RATING:
		case ADMIN_MANUAL:
			return i18next.t(`${translationKey}.${type}`);

		default:
			return removeCancelState
				? ''
				: i18next.t(`${translationKey}.cancellation`);
	}
};

export const getCompensationStatusLabel = ({ status, type }) =>
	status === APPROVED && type === ADMIN_MANUAL ? VALIDATE : status;

export const getCompensationChipColor = (compensationStatus) => {
	switch (compensationStatus) {
		case BOOKING_COMPENSATION_STATES.COMPENSATED:
		case BOOKING_COMPENSATION_STATES.APPROVED:
			return CHIP_COLORS.SUCCESS;

		case BOOKING_COMPENSATION_STATES.REJECTED:
		case BOOKING_COMPENSATION_STATES.WAITING:
		case BOOKING_COMPENSATION_STATES.INVALID:
			return CHIP_COLORS.ERROR;

		case BOOKING_COMPENSATION_STATES.REVIEW:
			return CHIP_COLORS.WARNING;

		default:
			return CHIP_COLORS.GRAY;
	}
};
