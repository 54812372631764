import React from 'react';
import { Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { COMPENSATION_FIELDS } from './helpers';

// Import context
import { useCompensationFormProvider } from '../../../../context';

// Import components
import { LoadingWrapper } from 'components/elements';

export const FormContent = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const { compensationAmountMax, isLoading, isEdit } =
		useCompensationFormProvider();

	const { values } = useFormState();

	if (isLoading) {
		return <LoadingWrapper />;
	}

	return (
		<Grid container spacing={2}>
			{COMPENSATION_FIELDS({
				compensationAmountMax,
				selectedMethod: values?.method,
				t,
				isEdit,
			}).map(renderFields)}
		</Grid>
	);
};
