import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DownloadIconCurved = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M15.5525 11.1094L11.995 14.6669L8.4375 11.1094"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.0002 3.99609V14.6675"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M19.9989 16.4453C19.9989 18.4101 18.4061 20.0028 16.4414 20.0028H7.54967C5.58493 20.0028 3.99219 18.4101 3.99219 16.4453"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
