import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useContractDraftData = ({ uuid, enabled }) => {
	const path = `/v2/booking/${uuid}/econtract`;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CONTRACTS.DRAFT_DATA(uuid),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError };
};
