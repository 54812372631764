import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		padding: '8px 10px',
		borderRadius: '12px',
		border: '1px solid',
		borderColor: theme.palette.gray.light,
	},
}));
