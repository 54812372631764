import { FIELDS_ACCESSORS } from '../../../../../helpers';

export const FIELDS_INVOICE = (isTransferred) => [
	{
		accessor: 'company_name',
		label: 'common.fields.company',
	},
	{
		accessor: isTransferred ? 'transferred_at' : 'created_at',
		label: isTransferred
			? 'common.fields.transferred_at'
			: 'common.fields.created_at',
	},
	{
		accessor: FIELDS_ACCESSORS.PAYMENT_METHODS,
		label: 'bookings.preview_booking.invoice.payment_method',
	},
	{
		accessor: FIELDS_ACCESSORS.PAYER,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.PERIOD,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.DAILY_RENT_PRICE,
		label: '',
	},
	{
		accessor: 'subtotal.amount_nett',
		label: 'bookings.preview_booking.invoice.total_nett_price',
	},
	{
		accessor: FIELDS_ACCESSORS.INVOICE_OPTIONS,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.VAT,
		label: '',
	},
	{
		accessor: 'subtotal.amount_gross',
		label: 'common.fields.total_cost',
	},
	{
		accessor: FIELDS_ACCESSORS.TOTAL_AMOUNT_PAID_ONLINE,
		label: 'bookings.preview_booking.invoice.total_amount_paid_online',
	},
	{
		accessor: FIELDS_ACCESSORS.TOTAL_AMOUNT_PAID_WALLET,
		label: 'bookings.preview_booking.invoice.total_amount_paid_wallet',
	},
	{
		accessor: FIELDS_ACCESSORS.DISCOUNT,
		label: '',
	},
	{
		accessor: FIELDS_ACCESSORS.TOTAL_PAID,
		label: '',
	},
];
