import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useOfficeRatingHistory = (bookingUuid) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.RATINGS.HISTORY(bookingUuid),
		async () =>
			await services.get(
				ENDPOINTS.BOOKINGS.BOOKING_OFFICE_RATING_HISTORY({ bookingUuid })
			),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError };
};
