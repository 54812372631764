export const COLUMN_ACCESSORS = {
	WALLET: 'wallet',
	LICENSE_MODAL: 'license_modal',
};

export const CUSTOMER_BASIC_INFO_FIELDS = ({ licenseExists, filePath }) => [
	{
		accessor: 'details.full_name',
		label: 'common.fields.full_name',
	},
	{
		accessor: 'details.full_arabic_name',
		label: 'common.fields.arabic_name',
	},
	{
		accessor: 'details.address.country',
		label: 'common.fields.nationality',
	},
	{
		accessor: 'details.gender.type',
		label: 'common.fields.gender',
	},
	{
		accessor: 'details.phone_with_prefix',
		label: 'common.fields.phone',
	},
	{
		accessor: 'details.email',
		label: 'common.fields.address_email',
	},
	{
		accessor: 'details.birth_date_with_age',
		label: 'common.fields.birth_date_and_age',
	},
	{
		accessor: 'customer.card_holder_name',
		label: 'common.fields.cardholder_name',
	},
	{
		accessor: 'details.license.license_number',
		label: 'common.fields.license_number',
	},
	{
		accessor: 'details.license.license_expire_date',
		label: 'common.fields.license_expiry_date',
	},
	{
		accessor: COLUMN_ACCESSORS.LICENSE_MODAL,
		label: 'common.fields.license_photo',
		licenseExists,
		filePath,
	},
];

export const CUSTOMER_DETAILS_FIELDS = ({ customerUuid }) => [
	{
		accessor: 'details.type.type',
		label: 'common.fields.user_type',
	},
	{
		accessor: 'details.signup_date',
		label: 'common.fields.sign_up_date',
	},
	{
		accessor: 'booking.last_created',
		label: 'common.fields.last_created_booking',
	},
	{
		accessor: 'details.rating',
		label: 'common.fields.rating',
	},
	{
		accessor: COLUMN_ACCESSORS.WALLET,
		label: 'common.fields.customer_wallet',
		customerUuid,
	},
	{
		accessor: 'booking.total_count',
		label: 'common.fields.total_bookings',
	},
	{
		accessor: 'booking.total_accepted_count',
		label: 'common.fields.accepted_bookings',
	},
	{
		accessor: 'booking.total_company_cancelled_count',
		label: 'common.fields.cancelled_by_company',
	},
	{
		accessor: 'booking.total_customer_cancelled_count',
		label: 'common.fields.cancelled_by_customer',
	},
	{
		accessor: 'booking.total_customer_no_show_count',
		label: 'common.fields.customer_no_shows',
	},
	{
		accessor: 'booking.location',
		label: 'common.fields.booking_location',
	},
];

export const convertCustomerDetails = (customerDetails) => {
	if (!customerDetails) return null;

	const details = customerDetails.details;

	const {
		phone_prefix: phonePrefix,
		birth_date: birthDate,
		phone,
		age,
	} = details;

	return {
		...customerDetails,
		details: {
			...details,
			birth_date_with_age: `${birthDate} (${age})`,
			phone_with_prefix: `${phonePrefix}${phone}`,
		},
	};
};
