import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	carBox: {
		position: 'relative',
		padding: theme.spacing(2),
		border: `1px solid ${theme.palette.background[3]}`,
		borderRadius: '8px',
		height: '100%',
		width: '90%',

		'& .MuiButton-label': {
			flexDirection: 'column',
		},
	},
	text: {
		fontSize: 14,
		marginBottom: theme.spacing(1),
	},
	bold: {
		fontWeight: 600,
	},
	companyBox: {
		display: 'flex',
		alignItems: 'center',
	},
	icon: {
		marginRight: '5px',
		width: '18px',
		height: '18px',
		marginTop: '9px',
	},
	checkMarkBox: {
		color: theme.palette.primary.main,
		width: '24px',
		height: '24px',
		borderRadius: '50%',
		position: 'absolute',
		top: '-10px',
		right: '-10px',
	},
	active: {
		backgroundColor: theme.palette.primary.medium,
		borderColor: theme.palette.primary.main,

		'&:hover': {
			borderColor: theme.palette.background[3],
			backgroundColor: theme.palette.background[6],
		},
	},
	cancelIcon: {
		color: theme.palette.text[1],
		fontSize: 14,
	},
	cancelIconBox: {
		width: '24px',
		height: '24px',
		borderRadius: '50%',
		backgroundColor: theme.palette.background[6],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
}));
