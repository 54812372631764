import React from 'react';
import { object, string } from 'prop-types';
import { FormControl } from '@material-ui/core';
import { KeyboardTimePicker } from '@material-ui/pickers';

// Import helpers
import { useToggle } from 'components/utilities';

// Import assets
import { CalendarIcon } from 'assets/icons';

// Import components
import { InputError, Typography } from 'components/elements';

// Import styles
import { useStyles } from './TimePickerFieldAdapter.styles';

export const TimePickerFieldAdapter = ({
	input,
	meta,
	inputLabel,
	...rest
}) => {
	const error = meta.touched && (meta.error || meta.submitError) ? true : false;
	const classes = useStyles();
	const { on: open, toggle } = useToggle();
	return (
		<FormControl error={error} className={classes.container}>
			{inputLabel && (
				<Typography className={classes.inputLabel}> {inputLabel}</Typography>
			)}
			<KeyboardTimePicker
				{...input}
				{...rest}
				open={rest.disabled ? false : open}
				value={input.value || null}
				onClick={toggle}
				onClose={toggle}
				KeyboardButtonProps={{
					'aria-label': 'change time',
				}}
				className={classes.input}
				variant="outlined"
				keyboardIcon={<CalendarIcon />}
			/>
			<InputError meta={meta} />
		</FormControl>
	);
};

TimePickerFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	className: string,
	inputLabel: string,
};
