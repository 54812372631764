import React from 'react';
import { Box, Divider } from '@material-ui/core';

// Import components
import { CollapsableCard } from 'components/elements';
import { CarAndCompanyContent } from '../CarAndCompanyContent';

// Import context
import { useCarAndCompanyProvider } from '../../context';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from '../../CarAndCompany.styles';

export const CarAndCompanyHistory = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { isHistory } = useCarAndCompanyProvider();

	if (!isHistory) return null;

	return (
		<Box>
			<Divider className={classes.divider} />
			<CollapsableCard
				cardClassName={classes.oldCarCompanyCard}
				headerClassName={classes.oldCarCompanyHeader}
				contentClassName={classes.oldCarCompanyContent}
				title={t('bookings.preview_booking.old_company_and_car_card')}
				initialOpen={false}
			>
				<CarAndCompanyContent isHistoryDialog />
			</CollapsableCard>
		</Box>
	);
};
