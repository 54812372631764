import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		display: 'grid',
		gridTemplateColumns: 'repeat(2, 1fr)',
		gap: '16px',
	},
	buttons: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		gap: '16px',
	},
}));
