import React from 'react';
import { array } from 'prop-types';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import helpers and utils
import { useTranslations } from 'components/utilities';
import { URGENT_STATUSES_FIELDS } from './helpers';

// Import styles
import { useStyles } from './UrgentStatuses.styles';

export const UrgentStatuses = ({ urgentReasons = [] }) => {
	const {
		i18n: { language: lang },
		t,
	} = useTranslations();

	const classes = useStyles();

	if (urgentReasons.length === 0) return null;

	const currentReason = urgentReasons[0];

	return (
		<>
			<PreviewFields
				fields={URGENT_STATUSES_FIELDS({
					reason: currentReason,
					lang,
					t,
					classes,
				})}
				customData={{ ...currentReason, date: currentReason.created_at }}
			/>
		</>
	);
};

UrgentStatuses.propTypes = {
	urgentReasons: array,
};
