import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	chip: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
	priceDifferenceChip: ({ isNegativeDifference }) => ({
		backgroundColor: isNegativeDifference
			? theme.palette.error.light
			: theme.palette.primary.light,
		color: isNegativeDifference
			? theme.palette.error.main
			: theme.palette.primary.main,
	}),
	blueChip: {
		backgroundColor: '#A6C4E0',
		color: theme.palette.white,
		borderRadius: 4,
		fontSize: 11,
		'& span': {
			padding: '0 5px',
		},
	},
	chipIcon: {
		width: 15,
		height: 15,
	},
	cardsIcon: {
		width: 20,
	},
	image: {
		width: 30,
	},
	smallImage: {
		width: 20,
	},
	dateColumn: {
		minWidth: 150,
	},
	ratingCellContainer: {
		display: 'flex',
		alignItems: 'center',
		gridGap: 8,
	},
	ratingCellIcon: {
		fontSize: '1.5em',
		color: theme.palette.text[2],
	},
	subscriptionImage: {
		width: 20,
	},
	redDeliveryChip: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		backgroundColor: theme.palette.error.light,
	},
	greenDeliveryChip: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		backgroundColor: theme.palette.primary.light,
	},
	greenIcon: {
		color: theme.palette.primary.main,
		margin: 0,
		width: '20px',
		height: '20px',
	},
	redIcon: {
		color: theme.palette.error.main,
		margin: 0,
		width: '12px',
		height: '12px',
	},
	tamaraLogo: {
		width: '30px',
		height: '30px',
		borderRadius: '50%',
		backgroundImage: "url('/images/tamara.png')",
		backgroundSize: 'contain',
	},
}));
