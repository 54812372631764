import React from 'react';

// Import store
import { fetchSliders } from 'store/actions';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { BUTTONS } from './helpers';

// Import components
import { InnerNavbarElementsButtons } from 'components/elements';
import { OuterToolbarElements, TableBodyRow } from './components';

export const Sliders = () => (
	<TableRPC
		tableNavigation={
			<InnerNavbarElementsButtons buttons={BUTTONS} filterPath="show_in" />
		}
		columnsAccessor={COLUMNS_ACCESSORS}
		fetchDataTableAction={fetchSliders}
		title="nav.marketing.sliders"
		outerToolbarElements={<OuterToolbarElements />}
		tableBodyRow={TableBodyRow}
		columns={columns}
		showSearchInput={false}
	/>
);
