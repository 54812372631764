import React from 'react';
import { bool, func, object, string } from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCancelAndRejectBookingForm } from './useCancelAndRejectBookingForm';

// Import components
import { FormContent } from './components';

// Import helpers
import { INITIAL_VALUES } from './helpers';

export const CancelAndRejectBookingForm = ({
	onClose,
	onChange,
	open = false,
	booking,
	dialogTitle,
	isRejection,
}) => {
	const { t } = useTranslations();

	const { handleSubmit, error, cancellationReasons, isLoading } =
		useCancelAndRejectBookingForm({ booking, onChange, isRejection });

	return (
		<Dialog fullWidth disableEscapeKeyDown open={open} onClose={onClose}>
			<DialogTitle>{t(dialogTitle)}</DialogTitle>
			<Form
				onSubmit={handleSubmit}
				initialValues={INITIAL_VALUES}
				render={({ handleSubmit }) => (
					<form onSubmit={handleSubmit}>
						<FormContent
							cancellationReasons={cancellationReasons}
							isLoading={isLoading}
							error={error}
							onClose={onClose}
						/>
					</form>
				)}
			/>
		</Dialog>
	);
};

CancelAndRejectBookingForm.propTypes = {
	dialogTitle: string,
	isRejection: bool,
	booking: object,
	onChange: func,
	onClose: func,
	open: bool,
};
