import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useVehicleYearList = ({ body = {}, enabled }) => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.CARS.YEARS(JSON.stringify(body)),
		async () =>
			await services.post(ENDPOINTS.CARS.SUPPLIER_VEHICLE_YEAR_LIST, body),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
