import React from 'react';

// import component
import { Button } from 'components/elements';
import { CarSelectionDialog } from './components';

// Import assets
import { CarIcon } from 'assets/icons';

// Import helpers
import { CarSelectionDialogContextProvider } from './context';
import { useSelectCar } from './useSelectCar';
import { SelectedCarPreview } from './components/SelectedCarPreview/SelectedCarPreview';

export const SelectCar = () => {
	const {
		isCarSelectionDialogOpen,
		toggleCarSelectionDialog,
		handleDialogOpen,
		isPreview,
	} = useSelectCar();
	return (
		<CarSelectionDialogContextProvider>
			{!isPreview && (
				<Button
					icon={CarIcon}
					text="bookings.create_booking.buttons.browse_cars_catalog"
					type="outlined_and_primary"
					onClick={handleDialogOpen}
				/>
			)}
			<CarSelectionDialog
				open={isCarSelectionDialogOpen}
				toggle={toggleCarSelectionDialog}
			/>
			{isPreview && <SelectedCarPreview handleDialogOpen={handleDialogOpen} />}
		</CarSelectionDialogContextProvider>
	);
};
