import React from 'react';
import { bool, object } from 'prop-types';

// Import components
import { Cell } from './components';
import { TableRow } from 'components/elements';

export const TableBodyRow = (props) => {
	const { row, columnsAccessor, isHovered } = props;

	return (
		<TableRow {...props}>
			{row.cells.map((cell) => (
				<Cell
					key={cell.column.id}
					cell={cell}
					columnsAccessor={columnsAccessor}
					isHovered={isHovered}
				/>
			))}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	isHovered: bool,
};
