export const BUTTONS = [
	{
		label: 'sliders.table.navigation.website_slider',
		status: 'website',
	},
	{
		label: 'sliders.table.navigation.mobile_app_slider',
		status: 'mobile',
	},
];
