import React from 'react';
import { arrayOf, string, object, func, bool } from 'prop-types';
import { Form } from 'react-final-form';
import { Dialog, DialogContent } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from '../../components';

export const AdditionalDialog = ({
	initialValues,
	isOpen,
	toggle,
	onSubmit,
	fields,
	groupedFields,
	onClear,
	transKey,
}) => {
	const { t } = useTranslations();

	return (
		<Dialog keepMounted open={isOpen} onClose={toggle} fullWidth maxWidth="md">
			<ClosableDialogTitle onClose={toggle} title={t('common.add_columns')} />
			<DialogContent>
				<Form
					onSubmit={onSubmit}
					initialValues={initialValues}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormContent
								groupedFields={groupedFields}
								onClear={onClear}
								fields={fields}
								transKey={transKey}
							/>
						</form>
					)}
				/>
			</DialogContent>
		</Dialog>
	);
};

AdditionalDialog.propTypes = {
	groupedFields: arrayOf(object),
	fields: arrayOf(string),
	onSubmit: func.isRequired,
	onClear: func.isRequired,
	isOpen: bool.isRequired,
	initialValues: object,
	toggle: func.isRequired,
	transKey: string,
};
