import { FIELDS_ACCESSORS } from '../helpers';

export const EXTENSION_DIALOG_FIELDS = [
	{
		accessor: FIELDS_ACCESSORS.NEW_DROP_DATE,
		label:
			'bookings.preview_booking.booking_info.extension_info.new_drop_off_date',
	},
	{
		accessor: FIELDS_ACCESSORS.TOTAL_EXTENSION_AMOUNT,
		label:
			'bookings.preview_booking.booking_info.extension_info.total_extension_amount',
	},
];
