import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import components
import { CopyToClipboard } from 'components/elements';

// Import helpers and utilities
import { getCopyActions } from './helpers';
import { useTranslations } from 'components/utilities';

export const BookingId = () => {
	const { t } = useTranslations();
	const { values } = useFormState();
	const { individual_number: individualNumber = '', uuid } = values || {};

	return (
		<Grid container spacing={2} alignItems="center">
			<Grid item>
				<Typography variant="h3">{`${t(
					'bookings.preview_booking.title'
				)} #${individualNumber.toUpperCase()}`}</Typography>
			</Grid>
			<Grid item>
				<CopyToClipboard actions={getCopyActions({ individualNumber, uuid })} />
			</Grid>
		</Grid>
	);
};
