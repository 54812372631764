import React from 'react';

// Import components
import { ButtonProgress } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import context
import { useCompensationFormProvider } from '../../context';

// Import styles
import { useStyles } from './AddCompensation.styles';

export const AddCompensation = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { AddCompensation, showAddCompensationButton, isHistory, isLoading } =
		useCompensationFormProvider();

	if (!showAddCompensationButton || isHistory) return null;

	return (
		<ButtonProgress
			variant="contained"
			color="primary"
			size="large"
			className={classes.button}
			onClick={AddCompensation}
			isLoading={isLoading}
			disabled={isLoading}
		>
			{t('common.buttons.add_compensation')}
		</ButtonProgress>
	);
};
