import React from 'react';
import { useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { PreviewField } from 'components/elements';

export const PayerPreviewField = () => {
	const booking = useSelector(previewBookingSelector);
	const { t } = useTranslations();

	const isCorporate = !!booking?.corporate;
	const corporateName = booking?.corporate?.name;

	if (!isCorporate) return null;

	return (
		<PreviewField label={t('common.fields.payer')}>
			{corporateName}
		</PreviewField>
	);
};
