import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		border: '1px solid',
		borderColor: theme.palette.background[3],
		borderRadius: '12px',
	},
	topBarWrapper: {
		borderBottom: '1px solid',
		borderBottomColor: theme.palette.background[3],
	},
	loadingWrapper: {
		minHeight: '120px',
	},
}));
