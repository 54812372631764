export default {
	root: {
		padding: '16.5px 24px 16.5px 24px',
	},
	outlined: {
		borderRadius: 8,
	},
	label: {
		color: '#263238',
	},
	iconOutlined: {
		right: '16px',
	},
};
