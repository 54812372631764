import React from 'react';
import { node, oneOfType, oneOf, string, bool } from 'prop-types';
import { Chip } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './ChipLabel.styles';

// Import helpers
import { CHIP_COLORS } from 'helpers';

const { INFO, ERROR, GRAY, SUCCESS, WARNING } = CHIP_COLORS;

export const ChipLabel = ({
	label,
	color = SUCCESS,
	fullWidth = false,
	className,
	...rest
}) => {
	const classes = useStyles({ color, fullWidth });

	return (
		<Chip
			className={clsx([classes.chip, classes[color], className])}
			data-testid="chip-label"
			label={label}
			{...rest}
		/>
	);
};

ChipLabel.propTypes = {
	label: oneOfType([string, node]).isRequired,
	color: oneOf([SUCCESS, WARNING, GRAY, ERROR, INFO]),
	fullWidth: bool,
	className: string,
};
