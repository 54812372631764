import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AgentIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 30 30" fill="none" {...props}>
		<path
			d="M15.5992 19.6641V22.8004"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.39844 22.7969V21.6197C8.39844 20.4756 9.20594 19.4906 10.3278 19.2663L12.6046 18.8109C12.7927 18.7733 12.9882 18.812 13.1479 18.9184V18.9184C14.6318 19.9077 16.5651 19.9077 18.0491 18.9184V18.9184C18.2088 18.812 18.4042 18.7733 18.5924 18.8109L20.8692 19.2663C21.991 19.4907 22.7984 20.4757 22.7984 21.6197V22.7969"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.7984 11.9219V14.0032C18.7984 15.7705 17.3657 17.2032 15.5984 17.2032V17.2032C13.8311 17.2032 12.3984 15.7705 12.3984 14.0032V11.9219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.2822 16.7266L17.8615 19.0437"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.9201 16.7266L13.3408 19.0437"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.7127 10.8016L19.5545 9.309C19.6936 9.06236 19.5156 8.7915 19.1333 8.66824L16.0223 7.66511C15.7596 7.58038 15.4435 7.58038 15.1808 7.66511L12.0698 8.66824C11.6875 8.7915 11.5095 9.06236 11.6486 9.309L12.4905 10.8016"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M18.8023 10.8047V11.6885C18.8006 11.9345 18.6723 12.1667 18.453 12.3207C16.7912 13.4993 14.4135 13.4993 12.7517 12.3207C12.5324 12.1667 12.4041 11.9345 12.4023 11.6885V10.8047"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.4023 10.6177H18.8023"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
