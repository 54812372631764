import React from 'react';

// Import components
import { CitiesFilter } from 'components/elements';

export const AdditionalFilterFields = () => (
	<CitiesFilter
		name="city"
		smallFieldStyle
		showEmptyLabel
		xs="auto"
		md="auto"
	/>
);
