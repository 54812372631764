import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	customerName: {
		fontSize: 20,
		fontWeight: 600,
		marginBottom: theme.spacing(2),
	},
	createdAt: {
		fontSize: 16,
		color: theme.palette.text[1],
	},
	topInfo: {
		marginBottom: theme.spacing(4),
	},
}));
