import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		fontWeight: 600,
		marginBottom: theme.spacing(3),
	},
	card: {
		borderRadius: 8,
		boxShadow: 'none',
		padding: 0,
		height: '100%',
	},
	cardTitle: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(1),
	},
	cardBody: {
		fontSize: 14,
		fontWeight: 400,
		color: theme.palette.text[2],
	},
	button: {
		width: '100%',
		height: '100%',
		padding: theme.spacing(3),
		display: 'block',
		textAlign: 'left',
		border: `1px solid ${theme.palette.background[3]}`,
		borderRadius: 8,
	},
	active: {
		backgroundColor: theme.palette.primary.medium,
		borderColor: theme.palette.primary.main,

		'&:hover': {
			backgroundColor: theme.palette.primary.medium,
		},
	},
	goalContainer: {
		padding: '33px 0 48px 0',
	},
}));
