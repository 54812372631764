import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import components
import { ActionConfirmDialog, Alert, SuccessDialog } from 'components/elements';

// Import utils and helpers
import { useApproveRejectionDialog } from './useApproveRejectionDialog';
import { useTranslations } from 'components/utilities';

// Import context
import { useUrgentFormsProvider } from '../../../../context';

// Import styles
import { useStyles } from './ApproveRejectionDialog.styles';

export const ApproveRejectionDialog = () => {
	const classes = useStyles();
	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const {
		handleCloseSuccess,
		isApproveRejectionOpen,
		toggleApproveRejection,
		cancelReasonBooking,
	} = useUrgentFormsProvider();

	const {
		isSuccess,
		toggleSuccess,
		isError,
		error,
		isLoading,
		handleApproveRejection,
	} = useApproveRejectionDialog(toggleApproveRejection);

	const currentReason = cancelReasonBooking?.name?.[lang];

	if (isError) {
		return <Alert message={error.message} />;
	}

	return (
		<>
			<ActionConfirmDialog
				isOpen={isApproveRejectionOpen}
				toggle={toggleApproveRejection}
				title={t('bookings.validate_cancellation.approve_rejection_title')}
				description={t('bookings.validate_cancellation.approve_rejection_desc')}
				actionButtonText="common.buttons.approve"
				actionButtonVariant="contained"
				confirmAction={handleApproveRejection}
				isLoading={isLoading}
				extraContent={
					<Box className={classes.reasonContainer}>
						<Typography variant="body1" className={classes.reasonTitle}>
							{t('bookings.validate_cancellation.reject_reason')}:
						</Typography>
						<Typography variant="body1" className={classes.reason}>
							{currentReason}
						</Typography>
					</Box>
				}
			/>

			<SuccessDialog
				isOpen={isSuccess}
				toggle={toggleSuccess}
				handleOk={() => handleCloseSuccess(toggleSuccess)}
				title={t('bookings.validate_cancellation.cancellation_validated')}
				description={t(
					'bookings.validate_cancellation.approve_rejection_success_message'
				)}
			/>
		</>
	);
};
