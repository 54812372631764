import React from 'react';
import { bool, number, object, string } from 'prop-types';
import { Grid, TableCell } from '@material-ui/core';

// Import helpers
import { Row } from './components';

export const AvailableCarTableCell = ({
	eventStoreUuid = null,
	showIcon = false,
	amount,
	leasingAmount,
	cell,
}) => {
	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			style={{ minWidth: 140 }}
		>
			<Grid container alignItems="center" spacing={2}>
				<Row
					amount={amount}
					showIcon={showIcon}
					eventStoreUuid={eventStoreUuid}
				/>
				{leasingAmount !== null && (
					<Row amount={leasingAmount} showIcon={showIcon} />
				)}
			</Grid>
		</TableCell>
	);
};

AvailableCarTableCell.propTypes = {
	amount: number.isRequired,
	cell: object.isRequired,
	eventStoreUuid: string,
	leasingAmount: number,
	showIcon: bool,
};
