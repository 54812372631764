import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	CircularProgress,
	Divider,
	Grid,
} from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	GratificationTypeField,
	GratificationPointsField,
	GratificationDiscountFields,
} from './components/';
import { useCampaignGratificationCard } from './useCampaignGratificationCard';

export const CampaignGratificationCard = () => {
	const { t } = useTranslations();

	const { isPointsType, isDiscountType, filteredTypes, isFetching } =
		useCampaignGratificationCard();

	return (
		<Card>
			<CardHeader title={t('campaigns.form.gratification_card_title')} />
			<Divider />
			<CardContent>
				{isFetching ? (
					<CircularProgress />
				) : (
					<Grid container spacing={3}>
						<GratificationTypeField options={filteredTypes} />

						{isPointsType && <GratificationPointsField />}
						{isDiscountType && <GratificationDiscountFields />}
					</Grid>
				)}
			</CardContent>
		</Card>
	);
};
