// Import helpers
import { ADMIN } from 'helpers';

export const FIELDS_ACCESSORS = {
	LICENSE_MODAL: 'license_modal',
	WALLET: 'wallet',
	ADDRESS_LINK: 'address_link',
	ASSIGN_DRIVER: 'assign_driver',
	SUBSCRIPTION_INVOICE_PAYMENT_METHOD: 'subscription_invoice_payment_method',
	VIEW_ATTACHMENT: 'view_attachment',
	PAYMENT_METHODS: 'payment_methods',
	INVOICE_OPTIONS: 'invoice_options',
	DISCOUNT: 'discount',
	TOTAL_AMOUNT_PAID_ONLINE: 'total_amount_paid_online',
	TOTAL_AMOUNT_PAID_WALLET: 'total_amount_paid_wallet',
	VAT: 'vat',
	PAYER: 'payer',
	PERIOD: 'period',
	DAILY_RENT_PRICE: 'daily_rent_price',
	TOTAL_PAID: 'total_paid',
	OPTIONS: 'options',
	PHONE_NUMBER: 'phone_number',
	WORKING_HOURS: 'working_hours',
	DATE: 'date',
	URGENT_REQUEST_STATUS: 'urgent_request_status',
	LINK: 'link',
	TOTAL_EXTENSION_AMOUNT: 'total_extension_amount',
	NEW_DROP_DATE: 'new_drop_date',
	PICK_UP_ADDRESS: 'pick_up_address',
};

export const CUSTOMER_WALLET_PREVIEW_PERMISSION = [ADMIN];
