import React from 'react';
import { DialogActions, Button } from '@material-ui/core';
import { bool, array, func, string } from 'prop-types';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useActions } from './useActions';

// Import components
import { ButtonProgress } from 'components/elements';

// Import styles
import { useStyles } from '../styles';

export const Actions = (props) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { dialogClose, isLoading } = props;
	const { handleUpdateOffices, isDisabled, isDataSending } = useActions(props);

	return (
		<DialogActions className={classes.dialogActions}>
			<Button disabled={isLoading} onClick={dialogClose} color="primary">
				{t('table.modal.delete.btn_cancel')}
			</Button>

			<ButtonProgress
				onClick={handleUpdateOffices}
				color="primary"
				variant="contained"
				disabled={isDisabled}
				isLoading={isDataSending}
			>
				{t('common.buttons.save')}
			</ButtonProgress>
		</DialogActions>
	);
};

Actions.propTypes = {
	checkedOffices: array.isRequired,
	dialogClose: func.isRequired,
	isDataExist: bool.isRequired,
	isLoading: bool.isRequired,
	userId: string.isRequired,
};
