import { useHistory, useLocation, useParams } from 'react-router-dom';

// Import utils and helpers
import { useProfile, useToggle } from 'components/utilities';
import {
	replaceCompanyOfficesUrl,
	replaceExternalPartnersListUrl,
	replaceGlobalAddCarUrl,
	replaceGlobalEditCarUrl,
} from 'helpers';
import { URLS } from 'components/routes';

// Import assets
import { AddCarIcon, EditCarIcon, BanknoteIcon } from 'assets/icons';
import DriveEtaIcon from '@material-ui/icons/DriveEta';

export const useOuterToolbarElements = ({ companyData }) => {
	const { on: isOpen, toggle: toggleEditPriceDialog } = useToggle();
	const { isAdmin, isCompanyManager } = useProfile();
	const { id: companyUuid } = useParams();
	const { pathname } = useLocation();
	const { push } = useHistory();

	const actionButtons = [];

	const isCompanyOfficesView =
		pathname === replaceCompanyOfficesUrl(companyUuid);

	const hasPartner = !!companyData?.external?.use_partner;

	const showMapExternalPartnerButton =
		isAdmin && isCompanyOfficesView && hasPartner;

	const goToAddCarView = () => push(replaceGlobalAddCarUrl(companyUuid));
	const goToEditCarsView = () => push(replaceGlobalEditCarUrl(companyUuid));
	const goToAddOfficeView = () => push(URLS.createOfficeUrl);

	if (showMapExternalPartnerButton) {
		actionButtons.push({
			onClick: push(replaceExternalPartnersListUrl(companyUuid)),
			icon: DriveEtaIcon,
			text: 'common.buttons.map_external_partner',
		});
	}

	if (!!companyUuid || isCompanyManager) {
		actionButtons.push(
			...[
				{
					onClick: goToEditCarsView,
					text: 'common.buttons.edit_cars',
					icon: EditCarIcon,
				},
				{
					onClick: goToAddCarView,
					text: 'common.buttons.add_car',
					icon: AddCarIcon,
				},
			]
		);
	}

	if (isCompanyOfficesView) {
		actionButtons.push({
			text: 'common.buttons.edit_prices',
			onClick: toggleEditPriceDialog,
			icon: BanknoteIcon,
			isLink: false,
		});
	}

	return {
		toggleEditPriceDialog,
		goToAddOfficeView,
		actionButtons,
		isAdmin,
		isOpen,
	};
};
