import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useTransactionHistory = ({ bookingId }) => {
	const { data, refetch, isFetching, isError, error } = useQuery(
		QUERY_KEYS.BOOKINGS.TRANSACTION_HISTORY(bookingId),
		async () =>
			await services.get(
				ENDPOINTS.BOOKINGS.BOOKING_TRANSACTION_HISTORY({ bookingId })
			),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;
	const meta = data ? data.data.meta : null;

	return { data: fetchedData, meta, refetch, isFetching, isError, error };
};

export default useTransactionHistory;
