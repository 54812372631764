import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';

// Import components
import { ClosableDialogTitle, Steps } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';
import { useEditHistoryDialog } from './useEditHistoryDialog';

export const EditHistoryDialog = () => {
	const { t } = useTranslations();

	const { steps, isEditHistoryOpen, toggleEditHistory } =
		useEditHistoryDialog();

	return (
		<Dialog
			open={isEditHistoryOpen}
			onClose={toggleEditHistory}
			maxWidth="sm"
			fullWidth
		>
			<ClosableDialogTitle
				title={t('common.buttons.edit_history')}
				onClose={toggleEditHistory}
			/>
			<DialogContent>
				<Steps steps={steps} />
			</DialogContent>
		</Dialog>
	);
};
