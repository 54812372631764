const checkTruthy = (val) => (val ? val : '');

const sum = (...args) => args.reduce((acc, num) => acc + Number(num), 0);

export const multiplication = (a, b) => checkTruthy(Number(a) * Number(b));

export const getPaymentDetails = (...args) => ({
	totalGross: checkTruthy((sum(...args) * 1.15).toFixed(2)),
	vat: checkTruthy((sum(...args) * 0.15).toFixed(2)),
});
