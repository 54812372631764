import React from 'react';
import { number, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { PAYMENT_METHOD_FILTER_FIELDS } from './helpers';

export const PaymentMethodFilter = ({ name, label, md = 12, xs = 12 }) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{PAYMENT_METHOD_FILTER_FIELDS.map((value) => (
					<MenuItem key={value} value={value}>
						{t(`bookings.filters.payment_methods.${value}`)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

PaymentMethodFilter.propTypes = {
	name: string,
	label: string,
	md: number,
	xs: number,
};
