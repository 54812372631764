export const CAMPAIGN_TYPES = {
	CUSTOMER_REGISTERED: 'customer_registered',
	CUSTOMER_EXPERIENCE_SHARED: 'customer_experience_shared',
	BOOKING_CASHBACK: 'booking_cashback',
	PARTNERSHIP: 'partnership',
};

export const DEFAULT_REASON_UUID = 'other';

export const FINANCIAL_REPORT_TYPES = {
	CAMPAIGN: 'campaign',
	REVENUE: 'revenue',
};
