import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setValidationTableChangedState } from 'store/actions';

export const useValidateCompensationForm = () => {
	const [isSubmitted, setIsSubmitted] = useState(false);
	const dispatch = useDispatch();

	const handleOnClose = () => {
		setIsSubmitted(true);
		setValidationTableChangedState({ isTableChanged: true, dispatch });
	};

	return { isSubmitted, onClose: handleOnClose };
};
