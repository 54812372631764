import { useSelector } from 'react-redux';
import { BOOKING_URGENT_STATES } from 'helpers';
import { previewBookingSelector } from 'store/selectors';

export const useUrgentAttentionWarningCard = () => {
	const booking = useSelector(previewBookingSelector);

	const { REVIEW } = BOOKING_URGENT_STATES;

	const urgentStatus = booking?.urgentAssistance?.status;

	const isWarningVisible = urgentStatus === REVIEW;

	return {
		isWarningVisible,
	};
};
