import React from 'react';
import { string } from 'prop-types';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';

// Import components
import { TableExportFilters } from 'components/elements';
import { FilterFields } from '../../components';

export const ExportFilters = ({ pageType }) => (
	<TableExportFilters initialValues={initialValues} formatValues={formatValues}>
		<FilterFields pageType={pageType} isExport />
	</TableExportFilters>
);

ExportFilters.propTypes = {
	pageType: string,
};
