// Import utils
import { useMessage, useTranslations } from 'components/utilities';

// Import store
import { updateCompanyDropCities } from 'store/actions/dropCities';

export const useDropCities = () => {
	const { message } = useMessage();
	const { t } = useTranslations();

	const handleOnSubmit = async (values) => {
		const { company_uuid: companyUuid, out_data: outData } = values;

		const convertData = Object.entries(outData).reduce(
			(acc, [cityInUuid, data]) => {
				const { drop_cities: dropCities, price } = data;

				const innerData = dropCities.map((city_out_uuid) => ({
					city_in_uuid: cityInUuid,
					city_out_uuid: city_out_uuid,
					nett_price: price,
					is_available: true,
				}));

				return [...acc, ...innerData];
			},
			[]
		);

		try {
			await updateCompanyDropCities({
				companyId: companyUuid,
				body: {
					data: convertData,
				},
			});

			message.success(t('common.messages.successfully_saved'));
		} catch {
			message.error(t('common.messages.error_message'));
		}
	};

	return {
		handleOnSubmit,
	};
};
