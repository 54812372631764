// Import helpers and utils
import { services } from 'helpers';
import { useMessage, useTranslations } from 'components/utilities';

// Import store
import { activateCarsUrl } from 'store/paths';

export const useActiveButton = ({ carUuid }) => {
	const { t } = useTranslations();
	const { message } = useMessage();

	const handleOnSwitchChange = async (e) => {
		const { checked } = e.target;

		const body = {
			cars: carUuid,
		};

		try {
			await services.post(activateCarsUrl(!checked), body);
		} catch {
			message.error(t('common.messages.error_message'));
		}
	};

	return { handleOnSwitchChange };
};
