const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
	variant: 'menu',
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 5 + ITEM_PADDING_TOP,
			marginTop: '8px',
			borderRadius: '12px',
			boxShadow: '0px 0px 8px 0px rgba(38, 50, 56, 0.16)',
			minHeight: '400px',
			padding: '24px',
			maxWidth: '370px',
		},
	},
	anchorOrigin: {
		vertical: 'bottom',
		horizontal: 'left',
	},
	getContentAnchorEl: null,
};
