import React from 'react';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

// Import components
import { Grid } from 'components/elements';
// import { Export } from '../Export';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import assets
import { SingleUserIcon, UserProfileRefreshChangeIcon } from 'assets/icons';

// Import styles
import { useStyles } from './OuterToolbarElements.styles';
import { Export } from '../../components';

export const OuterToolbarElements = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { push } = useHistory();

	const redirectToAddManagerView = () => push(URLS.createCompanyManagerUrl);

	return (
		<Grid container spacing={1}>
			<Grid item>
				{/* it will be handled as separated task */}
				<Button
					startIcon={<UserProfileRefreshChangeIcon />}
					className={classes.whiteButton}
					disabled
				>
					{t('common.buttons.update_managers')}
				</Button>
			</Grid>
			<Grid item>
				<Export />
			</Grid>
			<Grid item>
				<Button
					onClick={redirectToAddManagerView}
					startIcon={<SingleUserIcon />}
					className={classes.button}
					variant="contained"
					color="primary"
				>
					{t('common.buttons.new_manager')}
				</Button>
			</Grid>
		</Grid>
	);
};
