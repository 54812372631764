import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TriangleExclamationIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M9.99479 10.937V7.82031"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M9.99396 13.4349C9.87896 13.4349 9.78563 13.5282 9.78646 13.6432C9.78646 13.7582 9.87979 13.8516 9.99479 13.8516C10.1098 13.8516 10.2031 13.7582 10.2031 13.6432C10.2031 13.5282 10.1098 13.4349 9.99396 13.4349"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M11.6958 3.45062L18.0783 14.6206C18.82 15.919 17.8825 17.5348 16.3875 17.5348H3.62251C2.12668 17.5348 1.18918 15.919 1.93168 14.6206L8.31418 3.45062C9.06168 2.14146 10.9483 2.14146 11.6958 3.45062Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
