import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BookingStatusFilter, DateRangeFilter } from 'components/elements';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>
			<BookingStatusFilter
				md={6}
				xs={12}
				name="state"
				label="bookings.filters.state"
			/>
		</Grid>
	);
};
