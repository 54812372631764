import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		fontSize: 13,
		fontWeight: 600,
		padding: '12px 24px',
	},
	actionButton: {
		borderColor: theme.palette.background[3],
	},
	contentText: {
		fontSize: 16,
		marginBottom: '24px',
	},
	addSpace: {
		marginBottom: '48px',
	},
	buttonsContainer: {
		marginBottom: theme.spacing(2),
	},
}));
