import React from 'react';
import { func } from 'prop-types';
import { Box, Grid } from '@material-ui/core';

// Import assets
import { BaseEditPenIcon, CircularXIcon } from 'assets/icons';

// import components
import { CarCard } from '../CarCard';
import { Button } from 'components/elements';

// Import utils and  helpers
import { useCarSelectionDialogProvider } from '../../context';
import { useTranslations } from 'components/utilities';

// import styles
import { useStyles } from './SelectedCarPreview.styles';

export const SelectedCarPreview = ({ handleDialogOpen }) => {
	const { chosenCar, handleRemoveSelectedCar } =
		useCarSelectionDialogProvider();
	const classes = useStyles();
	const { t } = useTranslations();

	if (!chosenCar) return null;

	return (
		<Box>
			<Grid container>
				<Grid item md={4}>
					<CarCard car={chosenCar} preview />
				</Grid>
			</Grid>

			<Box className={classes.iconsContainer}>
				<Button
					icon={BaseEditPenIcon}
					text={t('bookings.create_booking.buttons.edit_selected_car')}
					type="outlined_and_primary"
					onClick={handleDialogOpen}
				/>
				<Button
					icon={CircularXIcon}
					text={t('bookings.create_booking.buttons.remove_selected_car')}
					type="error"
					onClick={handleRemoveSelectedCar}
				/>
			</Box>
		</Box>
	);
};

SelectedCarPreview.propTypes = {
	handleDialogOpen: func.isRequired,
};
