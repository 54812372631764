import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import utils
import { useProfile } from 'components/utilities';

// Import queries and selector
import { useEditBookingHistory } from 'queries';
import { previewBookingSelector } from 'store/selectors';

export const useBookingInfoTab = () => {
	const { id } = useParams();
	const { isAdmin } = useProfile();
	const { delivery } = useSelector(previewBookingSelector);

	const { data, isFetching } = useEditBookingHistory({
		uuid: id,
		enabled: isAdmin,
	});

	const isHistory = data?.length;

	const editBookingHistory = isHistory ? data[0] : null;

	const isDelivery =
		delivery?.pick_up_delivery?.available || delivery?.drop_delivery?.available;

	return { isHistory, isDelivery, editBookingHistory, isFetching };
};
