import React, { Fragment } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';

// Import components
import { IconButton } from 'components/elements';

// Import utils
import { usePageBreadcrumbs } from './usePageBreadcrumbs';

// Import styles
import { useStyles } from './PageBreadcrumbs.styles';

export const PageBreadcrumbs = () => {
	const classes = useStyles();

	const { goBack, sectionNameArray } = usePageBreadcrumbs();

	return (
		<Grid
			container
			xs={1}
			item
			className={classes.sectionTitleContainer}
			spacing={2}
		>
			<Grid item>
				<IconButton onClick={goBack} className={classes.backButton}>
					<ArrowBack className={classes.sectionPreviousParts} />
				</IconButton>
			</Grid>

			<Grid item>
				<Typography className={classes.sectionTitle} noWrap>
					{sectionNameArray.map((section, index) => (
						<Fragment key={index}>
							{index > 0 ? (
								section
							) : (
								<Typography
									component="span"
									className={classes.sectionPreviousParts}
								>
									{section} {sectionNameArray.length > 1 && '/'}
								</Typography>
							)}
						</Fragment>
					))}
				</Typography>
			</Grid>
		</Grid>
	);
};
