import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers
import { replaceEditTrainStationUrl, TABLE_ACTIONS_ACCESSORS } from 'helpers';

// Import components
import { ActiveTableCell, EditTableCell } from 'components/elements';

// Import store
import { updateTrainStationState } from 'store/actions';

export const Cell = ({ cell }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const { uuid, active } = cell.row.original;
		const columnId = cell.column.id;

		switch (columnId) {
			case TABLE_ACTIONS_ACCESSORS.EDIT: {
				return (
					<EditTableCell
						editLinkPath={replaceEditTrainStationUrl({ uuid })}
						key={columnId}
						cell={cell}
					/>
				);
			}

			case TABLE_ACTIONS_ACCESSORS.ACTIVE:
				return (
					<ActiveTableCell
						activeAction={() => updateTrainStationState({ uuid, active })}
						key={columnId}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
};
