import React from 'react';
import { node } from 'prop-types';
import { createContext, useContext } from 'react';

// Import helpers
import { useCarSelectionDialog } from './useCarSelectionDialog';

const CarSelectionDialogContext = createContext();

export const CarSelectionDialogContextProvider = ({ children }) => {
	const values = useCarSelectionDialog();

	return (
		<CarSelectionDialogContext.Provider value={values}>
			{children}
		</CarSelectionDialogContext.Provider>
	);
};

CarSelectionDialogContextProvider.propTypes = {
	children: node.isRequired,
};

export const useCarSelectionDialogProvider = () => {
	const context = useContext(CarSelectionDialogContext);

	if (!context) {
		throw new Error(
			'useCarSelectionDialogProvider must be used within CarSelectionDialogContextProvider'
		);
	}

	return context;
};
