import React from 'react';

// Import helpers and utils
import { TableRPC } from 'components/utilities';
import { buttons } from './helpers';
import { URLS } from 'components/routes';

// Import store
import { fetchPromoCodes } from 'store/actions';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { Filters, TableBodyRow } from './components';
import { AddNewButton, InnerNavbarElementsButtons } from 'components/elements';

export const PromoCodes = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchPromoCodes}
			title="nav.marketing.promo_codes"
			filtersBar={<Filters />}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="active" buttons={buttons} />
			}
			searchBarPlaceholder="common.fields.promo_code"
			outerToolbarElements={
				<AddNewButton
					text="promo_codes.table.toolbar.add"
					to={URLS.addPromoCodeUrl}
				/>
			}
		/>
	);
};
