// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	replaceEditCompanyManagersUrl,
} from 'helpers';
import { updateActiveManager } from 'store/actions';

const { EDIT, ACTIVE, ASSIGN_OFFICES } = ACTION_MENU_POPUP_FIELD_TYPES;

export const useCell = ({ cell }) => {
	const { active, uuid } = cell.row.original;

	const activeAction = () =>
		updateActiveManager({ isActive: active, id: uuid });

	const actionMenuFields = [
		{
			type: ACTIVE,
			activeState: active,
			isDivider: true,
			activeAction,
		},
		{
			type: EDIT,
			editViewUrl: replaceEditCompanyManagersUrl(uuid),
		},
		{
			type: ASSIGN_OFFICES,
		},
	];

	return {
		actionMenuFields,
	};
};
