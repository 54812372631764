import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '80%',
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '16px',
	},
	caption: {
		color: theme.palette.text[2],
		fontWeight: '400',
		fontSize: 14,
	},
	warningIcon: {
		color: theme.palette.error.medium,
	},
}));
