import React, { useEffect } from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers and utils
import { useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';
import { BOOKING_URGENT_STATES } from 'helpers';
import { getColumns } from 'views/UrgentBookings/columns';
import { actionMenuFields } from './helpers';

// Import components
import {
	BookingIdTableCell,
	Notes,
	CompanyTableCell,
	UrgentStatusTableCell,
	NewActionsMenuTableCell,
} from 'components/elements';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';

export const TableBodyRow = ({
	row,
	onMouseLeave,
	columnsAccessor,
	onMouseEnter,
	isHovered,
	ref,
}) => {
	const {
		ID,
		CUSTOMER_NAME,
		URGENT_REASON,
		COMPANY,
		PICK_DATE,
		STATUS,
		ACTIONS,
	} = columnsAccessor;

	const {
		i18n: { language: lang },
	} = useTranslations();

	const classes = useSharedStyles();

	const { updateColumns, filters } = useTableContextProvider();

	const { urgent_assistance_status: urgentAssistanceStatus } = filters || {};

	useEffect(() => {
		const isAllSelected =
			urgentAssistanceStatus === 'all' || !urgentAssistanceStatus;
		updateColumns(getColumns(isAllSelected));
		// eslint-disable-next-line
	}, [urgentAssistanceStatus]);

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			urgentAssistance,
			customer_name: customerName,
			customer_phone: customerPhone,
			pick_date: pickDate,
			pick_time: pickTime,
			urgent_assistance_status: status,
		} = cell.row.original;

		const isReview = status === BOOKING_URGENT_STATES.REVIEW;

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case URGENT_REASON:
				return (
					<TableCell key={cell.column.id}>
						{urgentAssistance.name[lang]}
					</TableCell>
				);

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case PICK_DATE:
				return (
					<TableCell key={cell.column.id}>
						{pickDate},
						<br /> {pickTime}
					</TableCell>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return <UrgentStatusTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						isHovered={isHovered}
						extraActions={{ urgentMark: isReview }}
						previewPopupFields={actionMenuFields}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
