import React, { useState } from 'react';
import { createContext } from 'react';
import { node, string } from 'prop-types';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useHistory, useLocation } from 'react-router-dom';

// Import utils and helpers
import { useToggle } from 'components/utilities';
import {
	BOOKING_DETAILS_TABS,
	convertApiFormErrors,
	getTabIndexBookingDetails,
	replacePreviewBookingUrl,
} from 'helpers';
import useTableContextProvider from '../TableProvider/useTableContextProvider';
import { useTabsProvider } from '../TabsProvider';

// Import store
import { changeUrgentReasonStatus, fetchBookingPreview } from 'store/actions';

const { COMPENSATION } = BOOKING_DETAILS_TABS;

export const MarkUrgentProviderContext = createContext(null);

export const MarkUrgentProvider = ({ children, bookingUuid = '' }) => {
	const { fetchData } = useTableContextProvider();

	const history = useHistory();

	const dispatch = useDispatch();

	const { pathname } = useLocation();

	const { setCurrentTab } = useTabsProvider();

	const isBookingDetails = pathname.includes('preview');

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const { on: isMarkUrgentOpen, toggle: toggleMarkUrgent } = useToggle();
	const { on: isMarkConfirmOpen, toggle: toggleMarkConfirm } = useToggle();
	const { on: isSuccessMark, toggle: toggleSuccessMark } = useToggle();

	const [markAsLoading, setMarkAsLoading] = useState(false);
	const [markStatus, setMarkStatus] = useState(false);
	const [markError, setMarkError] = useState(null);

	const handleSolveAndUnSolveClick = (status) => {
		toggleMarkConfirm();
		setMarkStatus(status);
	};

	const markUrgentAsSolvedOrUnsolved = async (status) => {
		setMarkAsLoading(true);
		try {
			await changeUrgentReasonStatus({
				bookingId: bookingUuid,
				status,
				cancelToken,
			});

			toggleMarkConfirm();
			toggleSuccessMark();
		} catch (e) {
			setMarkError(e);
		}
		setMarkAsLoading(false);
	};

	const handleCloseSuccess = async (toggle) => {
		toggle();
		try {
			isMarkUrgentOpen && toggleMarkUrgent();
			const cancelToken = axios.CancelToken.source().token;
			isBookingDetails
				? await fetchBookingPreview({ id: bookingUuid, cancelToken })(dispatch)
				: await fetchData();
		} catch (error) {
			setMarkError(convertApiFormErrors(error.data.errors));
		}
	};

	const goToCompensationTab = () => {
		toggleMarkUrgent();
		isBookingDetails
			? setCurrentTab(getTabIndexBookingDetails(COMPENSATION))
			: history.push(`${replacePreviewBookingUrl(bookingUuid)}?tab=6`);
	};

	return (
		<MarkUrgentProviderContext.Provider
			value={{
				isMarkUrgentOpen,
				toggleMarkUrgent,
				setMarkStatus,
				markStatus,
				isMarkConfirmOpen,
				handleSolveAndUnSolveClick,
				markUrgentAsSolvedOrUnsolved,
				markAsLoading,
				isSuccessMark,
				markError,
				handleCloseSuccess,
				goToCompensationTab,
				toggleMarkConfirm,
				toggleSuccessMark,
			}}
		>
			{children}
		</MarkUrgentProviderContext.Provider>
	);
};

MarkUrgentProvider.propTypes = {
	children: node,
	bookingUuid: string,
};
