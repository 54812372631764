import { omit } from 'lodash';
import * as types from 'store/types';

// Import helpers
import {
	LEASING_MODEL_PERIODS_TEXT,
	SUBSCRIPTION_MODEL_PERIODS,
	SUBSCRIPTION_MODEL_PERIODS_TEXT,
} from 'helpers';

export const carCatchError = ({ error, dispatch }) => {
	const defaultError = { message: '' };
	const response = error.response?.data ?? defaultError;
	dispatch({
		type: types.EDIT_CAR_FORM_FETCH_DETAILS_ERROR,
		payload: response,
	});
};

export const convertSubmitData = ({
	formValues,
	cars,
	offices,
	officesList,
	officeId,
	externalCars,
	choosenExternal,
	isEdit,
}) => {
	const {
		carInfo,
		is_daily,
		is_leasing,
		is_available_to_sell_after_leasing_period,
		leasing,
	} = formValues || {};
	const {
		vehicle,
		details,
		prices,
		external,
		options,
		specification,
		subscription,
	} = carInfo;

	const {
		free_kms,
		extra_km_nett_price,
		is_unlimited,
		unlimited_nett_price,
		is_insurance,
		insurance_prices,
		is_no_smoking_car,
		no_smoking_prices,
	} = options;

	const externalUuid = external.use_partner_car
		? getExternalCarUuid(externalCars, choosenExternal)
		: '';

	const vehicleUuid = { uuid: vehicle.uuid };

	const newOptions = {
		free_kms,
		extra_km_nett_price,
		is_unlimited,
		unlimited_nett_price,
		is_insurance,
		insurance_prices,
		is_no_smoking_car,
		no_smoking_prices,
	};

	const newRatingPrices = isEdit ? convertRatingPrices(prices) : prices;

	const newDetails = isEdit ? omit(details, ['active']) : details;

	const convertedSubscription = convertSubscriptionBeforeSubmit(subscription);
	const convertedLeasingPrices = convertLeasingPricesSubmit(
		leasing?.base_rent?.months
	);

	const newEquipment = {
		navigation: specification.navigation,
	};

	const newSpecification = omit(specification, 'navigation');

	const newValues = {
		details: { ...newDetails },
		prices: newRatingPrices,
		options: { ...newOptions },
		subscription: convertedSubscription,
		equipment: newEquipment,
		specification: newSpecification,
		is_daily,
		is_leasing,
		is_available_to_sell_after_leasing_period,
		leasing_prices: {
			...leasing,
			base_rent: {
				months: convertedLeasingPrices,
			},
		},
	};

	const newCars = cars && cars.map((car) => ({ uuid: car }));

	const newOffices = converOffices({ offices, officesList });

	if (officeId) {
		return {
			...newValues,
			vehicle: vehicleUuid,
			office: { uuid: officeId },
			external: { ...external, partner_car_uuid: externalUuid },
		};
	} else if (!isEdit && !officeId) {
		return { ...newValues, vehicle: vehicleUuid, offices: newOffices };
	} else {
		return {
			...newValues,
			vehicle: { uuid: vehicle.global_uuid },
			cars: [...newCars],
		};
	}
};

const getExternalCarUuid = (externalCarsList, externalCar) =>
	externalCarsList.find((car) => (car.name.en || car.name.ar) === externalCar);

const converOffices = ({ offices, officesList }) => {
	if (offices?.length > 0) {
		const isAllOfficesCarsSelected = offices.length !== officesList.length;
		const newOffices = isAllOfficesCarsSelected
			? offices.map((office) => ({ uuid: office }))
			: [];
		return newOffices;
	}
};

const convertRatingPrices = (prices) => {
	const newPrices = Object.entries(prices).reduce((acc, next) => {
		const {
			days,
			nett_price,
			discount_nett_price,
			nett_price_before_markup,
			nett_price_after_markup,
		} = next[1];
		acc[next[0]] = {
			days,
			nett_price,
			discount_nett_price,
			nett_price_before_markup,
			nett_price_after_markup,
		};
		return acc;
	}, {});
	return newPrices;
};

const convertSubscriptionBeforeSubmit = (subscription) => {
	// Backend asked to not send subscription.prices.one with form data
	const filteredSubscription = omit(subscription, ['prices.one']);

	const { prices } = filteredSubscription || {};

	const numberKeys = Object.values(SUBSCRIPTION_MODEL_PERIODS_TEXT);

	const newPrices = Object.keys(prices).reduce((acc, key) => {
		const newKey = SUBSCRIPTION_MODEL_PERIODS[key.toUpperCase()];
		if (numberKeys.includes(key)) {
			acc[newKey] = prices[key];
		} else {
			acc[key] = prices[key];
		}
		return acc;
	}, {});

	return { ...filteredSubscription, prices: newPrices };
};

const convertLeasingPricesSubmit = (months) =>
	Object.fromEntries(
		Object.entries(months).map(([key, value]) => [key.slice(1), value])
	);

export const convertCarInfo = (carInfo) => {
	const equipment = carInfo?.equipment || {};
	const specification = carInfo?.specification || {};
	const newSpecification = { ...specification, ...equipment };
	const newCarInfo = omit(carInfo, 'equipment');

	return { ...newCarInfo, specification: newSpecification };
};

export const convertSubscriptionPrices = (carInfo) => {
	const subscriptionPrices = carInfo.subscription.prices;

	return Object.entries(SUBSCRIPTION_MODEL_PERIODS_TEXT).reduce(
		(acc, [numericPeriod, textPeriod], index) => {
			const newKey = {
				[textPeriod]: {
					...Object.values(subscriptionPrices)[index],
					period: numericPeriod,
				},
			};
			return { ...acc, ...newKey };
		},
		{}
	);
};

export const convertLeasingPrices = (carInfo) => {
	const leasingPrices = carInfo?.leasing_prices?.base_rent?.months || {};

	return Object.entries(LEASING_MODEL_PERIODS_TEXT).reduce(
		(acc, [numPeriod, textPeriod]) => {
			const newKey = {
				[textPeriod]: leasingPrices[numPeriod] || '',
			};
			return { ...acc, ...newKey };
		},
		{}
	);
};

export const convertCarEditApiErrors = (apiErrors) => {
	const convertedErrors = Object.keys(apiErrors).reduce((acc, key) => {
		const error = apiErrors[key];
		const convertedError = {
			[`carInfo.${key}`]: error,
		};
		return { ...acc, ...convertedError };
	}, {});
	return convertedErrors;
};

export const EMPTY_FIELDS = {
	carInfo: {
		details: {
			quantity: '',
			description: {
				ar: '',
				en: '',
			},
		},
		vehicle: {
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
			year: null,
			uuid: '',
		},
		options: {
			is_unlimited: false,
			unlimited_nett_price: '',
			free_kms: '',
			extra_km_nett_price: '',
			is_insurance: false,
			is_no_smoking_car: false,
			insurance_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
			no_smoking_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
		},
		external: {
			car_codes: [],
			has_external: false,
			codes: [],
		},
		equipment: {
			navigation: false,
		},
		specification: {
			doors_number: '5',
			bluetooth: false,
			panorama: false,
			usb: false,
			rear_camera: false,
			sensors: false,
			car_multimedia: false,
			fuel_type: 'diesel',
		},
		prices: {
			daily: {
				days: 1,
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
			weekly: {
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
			monthly: {
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
			annual: {
				discount_nett_price: 0,
				discount_gross_price: 0,
			},
		},
		subscription: {
			is_enabled: false,
			prices: {
				one: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.ONE,
				},
				three: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.THREE,
				},
				six: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.SIX,
				},
				nine: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.NINE,
				},
				twelve: {
					nett_price: 0,
					period: SUBSCRIPTION_MODEL_PERIODS.TWELVE,
				},
			},
		},
	},
	prices: {
		annual: {
			from: 31,
			to: 360,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		monthly: {
			from: 8,
			to: 30,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		weekly: {
			from: 2,
			to: 7,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		daily: {
			from: 1,
			to: 1,
			average_nett_price: '',
			lowest_nett_price: '',
		},
	},
	externalCars: [],
	cars: [],
	offices: [],
	is_daily: true,
	is_leasing: false,
	is_available_to_sell_after_leasing_period: false,
	leasing: {
		base_rent: {
			months: {
				m1: '',
				m12: '',
				m24: '',
				m36: '',
			},
		},
		security_deposit_price: '',
		leasing_sell_price: '',
	},
};

const convertLeasingPriceObject = (object) =>
	Object.entries(object).reduce(
		(acc, [, value]) => ({
			...acc,
			[value.period]: value.price,
		}),
		{}
	);

export const convertLeasingCarSubmitData = ({ values, officeId, isEdit }) => {
	const {
		plate,
		prices,
		plate_type,
		carInfo,
		free_kms,
		description_en,
		description_ar,
	} = values;

	const {
		base_rent: { months: baseRentMonths },
		extra_km: { kilometers: extraKilometers },
		insurance: { months: monthsInsurance },
		no_smoking: { months: noSmokingMonths },
		deductible_amount,
		full_tank,
		unlimited_kms,
		security_deposit,
	} = prices;

	const convertedPrices = {
		base_rent: {
			months: convertLeasingPriceObject(baseRentMonths),
		},
		leasing_sell_price: {
			one_time: prices.leasing_sell_price.is_active
				? prices.leasing_sell_price.one_time
				: null,
		},
		extra_km: {
			kilometers: convertLeasingPriceObject(extraKilometers),
		},
		insurance: {
			months: prices.insurance.months.m1.is_active
				? convertLeasingPriceObject(monthsInsurance)
				: { 1: null },
		},
		no_smoking: {
			months: prices.no_smoking.months.m1.is_active
				? convertLeasingPriceObject(noSmokingMonths)
				: { 1: null },
		},
		deductible_amount,
		full_tank,
		unlimited_kms: {
			one_time: unlimited_kms.is_unlimited ? unlimited_kms.one_time : null,
		},
		security_deposit,
	};

	const data = {
		vehicle_uuid: carInfo.vehicle.uuid,
		plate_number: plate,
		plate_type,
		prices: convertedPrices,
		free_kms: parseInt(free_kms),
		description_ar,
		description_en,
		...carInfo.specification,
		miles_usage: parseInt(carInfo.specification.miles_usage),
		doors_number: parseInt(carInfo.specification.doors_number),
	};

	if (!isEdit) {
		data.office_uuid = officeId;
	}

	return data;
};

export const convertLeasingCarFetchData = ({
	coreData,
	detailsVehicleData,
	carId,
}) => {
	const vehicle = {
		maker_name: detailsVehicleData.maker_name,
		model_name: detailsVehicleData.model_name,
		year: detailsVehicleData.year,
		uuid: carId,
	};

	const {
		prices: {
			base_rent,
			security_deposit,
			deductible_amount,
			leasing_sell_price,
			full_tank,
			unlimited_kms,
			insurance,
			no_smoking,
			extra_km,
		},
		plate_type,
		plate_number,
		miles_usage,
		fuel_type,
		doors_number,
		used_oil,
		seat_material,
		front_camera,
		rear_camera,
		sensors,
		panorama,
		usb,
		bluetooth,
		apple_car_play,
		cruise_control,
		self_driving_car,
		body_color,
		free_kms,
		description_en,
		description_ar,
	} = coreData;

	const editItemData = {
		carInfo: {
			vehicle,
			specification: {
				miles_usage,
				fuel_type,
				doors_number,
				used_oil,
				seat_material,
				front_camera,
				rear_camera,
				sensors,
				panorama,
				usb,
				bluetooth,
				apple_car_play,
				cruise_control,
				self_driving_car,
				body_color,
			},
		},
		prices: {
			base_rent: {
				months: {
					m1: {
						price: base_rent?.months[1] ?? null,
						period: 1,
					},
					m12: {
						price: base_rent?.months[12] ?? null,
						period: 12,
					},
					m24: {
						price: base_rent?.months[24] ?? null,
						period: 24,
					},
					m36: {
						price: base_rent?.months[36] ?? null,
						period: 36,
					},
				},
			},
			security_deposit,
			deductible_amount,
			leasing_sell_price: {
				one_time: leasing_sell_price?.one_time ?? null,
				is_active: !!leasing_sell_price?.one_time ?? false,
			},
			full_tank,
			unlimited_kms: {
				is_unlimited: null != unlimited_kms?.one_time,
				one_time: unlimited_kms?.one_time ?? null,
			},
			insurance: {
				months: {
					m1: {
						is_active: !!insurance?.months[1] ?? false,
						price: insurance?.months[1] ?? null,
						period: 1,
					},
				},
			},
			no_smoking: {
				months: {
					m1: {
						is_active: !!no_smoking?.months[1] ?? false,
						price: no_smoking?.months[1] ?? null,
						period: 1,
					},
				},
			},
			extra_km: {
				kilometers: {
					k1: {
						price: extra_km?.kilometers[1] ?? null,
						period: 1,
					},
				},
			},
		},
		plate_type,
		plate: plate_number,
		free_kms,
		description_en,
		description_ar,
	};

	return editItemData;
};
