export const COLUMNS_ACCESSORS = {
	ANALYZE_DRIVER_LICENSE: 'analyze_driver_license',
	APPROVE: 'organization_verified',
	NAME: 'name',
	NOTE: 'note',
	PREVIEW: 'preview',
	ACTIONS: 'actions',
	CREATED_AT: 'created_at',
	ORGANIZATION_NAME: 'organization_name',
	GENDER: 'gender_type',
};

export const columns = [
	{
		Header: 'common.fields.note',
		accessor: COLUMNS_ACCESSORS.NOTE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.name',
		accessor: COLUMNS_ACCESSORS.NAME,
	},
	{
		Header: 'common.fields.phone_number',
		accessor: 'phone',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.location',
		accessor: 'location',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.age',
		accessor: 'age',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.rating',
		accessor: 'rating',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.total_no_of_booking',
		accessor: 'accepted_booking_counter',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.sign_up_date_time',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.approve_organization',
		accessor: COLUMNS_ACCESSORS.APPROVE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.analyze_driver_license',
		accessor: COLUMNS_ACCESSORS.ANALYZE_DRIVER_LICENSE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.preview',
		accessor: COLUMNS_ACCESSORS.PREVIEW,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},

	// Additional
	{
		Header: 'common.fields.gender_type',
		accessor: COLUMNS_ACCESSORS.GENDER,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.license_number',
		accessor: 'license_number',
		disableSortBy: true,
		isHidden: true,
	},

	{
		Header: 'common.fields.organization_name',
		accessor: COLUMNS_ACCESSORS.ORGANIZATION_NAME,
		disableSortBy: true,
		isHidden: true,
	},
	{
		Header: 'common.fields.type',
		accessor: 'type',
		disableSortBy: true,
		isHidden: true,
	},
];
