import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFaqDetails } from './useFaqDetails';
import { FAQ_FIELDS } from './helpers';

export const FaqDetails = () => {
	const { t } = useTranslations();

	const helpers = useFaqDetails();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader title={t('faq.form.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FAQ_FIELDS(helpers).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
