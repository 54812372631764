import React from 'react';
import { Link } from 'react-router-dom';
import BusinessIcon from '@material-ui/icons/Business';

// Import components
import { Button } from 'components/elements';
import { URLS } from 'components/routes';

export const OuterToolbarElements = () => {
	return (
		<Link to={URLS.addTrainStation}>
			<Button
				text="common.buttons.add_a_new_station"
				startIcon={<BusinessIcon />}
			/>
		</Link>
	);
};
