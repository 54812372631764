// Import helpers
import { FIELD_TYPES } from 'helpers';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';

export const PAYMENT_FIELD = {
	type: FIELD_TYPES.CHECKBOX,
	name: 'payment_link',
	label: `${transPrefix}.send_payment_link`,
	disabled: true,
	checked: true,
};
