import React from 'react';

// Import components
import { Tabs } from 'components/elements';

// Import helpers
import { BOOKING_DETAILS_TABS } from 'helpers';
import { getTab } from './helpers';

export const BookingsTabs = () => {
	return (
		<Tabs
			tabs={BOOKING_DETAILS_TABS}
			getTab={getTab}
			transKey="bookings.preview_booking.tabs"
		/>
	);
};
