// Import helpers
import { CAMPAIGN_TYPES, CHIP_COLORS } from 'helpers';

export const statusList = [
	{
		label: 'common.filters.active',
		color: CHIP_COLORS.SUCCESS,
		status: 'active',
	},
	{
		label: 'common.filters.inactive',
		color: CHIP_COLORS.ERROR,
		status: 'inactive',
	},
];

export const getCampaignType = (campaignType) =>
	Object.values(CAMPAIGN_TYPES).find((type) => campaignType === type);
