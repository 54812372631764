import React from 'react';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';
import { Alert } from 'components/elements';

// Import context
import { useUrgentFormsProvider } from '../../context';

// Import helpers
import { ASSIGN_REASON_INITIAL_VALUES } from './helpers';

export const AssignFields = () => {
	const { submitAssignUrgent, assignError, isMaxUrgentReasonsSize } =
		useUrgentFormsProvider();

	if (isMaxUrgentReasonsSize) return null;

	return (
		<Form
			onSubmit={submitAssignUrgent}
			initialValues={ASSIGN_REASON_INITIAL_VALUES}
			render={({ handleSubmit }) => (
				<Box component="form" id="assign-urgent-form" onSubmit={handleSubmit}>
					{assignError.length > 0 && <Alert message={assignError[0][0]} />}
					<FormContent />
				</Box>
			)}
		/>
	);
};
