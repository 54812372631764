import { useQuery } from 'react-query';

import { ENDPOINTS, QUERY_KEYS } from 'helpers';
import services from 'services/services';

const useGratificationsTypes = ({ campaignType }) => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.CAMPAIGNS.GRATIFICATIONS(campaignType),
		async () =>
			await services.get(ENDPOINTS.CAMPAIGNS.GRATIFICATIONS({ campaignType })),
		{
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000,
			enabled: !!campaignType,
		}
	);

	const fetchedData = data ? data.data.data.gratification : [];

	return { data: fetchedData, isFetching };
};

export default useGratificationsTypes;
