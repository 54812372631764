import React from 'react';
import { func, number, object, oneOfType, string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Grid, Link, TableCell, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './LinkWithIconTableCell.styles';

export const LinkWithIconTableCell = ({ cell, icon: Icon, to, text }) => {
	const classes = useStyles();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Link component={RouterLink} to={to}>
				<Grid container alignItems="center" spacing={1}>
					<Grid item>
						<Icon className={classes.icon} />
					</Grid>
					<Grid item>
						<Typography className={classes.text}>{text}</Typography>
					</Grid>
				</Grid>
			</Link>
		</TableCell>
	);
};

LinkWithIconTableCell.propTypes = {
	cell: object.isRequired,
	icon: oneOfType([func, object]),
	to: string.isRequired,
	text: oneOfType([string, number]).isRequired,
};
