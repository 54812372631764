import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { object } from 'prop-types';

// Import utilities
import { useDiscountPreviewField } from './useDiscountPreviewField';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './DiscountPreviewField.styles';

export const DiscountPreviewField = ({ customData }) => {
	const discount = customData.discount;

	const { label, subLabel, fixedDiscount } = useDiscountPreviewField(discount);
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid
			container
			spacing={5}
			justifyContent="space-between"
			alignItems="center"
		>
			<Grid item lg="auto">
				<Grid container>
					<Grid item xs={12}>
						<Typography variant="h6" className={classes.label}>
							{t(label)}
						</Typography>
					</Grid>
					{subLabel && (
						<Grid item xs={12}>
							<Typography variant="h6" className={classes.subLabel}>
								{t(subLabel)}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
			<Grid item lg="auto">
				<Typography
					variant="body1"
					component="div"
					align="right"
					className={classes.value}
				>
					{fixedDiscount}
				</Typography>
			</Grid>
		</Grid>
	);
};

DiscountPreviewField.propTypes = {
	customData: object,
};
