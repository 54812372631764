import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useCompanyManagerAssignedOfficesQuery = ({ userUuid }) => {
	const path = `/v2/supplier/business/manager/${userUuid}/office-list`;

	const { data, isLoading, isError, error } = useQuery(
		QUERY_KEYS.OFFICES.ASSIGN_OFFICES(userUuid),
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !!userUuid,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isLoading, isError, error };
};
