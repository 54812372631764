import React from 'react';

// Import helpers
import { DELIVERY_TYPES } from 'helpers';

// Import components
import { DeliveryTabContent } from './components';

export const getDeliveryTabs = (booking) => {
	let DELIVERY_TABS = {};

	const { delivery } = booking || {};

	const { pick_up_delivery, drop_delivery } = delivery || {};

	if (pick_up_delivery.available) DELIVERY_TABS.PICKUP = 'pickup';
	if (drop_delivery.available) DELIVERY_TABS.RETURN = 'return';

	return DELIVERY_TABS;
};

export const getTab = (currentTab, outerDeliveryData) => {
	switch (currentTab) {
		case 0:
			return (
				<DeliveryTabContent
					outerDeliveryData={outerDeliveryData}
					type={DELIVERY_TYPES.PICK_UP_DELIVERY}
				/>
			);
		default:
			return (
				<DeliveryTabContent
					outerDeliveryData={outerDeliveryData}
					type={DELIVERY_TYPES.DROP_DELIVERY}
				/>
			);
	}
};
