import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils
import { useDeliveryStatus } from './useDeliveryStatus';
import { useTranslations } from 'components/utilities';

// Import components
import { ChipLabel } from 'components/elements/atoms';

// Import styles
import { useStyles } from './DeliveryStatusTableCell.styles';

export const DeliveryStatusTableCell = ({ cell, isDropOffSort }) => {
	const { chipLabel, chipColors } = useDeliveryStatus({ cell, isDropOffSort });
	const classes = useStyles({ chipColors });
	const { t } = useTranslations();

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ChipLabel
				fullWidth
				label={t(chipLabel)}
				color="gray"
				className={classes.chip}
			/>
		</TableCell>
	);
};

DeliveryStatusTableCell.propTypes = {
	isDropOffSort: bool.isRequired,
	cell: object.isRequired,
};
