import * as dateFns from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT, PERIODS } from 'helpers';

export const findPeriod = (date) => {
	const parseDate = (date) =>
		dateFns.parse(date, DEFAULT_DATE_FORMAT, new Date());
	return PERIODS.find(({ isActive }) => isActive(parseDate(date)));
};

export const convertBookingCell = (cell) => {
	const { booking_status: bookingStatus } = cell.row.original;

	return {
		...cell,
		row: {
			...cell.row,
			original: {
				...cell.row.original,
				state: bookingStatus,
			},
		},
	};
};
