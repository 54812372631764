import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { UrgentHistoryDialog } from './components';
import { MarkUrgentDialog, UrgentStatuses } from 'components/elements';

// Import context
import { useUrgentFormsProvider } from '../../context';
import { useMarkUrgentProvider } from 'components/context';

// Import utils
import { useTranslations } from 'components/utilities';

// Import assets
import { CheckMarkWithCircleIcon, HistoryIcon } from 'assets/icons';

// Import styles
import { useStyles } from './UrgentRequests.styles';

export const UrgentRequests = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const {
		isSomeUrgentReason,
		toggleUrgentHistory,
		isSomeReviewStatus,
		isMaxUrgentReasonsSize,
		urgentReasons,
	} = useUrgentFormsProvider();

	const { toggleMarkUrgent } = useMarkUrgentProvider();

	if (!isSomeUrgentReason) return null;

	return (
		<Box
			className={clsx({
				[classes.container]: !isMaxUrgentReasonsSize,
			})}
		>
			<Typography variant="body1" className={classes.title}>
				{t('bookings.urgent_tab.urgent_requests')}
			</Typography>
			<Grid container>
				<Grid item xs={12} md={8}>
					<UrgentStatuses urgentReasons={urgentReasons} />
					<Grid
						container
						justifyContent="flex-end"
						spacing={2}
						className={classes.buttonsContainer}
					>
						<Grid item>
							<Button
								onClick={toggleUrgentHistory}
								color="primary"
								variant="outlined"
								className={clsx([classes.button, classes.historyButton])}
								startIcon={<HistoryIcon />}
							>
								{t('common.buttons.urgent_history')}
							</Button>
						</Grid>
						{isSomeReviewStatus && (
							<Grid item>
								<Button
									onClick={toggleMarkUrgent}
									color="primary"
									variant="contained"
									startIcon={<CheckMarkWithCircleIcon />}
									className={classes.button}
								>
									{t('common.buttons.mark')}
								</Button>
							</Grid>
						)}
					</Grid>
				</Grid>
			</Grid>
			<UrgentHistoryDialog />
			<MarkUrgentDialog urgentReasons={urgentReasons} />
		</Box>
	);
};
