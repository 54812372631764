import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		padding: '12px 24px',
	},
	buttonsContainer: {
		margin: 0,
		marginTop: theme.spacing(4),
	},
	submitButton: {
		padding: '14px 24px',

		'&.Mui-disabled': {
			backgroundColor: theme.palette.background[3],
			color: theme.palette.gray.medium,
		},
	},
	runningRequest: {
		fontSize: 14,
		color: theme.palette.text[2],
		marginTop: '24px',
	},
}));
