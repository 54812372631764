import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BrandIcon = (props) => (
	<SvgIcon width="32" height="32" viewBox="0 0 32 32" fill="none" {...props}>
		<path
			d="M32.0006 18.5211V32H13.4705L0 18.5312V0H13.4797V18.5211H32.0006Z"
			fill="currentColor"
		/>
	</SvgIcon>
);
