import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	cell: {
		display: 'flex',
		alignItems: 'center',
	},
	button: {
		margin: theme.spacing(0),
		padding: '1px 16px',
		fontSize: '12px',
		visibility: ({ hideActive }) => (!hideActive ? 'visible' : 'hidden'),
		backgroundColor: ({ isActive }) =>
			isActive ? theme.palette.success.light : theme.palette.error.light,
		color: ({ isActive }) =>
			isActive ? theme.palette.success.medium : theme.palette.error.medium,
		'&:hover': {
			backgroundColor: ({ isActive }) =>
				isActive ? theme.palette.success.main : theme.palette.error.main,
			color: 'white',
		},
	},
	chip: {
		padding: '14px',
	},
}));

export default useStyles;
