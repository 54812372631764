import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeFilter, MultiCompaniesFilter } from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const FilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="due_date"
				nameUntil="due_date_to"
				label="common.filters.due_date"
				combinedInputs
			/>
			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					label="bookings.filters.company"
					xs={12}
					md={6}
				/>
			)}
		</Grid>
	);
};
