import moment from 'moment';

// Import utils
import { useTranslations } from 'components/utilities';
import { DELIVERY_TYPES } from 'helpers';

export const useAssignmentStatus = ({ deliveryType, cell }) => {
	const { t } = useTranslations();

	const {
		uuid,
		delivery,
		pick_time: pickTime,
		pick_date: pickDate,
		drop_date: dropDate,
	} = cell.row.original;

	const deliveryData = delivery[deliveryType];
	const {
		driver_assigned_at: driverAssignedAt,
		driver_name: driverName,
		has_late_assigment: lateAssignment,
		has_driver_assigned: hasDriverAssigned,
		assigned,
	} = deliveryData || {};

	const [assignDate, assignTime] = driverAssignedAt?.split(' ') || [];

	const isDriverAssigned = hasDriverAssigned || assigned;

	const getLateHoursLeft = () => {
		if (hasDriverAssigned) return null;
		const date =
			deliveryType === DELIVERY_TYPES.DROP_DELIVERY ? dropDate : pickDate;

		let hoursLeft = moment(`${date} ${pickTime}`).diff(moment(), 'hours');

		let hoursLeftText = '';

		if (hoursLeft === 2) hoursLeftText = `2 ${t('common.hours_left')}`;
		if (hoursLeft < 2)
			hoursLeftText = `${t('common.less_than')} 2 ${t('common.hours_left')}`;

		return hoursLeftText;
	};

	return {
		assignDate,
		assignTime: assignTime?.slice(0, 5),
		driverName,
		lateAssignment,
		hoursLeftText: getLateHoursLeft(),
		delivery,
		uuid,
		isDriverAssigned,
	};
};
