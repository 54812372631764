import { useFormState } from 'react-final-form';

// Import utils and helpers
import { FAQ_PAGE_LOCATION, FAQ_SERVICE_TYPE } from 'helpers';
import { useTranslations } from 'components/utilities';

export const useFaqDetails = () => {
	const { values } = useFormState();
	const { t } = useTranslations();

	const { faq_location: selectedPages, service_type: selectedServiceTypes } =
		values || {};

	const pages = [
		{
			uuid: FAQ_PAGE_LOCATION.HOME,
			label: 'faq.form.fields.home',
		},
		{
			uuid: FAQ_PAGE_LOCATION.CAR_DETAILS,
			label: 'faq.form.fields.car_details',
		},
	];

	const serviceTypes = Object.values(FAQ_SERVICE_TYPE).map((serviceType) => ({
		uuid: serviceType,
		label: `faq.form.fields.service_types.${serviceType}`,
	}));

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: t(options.find(({ uuid }) => uuid === currentUuid)?.label);

	return {
		selectedPages,
		selectedServiceTypes,
		pages,
		multiSelectLabelExtractor,
		serviceTypes,
	};
};
