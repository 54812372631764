import React from 'react';
import { func, object } from 'prop-types';
import { Autocomplete } from '@react-google-maps/api';
import { TextField } from '@material-ui/core';

// Import components
import { useLocationAutocomplete } from './useLocationAutocomplete';

export const LocationAutocomplete = ({ value, onChange }) => {
	const { handleLoad, handleChange, inputRef } = useLocationAutocomplete({
		value,
		onChange,
	});

	return (
		<Autocomplete
			fields={['geometry.location', 'formatted_address']}
			onLoad={handleLoad}
			onPlaceChanged={handleChange}
		>
			<TextField fullWidth variant="outlined" inputRef={inputRef} />
		</Autocomplete>
	);
};

LocationAutocomplete.propTypes = {
	value: object,
	onChange: func.isRequired,
};
