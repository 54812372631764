import React from 'react';
import { bool, object } from 'prop-types';

// Import components
import { PreviewField } from '../PreviewField';

// Import assets
import { CheckMarkIcon } from 'assets/icons';

// Import styles
import { useStyles } from './OptionsPreviewField.styles';

export const OptionsPreviewField = ({ customData, hideIfEmpty = false }) => {
	const classes = useStyles();

	const { options } = customData || {};

	return options.map(({ type, checked }) =>
		!checked && hideIfEmpty ? null : (
			<PreviewField key={type} label={`bookings.options.${type}`}>
				{checked ? <CheckMarkIcon className={classes.icon} /> : ''}
			</PreviewField>
		)
	);
};

OptionsPreviewField.propTypes = {
	customData: object,
	hideIfEmpty: bool,
};
