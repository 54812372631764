import React from 'react';
import { object } from 'prop-types';

// Import utils and helpers
import { CANCELLATION_FIELDS, convertCustomData } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import styles
import { useStyles } from './Cancellation.styles';

export const Cancellation = ({ data }) => {
	const convertedData = convertCustomData(data);
	const classes = useStyles();

	return (
		<PreviewFields
			customData={convertedData}
			fields={CANCELLATION_FIELDS({
				accessorClassName: classes.accessorClassName,
				labelClassName: classes.labelClassName,
				data: convertedData,
			})}
		/>
	);
};

Cancellation.propTypes = {
	data: object.isRequired,
};
