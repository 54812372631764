import React from 'react';
import { bool, func, node, object } from 'prop-types';
import { Box } from '@material-ui/core';
import { Form } from 'react-final-form';
import _ from 'lodash';

// Import components
import { useTableContextProvider } from 'components/context';
import { Content } from './components';

// Import utils
import { useQueryStringParams, useToggle } from 'components/utilities';

export const TableFiltersToolbar = ({
	prevAdditionalFilters,
	nextAdditionalFilters,
	dialogFilters,
	initialValues,
	formatValues,
	showAdditionalClearButton = false,
	showOuterFilterActionButtons = true,
}) => {
	const { setFilters, inputColumns } = useTableContextProvider();
	const { parsedFilters } = useQueryStringParams();
	const { on: isOpen, toggle } = useToggle();

	const handleSubmit = (values) => {
		const newValues = formatValues
			? formatValues(values, { inputColumns })
			: values;
		setFilters(newValues);
		isOpen && toggle();
	};

	const persistedFilters = _.mergeWith(
		parsedFilters,
		initialValues,
		(parsedValue, initialValue) =>
			_.isNil(parsedValue) ? initialValue : parsedValue
	);

	return (
		<Form
			onSubmit={handleSubmit}
			initialValues={persistedFilters}
			render={({ handleSubmit }) => (
				// eslint-disable-next-line
				<Box component="form" onSubmit={handleSubmit}>
					<Content
						prevAdditionalFilters={prevAdditionalFilters}
						nextAdditionalFilters={nextAdditionalFilters}
						dialogFilters={dialogFilters}
						initialValues={initialValues}
						isOpen={isOpen}
						toggle={toggle}
						showAdditionalClearButton={showAdditionalClearButton}
						showOuterFilterActionButtons={showOuterFilterActionButtons}
					/>
				</Box>
			)}
		/>
	);
};

TableFiltersToolbar.propTypes = {
	initialValues: object.isRequired,
	dialogFilters: node,
	prevAdditionalFilters: node,
	nextAdditionalFilters: node,
	formatValues: func,
	showAdditionalClearButton: bool,
	showOuterFilterActionButtons: bool,
};
