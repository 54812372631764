import React from 'react';
import { useHistory } from 'react-router-dom';

// Import components
import { Button } from 'components/elements';

// Import helpers
import { URLS } from 'components/routes';

export const OuterToolbarElements = () => {
	const { push } = useHistory();
	const goToAddFaq = () => push(URLS.createFaqUrl);

	return (
		<Button
			text="faq.table.toolbar.add_faq"
			variant="contained"
			onClick={goToAddFaq}
		/>
	);
};
