import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BaseSelectFilter, DateRangeFilter } from 'components/elements';

// Import utils
import { ROLE_FILTER_OPTIONS } from 'helpers';

export const AdditionalFilterFields = () => {
	return (
		<>
			<Grid item xs="auto">
				<DateRangeFilter
					nameFrom="date"
					nameUntil="date_to"
					label="employee_rewarding.table.filters.date_range"
					combinedInputs
					isToggleButton
					xs="auto"
					md="auto"
				/>
			</Grid>

			<BaseSelectFilter
				allLabel="employee_rewarding.table.filters.role"
				name="role"
				options={ROLE_FILTER_OPTIONS}
				smallFieldStyle
				xs="auto"
				md="auto"
			/>
		</>
	);
};
