import React from 'react';
import { Chip } from '@material-ui/core';
import { bool, object } from 'prop-types';

// Import utils
import { useStatusChip } from './useStatusChip';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './StatusChip.styles';

export const StatusChip = ({ hasLateDelivery, outerDeliveryData }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { isChipLabelVisible, label, isChipLabelRed } = useStatusChip({
		hasLateDelivery,
		outerDeliveryData,
	});

	return (
		isChipLabelVisible && (
			<Chip
				label={t(label)}
				className={isChipLabelRed ? classes.redChip : classes.greenChip}
				color="primary"
				size="small"
			/>
		)
	);
};

StatusChip.propTypes = {
	hasLateDelivery: bool,
	outerDeliveryData: object,
};
