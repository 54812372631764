import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	icon: {
		width: '20px',
		height: '20px',
	},
	phone: {
		fontWeight: 500,
	},
}));
