import { StepConnector, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	labelRoot: {
		alignItems: 'flex-start',
		position: 'relative',
	},
	optionalLabel: {
		fontSize: 14,
		fontWeight: 600,
		marginLeft: '24px',
	},
	stepIcon: {
		width: '16px',
		height: '16px',
		borderRadius: '50%',
		border: `2px solid ${theme.palette.success.medium}`,
	},
	notActiveStepIcon: {
		borderColor: theme.palette.background[3],
	},
	stepper: {
		padding: 0,
		marginBottom: '40px',

		'& .MuiStepLabel-iconContainer': {
			padding: 0,
		},

		'& .MuiStepConnector-vertical': {
			margin: 0,
			padding: 0,
			marginLeft: '7px',
		},
	},
	notActiveLabel: {
		color: theme.palette.text[3],
		fontSize: 12,
	},

	stepContent: {
		borderColor: theme.palette.background[3],
		borderWidth: '2px',
		marginLeft: '7px',
		marginTop: -5,
		paddingLeft: '30px',
	},

	date: {
		paddingRight: '24px',
	},
}));

export const CustomConnector = withStyles((theme) => ({
	line: {
		borderLeftWidth: '2px',
		borderStyle: 'solid',
		borderBottom: 0,
		borderTop: 0,
		borderRight: 0,
		borderColor: theme.palette.background[3],
	},
}))(StepConnector);
