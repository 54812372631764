export const GOALS_TYPES = {
	CAR_CATALOG: 'carCatalog',
	COMPANY_PROFILES: 'companyProfiles',
	MANAGERS_PROFILES: 'managerProfiles',
};

export const CARDS = [
	{
		title: 'company.quick_finder.car_catalog',
		body: 'company.quick_finder.car_catalog_description',
		type: GOALS_TYPES.CAR_CATALOG,
	},
	{
		title: 'company.quick_finder.company_profiles',
		body: 'company.quick_finder.company_profiles_description',
		type: GOALS_TYPES.COMPANY_PROFILES,
	},
	{
		title: 'company.quick_finder.manager_profiles',
		body: 'company.quick_finder.manager_profiles_description',
		type: GOALS_TYPES.MANAGERS_PROFILES,
	},
];
