import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';

// Import helpers
import { replaceEditPromoCodeUrl, replacePromoCodeUrl } from 'helpers';

// Import components
import {
	ActionsMenuTableCell,
	ActiveStatusTableCell,
	LinkTableCell,
	PeriodTableCell,
} from 'components/elements';
import { CustomActions } from '../CustomActions';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';

export const TableBodyRow = ({
	row,
	onMouseLeave,
	columnsAccessor,
	onMouseEnter,
	isHovered,
	ref,
}) => {
	const classes = useSharedStyles();

	const { CODE, PERIOD, CREATED_AT, STATUS, ACTIONS } = columnsAccessor;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			code,
			from,
			until,
			created_at: createdAt,
			created_by: createdBy,
			active,
		} = cell.row.original;

		const { id } = cell.column;

		switch (id) {
			case CODE:
				return (
					<LinkTableCell key={id} cell={cell} to={replacePromoCodeUrl(code)}>
						{code}
					</LinkTableCell>
				);

			case PERIOD:
				return (
					<PeriodTableCell
						key={id}
						cell={cell}
						outerDateBefore={from}
						outerDateAfter={until}
						isOneLine
					/>
				);

			case CREATED_AT:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{createdAt} <br /> {createdBy}
					</TableCell>
				);

			case STATUS:
				return <ActiveStatusTableCell key={id} cell={cell} isActive={active} />;

			case ACTIONS:
				return (
					<ActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						isHovered={isHovered}
						extraActions={{
							editAction: true,
							editPath: replaceEditPromoCodeUrl(code),
						}}
						customActions={<CustomActions cell={cell} />}
					/>
				);

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
