import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

const useCustomerWalletBalanceHistory = ({ customerUuid }) => {
	const path = `/v2/customer/${customerUuid}/wallet/balance/history`;

	const { data, isFetched, isError, error } = useQuery(
		QUERY_KEYS.CUSTOMERS.WALLET_BALANCE_HISTORY(customerUuid),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || null;

	return { data: fetchedData, isFetched, isError, error };
};

export default useCustomerWalletBalanceHistory;
