import services from 'services/services';
import {
	fetchSlidersUrl,
	updateActiveSliderUrl,
	deleteSliderUrl,
} from 'store/paths';

export const fetchSliders =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			// Get response
			const { data } = await services.post(
				fetchSlidersUrl(queryParams),
				body,
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveSlider = async ({ isActive, id }) => {
	await services.put(updateActiveSliderUrl(id, isActive));
};

export const deleteSlider = async ({ id }) =>
	await services.delete(deleteSliderUrl(id));
