import React from 'react';

// Import store
import { fetchTrainStationsList } from 'store/actions';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';

// Import components
import { OuterToolbarElements, TableBodyRow } from './components';

export const TrainStations = () => (
	<TableRPC
		outerToolbarElements={<OuterToolbarElements />}
		fetchDataTableAction={fetchTrainStationsList}
		columnsAccessor={COLUMNS_ACCESSORS}
		title="nav.kiosk.train_stations"
		tableBodyRow={TableBodyRow}
		columns={columns}
	/>
);
