import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EmployeeUserIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M14.1667 14.1673C14.1667 13.0167 13.234 12.084 12.0834 12.084H7.91671C6.76611 12.084 5.83337 13.0167 5.83337 14.1673"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.1667 14.1673C14.1667 13.0167 13.234 12.084 12.0834 12.084H7.91671C6.76611 12.084 5.83337 13.0167 5.83337 14.1673"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<circle
			cx="9.99996"
			cy="7.91732"
			r="2.08333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<circle
			cx="9.99996"
			cy="7.91732"
			r="2.08333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10 14.1673V12.084"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10 14.1673V12.084"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<rect
			x="2.5"
			y="2.5"
			width="15"
			height="15"
			rx="5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<rect
			x="2.5"
			y="2.5"
			width="15"
			height="15"
			rx="5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
