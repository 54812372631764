// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	replaceEditLeasingCarUrl,
} from 'helpers';

// Import store
import { deleteLeasingCar } from 'store/actions';

const { EDIT, DELETE } = ACTION_MENU_POPUP_FIELD_TYPES;

const deleteAction = (vehicleId) => deleteLeasingCar(vehicleId);

export const getActionMenuFields = ({ officeId, vehicleId }) => [
	{
		type: EDIT,
		editViewUrl: replaceEditLeasingCarUrl({ vehicleId, officeId }),
	},
	{ type: DELETE, deleteAction: () => deleteAction(vehicleId) },
];
