import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(1),
		color: 'inherit',
	},
	content: {
		fontSize: 14,
		color: theme.palette.text[2],

		'&:last-child': {
			marginBottom: theme.spacing(3),
		},
	},
	differencePrice: {
		backgroundColor: theme.palette.primary.medium,
		color: theme.palette.primary.main,
		padding: '6px 12px',
		borderRadius: '8px',
		fontWeight: 600,
		marginBottom: theme.spacing(1),
		width: 'fit-content',
	},
	monitorLimit: {
		fontSize: 12,
		color: theme.palette.text[1],
	},
	differencePriceDecreased: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},
}));
