// Import helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';

const { REVIEW, APPROVED, REJECTED, COMPENSATED } = BOOKING_CANCELLATION_STATES;

export const OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: REVIEW,
		label: 'bookings.filters.review',
	},
	{
		value: APPROVED,
		label: 'bookings.filters.approved',
	},
	{
		value: REJECTED,
		label: 'bookings.filters.rejected',
	},
	{
		value: COMPENSATED,
		label: 'bookings.filters.compensated',
	},
];
