import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components';
import { OptionLabels } from '../../components';

// Import utils and helpers
import { getSummaryCustomData, SUMMARY_FIELDS } from './helpers';
import { useTabsProvider } from 'components/context';

export const Summary = () => {
	const { data: booking } = useTabsProvider();

	const customData = getSummaryCustomData({ booking });

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<PreviewFields fields={SUMMARY_FIELDS} customData={customData} />
			</Grid>
			<Grid item xs={12}>
				<OptionLabels />
			</Grid>
		</Grid>
	);
};
