import palette from 'theme/palette';

export default {
	root: {
		color: palette.text.inputLabel,
		fontWeight: 600,
	},
	formControl: {
		position: 'relative',
		paddingTop: '16px',
		top: -10,
		'@media (max-width: 960px)': {
			top: 0,
			width: '40%',
		},
	},
};
