// Import helpers
import {
	COMPANY_MANAGERS_WITHOUT_ADMINS,
	convertPaymentMethodFilterValues,
	convertFormDates,
	SERVICES_TYPES,
	removePreviousFilterValues,
	getFilterIndex,
	spreadFilterArray,
	ADMINS,
} from 'helpers';

export const initialValues = {
	company_uuid: 'all',
	office_uuid: 'all',
	state: 'all',
	manipulation: 'all',
	office_rating: 'all',
	rating_reasons: 'all',
	extension: 'all',
	payment: 'all',
	payment_type: null,
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
	created_at: null,
	paid_at_date: null,
	paid_at_date_to: null,
	is_compensation: 'all',
	is_subscription: 'all',
	is_delivery: 'all',
	is_discount: 'all',
	is_settled: 'all',
	is_kiosk: 'all',
	paid_at: null,
	paid_at_to: null,
	extension_created_at: null,
	extension_created_at_to: null,
	cancellation_date: null,
	cancellation_date_to: null,
	contract_opened_at_date: null,
	contract_opened_at_date_to: null,
	contract_closed_at_date: null,
	contract_closed_at_date_to: null,
	service_type: 'all',
	airport_uuid: 'all',
	discount_code: [],
	cities: ['all'],
	partners: ['all'],
};

export const formatValues = (values, { inputColumns }) => {
	const cityRemovedValues = removePreviousFilterValues({
		values,
		inputColumns,
		filterName: 'office_city',
		defaultFilterIndex: 46,
	});

	const newValues = removePreviousFilterValues({
		values: cityRemovedValues,
		inputColumns,
		filterName: 'external_partner',
		defaultFilterIndex: 16,
	});

	const cityFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'office_city',
		defaultFilterIndex: 46,
	});

	const partnerFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'external_partner',
		defaultFilterIndex: 16,
	});

	return {
		...newValues,
		state: values.state === 'all' ? null : values.state,
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
		office_uuid: values.office_uuid === 'all' ? null : values.office_uuid,
		is_compensation:
			values.is_compensation === 'all' ? null : values.is_compensation,
		is_settled: values.is_settled === 'all' ? null : values.is_settled,
		is_delivery: values.is_delivery === 'all' ? null : values.is_delivery,
		office_rating: values.office_rating === 'all' ? null : values.office_rating,
		is_kiosk: values.is_kiosk === 'all' ? null : values.is_kiosk,
		is_discount: values.is_discount === 'all' ? null : values.is_discount,
		is_subscription:
			values.is_subscription === 'all' ? null : values.is_subscription,
		manipulation: values.manipulation === 'all' ? null : values.manipulation,
		rating_reasons:
			values.rating_reasons === 'all' ? null : values.rating_reasons,
		extension: values.extension === 'all' ? null : values.extension,
		service_type: values.service_type === 'all' ? null : values.service_type,
		airport_uuid:
			values.airport_uuid === 'all' ||
			values.service_type !== SERVICES_TYPES.AIRPORT
				? null
				: values.airport_uuid,
		...spreadFilterArray({
			arrayElements: values.cities,
			filterIndex: cityFilterIndex,
			defaultFilterIndex: 46,
			inputColumns,
		}),

		...spreadFilterArray({
			arrayElements: values.partners,
			filterIndex: partnerFilterIndex,
			defaultFilterIndex: 16,
			inputColumns,
		}),

		...convertFormDates(values, [
			'paid_at',
			'pick_date',
			'drop_date',
			'created_at',
			'contract_opened_at_date',
			'contract_closed_at_date',
			'extension_created_at',
			'cancellation_date',
		]),
		...convertPaymentMethodFilterValues(values),
		discount_code: values.discount_code ? values.discount_code.join(',') : '',
	};
};

export const PERMISSION_COMPANY_FILTER = ADMINS;
export const PERMISSION_OFFICE_FILTER = COMPANY_MANAGERS_WITHOUT_ADMINS;
