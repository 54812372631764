import { useFormState } from 'react-final-form';

export const useFormContent = () => {
	const { values, submitting } = useFormState();
	const { rating } = values || {};

	const isSubmitButtonDisabled = !rating || submitting;

	return {
		isSubmitButtonDisabled,
		submitting,
	};
};
