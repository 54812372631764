import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const EditUserIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M15.0005 5.2C16.7005 6.9 16.7005 9.6 15.0005 11.2C13.3005 12.8 10.6005 12.9 9.00048 11.2C7.40048 9.5 7.30048 6.8 9.00048 5.2C10.7005 3.6 13.3005 3.6 15.0005 5.2"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4 20C4 17.5 6 15.5 8.5 15.5H11.1"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M14 21H16.3L19.7 17.6C20.1 17.2 20.1 16.6 19.7 16.2L18.8 15.3C18.4 14.9 17.8 14.9 17.4 15.3L14 18.7V21V21H14Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
