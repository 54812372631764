import { useSelector } from 'react-redux';

//Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { getCustomData, getEarlyReturnFields } from './helpers';
import { useProfile } from 'components/utilities';
import { useBookingInvoice } from 'queries';
import { BILLING_TYPES } from 'helpers';

export const useInvoice = () => {
	const booking = useSelector(previewBookingSelector);
	const { isAdmin } = useProfile();

	const {
		can_be_returned_earlier: canBeReturnedEarlier,
		early_return_details: earlyReturnDetails,
		uuid: bookingUuid,
		subscription,
		billings,
	} = booking || {};

	const isClosePayment =
		billings &&
		billings.some(({ type }) => type === BILLING_TYPES.BOOKING_CLOSE);

	const isEarlyReturnDialogVisible =
		!subscription && canBeReturnedEarlier && isAdmin;

	const {
		data: previousCompanyInvoiceData,
		isFetching: isPreviousCompanyInvoiceFetching,
		isError: isPreviousCompanyInvoiceError,
	} = useBookingInvoice({ bookingUuid, isTransferred: true });

	const {
		data: currentCompanyInvoiceData,
		isFetching: isCurrentCompanyInvoiceFetching,
		isError: isCurrentCompanyInvoiceError,
	} = useBookingInvoice({ bookingUuid });

	const isPreviousCompanyInvoice = !!booking.transferred_at && isAdmin;
	const isCancellationVisible =
		!!currentCompanyInvoiceData?.cancellation?.booking_cancelled_at;

	const earlyReturnCustomData =
		earlyReturnDetails &&
		getCustomData({
			isSubscription: subscription,
			earlyReturnDetails,
		});

	const earlyReturnFields =
		earlyReturnDetails &&
		getEarlyReturnFields({
			isSubscription: subscription,
			earlyReturnDetails,
		});

	const isEarlyReturnDetails = earlyReturnDetails && isAdmin;
	const subscriptionUuid = subscription?.uuid;

	const gridMd =
		isPreviousCompanyInvoice || isCancellationVisible || isEarlyReturnDetails
			? 6
			: 12;

	const getPriceDifference = ({
		isBookingTransferred,
		previousPrice,
		currentPrice,
	}) => {
		if (isBookingTransferred) {
			return Number(currentPrice) - Number(previousPrice);
		} else {
			return null;
		}
	};

	const previousPrice = previousCompanyInvoiceData?.total?.amount_gross;
	const currentPrice = currentCompanyInvoiceData?.total?.amount_gross;

	const priceDifference = getPriceDifference({
		isBookingTransferred: isPreviousCompanyInvoice,
		previousPrice,
		currentPrice,
	});

	return {
		isPreviousCompanyInvoiceFetching,
		isPreviousCompanyInvoiceError,
		isCurrentCompanyInvoiceFetching,
		isCurrentCompanyInvoiceError,
		isEarlyReturnDialogVisible,
		isPreviousCompanyInvoice,
		isCancellationVisible,
		isEarlyReturnDetails,
		previousCompanyInvoiceData,
		currentCompanyInvoiceData,
		earlyReturnCustomData,
		earlyReturnFields,
		subscriptionUuid,
		priceDifference,
		gridMd,
		isClosePayment,
		booking,
	};
};
