import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckMarkIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			id="Path"
			d="M20 6.5L9 17.5L4 12.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
