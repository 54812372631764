import React from 'react';
import { Box, Button, Collapse, Grid } from '@material-ui/core';
import { number, object, string } from 'prop-types';
import { useSelector } from 'react-redux';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { useProfile, useToggle, useTranslations } from 'components/utilities';
import { usePaymentProvider } from './context';
import {
	getChipColor,
	getChipLabel,
	SUBSCRIPTION_INVOICE_PAYMENT_FIELDS,
} from './helpers';

// Import components
import { PreviewFields } from '../../../../../../../../components';
import { ChipLabel, EarlyReturnDialog } from 'components/elements';
import { OfflineSettlementDialog } from '../../components';

// Import styles
import { useStyles } from './Payment.styles';

export const Payment = ({ title }) => {
	const { on: isOpen, toggle } = useToggle();
	const { isAdmin } = useProfile();
	const { t } = useTranslations();
	const classes = useStyles();

	const { canSettle, isSettled, paymentCustomData, isOfflinePayment } =
		usePaymentProvider();

	const chipColor = getChipColor(isSettled);
	const chipLabel = getChipLabel(isSettled);

	const { can_be_returned_earlier: canBeReturnedEarlier } = useSelector(
		previewBookingSelector
	);

	const isEarlyReturnDialogVisible = isAdmin && canBeReturnedEarlier;

	return (
		<Box className={classes.container}>
			<Box className={classes.topBar}>
				<Button
					className={classes.button}
					disableRipple
					onClick={toggle}
					endIcon={isOpen ? <ExpandLess /> : <ExpandMore />}
				>
					{title}
				</Button>
				<Box className={classes.settlementBox}>
					{canSettle && (
						<Grid
							container
							direction="row"
							justifyContent="flex-end"
							spacing={1}
						>
							{!isSettled && (
								<Grid item>
									<OfflineSettlementDialog />
								</Grid>
							)}
							{isEarlyReturnDialogVisible && (
								<Grid item>
									<EarlyReturnDialog />
								</Grid>
							)}
						</Grid>
					)}

					<ChipLabel color={chipColor} label={t(chipLabel)} />
				</Box>
			</Box>
			<Collapse in={isOpen} className={classes.collapse}>
				<Grid container className={classes.innerCollapseBox}>
					<PreviewFields
						customData={paymentCustomData}
						fields={SUBSCRIPTION_INVOICE_PAYMENT_FIELDS(isOfflinePayment)}
					/>
				</Grid>
			</Collapse>
		</Box>
	);
};

Payment.propTypes = {
	subscriptionUuid: string,
	paymentNumber: number,
	payment: object,
	title: string,
};
