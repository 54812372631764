import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiDialog-paper': {
			width: '60vw',
		},
	},
	title: {
		fontSize: '18px',
		fontWeight: '600',
		color: theme.palette.text[4],
		padding: '0px',
	},
	subtitle: {
		fontSize: '16px',
		fontWeight: '400',
		color: theme.palette.text[4],
		padding: '0px 24px',
		marginBottom: '40px',
	},
	infoContainer: {
		display: 'flex',
		gap: '3px',
		marginBottom: '8px',
	},
	text: {
		fontSize: '16px',
		fontWeight: '400',
		color: theme.palette.text[4],
	},
	value: {
		fontSize: '16px',
		fontWeight: '600',
		color: theme.palette.warning[1],
	},
	fieldsContainer: {
		marginTop: '40px',
	},
	iconContainer: {
		display: 'flex',
		gap: '8px',
	},
	icon: {
		color: theme.palette.text[2],
	},
	iconText: {
		fontSize: '14px',
		fontWeight: '400',
		color: theme.palette.text[2],
	},
	btnsContainer: {
		marginTop: '40px',
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	cancel: {
		color: theme.palette.text[1],
		padding: '19px 24px 20px 24px',
		height: '48px',
	},
	send: {
		color: theme.palette.white,
		backgroundColor: theme.palette.primary.main,
		padding: '19px 24px 20px 24px',
		borderRadius: '12px',
		height: '48px',
		'&:hover': {
			backgroundColor: theme.palette.primary.main,
		},
		'&:disabled': {
			backgroundColor: theme.palette.background[7],
		},
	},
}));
