import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	BaseYesNoAllFilter,
	CompanyFilter,
	DateRangeInput,
} from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<>
			{isAdmin && (
				<CompanyFilter
					label=""
					name="company_uuid"
					smallFieldStyle
					withSearch
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			)}
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="pick_date"
					nameUntil="pick_date_to"
					label="bookings.filters.pick_date"
					combinedInputs
					isToggleButton
				/>
			</Grid>
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="created_at"
					nameUntil="created_at_to"
					label="common.filters.created_at"
					combinedInputs
					isToggleButton
				/>
			</Grid>
			<BaseYesNoAllFilter
				allLabel="common.fields.compensation"
				name="is_compensation"
				smallFieldStyle
				xs="auto"
				md="auto"
			/>
		</>
	);
};
