import React from 'react';
import { Box } from '@material-ui/core';

// Import assets
// import { TriangleExclamationIcon } from 'assets/icons';

//Import styles
import { useStyles } from './CustomerLocation.styles';

// Import components
import { CollapsibleMap } from './components';
// import { Typography } from 'components/elements';

// Import utilities and helpers
// import { useTranslations } from 'components/utilities';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';
import { useCustomerLocation } from './useCustomerLocation';

export const CustomerLocation = () => {
	const classes = useStyles();
	// const { t } = useTranslations();
	const { isPickup } = useCustomerLocation();

	return (
		<Box>
			<Box className={classes.mapsContainer}>
				{isPickup ? (
					<CollapsibleMap section="location" label={`${transPrefix}.pickup`} />
				) : (
					<>
						<CollapsibleMap
							section="delivery"
							label={`${transPrefix}.delivery`}
						/>
						<CollapsibleMap section="return" label={`${transPrefix}.return`} />
					</>
				)}
			</Box>
			{/* This section will be hidden until BE for SMS is handled */}
			{/* <Box className={classes.alertContainer}>
				<TriangleExclamationIcon className={classes.icon} />
				<Typography className={classes.alert}>
					{t('bookings.create_booking.location_sms_alert')}
				</Typography>
			</Box> */}
		</Box>
	);
};
