import React, { useEffect, useState } from 'react';
import { object, node, string, func, oneOfType, bool } from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';

// Import components
import { SearchInput } from './components';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';

export const SelectFieldWithSearchAdapter = ({
	input,
	meta,
	children,
	smallfieldstyle = false,
	emptyLabel,
	icon: Icon,
	...rest
}) => {
	const classes = useSharedStyles();
	const [filteredChildren, setFilteredChildren] = useState(children);
	const { t } = useTranslations();

	const handleOnSearch = (e) => {
		const searchValue = e.target.value;
		const filteredChildren = children?.filter(({ props }) =>
			props?.children?.toLowerCase()?.includes(searchValue.toLowerCase())
		);
		setFilteredChildren(filteredChildren);
	};

	useEffect(() => {
		setFilteredChildren(children);
	}, [children]);

	const findValue = (value) =>
		filteredChildren?.flat()?.find((child) => child?.props?.value === value)
			?.props?.children;

	const renderValue = (value) =>
		Icon ? (
			<Grid container spacing={1} alignItems="center">
				<Grid item>
					<Icon />
				</Grid>
				<Grid item>{value} </Grid>
			</Grid>
		) : (
			value
		);

	return (
		<TextField
			select
			className={smallfieldstyle ? classes.smallField : ''}
			{...input}
			{...rest}
			error={meta.touched && (meta.error || meta.submitError) ? true : false}
			helperText={
				meta.touched && (meta.error || meta.submitError)
					? t(meta.error) || meta.submitError
					: ''
			}
			SelectProps={{
				renderValue: (value) =>
					emptyLabel && value === 'all'
						? renderValue(emptyLabel)
						: renderValue(findValue(value)),
			}}
		>
			<SearchInput onChange={handleOnSearch} />
			{filteredChildren}
		</TextField>
	);
};

SelectFieldWithSearchAdapter.propTypes = {
	label: string.isRequired,
	input: object.isRequired,
	meta: object.isRequired,
	children: node.isRequired,
	labelExtractor: func,
	smallfieldstyle: oneOfType([string, bool]),
	emptyLabel: string,
	icon: func,
};
