import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useAssignUrgent } from './useAssignUrgent';

// Import components
import { UrgentForms } from './components';

// Import styles
import { useStyles } from './AssignUrgent.styles';

export const AssignUrgent = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { isMaxUrgentReasonsSize } = useAssignUrgent();

	return (
		<Box>
			{!isMaxUrgentReasonsSize && (
				<Typography variant="h4" className={classes.title}>
					{t('bookings.urgent_tab.title')}
				</Typography>
			)}
			<UrgentForms />
		</Box>
	);
};
