import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import utilities
import { fetchDriverFormResources, submitDriverForm } from 'store/actions';
import { FormRPC, useProfile } from 'components/utilities';

// Import helpers
import { driverFormSelector } from 'store/selectors';
import { URLS } from 'components/routes';

// Import components
import { Details } from './components';

// Import styles
import useStyles from './styles';

export const DriverForm = () => {
	const classes = useStyles();

	const { companyUuid } = useProfile();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={(options) =>
					fetchDriverFormResources({ ...options, companyUuid })
				}
				submitFormAction={submitDriverForm}
				isCardLayout={false}
				goBackPath={URLS.driversUrl}
				store={driverFormSelector}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<Details />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
