export const convertPaymentCollectionCell = (cell) => {
	const {
		booking_individual_number: bookingIndividualNumber,
		booking_status: bookingStatus,
	} = cell.row.original;

	return {
		...cell,
		row: {
			...cell.row,
			original: {
				...cell.row.original,
				individual_number: bookingIndividualNumber,
				state: bookingStatus,
			},
		},
	};
};
