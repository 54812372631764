import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeInput } from 'components/elements';

export const AdditionalFilterFields = () => (
	<>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="period"
				nameUntil="period_to"
				label="common.filters.period"
				combinedInputs
				isToggleButton
			/>
		</Grid>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="created_at"
				nameUntil="created_at_to"
				label="common.filters.created_at"
				combinedInputs
				isToggleButton
			/>
		</Grid>
	</>
);
