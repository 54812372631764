// Import translations
import { useTranslations } from 'components/utilities';

// Import variables
import { AR } from 'helpers';

export const useIsRTL = () => {
	const {
		i18n: { language },
	} = useTranslations();

	const isRtl = language === AR;

	return isRtl;
};
