import { makeStyles } from '@material-ui/core';

// Import helpers
import { BOOKING_COMPENSATION_STATES } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(4),
		textTransform: 'capitalize',
	},
	emptyRequests: {
		fontSize: 16,
		color: theme.palette.gray.medium,
		marginBottom: theme.spacing(4),
	},

	statusChip: {
		borderRadius: '100px',
		textTransform: 'capitalize',
		fontWeight: 600,
		fontSize: 12,
		minWidth: '92px',
		padding: '12px 20px',
	},

	[BOOKING_COMPENSATION_STATES.APPROVED]: {
		backgroundColor: theme.palette.success.lighter,
		color: theme.palette.success.medium,
	},

	[BOOKING_COMPENSATION_STATES.REJECTED]: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},

	[BOOKING_COMPENSATION_STATES.INVALID]: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},

	[BOOKING_COMPENSATION_STATES.REVIEW]: {
		backgroundColor: theme.palette.warning.medium,
		color: theme.palette.warning.dark,
	},

	[BOOKING_COMPENSATION_STATES.WAITING]: {
		backgroundColor: theme.palette.warning.medium,
		color: theme.palette.warning.dark,
	},

	[BOOKING_COMPENSATION_STATES.COMPENSATED]: {
		backgroundColor: theme.palette.background[6],
		color: theme.palette.text[1],
	},

	editContainer: {
		display: 'flex',
		justifyContent: 'flex-end',
	},

	editButton: {
		borderColor: theme.palette.background[3],
	},

	type: {
		fontSize: 14,
		fontWeight: 600,
	},
	reason: {
		fontSize: 12,
		color: theme.palette.text[2],
	},
}));
