import React from 'react';
import { array, string } from 'prop-types';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { useDynamicField } from './useDynamicField';

// Import styles
import { useStyles } from './DynamicField.styles';

export const DynamicField = ({ label, FIELDS }) => {
	const { renderFields } = useCommonFields();
	const { on, onCheckboxChange } = useDynamicField({ FIELDS });

	const classes = useStyles();
	return (
		<Box className={classes.container}>
			<FormControlLabel
				control={
					<Checkbox onChange={onCheckboxChange} className={classes.checkbox} />
				}
				label={label}
			/>
			{on &&
				FIELDS.map((FIELD) => (
					<Box className={classes.fieldWrapper} key={FIELD.name}>
						{renderFields(FIELD)}
					</Box>
				))}
		</Box>
	);
};

DynamicField.propTypes = {
	label: string.isRequired,
	FIELDS: array.isRequired,
};
