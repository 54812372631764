import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DiscountIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.79875 9.10083L3.59542 10.1435L3.76742 11.4415C3.86275 12.1615 4.42875 12.7282 5.14875 12.8242L6.45008 12.9982L7.49208 13.7942C8.06875 14.2348 8.86808 14.2348 9.44475 13.7942L10.4874 12.9975H10.4861L11.7847 12.8255C12.5048 12.7302 13.0714 12.1642 13.1674 11.4442L13.3408 10.1428C13.3408 10.1435 13.7434 9.61617 14.1374 9.10083C14.5781 8.52417 14.5774 7.72483 14.1374 7.14817L13.3421 6.1055L13.1701 4.8075C13.0747 4.0875 12.5088 3.52083 11.7888 3.42483L10.4868 3.2515L9.44475 2.4555C8.86808 2.01483 8.06875 2.01483 7.49208 2.4555L6.44942 3.2515H6.45075L5.15208 3.42417C4.43208 3.5195 3.86542 4.0855 3.76942 4.8055L3.59542 6.10683C3.59542 6.10617 3.19275 6.6335 2.79875 7.14883C2.35875 7.72483 2.35875 8.52483 2.79875 9.10083Z"
			fill="#BA8830"
			stroke="#BA8830"
			strokeWidth="0.792952"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M7.07031 9.51875L9.87031 6.71875"
			stroke="currentColor"
			strokeWidth="0.792952"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9.99711 9.42346C10.0598 9.48613 10.0598 9.58746 9.99711 9.64946C9.93445 9.71213 9.83311 9.71213 9.77111 9.64946C9.70845 9.58679 9.70845 9.48546 9.77111 9.42346C9.83311 9.36079 9.93445 9.36079 9.99711 9.42346"
			stroke="currentColor"
			strokeWidth="0.792952"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.16886 6.59521C7.23153 6.65788 7.23153 6.75921 7.16886 6.82121C7.1062 6.88388 7.00486 6.88388 6.94286 6.82121C6.88086 6.75854 6.8802 6.65721 6.94286 6.59521C7.00553 6.53321 7.10686 6.53254 7.16886 6.59521"
			stroke="currentColor"
			strokeWidth="0.792952"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
