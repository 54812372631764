import React from 'react';
import { MenuItem } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';

// Import utils
import { useRentalCompaniesProvider } from '../../context';
import { useTranslations } from 'components/utilities';

// Import components
import { MultiSelectFieldWithSearchAdapter } from 'components/elements';

export const MultiSelect = () => {
	const { companiesList } = useRentalCompaniesProvider();

	const { t, i18n } = useTranslations();
	const { change } = useForm();

	const multiSelectLabelExtractor = (options, currentUuid) =>
		options.find(({ uuid }) => uuid === currentUuid)?.name[i18n.language];

	const handleOnMultiSelectChange = (event) => {
		const { value, name } = event.target;

		const filteredValue = value.filter((value) => null != value);

		change(name, filteredValue);
	};

	const convertedOptions = Object.values(companiesList);

	return (
		<Field
			format={(value) => (Array.isArray(value) ? value : [])}
			label={t('common.fields.company_name')}
			component={MultiSelectFieldWithSearchAdapter}
			labelextractor={(option) =>
				multiSelectLabelExtractor(convertedOptions, option)
			}
			variant="outlined"
			margin="dense"
			type="select"
			name="company_uuids"
			fullWidth
			onChange={handleOnMultiSelectChange}
			displayEmpty
		>
			{convertedOptions.map(({ uuid, name }) => (
				<MenuItem key={uuid} value={uuid}>
					{name[i18n.language]}
				</MenuItem>
			))}
		</Field>
	);
};
