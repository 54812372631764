import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useAutoVehicleModelCollection = ({ name, maker_id }) => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.CARS.AUTO_MODEL_COLLECTION(`${name}, ${maker_id}`),
		async () =>
			await services.post(ENDPOINTS.CARS.AUTO_MODEL_COLLECTION, {
				name,
				auto: { maker_id },
			}),
		{ refetchOnWindowFocus: false, cacheTime: 400, enabled: !!name }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default useAutoVehicleModelCollection;
