import { addDays, format } from 'date-fns';
import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import helpers and utils
import { FIELD_TYPES, DEFAULT_DATE_FORMAT } from 'helpers';
import { useProfile, useToggle, useTranslations } from 'components/utilities';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { fetchBookingPreview } from 'store/actions';

// Import context
import { useEditBookingFormProvider } from '../../context';

export const useEditBookingPeriod = () => {
	const { t } = useTranslations();
	const { values, submitting, submitSucceeded } = useFormState();
	const { change } = useForm();
	const { supplier, period } = values || {};
	const { period: currentBookingPeriod } = useSelector(previewBookingSelector);
	const { isCorporateCoordinator } = useProfile();

	const { id: itemId } = useParams();

	const dispatch = useDispatch();

	const { on: isSuccessEditPeriod, toggle: toggleSuccessEditPeriod } =
		useToggle();

	const {
		toggleChangePeriodConfirm,
		isChangePeriodConfirmOpen,
		isPeriodValuesChanged,
		toggleEditHistory,
		initialHistoryValues,
	} = useEditBookingFormProvider();

	useEffect(() => {
		if (submitSucceeded) {
			toggleChangePeriodConfirm();
			toggleSuccessEditPeriod();
		}
		// eslint-disable-next-line
	}, [submitSucceeded]);

	const { days, pick_date: pickDate, drop_date: dropoffDate } = period || {};

	const pickUpTimeList = Object.entries(supplier.time_list);

	useEffect(() => {
		pickDate &&
			change(
				'period.drop_date',
				format(addDays(new Date(pickDate), days), DEFAULT_DATE_FORMAT)
			);
		// eslint-disable-next-line
	}, [pickDate]);

	const fields = [
		{
			type: FIELD_TYPES.DATE,
			label: 'common.fields.pickup_date',
			name: 'period.pick_date',
			minDate: false,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.pickup_time',
			name: 'period.pick_time',
			options: pickUpTimeList,
			keyExtractor: (time) => time[0],
			labelExtractor: (time) => time[1],
		},
	];

	const resetPeriod = () => {
		change('period', currentBookingPeriod);
	};

	const handleSuccessOk = () => {
		toggleSuccessEditPeriod();
		fetchBookingPreview({
			id: itemId,
			isCorporateCoordinator,
		})(dispatch);
	};

	const getPeriodLabel = ({
		drop_date: dropDate,
		pick_date: pickDate,
		pick_time: pickTime,
		days,
	}) => {
		const formattedPickDate = format(new Date(pickDate), DEFAULT_DATE_FORMAT);
		const formattedDropDate = format(new Date(dropDate), DEFAULT_DATE_FORMAT);

		return `${formattedPickDate} ${t(
			'common.to'
		)} ${formattedDropDate} (${days} ${t(
			`common.${days > 1 ? 'days' : 'day'}`
		)}) - ${pickTime}`;
	};

	const prevDate = getPeriodLabel(currentBookingPeriod);

	const currentDate = getPeriodLabel(period);

	const isHistory = !!initialHistoryValues?.length;

	return {
		fields,
		dropoffDate,
		resetPeriod,
		toggleChangePeriodConfirm,
		isChangePeriodConfirmOpen,
		isPeriodValuesChanged,
		submitting,
		itemId,
		submitSucceeded,
		isSuccessEditPeriod,
		toggleSuccessEditPeriod,
		handleSuccessOk,
		toggleEditHistory,
		prevDate,
		currentDate,
		isHistory,
	};
};
