import React from 'react';
import clsx from 'clsx';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Import assets
import { CheckMarkIcon } from 'assets/icons';

// Import utilities and context
import { useBookingStatusActionsProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import components
import { ExtensionInfoDialog } from 'views/Bookings/BookingDetails/components';
import {
	NotificationAlert,
	ButtonProgress,
	CancelAndRejectBookingForm,
} from 'components/elements';

// Import styles
import { useStyles } from './BookingStatusActions.styles';

export const BookingStatusActions = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		error,
		canAccept,
		canReject,
		clearError,
		handleClose,
		handleAccept,
		handleReject,
		handleChange,
		handleAcceptExtension,
		handleRejectExtension,
		isRejectExtensionLoading,
		isAcceptExtensionLoading,
		isExtensionStatusPending,
		isExtensionDialog,
		isButtonDisabled,
		isRejection,
		isCancellation,
		booking,
		isStatusAccepted,
		handleOpenContract,
		isOpenContractLoading,
		isBookingCaptured,
	} = useBookingStatusActionsProvider();

	return (
		<>
			{error && <NotificationAlert open onClose={clearError} message={error} />}
			<CancelAndRejectBookingForm
				booking={booking}
				onChange={handleChange}
				open={isRejection}
				onClose={handleClose}
				dialogTitle="bookings.reject_booking_form.title"
				isRejection
			/>
			<CancelAndRejectBookingForm
				booking={booking}
				onChange={handleChange}
				open={isCancellation}
				onClose={handleClose}
				dialogTitle="bookings.cancel_booking_form.title"
			/>
			<Grid container justifyContent="flex-start" spacing={2}>
				{canAccept && (
					<Grid item>
						<IconButton
							color="primary"
							onClick={handleAccept}
							className={classes.acceptButton}
						>
							<CheckMarkIcon className={classes.icon} />
						</IconButton>
					</Grid>
				)}

				{canReject && (
					<Grid item>
						<IconButton onClick={handleReject} className={classes.rejectButton}>
							<CloseIcon className={classes.icon} />
						</IconButton>
					</Grid>
				)}

				{isExtensionStatusPending && !isBookingCaptured && (
					<Grid item>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<ButtonProgress
									variant="contained"
									color="primary"
									size="small"
									className={classes.button}
									isLoading={isAcceptExtensionLoading}
									disabled={isButtonDisabled}
									onClick={handleAcceptExtension}
									circularProgressSize={24}
								>
									{t('bookings.state_actions.accept_extension')}
								</ButtonProgress>
							</Grid>
							<Grid item xs={12}>
								<ButtonProgress
									startIcon={<CloseIcon />}
									variant="outlined"
									color="inherit"
									size="small"
									className={clsx([classes.button, classes.rejected])}
									isLoading={isRejectExtensionLoading}
									disabled={isButtonDisabled}
									onClick={handleRejectExtension}
									circularProgressSize={24}
								>
									{t('bookings.state_actions.reject_extension')}
								</ButtonProgress>
							</Grid>
						</Grid>
					</Grid>
				)}
				{isStatusAccepted && (
					<Grid item xs={12}>
						<ButtonProgress
							variant="outlined"
							color="primary"
							size="small"
							className={clsx([classes.button, classes.contractOpened])}
							isLoading={isOpenContractLoading}
							disabled={isOpenContractLoading}
							onClick={handleOpenContract}
							circularProgressSize={24}
						>
							{t('bookings.state_actions.open_contract')}
						</ButtonProgress>
					</Grid>
				)}

				{isExtensionDialog && (
					<Grid container item xs={12} justifyContent="flex-start">
						<Grid item>
							<ExtensionInfoDialog />
						</Grid>
					</Grid>
				)}
			</Grid>
		</>
	);
};
