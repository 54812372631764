import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(4),
		textTransform: 'capitalize',
	},
	divider: {
		margin: theme.spacing(5, 0),
	},
	container: {
		flexWrap: 'nowrap',
	},
}));
