import React from 'react';

// Import store
import { fetchOffices } from 'store/actions';

// Import components
import { Filters, TableBodyRow, OuterToolbarElements } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';
import { useOffices } from './useOffices';
import { BUTTONS } from './helpers';

export const Offices = () => {
	const { tableTitle, data } = useOffices();

	return (
		<TableRPC
			fetchDataTableAction={fetchOffices}
			title={tableTitle}
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			filtersBar={<Filters />}
			columns={columns}
			outerToolbarElements={<OuterToolbarElements companyData={data} />}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="active" buttons={BUTTONS} />
			}
		/>
	);
};
