// Import helpers
import { capitalize, FIELD_TYPES } from 'helpers';

export const CLEAR_DIALOG_FIELDS = ({ reasons, isDescriptionInputVisible }) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'customers.preview.wallet.clear_dialog.clear_wallet_reason',
		name: 'reason_uuid',
		required: true,
		options: reasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ description }) => capitalize(description),
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'customers.preview.wallet.description',
		name: 'description',
		required: true,
		isHidden: !isDescriptionInputVisible,
		md: isDescriptionInputVisible ? 6 : 12,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'customers.preview.wallet.clear_dialog.clear_amount',
		name: 'amount',
		required: true,
		md: isDescriptionInputVisible ? 6 : 12,
		options: reasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ description }) => capitalize(description),
	},
];
