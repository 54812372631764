import React, { useEffect } from 'react';
import { any, bool, func, object } from 'prop-types';
import clsx from 'clsx';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTableContextProvider } from 'components/context';
import { COLUMNS_ACCESSORS } from '../../columns';
import { findPeriod } from './helpers';
import { convertVehicleName, DATE_TYPES } from 'helpers';
import { useProfile } from 'components/utilities';
import { columns } from '../../columns';

// Import components
import { DeliveryStatusTableCell, TopicTableCell } from './components';
import { Notes } from '../../components';
import {
	ActionsMenuTableCell,
	BookingIdTableCell,
	PickDateTableCell,
	CompanyTableCell,
	DeliveryAssignmentTableCell,
	StatusTableCell,
	DropDateTableCell,
} from 'components/elements';

// Import styles
import { useStyles } from './TableBodyRow.styles';

const {
	DELIVERY_ASSIGNMENT,
	DELIVERY_STATUS,
	PICK_DATE,
	DROP_DATE_ACCESSOR,
	CUSTOMER,
	ACTIONS,
	COMPANY,
	CAR,
	ID,
	STATUS,
	TOPIC,
} = COLUMNS_ACCESSORS;

const { DROP_DATE } = DATE_TYPES;

export const TableBodyRow = ({
	onMouseLeave,
	onMouseEnter,
	isHovered,
	row,
	ref,
}) => {
	const { filters, updateColumns } = useTableContextProvider();
	const classes = useStyles();
	const { isAdmin } = useProfile();

	const { date_type } = filters || {};
	const {
		customer_phone: customerPhone,
		customer_name: customerName,
		drop_date: dropDate,
		pick_date: pickDate,
		vehicle,
	} = row.original;

	const date = date_type === DROP_DATE ? dropDate : pickDate;
	const isDropOffSort = date_type === DROP_DATE;
	const period = findPeriod(date);

	const { name, year } = convertVehicleName(vehicle);

	useEffect(() => {
		updateColumns(
			columns.filter(
				({ accessor }) =>
					accessor !== (isDropOffSort ? PICK_DATE : DROP_DATE_ACCESSOR)
			)
		);
		// eslint-disable-next-line
	}, [isDropOffSort]);

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case TOPIC:
				return <TopicTableCell key={cell.column.id} cell={cell} />;

			case ID:
				return (
					<BookingIdTableCell
						cell={cell}
						isHovered={isHovered}
						key={cell.column.id}
						notes={<Notes cell={cell} />}
					/>
				);

			case CAR:
				return (
					<TableCell key={cell.column.id} style={{ minWidth: '180px' }}>
						<Typography>{name}</Typography>
						<Typography>{year}</Typography>
					</TableCell>
				);

			case PICK_DATE:
			case DROP_DATE_ACCESSOR:
				return isDropOffSort ? (
					<DropDateTableCell key={cell.column.id} cell={cell} withTime />
				) : (
					<PickDateTableCell key={cell.column.id} cell={cell} />
				);

			case DELIVERY_ASSIGNMENT:
				return (
					<DeliveryAssignmentTableCell
						key={cell.column.id}
						cell={cell}
						isDropOffSort={isDropOffSort}
					/>
				);

			case DELIVERY_STATUS:
				return (
					<DeliveryStatusTableCell
						key={cell.column.id}
						cell={cell}
						isDropOffSort={isDropOffSort}
					/>
				);

			case STATUS:
				return <StatusTableCell key={cell.column.id} cell={cell} />;

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case ACTIONS:
				return (
					<ActionsMenuTableCell
						hideBookingStatusActions
						key={cell.column.id}
						cell={cell}
						showTransferBooking={isAdmin}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			ref={ref}
			className={clsx(
				classes.periodItem,
				classes[period.type],
				isHovered ? classes[`hover_row_${period.type}`] : ''
			)}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
