import React from 'react';

// Import components
import { Grid } from 'components/elements';

// Import utils
import { useCommonFields } from 'components/utilities';
import { useCompanySelector } from './useCompanySelector';

export const CompanySelector = () => {
	const { renderFields } = useCommonFields();

	const { FIELDS } = useCompanySelector();

	return (
		<Grid container spacing={3}>
			{FIELDS.map(renderFields)}
		</Grid>
	);
};
