import i18next from 'i18next';

// Import helpers
import { QUERY_KEYS as ALL_QUERY_KEYS } from 'helpers';

const {
	CARS: { YEARS, MODELS, OFFICE_CARS },
} = ALL_QUERY_KEYS;

export const QUERY_KEYS = {
	YEARS,
	MODELS,
	OFFICE_CARS,
};

export const fieldNames = ({ lng }) => ({
	manufacturerFieldName: `carInfo.vehicle.maker_name.${lng}`,
	modelFieldName: `carInfo.vehicle.model_name.${lng}`,
	yearFieldName: 'carInfo.vehicle.year',
	carUuidFieldName: 'carInfo.vehicle.uuid',
});

export const getGlobalUuid = ({ values, supplierVehicleList }) => {
	const lng = i18next.language;
	const currentModelName = values.carInfo.vehicle.model_name[lng];

	return supplierVehicleList.find(
		({ model_name }) => currentModelName === model_name[lng]
	)?.uuid;
};
