import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

// Import store actions and selectors
import {
	submitEditCarForm,
	fetchCarInfo,
	setOfficeContext,
} from 'store/actions';
import { companyManagerContextSelector } from 'store/selectors';

// Import helpers
import { INITIAL_VALUES, validation } from './helpers';
import { replaceCarsUrl } from 'helpers';

// Import utilities
import { FormRPC } from 'components/utilities';

// Import components
import {
	CarDetails,
	Ratings,
	Details,
	ExternalPartner,
	ExtraServices,
	SubscriptionModel,
	CarSpecification,
	LeasingRateModel,
} from '../components';

//Import styles
import useStyles from '../styles';

const EditCars = () => {
	const classes = useStyles();

	const { companyUuid, officeUuid } = useSelector(
		companyManagerContextSelector
	);

	const hasCompanyManagerData = companyUuid || officeUuid;

	const { id } = useParams();

	const dispatch = useDispatch();

	useEffect(() => {
		setOfficeContext(dispatch, id);
	}, [dispatch, id]);

	if (!hasCompanyManagerData) return <CircularProgress color="primary" />;

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchCarInfo}
				submitFormAction={submitEditCarForm}
				isCardLayout={false}
				goBackPath={replaceCarsUrl(id)}
				store={(store) => store.car.form}
				initialValues={INITIAL_VALUES}
				validation={validation}
				successMessage="common.messages.successfully_saved"
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<CarDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Ratings section="prices" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<SubscriptionModel section="subscription" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<LeasingRateModel section="leasing" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExtraServices section="options" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CarSpecification section="specification" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Details section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExternalPartner section="external" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};

export default EditCars;
