import React from 'react';
import { Paper, Input, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { string } from 'prop-types';

// Import icons
import { SearchIcon } from 'assets/icons';

// Import utils
import { useTableRPCWithQueryProvider } from 'components/utilities/rpc/TableRPCWithQuery/context';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './Search.styles';

export const Search = ({ placeholder = 'common.search' }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { searchValue, handleOnSearchChange, handleDeleteSearchValue } =
		useTableRPCWithQueryProvider();

	return (
		<Paper className={classes.root}>
			<Input
				value={searchValue}
				className={classes.input}
				disableUnderline
				onChange={handleOnSearchChange}
				startAdornment={<SearchIcon className={classes.icon} />}
				placeholder={t(placeholder)}
				endAdornment={
					searchValue && (
						<Button>
							<CloseIcon
								onClick={handleDeleteSearchValue}
								className={classes.iconClose}
							/>
						</Button>
					)
				}
			/>
		</Paper>
	);
};

Search.propTypes = {
	placeholder: string,
};
