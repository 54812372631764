import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

// Import helpers
import { COLOR_VARIANTS, getColor } from './helpers';

const { LIGHT } = COLOR_VARIANTS;

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		minHeight: 50,
		height: 'min-content',
		width: '100%',
		borderRadius: '8px',
		padding: '8px 16px',
		backgroundColor: (props) => getColor({ props, theme, variant: LIGHT }),
		border: (props) => `1px solid ${alpha(getColor({ props, theme }), 0.5)}`,
	},
	info: {
		display: 'flex',
		alignItems: 'center',
		gridGap: '16px',
	},
	icon: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		color: (props) => getColor({ props, theme }),
		fontSize: '24px',
	},
	labels: {
		display: 'flex',
		flexDirection: 'column',
		gridGap: 6,
	},
	description: {
		fontSize: '12px',
		fontWeight: '500',
		color: theme.palette.text[4],
	},
}));
