export const useBookingLabelsTableCell = ({ cell }) => {
	const {
		external_partner: externalPartner,
		is_created_by_agent: isAgent,
		corporate_name: corpName,
	} = cell.row.original;

	const partnerLabel = externalPartner && `P-${externalPartner}`;
	const corporateLabel = corpName && `B-${corpName}`;

	return { partnerLabel, isAgent, corporateLabel };
};
