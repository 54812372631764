import React from 'react';
import { Box, Grid } from '@material-ui/core';

// Import components
import { BookingInfo, EditForm, TopBar } from './components';
import { AlertMessage, LoadingWrapper } from 'components/elements';

// Import utils
import { useEditBooking } from './useEditBooking';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './EditBooking.styles';

export const EditBooking = () => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { error, isLoaded, isError } = useEditBooking();

	if (!isLoaded)
		return (
			<Box className={classes.loader}>
				<LoadingWrapper size={70} />
			</Box>
		);

	if (isError && isLoaded) {
		return (
			<AlertMessage
				title={t('common.error')}
				variant="error"
				description={error.error_header || error.message}
			/>
		);
	}

	return (
		<Grid container spacing={3} className={classes.container}>
			<Grid item className={classes.middleContent}>
				<TopBar />
				<EditForm />
			</Grid>
			<Grid item>
				<BookingInfo />
			</Grid>
		</Grid>
	);
};
