import { useQuery } from 'react-query';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useLeasingCarsExport = ({
	isExportAllowed,
	handleExportError,
	handleExportSettled,
	fetchExportData,
}) => {
	const { isLoading: isExportLoading } = useQuery(
		QUERY_KEYS.LEASING.OFFICE_LEASING_CARS_EXPORT,
		fetchExportData,
		{
			refetchOnWindowFocus: false,
			enabled: isExportAllowed,
			onError: handleExportError,
			onSettled: handleExportSettled,
		}
	);

	return { isExportLoading };
};
