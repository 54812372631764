import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AddOfficeIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M12 21H3"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M3.92974 20.9998L3.92964 10.9414"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 20.9999V10.9502"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M9 16H11"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="18"
			cy="18"
			r="4"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.27311 7.47765C1.97799 8.03345 1.91933 8.68474 2.11038 9.28433C2.44083 10.3245 3.417 11.0228 4.50817 10.9994C5.59935 10.9761 6.54478 10.2368 6.83048 9.18341C6.84859 9.12223 6.90479 9.08025 6.9686 9.08025C7.03241 9.08025 7.08861 9.12223 7.10673 9.18341C7.39849 10.2555 8.37207 10.9995 9.4832 10.9994C10.5943 10.9993 11.5677 10.2551 11.8593 9.1829C11.8773 9.12169 11.9336 9.07968 11.9974 9.07968C12.0612 9.07968 12.1174 9.12169 12.1355 9.1829C12.4271 10.2551 13.4007 10.9992 14.5119 10.9991C15.623 10.9991 16.5965 10.255 16.8881 9.18273C16.9062 9.12156 16.9624 9.0796 17.0262 9.0796C17.09 9.0796 17.1462 9.12156 17.1644 9.18273C17.4501 10.236 18.3955 10.9752 19.4865 10.9986C20.5776 11.0219 21.5537 10.3239 21.8843 9.28381C22.0754 8.68412 22.0168 8.0327 21.7216 7.47681L20.0149 4.09822C19.6746 3.4247 18.9843 3 18.2297 3H5.76538C5.01081 3.00002 4.32049 3.42471 3.98027 4.09822L2.27311 7.47765Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M16.75 18H19.25"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18 19.25V16.75"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
