import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell, Tooltip, Typography } from '@material-ui/core';

// Import utilities
import { useCompensationAmountTableCell } from './useCompensationAmountTableCell';

// Import components
import { NotesForAdmins } from '../NoteTableCell/components';
import RoleProtected from 'components/hoc/RoleProtected';
import { Grid } from 'components/elements';

// Import helpers
import { ADMIN } from 'helpers';

// Import styles
import { useStyles } from '../../../Booking/styles';

export const CompensationAmountTableCell = ({
	cell,
	showAdminNotes = false,
}) => {
	const { compensationAmount, renderCompensationMethodIcon, tooltipTitle } =
		useCompensationAmountTableCell({ cell });
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()}>
			{compensationAmount && (
				<Grid container spacing={2} alignContent="center" wrap="nowrap">
					<Tooltip title={tooltipTitle}>
						<Grid item>{renderCompensationMethodIcon()}</Grid>
					</Tooltip>

					<Grid item>
						<Typography>{compensationAmount}</Typography>
					</Grid>
				</Grid>
			)}
			{showAdminNotes && (
				<RoleProtected roles={[ADMIN]}>
					<NotesForAdmins
						classes={classes}
						payment={cell.row.original.payment}
					/>
				</RoleProtected>
			)}
		</TableCell>
	);
};

CompensationAmountTableCell.propTypes = {
	cell: object,
	showAdminNotes: bool,
};
