import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	label: {
		fontSize: '16px',
		fontWeight: 600,
	},
	value: {
		fontSize: '16px',
		fontWeight: 600,
	},
}));
