import { alpha } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

// Import helpers
import { PERIOD_TYPES } from './helpers';

const { TODAY, TOMORROW, AFTER_TOMORROW } = PERIOD_TYPES;

export const useStyles = makeStyles((theme) => ({
	periodItem: {
		padding: '6px 18px',
		borderLeft: `4px solid`,
	},
	hoverRow: {},
	[`hover_row_${TODAY}`]: {
		backgroundColor: alpha(theme.palette.uncategorized.pink[1], 0.2),
	},
	[`hover_row_${TOMORROW}`]: {
		backgroundColor: alpha(theme.palette.uncategorized.blue[1], 0.2),
	},
	[`hover_row_${AFTER_TOMORROW}`]: {
		backgroundColor: theme.palette.primary.medium,
	},
	[TODAY]: {
		color: theme.palette.uncategorized.pink[1],
	},
	[TOMORROW]: {
		color: theme.palette.uncategorized.blue[1],
	},
	[AFTER_TOMORROW]: {
		color: theme.palette.primary.main,
	},
}));
