import React from 'react';
import { Box } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// Import assets
import { PlusIcon } from 'icons';

// Import utils and helpers
import { BOOKINGS_PAGES_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import components
import { Export } from '../Export';
import { Button } from 'components/elements';

// Import styles
import { useStyles } from './OuterToolbarElements.styles';

export const OuterToolbarElements = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const history = useHistory();

	return (
		<Box className={classes.container}>
			<Export pageType={BOOKINGS_PAGES_TYPES.MAIN} />
			<Button
				text={t('bookings.create_booking.title')}
				icon={PlusIcon}
				onClick={() => history.push(URLS.createBooking)}
				styleOverrides={classes.btn}
			/>
		</Box>
	);
};
