import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { isArray } from 'lodash';
import { useSelector } from 'react-redux';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { RejectReason } from './components';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import styles
import { useStyles } from './ValidationCancellationFields.styles';

export const ValidationCancellationFields = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const booking = useSelector(previewBookingSelector);
	const { cancel_reason: cancelReason } = booking || {};

	const isCancelReason = isArray(cancelReason);

	return (
		<Grid item>
			<Typography variant="body1" className={classes.title}>
				{t('bookings.urgent_tab.validate_cancellation')}
			</Typography>
			{!isCancelReason ? (
				<RejectReason />
			) : (
				<Typography variant="body1" className={classes.emptyState}>
					{t('bookings.urgent_tab.no_cancellation_request')}
				</Typography>
			)}
		</Grid>
	);
};
