import { makeStyles } from '@material-ui/styles';
import { DIMENSIONS } from 'helpers';

const { sideMenuDrawerOpened, sideMenuDrawerClosed } = DIMENSIONS;

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		right: 16,
		height: 80,
		width: '100vw',
		padding: '0 23px 0 35px',
		boxShadow: 'none',
		backgroundColor: theme.palette.white,
		[theme.breakpoints.up('lg')]: {
			padding: '0 35px',
			width: ({ isOpen }) =>
				`calc(100vw - (${
					isOpen ? sideMenuDrawerOpened : sideMenuDrawerClosed
				}px + 16px))`,
		},
		[theme.breakpoints.down('md')]: {
			right: 0,
			height: 90,
		},
	},
	sectionTitle: {
		lineHeight: 2,
		fontSize: 8,
		fontWeight: 500,
		color: theme.palette.text.primary,
		[theme.breakpoints.up('md')]: {
			fontSize: 16,
		},
		[theme.breakpoints.up('lg')]: {
			fontSize: 20,
		},
	},
	line: {
		height: '40px',
	},
	sectionPreviousParts: {
		color: theme.palette.text[3],
		fontSize: 'inherit',
		fontWeight: 500,
	},
	sectionTitleContainer: {
		flexWrap: 'nowrap',
		alignItems: 'center',
	},
	backButton: {
		'&:hover': {
			backgroundColor: 'transparent',
			boxShadow: 'none',
		},
	},
}));
