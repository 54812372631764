import {
	BOOKING_COMPENSATION_STATES,
	convertFormDates,
	getFilterIndex,
	removePreviousFilterValues,
	spreadFilterArray,
} from 'helpers';

export const initialValues = {
	compensation_status: BOOKING_COMPENSATION_STATES.REVIEW,
	company_uuid: ['all'],
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
};

export const formatValues = (values, { inputColumns }) => {
	const defaultCompanyFilterIndex = 17;

	const companyFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: defaultCompanyFilterIndex,
	});

	const newValues = removePreviousFilterValues({
		values,
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: defaultCompanyFilterIndex,
	});

	return {
		...newValues,
		company_uuid: values.company_uuid,
		compensation_status: values.compensation_status,
		...convertFormDates(values, ['pick_date', 'drop_date']),
		...spreadFilterArray({
			arrayElements: values.company_uuid,
			filterIndex: companyFilterIndex,
			defaultFilterIndex: defaultCompanyFilterIndex,
			inputColumns,
		}),
	};
};
