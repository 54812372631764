import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import { number, oneOfType, string } from 'prop-types';

// Import styles
import { useStyles } from './PriceDifference.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const PriceDifference = ({ priceDifference }) => {
	const { t } = useTranslations();

	const isPositivePrice = priceDifference >= 0;
	const classes = useStyles({ isPositivePrice });

	if (!priceDifference) return null;

	return (
		<Grid item xs={12}>
			<Box className={classes.container}>
				<Box>
					<Typography className={classes.title}>
						{t('bookings.preview_booking.invoice.price_difference')}
					</Typography>
					<Typography className={classes.subtitle}>
						{t('bookings.preview_booking.invoice.price_difference_subtitle')}
					</Typography>
				</Box>
				<Box className={classes.label}>
					<Typography className={classes.labelText}>
						{Number(priceDifference).toFixed(2)}
					</Typography>
				</Box>
			</Box>
		</Grid>
	);
};

PriceDifference.propTypes = {
	priceDifference: oneOfType([string, number]),
};
