import React from 'react';
import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import usePrevious from '@rooks/use-previous';
import { find, isEqual } from 'lodash';

// Import helpers and utils
import { convertDate, DEFAULT_DATE_FORMAT, EN, FIELD_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';

// Import queries
import {
	useCompanyCollectionCarFilter,
	useManufacturers,
	useOfficeCollection,
	useVehicleYearList,
} from 'queries';
import { useCars, useCarModels } from 'queries/CarSearch';

// Import store
import {
	editBookingFormSelector,
	supplierPeriodSelector,
} from 'store/selectors';

// Import components
import { CarFilterItem } from '../CarFilterItem';

// Import context
import { useEditBookingFormProvider } from 'views/Bookings/EditBooking/components/EditForm/context';

export const useFormContent = () => {
	const {
		i18n: { language: lang },
	} = useTranslations();

	const { id: bookingUuid } = useParams();

	const prevBookingUuid = usePrevious(bookingUuid);

	const { values } = useFormState();

	const { change } = useForm();

	const { item: booking } = useSelector(editBookingFormSelector);

	const { data: manufacturers, isFetching: isManufacturesFetching } =
		useManufacturers();

	const { changeMainEditForm, setSelectedCar } = useEditBookingFormProvider();

	const {
		vehicle,
		company_uuid: companyUuid,
		office_uuid: officeUuid,
		item,
	} = values || {};

	const prevValues = usePrevious(values);

	const { uuid: itemUuidValue } = item || {};

	const {
		model_uuid: modelUuidValue,
		maker_uuid: makerUuidValue,
		year,
	} = vehicle || {};

	const {
		maker: bookingMaker,
		model: bookingModel,
		year: bookingYear,
		uuid: itemUuid,
	} = booking?.item || {};

	const maker_uuid = manufacturers.find(
		({ name }) => name[EN] === bookingMaker
	)?.uuid;

	const makerUuid = makerUuidValue || maker_uuid;

	const { data: models, isFetching: isModelsFetching } = useCarModels({
		manufacturerUuid: makerUuid,
	});

	const model_uuid = models.find(
		({ model_name }) => model_name[EN] === bookingModel
	)?.model_uuid;

	const { data: years, isFetching: isYearFetching } = useVehicleYearList({
		enabled: !!makerUuid,
		body: { vehicle_maker: { uuid: makerUuid } },
	});

	const { data: companies, isFetching: isCompaniesFetching } =
		useCompanyCollectionCarFilter({
			enabled: !!makerUuid,
			values,
		});

	const {
		drop_date: dropDateSupplier,
		pick_date: pickDateSupplier,
		pick_time: pickTimeSupplier,
	} = useSelector(supplierPeriodSelector);

	const fetchOfficesBody = {
		vehicle_year: year || null,
		car_model_uuid: modelUuidValue || null,
		car_manufacturer_uuid: makerUuidValue || null,
		company_uuid: companyUuid || null,
		pickup_date: convertDate(
			`${convertDate(
				pickDateSupplier,
				DEFAULT_DATE_FORMAT
			)} ${pickTimeSupplier}`
		),
		drop_date: convertDate(dropDateSupplier, DEFAULT_DATE_FORMAT),
	};

	const { data: offices, isFetching: isOfficesFetching } = useOfficeCollection({
		bookingUuid,
		body: fetchOfficesBody,
	});

	const isFetchCarsEnabled = !!modelUuidValue && !!makerUuidValue;

	const {
		data: cars,
		refetch,
		isFetching: isCarsFetching,
	} = useCars({
		bookingUuid,
		values: booking,
		filters: values,
		offsets: {
			offset: 1,
		},
		enabled: isFetchCarsEnabled,
	});

	const isValuesEqual = isEqual(prevValues, values);

	useEffect(() => {
		if (bookingUuid !== prevBookingUuid || !isValuesEqual) {
			isFetchCarsEnabled && refetch();
		}
		// eslint-disable-next-line
	}, [bookingUuid, prevBookingUuid, values, prevValues, refetch]);

	useEffect(() => {
		if (!isValuesEqual && !!prevValues) {
			const model = find(models, ['model_uuid', modelUuidValue]);
			const maker = find(manufacturers, ['uuid', makerUuidValue]);
			const company = find(companies, ['uuid', companyUuid]);
			const office = find(offices, ['uuid', officeUuid]);

			setSelectedCar({ model, maker, year, company, office });
		}
		// eslint-disable-next-line
	}, [values]);

	useEffect(() => {
		change('item.uuid', itemUuidValue || itemUuid);
		change('vehicle.maker_uuid', makerUuidValue || maker_uuid);
		change('vehicle.model_uuid', modelUuidValue || model_uuid);
		change('vehicle.year', year || bookingYear);

		// eslint-disable-next-line
	}, [maker_uuid, model_uuid, bookingYear, itemUuid]);

	const handleCarChange = (e) => {
		const { value } = e.target;
		changeMainEditForm('item.uuid', value);
		change('item.uuid', value);
	};

	const SELECT_CAR_FILTERS_FIELDS = [
		{
			type: FIELD_TYPES.SELECT,
			name: 'vehicle.maker_uuid',
			label: 'common.fields.select_manufacture',
			options: manufacturers,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			isLoading: isManufacturesFetching,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'vehicle.model_uuid',
			label: 'common.fields.select_model',
			options: models,
			keyExtractor: ({ model_uuid }) => model_uuid,
			labelExtractor: ({ model_name }) => model_name[lang],
			isLoading: isModelsFetching,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'vehicle.year',
			label: 'common.fields.select_year',
			options: years,
			keyExtractor: (year) => year,
			labelExtractor: (year) => year,
			isLoading: isYearFetching,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'company_uuid',
			label: 'common.fields.select_company',
			options: companies,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			isLoading: isCompaniesFetching,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'office_uuid',
			label: 'common.fields.select_office',
			options: offices,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			isLoading: isOfficesFetching,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'item.uuid',
			label: 'common.fields.select_car',
			options: cars,
			keyExtractor: ({ uuid }) => uuid,
			// eslint-disable-next-line
			labelExtractor: ({ uuid, ...car }) => (
				<CarFilterItem key={uuid} car={car} />
			),
			isLoading: isCarsFetching,
			onChange: handleCarChange,
			value: itemUuidValue || itemUuid,
		},
	];

	return { SELECT_CAR_FILTERS_FIELDS };
};
