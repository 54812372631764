import { useState } from 'react';
import { queryCache } from 'react-query';
import services from 'services/services';

// Import utilities
import { useMessage, useTranslations, useToggle } from 'components/utilities';

// Import helpers
import { deductCustomerWalletFundsUrl } from 'store/paths';
import { QUERY_KEYS } from 'helpers';

export const useClearDialog = ({ customerUuid }) => {
	const [isLoading, setIsLoading] = useState(false);

	const { on: isOpen, toggle } = useToggle();

	const { message } = useMessage();

	const { t } = useTranslations();

	const handleOnSubmit = async (values) => {
		const { description, reason_uuid, amount } = values;

		const body = description
			? { description, amount }
			: { reason_uuid, amount };

		setIsLoading(true);

		try {
			await services.post(deductCustomerWalletFundsUrl(customerUuid), body);
			await queryCache.refetchQueries(
				QUERY_KEYS.CUSTOMERS.WALLET_BALANCE_HISTORY(customerUuid)
			);
			await queryCache.refetchQueries(
				QUERY_KEYS.CUSTOMERS.WALLET_BALANCE(customerUuid)
			);

			setIsLoading(false);
			toggle();
			message.success(
				`${t('customers.preview.wallet.clear_dialog.success_message')} 
					${t('currency.price', { amount })}`
			);
		} catch (error) {
			const errorMessage =
				error?.response?.data?.error || t('common.messages.error_message');

			setIsLoading(false);
			toggle();
			message.error(errorMessage);
		}
	};

	return {
		handleOnSubmit,
		toggle,
		isLoading,
		isOpen,
	};
};
