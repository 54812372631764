import React from 'react';

// Import components
import { DateRangeInput, Grid } from 'components/elements';

export const AdditionalFilterFields = () => (
	<>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="amount_discount"
				nameUntil="amount_discount_to"
				label="common.filters.amount_discount"
				combinedInputs
				isToggleButton
			/>
		</Grid>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="amount_paid"
				nameUntil="amount_paid_to"
				label="common.filters.amount_paid"
				combinedInputs
				isToggleButton
			/>
		</Grid>
	</>
);
