import { useRef, useEffect } from 'react';
import { useFormState } from 'react-final-form';

// Import helpers
import { checkIsLocationOutsideArea } from '../../helpers';

export const useLocationAutocomplete = ({ value, onChange }) => {
	const {
		values: { supplier },
	} = useFormState();

	const autocompleteRef = useRef();

	const inputRef = useRef();

	const draggableArea = {
		radius: supplier.max_delivery_radius || 5000,
		center: supplier.location,
	};

	const setInputValue = (value = '') => {
		inputRef.current.value = value;
	};

	const handleChange = () => {
		const place = autocompleteRef.current.getPlace();

		if (!place) {
			onChange(null);
		}

		const location = {
			formatted_address: place.formatted_address,
			lat: place.geometry.location.lat(),
			lng: place.geometry.location.lng(),
		};

		const isOutside = checkIsLocationOutsideArea(location, draggableArea);

		if (isOutside) {
			setInputValue(value ? value.formatted_address : '');
			return;
		}

		onChange(location);
	};

	const handleLoad = (autocomplete) => {
		autocompleteRef.current = autocomplete;
		setInputValue(value ? value.formatted_address : '');
	};

	useEffect(() => {
		setInputValue(value ? value.formatted_address : '');
	}, [value]);

	return {
		handleChange,
		handleLoad,
		inputRef,
	};
};
