import React from 'react';
import { node, oneOf, oneOfType, string } from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { t } from 'i18next';

// Import styles
import { useStyles } from './AlertMessage.styles';

// Import helpers
import { renderAlertIcon } from './helpers';
import clsx from 'clsx';

export const AlertMessage = ({
	variant = 'error',
	title,
	description,
	action,
	iconClassName,
	containerClassName,
	infoClassName,
	labelsClassName,
}) => {
	const classes = useStyles({ variant });

	return (
		<Box
			className={clsx({
				[classes.container]: true,
				[containerClassName]: !!containerClassName,
			})}
		>
			<Box
				className={clsx({
					[classes.info]: true,
					[infoClassName]: !!infoClassName,
				})}
			>
				<Box
					className={clsx({
						[classes.icon]: true,
						[iconClassName]: !!iconClassName,
					})}
				>
					{renderAlertIcon(variant)}
				</Box>
				<Box
					className={clsx({
						[classes.labels]: true,
						[labelsClassName]: !!labelsClassName,
					})}
				>
					{title && <Typography variant="h5">{title}</Typography>}
					<Typography className={classes.description} variant="body1">
						{description}
					</Typography>
				</Box>
			</Box>
			{action && <Box className={classes.action}>{action}</Box>}
		</Box>
	);
};

AlertMessage.propTypes = {
	title: oneOfType([node, string]),
	description: string,
	action: oneOfType([node, string]),
	variant: oneOf(['success', 'error', 'warning', 'default']),
	iconClassName: string,
	containerClassName: string,
	infoClassName: string,
	labelsClassName: string,
};

AlertMessage.defaultProps = {
	description: t('common.messages.error_message'),
	title: t('common.error'),
	variant: 'error',
	iconClassName: '',
	containerClassName: '',
	infoClassName: '',
	labelsClassName: '',
};
