import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCompanyDetails = (uuid, enabled) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.COMPANIES.DETAILS(uuid),
		async () => await services.get(ENDPOINTS.COMPANIES.DETAILS({ uuid })),
		{ refetchOnWindowFocus: false, enabled }
	);
	const fetchedData = data?.data.data || {};

	return { data: fetchedData, isFetching, isError };
};
