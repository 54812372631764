import React from 'react';
import { func, object } from 'prop-types';
import { Box } from '@material-ui/core';

// import styles
import { useStyles } from './FormContent.styles';

// import component
import { ConfirmationDialog } from './components';
import { Typography, Button, Grid } from 'components/elements';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { GET_FIELD } from './helpers';
import { useFormContent } from './useFormContent';

export const FormContent = ({ toggle, booking }) => {
	const classes = useStyles();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { drop_date: dropDate } = booking || {};
	const FIELD = GET_FIELD({ t, dropDate });
	const {
		isConfirmationDialogOpen,
		btnDisabled,
		toggleConfirmationDialog,
		onConfirmationClick,
		surchargePrice,
		loading,
	} = useFormContent({ booking });

	return (
		<Box>
			<Typography className={classes.heading}>
				{t('return_page.extend_date')}
			</Typography>
			<Grid container>{renderFields(FIELD)}</Grid>
			<Box className={classes.btnsContainer}>
				<Button
					className={classes.cancel}
					onClick={toggle}
					text={t('common.buttons.cancel')}
				/>
				<Button
					className={classes.extend}
					disabled={btnDisabled}
					onClick={onConfirmationClick}
					text={t('common.buttons.confirm')}
					isLoading={loading}
				/>
			</Box>
			<ConfirmationDialog
				isOpen={isConfirmationDialogOpen}
				toggle={toggleConfirmationDialog}
				surchargePrice={surchargePrice}
			/>
		</Box>
	);
};

FormContent.propTypes = {
	toggle: func.isRequired,
	booking: object.isRequired,
};
