import React from 'react';
import { bool, node, object, string } from 'prop-types';
import { TableCell, Box } from '@material-ui/core';

// Import styles
import { useStyles } from './BookingIdTableCell.styles';

// Import components
import { PreviewPopup, BookingDetailsDialog } from './components';

export const BookingIdTableCell = ({
	cell,
	notes,
	isHovered = true,
	isExtended = false,
	uuid = '',
}) => {
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()} style={{ minWidth: '170px' }}>
			<Box className={classes.container}>
				<BookingDetailsDialog cell={cell} isExtended={isExtended} uuid={uuid} />
				{isHovered && notes && <PreviewPopup notes={notes} />}
			</Box>
		</TableCell>
	);
};

BookingIdTableCell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
	notes: node,
	isExtended: bool,
	uuid: string,
};
