import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	radioWrapper: {
		'& .MuiFormGroup-root': {
			flexDirection: 'row',
		},
		'& .MuiTypography-root': {
			color: theme.palette.text.inputLabel,
		},
	},
}));
