import React from 'react';
import { bool, func, object } from 'prop-types';
import { Box, Dialog, DialogContent } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

// import utils & helpers
import { INITIAL_VALUES, handleSubmit } from './helpers';
import { useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

// Import styles
import { useStyles } from './CloseContractDialog.styles';

export const CloseContractDialog = ({ isOpen, toggle, booking }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { fetchData } = useTableContextProvider();
	const successCallback = () => {
		fetchData();
		toggle();
	};
	return (
		<Dialog open={isOpen} onClose={toggle} className={classes.dialog}>
			<ClosableDialogTitle
				onClose={toggle}
				title={t('return_page.close_contract')}
				titleClassName={classes.title}
			/>
			<DialogContent>
				<Form
					initialValues={INITIAL_VALUES}
					onSubmit={(values) =>
						handleSubmit({ values, booking, successCallback })
					}
					render={({ handleSubmit }) => (
						<Box component="form" onSubmit={handleSubmit}>
							<FormContent toggle={toggle} />
						</Box>
					)}
				/>
			</DialogContent>
		</Dialog>
	);
};

CloseContractDialog.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired,
	booking: object.isRequired,
};
