import React from 'react';
import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { bool, func } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { EARLY_RETURN_FIELDS } from './helpers';
import { useFormContent } from './useFormContent';

// Import styles
import { useStyles } from './FormContent.styles';

// Import components
import { AlertMessage, ButtonProgress } from 'components/elements';
import { PreviewFields } from 'views/Bookings/BookingDetails/components';

export const FormContent = ({ toggle, isLoading }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const classes = useStyles();

	const { submitting, INPUT_FIELDS, customData, isFetching, isError } =
		useFormContent();

	if (isFetching) {
		return (
			<Box className={classes.loadingBox}>
				<CircularProgress />
			</Box>
		);
	}

	if (isError) {
		return (
			<Box>
				<AlertMessage
					variant="error"
					title={t('common.error')}
					description={t('common.messages.error_message')}
				/>
			</Box>
		);
	}

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<PreviewFields fields={EARLY_RETURN_FIELDS} customData={customData} />
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{INPUT_FIELDS({
						buttonClassName: classes.fileUploaderButton,
						buttonFullWidth: true,
					}).map(renderFields)}
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={2}>
					<Grid item>
						<Button onClick={toggle} color="primary" variant="outlined">
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							type="submit"
							color="primary"
							variant="contained"
							circularProgressSize={24}
							isLoading={isLoading || submitting}
							disabled={isLoading || submitting}
						>
							{t('common.buttons.submit')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isLoading: bool.isRequired,
	toggle: func.isRequired,
};
