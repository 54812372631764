import React from 'react';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { Button, CircularProgress } from '@material-ui/core';
import { string } from 'prop-types';

// Import components
import { CustomerWalletDialog } from './components';
import { PreviewField } from 'components/elements';

// Import utilities
import { useCustomerWalletPreviewField } from './useCustomerWalletPreviewField';
import { useToggle } from 'components/utilities';

// Import styles
import { useStyles } from './CustomerWalletPreviewField.styles';

// Import assets
import { WalletIcon } from 'assets/icons';

export const CustomerWalletPreviewField = ({ label, customerUuid }) => {
	const { on, toggle } = useToggle();

	const classes = useStyles();

	const { balance, isError, errorMessage, isFetched } =
		useCustomerWalletPreviewField({
			customerUuid,
		});

	return (
		<PreviewField label={label}>
			{isFetched ? (
				<Button
					disabled={isError}
					onClick={toggle}
					color="primary"
					variant="outlined"
					className={classes.button}
					startIcon={<WalletIcon color="primary" />}
					endIcon={<KeyboardArrowDownIcon />}
				>
					{isError ? errorMessage : balance}
				</Button>
			) : (
				<CircularProgress size={20} />
			)}
			<CustomerWalletDialog
				on={on}
				toggle={toggle}
				customerUuid={customerUuid}
			/>
		</PreviewField>
	);
};

CustomerWalletPreviewField.propTypes = {
	customerUuid: string,
	label: string,
};
