import React from 'react';

// Import components
import { AddNewButton } from 'components/elements';

// Import utils
import { useTableContextProvider } from 'components/context';
import { URLS } from 'components/routes';

export const OuterToolbarElements = () => {
	const { filters } = useTableContextProvider();
	const addSliderUrl = `${URLS.addSliderUrl}?show_in=${filters?.show_in}`;

	return (
		<AddNewButton text="sliders.table.toolbar.new_slider" to={addSliderUrl} />
	);
};
