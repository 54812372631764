import { useSelector } from 'react-redux';

// Import selectors
import { previewBookingSelector } from 'store/selectors';

export const useCarAndCompany = () => {
	const booking = useSelector(previewBookingSelector);

	const { item, company, office } = booking;
	const { name: carName, vehicle_image_url: carImage } = item || {};
	const { name: companyName } = company || {};
	const { city: officeCity } = office || {};

	const getOption = (type) =>
		booking.options?.find((option) => option.type === type);

	return { carName, companyName, officeCity, getOption, carImage };
};
