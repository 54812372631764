// Import helpers
import { ACTION_MENU_POPUP_FIELD_TYPES } from 'helpers';

const {
	TRANSACTIONS_HISTORY,
	TRANSFER_BOOKINGS,
	BOOKINGS_HISTORY,
	ADD_RATING,
	DOWNLOAD_INVOICE,
	ACCEPT_BOOKING,
	CANCEL_BOOKING,
} = ACTION_MENU_POPUP_FIELD_TYPES;

export const actionMenuFields = [
	{ type: ACCEPT_BOOKING },
	{ type: CANCEL_BOOKING },
	{ type: TRANSFER_BOOKINGS },
	{ type: BOOKINGS_HISTORY },
	{ type: TRANSACTIONS_HISTORY },
	{ type: ADD_RATING },
	{ type: DOWNLOAD_INVOICE },
];
