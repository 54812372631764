import React from 'react';
import { Field } from 'react-final-form';
import { number, oneOfType, string } from 'prop-types';
import { CircularProgress, Grid, MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCityFilter } from './useCityFilter';

export const CityFilter = ({
	label,
	name,
	externalCompanyUuid = null,
	md = 3,
	xs = 3,
}) => {
	const { t } = useTranslations();

	const { cities, isFetching, isFieldDisabled } = useCityFilter(
		name,
		externalCompanyUuid
	);

	if (!cities) {
		return null;
	}

	return (
		<Grid container item xs={xs} md={md}>
			{isFetching ? (
				<CircularProgress size={28} />
			) : (
				<Field
					fullWidth
					component={SelectFieldAdapter}
					disabled={isFieldDisabled}
					label={t(label)}
					name={name}
					variant="outlined"
					margin="dense"
				>
					<MenuItem value="all">{t('common.all')}</MenuItem>
					{cities.map(({ uuid, name }) => (
						<MenuItem key={uuid} value={uuid}>
							{name}
						</MenuItem>
					))}
				</Field>
			)}
		</Grid>
	);
};

CityFilter.propTypes = {
	externalCompanyUuid: string,
	label: string.isRequired,
	name: string.isRequired,
	md: oneOfType([number, string]),
	xs: oneOfType([number, string]),
};
