import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DeliveryKeyIcon = (props) => (
	<SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
		<path
			id="Path"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M6.99759 22.0038H4.99675C4.44452 22.0038 3.99634 21.5556 3.99634 21.0034V15.0009C3.99634 14.4487 4.44452 14.0005 4.99675 14.0005H6.99759C7.54982 14.0005 7.998 14.4487 7.998 15.0009V21.0034C7.998 21.5556 7.54982 22.0038 6.99759 22.0038Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_2"
			d="M14.0005 18.0021H16.3345C16.7677 18.0021 17.1889 17.862 17.535 17.6019L19.952 15.7892C20.5553 15.337 21.3996 15.397 21.9329 15.9302V15.9302C22.5251 16.5225 22.5251 17.4819 21.9329 18.0731L19.859 20.147C19.3008 20.7052 18.5885 21.0864 17.8141 21.2414L14.8829 21.8277C14.3017 21.9437 13.7024 21.9297 13.1272 21.7857L10.4761 21.1234C10.1589 21.0434 9.83381 21.0033 9.50668 21.0033H7.99805"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_3"
			d="M14.0005 18.0022H15.4862C16.3235 18.0022 17.0018 17.3239 17.0018 16.4865V16.1834C17.0018 15.4881 16.5286 14.8819 15.8543 14.7138L13.5614 14.1405C13.1882 14.0475 12.806 14.0005 12.4219 14.0005V14.0005C11.4945 14.0005 10.5871 14.2746 9.8158 14.7898L7.99805 16.0013"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_4"
			d="M14.4497 5.17076L9.82281 5.17376C9.5577 5.17376 9.3036 5.2798 9.11652 5.46688L8.29117 6.29223C7.90001 6.68339 7.90001 7.31665 8.29117 7.70682L9.11952 8.53516C9.3066 8.72224 9.5617 8.82828 9.82681 8.82728L14.4527 8.82428L14.4637 8.82528C15.127 10.1128 16.4546 11.0012 18.0042 10.9992C20.2131 10.9932 22.0039 9.19744 22.0039 6.98952C22.0049 4.7806 20.2161 2.99385 18.0072 2.99585C16.4636 2.99985 15.139 3.88923 14.4727 5.17176"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Shape_3077_1_"
			d="M18.0041 7.26156C18.1482 7.26156 18.2642 7.14451 18.2642 7.00045C18.2642 6.85639 18.1462 6.73934 18.0031 6.73934C17.8591 6.73934 17.742 6.85639 17.742 7.00045C17.742 7.14451 17.8591 7.26156 18.0041 7.26156"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
