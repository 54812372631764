import React from 'react';

// Import utils
import { TableWithQueryProvider } from './context';

// Import components
import { Wrapper } from './components';
import { array, bool, element, func, number, object, string } from 'prop-types';

export const TableRPCWithQuery = ({
	title,
	queryKey,
	url,
	columns,
	rowsPerPageOptions,
	titleFontSize = 28,
	removeContainerPadding = false,
	titleCustomStyles = '',
	showSearch = true,
	filters = null,
	getFormattedFilterValues = () => {},
	filtersInitialValues = {},
	headerContainerMinHeight,
}) => {
	return (
		<TableWithQueryProvider
			queryKey={queryKey}
			url={url}
			columns={columns}
			cacheTime={0}
			rowsPerPageOptions={rowsPerPageOptions}
			getFormattedFilterValues={getFormattedFilterValues}
			filtersInitialValues={filtersInitialValues}
		>
			<Wrapper
				headerContainerMinHeight={headerContainerMinHeight}
				removeContainerPadding={removeContainerPadding}
				filtersInitialValues={filtersInitialValues}
				rowsPerPageOptions={rowsPerPageOptions}
				titleCustomStyles={titleCustomStyles}
				titleFontSize={titleFontSize}
				showSearch={showSearch}
				filters={filters}
				title={title}
			/>
		</TableWithQueryProvider>
	);
};

TableRPCWithQuery.propTypes = {
	title: string,
	queryKey: string,
	url: string,
	columns: array,
	rowsPerPageOptions: array,
	titleFontSize: number,
	removeContainerPadding: bool,
	titleCustomStyles: string,
	showSearch: bool,
	filters: element,
	getFormattedFilterValues: func,
	filtersInitialValues: object,
	headerContainerMinHeight: string,
};
