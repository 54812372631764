import React from 'react';
import { createContext } from 'react';
import { string, node } from 'prop-types';

// Import utilities
import { useCarsLogHistory } from 'queries';

export const CarsLogHistoryContext = createContext();

export const CarsLogHistoryProvider = ({ uuid, children }) => {
	const { data, isFetching, isError, error } = useCarsLogHistory({ uuid });

	return (
		<CarsLogHistoryContext.Provider
			value={{
				isFetching,
				isError,
				error,
				data,
			}}
		>
			{children}
		</CarsLogHistoryContext.Provider>
	);
};

CarsLogHistoryProvider.propTypes = {
	children: node,
	uuid: string,
};
