import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		margin: '32px 0 24px 0',
	},
	recordContainer: {
		padding: '16px 0',
		alignItems: 'center',
	},
	amount: {
		color: theme.palette.text[2],
		fontSize: 14,
	},
	amountContainer: {
		textAlign: 'center',
	},
}));
