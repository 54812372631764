// Import helpers
import { FIELD_TYPES } from 'helpers';

export const TOP_FIELDS = [
	{
		type: FIELD_TYPES.TEXT,
		name: 'details.full_name',
		label: 'users.form.details.full_name',
		required: true,
	},
];

export const BOTTOM_FIELDS = (options) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'users.form.details.phone_prefix',
		name: 'details.phone_prefix',
		md: 3,
		required: true,
		options,
		keyExtractor: (row) => row,
		labelExtractor: (row) => row,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'details.phone',
		label: 'users.form.details.phone',
		md: 9,
	},
	{
		type: FIELD_TYPES.DATE,
		name: 'license_expiry_date',
		label: 'users.form.details.license_expiry_date',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		name: 'id_number',
		label: 'users.form.details.id_number',
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'users.form.details.email',
		name: 'details.email',
		required: true,
	},
	{
		type: FIELD_TYPES.PASSWORD,
		label: 'users.form.details.password',
		name: 'password',
		required: false,
		md: 6,
	},
	{
		type: FIELD_TYPES.PASSWORD,
		label: 'users.form.details.password_confirmation',
		name: 'password_confirmation',
		required: false,
		md: 6,
	},
];
