import services from 'services/services';
import * as types from 'store/types';
import {
	submitEditCarFormUrl,
	submitCarsFormUrl,
	fetchVehicleMakerListUrl,
	fetchSupplierBusinessCarPriceListUrl,
	showSupplierBusinessCarUrl,
	fetchCompanyOfficesCollectionUrl,
	showOfficeDetailsUrl,
	fetchBusinessManagerProfileUrl,
} from 'store/paths';

//Import helpers
import { getOfficeUuid, getCompanyUuidFromContext } from '../profile/helpers';
import {
	EMPTY_FIELDS,
	convertSubmitData,
	carCatchError,
	convertCarInfo,
	convertCarEditApiErrors,
	convertSubscriptionPrices,
	convertLeasingPrices,
} from './helpers';
import { catchError } from '../helpers';

export const submitEditCarForm =
	({ values, itemId, carId, isEdit, callbackSuccess, errorCallback }) =>
	async (dispatch) => {
		const data = convertSubmitData({
			formValues: values,
			cars: values.cars,
			offices: values.offices,
			officesList: values.officesList,
			officeId: itemId,
			externalCars: values.externalCars,
			choosenExternal: values.external,
			isEdit,
		});

		try {
			const isGlobalAddCar = !isEdit && !itemId;
			const isGlobalEditCar = isEdit && !itemId;
			const companyUuid =
				(isGlobalEditCar || isGlobalAddCar) && (await getCompanyUuid());

			await services.post(
				submitEditCarFormUrl(companyUuid, carId, itemId, isEdit),
				data
			);

			clearCarInfoData()(dispatch);
			callbackSuccess();
		} catch (error) {
			if (error.response) {
				const apiErrors = error.response.data.errors;
				const convertedErrors = convertCarEditApiErrors(apiErrors);

				errorCallback(convertedErrors);
			}
		}
	};

export const submitCarsForm =
	({ values, itemId, callbackSuccess, errorCallback }) =>
	async () => {
		try {
			await services.put(submitCarsFormUrl(itemId), values);
			callbackSuccess();
		} catch (error) {
			if (error.response) {
				const apiErrors = error.response.data.errors;
				const convertedErrors = convertCarEditApiErrors(apiErrors);

				errorCallback(convertedErrors);
			}
		}
	};

export const fetchCarInfo =
	({ isEdit, carId, itemId, cancelToken, companyUuid }) =>
	async (dispatch) => {
		try {
			dispatch({
				type: types.EDIT_CAR_FORM_FETCH_DETAILS_LOADING,
				payload: true,
			});

			if (!companyUuid) {
				companyUuid = await getCompanyUuid();
			}

			// --------- CAR BRANDS -----------
			const {
				data: { data: vehicleList },
			} = await services.get(fetchVehicleMakerListUrl, cancelToken);

			const isAddCar = !isEdit && itemId;
			const isGlobalAddCar = !isEdit && !itemId;
			const isEditCar = isEdit && itemId;
			const isGlobalEditCar = isEdit && !itemId;

			let item = {};
			let leasing = {};
			let carInfo = {};

			if (isEditCar) {
				// --------- CAR INFO -----------
				const {
					data: { data },
				} = await services.get(showSupplierBusinessCarUrl(carId), cancelToken);

				carInfo = data;

				// --------- SUBSCRIPTION PRICES -------
				carInfo = {
					...carInfo,
					subscription: {
						...(carInfo.subscription || {}),
						prices: convertSubscriptionPrices(carInfo),
					},
				};

				// --------- LEASING PRICES -------
				leasing = convertLeasingPrices(carInfo);

				// --------- RATINGS -----------
				const ratingBody = { office_car: { uuid: carId } };
				const {
					data: { data: ratingsList },
				} = await services.post(
					fetchSupplierBusinessCarPriceListUrl,
					ratingBody,
					cancelToken
				);

				// -------- EXTERNAL CAR -----------
				if (carInfo.external.car_codes === null) {
					carInfo = {
						...carInfo,
						external: {
							...(carInfo?.external || {}),
							car_codes: [],
						},
					};
				}

				const {
					is_daily,
					is_leasing,
					is_available_to_sell_after_leasing_period,
					leasing_prices,
				} = carInfo || {};

				const { leasing_sell_price, security_deposit_price } =
					leasing_prices || {};

				item = {
					prices: { ...ratingsList },
					carInfo,
					leasing: {
						base_rent: { months: leasing },
						security_deposit_price,
						leasing_sell_price,
					},
					is_daily,
					is_leasing,
					is_available_to_sell_after_leasing_period,
				};
			} else if (isAddCar) {
				item = EMPTY_FIELDS;
			} else if (isGlobalAddCar) {
				// ------------- OFFICES LIST --------------
				const {
					data: { data: officesList },
				} = await services.get(
					fetchCompanyOfficesCollectionUrl(companyUuid),
					cancelToken
				);

				item = {
					...EMPTY_FIELDS,
					officesList: officesList,
				};
			} else if (isGlobalEditCar) {
				item = {
					...EMPTY_FIELDS,
					managerProfileUuid: companyUuid,
				};
			}
			item = {
				...item,
				carInfo: convertCarInfo(item.carInfo),
			};

			dispatch({
				type: types.EDIT_CAR_FORM_FETCH_DETAILS_SUCCESS,
				payload: {
					item: item,
					vehicleList: [...vehicleList],
				},
			});
		} catch (error) {
			catchError({
				error,
				dispatch,
				type: types.EDIT_CAR_FORM_FETCH_DETAILS_ERROR,
			});
		}
	};

export const clearCarInfoData = () => async (dispatch) => {
	try {
		dispatch({
			type: types.CLEAR_CAR_INFO,
		});
	} catch (error) {
		carCatchError({ error, dispatch });
	}
};

const getCompanyUuid = async () => {
	const companyUuid = getCompanyUuidFromContext();
	if (companyUuid) {
		return companyUuid;
	}

	const { userOfficeUuid } = getOfficeUuid();

	if (userOfficeUuid) {
		const {
			data: {
				data: {
					company: { uuid: companyUuid },
				},
			},
		} = await services.get(showOfficeDetailsUrl(userOfficeUuid));

		return companyUuid;
	} else {
		const {
			data: {
				data: {
					belonging: {
						company: { uuid: companyUuid },
					},
				},
			},
		} = await services.get(fetchBusinessManagerProfileUrl);

		return companyUuid;
	}
};
