import React from 'react';
import { Grid } from '@material-ui/core';
import { object, array, string, oneOfType } from 'prop-types';

// Import components
import { PreviewFieldFactory } from '../components';

export const PreviewFields = ({ fields, color, customData }) => (
	<Grid item xs={12}>
		{fields.map((field) => (
			<PreviewFieldFactory
				customData={customData}
				key={field.key || field.accessor}
				color={color}
				{...field}
			/>
		))}
	</Grid>
);

PreviewFields.propTypes = {
	customData: oneOfType([array, object]),
	fields: array,
	color: string,
};
