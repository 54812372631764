// Import helpers
import {
	ADMIN,
	SUPPLIER_ADMIN,
	COMPANY_MANAGER,
	OFFICE_MANAGER,
	DRIVER,
} from 'helpers';
import store from '../../store';

// initial empty form values
export const initialValues = {
	belonging: {
		company: {
			uuid: '',
		},
		offices: [],
	},
	details: {
		active: false,
		email: '',
		email_notify: false,
		name: '',
		phone: '',
		phone_notify: false,
		phone_prefix: '',
		user_uuid: '',
		uuid: '',
	},
	manager: {
		additional_phone: '',
		additional_phone_notify: false,
		additional_phone_prefix: '',
		role: '',
		role_uuid: '',
	},
};

// format user data before request
export const formatUserData = ({ values, isDriver }) => {
	const { manager, details, belonging, ...rest } = values;

	const {
		additional_phone_notify,
		additional_phone_prefix,
		additional_phone,
		role,
	} = manager;

	const {
		name,
		full_name,
		email_notify,
		email,
		phone_notify,
		phone_prefix,
		phone,
		password,
		password_confirmation,
		uuid: userUuid,
	} = details;

	const userData = {
		manager: {
			additional_phone_notify,
			additional_phone_prefix,
			additional_phone,
			role: isDriver ? DRIVER : role,
		},
		details: {
			name: isDriver ? full_name : name,
			email_notify,
			email,
			phone_notify,
			phone_prefix,
			phone,
		},
		company: {
			uuid: belonging?.company?.uuid,
		},
		password: isDriver ? rest.password : password,
		password_confirmation: isDriver
			? rest.password_confirmation
			: password_confirmation,
	};

	return { userData, userUuid };
};

export const getCompanyUuidFromContext = () =>
	store.getState().companyManager.context.companyUuid;

export const findCompanyUuid = (role, belonging) => {
	switch (role) {
		case ADMIN:
		case SUPPLIER_ADMIN:
			return {
				userCompanyUuid: getCompanyUuidFromContext(),
			};
		case COMPANY_MANAGER:
		case OFFICE_MANAGER:
			return {
				userCompanyUuid: belonging?.company?.uuid,
			};
		default:
			return {};
	}
};

export const getOfficeUuid = () => {
	return {
		userOfficeUuid: store.getState().companyManager.context.officeUuid,
	};
};

export const convertDataToStore = ({
	isEdit,
	userData,
	initialBelonging,
	userCompanyUuid,
}) => {
	return isEdit
		? { item: { ...userData } }
		: {
				belonging: { ...initialBelonging, company: { uuid: userCompanyUuid } },
				//eslint-disable-next-line
		  };
};

export const getLeasingCarsDataFromContext = () => ({
	officeId: store.getState().companyManager.context.officeUuid,
	vehicleId: store.getState().companyManager.context.vehicleUuid,
});
