import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ErrorWithXIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M12.3359 11.0898L14.0033 12.7572"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.0033 11.0898L12.3359 12.7572"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.33347 8.04552V6"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M8.34062 10.452C8.31209 10.452 8.28474 10.4634 8.26469 10.4837C8.24464 10.504 8.23355 10.5315 8.23391 10.56C8.23426 10.619 8.28162 10.667 8.34062 10.6681C8.40029 10.6681 8.44867 10.6197 8.44867 10.56C8.44867 10.5004 8.40029 10.452 8.34062 10.452"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M13.7014 9.01211L10.5488 3.59785C10.0888 2.81146 9.24618 2.32812 8.33516 2.32812C7.42414 2.32812 6.58151 2.81146 6.12157 3.59785L2.31999 10.1192C1.89403 10.852 1.89329 11.7568 2.31805 12.4903C2.7428 13.2237 3.52795 13.6734 4.37551 13.6688H10.36"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
