import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography, Grid } from '@material-ui/core';

// Import assets
import { InformationIcon } from 'assets/icons';

// Import styles
import { useStyles } from './CityTableCell.styles';

export const CityTableCell = ({ cell }) => {
	const classes = useStyles();
	const { city } = cell.row.original || {};

	return (
		<TableCell {...cell.getCellProps()}>
			<Grid container spacing={1} wrap="nowrap">
				<Grid item>
					<InformationIcon color="primary" className={classes.icon} />
				</Grid>
				<Grid item>
					<Typography>{city}</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

CityTableCell.propTypes = {
	cell: object,
};
