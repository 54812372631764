import _ from 'lodash';

export const convertDropCities = (dropCities) =>
	dropCities
		.filter(({ is_available }) => is_available)
		.map(({ city_in, city_out, nett_price }) => ({
			city_in_uuid: city_in[0],
			city_out_uuid: city_out[0],
			price: parseFloat(nett_price),
			unsaved: false,
		}));

export const groupDropCities = (dropCities) =>
	Object.entries(_.groupBy(dropCities, 'city_in_uuid')).reduce(
		(acc, [city_in_uuid, array]) => {
			const allPriceEqual = array.every((val) => val.price === array[0].price);
			const price = array[0].price;

			const newDropCity = {
				[city_in_uuid]: {
					drop_cities: array.map(({ city_out_uuid }) => city_out_uuid),
					price: allPriceEqual ? price : null,
				},
			};

			return { ...acc, ...newDropCity };
		},
		{}
	);
