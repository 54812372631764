// Import helpers and utils
import useCalculateUrgentTime from './useCalculateUrgentTime';
import { BOOKING_URGENT_STATES, CHIP_COLORS } from 'helpers';
import { useTranslations } from 'components/utilities';

export const useUrgentStatusCell = ({ cell }) => {
	const { t } = useTranslations();

	const { SOLVED, UNSOLVED } = BOOKING_URGENT_STATES;
	const { WARNING, SUCCESS, ERROR } = CHIP_COLORS;

	const { urgent_assistance_status: status, urgentAssistance } =
		cell.row.original;

	const { elapsedTime } = useCalculateUrgentTime({ urgentAssistance });

	const getChipColor = () => {
		switch (status) {
			case SOLVED:
				return SUCCESS;
			case UNSOLVED:
				return ERROR;

			default:
				return WARNING;
		}
	};

	const chipColor = getChipColor();

	const chipLabel = t(`common.common_states.${status}`);

	return {
		status,
		chipColor,
		elapsedTime,
		chipLabel,
	};
};
