// Import utils
import { useProfile, useTranslations } from 'components/utilities';

export const useDiscountPreviewField = (data) => {
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	const { amount_gross: amountGross, percentage, code } = data;

	const discountPrice = +amountGross;

	const getSubLabel = () => {
		const discountInfoForAdmin = `${percentage}% ${t(
			'common.coupon'
		)} "${code}"`;

		const discountInfoForOthers = `${t(
			'bookings.preview_booking.invoice.pre_paid'
		)}`;

		if (null == code || null == percentage) {
			return null;
		}

		if (discountPrice) {
			return isAdmin ? discountInfoForAdmin : discountInfoForOthers;
		}

		return null;
	};

	const label = t('common.fields.total_discount');
	const subLabel = getSubLabel();

	let fixedDiscount = discountPrice.toFixed(2);
	if (discountPrice) fixedDiscount = `-${fixedDiscount}`;

	return {
		label,
		subLabel,
		fixedDiscount,
	};
};
