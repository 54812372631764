import clsx from 'clsx';
import React from 'react';
import { string } from 'prop-types';
import MuiDivider from '@material-ui/core/Typography';

// Import styles
import { useStyles } from './Divider.styles';

export const Divider = ({ styleOverride = {}, ...props }) => {
	const classes = useStyles();
	return (
		<MuiDivider className={clsx([classes.divider, styleOverride])} {...props} />
	);
};

Divider.propTypes = {
	styleOverride: string,
};
