import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useContractPrivacyPolicy = (contractNumber) => {
	const path = `/econtract/${contractNumber}/policy`;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CONTRACTS.PRIVACY_POLICY(contractNumber),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !!contractNumber }
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching, isError };
};
