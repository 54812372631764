export const getColorClass = (availableCars) => {
	if (availableCars >= 20) {
		return 'green';
	}

	if (availableCars <= 5) {
		return 'red';
	}

	return null;
};

export const getRows = ({ leasingStats, regularStats }) =>
	[regularStats, leasingStats].reduce(
		(acc, row) => (typeof row.available === 'number' ? [...acc, row] : acc),
		[]
	);
