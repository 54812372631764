import React from 'react';
import { func, number } from 'prop-types';
import { Grid, TextField } from '@material-ui/core';

// Import components
import { SquareButton } from 'components/elements';

// Import helpers and utils
import { BUTTON_ACTION_TYPES } from 'helpers';
import { useQuantityChanger } from './useQuantityChanger';

// Import styles
import { useStyles } from './QuantityChanger.styles';
import clsx from 'clsx';

export const QuantityChanger = ({ defaultQuantity, callBack }) => {
	const classes = useStyles();

	const { INCREMENT, DECREMENT } = BUTTON_ACTION_TYPES;

	const { value, changeQuantity, onQuantityChange } = useQuantityChanger({
		defaultQuantity,
		callBack,
	});

	return (
		<Grid
			container
			alignItems="center"
			wrap="nowrap"
			className={classes.container}
		>
			<Grid item>
				<SquareButton
					onClick={() => changeQuantity(DECREMENT)}
					iconType={DECREMENT}
					className={clsx([classes.square, classes.button])}
				/>
			</Grid>
			<Grid item>
				<TextField
					variant="outlined"
					name="quantity"
					type="number"
					onChange={onQuantityChange}
					value={value}
					className={clsx([classes.square, classes.removeUpAndDownArrows])}
				/>
			</Grid>
			<Grid item>
				<SquareButton
					onClick={() => changeQuantity(INCREMENT)}
					iconType={INCREMENT}
					className={clsx([classes.square, classes.button])}
				/>
			</Grid>
		</Grid>
	);
};

QuantityChanger.propTypes = {
	defaultQuantity: number,
	callBack: func,
};
