import { useFormState } from 'react-final-form';
import { addDays } from 'date-fns';

// Import utilities
import { useCustomerWalletReasons } from 'queries';

// import helpers
import { CUSTOMER_WALLET_OPERATIONS, DEFAULT_REASON_UUID } from 'helpers';

export const useFormContent = () => {
	const { ADD } = CUSTOMER_WALLET_OPERATIONS;

	const { data: reasons, isError } = useCustomerWalletReasons(ADD);

	const { values } = useFormState();

	const isDescriptionInputVisible = values.reason_uuid === DEFAULT_REASON_UUID;

	const minDate = addDays(new Date(), 1);

	return {
		reasons,
		minDate,
		isDescriptionInputVisible,
		isError,
	};
};
