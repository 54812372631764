import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const usePartnersCollection = () => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.PARTNERS.COLLECTION,
		async () => await services.get(ENDPOINTS.PARTNERS.COLLECTION),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
