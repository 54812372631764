import React from 'react';
import { List, ListItem } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { func } from 'prop-types';

// Import components
import { PublicHoliday } from '../../components';

export const DateFields = () => {
	const { values } = useFormState();

	const publicHolidays = values.public_holidays;
	const convertedPublicHolidays = publicHolidays
		? Object.keys(values.public_holidays)
		: [];

	return (
		<List>
			{convertedPublicHolidays.map((name) => (
				<ListItem key={name}>
					<PublicHoliday name={name} />
				</ListItem>
			))}
		</List>
	);
};

DateFields.propTypes = {
	setButtonDisabled: func,
};
