import React from 'react';
import { Button, ButtonGroup, MenuItem, Menu } from '@material-ui/core';

// Import utils
import { useBookingActions } from './useBookingActions';

// Import styles
import { useStyles } from './BookingActions.styles';

export const BookingActions = () => {
	const classes = useStyles();

	const { actions, moreOptions, handleCloseMoreMenu, moreMenuEl } =
		useBookingActions();

	return (
		<>
			<ButtonGroup aria-label="split button">
				{actions.map(
					({ label, action, startIcon, endIcon, isHidden, ...rest }) =>
						!isHidden && (
							<Button
								className={classes.button}
								key={label}
								startIcon={startIcon}
								endIcon={endIcon}
								onClick={action}
								{...rest}
							>
								{label}
							</Button>
						)
				)}
			</ButtonGroup>

			<Menu
				id="more-menu"
				className={classes.menuList}
				anchorEl={moreMenuEl}
				open={Boolean(moreMenuEl)}
				onClose={handleCloseMoreMenu}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
			>
				{moreOptions.map(
					({ label, action, icon, isHidden }) =>
						!isHidden && (
							<MenuItem
								key={label}
								onClick={action}
								className={classes.menuItem}
							>
								{icon}
								{label}
							</MenuItem>
						)
				)}
			</Menu>
		</>
	);
};
