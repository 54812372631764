import React from 'react';
import { useSelector } from 'react-redux';
import { bool, object } from 'prop-types';

//Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { TRANSFERRED_INVOICE_FIELDS } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';
import { LoadingWrapper, AlertMessage } from 'components/elements';

// Import styles
import { useStyles } from './PreviousCompanyInvoice.styles';

export const PreviousCompanyInvoice = ({ data, isFetching, isError }) => {
	const booking = useSelector(previewBookingSelector);

	const classes = useStyles();

	if (isFetching) {
		return <LoadingWrapper className={classes.loadingWrapper} />;
	}

	if (isError) {
		return <AlertMessage />;
	}

	const convertedCustomData = {
		...data,
		created_at: booking.item.created_at,
		company_name: booking.company.name,
		period: {
			pickDate: booking.period.pick_date,
			dropDate: booking.period.drop_date,
			days: booking.period.days,
		},
	};

	return (
		<PreviewFields
			customData={convertedCustomData}
			fields={TRANSFERRED_INVOICE_FIELDS}
		/>
	);
};

PreviousCompanyInvoice.propTypes = {
	data: object.isRequired,
	isFetching: bool.isRequired,
	isError: bool.isRequired,
};
