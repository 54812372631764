import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CircularXIcon = (props) => (
	<SvgIcon width="20" height="21" viewBox="0 0 25 25" fill="none" {...props}>
		<path
			d="M14.828 9.68555L9.16797 15.3455"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.828 15.3455L9.16797 9.68555"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M12 21.5156V21.5156C7.029 21.5156 3 17.4866 3 12.5156V12.5156C3 7.54462 7.029 3.51562 12 3.51562V3.51562C16.971 3.51562 21 7.54462 21 12.5156V12.5156C21 17.4866 16.971 21.5156 12 21.5156Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
