// Import helpers and utils
import { FIELD_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';

// Import queries
import { useCompanies } from 'queries';

export const useCompanySelector = () => {
	const {
		i18n: { language: lang },
	} = useTranslations();

	const { data: companies, isFetching: isCompaniesFetching } =
		useCompanies(true);

	const FIELDS = [
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.company',
			name: 'company',
			options: companies,
			isLoading: isCompaniesFetching,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			md: 6,
		},
	];

	return { FIELDS };
};
