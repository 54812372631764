import React from 'react';
import { object } from 'prop-types';

// Import components
import { Typography } from 'components/elements';

// Import helpers
import { getCashFlowColor } from './helpers';

// Import styles
import { useStyles } from './AmountTableCell.styles';

export const AmountTableCell = ({ row }) => {
	const classes = useStyles();

	const { cash_flow: cashFlow, net_worth: netWorth } = row || {};

	const cashFlowClass = getCashFlowColor({ cashFlow });

	return (
		<Typography fontSize={13}>
			<span className={classes[cashFlowClass]}>{cashFlow}</span> / {netWorth}
		</Typography>
	);
};

AmountTableCell.propTypes = {
	row: object.isRequired,
};
