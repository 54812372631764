// Import helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';

export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.filters.review',
		status: BOOKING_CANCELLATION_STATES.REVIEW,
	},
	{
		label: 'common.filters.approved',
		status: BOOKING_CANCELLATION_STATES.APPROVED,
	},
	{
		label: 'common.filters.rejected',
		status: BOOKING_CANCELLATION_STATES.REJECTED,
	},
];
