import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiDialog-paper': {
			width: '60vw',
		},
	},
	title: {
		fontSize: '18px',
		fontWeight: '600',
		color: theme.palette.text[4],
	},
}));
