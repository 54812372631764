import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	title: {
		fontSize: '14px',
		fontWeight: '600',
		color: theme.palette.text.inputLabel,
	},
	subtitle: {
		fontSize: '12px',
		fontWeight: '400',
		color: theme.palette.text[1],
	},
	label: ({ isPositivePrice }) => ({
		borderRadius: '8px',
		backgroundColor: isPositivePrice
			? theme.palette.success.lighter
			: theme.palette.error.lighter,

		padding: '6px 12px',
	}),
	labelText: ({ isPositivePrice }) => ({
		color: isPositivePrice
			? theme.palette.success.medium
			: theme.palette.error.medium,
		fontSize: '14px',
		fontWeight: '600',
	}),
}));
