import React from 'react';
import { any, bool, func, object } from 'prop-types';
import clsx from 'clsx';
import { TableCell, TableRow, Typography } from '@material-ui/core';

// Import utils and helpers
import { COLUMNS_ACCESSORS } from '../../columns';
import { convertBookingCell, findPeriod } from './helpers';
import { BOOKING_STATES } from 'helpers';
import { useTableBodyRow } from './useTableBodyRow';

// Import components
import { ReturnStatusTableCell } from './components';
import {
	NewActionsMenuTableCell,
	BookingIdTableCell,
	PickDateTableCell,
	CompanyTableCell,
	DeliveryAssignmentTableCell,
	StateWithActionsTableCell,
} from 'components/elements';

// Import styles
import { useStyles } from './TableBodyRow.styles';

const {
	RETURN_ASSIGNMENT,
	RETURN_STATUS,
	PICK_DATE,
	CUSTOMER,
	ACTIONS,
	COMPANY,
	STATUS,
	CAR,
	ID,
} = COLUMNS_ACCESSORS;

export const TableBodyRow = ({
	onMouseLeave,
	onMouseEnter,
	isHovered,
	row,
	ref,
}) => {
	const classes = useStyles();

	const { getActionMenuFields } = useTableBodyRow();

	const {
		customer_phone: customerPhone,
		customer_name: customerName,
		drop_date: dropDate,
		vehicle_maker: maker,
		vehicle_model: model,
		vehicle_year: year,
		booking_status: bookingStatus,
		payment_link_uuid: paymentLinkUuid,
	} = row.original;

	const { SUSPEND, PAYMENT_PENDING } = BOOKING_STATES;

	const showPaymentCancelOption =
		bookingStatus === SUSPEND || bookingStatus === PAYMENT_PENDING;

	const period = findPeriod(dropDate?.split(' ')?.[0]);

	const renderCell = (cell) => {
		const formattedCell = convertBookingCell(cell);

		switch (cell.column.id) {
			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case ID:
				return (
					<BookingIdTableCell
						cell={cell}
						isHovered={isHovered}
						key={cell.column.id}
					/>
				);

			case CAR:
				return (
					<TableCell key={cell.column.id} style={{ minWidth: '180px' }}>
						<Typography>{`${maker} ${model}`}</Typography>
						<Typography>{year}</Typography>
					</TableCell>
				);

			case PICK_DATE:
				return <PickDateTableCell key={cell.column.id} cell={cell} />;

			case RETURN_ASSIGNMENT:
				return (
					<DeliveryAssignmentTableCell
						key={cell.column.id}
						cell={cell}
						isDropOffSort
					/>
				);

			case RETURN_STATUS:
				return <ReturnStatusTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StateWithActionsTableCell
						key={cell.column.id}
						cell={formattedCell}
						isHovered={isHovered}
					/>
				);

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={getActionMenuFields({
							showPaymentCancelOption,
							paymentLinkUuid,
						})}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			ref={ref}
			className={clsx(
				classes.periodItem,
				classes[period?.type],
				isHovered ? classes[`hover_row_${period?.type}`] : ''
			)}
			{...row.getRowProps()}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
