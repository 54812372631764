import React from 'react';
import { object } from 'prop-types';
import { Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './CompanyTableCell.styles';

// Import components
import { PreviewPopup } from './components';

export const CompanyTableCellQuery = ({ row }) => {
	const classes = useStyles();

	const { company_name: companyName } = row;

	return (
		<Box className={classes.container}>
			<PreviewPopup cell={{ row: { original: row } }} />
			<Typography className={classes.companyName}>{companyName}</Typography>
		</Box>
	);
};

CompanyTableCellQuery.propTypes = {
	row: object.isRequired,
};
