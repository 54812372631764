import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	by: {
		fontSize: 14,
	},
	reason: {
		fontSize: 16,
		fontWeight: 600,
		margin: '16px 0 12px 0',
	},
	chip: {
		margin: '12px 0 30px 0',
	},
}));
