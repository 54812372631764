import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FilledStarIcon = (props) => (
	<SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
		<path
			d="M4.28219 4.71032L5.55623 2.26867C5.64241 2.10354 5.81324 2 5.9995 2C6.18577 2 6.3566 2.10354 6.44277 2.26867L7.71682 4.71032L10.082 5.1085C10.2657 5.13942 10.417 5.2699 10.4745 5.44706C10.5321 5.62421 10.4864 5.81868 10.356 5.95167L8.59596 7.7461L8.9938 10.4256C9.02115 10.6098 8.9438 10.794 8.7931 10.9035C8.6424 11.013 8.44333 11.0296 8.27655 10.9467L5.9995 9.81415L3.72246 10.9467C3.55568 11.0296 3.35661 11.013 3.2059 10.9035C3.0552 10.794 2.97786 10.6098 3.0052 10.4256L3.40305 7.7461L1.64305 5.95167C1.51261 5.81868 1.46692 5.62421 1.52448 5.44706C1.58204 5.2699 1.73331 5.13942 1.917 5.1085L4.28219 4.71032Z"
			fill="currentColor"
			stroke="currentColor"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
