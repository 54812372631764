import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }) => ({
	container: {
		display: 'flex',
		gap: '16px',
		height: '60px',
		alignItems: 'center',
	},
	checkbox: {
		'&.Mui-checked': {
			color: palette.primary.main,
		},
	},
	fieldWrapper: {
		'& .MuiInputBase-root': {
			backgroundColor: palette.background[6],
			border: 'none',
			'& fieldset': {
				border: 'none',
			},
		},
		'& input': {
			backgroundColor: palette.background[6],
		},
		'& input::placeholder': {
			color: palette.text[1],
			fontWeight: 400,
			opacity: '1',
			zIndex: 1000,
		},
	},
}));
