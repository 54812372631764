import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import assets
import { TasksListIcon, SearchIcon } from 'assets/icons';

// Import utils
import { useToggle } from 'components/utilities';
import { URLS } from 'components/routes';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useExtraActions = () => {
	const history = useHistory();

	const { on: isHistoryOpen, toggle: toggleHistoryDialog } = useToggle();

	const { uuid: bookingUuid, customer } = useSelector(previewBookingSelector);

	const { phone } = customer || {};

	const params = { globalFilter: phone };

	const encodedParams = encodeURIComponent(JSON.stringify(params));

	const goToCustomerBookings = () =>
		history.push(`${URLS.bookingsUrl}?options=${encodedParams}`);

	const actions = [
		{
			label: 'bookings.preview_booking.booking_history',
			Icon: TasksListIcon,
			onClick: toggleHistoryDialog,
		},
		{
			label: 'bookings.preview_booking.customer_bookings',
			Icon: SearchIcon,
			onClick: goToCustomerBookings,
		},
	];

	return { actions, isHistoryOpen, toggleHistoryDialog, bookingUuid };
};
