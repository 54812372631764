import React from 'react';
import { object } from 'prop-types';

// Import components
import { OptionField } from './OptionField';
import { DeliveryOptionField } from './DeliveryOptionField';
import { DropCityOptionField } from './DropCityOptionField';

export const OptionFieldFactory = ({ option }) => {
	switch (option.type) {
		case 'delivery':
			return <DeliveryOptionField label={option.label} />;
		case 'dropCity':
			return <DropCityOptionField label={option.label} />;
		default:
			return <OptionField type={option.type} label={option.label} />;
	}
};

OptionFieldFactory.propTypes = {
	option: object,
};
