import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCustomerCancellationReasons = (enabled = true) => {
	const { data, isFetched } = useQuery(
		QUERY_KEYS.CANCELLATIONS.CUSTOMER_REASONS,
		async () =>
			await services.get(ENDPOINTS.CANCELLATION.CUSTOMER_CANCELLATION_REASONS),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetched };
};
