import {
	convertFormDates,
	getFilterIndex,
	removePreviousFilterValues,
	spreadFilterArray,
} from 'helpers';

export const initialValues = {
	pickup_date: null,
	pickup_date_to: null,
	dropoff_date: null,
	dropoff_date_to: null,
	company_uuid: ['all'],
	is_settled: 'all',
	has_early_return_state: 'all',
	created_at: null,
	created_at_to: null,
};

export const formatValues = (values, { inputColumns }) => {
	const companyFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: 33,
	});

	const newValues = removePreviousFilterValues({
		values,
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: 33,
	});

	return {
		...newValues,
		...convertFormDates(values, ['pickup_date', 'dropoff_date', 'created_at']),
		...spreadFilterArray({
			arrayElements: values.company_uuid,
			filterIndex: companyFilterIndex,
			defaultFilterIndex: 33,
			inputColumns,
		}),
	};
};
