import { useQuery } from 'react-query';

// Import helpers and utils
import { ENDPOINTS, QUERY_KEYS } from 'helpers';
import services from 'services/services';

export const useTrainStation = ({ uuid }) => {
	const { data, isError, isFetching } = useQuery(
		QUERY_KEYS.TRAIN_STATIONS.DETAILS(uuid),
		async () => await services.get(ENDPOINTS.TRAIN_STATIONS.DETAILS(uuid)),
		{
			refetchOnWindowFocus: false,
			enabled: !!uuid,
		}
	);

	const fetchedData = data ? data.data : null;

	return { data: fetchedData, isFetching, isError };
};
