import * as yup from 'yup';
import i18n from 'i18next';

export const validationSchema = ({ values }) => {
	const requiredFieldText = i18n.t('common.fields.required_field');

	const getDateFromValidation = (holidayName) =>
		values.public_holidays[holidayName]?.selected
			? yup.date().nullable().required(requiredFieldText)
			: yup.date().nullable().notRequired();

	const getDateToValidation = (holidayName) => {
		const { selected, date_from: dateFrom } =
			values.public_holidays[holidayName] || {};

		const minimumValueFieldText = i18n.t('common.fields.minimum', {
			value: dateFrom,
		});

		return selected
			? yup
					.date()
					.nullable()
					.min(new Date(dateFrom), minimumValueFieldText)
					.required(requiredFieldText)
			: yup.date().nullable().notRequired();
	};

	return yup.object({
		company_uuids: yup.array().required(requiredFieldText),
		public_holidays: yup.object({
			eid_al_fitr_holiday: yup.object({
				date_from: getDateFromValidation('eid_al_fitr_holiday'),
				date_to: getDateToValidation('eid_al_fitr_holiday'),
			}),
			eid_al_adha_holiday: yup.object({
				date_from: getDateFromValidation('eid_al_adha_holiday'),
				date_to: getDateToValidation('eid_al_adha_holiday'),
			}),
			national_day_holiday: yup.object({
				date_from: getDateFromValidation('national_day_holiday'),
				date_to: getDateToValidation('national_day_holiday'),
			}),
		}),
	});
};
