export const COLUMN_ACCESSORS = {
	AVAILABLE: 'available',
	ACTIONS: 'actions',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'common.cars.headers.maker',
		accessor: 'maker',
	},
	{
		Header: 'common.cars.headers.model',
		accessor: 'model',
	},
	{
		Header: 'common.cars.headers.year',
		accessor: 'year',
	},
	{
		Header: 'common.cars.headers.available',
		accessor: COLUMN_ACCESSORS.AVAILABLE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.taken',
		accessor: 'taken',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMN_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMN_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
