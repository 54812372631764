import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: '24px',
		display: 'flex',
		gap: '10px',
	},
	input: {
		'& .MuiInputBase-root': {
			width: '150px',
			minHeight: '32px',
		},
		'& .MuiSelect-root': {
			padding: '10px 16px',
			color: theme.palette.text[2],
			fontSize: '12px',
			fontWeight: 600,
		},
	},
}));
