import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const UserProfileRefreshChangeIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M3.19531 3.96875V6.24887H5.47459"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.4999 6.25292C15.1632 3.92836 12.6865 2.495 10.0051 2.49414C7.32359 2.49328 4.84596 3.92505 3.50781 6.24875"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.4368 9.0625C17.7126 11.1999 17.0536 13.3525 15.6285 14.9693C14.2034 16.586 12.1505 17.5098 9.99534 17.5044V17.5044C7.84009 17.5101 5.78701 16.5863 4.36183 14.9695C2.93665 13.3528 2.27777 11.2 2.55391 9.0625"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.3335 13.3359C13.2366 13.092 13.0862 12.8729 12.8933 12.6948V12.6948C12.5684 12.3928 12.1414 12.2249 11.6978 12.2246H8.30058C7.85678 12.2249 7.42947 12.3928 7.10425 12.6948V12.6948C6.91187 12.8733 6.76152 13.0923 6.66406 13.3359"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<circle
			cx="9.99297"
			cy="8.5418"
			r="1.87578"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
