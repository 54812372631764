// Import helpers and utils
import { ACTION_MENU_POPUP_FIELD_TYPES } from 'helpers';

export const useTableBodyRow = () => {
	const {
		EXTEND,
		CLOSE,
		BOOKINGS_HISTORY,
		TRANSACTIONS_HISTORY,
		ADD_RATING,
		DOWNLOAD_INVOICE,
		CANCEL_PAYMENT,
	} = ACTION_MENU_POPUP_FIELD_TYPES;

	const getActionMenuFields = ({
		showPaymentCancelOption,
		paymentLinkUuid,
	}) => {
		const actions = [
			{ type: EXTEND },
			{ type: CLOSE, isDivider: true },
			{ type: BOOKINGS_HISTORY },
			{ type: TRANSACTIONS_HISTORY },
			{ type: ADD_RATING, isDivider: true },
			{ type: DOWNLOAD_INVOICE },
		];

		if (showPaymentCancelOption)
			actions.unshift({
				type: CANCEL_PAYMENT,
				paymentLinkUuid,
			});

		return actions;
	};

	return { getActionMenuFields };
};
