import React from 'react';
import { Box, Divider, Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useInvoice } from './useInvoice';

// Import components
import { EarlyReturnDialog } from 'components/elements';
import { PreviewFields } from '../../../PreviewFields';
import {
	PreviousCompanyInvoice,
	CurrentCompanyInvoice,
	SubscriptionInvoice,
	Cancellation,
	PriceDifference,
	ClosePaymentDetails,
} from './components';

// Import styles
import { useStyles } from './Invoice.styles';

export const Invoice = () => {
	const { t } = useTranslations();
	const {
		isPreviousCompanyInvoiceFetching,
		isPreviousCompanyInvoiceError,
		isCurrentCompanyInvoiceFetching,
		isCurrentCompanyInvoiceError,
		isEarlyReturnDialogVisible,
		isCancellationVisible,
		isPreviousCompanyInvoice,
		isEarlyReturnDetails,
		previousCompanyInvoiceData,
		currentCompanyInvoiceData,
		earlyReturnCustomData,
		earlyReturnFields,
		subscriptionUuid,
		priceDifference,
		gridMd,
		isClosePayment,
		booking,
	} = useInvoice();

	const classes = useStyles({ isEarlyReturnDetails });

	return (
		<Grid container spacing={4}>
			<Grid item xs={12} md={gridMd}>
				<Box className={classes.topBar}>
					<Typography variant="h5">
						{t('bookings.preview_booking.invoice.current_company_invoice')}
					</Typography>
					{isEarlyReturnDialogVisible && <EarlyReturnDialog />}
				</Box>
				<CurrentCompanyInvoice
					data={currentCompanyInvoiceData}
					isFetching={isCurrentCompanyInvoiceFetching}
					isError={isCurrentCompanyInvoiceError}
				/>
			</Grid>

			{isPreviousCompanyInvoice && (
				<Grid item xs={12} md={gridMd}>
					<Box className={classes.topBar}>
						<Typography variant="h5">
							{t('bookings.preview_booking.invoice.previous_company_invoice')}
						</Typography>
					</Box>
					<PreviousCompanyInvoice
						data={previousCompanyInvoiceData}
						isFetching={isPreviousCompanyInvoiceFetching}
						isError={isPreviousCompanyInvoiceError}
					/>
				</Grid>
			)}

			<PriceDifference priceDifference={priceDifference} />

			{isEarlyReturnDetails && (
				<Grid item xs={12} md={gridMd}>
					<Box className={classes.topBar}>
						<Typography variant="h5">
							{t('bookings.preview_booking.early_return.title')}
						</Typography>
					</Box>
					<PreviewFields
						customData={earlyReturnCustomData}
						fields={earlyReturnFields}
					/>
				</Grid>
			)}

			{isCancellationVisible && (
				<Grid item xs={12} md={gridMd}>
					<Box className={classes.topBar}>
						<Typography variant="h5">
							{t('bookings.preview_booking.cancellation')}
						</Typography>
					</Box>
					<Cancellation data={currentCompanyInvoiceData?.cancellation} />
				</Grid>
			)}

			{subscriptionUuid && (
				<>
					<Grid item xs={12}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Box className={classes.topBar}>
							<Typography variant="h5">
								{t('bookings.preview_booking.subscription_invoice.title')}
							</Typography>
						</Box>
						<SubscriptionInvoice subscriptionUuid={subscriptionUuid} />
					</Grid>
				</>
			)}
			{isClosePayment && <ClosePaymentDetails booking={booking} />}
		</Grid>
	);
};
