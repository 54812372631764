import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		color: theme.palette.gray.medium,
		borderRadius: '12px',
		padding: '15px 16px',
		fontFamily: 'Inter',
		fontWeight: '600',
		fontSize: '14px',
		maxHeight: '40px',
		minWidth: 'unset',
	},
	activeButton: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.medium,
	},
}));
