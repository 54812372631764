import { useEffect } from 'react';
import { useMutation } from 'react-query';

// Import helpers
import { getCities } from 'store/actions/dropCities';

export const useCitiesMutation = ({ companyUuid }) => {
	const [mutate, { data: cities, isLoading }] = useMutation(() => {
		return getCities({
			body: {
				company_uuid: companyUuid,
			},
		});
	});

	useEffect(() => {
		if (companyUuid) {
			mutate({});
		}
		// eslint-disable-next-line
	}, [companyUuid]);

	const fetchedData = cities?.data?.data || [];

	return { data: fetchedData, isLoading };
};
