import { DELIVERY_STATUS } from 'helpers';
import { useDeliveryLocationProvider } from '../../context';

export const useStatusChip = ({ hasLateDelivery, outerDeliveryData }) => {
	const { FINISHED, CANCELLED } = DELIVERY_STATUS;

	const deliveryLocationData = useDeliveryLocationProvider();

	const { state: statusChipLabel } =
		outerDeliveryData || deliveryLocationData || {};

	const isChipLabelVisible =
		statusChipLabel === FINISHED || statusChipLabel === CANCELLED;

	const isChipLabelRed = statusChipLabel === CANCELLED || hasLateDelivery;

	const getChipLabel = () => {
		const transKey = 'common.delivery_terms.chip_label';

		if (statusChipLabel === CANCELLED) {
			return `${transKey}.cancelled`;
		} else {
			return hasLateDelivery
				? `${transKey}.delivery_late`
				: `${transKey}.delivery_on_time`;
		}
	};

	const label = getChipLabel();

	return { statusChipLabel, isChipLabelVisible, isChipLabelRed, label };
};
