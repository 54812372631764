// Import helpers
import {
	convertFormDates,
	ADMINS,
	COMPANY_MANAGERS_WITHOUT_ADMINS,
	firstDateOfMonth,
	lastDateOfMonth,
} from 'helpers';

export const initialValues = {
	date: new Date(firstDateOfMonth),
	date_to: new Date(lastDateOfMonth),
	role: 'all',
	office_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		role: values.role === 'all' ? null : values.role,
		office_uuid: values.office_uuid === 'all' ? null : values.office_uuid,
		...convertFormDates(values, ['date']),
	};
};

export const PERMISSION_COMPANY_OFFICES_FILTER = ADMINS;
export const PERMISSION_OFFICE_FILTER = COMPANY_MANAGERS_WITHOUT_ADMINS;
