import React from 'react';
import { Grid, TableCell, Typography } from '@material-ui/core';
import { object } from 'prop-types';
import clsx from 'clsx';

// Import assets
import { DoubleCheckMarkCircleIcon, ErrorWithXIcon } from 'assets/icons';

// Import styles
import { useStyles } from './SettlementDueTableCell.styles';

export const SettlementDueTableCell = ({ cell }) => {
	const classes = useStyles();

	const {
		success_payments_count: successPaymentsCount,
		total_payments_count: totalPaymentsCount,
		is_settled: isSettled,
	} = cell.row.original;

	return (
		<TableCell {...cell.getCellProps()}>
			<Grid container spacing={1} alignItems="center">
				<Grid item>
					<Typography
						className={clsx({
							[classes.text]: true,
							[classes.error]: !isSettled,
						})}
					>{`${successPaymentsCount}/${totalPaymentsCount}`}</Typography>
				</Grid>
				<Grid item>
					{isSettled ? (
						<DoubleCheckMarkCircleIcon className={classes.icon} />
					) : (
						<ErrorWithXIcon
							className={clsx({
								[classes.icon]: true,
								[classes.error]: !isSettled,
							})}
						/>
					)}
				</Grid>
			</Grid>
		</TableCell>
	);
};

SettlementDueTableCell.propTypes = {
	cell: object.isRequired,
};
