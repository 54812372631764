import React from 'react';
import { object } from 'prop-types';
import { TableCell, TableRow } from '@material-ui/core';
import { useParams } from 'react-router-dom';

// Import components
import { ActiveTableCell, NewActionsMenuTableCell } from 'components/elements';

// Import helpers
import { numberWithCommas, capitalize } from 'helpers';
import { COLUMNS_ACCESSORS } from 'views/Offices/LeasingCars/columns';
import { getActionMenuFields } from './helpers';

// Import store
import { updateActiveLeasingCar } from 'store/actions';

const { ODOMETER, COLOR, ACTIONS, ACTIVE } = COLUMNS_ACCESSORS;

export const TableBodyRow = ({ row }) => {
	const { id: officeId } = useParams();

	const renderCell = (cell) => {
		const {
			uuid,
			miles_usage,
			body_color,
			is_active: isActive,
		} = cell.row.original;

		const { id, isHidden } = cell.column;

		if (isHidden) return null;

		switch (id) {
			case ACTIVE:
				return (
					<ActiveTableCell
						key={id}
						activeAction={updateActiveLeasingCar}
						cell={cell}
						id={uuid}
						isActive={isActive}
						additionalParameters={{ officeId }}
					/>
				);

			case ODOMETER:
				return <TableCell key={id}>{numberWithCommas(miles_usage)}</TableCell>;

			case COLOR:
				return <TableCell key={id}>{capitalize(body_color)}</TableCell>;

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={getActionMenuFields({
							officeId,
							vehicleId: uuid,
						})}
						key={cell.column.id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow key={row.id} {...row.getRowProps()}>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
};
