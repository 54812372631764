import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import utils and helpers
import { useMessage, useToggle } from 'components/utilities';

// Import store
import { fetchBookingPreview, resetBookingRating } from 'store/actions';
import { previewBookingSelector } from 'store/selectors';

export const useResetButton = () => {
	const { on: isResetDialogOpen, toggle: toggleResetDialog } = useToggle();
	const [isButtonLoading, setButtonLoading] = useState(false);
	const [isSuccessReset, setSuccessReset] = useState(false);
	const booking = useSelector(previewBookingSelector);
	const { message } = useMessage();
	const dispatch = useDispatch();

	const clearDialogState = () => {
		if (isSuccessReset) {
			toggleResetDialog();
			setSuccessReset(false);
			setButtonLoading(false);

			fetchBookingPreview({ id: booking.uuid })(dispatch);
		} else {
			toggleResetDialog();
		}
	};

	const handleOnResetBookingRating = async () => {
		setButtonLoading(true);

		await resetBookingRating({
			bookingUuid: booking.uuid,
			errorCallback: (error) => {
				const errorData = error.response.data;
				const errorMessage =
					errorData.message ?? errorData.error ?? errorData.errors.uuid;
				message.error(errorMessage);
				setButtonLoading(false);
			},
			successCallback: () => {
				setSuccessReset(true);
				setButtonLoading(false);
			},
		});
	};

	const isButtonHidden = !booking?.ratings?.office;

	return {
		handleOnResetBookingRating,
		toggleResetDialog,
		clearDialogState,
		isResetDialogOpen,
		isButtonLoading,
		isButtonHidden,
		isSuccessReset,
	};
};
