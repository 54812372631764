import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiChip from './MuiChip';
import MuiDialog from './MuiDialog';
import MuiInputBase from './MuiInputBase';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiSelect from './MuiSelect';
import MuiMenuItem from './MuiMenuItem';
import MuiCard from './MuiCard';
import MuiFormLabel from './MuiFormLabel';
import MuiInputLabel from './MuiInputLabel';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiFormControl from './MuiFormControl';
import MuiButtonBase from './MuiButtonBase';

export default {
	MuiButton,
	MuiIconButton,
	MuiButtonBase,
	MuiPaper,
	MuiTableCell,
	MuiTableHead,
	MuiTypography,
	MuiChip,
	MuiDialog,
	MuiInputBase,
	MuiOutlinedInput,
	MuiSelect,
	MuiMenuItem,
	MuiCard,
	MuiFormLabel,
	MuiInputLabel,
	MuiFormControlLabel,
	MuiFormControl,
};
