import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from 'react-query';
import HistoryIcon from '@material-ui/icons/History';
import { Button, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import utils and helpers
import {
	useBookingStatusActionsProvider,
	useTableContextProvider,
} from 'components/context';
import { useMessage, useTranslations, useProfile } from 'components/utilities';
import { URLS } from 'components/routes';
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	getTabIndexBookingDetails,
	replacePreviewBookingUrl,
	BOOKING_DETAILS_TABS,
	BOOKING_STATES,
} from 'helpers';

// Import assets
import {
	DownloadDocumentIcon,
	EditPenOutlinedIcon,
	CheckMarkDoneIcon,
	StarOutlinedIcon,
	CircleCloseIcon,
	BaseEditPenIcon,
	ClockTimeIcon,
	WalletIcon,
	TrashIcon,
	MoneyIcon,
	OfficeIcon,
	CarWithKeyIcon,
	CalendarIcon,
} from 'assets/icons';

// Import components
import {
	ButtonProgress,
	CustomSwitch,
	Grid,
	LoadingWrapper,
} from 'components/elements';

// Import store
import { cancelPayment, printBooking } from 'store/actions';

// Import styles
import { useStyles } from './PreviewPopup.styles';

const {
	TRANSACTIONS_HISTORY,
	SUBSCRIPTION_HISTORY,
	TRANSFER_BOOKINGS,
	CONFIRM_REJECTION,
	CAR_AVAILABILITY,
	CARS_LOG_HISTORY,
	BOOKINGS_HISTORY,
	DOWNLOAD_INVOICE,
	ACCEPT_BOOKING,
	CANCEL_BOOKING,
	ASSIGN_OFFICES,
	EDIT_PRICES,
	ADD_RATING,
	REDIRECT,
	ACTIVE,
	DELETE,
	EDIT,
	EXTEND,
	CLOSE,
	CANCEL_PAYMENT,
} = ACTION_MENU_POPUP_FIELD_TYPES;

export const usePreviewPopupFields = (props) => {
	const {
		handleApproveCancellation,
		handleAccept,
		handleCancel,
		previewPopupProps,
		uuid: bookingUuid,
		canApproveCancellation,
		canCancel,
		canAccept,
		state,
	} = useBookingStatusActionsProvider();

	const isBookingRejected = state === BOOKING_STATES.REJECTED;

	const {
		toggleEditPriceDialog,
		toggleAssignOfficesDialog,
		toggleCloseContractDialog,
		toggleExtendBookingDialog,
	} = props || {};

	const { fetchData } = useTableContextProvider();

	const [isActiveLoading, setActiveLoading] = useState(false);
	const [isDeleteActionLoading, setDeleteActionLoading] = useState(false);

	const { message } = useMessage();
	const { t } = useTranslations();
	const history = useHistory();
	const classes = useStyles();
	const { isAdmin } = useProfile();

	const {
		toggleCarAvailabilityDialog,
		toggleCarsLogHistoryDialog,
		toggleSubscriptionsHistory,
		toggleTransactionsHistory,
		toggleBookingHistory,
	} = previewPopupProps;

	const getAddRatingUrl = () => {
		const addRatingTab = getTabIndexBookingDetails(
			BOOKING_DETAILS_TABS.RATINGS
		);
		return `${replacePreviewBookingUrl(bookingUuid)}?tab=${addRatingTab}`;
	};

	const goToTransferBookings = () =>
		history.push(
			`${URLS.editBookingUrl.replace(':id', bookingUuid)}#alternative-cars`
		);
	const goToRatingsTab = () => history.push(addRatingUrl);
	const handleRedirectTo = (url) => history.push(url);

	const addRatingUrl = getAddRatingUrl();

	const [mutate, { isLoading: invoicePrintLoading }] = useMutation((data) =>
		printBooking(data)
	);
	const handleOnInvoicePrint = () => mutate({ bookingUuid });

	const handleOnActiveSwitch = async (activeAction) => {
		setActiveLoading(true);
		try {
			await activeAction();
			message.success(t('common.messages.successfully_done'));
			await fetchData();
			setActiveLoading(false);
		} catch (error) {
			setActiveLoading(false);

			if (error.response) {
				if (error.response.status === 403) {
					message.error(t('errors.not_authorized'));
				} else {
					message.error(t('common.messages.error_message'));
				}
			} else if (error.message) {
				message.error(error.message);
			} else {
				message.error(t('errors.unknown'));
				throw error;
			}
		}
	};

	const handleOnDelete = async (deleteAction) => {
		setDeleteActionLoading(true);
		try {
			await deleteAction();
			await fetchData();
		} catch (error) {
			if (error?.response) {
				const errorData = error.response.data;
				const errorMessage =
					errorData.message ?? errorData.error ?? errorData.errors.uuid;
				message.error(errorMessage);
			}
		}
		setDeleteActionLoading(false);
	};

	const handlePaymentCancellation = async ({ paymentLinkUuid, popupState }) => {
		await cancelPayment({
			paymentLinkUuid,
			errorCallback: () => {
				message.error(t('common.messages.error_message'));
			},
			successCallback: () => {
				popupState.close();
				message.success(t('common.messages.successfully_canceled'));
				fetchData();
			},
		});
	};

	const renderField = (field, popupState) => {
		const {
			deleteAction,
			activeState,
			editViewUrl,
			editText = 'common.buttons.edit',
			type,
			redirectText,
			redirectUrl,
			redirectIcon: RedirectIcon,
			activeAction,
			paymentLinkUuid,
		} = field;

		switch (type) {
			case ACTIVE: {
				return (
					<Grid item xs={12}>
						<Grid container justifyContent="space-between" alignItems="center">
							<Grid item xs="auto">
								<Typography className={classes.activeLabel}>
									{t(
										activeState
											? 'common.filters.active'
											: 'common.filters.inactive'
									)}
								</Typography>
							</Grid>
							<Grid item xs="auto">
								{isActiveLoading ? (
									<LoadingWrapper
										size={24}
										className={classes.loadingWrapper}
									/>
								) : (
									<CustomSwitch
										onChange={() => handleOnActiveSwitch(activeAction)}
										defaultChecked={activeState}
										color="primary"
									/>
								)}
							</Grid>
						</Grid>
					</Grid>
				);
			}

			case DELETE: {
				return (
					<Grid item xs={12}>
						<ButtonProgress
							startIcon={
								<TrashIcon
									className={clsx({
										[classes.dangerIcon]: !isDeleteActionLoading,
										[classes.icon]: true,
									})}
								/>
							}
							onClick={() => handleOnDelete(deleteAction)}
							className={classes.dangerButton}
							fullWidth
							isLoading={isDeleteActionLoading}
							disabled={isDeleteActionLoading}
						>
							{t('common.buttons.delete')}
						</ButtonProgress>
					</Grid>
				);
			}

			case EDIT: {
				return (
					<Grid item xs={12}>
						<Button
							startIcon={<BaseEditPenIcon className={classes.icon} />}
							onClick={() => handleRedirectTo(editViewUrl)}
							className={clsx([classes.editButton, classes.button])}
							fullWidth
						>
							{t(editText)}
						</Button>
					</Grid>
				);
			}

			case CONFIRM_REJECTION: {
				if (!canApproveCancellation) return null;

				return (
					<Grid item xs={12}>
						<Button
							startIcon={
								<CircleCloseIcon className={classes.confirmRejectionIcon} />
							}
							className={classes.confirmRejectionButton}
							onClick={handleApproveCancellation}
							fullWidth
						>
							{t('common.buttons.confirm_rejection')}
						</Button>
					</Grid>
				);
			}

			case ACCEPT_BOOKING: {
				if (!canAccept) return null;

				return (
					<Grid item xs={12}>
						<Button
							startIcon={<CheckMarkDoneIcon className={classes.reAcceptIcon} />}
							className={classes.reAcceptButton}
							onClick={handleAccept}
							fullWidth
						>
							{t('common.buttons.re_accept_booking')}
						</Button>
					</Grid>
				);
			}

			case CANCEL_BOOKING: {
				if (!canCancel) return null;

				return (
					<Grid item xs={12}>
						<Button
							startIcon={
								<CircleCloseIcon className={classes.confirmRejectionIcon} />
							}
							className={classes.confirmRejectionButton}
							onClick={handleCancel}
							fullWidth
						>
							{t('common.buttons.cancel_booking')}
						</Button>
					</Grid>
				);
			}

			case DOWNLOAD_INVOICE:
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							fullWidth
							startIcon={<DownloadDocumentIcon className={classes.icon} />}
							disabled={invoicePrintLoading}
							onClick={handleOnInvoicePrint}
						>
							{t('common.buttons.download_invoice')}
						</Button>
					</Grid>
				);

			case ADD_RATING:
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							fullWidth
							startIcon={<StarOutlinedIcon className={classes.icon} />}
							onClick={goToRatingsTab}
						>
							{t('common.buttons.add_rating')}
						</Button>
					</Grid>
				);

			case TRANSFER_BOOKINGS:
				if (!isBookingRejected || !isAdmin) return null;
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							fullWidth
							startIcon={<EditPenOutlinedIcon className={classes.icon} />}
							onClick={goToTransferBookings}
						>
							{t('common.buttons.transfer_booking')}
						</Button>
					</Grid>
				);

			case TRANSACTIONS_HISTORY:
				return (
					<Grid item xs={12}>
						<Button
							startIcon={<WalletIcon className={classes.icon} />}
							onClick={toggleTransactionsHistory}
							className={classes.button}
							fullWidth
						>
							{t('common.buttons.transactions_history')}
						</Button>
					</Grid>
				);

			case BOOKINGS_HISTORY:
				return (
					<Grid item xs={12}>
						<Button
							startIcon={<HistoryIcon className={classes.icon} />}
							onClick={toggleBookingHistory}
							className={classes.button}
							fullWidth
						>
							{t('common.buttons.booking_history')}
						</Button>
					</Grid>
				);

			case SUBSCRIPTION_HISTORY:
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							fullWidth
							startIcon={<WalletIcon className={classes.icon} />}
							onClick={toggleSubscriptionsHistory}
						>
							{t('common.buttons.subscription_history')}
						</Button>
					</Grid>
				);

			case REDIRECT:
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							startIcon={<RedirectIcon className={classes.icon} />}
							onClick={() => handleRedirectTo(redirectUrl)}
							fullWidth
						>
							{t(redirectText)}
						</Button>
					</Grid>
				);

			case EDIT_PRICES:
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							startIcon={<MoneyIcon className={classes.icon} />}
							onClick={toggleEditPriceDialog}
							fullWidth
						>
							{t('common.buttons.edit_prices')}
						</Button>
					</Grid>
				);

			case ASSIGN_OFFICES: {
				return (
					<Grid item xs={12}>
						<Button
							startIcon={<OfficeIcon className={classes.icon} />}
							onClick={toggleAssignOfficesDialog}
							className={classes.button}
							fullWidth
						>
							{t('common.buttons.assign_offices')}
						</Button>
					</Grid>
				);
			}

			case CARS_LOG_HISTORY: {
				return (
					<Grid item xs={12}>
						<Button
							startIcon={<ClockTimeIcon className={classes.icon} />}
							onClick={toggleCarsLogHistoryDialog}
							className={classes.button}
							fullWidth
						>
							{t('common.buttons.cars_log_history')}
						</Button>
					</Grid>
				);
			}

			case CAR_AVAILABILITY: {
				return (
					<Grid item xs={12}>
						<Button
							startIcon={<CarWithKeyIcon className={classes.icon} />}
							onClick={toggleCarAvailabilityDialog}
							className={classes.button}
							fullWidth
						>
							{t('common.buttons.car_availability')}
						</Button>
					</Grid>
				);
			}

			case EXTEND:
				return (
					<Grid item xs={12}>
						<Button
							className={classes.button}
							startIcon={<CalendarIcon className={classes.icon} />}
							onClick={() => {
								popupState.close();
								toggleExtendBookingDialog();
							}}
							fullWidth
						>
							{t('common.buttons.extend')}
						</Button>
					</Grid>
				);

			case CLOSE:
				return (
					<Grid item xs={12}>
						<Button
							startIcon={
								<CircleCloseIcon
									className={clsx([classes.dangerIcon, classes.icon])}
								/>
							}
							onClick={() => {
								popupState.close();
								toggleCloseContractDialog();
							}}
							className={classes.dangerButton}
							fullWidth
						>
							{t('common.buttons.close')}
						</Button>
					</Grid>
				);

			case CANCEL_PAYMENT:
				return (
					<Grid item xs={12}>
						<Button
							startIcon={
								<CircleCloseIcon
									className={clsx([classes.dangerIcon, classes.icon])}
								/>
							}
							onClick={() =>
								handlePaymentCancellation({ popupState, paymentLinkUuid })
							}
							className={classes.dangerButton}
							fullWidth
						>
							{t('common.buttons.cancel_payment')}
						</Button>
					</Grid>
				);

			default:
				return null;
		}
	};

	return { renderField };
};
