import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CampaignConditionsCard,
	CampaignCustomerCard,
	CampaignGratificationCard,
	CampaignSupplierCard,
} from '../index';
import { useCustomerRegisteredFields } from './useCustomerRegisteredFields';

export const CustomerRegisteredFields = () => {
	const { customerCardProps, supplierCardProps } =
		useCustomerRegisteredFields();

	return (
		<>
			<Grid item lg={9} xs={12}>
				<CampaignConditionsCard />
			</Grid>

			<Grid item lg={9} xs={12}>
				<CampaignCustomerCard {...customerCardProps} />
			</Grid>

			<Grid item lg={9} xs={12}>
				<CampaignSupplierCard {...supplierCardProps} />
			</Grid>

			<Grid item lg={9} xs={12}>
				<CampaignGratificationCard />
			</Grid>
		</>
	);
};
export default CustomerRegisteredFields;
