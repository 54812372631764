export const CarouselResponsive = {
	desktop: {
		breakpoint: { max: 6000, min: 1024 },
		items: 4,
		slidesToSlide: 4,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		slidesToSlide: 2,
	},
};
