import { useContext } from 'react';

import { TableExportContext } from './TableExportProvider';

const useTableExportContextProvider = () => {
	const context = useContext(TableExportContext);

	return { ...context };
};

export default useTableExportContextProvider;
