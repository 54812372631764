import React from 'react';
import { any, bool, func, object } from 'prop-types';
import { TableCell, TableRow, Typography } from '@material-ui/core';
import { format } from 'date-fns';

// Import components
import {
	BookingIdTableCell,
	CompanyTableCell,
	StateWithActionsTableCell,
	ActionsMenuTableCell,
	Notes,
} from 'components/elements';

// Import styles
import { useSharedStyles } from 'theme/styles/shared';

// Import utils and helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';
import { convertPaymentCollectionCell } from './helpers';
import { useTranslations } from 'components/utilities';

export const TableBodyRow = ({
	row,
	onMouseLeave,
	columnsAccessor,
	onMouseEnter,
	isHovered,
	ref,
}) => {
	const classes = useSharedStyles();

	const { t } = useTranslations();

	const { ID, CUSTOMER, COMPANY, LATE_PAYMENT, DUE_DATE, STATUS, ACTIONS } =
		columnsAccessor;

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			customer_name: customerName,
			customer_phone: customerPhone,
			booking_uuid: bookingUuid,
			due_date: dueDate = '2023-08-18',
			late_payment: latePayment = 2,
			days = 5,
		} = cell.row.original;

		const formattedCell = convertPaymentCollectionCell(cell);

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={formattedCell}
					/>
				);

			case CUSTOMER:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StateWithActionsTableCell
						key={cell.column.id}
						cell={formattedCell}
						isHovered={isHovered}
						minWidth="auto"
					/>
				);

			case LATE_PAYMENT:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						<Typography className={classes.error}>{latePayment}</Typography>
					</TableCell>
				);

			case ACTIONS:
				return (
					<ActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						showSubscriptionsHistory
						showTransactionsHistory={false}
						showPrintInvoice
						showAddRating={false}
						showTransferBooking={false}
						bookingUuid={bookingUuid}
						extraActions={{ bookingHistory: true, subscriptionHistory: true }}
						isHovered={isHovered}
					/>
				);

			case DUE_DATE:
				return (
					<TableCell
						key={cell.column.id}
						{...cell.getCellProps()}
						style={{ minWidth: 150 }}
					>
						{format(new Date(dueDate), DEFAULT_DATE_FORMAT)} <br />
						{t('common.fields.days_ago', { days })}
					</TableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<TableRow
			key={row.id}
			{...row.getRowProps()}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			className={isHovered ? classes.hoverRow : ''}
			ref={ref}
		>
			{row.cells.map(renderCell)}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
