import React from 'react';

// Import components
import {
	DateRangeInput,
	Grid,
	MultiCompaniesFilter,
	MultiOfficesFilter,
} from 'components/elements';

// Import utils and helpers
import { useProfile } from 'components/utilities';

export const Filters = () => {
	const { isAdmin } = useProfile();

	return (
		<>
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="created.from"
					nameUntil="created.until"
					label="common.fields.created_at"
					combinedInputs
					isToggleButton
				/>
			</Grid>

			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			)}

			{!isAdmin && (
				<MultiOfficesFilter
					name="office_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			)}
		</>
	);
};
