import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import components
import { ChipLabel } from 'components/elements';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { CHIP_COLORS } from 'helpers';

export const ActiveStatusTableCell = ({ cell, isActive }) => {
	const { t } = useTranslations();

	const { SUCCESS, ERROR } = CHIP_COLORS;

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<ChipLabel
				label={t(`common.filters.${isActive ? 'active' : 'inactive'}`)}
				color={isActive ? SUCCESS : ERROR}
			/>
		</TableCell>
	);
};

ActiveStatusTableCell.propTypes = {
	cell: object.isRequired,
	isActive: bool.isRequired,
};
