import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	linkText: {
		display: 'flex',
		alignItems: 'center',
		fontSize: 14,
		fontWeight: 600,

		'& svg': {
			marginRight: 8,
		},
	},
}));
