import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CitiesFilter,
	CompanyFilter,
	CompanyOfficesFilter,
	DateRangeInput,
	OfficeFilter,
	ServiceTypeFilter,
} from 'components/elements';
import RoleProtected from 'components/hoc/RoleProtected';

// Import assets
import { OfficeIcon } from 'assets/icons';

// Import helpers
import {
	PERMISSION_COMPANY_FILTER,
	PERMISSION_OFFICE_FILTER,
} from '../../helpers';

export const PrevAdditionalFilterFields = () => (
	<>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
				isToggleButton
			/>
		</Grid>
		<Grid item xs="auto">
			<CitiesFilter
				smallFieldStyle
				name="city_uuid"
				xs="auto"
				md="auto"
				withSearch
				showEmptyLabel
			/>
		</Grid>

		<RoleProtected roles={PERMISSION_COMPANY_FILTER}>
			<CompanyFilter
				label=""
				name="company_uuid"
				smallFieldStyle
				withSearch
				xs="auto"
				md="auto"
				showEmptyLabel
			/>
			<CompanyOfficesFilter
				label=""
				name="office_uuid"
				smallFieldStyle
				withSearch
				showEmptyLabel
				xs="auto"
				md="auto"
				icon={OfficeIcon}
			/>
		</RoleProtected>

		<RoleProtected roles={PERMISSION_OFFICE_FILTER}>
			<OfficeFilter
				label=""
				name="office_uuid"
				smallFieldStyle
				withSearch
				showEmptyLabel
				xs="auto"
				md="auto"
			/>
		</RoleProtected>

		<ServiceTypeFilter
			name="service_type"
			xs="auto"
			md="auto"
			smallFieldStyle
			withSearch
			allLabel="common.fields.service"
		/>
	</>
);
