export const COLUMNS_ACCESSORS = {
	NAME: 'name',
	STATUS: 'status',
	ACTIONS: 'actions',
	AVAILABLE: 'available',
	BOOKINGS: 'bookings',
	ACCEPTED: 'accepted',
};

export const columns = [
	{
		Header: 'common.fields.name',
		accessor: COLUMNS_ACCESSORS.NAME,
	},
	{
		Header: 'common.fields.city',
		accessor: 'city',
	},
	{
		Header: 'common.fields.contact_person',
		accessor: 'contact_person',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.contact_number',
		accessor: 'contact_number',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.available',
		accessor: COLUMNS_ACCESSORS.AVAILABLE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.bookings',
		accessor: COLUMNS_ACCESSORS.BOOKINGS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.accepted',
		accessor: COLUMNS_ACCESSORS.ACCEPTED,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
