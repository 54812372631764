import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ExclamationMarkWithCircleIcon = (props) => (
	<SvgIcon width="17" height="17" viewBox="0 0 17 17" fill="none" {...props}>
		<path
			id="Path"
			d="M8.24966 5.83366C8.15766 5.83366 8.08299 5.90833 8.08366 6.00033C8.08366 6.09233 8.15833 6.16699 8.25033 6.16699C8.34233 6.16699 8.41699 6.09233 8.41699 6.00033C8.41699 5.90833 8.34233 5.83366 8.24966 5.83366"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			id="Path_2"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8.25 14.5V14.5C4.936 14.5 2.25 11.814 2.25 8.5V8.5C2.25 5.186 4.936 2.5 8.25 2.5V2.5C11.564 2.5 14.25 5.186 14.25 8.5V8.5C14.25 11.814 11.564 14.5 8.25 14.5Z"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			id="Path_3"
			d="M8.25033 8.5V11.8333"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
