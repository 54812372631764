import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CircleArrowsIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M10 16.6701C12.4428 16.6703 14.6903 15.335 15.8583 13.1895"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.6694 10.0005C16.6694 6.31707 13.6834 3.33105 10 3.33105"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M9.9989 3.33105C7.55608 3.33085 5.3086 4.66616 4.14062 6.81167"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.32812 10C3.32812 13.6834 6.31414 16.6694 9.99757 16.6694"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4.14062 6.81137C5.59852 4.13218 8.68819 2.78343 11.6441 3.53583C14.6 4.28823 16.6688 6.95003 16.6683 10.0002"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.32813 10C3.32766 13.0502 5.39647 15.712 8.35238 16.4644C11.3083 17.2168 14.398 15.868 15.8558 13.1888"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.69706 6.81118H3.75V3.86328"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.2891 13.1895H16.2361V16.1373"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
