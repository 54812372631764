import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useInvoiceDetails = ({ uuid, enabled = true }) => {
	const { data, isError, isLoading, error } = useQuery(
		QUERY_KEYS.BOOKINGS.INVOICE_DETAILS(uuid),
		async () =>
			await services.get(ENDPOINTS.BOOKINGS.INVOICE_DETAILS({ uuid })),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data : null;

	return { data: fetchedData, isError, error, isLoading };
};
