export default {
	root: {
		textTransform: 'capitalize',
	},
	outlined: { borderRadius: 12 },
	outlinedPrimary: { borderRadius: 12 },
	contained: {
		borderRadius: 12,
		boxShadow: 'none',
		backgroundColor: '#FFFFFF',
	},
	containedSizeLarge: {
		padding: '11px 24px 12px 24px',
	},
};
