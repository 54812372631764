import React from 'react';
import { Button, Collapse } from '@material-ui/core';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';

// Import assets
import { SearchIcon } from 'assets/icons';

// Import components
import { SelectCarFilters } from './components';

export const FindMoreCars = () => {
	const { t } = useTranslations();
	const { on: isOpen, toggle } = useToggle();

	const buttonText = t(
		`bookings.edit_booking.${isOpen ? 'close_the_search' : 'find_more_cars'}`
	);

	return (
		<>
			<Button
				color="primary"
				onClick={toggle}
				startIcon={<SearchIcon color="primary" />}
			>
				{buttonText}
			</Button>
			<Collapse in={isOpen}>
				<SelectCarFilters />
			</Collapse>
		</>
	);
};
