import React from 'react';
import { fetchDrivers } from 'store/actions';

// Import helpers
import { columns } from './columns';
import { TABLE_ACTIONS_ACCESSORS } from 'helpers';

// Import components
import { TableRPC } from 'components/utilities';
import { AddDriverButton } from './components';
import { TableBodyRow } from './components';

export const Drivers = () => {
	return (
		<TableRPC
			columns={columns}
			title="nav.administrative.drivers"
			fetchDataTableAction={fetchDrivers}
			tableBodyRow={TableBodyRow}
			columnsAccessor={TABLE_ACTIONS_ACCESSORS}
			outerToolbarElements={<AddDriverButton />}
		/>
	);
};
