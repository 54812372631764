import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useCompanyOffices } from 'queries';

export const useMultiOfficeSelect = ({ name, externalCompanyUuid }) => {
	const { submitting, values } = useFormState();

	const {
		i18n: { language },
	} = useTranslations();

	const { change } = useForm();

	const { company_uuid } = values;

	const companyUuid = company_uuid || externalCompanyUuid;

	const currentOffices = values?.[name] || [];

	const prevCompanyUuid = useMemo(
		() => companyUuid,
		// eslint-disable-next-line
		[]
	);

	const { data: offices, isFetching } = useCompanyOffices(companyUuid);

	const isDisabled = !offices.length || submitting;

	const findOfficeLabel = (selectedUuid) =>
		offices.find(({ uuid }) => selectedUuid === uuid)?.name[language];

	useEffect(() => {
		if (!_.isEqual(companyUuid, prevCompanyUuid)) {
			change('offices_uuid', []);
		}
		// eslint-disable-next-line
	}, [companyUuid]);

	return {
		findOfficeLabel,
		currentOffices,
		language,
		offices,
		isDisabled,
		isFetching,
	};
};
