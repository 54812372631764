import * as types from 'store/types';
import services from 'services/services';
import localStorage from 'services/localStorage';
import {
	// updateUserProfileUrl,
	forgotPasswordUrl,
	forcePasswordUrl,
	resetPasswordUrl,
	verifyCodeUrl,
	signOutUrl,
	signInUrl,
	updateUserProfileUrl,
	signInVerifyUrl,
	// updateUserProfileUrl,
} from 'store/paths';

// Import variables
import { convertProfileData, modifyVerifyData } from './helpers';

// Import actions
import { getProfile } from 'store/actions';

// ******************** FORGOT PASSWORD ********************
export const forgotPassword =
	(values, errorCallback, callbackSuccess) => async (dispatch) => {
		try {
			const {
				data: {
					data: { token },
				},
			} = await services.post(forgotPasswordUrl, values);
			dispatch({
				type: types.SET_RESET_TOKEN,
				payload: token,
			});
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const verifyCode =
	(values, errorCallback, callbackSuccess) => async () => {
		try {
			await services.post(verifyCodeUrl, values);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const resetPassword =
	(values, errorCallback, callbackSuccess) => async (dispatch) => {
		try {
			await services.post(resetPasswordUrl, values);
			dispatch({
				type: types.SET_RESET_TOKEN,
				payload: null,
			});
			await signOut()(dispatch);
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data.errors);
		}
	};

export const forcePassword =
	(errorCallback, callbackSuccess) => async (dispatch) => {
		try {
			const {
				data: {
					data: { token },
				},
			} = await services.get(forcePasswordUrl);
			dispatch({
				type: types.SET_RESET_TOKEN,
				payload: token,
			});
			callbackSuccess();
		} catch (error) {
			error.response && errorCallback(error.response.data?.errors);
		}
	};

// ******************** LOGIN ********************
export const signIn = async ({ values, errorCallback, successCallback }) => {
	try {
		const { data } = await services.post(signInUrl, values);
		successCallback(data);
	} catch (error) {
		error.response && errorCallback(error.response.data);
	}
};

// ******************** Verify OTP ********************
export const verifyLoginOtp = (values, errorCallback) => async (dispatch) => {
	try {
		const { data } = await services.post(signInVerifyUrl, values);

		const convertedData = { ...modifyVerifyData(data) };

		localStorage.setLsValue(convertedData);

		dispatch({
			type: types.AUTH_USER_SUCCESS,
			payload: convertedData,
		});
	} catch (error) {
		error.response && errorCallback(error.response.data);
		dispatch({
			type: types.AUTH_USER_ERROR,
			payload: true,
		});
	}
};

// ******************** SIGN OUT ********************
export const signOut = () => async (dispatch) => {
	try {
		// Dispatch an loading action
		dispatch({
			type: types.SIGN_OUT_LOADING,
			payload: true,
		});

		// Sign out user from application
		await services.get(signOutUrl);

		// Remove user data from local storage
		localStorage.removeLsValue();

		dispatch({
			type: types.SIGN_OUT_SUCCESS,
			payload: true,
		});
	} catch (error) {
		dispatch({
			type: types.SIGN_OUT_ERROR,
			payload: true,
		});
	}
};

export const updateUserProfile = () => async (dispatch) => {
	try {
		const {
			data: { data },
		} = await services.get(updateUserProfileUrl);

		const convertedProfileData = convertProfileData(data);

		await getProfile(dispatch);

		dispatch({
			type: types.USER_PROFILE_FETCH_SUCCESS,
			payload: convertedProfileData,
		});
	} catch (error) {
		dispatch({
			type: types.USER_PROFILE_FETCH_ERROR,
			payload: true,
		});
	}
};
