import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

// Import utilities
import { previewBookingSelector } from 'store/selectors';

// Import components
import { PreviewField } from 'components/elements';

// Import helpers
import { convertDate } from 'helpers';

export const NewDropDatePreviewField = ({ label }) => {
	const {
		extension: { extension_new_drop_date },
	} = useSelector(previewBookingSelector);

	const date = convertDate(extension_new_drop_date);

	return <PreviewField label={label}> {date} </PreviewField>;
};

NewDropDatePreviewField.propTypes = {
	label: string,
};
