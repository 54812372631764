import React from 'react';
import { Divider, Grid } from '@material-ui/core';

// Import components
import {
	AssignFields,
	UrgentRequests,
	ValidationCancellationFields,
} from './components';

// Import styles
import { useStyles } from '../../AssignUrgent.styles';

// Import helpers and context
import { UrgentFormProvider } from './context';

export const UrgentForms = () => {
	const classes = useStyles();

	return (
		<Grid container direction="column" className={classes.container}>
			<UrgentFormProvider>
				<Grid item>
					<AssignFields />
					<UrgentRequests />
				</Grid>
				<Grid item>
					<Divider className={classes.divider} />
				</Grid>
				<Grid item>
					<ValidationCancellationFields />
				</Grid>
			</UrgentFormProvider>
		</Grid>
	);
};
