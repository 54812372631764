// Import utils and helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	replaceGlobalAddCarUrl,
	replaceCompanyDropCitiesUrl,
	replaceCompanyEmployeePerformanceUrl,
	replaceEditCompanyUrl,
	replaceEmployeePerformanceUsersWithIdUrl,
} from 'helpers';
import { URLS } from 'components/routes';

// Import assets
import {
	AddCarIcon,
	AddOfficeIcon,
	DropCityIcon,
	RewardIcon,
	TwoUsersIcon,
	UserPerformanceIcon,
} from 'assets/icons';

// Import store
import { updateActiveCompany } from 'store/actions';

const { ACTIVE, EDIT, REDIRECT, EDIT_PRICES } = ACTION_MENU_POPUP_FIELD_TYPES;

export const useCell = (cell) => {
	const { active: isActive, uuid } = cell.row.original;

	const activeAction = () => updateActiveCompany({ isActive, id: uuid });

	const actionMenuFields = [
		{
			type: ACTIVE,
			activeState: isActive,
			isDivider: true,
			activeAction,
		},
		{
			type: EDIT,
			editText: 'company.form.title',
			editViewUrl: replaceEditCompanyUrl(uuid),
		},
		{
			type: EDIT_PRICES,
		},
		{
			type: REDIRECT,
			redirectText: 'common.buttons.add_office',
			redirectUrl: URLS.createOfficeUrl,
			redirectIcon: AddOfficeIcon,
		},
		{
			type: REDIRECT,
			redirectText: 'common.buttons.add_car',
			redirectUrl: replaceGlobalAddCarUrl(uuid),
			redirectIcon: AddCarIcon,
		},
		{
			type: REDIRECT,
			redirectText: 'users.table.toolbar.add_user',
			redirectUrl: URLS.addUserUrl,
			redirectIcon: TwoUsersIcon,
			isDivider: true,
		},

		{
			type: REDIRECT,
			redirectText: 'company.table.headers.drop_cities',
			redirectUrl: replaceCompanyDropCitiesUrl(uuid),
			redirectIcon: DropCityIcon,
		},
		{
			type: REDIRECT,
			redirectText: 'common.buttons.rewards',
			redirectUrl: replaceCompanyEmployeePerformanceUrl(uuid),
			redirectIcon: RewardIcon,
		},
		{
			type: REDIRECT,
			redirectText: 'nav.employee_performance',
			redirectUrl: replaceEmployeePerformanceUsersWithIdUrl(uuid),
			redirectIcon: UserPerformanceIcon,
		},
	];

	return {
		actionMenuFields,
	};
};
