import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	table: {
		border: `1px solid ${theme.palette.background[5]}`,
		marginBottom: theme.spacing(4),
	},
	tableHead: {
		backgroundColor: 'transparent',
		padding: '14px 24px',

		'& .MuiTableCell-head': {
			color: theme.palette.text[1],
		},
	},
}));
