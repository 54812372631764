import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	fieldValue: {
		fontFamily: 'Inter',
		fontSize: '14px',
		fontWeight: 400,
		color: theme.palette.text[2],
	},
}));
