import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		marginBottom: theme.spacing(3),
		fontWeight: 600,
	},
	loader: {
		width: '100%',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
	headerContainer: {
		minHeight: 0,
	},
}));
