export const COLUMNS_ACCESSORS = {
	ID: 'id',
	CUSTOMER: 'customer',
	COMPANY: 'company',
	LATE_PAYMENT: 'late_payment',
	DUE_DATE: 'due_date',
	STATUS: 'status',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.late_payment',
		accessor: COLUMNS_ACCESSORS.LATE_PAYMENT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.total_overdue',
		accessor: 'total_overdue',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.due_date',
		accessor: COLUMNS_ACCESSORS.DUE_DATE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
