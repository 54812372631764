import { makeStyles } from '@material-ui/styles';
import { INPUT_BACKGROUND_COLOR } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 16px',
		backgroundColor: INPUT_BACKGROUND_COLOR,
		minWidth: '324px',
		borderRadius: '8px',
		height: '42px',

		'& .MuiInputBase-root': {
			minHeight: '40px',
		},
	},
	icon: {
		color: theme.palette.text.secondary,
		marginRight: theme.spacing(2),
	},
	iconClose: {
		color: theme.palette.text.secondary,
	},
	input: {
		flexGrow: 1,
		fontSize: '14px',
		letterSpacing: '-0.05px',
	},
}));
