import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DotsMenuIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M18.5029 11.9983C18.5029 12.2745 18.2789 12.4985 18.0026 12.4985C17.7264 12.4985 17.5024 12.2745 17.5024 11.9983C17.5024 11.722 17.7264 11.498 18.0026 11.498C18.2789 11.498 18.5029 11.722 18.5029 11.9983"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12.5004 11.9983C12.5004 12.2745 12.2765 12.4985 12.0002 12.4985C11.724 12.4985 11.5 12.2745 11.5 11.9983C11.5 11.722 11.724 11.498 12.0002 11.498C12.2765 11.498 12.5004 11.722 12.5004 11.9983"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M6.49798 11.9983C6.49798 12.2745 6.27402 12.4985 5.99777 12.4985C5.72151 12.4985 5.49756 12.2745 5.49756 11.9983C5.49756 11.722 5.72151 11.498 5.99777 11.498C6.27402 11.498 6.49798 11.722 6.49798 11.9983"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
