import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import components
import useTimeElapsed from './useTimeElapsed';

// Import styles
import { useStyles } from './styles';

// Import translations
import { useTranslations } from 'components/utilities';

export const TimeElapsed = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { elapsedTime } = useTimeElapsed();

	return (
		<Box className={classes.wrapper}>
			<Typography>
				{t('bookings.preview_booking.urgent_attention.customer_waiting') + ' '}
				<Typography component="span" className={classes.timeElapsed}>
					{elapsedTime}
				</Typography>
			</Typography>
		</Box>
	);
};
