import React from 'react';

// Import components
import { AdditionalColumnsDialog } from 'components/elements';

// Import helpers
import { ADDITIONAL_INFO_FIELDS } from './helpers';

export const AdditionalInfo = () => (
	<AdditionalColumnsDialog fields={ADDITIONAL_INFO_FIELDS} />
);
