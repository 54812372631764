import React from 'react';
import { Divider, Grid } from '@material-ui/core';

// Import components
import { Delivery, Info } from './components';
import RoleProtected from 'components/hoc/RoleProtected';
import { CollapsableCard, LoadingWrapper } from 'components/elements';

// Import helpers and utils
import {
	PERMISSION_DELIVERY_INFORMATION,
	PERMISSION_OLD_DELIVERY_INFORMATION,
} from './helpers';
import { useTranslations } from 'components/utilities';
import { useBookingInfoTab } from './useBookingInfoTab';

// Import styles
import { useStyles } from './BookingInfoTab.styles';

export const BookingInfoTab = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { isHistory, isDelivery, editBookingHistory, isFetching } =
		useBookingInfoTab();

	const { old_deliveries: oldDeliveries } = editBookingHistory || {};

	const { deliveries, delivery_details: deliveryDetails } = oldDeliveries || {};

	const { location, lat, lng } = deliveryDetails || {};

	return (
		<Grid container>
			<Grid item xs={12} className={classes.infoContainer}>
				<Info />
			</Grid>
			<RoleProtected roles={PERMISSION_DELIVERY_INFORMATION}>
				{isDelivery && (
					<>
						<Divider className={classes.divider} />
						<Grid item xs={12}>
							<Delivery />
						</Grid>
					</>
				)}
			</RoleProtected>
			{isFetching ? (
				<LoadingWrapper />
			) : (
				<RoleProtected roles={PERMISSION_OLD_DELIVERY_INFORMATION}>
					<Grid item xs={12}>
						{isHistory &&
							deliveries.length > 0 &&
							deliveries.map(({ delivery_uuid, ...delivery }) => (
								<CollapsableCard
									key={delivery_uuid}
									cardClassName={classes.oldDeliveryCard}
									headerClassName={classes.oldDeliveryHeader}
									title={t('common.delivery_terms.old_deliveries')}
									initialOpen={false}
								>
									<Delivery
										hideTitle
										outerDeliveryData={{
											...delivery,
											state: delivery.delivery_state,
											type: delivery.delivery_type,
											address: location,
											lat,
											lng,
										}}
									/>
								</CollapsableCard>
							))}
					</Grid>
				</RoleProtected>
			)}
		</Grid>
	);
};
