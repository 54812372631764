// Import helpers
import { TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { Legend } from './components';

export const toolbarContent = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Legend,
		customKey: 'legend',
	},
];
