import React from 'react';
import { object } from 'prop-types';
import { Chip, Grid, Tooltip } from '@material-ui/core';

// Import helpers
import { PAYMENT_METHODS } from 'helpers';

// Import translations
import { useTranslations } from 'components/utilities';

// Import icons
import { CardsIcon } from 'assets/icons';

const { WALLET, CARD } = PAYMENT_METHODS;

export const NotesForOthers = ({ classes, cell }) => {
	const { t } = useTranslations();

	const { payment, total_discounted_gross_price: amount } = cell.row.original;

	const paidOnline = payment?.method === WALLET || payment?.method === CARD;

	return (
		<>
			{paidOnline && (
				<Grid item>
					<Tooltip
						title={t('bookings.table.notes.payment_for_partners', { amount })}
					>
						<Chip
							className={classes.chip}
							size="small"
							label={<CardsIcon className={classes.cardsIcon} />}
						/>
					</Tooltip>
				</Grid>
			)}
		</>
	);
};

NotesForOthers.propTypes = {
	classes: object.isRequired,
	cell: object,
};
