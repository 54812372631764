import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { string } from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from './OldAndNewDates.styles';

export const OldAndNewDates = ({
	currentDate,
	prevDate,
	currentDateTitle,
	prevDateTitle,
	containerClassName,
}) => {
	const classes = useStyles();

	return (
		<Grid
			container
			spacing={2}
			className={clsx({
				[classes.container]: true,
				[containerClassName]: !!containerClassName,
			})}
		>
			<Grid item xs={12}>
				<Typography variant="body1" className={classes.title}>
					{`${prevDateTitle}:`}
				</Typography>
				<Typography variant="body1" className={classes.date}>
					{prevDate}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1" className={classes.title}>
					{`${currentDateTitle}:`}
				</Typography>
				<Typography variant="body1" className={classes.date}>
					{currentDate}
				</Typography>
			</Grid>
		</Grid>
	);
};

OldAndNewDates.propTypes = {
	currentDate: string.isRequired,
	prevDate: string.isRequired,
	currentDateTitle: string.isRequired,
	prevDateTitle: string.isRequired,
	containerClassName: string,
};
