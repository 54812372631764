import { t } from 'i18next';

// Import helpers
import { EMPTY_VALUE } from 'helpers';

export const EARLY_RETURN_FIELDS = [
	{
		accessor: 'payment_no',
		label: 'common.fields.payment_no',
	},
	{
		accessor: 'amount.gross',
		label: 'common.fields.amount',
	},
	{
		accessor: 'transactional_type',
		label: 'common.fields.transactional_type',
	},
];

export const convertedEarlyReturnData = (earlyReturnData) => {
	const { transactional_type, amount } = earlyReturnData || {};

	return {
		...earlyReturnData,
		transactional_type: transactional_type
			? t(`common.fields.transactional_types.${transactional_type}`)
			: EMPTY_VALUE,
		amount: {
			...amount,
			gross: t('currency.price', { amount: amount?.gross }),
		},
	};
};
