import React from 'react';
import { bool, func } from 'prop-types';
import { Button, Grid } from '@material-ui/core';

// Import components
import { ButtonProgress, Alert } from 'components/elements';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import helpers
import { ADD_AMOUNT_DIALOG_FIELDS } from './helpers';

export const FormContent = ({ toggle, isLoading }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { reasons, minDate, isDescriptionInputVisible, isError } =
		useFormContent();

	if (isError) {
		return <Alert title={t('common.messages.error_message')} />;
	}

	return (
		<Grid container direction="column" spacing={1}>
			<Grid container item spacing={1}>
				{ADD_AMOUNT_DIALOG_FIELDS({
					isDescriptionInputVisible,
					reasons,
					minDate,
				}).map(renderFields)}
			</Grid>
			<Grid justifyContent="flex-end" spacing={1} container item>
				<Grid item xs="auto">
					<Button variant="outlined" color="primary" onClick={toggle}>
						{t('common.buttons.close')}
					</Button>
				</Grid>
				<Grid item xs="auto">
					<ButtonProgress
						circularProgressSize={24}
						isLoading={isLoading}
						disabled={isLoading}
						variant="contained"
						color="primary"
						type="submit"
					>
						{t('common.buttons.submit')}
					</ButtonProgress>
				</Grid>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func,
	isLoading: bool,
};
