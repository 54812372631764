export const SELECT_CAR_FILTER_INITIAL_VALUES = {
	office_uuid: '',
	company_uuid: '',

	vehicle: {
		year: '',
		maker_uuid: '',
		model_uuid: '',
	},
	offsets: {
		offset: '',
	},
	item: { uuid: '' },
	sort: 'near_me_company_edit',
};
