import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useAllCities = () => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.CITIES.ALL_CITIES,
		async () => await services.get(ENDPOINTS.CITIES.CITIES_COLLECTION),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
