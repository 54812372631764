export const COLUMNS_ACCESSORS = {
	TOTAL_DEPOSIT_COUNTER: 'total_deposit_counter',
	CAMPAIGN_TYPE: 'campaign_type',
	ACTIONS: 'actions',
	CREATED: 'created',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'campaigns.table.headers.campaign_type',
		accessor: COLUMNS_ACCESSORS.CAMPAIGN_TYPE,
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.created',
		accessor: COLUMNS_ACCESSORS.CREATED,
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.started',
		accessor: 'campaign_duration_from',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.ended',
		accessor: 'campaign_duration_to',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_users',
		accessor: COLUMNS_ACCESSORS.TOTAL_DEPOSIT_COUNTER,
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_usage',
		accessor: 'total_used_counter',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_deposits',
		accessor: 'total_deposit_amount',
		disableSortBy: true,
	},
	{
		Header: 'campaigns.table.headers.total_spent',
		accessor: 'total_spend_amount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
