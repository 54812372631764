import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeFilter } from 'components/elements';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { useFilterFields } from './useFilterFields';

export const FilterFields = () => {
	const { renderFields } = useCommonFields();
	const { REPORT_TYPE_FILTER, isUserTypeSelected } = useFilterFields();

	return (
		<Grid container spacing={3}>
			{REPORT_TYPE_FILTER.map(renderFields)}
			{isUserTypeSelected && (
				<DateRangeFilter
					nameFrom="startDate"
					nameUntil="endDate"
					label="common.fields.date"
					combinedInputs
					md={3}
				/>
			)}
		</Grid>
	);
};
