import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		width: '300px',
		backgroundColor: theme.palette.white,
		position: 'fixed',
		right: '16px',
		bottom: 0,
		borderLeft: `1px solid ${theme.palette.background[5]}`,
		height: `calc(100% - 80px)`,
	},
}));
