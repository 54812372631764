// Import helpers
import { DELIVERY_STATUS } from 'helpers';
import palette from 'theme/palette';

export const useDeliveryStatus = ({ cell, isDropOffSort }) => {
	const {
		delivery: { drop_delivery, pick_up_delivery },
		is_drop_delivery,
		is_pickup_delivery,
	} = cell.row.original;

	const { delivery_state: pickupDeliveryState } = pick_up_delivery || {};
	const { delivery_state: dropoffDeliveryState } = drop_delivery || {};

	const status = isDropOffSort ? dropoffDeliveryState : pickupDeliveryState;

	const isDeliveryPending = (status) => status === DELIVERY_STATUS.PENDING;

	const isPickupAllowed =
		(pick_up_delivery.is_available || is_pickup_delivery) &&
		pickupDeliveryState &&
		!isDeliveryPending(pickupDeliveryState);

	const isDropoffAllowed =
		(drop_delivery.is_available || is_drop_delivery) &&
		dropoffDeliveryState &&
		!isDeliveryPending(dropoffDeliveryState);

	const isAllow = isDropOffSort ? isDropoffAllowed : isPickupAllowed;

	const getChipLabel = () => {
		if (!isAllow) {
			return 'common.not_available_shorthand';
		} else {
			return `common.delivery_status.${status}`;
		}
	};

	const getChipColors = () => {
		if (!isAllow || status === DELIVERY_STATUS.PROLONGED) {
			return {
				backgroundColor: palette.background[6],
				color: palette.text[1],
			};
		} else if (
			status === DELIVERY_STATUS.IN_PROGRESS ||
			status === DELIVERY_STATUS.PENDING ||
			status === DELIVERY_STATUS.ARRIVED
		) {
			return {
				backgroundColor: palette.warning.medium,
				color: palette.warning.dark,
			};
		} else if (status === DELIVERY_STATUS.CANCELLED) {
			return {
				backgroundColor: palette.error.lighter,
				color: palette.error.medium,
			};
		} else {
			return {
				backgroundColor: palette.success.lighter,
				color: palette.success.medium,
			};
		}
	};

	const chipColors = getChipColors();
	const chipLabel = getChipLabel();

	return {
		chipColors,
		chipLabel,
		isAllow,
	};
};
