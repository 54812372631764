import React from 'react';
import PropTypes, { bool, number, oneOfType, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import { SelectFieldAdapter } from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { OPTIONS } from './helpers';

export const AcceptedBookingsNumberFilter = ({
	name,
	label,
	xs = 12,
	md = 3,
	smallFieldStyle = false,
	allLabel = 'customers.filters.titles.accepted_bookings_number',
}) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const renderValue = (value) => (value === 'all' ? t(allLabel) : value);

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
				smallfieldstyle={smallFieldStyle}
				SelectProps={{ renderValue }}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{OPTIONS.map((option) => (
					<MenuItem key={option} value={option}>
						{option}
					</MenuItem>
				))}
				<MenuItem value={10}>
					{t('customers.filters.last_select_option')}
				</MenuItem>
			</Field>
		</Grid>
	);
};

AcceptedBookingsNumberFilter.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	smallFieldStyle: bool,
	allLabel: string,
	xs: oneOfType([number, string]),
	md: oneOfType([number, string]),
};
