import React from 'react';
import { Divider, Grid } from '@material-ui/core';

// Import components
import { Amount, CurrentBooking } from './components';

// Import styles
import { useStyles } from './BookingInfo.styles';

export const BookingInfo = () => {
	const classes = useStyles();

	return (
		<Grid container className={classes.container}>
			<Grid item xs={12}>
				<Amount />
				<Divider className={classes.divider} />
				<CurrentBooking />
			</Grid>
		</Grid>
	);
};
