import React from 'react';

// Import store
import { fetchValidationBookings } from 'store/actions';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BOOKING_COMPENSATION_STATES } from 'helpers';
import { TableRPC } from 'components/utilities';
import { BUTTONS } from './helpers';

// Import components
import { InnerNavbarElementsButtons } from 'components/elements';
import { Filters, TableBodyRow } from './components';

export const Validation = () => (
	<TableRPC
		fetchDataTableAction={fetchValidationBookings}
		title="nav.operations.compensation_validation"
		columnsAccessor={COLUMNS_ACCESSORS}
		tableBodyRow={TableBodyRow}
		filtersBar={<Filters />}
		columns={columns}
		tableNavigation={
			<InnerNavbarElementsButtons
				defaultFilterState={BOOKING_COMPENSATION_STATES.REVIEW}
				filterPath="compensation_status"
				buttons={BUTTONS}
			/>
		}
	/>
);
