import { makeStyles } from '@material-ui/styles';

const logoSharedStyles = (theme) => ({
	color: theme.palette.primary.main,
	marginTop: '20px',
});

export const useStyles = makeStyles((theme) => ({
	link: {
		position: 'relative',
		minHeight: '100px',
		width: 'fit-content',
	},
	logo: {
		fontSize: '150px',
		height: 'auto',
		...logoSharedStyles(theme),
	},
	logoShorthand: {
		...logoSharedStyles(theme),
		fontSize: '32px',
	},
}));
