import React from 'react';
import { number, object, oneOfType, string } from 'prop-types';
import { Box, TableCell, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from '../styles';

// Import helpers
import { EMPTY_VALUE } from 'helpers';

// Import assets
import { StarOutlinedIcon } from 'assets/icons';

export const RatingTableCell = ({ cell, rating, emptyValue = EMPTY_VALUE }) => {
	const classes = useStyles();

	return (
		<TableCell {...cell.getCellProps()}>
			{rating ? (
				<Box className={classes.ratingCellContainer}>
					<StarOutlinedIcon className={classes.ratingCellIcon} />
					<Typography>{rating}</Typography>
				</Box>
			) : (
				<Typography>{emptyValue}</Typography>
			)}
		</TableCell>
	);
};

RatingTableCell.propTypes = {
	rating: oneOfType([string, number]),
	cell: object.isRequired,
	emptyValue: string,
};
