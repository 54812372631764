/* eslint-disable react/prop-types */
import React from 'react';
import clsx from 'clsx';
import { IconButton } from '@material-ui/core';
import { PaginationItem as MuiPaginationItem } from '@material-ui/lab';

// Import helpers and utils
import { renderIcon } from './helpers';
import { AR, PAGINATION_BUTTON_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';

import { useStyles } from '../../Pagination.styles';

const { FIRST, LAST, NEXT, PREV } = PAGINATION_BUTTON_TYPES;

export const PaginationItem = (props) => {
	const classes = useStyles();

	const {
		i18n: { language },
	} = useTranslations();

	const navIconClassName = language === AR ? classes.rtlIcon : null;

	const renderItem = () => {
		const { type } = props;

		switch (type) {
			case FIRST:
			case PREV:
			case NEXT:
			case LAST:
				return (
					<IconButton
						{...props}
						className={clsx([
							classes.paginationButton,
							classes.sideButton,
							navIconClassName,
						])}
					>
						{renderIcon(type)}
					</IconButton>
				);

			default:
				return (
					<MuiPaginationItem {...props} className={classes.paginationButton} />
				);
		}
	};

	return renderItem();
};
