import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		gap: '16px',
		justifyContent: 'space-between',
		paddingTop: '12px',
	},
	serviceContainer: {
		display: 'flex',
		alignItems: 'center',
		gap: '5px',
	},
	icon: {
		width: '16px',
		color: theme.palette.gray[1],
	},
	text: {
		fontSize: '14px',
		fontWeight: 400,
	},
}));
