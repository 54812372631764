// Import helpers
import { BOOKING_URGENT_STATES, TOOLBAR_VARIANTS } from 'helpers';

// Import components
import { UrgentStats } from 'components/elements';

const { SOLVED, UNSOLVED, REVIEW } = BOOKING_URGENT_STATES;

export const buttons = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.common_states.review',
		status: REVIEW,
	},
	{
		label: 'common.common_states.solved',
		status: SOLVED,
	},
	{
		label: 'common.common_states.unsolved',
		status: UNSOLVED,
	},
];

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: UrgentStats,
		customKey: 'stats',
	},
];
