import i18n from 'i18n';

const getErrorMessage = (error) => {
	let message = i18n.t('errors.unknown');

	if (error && error.response) {
		message = error.response.data.message
			? error.response.data.message
			: error.response.data.error;
	}

	return message;
};

export default getErrorMessage;
