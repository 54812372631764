import React from 'react';
import { func, bool } from 'prop-types';
import { Button } from '@material-ui/core';
import { ExpandMore, ExpandLess } from '@material-ui/icons';

// Import styles
import { useStyles } from './ToggleButton.styles';

// Import translations
import { useTranslations } from 'components/utilities';

export const ToggleButton = ({
	onClick,
	isOpen,
	withoutText = false,
	disableRipple = false,
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Button
			color="primary"
			className={classes.collapseBtn}
			onClick={onClick}
			disableRipple={disableRipple}
			startIcon={
				isOpen ? <ExpandLess color="primary" /> : <ExpandMore color="primary" />
			}
		>
			{!withoutText && t(`common.buttons.${isOpen ? 'minimize' : 'maximize'}`)}
		</Button>
	);
};

ToggleButton.propTypes = {
	onClick: func.isRequired,
	isOpen: bool,
	withoutText: bool,
	disableRipple: bool,
};
