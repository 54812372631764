import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckMarkWithCircleIcon = (props) => (
	<SvgIcon width="26" height="26" viewBox="0 0 26 26" fill="none" {...props}>
		<path
			d="M19.4673 6.68579L12.0002 14.1259L8.96191 11.0876"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M20.2877 10.1163C21.1957 14.1089 19.1293 18.1817 15.3711 19.8069C11.6129 21.4322 7.23004 20.1483 4.94277 16.7521C2.6555 13.3559 3.11334 8.81189 6.03215 5.94025C8.95095 3.06862 13.5018 2.68485 16.8603 5.02714"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
