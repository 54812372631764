import { getCheckedOptions, getServiceType } from 'helpers';

const COLUMN_ACCESSORS = {
	OPTIONS: 'options',
};

export const CURRENT_BOOKING_FIELDS = [
	{
		accessor: 'individual_number',
		label: 'common.fields.booking_id',
	},
	{
		accessor: 'customer.full_name',
		label: 'common.fields.customer',
	},
	{
		accessor: 'period.pick_date',
		label: 'common.fields.pickup_date',
	},
	{
		accessor: 'period.pick_time',
		label: 'common.fields.pickup_time',
	},
	{
		accessor: 'period.drop_date',
		label: 'common.fields.dropoff_date',
	},
	{
		accessor: 'period.days',
		label: 'common.fields.period',
	},
	{
		accessor: 'item.name',
		label: 'common.fields.car',
	},
	{
		accessor: 'item.base_gross_price',
		label: 'common.fields.price',
	},
	{
		accessor: 'service',
		label: 'common.fields.service',
	},
	{
		accessor: COLUMN_ACCESSORS.OPTIONS,
		label: '',
		hideIfEmpty: true,
	},
];

export const getConvertedCustomData = (customData) => {
	const { options } = customData || {};
	const service = getServiceType(customData);
	const formattedOptions = getCheckedOptions(options);

	return {
		...customData,
		service,
		options: formattedOptions,
	};
};
