import React from 'react';
import { bool, object } from 'prop-types';
import { Typography } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

// Import components
import { DeliveryTabs } from './components';

// Import context
import { TabsProvider } from 'components/context';

// Import styles
import { useStyles } from './Delivery.styles';

export const Delivery = ({ hideTitle, outerDeliveryData }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<>
			{!hideTitle && (
				<Typography variant="body1" className={classes.title}>
					{t('common.delivery_terms.delivery_information')}
				</Typography>
			)}
			<TabsProvider>
				<DeliveryTabs outerDeliveryData={outerDeliveryData} />
			</TabsProvider>
		</>
	);
};

Delivery.propTypes = {
	hideTitle: bool,
	outerDeliveryData: object,
};
