import React from 'react';
import { Button } from '@material-ui/core';
import { bool, func, string } from 'prop-types';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './ToggleButton.styles';

export const ToggleButton = ({ isOpen, toggle, label }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Button
			endIcon={isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
			className={classes.button}
			onClick={toggle}
		>
			{t(label)}
		</Button>
	);
};

ToggleButton.propTypes = {
	label: string.isRequired,
	toggle: func.isRequired,
	isOpen: bool,
};
