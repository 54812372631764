import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useBookingPreview = (uuid) => {
	const { data, isFetching, isError, isFetched } = useQuery(
		QUERY_KEYS.BOOKINGS.COUNT(uuid),
		async () =>
			await services.get(ENDPOINTS.BOOKINGS.BOOKING_PREVIEW({ uuid })),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching, isError, isFetched };
};

export default useBookingPreview;
