import React from 'react';
import { string, oneOfType, oneOf, arrayOf, node, object } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './PreviewField.styles';

export const PreviewField = ({
	label,
	children,
	labelColor = 'initial',
	labelOptions = {},
	valueClassName,
	labelClassName = '',
	labelSubText = '',
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid
			container
			spacing={5}
			justifyContent="space-between"
			alignItems="center"
			className={classes.container}
		>
			<Grid item lg="auto">
				<Typography
					className={clsx(classes.label, labelClassName)}
					variant="h6"
					color={labelColor}
				>
					{t(label, labelOptions)}
				</Typography>
				{labelSubText && (
					<Typography className={classes.labelSubText}>
						{labelSubText}
					</Typography>
				)}
			</Grid>
			<Grid item lg="auto">
				<Typography
					className={valueClassName}
					variant="body1"
					component="div"
					align="right"
				>
					{children}
				</Typography>
			</Grid>
		</Grid>
	);
};

PreviewField.propTypes = {
	labelColor: oneOf([
		'initial',
		'inherit',
		'primary',
		'secondary',
		'textPrimary',
		'textSecondary',
		'error',
	]),
	label: string,
	children: oneOfType([arrayOf(node), node]),
	labelOptions: object,
	labelClassName: string,
	valueClassName: string,
	labelSubText: string,
};
