import React from 'react';
import { string } from 'prop-types';

// Import components
import { DefaultPreviewField, OptionsPreviewField } from 'components/elements';

// Import helpers
import { FIELDS_ACCESSORS } from './helpers';

export const PreviewFieldFactory = (props) => {
	switch (props.accessor) {
		case FIELDS_ACCESSORS.OPTIONS:
			return <OptionsPreviewField {...props} />;

		default:
			return <DefaultPreviewField {...props} />;
	}
};

PreviewFieldFactory.propTypes = {
	accessor: string,
};
