import { t } from 'i18next';

// Import helpers
import { replaceCompanyOfficesUrl } from 'helpers';

export const COLUMN_ACCESSORS = {
	WORKING_HOURS: 'working_hours',
	PHONE_NUMBER: 'phone_number',
	ADDRESS_LINK: 'address_link',
	LINK: 'link',
};

export const COMPANY_INFO_FIELDS = ({
	officeMapLink,
	companyOfficesListLink,
	companyName,
	isAdmin,
}) => [
	{
		accessor: isAdmin ? COLUMN_ACCESSORS.LINK : 'companyName',
		label: 'common.fields.name',
		link: companyOfficesListLink,
		name: companyName,
	},
	{
		accessor: 'officeName',
		label: 'common.fields.office',
	},
	{
		accessor: 'address',
		label: 'common.fields.address',
	},
	{
		accessor: COLUMN_ACCESSORS.PHONE_NUMBER,
		label: 'common.fields.number',
	},
	{
		accessor: COLUMN_ACCESSORS.ADDRESS_LINK,
		label: 'common.fields.location',
		address: t('common.fields.map_link'),
		link: officeMapLink,
	},
	{
		accessor: COLUMN_ACCESSORS.WORKING_HOURS,
		label: 'common.fields.work_hours',
	},
];

export const convertCompanyDetails = (booking) => {
	const { company, office } = booking || {};
	const {
		name: officeName,
		address,
		lat,
		lng,
		contact_phone: contactPhone,
		working_hours: workingHours,
	} = office || {};
	const { name: companyName, uuid: companyUuid } = company || {};

	const officeMapLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

	const companyOfficesListLink = replaceCompanyOfficesUrl(companyUuid);

	return {
		companyName,
		officeName,
		address,
		phoneNumber: contactPhone,
		officeMapLink,
		companyOfficesListLink,
		workingHours,
	};
};

export const convertOldCompanyDetails = (bookingHistory) => {
	const {
		office_working_hours: workingHours,
		company_name: companyName,
		company_uuid: companyUuid,
		office_address: address,
		office_name: officeName,
		office_phone: phoneNumber,
		pick,
	} = bookingHistory || {};

	const { lat, lng } = pick || {};

	const officeMapLink = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;

	const companyOfficesListLink = replaceCompanyOfficesUrl(companyUuid);

	return {
		companyName,
		officeName,
		address,
		phoneNumber,
		officeMapLink,
		companyOfficesListLink,
		workingHours,
	};
};
