import { useState } from 'react';

// Import store
import { updateManagerAssignOffices } from 'store/actions';

// Import utilities
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

export const useActions = ({
	checkedOffices,
	dialogClose,
	isDataExist,
	isLoading,
	userId,
}) => {
	const [isDataSending, setDataSending] = useState(false);
	const { fetchData } = useTableContextProvider();
	const { message } = useMessage();
	const { t } = useTranslations();

	const isDisabled = isDataSending || isLoading || !isDataExist;

	const handleUpdateOffices = async () => {
		setDataSending(true);

		try {
			const offices = checkedOffices.map(({ uuid }) => ({ uuid }));
			await updateManagerAssignOffices({ userId, offices });
			await fetchData();
			dialogClose();
			setDataSending(false);
			message.success(t('common.messages.successfully_done'));
		} catch {
			message.error(t('common.messages.error_message'));
			setDataSending(false);
		}
	};

	return {
		handleUpdateOffices,
		isDisabled,
		isDataSending,
	};
};
