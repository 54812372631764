import React from 'react';

// Import store
import { fetchSubscriptionList } from 'store/actions';

// Import utils and helpers
import { TableRPC } from 'components/utilities';
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BUTTONS } from './helpers';

// Import components
import { TableBodyRow, Export, Filters } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

export const PaymentCollection = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchSubscriptionList}
			title="nav.subscriptions.payment_collection"
			outerToolbarElements={<Export />}
			filtersBar={<Filters />}
			tableNavigation={
				<InnerNavbarElementsButtons
					filterPath="payment_status"
					buttons={BUTTONS}
				/>
			}
		/>
	);
};
