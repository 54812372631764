import React from 'react';
import { object } from 'prop-types';
import { Grid, TableCell } from '@material-ui/core';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { BOOKING_COMPENSATION_STATES } from 'helpers';
import { getChipColor } from './helpers';

// Import components
import { MarkAsCompensatedDialog } from './components';
import { ChipLabel } from 'components/elements';

const { REVIEW, APPROVED } = BOOKING_COMPENSATION_STATES;

export const StatusTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { compensation_status: compensationStatus } = cell.row.original;
	const chipColor = getChipColor(compensationStatus);
	const isDialogVisible = [REVIEW, APPROVED].some(
		(state) => state === compensationStatus
	);

	return (
		<TableCell
			key={cell.column.id}
			style={{ minWidth: '200px' }}
			{...cell.getCellProps()}
		>
			<Grid container alignItems="center" spacing={2}>
				<Grid item xs="auto">
					<ChipLabel
						label={t(`bookings.compensation_states.${compensationStatus}`)}
						color={chipColor}
					/>
				</Grid>
				{isDialogVisible && (
					<Grid item xs="auto">
						<MarkAsCompensatedDialog cell={cell} />
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

StatusTableCell.propTypes = {
	cell: object,
};
