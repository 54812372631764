import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useManufacturers = () => {
	const { data, isError, isFetching } = useQuery(
		QUERY_KEYS.CARS.MAKERS,
		async () => await services.get(ENDPOINTS.CARS.MANUFACTURES),
		{ refetchOnWindowFocus: false, cacheTime: 0 }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};
