import { combineQueryAndPaginationParams } from 'helpers';
import services from 'services/services';
import { fetchReturnBookingsUrl } from 'store/paths';

export const fetchReturnBookings =
	({ options, queryParams, cancelToken }) =>
	async () => {
		try {
			const { pageIndex, pageSize } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			const { data } = await services.get(
				fetchReturnBookingsUrl(params),
				cancelToken
			);

			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};
