/* eslint-disable react/prop-types */
import React from 'react';
import { t } from 'i18next';

// Import components
import {
	AmountTableCell,
	ReceiptDetailsTableCell,
	ReceiptIdTableCell,
} from './components';
import { DateTimeTableCell } from 'components/elements';

export const columns = [
	{
		header: 'common.fields.receipt_id',
		accessor: 'number',
		component: (row) => <ReceiptIdTableCell row={row} />,
	},
	{
		header: 'common.fields.type',
		accessor: 'purpose',
		component: ({ purpose }) =>
			t(`bookings.preview_booking.booking_purpose.${purpose}`),
	},
	{
		header: 'common.fields.amount',
		accessor: 'amount',
		component: (row) => <AmountTableCell row={row} />,
	},
	{
		header: 'common.fields.issue_date',
		accessor: 'issue_date',
		component: ({ created_at: createdAt }) => (
			<DateTimeTableCell date={createdAt} />
		),
	},
	{
		header: 'common.fields.action',
		accessor: 'action',
		component: (row) => <ReceiptDetailsTableCell row={row} />,
	},
];
