import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: '16px',
	},
	name: {
		fontSize: 14,
		fontWeight: 500,
	},
	date: {
		fontSize: 12,
		color: theme.palette.text[3],
	},
	comment: {
		fontSize: 16,
	},
	divider: {
		margin: '16px 0',
	},
}));
