import React, { useState } from 'react';
import { Box, Popper } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { node } from 'prop-types';

// Import components
import { IconButton } from 'components/elements/atoms';

// Import styles
import { useStyles } from './PreviewPopup.styles';

// Import utils
import { useIsRTL } from 'components/utilities';

export const PreviewPopup = ({ notes }) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const [arrowRef, setArrowRef] = useState(null);
	const classes = useStyles();
	const isRTL = useIsRTL();

	const open = Boolean(anchorEl);
	const id = open ? 'simple-popper' : undefined;

	const handleOnClick = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	return (
		<>
			<IconButton onClick={handleOnClick} aria-describedby={id}>
				<VisibilityIcon color="primary" fontSize="small" />
			</IconButton>
			<Popper
				placement="top-start"
				anchorEl={anchorEl}
				keepMounted
				open={open}
				id={id}
				modifiers={{
					flip: {
						enabled: true,
					},
					offset: {
						enabled: true,
						offset: isRTL ? '19, 16' : '-19, 16',
					},
					arrow: {
						enabled: true,
						element: arrowRef,
					},
				}}
			>
				<Box className={classes.popperContent}>{notes}</Box>
				<Box component="span" className={classes.arrow} ref={setArrowRef} />
			</Popper>
		</>
	);
};

PreviewPopup.propTypes = {
	notes: node,
};
