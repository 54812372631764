import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useOfficeCollection = ({ bookingUuid, body = {} }) => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.OFFICES.COLLECTION(JSON.stringify(body)),
		async () =>
			await services.post(
				ENDPOINTS.BOOKINGS.BOOKING_OFFICES_COLLECTION({ bookingUuid }),
				body
			),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useOfficeCollection;
