import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DoubleCheckMarkCircleIcon = (props) => {
	return (
		<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M11.3307 7L8.66406 9.66667"
				stroke="currentColor"
				fill="none"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.9974 9.66537L4.66406 8.33203"
				stroke="currentColor"
				fill="none"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M9.33333 6.33203L6 9.66536"
				stroke="currentColor"
				fill="none"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.2428 3.75735C13.9588 5.47334 14.4721 8.05405 13.5435 10.2961C12.6148 12.5381 10.427 14 8.00018 14C5.5734 14 3.38558 12.5381 2.4569 10.2961C1.52821 8.05405 2.04155 5.47334 3.75754 3.75735C6.10069 1.41422 9.89966 1.41422 12.2428 3.75735"
				stroke="currentColor"
				fill="none"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</SvgIcon>
	);
};
