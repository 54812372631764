import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ThunderIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.6829 9.64574L10.6737 16.8816C10.2146 17.5441 9.17541 17.2199 9.17541 16.4132V11.6441H4.98791C4.32457 11.6441 3.93374 10.8991 4.31207 10.3532L9.32124 3.11741C9.78041 2.45491 10.8196 2.77908 10.8196 3.58574V8.35491H15.0071C15.6696 8.35491 16.0604 9.09991 15.6829 9.64574Z"
			stroke="currentColor"
			strokeWidth="1.5383"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
