import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

// Import utilities and helpers
import { useTableContextProvider } from 'components/context';
import { getStatus, getValidationState } from './helpers';

// Import store
import { isValidationTableChangedSelector } from 'store/selectors';
import {
	setValidationTableChangedState,
	setBookingPreviewItem,
	resetBookingPreviewItem,
} from 'store/actions';

export const useValidationActionModal = ({ cell, isHovered }) => {
	const { fetchData } = useTableContextProvider();
	const [isOpen, setIsOpen] = useState(false);
	const { pathname } = useLocation();
	const dispatch = useDispatch();

	const booking = cell.row.original;

	const {
		cancel_reason_status: cancellationStatus,
		compensation_status: compensationStatus,
	} = booking || {};

	const isChanged = useSelector(isValidationTableChangedSelector);

	const handleOnOpenModal = () => {
		setBookingPreviewItem({ booking, dispatch });
		setValidationTableChangedState({ isTableChanged: false, dispatch });
		setIsOpen(true);
	};

	const handleOnCloseModal = () => {
		setIsOpen(false);
		resetBookingPreviewItem({ dispatch });
		isChanged && fetchData();
	};

	const status = getStatus({
		pathname,
		cancellationStatus,
		compensationStatus,
	});

	const isValidateButtonVisible =
		!getValidationState({ pathname, status }) && isHovered;

	return {
		handleOpenModal: handleOnOpenModal,
		handleCloseModal: handleOnCloseModal,
		compensationStatus,
		isValidateButtonVisible,
		isOpen,
		status,
	};
};
