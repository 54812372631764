import React from 'react';
import { object } from 'prop-types';
import { Box } from '@material-ui/core';

// Import components
import { Typography } from 'components/elements';

// Import styles
import { useStyles } from './CarPriceSection.styles';

// Import utilities
import { useTranslations } from 'components/utilities';

export const CarPriceSection = ({ car }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	return (
		<Box className={classes.container}>
			<Box>
				<Typography className={classes.price}>
					{car.discount_price}{' '}
					<Typography component="span">
						{t('common.fields.sr_daily', { value: '' })}
					</Typography>
				</Typography>
				<Typography className={classes.oldPrice}>
					{' '}
					{t('common.fields.sr', { value: car.price })}
				</Typography>
			</Box>
			<Box className={classes.imgContainer}>
				<Box component="img" src={car.image} className={classes.img} />
			</Box>
		</Box>
	);
};

CarPriceSection.propTypes = {
	car: object.isRequired,
};
