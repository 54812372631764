import React from 'react';
import PropTypes from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';

// Import components
import { ChipLabel } from 'components/elements';

// Import utils
import { useUrgentStatusCell } from './useUrgentStatusCell';

export const UrgentStatusTableCell = ({ cell }) => {
	const { chipColor, elapsedTime, chipLabel } = useUrgentStatusCell({
		cell,
	});

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container alignItems="center" spacing={1}>
				<Grid item xs="auto">
					<ChipLabel label={chipLabel} color={chipColor} />
				</Grid>

				<Grid item xs="auto">
					<Typography>{elapsedTime}</Typography>
				</Grid>
			</Grid>
		</TableCell>
	);
};

UrgentStatusTableCell.propTypes = {
	cell: PropTypes.object.isRequired,
};
