import React from 'react';
import { Form } from 'react-final-form';
import { Dialog, DialogContent } from '@material-ui/core';

// Import components
import { ClosableDialogTitle, SuccessDialog } from 'components/elements';
import { FormContent } from './components';

// Import utils and helpers
import { useUpdateReasonDialog } from './useUpdateReasonDialog';
import { INITIAL_VALUES } from './helpers';
import { useTranslations } from 'components/utilities';

// Import context
import { useUrgentFormsProvider } from '../../../../context';

export const UpdateReasonDialog = () => {
	const { t } = useTranslations();

	const {
		isUpdateReasonOpen,
		toggleUpdateReasonOpen,
		updateReasonTitle,
		handleCloseSuccess,
	} = useUrgentFormsProvider();

	const {
		handleSubmit,
		cancelReasons,
		isReasonsLoading,
		isLoading,
		isError,
		error,
		getReasonName,
		isSuccess,
		toggleSuccess,
	} = useUpdateReasonDialog(toggleUpdateReasonOpen);

	return (
		<>
			<Dialog
				open={isUpdateReasonOpen}
				onClose={toggleUpdateReasonOpen}
				maxWidth="sm"
				fullWidth
			>
				<ClosableDialogTitle
					title={updateReasonTitle}
					onClose={toggleUpdateReasonOpen}
				/>
				<DialogContent>
					<Form
						initialValues={INITIAL_VALUES}
						onSubmit={handleSubmit}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent
									isReasonsLoading={isReasonsLoading}
									cancelReasons={cancelReasons}
									isLoading={isLoading}
									isError={isError}
									error={error}
									getReasonName={getReasonName}
								/>
							</form>
						)}
					/>
				</DialogContent>
			</Dialog>

			<SuccessDialog
				isOpen={isSuccess}
				toggle={toggleSuccess}
				handleOk={() => handleCloseSuccess(toggleSuccess)}
				title={t('bookings.validate_cancellation.cancellation_validated')}
				description={t(
					'bookings.validate_cancellation.cancellation_validated_success_message'
				)}
			/>
		</>
	);
};
