import { useState } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';

// Import utils and helpers
import { useToggle } from 'components/utilities';
import { BOOKING_CANCELLATION_STATES } from 'helpers';

// Import store
import { changeBookingCancelReasonStatus } from 'store/actions';
import { previewBookingSelector } from 'store/selectors';

export const useApproveRejectionDialog = (toggleApproveRejection) => {
	const { APPROVED } = BOOKING_CANCELLATION_STATES;

	const { uuid } = useSelector(previewBookingSelector);

	const { on: isSuccess, toggle: toggleSuccess } = useToggle();

	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const handleApproveRejection = async () => {
		setIsLoading(true);

		try {
			await changeBookingCancelReasonStatus({
				uuid,
				status: APPROVED,
				cancelToken,
				isCancellation: true,
			});

			toggleApproveRejection();
			toggleSuccess();
		} catch (e) {
			setError(e);
			setIsError(true);
		}
		setIsLoading(false);
	};

	return {
		isSuccess,
		toggleSuccess,
		isError,
		error,
		isLoading,
		handleApproveRejection,
	};
};
