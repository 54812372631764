import { makeStyles } from '@material-ui/styles';

// Import helpers
import { CHIP_COLORS } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	chip: {
		width: ({ fullWidth }) => (fullWidth ? '100%' : 'auto'),
		borderRadius: '100px',
		padding: '0 12px',
		fontWeight: 600,
		fontSize: 12,
	},
	[CHIP_COLORS.SUCCESS]: {
		backgroundColor: theme.palette.success.lighter,
		color: theme.palette.success.medium,
	},
	[CHIP_COLORS.ERROR]: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},
	[CHIP_COLORS.WARNING]: {
		backgroundColor: theme.palette.warning.medium,
		color: theme.palette.warning.mid,
	},
	[CHIP_COLORS.INFO]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},
	[CHIP_COLORS.GRAY]: {
		backgroundColor: theme.palette.gray.light,
		color: theme.palette.gray.medium,
	},
}));
