import React from 'react';
import { fetchAccountingValidationBookings } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { ExportFilters } from 'components/elements';
import { FilterFields } from '../../components';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';

export const Export = () => (
	<ExportFilters
		fetchDataTableAction={fetchAccountingValidationBookings}
		filterFields={() => <FilterFields />}
		initialValues={initialValues}
		formatValues={formatValues}
		columns={columns}
	/>
);
