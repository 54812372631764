import React from 'react';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';

// Import helpers and utils
import { COLUMNS_ACCESSORS } from 'views/Customers/CustomersList/columns';
import { replaceCustomerPreviewUrl } from 'helpers';
import { useCell } from './useCell';
import { useTranslations } from 'components/utilities';

// Import components
import {
	PreviewTableCell,
	LinkTableCell,
	ActiveTableCell,
	NewActionsMenuTableCell,
} from 'components/elements';
import { AnalyzeDriverLicenseTableCell, NoteTableCell } from '../../components';
import { CreatedAtTableCell } from 'views/Bookings/components';

// Import store
import { updateApproveOrganization } from 'store/actions';

const {
	NAME,
	APPROVE,
	NOTE,
	ANALYZE_DRIVER_LICENSE,
	PREVIEW,
	ACTIONS,
	CREATED_AT,
	ORGANIZATION_NAME,
	GENDER,
} = COLUMNS_ACCESSORS;

export const Cell = ({ cell }) => {
	const { uuid, name } = cell.row.original;
	const { actionMenuFields } = useCell(cell);
	const {
		i18n: { language: lang },
	} = useTranslations();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case NOTE:
				return <NoteTableCell key={cell.column.id} cell={cell} />;

			case PREVIEW:
				return (
					<PreviewTableCell
						key={cell.column.id}
						cell={cell}
						path={replaceCustomerPreviewUrl(uuid)}
					/>
				);

			case ANALYZE_DRIVER_LICENSE:
				return (
					<AnalyzeDriverLicenseTableCell key={cell.column.id} cell={cell} />
				);

			case NAME:
				return (
					<LinkTableCell
						key={cell.column.id}
						cell={cell}
						to={replaceCustomerPreviewUrl(uuid)}
					>
						{name || ''}
					</LinkTableCell>
				);

			case APPROVE:
				return (
					<ActiveTableCell
						key={cell.column.id}
						activeAction={updateApproveOrganization}
						cell={cell}
						activeText="table.actions.approved"
						inactiveText="table.actions.approve"
						activatedAlertMessage="table.actions.organization_approved"
						deactivatedAlertMessage="table.actions.organization_disapproved"
						isActive={cell.row.values.organization_verified}
					/>
				);

			case CREATED_AT:
				return <CreatedAtTableCell key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ORGANIZATION_NAME:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.row.original.organization_name?.[lang]}
					</TableCell>
				);

			case GENDER:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.row.original.gender_name?.[lang]}
					</TableCell>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
