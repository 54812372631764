import { addDays } from 'date-fns';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const GET_FIELD = ({ t, dropDate }) => ({
	type: FIELD_TYPES.DATE,
	name: 'date',
	label: t('return_page.new_drop_date'),
	placeholder: t('common.buttons.select'),
	minDate: addDays(new Date(dropDate), 1),
});
