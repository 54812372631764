import React from 'react';
import { bool, node, object, oneOfType, string } from 'prop-types';
import { Grid, TableCell } from '@material-ui/core';

// Import components
import { ExtraActions, PreviewPopup } from './components';
import {
	BookingHistory,
	BookingHistoryProvider,
	TransactionHistory,
	TransactionHistoryProvider,
} from 'components/elements/organisms/Booking';
import {
	SubscriptionHistory,
	EditCompensationModal,
} from 'components/elements';

// Import utils
import { useActionsMenuTableCell } from './useActionsMenuTableCell';
import { BookingStatusActionsProvider } from 'components/context';

export const ActionsMenuTableCell = (props) => {
	const {
		cell,
		minWidth = 'auto',
		bookingUuid = '',
		extraActions = false,
		isHovered = false,
		customActions = null,
	} = props;

	const {
		handleOnCompensationClose,
		isCompensationDialogOpen,
		toggleCompensationDialog,
		isBookingHistoryOpen,
		toggleBookingHistory,
		isTransactionsHistoryOpen,
		toggleTransactionsHistory,
		isSubscriptionHistoryOpen,
		toggleSubscriptionsHistory,
		fetchData,
		rowUuid,
		uuid,
		booking,
		previewPopupProps,
		extraActionsProps,
	} = useActionsMenuTableCell({
		cell,
		bookingUuid,
		props,
		extraActions,
	});

	return (
		<TableCell
			key={cell.column.id}
			width={150}
			style={{ minWidth }}
			{...cell.getCellProps()}
		>
			<Grid
				container
				alignItems="center"
				justifyContent="flex-end"
				wrap="nowrap"
				spacing={1}
			>
				<Grid item>
					{extraActions && isHovered && (
						<ExtraActions {...extraActionsProps} cell={cell} />
					)}
				</Grid>
				<Grid item>
					<BookingStatusActionsProvider onChange={fetchData} booking={booking}>
						<PreviewPopup
							customActions={customActions}
							{...previewPopupProps}
						/>
					</BookingStatusActionsProvider>
				</Grid>
			</Grid>

			{isTransactionsHistoryOpen && (
				<TransactionHistoryProvider bookingId={rowUuid}>
					<TransactionHistory
						open={isTransactionsHistoryOpen}
						onClose={toggleTransactionsHistory}
					/>
				</TransactionHistoryProvider>
			)}

			{isBookingHistoryOpen && (
				<BookingHistoryProvider bookingId={rowUuid}>
					<BookingHistory
						open={isBookingHistoryOpen}
						onClose={toggleBookingHistory}
					/>
				</BookingHistoryProvider>
			)}

			{isSubscriptionHistoryOpen && (
				<SubscriptionHistory
					uuid={uuid}
					on={isSubscriptionHistoryOpen}
					onClose={toggleSubscriptionsHistory}
				/>
			)}

			{isCompensationDialogOpen && (
				<EditCompensationModal
					open={isCompensationDialogOpen}
					onClose={toggleCompensationDialog}
					onInvalid={fetchData}
					onCustomSubmit={handleOnCompensationClose}
				/>
			)}
		</TableCell>
	);
};

ActionsMenuTableCell.propTypes = {
	cell: object.isRequired,
	minWidth: string,
	bookingUuid: string,
	extraActions: oneOfType([object, bool]),
	isHovered: bool,
	customActions: node,
};
