import { useForm } from 'react-final-form';

// Import utilitis and helpers
import { useToggle, useMapLocation } from 'components/utilities';
import { resetCarInfo } from '../../../../helpers';

export const useCollapsableMap = ({ section }) => {
	const { on: mapVisible, toggle } = useToggle();
	const { change } = useForm();
	const {
		t,
		fieldDisabled,
		markerLocation,
		setAutocompleteValue,
		onChange,
		onDragEnd,
		setMarkerLocation,
	} = useMapLocation({ section });
	const hideText = mapVisible
		? 'bookings.create_booking.hide_map'
		: 'bookings.create_booking.show_map';

	const getAddressFromLatLng = ({ location, section }) => {
		const geocoder = new window.google.maps.Geocoder();
		geocoder.geocode({ location }, (results, status) => {
			if (status === 'OK' && results[0]) {
				change(`${section}.address`, results[0].formatted_address);
			}
		});
	};

	const handleSetCurrentLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition((pos) => {
				change(`${section}.lng`, pos.coords.longitude);
				change(`${section}.lat`, pos.coords.latitude);
				resetCarInfo(change);
				setMarkerLocation({
					lat: pos.coords.latitude,
					lng: pos.coords.longitude,
				});
				getAddressFromLatLng({
					location: {
						lat: pos.coords.latitude,
						lng: pos.coords.longitude,
					},
					section,
				});
			});
		}
	};

	const onMapChange = () => {
		onChange();
		resetCarInfo(change);
	};

	return {
		t,
		fieldDisabled,
		markerLocation,
		setAutocompleteValue,
		onChange,
		onDragEnd,
		mapVisible,
		toggle,
		hideText,
		handleSetCurrentLocation,
		onMapChange,
	};
};
