import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';
import { useEffect } from 'react';

// Import utils
import { useProfile } from 'components/utilities';
import { LEASING_MODEL_PERIODS_TEXT } from 'helpers';

export const useLeasingRateModel = () => {
	const { values } = useFormState();
	const { change } = useForm();
	const { isAdmin } = useProfile();

	const {
		is_available_to_sell_after_leasing_period,
		carInfo,
		is_leasing: isLeasing,
	} = values || {};

	const isLeasingSellActive = is_available_to_sell_after_leasing_period;

	const leasingCheckboxName = 'is_available_to_sell_after_leasing_period';
	const leasingSellPriceName = 'leasing.leasing_sell_price';

	const isSubscriptionModalEnabled = carInfo?.subscription?.is_enabled;
	const subscriptionOneMonthPrice =
		carInfo?.subscription?.prices?.one?.nett_price;
	const subscriptionTwelveMonthPrice =
		carInfo?.subscription?.prices?.twelve?.nett_price;

	const isOneMonthDisabled =
		!isAdmin ||
		(carInfo?.subscription?.prices?.one?.nett_price &&
			isSubscriptionModalEnabled);

	const isRateDisabled = (period) =>
		!isAdmin ||
		(period === 'm12' &&
			carInfo?.subscription?.prices?.twelve?.nett_price &&
			isSubscriptionModalEnabled);

	const getCards = () => {
		const filteredPrices = _.omit(LEASING_MODEL_PERIODS_TEXT, 1);

		return Object.values(filteredPrices).map((period) => ({
			label: `cars.form.leasing.${period}_months_heading`,
			name: `leasing.base_rent.months.${period}`,
			disabled: !!isRateDisabled(period) || !isLeasing,
			required: true,
		}));
	};

	const leasingRateCards = getCards();

	const depositCard = {
		label: `leasing_cars.form.rates_model.security_deposit`,
		name: `leasing.security_deposit_price`,
		disabled: !isAdmin || !isLeasing,
		required: false,
	};

	const handleOnActiveCheckboxChange = (_, checked) => {
		change(leasingCheckboxName, checked);
		!checked && change(leasingSellPriceName, '');
	};

	useEffect(() => {
		if (subscriptionOneMonthPrice) {
			change('leasing.base_rent.months.m1', subscriptionOneMonthPrice);
		} else {
			change('leasing.base_rent.months.m1', '');
		}
		if (subscriptionTwelveMonthPrice) {
			change('leasing.base_rent.months.m12', subscriptionTwelveMonthPrice);
		} else {
			change('leasing.base_rent.months.m12', '');
		}
		// eslint-disable-next-line
	}, [
		subscriptionOneMonthPrice,
		subscriptionTwelveMonthPrice,
		isSubscriptionModalEnabled,
	]);

	return {
		onActiveCheckboxChange: handleOnActiveCheckboxChange,
		leasingCheckboxName,
		leasingSellPriceName,
		leasingRateCards,
		depositCard,
		isLeasingSellActive,
		isAdmin,
		isOneMonthDisabled,
		isLeasing,
	};
};
