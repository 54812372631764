import { bool, number, oneOfType, string } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import utilities
import { useCommonFields, useTranslations } from 'components/utilities';

// Import queries
import { useCompanies } from 'queries';

// Import helpers
import { sortByName } from '../helpers';
import { FIELD_TYPES } from 'helpers';

export const MultiCompaniesFilter = ({
	label = '',
	name,
	xs = 3,
	md = 3,
	isActive = false,
	required = false,
	smallFieldStyle = false,
	emptyLabel = 'common.company',
}) => {
	const { values } = useFormState();

	const { renderFields } = useCommonFields();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data } = useCompanies(isActive);

	if (!data) {
		return null;
	}

	const sortedCompanies = sortByName(data, language) || [];

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[language];

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name,
		label,
		options: sortedCompanies,
		showAllOption: true,
		multiSelectLabelExtractor,
		currentMultiSelectValues: values?.[name],
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[language],
		required,
		md,
		xs,
		smallFieldStyle,
		emptyLabel: t(emptyLabel),
		searchExtractor: 'children[1]',
	});
};

MultiCompaniesFilter.propTypes = {
	name: string.isRequired,
	label: string,
	showFetching: bool,
	isActive: bool,
	required: bool,
	md: oneOfType([string, number]),
	xs: oneOfType([string, number]),
	smallFieldStyle: oneOfType([bool, string]),
	emptyLabel: string,
};
