import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const popoverStyle = {
	backgroundColor: palette.white,
	borderRadius: '12px',
	padding: 0,
	height: 'auto',
};

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '16px',
	},
	popoverContent: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
		minHeight: '82px',
		gap: '8px',
		padding: '24px',
		minWidth: '250px',
	},
	dotsButton: {
		backgroundColor: theme.palette.white,
		width: '28px',
		height: '28px',
		border: '1px solid',
		borderColor: theme.palette.background[3],
	},
	buttonIcon: {
		width: '12px',
		height: '12px',
		color: theme.palette.background[3],
	},
	mainIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
	},
	button: {
		fontSize: '13px',
		fontWeight: '600',
		justifyContent: 'flex-start',
		color: theme.palette.text[2],
	},
	divider: {
		backgroundColor: theme.palette.background[5],
		width: '100%',
	},
	confirmRejectionButton: {
		color: theme.palette.error.medium,
		justifyContent: 'flex-start',
	},
	confirmRejectionIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.error.medium,
	},
	reAcceptButton: {
		color: theme.palette.primary.main,
		justifyContent: 'flex-start',
	},
	reAcceptIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
	},
	icon: {
		width: '24px',
		height: '24px',
		color: theme.palette.text[2],
	},
	dangerIcon: {
		color: theme.palette.error.medium,
	},
	activeLabel: {
		color: theme.palette.text[2],
		fontWeight: 600,
		fontSize: '13px',
	},
	dangerButton: {
		justifyContent: 'flex-start',
		color: theme.palette.error.medium,
	},
	editButton: {
		justifyContent: 'flex-start',
	},
}));
