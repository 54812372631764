import { useSelector } from 'react-redux';

// Import utilities
import { useMessage, useTranslations } from 'components/utilities';
import { BOOKING_STATES } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useEditForm = () => {
	const { t } = useTranslations();

	const { message } = useMessage();

	const { state } = useSelector(previewBookingSelector);

	const isRejected = state === BOOKING_STATES.REJECTED;

	const errorCallback = () => {
		message.error(t('common.messages.error_message'));
	};

	const links = [
		{
			label: t('common.fields.date_time'),
			path: '#date-time',
		},
		{
			label: t('bookings.edit_booking.alternative_cars'),
			path: '#alternative-cars',
		},
		{
			label: t('bookings.edit_booking.additional_services'),
			path: '#additional-service',
		},
	];

	return {
		errorCallback,
		links,
		isRejected,
	};
};
