import i18next from 'i18next';

export const columns = [
	{
		Header: 'common.cars.headers.maker',
		accessor: `maker_name.${i18next.language}`,
	},
	{
		Header: 'common.cars.headers.model',
		accessor: `model_name.${i18next.language}`,
	},
	{
		Header: 'common.cars.headers.year',
		accessor: 'year',
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.availability',
		accessor: 'plate_availability',
		disableSortBy: false,
	},
];
