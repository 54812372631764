import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	card: {
		position: 'relative',
		boxShadow: 'none',
		border: `1px solid ${theme.palette.background[5]}`,
		padding: '24px',
		borderRadius: '16px',
	},
	rejectedBookingCard: {
		overflowY: 'scroll',
		maxHeight: '700px',
	},
	transferBookingStyle: {
		padding: '14px 16px',
		fontSize: 14,
		color: theme.palette.gray.label,
		fontWeight: 600,
		marginBottom: theme.spacing(5),
	},
	activeTitle: {
		color: theme.palette.primary.main,
		backgroundColor: theme.palette.primary.medium,
		borderRadius: '12px',
	},
	headerTabsContainer: {
		position: 'sticky',
		top: '-24px',
		left: 0,
		backgroundColor: theme.palette.white,
		borderRadius: '16px 16px 0 0',
		zIndex: 10,
		padding: '10px 0 0 0',
	},
}));
