import { useState } from 'react';

export const usePaginatedCars = (cars) => {
	const [currentPage, setCurrentPage] = useState(1);

	const carsPerPage = 6;

	const CurrentPageCars = cars.slice(
		(currentPage - 1) * carsPerPage,
		currentPage * carsPerPage
	);

	const numberOfPages = Math.ceil(cars.length / carsPerPage);

	// eslint-disable-next-line
	const onPaginationChange = (_e, val) => setCurrentPage(Number(val));

	return { currentPage, CurrentPageCars, numberOfPages, onPaginationChange };
};
