import React from 'react';
import { FormControlLabel, Checkbox, Box } from '@material-ui/core';
import { object, func, array } from 'prop-types';

// Import utils
import { useOfficeItem } from './useOfficeItem';

// Import components
import { Grid } from 'components/elements';

// import styles
import { useStyles } from '../styles';

export const OfficeItem = (props) => {
	const { isChecked, officeName, handleCheckboxChange } = useOfficeItem(props);
	const classes = useStyles({ isChecked });

	return (
		<Grid item xs={12} height="max-content">
			<Box className={classes.checkboxWrapper} boxShadow={1}>
				<FormControlLabel
					className={classes.formControlLabel}
					control={
						<Checkbox
							color="primary"
							checked={isChecked}
							name={officeName}
							onChange={handleCheckboxChange}
						/>
					}
					label={officeName}
				/>
			</Box>
		</Grid>
	);
};

OfficeItem.propTypes = {
	office: object,
	checkedOffices: array,
	setCheckedOffices: func,
};
