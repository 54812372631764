import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { updateSupplierComments } from 'store/actions';

export const useAddComment = ({ type }) => {
	const { uuid } = useSelector(previewBookingSelector);
	const [isLoading, setIsLoading] = useState(false);
	const dispatch = useDispatch();

	const handleOnSubmit = async (values) => {
		setIsLoading(true);

		const options = {
			id: uuid,
			content: values.content,
			discuss_type: type,
		};
		await updateSupplierComments(options)(dispatch);
		setIsLoading(false);
	};

	const onSubmit = ({ event, handleSubmit, form }) => {
		const promise = handleSubmit(event);

		promise &&
			promise.then(() => {
				form.reset();
			});

		return promise;
	};

	return {
		handleOnSubmit,
		onSubmit,
		isLoading,
	};
};
