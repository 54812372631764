import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCorporationCustomer = ({ corporateUuid, customerUuid }) => {
	const { data, isFetching, isError, refetch } = useQuery(
		QUERY_KEYS.B2B.CORPORATION_CUSTOMERS(`${corporateUuid}, ${customerUuid}`),
		async () =>
			await services.get(
				ENDPOINTS.B2B.CORPORATION_CUSTOMER({ corporateUuid, customerUuid })
			),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError, refetch };
};
