import React from 'react';
import { object } from 'prop-types';

// Import components
import { PreviewField } from 'components/elements';

export const InvoiceOptionsPreviewField = ({ customData }) => {
	const { options } = customData || {};

	if (!options) return null;

	return options.map(({ type, amount_nett: price }) => (
		<PreviewField key={type} label={`bookings.options.${type}`}>
			{price}
		</PreviewField>
	));
};

InvoiceOptionsPreviewField.propTypes = {
	customData: object,
};
