import React from 'react';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components';

// Import utils and helpers
import { getExtensionCustomData, EXTENSION_FIELDS } from './helpers';
import { useTabsProvider } from 'components/context';

export const Extension = () => {
	const { data: booking } = useTabsProvider();

	const customData = getExtensionCustomData(booking);

	return <PreviewFields fields={EXTENSION_FIELDS} customData={customData} />;
};
