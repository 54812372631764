import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useBookingCounters = (reference) => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.BOOKINGS.COUNT(reference),
		async () =>
			await services.get(
				ENDPOINTS.BOOKINGS.BOOKINGS_COUNTER_REFERENCE({ reference })
			),
		{ refetchOnWindowFocus: false, staleTime: 60 * 1000 }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching };
};

export default useBookingCounters;
