import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		borderRadius: 0,
		padding: 0,
		backgroundColor: theme.palette.background[2],

		'& .MuiTableCell-body': {
			border: 0,
		},

		'& .MuiTableBody-root': {
			transform: 'translateY(-14px)',
		},

		'& .MuiTableCell-root': {
			border: 0,
		},

		'& .MuiTableHead-root': {
			visibility: 'hidden',
		},
	},
	headerTableCell: {
		'&.MuiTableCell-root': {
			maxWidth: 'unset',
		},
	},
	content: {
		position: 'relative',
		padding: 0,
	},
	nameContainer: {
		display: 'flex',
		alignItems: 'center',
	},
	avatar: {
		marginRight: theme.spacing(2),
	},
	loader: {
		position: 'absolute',
		top: 0,
		left: 0,
		height: '100%',
		width: '100%',
		zIndex: 1,
		background: alpha(theme.palette.background.default, 0.5),
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
	},
	noData: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		fontSize: '20px',
	},
	alignRight: {
		textAlign: 'right',
	},
	alignLeft: {
		textAlign: 'left',
	},
}));
