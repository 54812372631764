import React from 'react';
import { Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import components
import { BookingExtendedChip } from 'components/elements';
import { BookingStatusChip } from './components';

export const BookingStatus = ({ isExtended = false }) => {
	return (
		<Grid container spacing={2}>
			{isExtended && (
				<Grid item>
					<BookingExtendedChip />
				</Grid>
			)}
			<Grid item>
				<BookingStatusChip />
			</Grid>
		</Grid>
	);
};

BookingStatus.propTypes = {
	isExtended: bool,
};
