import { format } from 'date-fns';
import { string } from 'prop-types';

// Import helpers
import { DEFAULT_DATE_TIME_COMMA_FORMAT_12_HOUR } from 'helpers';

export const DateTimeTableCell = ({ date, formula = null }) => {
	const formattedDate = format(
		new Date(date),
		formula || DEFAULT_DATE_TIME_COMMA_FORMAT_12_HOUR
	);

	return formattedDate;
};

DateTimeTableCell.propTypes = {
	date: string.isRequired,
	formula: string,
};
