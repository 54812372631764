import React from 'react';
import { string, func, object } from 'prop-types';
import clsx from 'clsx';
import { Paper, Input, Button } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Import icons
import { SearchIcon } from 'assets/icons';

// Import styles
import { useStyles } from './SearchInput.styles';

export const SearchInput = ({
	className,
	style,
	setGlobalFilter,
	clearGlobalFilters,
	globalFilter,
	...rest
}) => {
	const classes = useStyles();

	return (
		<Paper {...rest} className={clsx(classes.root, className)} style={style}>
			<Input
				{...rest}
				value={globalFilter || ''}
				className={classes.input}
				disableUnderline
				onChange={setGlobalFilter}
				startAdornment={<SearchIcon className={classes.icon} />}
				endAdornment={
					globalFilter && (
						<Button>
							<CloseIcon
								onClick={clearGlobalFilters}
								className={classes.iconClose}
							/>
						</Button>
					)
				}
			/>
		</Paper>
	);
};

SearchInput.propTypes = {
	className: string,
	onChange: func,
	style: object,
	setGlobalFilter: func.isRequired,
	clearGlobalFilters: func.isRequired,
	globalFilter: string.isRequired,
};
