import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import assets
import {
	FlagIcon,
	EditPenOutlinedIcon,
	StarOutlinedIcon,
	DownloadDocumentIcon,
	WalletIcon,
} from 'assets/icons';

// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import {
	BOOKING_DETAILS_TABS,
	getTabIndexBookingDetails,
	replaceEditBookingUrl,
} from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import context
import { useTabsProvider } from 'components/context';

export const useBookingActions = () => {
	const { t } = useTranslations();
	const { push } = useHistory();
	const { uuid: bookingUuid } = useSelector(previewBookingSelector);

	const { isAdmin } = useProfile();

	const [moreMenuEl, setMoreMenuEl] = useState(null);

	const { setCurrentTab } = useTabsProvider();

	const goToEditBookingPage = () => push(replaceEditBookingUrl(bookingUuid));

	const handleCloseMoreMenu = () => {
		setMoreMenuEl(null);
	};

	const { URGENT, INVOICE, RATINGS, COMPENSATION } = BOOKING_DETAILS_TABS;

	const actions = [
		{
			label: t('common.buttons.urgent'),
			startIcon: <FlagIcon />,
			action: () => setCurrentTab(getTabIndexBookingDetails(URGENT)),
		},
		{
			label: t('common.buttons.edit'),
			startIcon: <EditPenOutlinedIcon />,
			action: goToEditBookingPage,
			isHidden: !isAdmin,
		},
		{
			label: t('common.buttons.more'),
			endIcon: moreMenuEl ? <ExpandLessIcon /> : <ExpandMoreIcon />,
			action: (event) => setMoreMenuEl(event.currentTarget),
			'aria-controls': 'more-menu',
			'aria-haspopup': 'true',
		},
	];

	const selectTabAndCloseMenu = (index) => {
		setCurrentTab(index);
		handleCloseMoreMenu();
	};

	const moreOptions = [
		{
			label: t('common.buttons.add_rating'),
			action: () => selectTabAndCloseMenu(getTabIndexBookingDetails(RATINGS)),
			icon: <StarOutlinedIcon />,
		},
		{
			label: t('common.buttons.add_compensation'),
			action: () =>
				selectTabAndCloseMenu(getTabIndexBookingDetails(COMPENSATION)),
			icon: <WalletIcon />,
			isHidden: !isAdmin,
		},
		{
			label: t('common.buttons.download_invoice'),
			action: () => selectTabAndCloseMenu(getTabIndexBookingDetails(INVOICE)),
			icon: <DownloadDocumentIcon />,
		},
	];

	return {
		actions,
		handleCloseMoreMenu,
		moreOptions,
		moreMenuEl,
	};
};
