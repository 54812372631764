import React, { createContext } from 'react';
import { node } from 'prop-types';
import { useParams } from 'react-router-dom';

// Import utils
import { useProfile } from 'components/utilities';

// Import queries and selector
import { useEditBookingHistory } from 'queries';

export const CarAndCompanyContext = createContext(null);

export const CarAndCompanyProvider = ({ children }) => {
	const { id } = useParams();
	const { isAdmin } = useProfile();

	const { data, isFetching } = useEditBookingHistory({
		uuid: id,
		enabled: isAdmin,
	});

	const isHistory = data?.length;

	const editBookingHistory = isHistory ? data[0] : null;

	return (
		<CarAndCompanyContext.Provider
			value={{ isHistory, editBookingHistory, isFetching }}
		>
			{children}
		</CarAndCompanyContext.Provider>
	);
};

CarAndCompanyProvider.propTypes = {
	children: node,
};
