import axios from 'axios';

// Import store
import { markBookingAsCompensated } from 'store/actions';

// Import utils and helpers
import { BOOKING_COMPENSATION_TYPES, convertApiFormErrors } from 'helpers';
import { useTableContextProvider } from 'components/context';
import { useToggle } from 'components/utilities';

export const useMarkAsCompensatedDialog = (cell) => {
	const { fetchData } = useTableContextProvider();
	const { on: isOpen, toggle } = useToggle();

	const cancelToken = axios.CancelToken.source().token;
	const booking = cell.row.original;

	const { operation_topics: topics, compensation } = booking;

	const isCancellation = topics.includes(
		BOOKING_COMPENSATION_TYPES.CANCELLATION
	);

	const onSubmit = async (values) => {
		try {
			await markBookingAsCompensated({
				booking,
				cancelToken,
				values,
				isCancellation,
			});
			toggle();
			fetchData();
		} catch (error) {
			const errors = convertApiFormErrors(error.data?.errors);
			return errors;
		}
	};

	return { onSubmit, compensation, isOpen, toggle };
};
