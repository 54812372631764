import React from 'react';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-final-form';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { DialogContent } from '@material-ui/core';

// Import utils
import { useToggle, useTranslations } from 'components/utilities';
import { replaceAddCampaignUrl } from 'helpers';

// Import components
import { Button, ClosableDialogTitle, Dialog } from 'components/elements';
import { FormContent } from './components';

export const AddCampaignDialog = () => {
	const { on: isOpen, toggle } = useToggle();
	const { t } = useTranslations();
	const { push } = useHistory();

	const addCampaignTrans = t('common.buttons.add_campaign');

	const handleOnSubmit = (values) =>
		push(replaceAddCampaignUrl(values.campaign_type));

	return (
		<>
			<Button onClick={toggle} icon={AddCircleIcon} text={addCampaignTrans} />
			<Dialog
				onClose={toggle}
				open={isOpen}
				maxWidth="md"
				keepMounted
				fullWidth
			>
				<ClosableDialogTitle onClose={toggle} title={addCampaignTrans} />
				<DialogContent>
					<Form
						onSubmit={handleOnSubmit}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent />
							</form>
						)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};
