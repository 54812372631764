// Import utils
import { useMessage, useTranslations } from 'components/utilities';

// Import store
import { updatePublicHolidays } from 'store/actions';

export const usePublicHolidays = () => {
	const { message } = useMessage();
	const { t } = useTranslations();

	const handleOnSubmit = async (values) => {
		await updatePublicHolidays({
			successCallback: () => {
				message.success(t('common.messages.successfully_done'));
			},
			errorCallback: () => {
				message.error(t('common.messages.error_message'));
			},
			values,
		});
	};

	return { handleOnSubmit };
};
