import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		margin: '40px 0 20px 0',
	},
	updateConfirmDesc: {
		fontSize: 16,
		marginBottom: theme.spacing(1),
	},
}));
