import React from 'react';
import { TableCell, Tooltip, Typography } from '@material-ui/core';
import { object } from 'prop-types';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { getReasonName } from './helpers';

export const ReasonTableCell = ({ cell }) => {
	const { cancel_reason: cancelReason, compensation } = cell.row.original || {};
	const { type } = compensation || {};
	const {
		i18n: { language },
		t,
	} = useTranslations();

	const { name } = cancelReason || {};
	const reason = getReasonName({ name, language });
	const originalReason = name?.[language];

	return (
		<TableCell
			style={{ minWidth: '180px' }}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			{type && (
				<Typography>
					{t(`bookings.compensation_history.compensation_types.${type}`)}
				</Typography>
			)}
			{reason && (
				<Tooltip title={originalReason}>
					<Typography>{reason}</Typography>
				</Tooltip>
			)}
		</TableCell>
	);
};

ReasonTableCell.propTypes = {
	cell: object,
};
