import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	map: {
		display: 'flex',
		width: '100%',
		minHeight: '400px',
		borderRadius: 12,

		'& .gmnoprint, & .gm-fullscreen-control, & .gm-svpc': {
			display: 'none',
		},
	},
}));
