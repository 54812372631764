import React from 'react';
import { Box } from '@material-ui/core';
import { promoCodeFormSelector } from 'store/selectors';
import {
	fetchPromoCodeFormResources,
	submitPromoCodeForm,
} from 'store/actions';

// Import styles
import useStyles from '../styles';

// Import components
import { FormContent } from './components';

// Import helpers
import { ADD_INITIAL_VALUES, EDIT_INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import utilities
import { useEditPromoCode } from './useEditPromoCode';
import { FormRPC } from 'components/utilities';

export const EditPromoCode = () => {
	const classes = useStyles();

	const { isAddView } = useEditPromoCode();

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchPromoCodeFormResources}
				submitFormAction={submitPromoCodeForm}
				isCardLayout={false}
				goBackPath={URLS.promoCodesUrl}
				store={promoCodeFormSelector}
				initialValues={isAddView ? ADD_INITIAL_VALUES : EDIT_INITIAL_VALUES}
				lg={12}
				md={12}
			>
				{() => <FormContent />}
			</FormRPC>
		</Box>
	);
};
