import React, { useEffect } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useLastLocation } from 'react-router-last-location';
import { queryCache } from 'react-query';

// Import helpers
import { INITIAL_VALUES } from './helpers';
import { QUERY_KEYS } from 'helpers';

// Import utilities
import { submitModelForm, fetchModelFormResources } from 'store/actions';
import { FormRPC } from 'components/utilities';

// Import components
import { BaseDetails } from './components';
import { SeoCard } from 'components/elements';

// Import styles
import { useBaseLayoutStyles } from 'theme/styles';

const { CARS } = QUERY_KEYS;

const ModelForm = () => {
	const { root } = useBaseLayoutStyles();
	const lastLocation = useLastLocation();

	const goBackPath = lastLocation?.pathname || '/cars/models';

	useEffect(() => {
		queryCache.removeQueries(CARS.MAKERS);
		queryCache.cancelQueries(CARS.MAKERS);
	}, []);

	return (
		<Box className={root}>
			<FormRPC
				fetchFormResAction={fetchModelFormResources}
				submitFormAction={submitModelForm}
				isCardLayout={false}
				goBackPath={goBackPath}
				store={(store) => store.models.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<SeoCard section="seo" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};

export default ModelForm;
