import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	card: {
		border: `1px solid ${theme.palette.background[5]}`,
		boxShadow: 'none',
		padding: '24px',
		marginBottom: theme.spacing(4),
	},
	statusRecord: {
		marginBottom: '24px',
	},
	checkedByLabel: {
		color: theme.palette.text[2],
		fontWeight: 400,
	},
}));
