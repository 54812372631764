export const validation = (values) => {
	let errors = {};

	const { leasing } = values || {};
	const { base_rent } = leasing || {};
	const { m12, m24, m36 } = base_rent?.months || {};

	if (Number(m12) < Number(m24)) {
		errors = {
			leasing: {
				...errors,
				base_rent: {
					months: {
						...(errors?.leasing?.base_rent?.months || {}),
						m24: 'cars.form.leasing.validations.2years',
					},
				},
			},
		};
	}

	if (Number(m24) < Number(m36)) {
		errors = {
			leasing: {
				...errors,
				base_rent: {
					months: {
						...(errors?.leasing?.base_rent?.months || {}),
						m36: 'cars.form.leasing.validations.3years',
					},
				},
			},
		};
	}

	return errors;
};

export const INITIAL_VALUES = {
	carInfo: {
		details: {
			quantity: '',
			description: {
				ar: '',
				en: '',
			},
		},
		prices: {
			annual: {
				days: '',
			},
			monthly: {
				days: '',
			},
			daily: {
				days: '',
			},
			weekly: {
				days: '',
			},
		},
		vehicle: {
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
			year: null,
			uuid: '',
		},
		options: {
			is_unlimited: false,
			unlimited_nett_price: '',
			free_kms: '',
			extra_km_nett_price: '',
			is_insurance: false,
			is_no_smoking_car: false,
			no_smoking_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
			insurance_prices: {
				daily: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
		},
		external: {
			has_external: false,
			car_codes: null,
			codes: [],
		},
		equipment: {
			navigation: false,
		},
		subscription: {
			is_enabled: false,
			prices: {
				1: {
					nett_price: 0,
				},
				3: {
					nett_price: 0,
				},
				6: {
					nett_price: 0,
				},
				9: {
					nett_price: 0,
				},
				12: {
					nett_price: 0,
				},
			},
		},
	},
	prices: {
		annual: {
			from: 180,
			to: 360,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		monthly: {
			from: 15,
			to: 31,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		daily: {
			from: 1,
			to: 2,
			average_nett_price: '',
			lowest_nett_price: '',
		},
		weekly: {
			from: 2,
			to: 7,
			average_nett_price: '',
			lowest_nett_price: '',
		},
	},
	externalCars: [],
	is_daily: true,
	is_leasing: false,
	is_available_to_sell_after_leasing_period: false,
	leasing: {
		base_rent: {
			months: {
				m1: '',
				m12: '',
				m24: '',
				m36: '',
			},
		},
		security_deposit_price: '',
		leasing_sell_price: '',
	},
};
