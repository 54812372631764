import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import utils
import { useTranslations } from 'components/utilities';
import { useRejectReason } from './useRejectReason';

// Import components
import { UpdateReasonDialog } from '../UpdateReasonDialog';
import { OldAndNewReasons } from '../OldAndNewReasons';
import { ApproveRejectionDialog } from '../ApproveRejectionDialog';

// Import context
import { useUrgentFormsProvider } from '../../../../context';

// Import assets
import { CheckMarkWithCircleIcon, UpdateIcon } from 'assets/icons';

// Import styles
import { useStyles } from './RejectReason.styles';

export const RejectReason = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const {
		cancelReasonName,
		oldCancellationReason,
		validatedByName,
		validatedDate,
	} = useRejectReason();
	const {
		toggleUpdateReasonOpen,
		isCancelReasonApproved,
		toggleApproveRejection,
	} = useUrgentFormsProvider();

	return (
		<Grid container>
			<Grid item xs={12} md={8}>
				{!isCancelReasonApproved ? (
					<>
						<Typography variant="body1" className={classes.title}>
							{t('bookings.validate_cancellation.current_reject_reason')}
						</Typography>
						<Typography variant="body1" className={classes.reason}>
							{cancelReasonName}
						</Typography>
					</>
				) : (
					<Grid container direction="column" spacing={3}>
						<OldAndNewReasons
							newReason={cancelReasonName}
							oldReason={oldCancellationReason}
							newReasonTitle="bookings.validate_cancellation.approved_reject_reason"
							validatedByName={validatedByName}
							validatedDate={validatedDate}
						/>
					</Grid>
				)}

				{!isCancelReasonApproved && (
					<Grid
						container
						spacing={2}
						justifyContent="flex-end"
						alignItems="center"
					>
						<Grid item>
							<Button
								color="default"
								type="button"
								startIcon={<UpdateIcon />}
								className={classes.button}
								onClick={toggleUpdateReasonOpen}
								size="large"
							>
								{t('common.buttons.update_reason')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="outlined"
								onClick={toggleApproveRejection}
								startIcon={<CheckMarkWithCircleIcon />}
								className={clsx([classes.button, classes.approveButton])}
								type="button"
								size="large"
							>
								{t('common.buttons.approve')}
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>

			<UpdateReasonDialog />
			<ApproveRejectionDialog />
		</Grid>
	);
};
