// Import helpers
import { CHIP_COLORS } from 'helpers';

export const statusList = [
	{
		label: 'common.filters.active',
		color: CHIP_COLORS.SUCCESS,
		status: 'active',
	},
	{
		label: 'common.filters.inactive',
		color: CHIP_COLORS.ERROR,
		status: 'inactive',
	},
];

export const AVAILABILITY_TABLE_COLUMN_ACCESSORS = {
	AVAILABILITY: 'availability',
	ACTIONS: 'actions',
};

export const availabilityTableColumns = [
	{
		header: 'common.fields.company',
		accessor: 'company',
		disableSortBy: true,
	},
	{
		header: 'common.fields.office',
		accessor: 'office',
		disableSortBy: true,
	},
	{
		header: 'common.fields.city',
		accessor: 'city',
		disableSortBy: true,
	},
	{
		header: 'common.fields.availability',
		accessor: AVAILABILITY_TABLE_COLUMN_ACCESSORS.AVAILABILITY,
		disableSortBy: true,
	},
	{
		header: 'bookings.table.headers.actions',
		accessor: AVAILABILITY_TABLE_COLUMN_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];

export const getCarData = (data) => {
	const { available, maker, model, year } = data;

	return {
		modelAndYear: `${model} ${year}`,
		availability: available,
		name: maker,
		image: '',
	};
};
