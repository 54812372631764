import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CarIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M14.6685 6L12.9219 6.66667"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M3.08927 6.66667L1.33594 6"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.1667 9.33333H10.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.5026 9.33333H3.83594"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.9206 6.66699L12.3613 3.99233C12.1993 3.22033 11.5186 2.66699 10.7293 2.66699H5.3426C4.5686 2.66699 3.89594 3.20033 3.71994 3.95433L3.08594 6.66699"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M2.16927 13.3337H3.66927C4.22127 13.3337 4.66927 12.8857 4.66927 12.3337V11.667H11.3359V12.3337C11.3359 12.8857 11.7839 13.3337 12.3359 13.3337H13.8359C14.2959 13.3337 14.6693 12.9603 14.6693 12.5003V8.66699C14.6693 7.56233 13.7739 6.66699 12.6693 6.66699H3.33594C2.23127 6.66699 1.33594 7.56233 1.33594 8.66699V12.5003C1.33594 12.9603 1.70927 13.3337 2.16927 13.3337Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
