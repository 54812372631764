import React from 'react';
import { Box, Popover, Typography } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { object } from 'prop-types';

// Import components
import { IconButton } from 'components/elements/atoms';

// Import styles
import { popoverStyle, useStyles } from './PreviewPopup.styles';

// Import assets
import {
	EmployeeUserIcon,
	InformationIcon,
	StarIcon,
	StoreIcon,
} from 'assets/icons';

export const PreviewPopup = ({ cell }) => {
	const classes = useStyles();

	const {
		blamable_rating: blamableRating,
		blamable_full_name: blamableFullName,
		office_name: officeName,
		company_name: companyName,
		office_city: officeCity,
	} = cell.row.original;

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<Box>
					<IconButton {...bindTrigger(popupState)}>
						<InformationIcon className={classes.mainIcon} />
					</IconButton>
					<Popover
						PaperProps={{
							style: popoverStyle,
						}}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 100,
							horizontal: 50,
						}}
						{...bindPopover(popupState)}
					>
						<Box className={classes.popoverContent}>
							<Box className={classes.row}>
								<StoreIcon className={classes.squareIcon} />
								<Typography className={classes.text}>
									{companyName} - {officeName}, {officeCity}
								</Typography>
							</Box>
							<Box className={classes.row}>
								{blamableFullName && (
									<Box className={classes.column}>
										<EmployeeUserIcon className={classes.squareIcon} />
										<Typography className={classes.text}>
											{blamableFullName}
										</Typography>
									</Box>
								)}
								{blamableRating && (
									<Box className={classes.column}>
										<StarIcon className={classes.starIcon} />
										<Typography className={classes.text}>
											{blamableRating}
										</Typography>
									</Box>
								)}
							</Box>
						</Box>
					</Popover>
				</Box>
			)}
		</PopupState>
	);
};

PreviewPopup.propTypes = {
	cell: object,
};
