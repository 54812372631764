import * as dateFns from 'date-fns';

// Import helpers
import { SCHEDULED_BOOKING_DATE_TYPES } from 'helpers';

const { TODAY, TOMORROW, AFTER_TOMORROW } = SCHEDULED_BOOKING_DATE_TYPES;

export const PERIODS = [
	{
		label: 'scheduled_bookings.table.legend.periods.today',
		type: TODAY,
		isActive: (date) => dateFns.isToday(date),
	},
	{
		label: 'scheduled_bookings.table.legend.periods.tomorrow',
		type: TOMORROW,
		isActive: (date) => dateFns.isTomorrow(date),
	},
	{
		label: 'scheduled_bookings.table.legend.periods.after_tomorrow',
		type: AFTER_TOMORROW,
		isActive: (date) =>
			dateFns.isAfter(dateFns.startOfDay(dateFns.addDays(date, 2)), date),
	},
];

export const findPeriod = (date) => {
	const parseDate = (date) => dateFns.parse(date, 'yyyy-MM-dd', new Date());

	return PERIODS.find(({ isActive }) => isActive(parseDate(date)));
};
