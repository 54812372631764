import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { AMOUNT_FIELDS } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/EditBooking/components';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import styles
import { useStyles } from '../../BookingInfo.styles';

export const Amount = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const booking = useSelector(previewBookingSelector);

	return (
		<Box className={classes.infoContainer}>
			<Typography variant="body1" className={classes.title}>
				{t('common.fields.amount')}
			</Typography>

			<PreviewFields
				fields={AMOUNT_FIELDS({ booking, t, classes })}
				customData={booking}
			/>
		</Box>
	);
};
