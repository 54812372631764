import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	closeIcon: {
		fontSize: 30,
		color: theme.palette.gray.medium,
	},
	iconButton: {
		width: 'auto',
		height: 'auto',
		border: '1px solid blck',
		padding: 0,

		'&:hover': {
			backgroundColor: theme.palette.white,
		},
	},
}));
