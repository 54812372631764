import { useState } from 'react';
import { queryCache } from 'react-query';
import services from 'services/services';
import { format } from 'date-fns';

// Import utilities
import { useMessage, useToggle, useTranslations } from 'components/utilities';

// Import helpers
import { addCustomerWalletFundsUrl } from 'store/paths';
import { QUERY_KEYS } from 'helpers';

export const useAddDialog = ({ customerUuid }) => {
	const [isLoading, setIsLoading] = useState(false);

	const { on: isOpen, toggle } = useToggle();

	const { message } = useMessage();

	const { t } = useTranslations();

	const handleOnSubmit = async (values) => {
		const { description, reason_uuid, expiry_date, amount } = values;

		const convertedExpiryDate = format(expiry_date, 'yyyy-MM-dd');

		const initialBody = { expiry_date: convertedExpiryDate, amount };

		const body = values.description
			? { ...initialBody, description }
			: { ...initialBody, reason_uuid };

		setIsLoading(true);

		try {
			await services.post(addCustomerWalletFundsUrl(customerUuid), body);
			await queryCache.refetchQueries(
				QUERY_KEYS.CUSTOMERS.WALLET_BALANCE_HISTORY(customerUuid)
			);
			await queryCache.refetchQueries(
				QUERY_KEYS.CUSTOMERS.WALLET_BALANCE(customerUuid)
			);

			toggle();
			message.success(t('customers.preview.wallet.add_dialog.success_message'));
			setIsLoading(false);
		} catch (error) {
			message.error(t('common.messages.error_message'));
			setIsLoading(false);
		}
	};

	return {
		handleOnSubmit,
		toggle,
		isLoading,
		isOpen,
	};
};
