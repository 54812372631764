import React from 'react';
import { Box } from '@material-ui/core';

// Import components
import { Grid, Typography } from 'components/elements';

// Import assets
import { ExclamationMarkWithCircleIcon } from 'assets/icons';

//Import styles
import { useStyles } from './DateAndTime.styles';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useDateAndTime } from './useDateAndTime';

export const DateAndTime = () => {
	const classes = useStyles();
	const { FIELDS, days } = useDateAndTime();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	return (
		<Box>
			<Grid container spacing={3}>
				{FIELDS.map(renderFields)}
			</Grid>

			<Typography className={classes.durationText}>
				{t('bookings.create_booking.total_rental_duration')} ={' '}
				<Typography component="span" className={classes.duration}>
					{days}
				</Typography>
			</Typography>

			<Box className={classes.noteContainer}>
				<ExclamationMarkWithCircleIcon className={classes.icon} />
				<Typography className={classes.note}>
					{t('bookings.create_booking.payment_sms_alert')}
				</Typography>
			</Box>
		</Box>
	);
};
