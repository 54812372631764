import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';

export const useDropCityField = () => {
	const {
		values: {
			cities,
			dropCities,
			options: { drop_city, drop_city_uuid },
		},
	} = useFormState();

	const { change } = useForm();

	const locations = dropCities.data.map((dropCity) => {
		const { name } = cities.data.find(
			({ uuid }) => dropCity.city_out_uuid === uuid
		);

		return {
			...dropCity,
			name,
		};
	});

	useEffect(() => {
		if (!drop_city) {
			change('options.drop_city_uuid', '');
		} else if (!drop_city_uuid) {
			change('options.drop_city_uuid', locations[0].uuid);
		}
	}, [drop_city, drop_city_uuid, change, locations]);

	return {
		locations,
	};
};
