import React from 'react';
import SuccessIcon from '@material-ui/icons/CheckOutlined';
import DefaultIcon from '@material-ui/icons/HelpOutlineOutlined';

// Import assets
import { ErrorIcon, RoundedTriangleWarningIcon } from 'assets/icons';

// If adding new variant to ALERT_VARIANTS
// Please, add a new variant here AlertMessage.propTypes.variant for IDE hints

export const ALERT_VARIANTS = {
	SUCCESS: 'success',
	WARNING: 'warning',
	ERROR: 'error',
	DEFAULT: 'default',
};

export const COLOR_VARIANTS = {
	MAIN: 'main',
	DARK: 'dark',
	LIGHT: 'light',
};

const { WARNING, ERROR, SUCCESS } = ALERT_VARIANTS;

export const renderAlertIcon = (variant) => {
	switch (variant) {
		case SUCCESS:
			return <SuccessIcon fontSize="inherit" />;

		case WARNING:
			return <RoundedTriangleWarningIcon />;

		case ERROR:
			return <ErrorIcon />;

		default:
			return <DefaultIcon fontSize="inherit" />;
	}
};

export const getColor = ({ props, theme, variant = COLOR_VARIANTS.MAIN }) => {
	switch (props.variant) {
		case SUCCESS:
			return theme.palette.success[variant];
		case WARNING:
			return theme.palette.warning[variant];
		case ERROR:
			return theme.palette.error[variant];

		default:
			return theme.palette.secondary[variant];
	}
};
