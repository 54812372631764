import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';

// Import utils and helpers
import { CUSTOMER_BASIC_INFO_FIELDS, CUSTOMER_DETAILS_FIELDS } from './helpers';
import { useCustomerDetails } from './useCustomerDetails';
import { useProfile, useTranslations } from 'components/utilities';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';
import { PreviewFields } from '../../../PreviewFields';

// Import styles
import { useStyles } from './CustomerDetails.styles';

// Import assets
import { EditUserIcon } from 'assets/icons';

export const CustomerDetails = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { isAdmin } = useProfile();

	const {
		convertedCustomerDetails,
		goToCustomerEditPage,
		licenseExists,
		customerUuid,
		filePath,
		isFetching,
		isError,
	} = useCustomerDetails();

	if (isFetching) {
		return <LoadingWrapper />;
	}

	if (isError) {
		return <AlertMessage />;
	}

	return (
		<Box className={classes.container}>
			<Box>
				<Box className={classes.topBar}>
					<Typography variant="h5">
						{t('bookings.preview_booking.customer.basic_info')}
					</Typography>
					{isAdmin && (
						<Button
							onClick={goToCustomerEditPage}
							color="primary"
							startIcon={
								<EditUserIcon color="primary" className={classes.editIcon} />
							}
						>
							{t('common.buttons.edit')}
						</Button>
					)}
				</Box>
				<PreviewFields
					fields={CUSTOMER_BASIC_INFO_FIELDS({ licenseExists, filePath })}
					customData={convertedCustomerDetails}
				/>
			</Box>
			<Box>
				<Box className={classes.topBar}>
					<Typography variant="h5">
						{t('bookings.preview_booking.customer.details')}
					</Typography>
				</Box>
				<PreviewFields
					fields={CUSTOMER_DETAILS_FIELDS({ customerUuid })}
					customData={convertedCustomerDetails}
				/>
			</Box>
		</Box>
	);
};
