import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import _ from 'lodash';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';
import {
	acceptBooking,
	assignDriver,
	fetchBookingPreview,
} from 'store/actions';

export const useAssignDriverDialog = ({ toggle, bookingUuid }) => {
	const [isLoading, setLoading] = useState(false);
	const { fetchData } = useTableContextProvider();
	const { pathname } = useLocation();
	const { message } = useMessage();
	const { t } = useTranslations();
	const dispatch = useDispatch();
	const { id } = useParams();

	const handleOnSubmit = async (values) => {
		setLoading(true);
		const newValues = _.omit(values, 'action');

		try {
			Object.entries(newValues).forEach(async ([deliveryType, driverUuid]) => {
				await assignDriver({ driverUuid, bookingUuid, deliveryType });
			});

			await acceptBooking({
				bookingId: bookingUuid,
			});

			if (pathname.includes('preview')) {
				await fetchBookingPreview({ id })(dispatch);
			} else {
				await fetchData();
			}

			setLoading(false);
			toggle();
		} catch {
			message.error(t('common.messages.error_message'));
			setLoading(false);
		}
	};

	return { handleOnSubmit, isLoading };
};
