import { makeStyles } from '@material-ui/styles';

const sharedStyles = {
	padding: '8px 20px',
	margin: '40px 0 10px 0',
	height: 'auto',
	fontSize: 12,
	fontWeight: 600,
};

export const useStyles = makeStyles((theme) => ({
	greenChip: {
		backgroundColor: theme.palette.success.lighter,
		color: theme.palette.success.main,
		...sharedStyles,
	},
	redChip: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
		...sharedStyles,
	},
}));
