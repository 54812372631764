import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	topBar: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: '16px',
		minHeight: '40px',
	},
}));
