import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		fontSize: 13,
		fontWeight: 600,
		padding: '12px 24px',
	},
	contentText: {
		marginBottom: '48px',
		fontSize: 16,
	},
	buttonsContainer: {
		marginBottom: theme.spacing(2),
	},
	icon: {
		color: theme.palette.primary.main,
		width: '40px',
		height: '40px',
	},
	title: {
		color: theme.palette.primary.main,
	},
}));
