export const convertVehicleName = (vehicle) => {
	if (null != vehicle && typeof vehicle === 'string') {
		const splittedVehicle = vehicle.split(' ');
		const name = splittedVehicle.slice(0, -1).join(' ');
		const year = splittedVehicle.slice(-1)[0];
		return { name, year };
	}

	return null;
};
