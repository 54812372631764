import React from 'react';
import { Form } from 'react-final-form';
import { string } from 'prop-types';

// Import utils and helpers
import { useAddComment } from './useAddComment';
import { yupValidator } from 'helpers';
import { validationSchema } from './validation';

// Import components
import { Comment } from './components';

export const AddComment = ({ type }) => {
	const { handleOnSubmit, onSubmit, isLoading } = useAddComment({ type });
	return (
		<Form
			onSubmit={handleOnSubmit}
			validate={(values) =>
				yupValidator({
					validationSchema: validationSchema(),
					values,
				})
			}
			render={(formData) => (
				<form
					onSubmit={(event) => {
						onSubmit({ event, ...formData });
					}}
				>
					<Comment isLoading={isLoading} />
				</form>
			)}
		/>
	);
};

AddComment.propTypes = {
	type: string,
};
