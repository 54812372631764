import { useState, useEffect } from 'react';
import { queryCache } from 'react-query';

// Import utils and helpers
import { useCompanyManagerAssignedOfficesQuery } from 'queries';
import { QUERY_KEYS } from 'helpers';

const { ASSIGN_OFFICES } = QUERY_KEYS.OFFICES;

export const useOfficesDialogContent = ({ userUuid }) => {
	const { data, isLoading } = useCompanyManagerAssignedOfficesQuery({
		userUuid,
	});
	const [checkedOffices, setCheckedOffices] = useState([]);

	const officesData = data ? Object.values(data) : [];
	const isDataExist = officesData?.length > 0;

	useEffect(() => {
		if (isDataExist) {
			const offices = officesData.filter(
				(office) => office.is_manager_assigned
			);
			setCheckedOffices(offices);
		}

		return () => {};
		// eslint-disable-next-line
	}, [JSON.stringify(officesData)]);

	useEffect(() => {
		return () => {
			queryCache.cancelQueries(ASSIGN_OFFICES(userUuid), { exact: true });
			queryCache.removeQueries(ASSIGN_OFFICES(userUuid), { exact: true });
		};
		// eslint-disable-next-line
	}, []);

	return {
		setCheckedOffices,
		checkedOffices,
		officesData,
		isDataExist,
		isLoading,
	};
};
