import React from 'react';
import {
	Button,
	DialogActions as MuiDialogActions,
	DialogContent,
} from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useQuickFinderProvider } from '../../context';

// Import components
import { DialogActions, GoalSelector } from './components';
import { ClosableDialogTitle, Dialog } from 'components/elements';

// Import assets
import { ThunderIcon } from 'assets/icons';

// Import styles
import { useStyles } from './QuickFinderDialog.styles';

export const QuickFinderDialog = () => {
	const classes = useStyles();
	const { t } = useTranslations();
	const {
		handleCloseDialog,
		isQuickFinderDialogOpen,
		toggleQuickFinderDialog,
	} = useQuickFinderProvider();

	return (
		<>
			<Button
				variant="contained"
				startIcon={<ThunderIcon />}
				className={classes.button}
				onClick={toggleQuickFinderDialog}
			>
				{t('common.buttons.quick_finder')}
			</Button>
			<Dialog
				fullWidth
				open={isQuickFinderDialogOpen}
				onClose={handleCloseDialog}
				maxWidth="md"
			>
				<ClosableDialogTitle
					title={t('common.buttons.quick_finder')}
					onClose={handleCloseDialog}
				/>
				<DialogContent className={classes.dialogContent}>
					<GoalSelector />
				</DialogContent>

				<MuiDialogActions className={classes.dialogActions}>
					<DialogActions />
				</MuiDialogActions>
			</Dialog>
		</>
	);
};
