import { makeStyles } from '@material-ui/styles';

import { BOOKING_STATES_TYPES } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: '100px',
		textTransform: 'capitalize',
		fontWeight: 600,
		fontSize: 12,
		minWidth: '109px',
	},

	[BOOKING_STATES_TYPES.SUCCESS]: {
		backgroundColor: theme.palette.success.lighter,
		color: theme.palette.success.medium,
	},

	[BOOKING_STATES_TYPES.ERROR]: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},

	[BOOKING_STATES_TYPES.WARNING]: {
		backgroundColor: theme.palette.warning.medium,
		color: theme.palette.warning.dark,
	},

	[BOOKING_STATES_TYPES.INFO]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},

	[BOOKING_STATES_TYPES.DEFAULT]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},
	rejectedButton: {
		'&:hover': {
			backgroundColor: theme.palette.error.lighter,
		},
	},
	menuList: {
		'& .MuiPaper-root': {
			minWidth: '288px',
			boxShadow: '0px 4px 8px rgba(38, 50, 56, 0.16)',
			padding: theme.spacing(2),
			borderRadius: '12px',
			marginTop: '8px',
			backgroundColor: theme.palette.error.lighter,
			border: `1px solid ${theme.palette.error.border}`,

			'& .MuiList-root': {
				padding: '0',
			},
		},
	},
	menuItem: {
		cursor: 'default',
		padding: '0px !important',
		flexDirection: 'column',
		whiteSpace: 'normal',
		alignItems: 'flex-start',
		'&:hover': {
			backgroundColor: `transparent`,
		},
	},
	divider: {
		backgroundColor: theme.palette.error.border,
		margin: theme.spacing(2, 0),
	},
	reasonWrapper: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		marginBottom: '12px',
	},
	reasonTitle: {
		color: theme.palette.text[3],
		fontSize: 12,
		fontWeight: 600,
	},
	reason: {
		fontSize: 12,
		marginBottom: '12px',
	},
	reasonText: {
		fontSize: 12,
		color: theme.palette.text[3],
	},
}));
