import React from 'react';
import {
	CardContent,
	CardHeader,
	Divider,
	Card,
	Grid,
} from '@material-ui/core';

// Import utils
import { usePhonePrefixes } from 'queries';
import {
	useProfile,
	useTranslations,
	useCommonFields,
} from 'components/utilities';

// Import helpers
import { TOP_FIELDS, BOTTOM_FIELDS } from './helpers';

// Import components
import { MultiOfficeSelect } from '../../components';
import { CompanyFilter } from 'components/elements';

export const Details = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const { isAdmin, companyUuid } = useProfile();

	const { data: prefixes } = usePhonePrefixes();

	return (
		<Card>
			<CardHeader title={t('users.form.details.driver_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					{TOP_FIELDS.map(renderFields)}

					{isAdmin && (
						<CompanyFilter
							label={t('users.form.details.assign_to_company')}
							name="company_uuid"
							showAll={false}
							required
							xs={6}
							md={6}
						/>
					)}

					<MultiOfficeSelect
						label={t('users.form.details.assign_to_office')}
						name="offices_uuid"
						xs={isAdmin ? 6 : 12}
						externalCompanyUuid={isAdmin ? '' : companyUuid}
					/>

					{BOTTOM_FIELDS(prefixes).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
