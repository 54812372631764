import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	buttonsWrapper: {
		marginTop: '24px',
	},
	filterButton: {
		border: '1px solid',
		borderColor: theme.palette.background[3],
		borderRadius: '8px',
		padding: '5px 14px',
	},
	submitButton: {
		borderRadius: '8px',
		padding: '10px 24px',
	},
	clearAllButton: {
		padding: '10px 24px',
	},
}));
