import React from 'react';
import { Button, Dialog, DialogContent, Divider } from '@material-ui/core';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components';
import { ClosableDialogTitle } from 'components/elements';

// Import utilities
import { useToggle, useTranslations } from 'components/utilities';

// Import helpers
import { EXTENSION_DIALOG_FIELDS } from './helpers';

// Import styles
import { useStyles } from './styles';

export const ExtensionInfoDialog = () => {
	const { on, toggle } = useToggle();

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<>
			<Button
				fullWidth
				onClick={toggle}
				color="primary"
				endIcon={<KeyboardArrowRightIcon />}
			>
				{t('bookings.preview_booking.booking_info.extension_info.button_title')}
			</Button>
			{on && (
				<Dialog open={on} onClose={toggle}>
					<ClosableDialogTitle
						onClose={toggle}
						title={t(
							'bookings.preview_booking.booking_info.extension_info.title'
						)}
					/>
					<Divider className={classes.divider} />
					<DialogContent className={classes.dialogContent}>
						<PreviewFields fields={EXTENSION_DIALOG_FIELDS} />
					</DialogContent>
				</Dialog>
			)}
		</>
	);
};
