import { useDispatch } from 'react-redux';

// Import actions
import { updateDropdownTabs } from 'store/actions';

export const useSidebar = (onClose) => {
	const dispatch = useDispatch();

	const handleCollapseDrawer = () => {
		onClose();
		updateDropdownTabs([])(dispatch);
	};
	return { handleCollapseDrawer };
};
