import { useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';

// Import store
import {
	changeBookingCancelReasonStatus,
	changeCancelReason,
} from 'store/actions';
import { previewBookingSelector } from 'store/selectors';

// Import queries
import { useCancellationReasons } from 'queries';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { BOOKING_CANCELLATION_STATES } from 'helpers';

export const useUpdateReasonDialog = (toggleUpdateReasonOpen) => {
	const { APPROVED } = BOOKING_CANCELLATION_STATES;

	const { uuid } = useSelector(previewBookingSelector);

	const { on: isSuccess, toggle: toggleSuccess } = useToggle();

	const {
		i18n: { language: lang },
	} = useTranslations();

	const { data: cancelReasons, isLoading: isReasonsLoading } =
		useCancellationReasons();

	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(null);
	const [isLoading, setIsLoading] = useState(false);

	const source = axios.CancelToken.source();
	const cancelToken = source.token;

	const getReasonName = (cancellationReasonUuid) =>
		cancelReasons.find(({ uuid }) => uuid === cancellationReasonUuid)?.details
			?.name?.[lang];

	const handleSubmit = async (values) => {
		setIsLoading(true);

		try {
			const { cancellationReason: cancellationReasonUuid } = values;

			const body = {
				cancel_reason: {
					uuid: cancellationReasonUuid,
					description: getReasonName(cancellationReasonUuid),
				},
			};

			await changeCancelReason({ uuid, body });

			await changeBookingCancelReasonStatus({
				uuid,
				status: APPROVED,
				cancelToken,
				isCancellation: true,
			});

			toggleUpdateReasonOpen();
			toggleSuccess();
		} catch (e) {
			setError(e);
			setIsError(true);
		}
		setIsLoading(false);
	};

	return {
		handleSubmit,
		cancelReasons,
		isReasonsLoading,
		isLoading,
		isError,
		error,
		getReasonName,
		isSuccess,
		toggleSuccess,
	};
};
