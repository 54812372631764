import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		border: '1px solid',
		borderColor: theme.palette.background[3],
		borderRadius: '8px',
		padding: '5px 14px',
	},
}));
