import { useFormState, useForm } from 'react-final-form';

// import utils and helpers
import { BOOKING_STEPS } from '../../helpers';
import { isAllDataFilled } from './helpers';
import { useToggle } from 'components/utilities';

export const useBottomActions = () => {
	const {
		values: { step },
		values,
	} = useFormState();

	const { on: confirmationDialogOpen, toggle: toggleConfirmationDialog } =
		useToggle();

	const { change } = useForm();

	const isStepCreate = step === BOOKING_STEPS.CREATE;

	const continueBtnDisabled = !isAllDataFilled(values);

	const changeStepToReview = () => {
		change('step', BOOKING_STEPS.REVIEW);
	};
	const changeStepToCreate = () => {
		change('step', BOOKING_STEPS.CREATE);
	};

	return {
		isStepCreate,
		changeStepToCreate,
		changeStepToReview,
		continueBtnDisabled,
		confirmationDialogOpen,
		toggleConfirmationDialog,
	};
};
