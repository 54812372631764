import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

const useCompanyOffices = (companyUuid) => {
	const officesCollectionUrl = `/v2/supplier/business/company/${companyUuid}/office/collection`;

	const enabled = companyUuid !== 'all' && null != companyUuid;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.OFFICES.COMPANY_OFFICES(companyUuid),
		async () => await services.get(officesCollectionUrl),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};

export default useCompanyOffices;
