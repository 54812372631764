export const BUTTON_PARAMS = {
	TYPE: {
		OUTLINED_AND_PRIMARY: 'outlined_and_primary', // outlined with primary letter
		OUTLINED_AND_DARK: 'outlined_and_dark', // outlined with dark letters
		PRIMARY_FILLED: 'primary_filled', // primary
		WHITE_FILLED: 'white_filled', // white
		ERROR: 'error',
	},
	SIZE: {
		LARGE: 'large',
		MEDIUM: 'medium',
		SMALL: 'small',
		TINY: 'tiny',
	},
};
