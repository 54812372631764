import { useFormState } from 'react-final-form';

// Import helpers and utils
import { removeUnwontedStates } from './helpers';
import { useBookingStates } from 'queries';

export const useBookingStatusFilter = () => {
	const { submitting } = useFormState();

	const { data } = useBookingStates();

	const bookingStates = removeUnwontedStates(data);

	return { submitting, bookingStates };
};
