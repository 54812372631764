// Import helpers
import { ACTIVE_CELL_STATES } from 'helpers';

const { ACTIVE, INACTIVE, BLOCKED } = ACTIVE_CELL_STATES;

export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.filters.active',
		status: ACTIVE,
	},
	{
		label: 'common.filters.inactive',
		status: INACTIVE,
	},
	{
		label: 'common.filters.blocked',
		status: BLOCKED,
	},
];
