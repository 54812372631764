import React, { forwardRef } from 'react';
import { Paper, Input } from '@material-ui/core';
import { func } from 'prop-types';

// Import icons
import { SearchIcon } from 'assets/icons';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './SearchInput.styles';

export const SearchInput = forwardRef(({ onChange }, ref) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const handleOnKeyDown = (e) => e.stopPropagation();

	return (
		<Paper className={classes.root} ref={ref}>
			<Input
				className={classes.input}
				disableUnderline
				onChange={onChange}
				onKeyDown={handleOnKeyDown}
				startAdornment={<SearchIcon className={classes.searchIcon} />}
				placeholder={t('common.search')}
			/>
		</Paper>
	);
});

SearchInput.displayName = 'SearchInput';
SearchInput.propTypes = {
	onChange: func,
};
