import React from 'react';
import { func, number } from 'prop-types';
import { Box } from '@material-ui/core';

export const TabPanel = ({ currentTab, getTab, ...rest }) => {
	return (
		<Box
			role="tabpanel"
			id={`tabpanel-${currentTab}`}
			aria-labelledby={`tab-${currentTab}`}
			{...rest}
		>
			<Box>{getTab(currentTab)}</Box>
		</Box>
	);
};

TabPanel.propTypes = {
	currentTab: number.isRequired,
	getTab: func.isRequired,
};
