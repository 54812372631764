import { useEffect } from 'react';
import { queryCache } from 'react-query';

// Import utils and helpers
import { BOOKING_CHIP_REFERENCES, QUERY_KEYS } from 'helpers';
import { useTableContextProvider } from 'components/context';
import { initialValues } from './helpers';

export const useFilters = () => {
	const { loading, filters } = useTableContextProvider();
	const refetch = () => {
		queryCache.refetchQueries(
			QUERY_KEYS.BOOKINGS.COUNT(BOOKING_CHIP_REFERENCES.CANCELLATION)
		);
	};

	useEffect(() => {
		if (loading) {
			refetch();
		}
		// eslint-disable-next-line
	}, [loading]);

	const convertInitialValues = () => {
		if (null != filters) {
			return {
				...initialValues,
				cancel_reason_status:
					filters.cancel_reason_status === null ||
					filters.cancel_reason_status === 'all'
						? 'all'
						: filters.cancel_reason_status,
			};
		}

		return initialValues;
	};

	const convertedInitialValues = convertInitialValues();

	return {
		initialValues: convertedInitialValues,
	};
};
