import React, { useState } from 'react';
import { createContext } from 'react';
import { bool, node, object } from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';

export const TabsProviderContext = createContext(null);

export const TabsProvider = ({ children, storeTabInUrl = true, data }) => {
	const location = useLocation();
	const history = useHistory();

	const decodedSearch = decodeURI(location.search.slice(1));

	const { tab = '0' } = queryString.parse(decodedSearch);

	const [currentTab, setCurrentTab] = useState(Number(tab));

	const a11yProps = (index) => ({
		id: `tab-${index}`,
		'aria-controls': `tabpanel-${index}`,
	});

	const handleSetCurrentTab = (tab) => {
		storeTabInUrl && history.push(`${location.pathname}?tab=${tab}`);
		setCurrentTab(tab);
	};

	const handleChange = (_, newValue) => {
		handleSetCurrentTab(newValue);
	};

	return (
		<TabsProviderContext.Provider
			value={{
				handleChange,
				a11yProps,
				currentTab,
				setCurrentTab: handleSetCurrentTab,
				data,
			}}
		>
			{children}
		</TabsProviderContext.Provider>
	);
};

TabsProvider.propTypes = {
	data: object,
	storeTabInUrl: bool,
	children: node,
};
