import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	mb: {
		marginBottom: '8px',
	},
	assignedLate: {
		color: theme.palette.error.main,
	},
	icon: {
		color: theme.palette.text[1],
		width: '20px',
		height: '20px',
	},
	driverWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
}));
