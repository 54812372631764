import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	actionsWrapper: {
		padding: '20px',
	},
	titleWrapper: {
		padding: '10px 20px',
	},
}));
