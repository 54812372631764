import { format } from 'date-fns';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { DAY_NAMES } from 'helpers';

export const useWorkingHoursPreviewField = (customData) => {
	const { on: isOpen, toggle } = useToggle();

	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const { officeName, workingHours } = customData || {};

	const todayDay = format(new Date(), 'EEEE');

	const officeHours = workingHours?.reduce((acc, item) => {
		const filteredPeriods = item.periods.filter(
			(period) => period.active === 1
		);

		if (filteredPeriods.length > 0) {
			acc.push({
				day: DAY_NAMES[item.day_of_week_iso - 1]?.[lang],
				hours: filteredPeriods
					.map(
						(period) =>
							`${period?.from?.slice(0, 5)} - ${period?.till?.slice(0, 5)}`
					)
					.join(` ${t('common.and')} `),
			});
		}

		return acc;
	}, []);

	return { isOpen, toggle, officeName, officeHours, todayDay };
};
