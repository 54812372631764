import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Rating } from '@material-ui/lab';

// Import styles
import { useStyles } from './DialogContent.styles';

// Import utils and helpers
import { getFormattedDate, getRatingReason } from './helpers';
import { useTranslations } from 'components/utilities';

// Import components
import { ChipLabel } from 'components/elements';

export const useDialogContent = (officeRatingHistory) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const stepIconClassName = officeRatingHistory?.[0]?.deletion_author_name
		? classes.resetStepIcon
		: classes.ratedStepIcon;

	const steps = officeRatingHistory?.map((reason) => {
		const {
			rating_author_name: createdBy,
			created_at: createdAt,
			deleted_at: resetAt,
			deletion_author_name: resetBy,
			description,
			rating_reasons: ratingReasonUuid,
			rating,
		} = reason || {};

		const label = `${t(
			`bookings.preview_booking.ratings.${resetBy ? 'reset_by' : 'rated_by'}`
		)}`;

		const date = getFormattedDate(resetBy ? resetAt : createdAt);
		const ratingReason = getRatingReason(ratingReasonUuid);

		return {
			label: label && (
				<Typography className={classes.label}>
					{label}{' '}
					<Box component="strong" className={classes.author}>
						{resetBy || createdBy}
					</Box>
				</Typography>
			),
			description: !resetBy ? (
				<Box className={classes.ratingBox}>
					<Rating readOnly value={rating} />
					<Typography className={classes.ratingReason}>
						{ratingReason}
					</Typography>
					<Typography className={classes.description}>{description}</Typography>
				</Box>
			) : null,
			chip: resetBy ? (
				<ChipLabel
					className={classes.deleteLabel}
					color="error"
					label={t('common.deleted')}
				/>
			) : null,
			date,
		};
	});

	return { steps, stepIconClassName };
};
