import React from 'react';
import { object } from 'prop-types';

// Import components
import { PreviewField } from 'components/elements';

export const DailyRentPricePreviewField = ({ customData }) => {
	const { period, item } = customData || {};
	const { amount_nett: pricePerDay } = item || {};
	const { days } = period || {};

	return (
		<PreviewField label="common.fields.daily_rent_price">
			{`${pricePerDay} x ${days}`}
		</PreviewField>
	);
};

DailyRentPricePreviewField.propTypes = {
	customData: object.isRequired,
};
