import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

const useOfficesAssignedToUser = () => {
	const officesCollectionUrl = `/v2/supplier/business/manager/offices/collection`;

	const { data, isFetching } = useQuery(
		QUERY_KEYS.OFFICES.LIST_ASSIGNED_TO_USER,
		async () => await services.get(officesCollectionUrl),
		{ refetchOnWindowFocus: false }
	);
	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default useOfficesAssignedToUser;
