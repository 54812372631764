import React from 'react';
import { string } from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';

// Import components
import { PreviewField } from 'components/elements';

export const LinkPreviewField = ({ name, link, label }) => {
	return (
		<PreviewField label={label}>
			<Link component={RouterLink} to={link} variant="h6">
				{name}
			</Link>
		</PreviewField>
	);
};

LinkPreviewField.propTypes = {
	name: string.isRequired,
	link: string.isRequired,
	label: string.isRequired,
};
