import { format } from 'date-fns';
import { queryCache } from 'react-query';
import i18next from 'i18next';

// Import helpers
import { QUERY_KEYS, YMD_SLASH_TIME_FORMAT } from 'helpers';

export const convertOfficeRatingHistory = (officeRatingHistory) =>
	officeRatingHistory?.reduce((acc, { rating, reset }) => {
		if (null != reset) {
			return [...acc, reset, rating];
		}
		return [...acc, rating];
	}, []);

export const getFormattedDate = (date) =>
	date && format(new Date(date), YMD_SLASH_TIME_FORMAT);

export const getRatingReason = (ratingReasonUuid) => {
	const reasons = queryCache.getQueryData(
		QUERY_KEYS.RATINGS.REASONS(ratingReasonUuid)
	)?.data?.data;
	return reasons?.find(({ uuid }) => ratingReasonUuid === uuid)?.reason[
		i18next.language
	];
};
