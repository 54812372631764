import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	labelBox: {
		position: 'relative',
		backgroundColor: theme.palette.purple.light,
		borderRadius: '8px',
		padding: '8px',
		border: `1px solid ${theme.palette.purple.medium}`,
		'&:after': {
			content: '""',
			position: 'absolute',
			left: 'calc(50% - 8px)',
			bottom: '-17px',
			transform: 'translateY(-50%)',
			width: 0,
			height: 0,
			borderLeft: '8px solid transparent',
			borderRight: '8px solid transparent',
			borderTop: `18px solid ${theme.palette.purple.medium}`,
			zIndex: -2,
		},
	},
	label: {
		color: theme.palette.purple.main,
		fontSize: '12px',
		fontWeight: '400',
	},
	dot: {
		position: 'absolute',
		left: 'calc(50% - 5px)',
		transform: 'translateY(-50%)',
		width: '10px',
		height: '10px',
		bottom: '-25px',
		borderRadius: '50%',
		zIndex: -1,
		backgroundColor: theme.palette.purple.main,
	},
}));
