import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	statusBox: {
		padding: '24px',
		border: `1px solid ${theme.palette.background[5]}`,
		borderRadius: '12px',
		marginBottom: theme.spacing(4),
	},
	buttonsContainer: {
		marginBottom: theme.spacing(2),
	},
	button: {
		fontSize: 14,
		fontWeight: 600,
		padding: '12px 24px',
	},
	solvedButton: {
		borderColor: theme.palette.background[3],
	},
	unsolvedButton: {
		color: theme.palette.error.medium,
	},
}));
