import { addMonths } from 'date-fns';
import { t } from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const OFFLINE_SETTLEMENT_FIELDS = [
	{
		accessor: 'payment_number',
		label: 'common.fields.payment_no',
	},
	{
		accessor: 'amount',
		label: 'common.fields.amount',
	},
	{
		accessor: 'payment_period',
		label: 'common.fields.period',
	},
];

const OFFLINE_REASON_OPTIONS = [
	{
		label:
			'bookings.preview_booking.subscription_invoice.offline_reasons.transfer_to_telgani_bank_account',
		value: 'transfer_to_telgani_bank_account',
	},
	{
		label:
			'bookings.preview_booking.subscription_invoice.offline_reasons.paid_by_telgani_link',
		value: 'paid_by_telgani_link',
	},
	{
		label:
			'bookings.preview_booking.subscription_invoice.offline_reasons.paid_through_office_rental',
		value: 'paid_through_office_rental',
	},
];

export const INPUT_FIELDS = ({ readOnly, paidAt }) => [
	{
		label: 'bookings.preview_booking.subscription_invoice.payments.paid_at',
		name: 'paid_at',
		type: FIELD_TYPES.DATE,
		md: 12,
		minDate: paidAt ? new Date(paidAt) : false,
		...(paidAt && [{ maxDate: addMonths(new Date(paidAt), 1) }]),
		disabled: readOnly,
		required: true,
	},
	{
		label: 'bookings.preview_booking.subscription_invoice.offline_reason',
		name: 'offline_reason',
		type: FIELD_TYPES.SELECT,
		md: 12,
		required: true,
		options: OFFLINE_REASON_OPTIONS,
		labelExtractor: ({ label }) => t(label),
		keyExtractor: ({ value }) => value,
	},
];
