import { useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash';

// Import helpers and utils
import myAxios from 'services/myAxiosFactory';
import { getInitialParams } from './helpers';

export const useTableWithQueryProvider = ({
	url,
	queryKey,
	columns,
	rowsPerPageOptions,
	getFormattedFilterValues = () => {},
	filtersInitialValues = {},
	cacheTime = 1000 * 60 * 5,
}) => {
	const filtersInitialValuesFormatted =
		getFormattedFilterValues(filtersInitialValues) || {};
	const initialParams = getInitialParams({
		rowsPerPageOptions,
		filtersInitialValues: filtersInitialValuesFormatted,
	});
	const [params, setParams] = useState(initialParams);

	const [searchValue, setSearchValue] = useState('');

	const axios = myAxios();

	const queryFn = async () => await axios.get(url, { params });

	const {
		data: fetchedData,
		isFetching,
		isLoading: loading,
		isError,
		refetch,
		error,
	} = useQuery([queryKey, params], queryFn, {
		refetchOnWindowFocus: false,
		cacheTime,
	});

	const data = fetchedData?.data?.data || [];
	const metadata = fetchedData?.data?.metadata || {};

	const {
		total: recordsFiltered,
		current_page: currentPage,
		per_page: perPage,
	} = metadata || {};

	const currentPageRowsCount = data.length;

	const pagesCount = Math.ceil(recordsFiltered / perPage);

	const onChangeSize = (e) =>
		setParams({ ...params, per_page: Number(e.target.value) });

	const onPaginationChange = (_e, page) =>
		setParams({ ...params, page: Number(page) });

	const delayedQuery = useCallback(
		debounce((value) => setParams({ ...params, search: value }), 400),
		[]
	);

	const handleOnSearchChange = (e) => {
		const { value } = e.target;
		setSearchValue(value);
		delayedQuery(value);
	};

	const handleDeleteSearchValue = () => {
		setSearchValue('');
		setParams({ ...params, search: '' });
	};

	const handleFiltersSubmit = (values) => {
		const formattedValues = getFormattedFilterValues(values) || values;
		setParams({ ...params, ...formattedValues });
	};

	const handleFiltersReset = (reset) => {
		reset(filtersInitialValues);
		setParams({ ...params, ...filtersInitialValuesFormatted });
	};

	return {
		handleDeleteSearchValue,
		currentPageRowsCount,
		handleOnSearchChange,
		handleFiltersSubmit,
		onPaginationChange,
		handleFiltersReset,
		recordsFiltered,
		setSearchValue,
		onChangeSize,
		searchValue,
		currentPage,
		isFetching,
		pagesCount,
		perPage,
		loading,
		isError,
		columns,
		refetch,
		params,
		error,
		data,
	};
};
