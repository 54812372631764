import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(4),
	},
	container: {
		scrollMarginTop: '150px',
	},
}));
