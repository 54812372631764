import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(2),
		textTransform: 'capitalize',
	},
	reason: {
		fontSize: 16,
		marginBottom: '48px',
		color: theme.palette.text[2],
	},
	button: {
		padding: '12px 24px',

		'& svg': {
			width: '24px',
			height: '24px',
		},
	},
	approveButton: {
		borderColor: theme.palette.background[3],
	},
}));
