// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = [
	{
		type: FIELD_TYPES.TEXT,
		name: 'description',
		label: 'bookings.office_rating.form.description',
		multiline: true,
	},
];
