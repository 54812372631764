import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

// Import utils and helpers
import { useCarAndCompany } from './useCarAndCompany';
import { useTranslations } from 'components/utilities';
import { BOOKING_OPTION_TYPES } from 'helpers';

// Import assets
import { CheckMarkIcon, DeliveryKeyIcon } from 'assets/icons';

// Import styles
import { useStyles } from './CarAndCompany.styles';

export const CarAndCompany = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { carName, companyName, officeCity, getOption, carImage } =
		useCarAndCompany();

	const renderOptions = () =>
		Object.keys(BOOKING_OPTION_TYPES).map((key) => {
			const type = BOOKING_OPTION_TYPES[key];
			const option = getOption(type);

			return (
				option && (
					<Box key={type} className={classes.options}>
						{option.type === BOOKING_OPTION_TYPES.DELIVERY ? (
							<DeliveryKeyIcon className={classes.icon} />
						) : (
							<CheckMarkIcon className={classes.icon} />
						)}
						<Typography variant="body1" className={classes.option}>
							{t(`bookings.options.${type}`)}
						</Typography>
					</Box>
				)
			);
		});

	return (
		<Grid container>
			<Grid item xs={12}>
				<Box className={classes.imageWrapper}>
					<img src={carImage} className={classes.image} alt={carName} />
				</Box>
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1" className={classes.carName}>
					{carName}
				</Typography>
			</Grid>
			<Grid item xs={12} className={classes.companyInfo}>
				<Typography variant="body1" className={classes.companyInfoText}>
					{companyName}
				</Typography>
				<Typography variant="body1" className={classes.companyInfoText}>
					{officeCity}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				{renderOptions()}
			</Grid>
			<Grid item xs={12}></Grid>
		</Grid>
	);
};
