import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'grid',
		gridTemplateColumns: '1fr auto',
		gap: '8px',
		padding: '16px',
		maxWidth: '400px',
	},
	notesContainer: {
		display: 'flex',
		alignItems: 'center',
		flexWrap: 'wrap',
		gap: '8px',
	},
	note: {
		display: 'grid',
		placeItems: 'center',
		width: 'auto',
		height: '100%',
		maxHeight: '32px',
		border: '1px solid',
		borderColor: theme.palette.background[3],
		borderRadius: '8px',
		'&:hover': {
			backgroundColor: theme.palette.background[3],
		},
	},
	squareNote: {
		display: 'grid',
		placeItems: 'center',
		width: '32px',
		height: '32px',
		border: '1px solid',
		borderColor: theme.palette.background[3],
		borderRadius: '8px',
	},
	chip: {
		display: 'grid',
		placeItems: 'center',
		color: theme.palette.text[1],
		backgroundColor: 'transparent',
	},
	transparentChip: {
		backgroundColor: 'transparent',
	},
	priceDifferenceChip: ({ isNegativeDifference }) => ({
		display: 'flex',
		height: '100%',
		width: '100%',
		borderRadius: '7px',
		backgroundColor: isNegativeDifference
			? theme.palette.error.light
			: theme.palette.primary.light,
		color: isNegativeDifference
			? theme.palette.error.main
			: theme.palette.primary.main,
	}),
	chipIcon: {
		width: '19.2px',
		height: '19.2px',
	},
	image: {
		width: '19.2px',
		height: 'auto',
	},
	redChip: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		borderRadius: '7px',
		backgroundColor: theme.palette.error.light,
	},
	redIcon: {
		color: theme.palette.error.main,
		width: '19.2px',
		height: '19.2px',
	},
	greenChip: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		height: '100%',
		borderRadius: '7px',
		backgroundColor: theme.palette.primary.light,
	},
	greenIcon: {
		color: theme.palette.primary.main,
		width: '19.2px',
		height: '19.2px',
	},
	link: {
		width: '100%',
		height: '100%',
	},
	agentIcon: {
		color: theme.palette.text[1],
	},
}));
