import { isString } from 'lodash';

export const getBase64ImageFromURL = (url) =>
	new Promise((resolve, reject) => {
		var img = new Image();
		img.setAttribute('crossOrigin', 'anonymous');

		img.onload = () => {
			var canvas = document.createElement('canvas');
			canvas.width = img.width;
			canvas.height = img.height;

			var ctx = canvas.getContext('2d');
			ctx.drawImage(img, 0, 0);

			var dataURL = canvas.toDataURL('image/png');

			resolve(dataURL);
		};

		img.onerror = (error) => {
			reject(error);
		};

		img.src = url;
	});

export const convertPlate = (plate) => {
	const {
		plate_first_char,
		plate_second_char,
		plate_third_char,
		plate_number,
		first_char,
		second_char,
		third_char,
	} = plate || {};

	const isPlateString = isString(plate);

	return {
		fullPlateFromApi: `${plate_first_char}${plate_second_char}${plate_third_char}${plate_number}`,
		fullPlateFromVehicleDetails: first_char
			? `${first_char}${second_char}${third_char}${plate_number}`
			: '',
		addPlatePayload: isPlateString && {
			plate_number: plate.slice(3),
			plate_first_char: plate.charAt(0),
			plate_second_char: plate.charAt(1),
			plate_third_char: plate.charAt(2),
		},
		plateBodyForGetPlateData: isPlateString && {
			plate_number: plate.slice(3),
			first_char: plate.charAt(0),
			second_char: plate.charAt(1),
			third_char: plate.charAt(2),
		},
		plateBodyForGetPlateDataReversed: isPlateString && {
			plate_number: plate.slice(3),
			first_char: plate.charAt(2),
			second_char: plate.charAt(1),
			third_char: plate.charAt(0),
		},
	};
};
