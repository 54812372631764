import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

// Import assets
import { FlagIcon } from 'assets/icons';

// Import utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import context
import { useUrgentFormsProvider } from '../../../../context';

// Import components
import { ActionConfirmDialog, SuccessDialog } from 'components/elements';

// Import styles
import { useStyles } from '../../AssignFields.styles';

export const FormContent = () => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const {
		toggleAssignConfirm,
		isSomeReviewStatus,
		isAssignConfirmOpen,
		assignLoading,
		isSuccessAssign,
		toggleSuccessAssign,
		handleCloseSuccess,
	} = useUrgentFormsProvider();

	const { ASSIGN_URGENT_FIELDS, isFormVisible, toggleForm, isSubmitDisabled } =
		useFormContent();

	const classes = useStyles();

	return (
		<>
			{!isFormVisible ? (
				<Box>
					<Button
						startIcon={<FlagIcon color="inherit" />}
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						onClick={toggleForm}
						disabled={isSomeReviewStatus}
					>
						{t('common.buttons.assign_urgent')}
					</Button>
					{isSomeReviewStatus && (
						<Typography variant="body1" className={classes.runningRequest}>
							{t('bookings.urgent_tab.urgent_request_running')}
						</Typography>
					)}
				</Box>
			) : (
				<Grid container className={classes.formContainer}>
					<Grid item xs={8}>
						{ASSIGN_URGENT_FIELDS.map(renderFields)}
						<Grid
							item
							xs={12}
							container
							spacing={2}
							justifyContent="flex-end"
							alignItems="center"
							className={classes.buttonsContainer}
						>
							<Grid item>
								<Button color="default" type="button" onClick={toggleForm}>
									{t('common.buttons.cancel')}
								</Button>
							</Grid>
							<Grid item>
								<Button
									color="primary"
									variant="contained"
									onClick={toggleAssignConfirm}
									disabled={isSubmitDisabled}
									className={classes.submitButton}
									type="button"
								>
									{t('common.buttons.submit')}
								</Button>
							</Grid>
						</Grid>
					</Grid>

					<ActionConfirmDialog
						isOpen={isAssignConfirmOpen}
						toggle={toggleAssignConfirm}
						title={t('bookings.urgent_tab.is_urgent')}
						description={t('bookings.urgent_tab.assign_if_urgent')}
						formId="assign-urgent-form"
						actionButtonText="common.buttons.assign"
						isLoading={assignLoading}
						startIcon={<FlagIcon color="primary" />}
					/>

					<SuccessDialog
						isOpen={isSuccessAssign}
						toggle={toggleSuccessAssign}
						handleOk={() => handleCloseSuccess(toggleSuccessAssign)}
						title={t('bookings.urgent_tab.urgent_assigned')}
						description={t('bookings.urgent_tab.success_assign_urgent_message')}
					/>
				</Grid>
			)}
		</>
	);
};
