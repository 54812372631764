import React, { useState } from 'react';
import { object, func } from 'prop-types';

// eslint-disable-next-line react/display-name
export const Row = React.forwardRef((props, ref) => {
	const [isHovered, setIsHovered] = useState(false);

	const onMouseEnter = () => setIsHovered(true);
	const onMouseLeave = () => setIsHovered(false);

	const { tableBodyRow: TableBodyRow, row, columnsAccessor } = props;
	return (
		<TableBodyRow
			columnsAccessor={columnsAccessor}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			isHovered={isHovered}
			ref={ref}
			key={row.id}
			row={row}
		/>
	);
});

Row.propTypes = {
	columnsAccessor: object,
	tableBodyRow: func,
	row: object,
};
