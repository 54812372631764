import services from 'services/services';

// Import store
import { submitSliderFormUrl } from 'store/paths';

export const submitSliderForm = async ({
	callbackSuccess,
	errorCallback,
	values,
	itemId,
}) => {
	try {
		await services.post(submitSliderFormUrl(itemId), values);
		callbackSuccess();
	} catch (error) {
		errorCallback(error);
	}
};
