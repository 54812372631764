export const COLUMNS_ACCESSORS = {
	IMAGE: 'image',
	ACTIONS: 'actions',
	CREATED_AT: 'created_at',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'sliders.table.headers.image',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: true,
	},
	{
		Header: 'sliders.table.headers.name',
		accessor: 'name',
	},
	{
		Header: 'sliders.table.headers.position',
		accessor: 'position',
		disableSortBy: true,
	},
	{
		Header: 'sliders.table.headers.locale',
		accessor: 'locale',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
