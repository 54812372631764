import React from 'react';
import { Box, Button, Dialog, Typography } from '@material-ui/core';
import { bool, func } from 'prop-types';

// Import utils and helpers
import { getDialogSubtitle, getDialogTitle } from './helpers';
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress, ClosableDialogTitle } from 'components/elements';

// Import styles
import { useStyles } from './ConfirmationDialog.styles';

// Import assets
import { CheckMarkDoneIcon } from 'assets/icons';

export const ConfirmationDialog = ({
	isOpen,
	toggle,
	submitAction,
	closeAction,
	isSuccess,
	isReset,
	isLoading,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const transKey = 'bookings.preview_booking.ratings';
	const dialogTitle = getDialogTitle({ isSuccess, transKey, isReset });
	const dialogSubtitle = getDialogSubtitle({ isSuccess, transKey, isReset });
	const buttonTitle = isReset
		? `${transKey}.reset_rating`
		: `${transKey}.submit_rating`;

	return (
		<Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
			<ClosableDialogTitle
				titleClassName={isSuccess ? classes.successDialogTitle : null}
				icon={isSuccess ? <CheckMarkDoneIcon className={classes.icon} /> : null}
				title={t(dialogTitle)}
				onClose={toggle}
			/>
			<Box className={classes.dialogContent}>
				<Typography align="left" className={classes.subtitle}>
					{t(dialogSubtitle)}
				</Typography>
				<Box className={classes.buttonsWrapper}>
					{isSuccess ? (
						<Button
							onClick={closeAction}
							variant="contained"
							color="primary"
							size="large"
						>
							{t('common.buttons.ok')}
						</Button>
					) : (
						<>
							<Button
								className={classes.cancelButton}
								onClick={toggle}
								color="primary"
								size="large"
							>
								{t('common.buttons.cancel')}
							</Button>
							<ButtonProgress
								className={isReset ? classes.resetButton : classes.submitButton}
								onClick={submitAction}
								isLoading={isLoading}
								disabled={isLoading}
								color="primary"
								type="submit"
								size="large"
							>
								{t(buttonTitle)}
							</ButtonProgress>
						</>
					)}
				</Box>
			</Box>
		</Dialog>
	);
};

ConfirmationDialog.propTypes = {
	submitAction: func.isRequired,
	closeAction: func.isRequired,
	toggle: func.isRequired,
	isLoading: bool.isRequired,
	isOpen: bool.isRequired,
	isSuccess: bool,
	isReset: bool,
};
