import React from 'react';
import { bool, func, object } from 'prop-types';
import { Box, Dialog, DialogContent } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import components
import { ClosableDialogTitle } from 'components/elements/organisms/Booking';
import { BookingInfoSection, FormContent } from './components';

// Import styles
import { useStyles } from './ExtendBookingDialog.styles';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { handleSubmit } from './helpers';
import { useTableContextProvider } from 'components/context';

export const ExtendBookingDialog = ({ isOpen, toggle, booking }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { fetchData } = useTableContextProvider();
	const successCallback = () => {
		fetchData();
		toggle();
	};
	return (
		<Dialog open={isOpen} onClose={toggle} className={classes.dialog}>
			<ClosableDialogTitle
				onClose={toggle}
				title={t('common.booking_details')}
				titleClassName={classes.title}
			/>
			<DialogContent>
				<BookingInfoSection booking={booking} />
				<Form
					initialValues={null}
					onSubmit={(values) =>
						handleSubmit({
							values,
							bookingUuid: booking.uuid,
							successCallback,
						})
					}
					render={({ handleSubmit }) => (
						<Box component="form" onSubmit={handleSubmit} id="extend-form">
							<FormContent toggle={toggle} booking={booking} />
						</Box>
					)}
				/>
			</DialogContent>
		</Dialog>
	);
};

ExtendBookingDialog.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired,
	booking: object.isRequired,
};
