// Import utils and helpers
import { DELIVERY_TYPES, getDeliveryAvailabilityStates } from 'helpers';
import { useBookingPreview, useDriverUsersListQuery } from 'queries';

export const useSelects = ({ bookingUuid, open }) => {
	const { data: bookingPreview, isFetching: isBookingPreviewFetching } =
		useBookingPreview(bookingUuid);

	const { pickupDeliveryAvailableAndNotAssigned } =
		getDeliveryAvailabilityStates(bookingPreview?.delivery);

	const { data: pickupDeliveryDrivers, isFetching: isPickupDeliveryFetching } =
		useDriverUsersListQuery({
			enabled: pickupDeliveryAvailableAndNotAssigned && open,
			deliveryType: DELIVERY_TYPES.PICK_UP_DELIVERY,
			externalKey: DELIVERY_TYPES.PICK_UP_DELIVERY,
			bookingUuid,
		});

	const selects = [
		{
			isVisible: pickupDeliveryAvailableAndNotAssigned,
			deliveryType: DELIVERY_TYPES.PICK_UP_DELIVERY,
			drivers: pickupDeliveryDrivers,
		},
	];

	const isFetching = isPickupDeliveryFetching || isBookingPreviewFetching;

	return { selects, isFetching };
};
