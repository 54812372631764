import React from 'react';
import { bool, func } from 'prop-types';
import {
	DialogTitle,
	DialogContent,
	DialogActions,
	CircularProgress,
	Box,
	Divider,
} from '@material-ui/core';

// Importt assets
import { LargeSearchBook } from 'assets/icons';

// Import components
import { Dialog, Button, Typography } from 'components/elements';
import { CarFilters, PaginatedCars } from './components';

// Import styles
import { useStyles } from './CarSelectionDialog.styles';

// Import utils and helpers
import { useCarSelectionDialogProvider } from '../../context';
import { useTranslations } from 'components/utilities';

export const CarSelectionDialog = ({ open, toggle }) => {
	const classes = useStyles();
	const {
		cars,
		isFetching,
		noCars,
		handleDialogCancel,
		isSaveDisabled,
		handleDialogSave,
	} = useCarSelectionDialogProvider();

	const { t } = useTranslations();

	return (
		<Dialog open={open} onClose={toggle} className={classes.dialog}>
			<DialogTitle>
				<Typography className={classes.dialogTitle}>
					{t('bookings.create_booking.select_car')}
				</Typography>
				<Divider className={classes.divider} />
			</DialogTitle>

			<DialogContent>
				<Typography className={classes.contentTitle}>
					{t('bookings.create_booking.available_cars')}{' '}
					<Typography component="span" className={classes.availableCars}>
						({cars.length} {t('bookings.create_booking.cars')})
					</Typography>
				</Typography>

				<CarFilters />

				{isFetching && <CircularProgress />}

				{!isFetching && !noCars && <PaginatedCars cars={cars} />}

				{!isFetching && noCars && (
					<Box className={classes.emptyIconContainer}>
						<LargeSearchBook className={classes.emptyIcon} />
					</Box>
				)}
			</DialogContent>
			<DialogActions>
				<Button
					text={t('common.buttons.cancel')}
					type="dark_text"
					onClick={() => {
						handleDialogCancel();
						toggle();
					}}
				/>
				<Button
					text={t('common.buttons.save')}
					onClick={() => {
						handleDialogSave();
						toggle();
					}}
					disabled={isSaveDisabled}
				/>
			</DialogActions>
		</Dialog>
	);
};

CarSelectionDialog.propTypes = {
	open: bool.isRequired,
	toggle: func.isRequired,
};
