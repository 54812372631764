import React from 'react';
import { InputAdornment } from '@material-ui/core';

// Import helpers
import {
	BOOKING_COMPENSATION_METHODS,
	COMPENSATION_REASONS,
	FIELD_TYPES,
	POPULAR_BANKS_LIST,
} from 'helpers';

export const COMPENSATION_FIELDS = ({
	compensationAmountMax,
	t,
	selectedMethod,
	isEdit,
}) => {
	const isBank = selectedMethod === BOOKING_COMPENSATION_METHODS.BANK;

	return [
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.compensation_reason',
			name: 'reason',
			options: COMPENSATION_REASONS,
			keyExtractor: (name) => name,
			labelExtractor: (name) => t(`bookings.compensation_tab.reasons.${name}`),
			isHidden: isEdit,
		},
		{
			type: FIELD_TYPES.NUMBER,
			label: 'common.fields.compensation_amount',
			name: 'compensation_amount',
			step: 0.01,
			min: 0.01,
			max: compensationAmountMax,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.method',
			name: 'method',
			required: true,
			options: Object.values(BOOKING_COMPENSATION_METHODS),
			keyExtractor: (value) => value,
			labelExtractor: (value) => t(`bookings.compensation_methods.${value}`),
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.bank',
			name: 'bank_name',
			options: POPULAR_BANKS_LIST,
			keyExtractor: (value) => value,
			labelExtractor: (value) => value,
			isHidden: !isBank,
		},
		{
			type: FIELD_TYPES.TEXT,
			label: 'common.fields.iban',
			name: 'iban',
			startAdornment: <InputAdornment position="start">SA</InputAdornment>,
			isHidden: !isBank,
		},
		{
			type: FIELD_TYPES.IMAGE_FILE,
			label: 'common.fields.contract',
			name: 'contract_file.file_path',
			fileNameField: 'contract_file.file_name',
			previewFileState: 'contract_file',
			size: 'large',
			uploadButtonWidth: 12,
		},
	];
};
