import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AttachmentIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M9.25305 5.58331L5.59572 9.24065C5.04505 9.79131 5.04505 10.6846 5.59572 11.2353V11.2353C6.14639 11.786 7.03972 11.786 7.59039 11.2353L12.4117 6.41398C13.4217 5.40398 13.4217 3.76665 12.4117 2.75665V2.75665C11.4017 1.74665 9.76439 1.74665 8.75439 2.75665L3.93305 7.57798C2.46372 9.04731 2.46372 11.4286 3.93305 12.898V12.898C5.40239 14.3673 7.78372 14.3673 9.25305 12.898L12.1791 9.97198"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
