// Import helpers and utils
import { getCompensationTypeLabel } from 'helpers';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CompensationHistory.styles';

const COLUMN_ACCESSORS = {
	DATE: 'date',
};

export const useCompensationHistory = (compensations) => {
	const classes = useStyles();
	const { t } = useTranslations();

	const getCompensationFields = (compensation) => {
		const {
			compensation_author_name: checkedBy,
			compensation_date: checkedAt,
			bank_name: bankName,
			iban,
			rrn,
		} = compensation || {};

		const checkedByLabel = t('common.fields.checked_by', {
			name: checkedBy,
		});

		return [
			{
				accessor: COLUMN_ACCESSORS.DATE,
				label: checkedByLabel,
				date: checkedAt,
				labelClassName: classes.checkedByLabel,
				key: checkedByLabel,
			},
			{
				accessor: 'compensation_amount.nett',
				label: 'common.fields.amount',
			},
			{
				accessor: 'method',
				label: 'common.fields.method',
			},
			{
				accessor: 'bank_name',
				label: 'common.fields.bank_name',
				isHidden: !bankName,
			},
			{
				accessor: 'iban',
				label: 'common.fields.iban',
				isHidden: !iban,
			},
			{
				accessor: 'rrn',
				label: 'common.fields.rrn',
				isHidden: !rrn,
			},
		];
	};

	const formattedCompensations = compensations?.reduce((acc, curr) => {
		const { type } = curr || {};

		const newCompensation = {
			...curr,
			type: getCompensationTypeLabel({ type }),
			fields: getCompensationFields(curr),
		};

		return [...acc, newCompensation];
	}, []);

	return { formattedCompensations };
};
