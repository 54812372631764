export const checkIsLocationOutsideArea = (location, area) => {
	if (area.radius && area.center) {
		const distance =
			window.google.maps.geometry.spherical.computeDistanceBetween(
				new window.google.maps.LatLng(location.lat, location.lng),
				new window.google.maps.LatLng(area.center.lat, area.center.lng)
			);

		return distance > area.radius;
	}

	return false;
};

export const geocodeCoords = (coords) => {
	return new Promise((resolve) => {
		const geocoder = new window.google.maps.Geocoder();

		geocoder.geocode({ location: coords }, (result, status) => {
			if (status !== window.google.maps.GeocoderStatus.OK) {
				resolve(null);
			}

			const location = {
				placeId: result[0].place_id,
				lat: coords.lat,
				lng: coords.lng,
				formatted_address: result[0].formatted_address,
			};

			resolve(location);
		});
	});
};
