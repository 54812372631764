import React from 'react';

// Import components
import { AdditionalColumnsDialog } from 'components/elements';

// Import helpers
import { ADDITIONAL_FIELDS } from './helpers';

export const AdditionalInfo = () => (
	<AdditionalColumnsDialog
		fields={ADDITIONAL_FIELDS}
		transKey="common.fields"
	/>
);
