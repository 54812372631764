import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '18px',
		fontWeight: 600,
		color: theme.palette.text.inputLabel,
		marginBottom: '40px',
	},
	titleWithInfo: {
		fontSize: '18px',
		fontWeight: 600,
		color: theme.palette.text.inputLabel,
		marginBottom: '40px',
	},
	info: {
		fontSize: '14px',
		fontWeight: 400,
		color: theme.palette.text[2],
		marginBottom: '24px',
	},
}));
