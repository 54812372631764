import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
		padding: '24px 24px 120px',
	},
	submitBar: {
		position: 'absolute',
		bottom: 0,
		left: 0,
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		padding: '24px',
		backgroundColor: theme.palette.white,
	},
	title: {
		fontSize: '28px',
		fontWeight: 500,
		margin: '24px 0',
	},
	subtitle: {
		fontSize: '18px',
		fontWeight: 600,
	},
	loadingWrapper: {
		minHeight: 'calc(100vh - 210px)',
	},
}));
