import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const DownloadDocumentIcon = (props) => {
	return (
		<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
			<path
				d="M8.66663 14H11.3333C12.4379 14 13.3333 13.1046 13.3333 12V5.67287C13.3333 5.14244 13.1226 4.63374 12.7475 4.25867L11.0746 2.58579C10.6996 2.21072 10.1908 2 9.66042 2H4.66663C3.56206 2 2.66663 2.89543 2.66663 4V8.66667"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M13.3333 5.66667H11C10.2636 5.66667 9.66663 5.06971 9.66663 4.33333V2"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M4.66671 10.666V13.9993"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M5.99996 12.666L4.66663 13.9993"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M3.33337 12.666L4.66671 13.9993"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</SvgIcon>
	);
};
