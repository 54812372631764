// Import helpers
import { SERVICES_TYPES } from 'helpers';

const { AIRPORT, DELIVERY, PICKUP, SUBSCRIPTION, TRAIN_STATION, LEASING } =
	SERVICES_TYPES;

export const OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: AIRPORT,
		label: 'common.filters.airport',
	},
	{
		value: DELIVERY,
		label: 'common.filters.delivery',
	},
	{
		value: PICKUP,
		label: 'common.filters.pickup',
	},
	{
		value: SUBSCRIPTION,
		label: 'common.filters.subscription',
	},
	{
		value: TRAIN_STATION,
		label: 'common.filters.train_station',
	},
	{
		value: LEASING,
		label: 'common.filters.leasing',
	},
];
