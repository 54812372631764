import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

// Import components
import { TableRPCWithQuery } from 'components/utilities';
import { Invoice } from '../Invoice';

// Import helpers and utils
import { columns } from './columns';
import { useReceipts } from './useReceipts';
import { ENDPOINTS, QUERY_KEYS } from 'helpers';
import { RECEIPTS_ROWS_PER_PAGE } from './helpers';

// Import styles
import { useStyles } from './Receipts.styles';

export const Receipts = () => {
	const { invoiceUuid, isLoading, isError } = useReceipts();

	const classes = useStyles();

	if (isLoading)
		return (
			<Box className={classes.loader}>
				<CircularProgress size={28} />
			</Box>
		);

	return isError ? (
		<Invoice />
	) : (
		<TableRPCWithQuery
			url={ENDPOINTS.INVOICES.INVOICES_FOR_BOOKING({ invoiceUuid })}
			queryKey={QUERY_KEYS.INVOICES.INVOICES_FOR_BOOKING}
			title="bookings.preview_booking.tabs.receipts"
			columns={columns}
			rowsPerPageOptions={RECEIPTS_ROWS_PER_PAGE}
			titleFontSize={20}
			removeContainerPadding
			titleCustomStyles={classes.title}
			showSearch={false}
			headerContainerMinHeight="auto"
		/>
	);
};
