import React from 'react';

// Import components
import { SuccessDialog as SuccessDialogComponent } from 'components/elements';

// Import context
import { useEditBookingFormProvider } from '../../context';

// Import utils
import { useTranslations } from 'components/utilities';

export const SuccessDialog = () => {
	const { t } = useTranslations();

	const { isSuccessDialogOpen, toggleSuccessDialog, handleOkAfterSuccess } =
		useEditBookingFormProvider();

	return (
		<SuccessDialogComponent
			isOpen={isSuccessDialogOpen}
			toggle={toggleSuccessDialog}
			handleOk={handleOkAfterSuccess}
			title={t('bookings.edit_booking.car_changed')}
			description={t('bookings.edit_booking.car_changed_message')}
		/>
	);
};
