import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import store actions and selectors
import { fetchOfficeFormResources, submitOfficeForm } from 'store/actions';
import { companyManagerContextSelector } from 'store/selectors';

// Import utilities
import { FormRPC } from 'components/utilities';

// Import helpers
import { replaceCompanyOfficesUrl } from 'helpers';
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import components
import {
	BaseDetails,
	DriverLicence,
	BankInfo,
	SupplierNotes,
	OfficeHours,
	CompanyLogo,
	CommercialRecord,
	Address,
	Holidays,
	ExternalPartner,
	ExtraServices,
	CommissionPercentage,
	ServiceTypes,
} from './components';
import { DeliveryHours } from './components';

// Import styles
import useStyles from '../styles';

const Edit = () => {
	const classes = useStyles();

	const { companyUuid } = useSelector(companyManagerContextSelector);

	const goBackPath = companyUuid
		? replaceCompanyOfficesUrl(companyUuid)
		: URLS.officesUrl;

	return (
		<div className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchOfficeFormResources}
				submitFormAction={submitOfficeForm}
				isCardLayout={false}
				goBackPath={goBackPath}
				store={(store) => store.offices.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<BaseDetails section="details" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ServiceTypes />
						</Grid>
						<Grid item lg={9} xs={12}>
							<DriverLicence section="driver_license" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<SupplierNotes section="supplier_notes" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExtraServices section="options" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<DeliveryHours section="delivery_hours" subSection="options" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<BankInfo section="bank" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CompanyLogo section="logo" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CommercialRecord section="commercial_record" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Address section="location" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<OfficeHours section="office_hours" subSection="options" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<Holidays section="holidays" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<CommissionPercentage section="commission" />
						</Grid>
						<Grid item lg={9} xs={12}>
							<ExternalPartner section="external" />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</div>
	);
};

export default Edit;
