import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CheckMarkFilledIcon = (props) => (
	<SvgIcon width="25" height="24" viewBox="0 0 25 24" fill="none" {...props}>
		<rect x="0.75" width="24" height="24" rx="12" fill="currentColor" />
		<path
			d="M10.5898 13.2L7.7098 10.32L5.5498 12.48L10.5898 17.52L19.9498 8.16L17.7898 6L10.5898 13.2Z"
			fill="white"
		/>
	</SvgIcon>
);
