import { useFormState } from 'react-final-form';
import moment from 'moment';

// Import utils and helpers
import { DAY_MONTH_YEAR_DATE_FORMAT, FIELD_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';

export const useConfirmationDialog = ({ surchargePrice }) => {
	const { t } = useTranslations();
	const {
		values: { date },
		submitting,
	} = useFormState();

	const dateStr = date ? moment(date).format(DAY_MONTH_YEAR_DATE_FORMAT) : '';
	const amount = t('common.fields.sr', { value: surchargePrice });

	const CHECKBOX_FIELD = {
		type: FIELD_TYPES.CHECKBOX,
		name: 'checkbox',
		label: t('common.buttons.send_payment_link'),
		disabled: true,
		checked: true,
	};

	const info = [
		{ text: t('return_page.the_new_drop_off_date_is'), value: dateStr },
		{ text: t('return_page.the_new_additional_amount_is'), value: amount },
	];
	return { info, CHECKBOX_FIELD, submitting };
};
