import React from 'react';
import { array } from 'prop-types';
import { Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';

// Import components
import { Grid } from 'components/elements';
import { CarCard } from '../../../CarCard';

// Import helpers
import { usePaginatedCars } from './usePaginatedCars';

// Import styles
import { useStyles } from './PaginatedCars.styles';

export const PaginatedCars = ({ cars }) => {
	const { currentPage, CurrentPageCars, numberOfPages, onPaginationChange } =
		usePaginatedCars(cars);

	const classes = useStyles();

	return (
		<Box>
			<Grid container spacing={3}>
				{CurrentPageCars.map((car) => (
					<Grid item md={4} key={car.id}>
						<CarCard car={car} />
					</Grid>
				))}
			</Grid>
			<Box className={classes.paginationContainer}>
				<Pagination
					count={numberOfPages}
					page={currentPage}
					onChange={onPaginationChange}
				/>
			</Box>
		</Box>
	);
};

PaginatedCars.propTypes = {
	cars: array.isRequired,
};
