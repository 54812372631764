import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useAlternativeCars = (uuid) => {
	const { data, isLoading, isError } = useQuery(
		QUERY_KEYS.CARS.ALTERNATIVES(uuid),
		async () => await services.get(ENDPOINTS.CARS.ALTERNATIVE_CARS({ uuid })),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isLoading, isError };
};
