import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import PerfectScrollbar from 'react-perfect-scrollbar';

// Import components
import { CollapsableCard } from 'components/elements';
import { AddComment, CommentsContent } from './components';

// Import utils
import { useComments } from './useComments';

// Import styles
import { useStyles } from './Comments.styles';
import 'react-perfect-scrollbar/dist/css/styles.css';

export const Comments = () => {
	const classes = useStyles();

	const { commentsSections, noCommentsMessage } = useComments();

	return (
		<Grid container spacing={2} className={classes.container}>
			{commentsSections.map(
				({ title, comments, isEmpty, type, isVisible = true }) => (
					<Grid item xs={12} key={title}>
						{isVisible ? (
							<>
								<CollapsableCard
									initialOpen={true}
									title={title}
									titleClassName={classes.title}
									headerClassName={classes.header}
									contentClassName={classes.content}
									toggleBtnWithoutText
									withoutContentDivider
									disableToggleBtnRipple
									contentSpacing={0}
									turnTabletSizeStyleOff
									cardClassName={classes.card}
								>
									<PerfectScrollbar className={classes.scrollContainer}>
										{isEmpty ? (
											<Typography
												variant="body1"
												className={classes.noComments}
											>
												{noCommentsMessage}
											</Typography>
										) : (
											<>
												<CommentsContent comments={comments} />
											</>
										)}
									</PerfectScrollbar>
								</CollapsableCard>
								<AddComment type={type} />
								<Divider />
							</>
						) : (
							<></>
						)}
					</Grid>
				)
			)}
		</Grid>
	);
};
