import React from 'react';
import { any, bool, func, object } from 'prop-types';

// Import components
import { Cell } from './components';
import { TableRow } from 'components/elements';

export const TableBodyRow = (props) => {
	const { row, isHovered } = props;

	return (
		<TableRow {...props}>
			{row.cells.map((cell) => (
				<Cell key={cell.column.id} cell={cell} isHovered={isHovered} />
			))}
		</TableRow>
	);
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	columnsAccessor: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
