import React from 'react';
import { string } from 'prop-types';
import { Box } from '@material-ui/core';

// Import styles
import { useStyles } from './LinkPreview.styles';

export const LinkPreview = ({ link, label }) => {
	const classes = useStyles();
	return (
		<Box
			component="a"
			target="_blank"
			rel="noopener noreferrer"
			href={link}
			className={classes.link}
		>
			{label}
		</Box>
	);
};

LinkPreview.propTypes = {
	link: string.isRequired,
	label: string.isRequired,
};
