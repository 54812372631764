import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3),
		backgroundColor: theme.palette.white,
		justifyContent: 'flex-end',
		position: 'fixed',
		bottom: 0,
		left: 0,
		right: 0,
		width: `calc(100% - 300px)`,
		border: `1px solid ${theme.palette.background[5]}`,
	},
}));
