import React from 'react';
import { bool, func, object } from 'prop-types';
import { Dialog, DialogContent, Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

// Import components
import { DialogInstruction, DownloadFileButton } from './components';
import { UploadFileButton } from 'components/elements';

// Import store
import { downloadCarPrices } from 'store/actions';
import { uploadCarPricesUrl } from 'store/paths';

export const EditPrices = ({ toggle, isOpen, cell }) => {
	const { id } = useParams();

	const companyUuid = cell ? cell.row.original.uuid : id;

	return (
		<Dialog open={isOpen} onClose={toggle} maxWidth="lg" fullWidth>
			<DialogContent>
				<Grid container spacing={4}>
					<Grid item xs={12}>
						<DialogInstruction />
					</Grid>
					<Grid item xs={12}></Grid>
					<Grid item xs={12}>
						<Grid container justifyContent="flex-end" spacing={2}>
							<Grid item>
								<DownloadFileButton
									fileName="car_prices"
									fetchAction={(fileFormat) =>
										downloadCarPrices({ companyUuid, fileFormat })
									}
								/>
							</Grid>
							<Grid item>
								<UploadFileButton uploadUrl={uploadCarPricesUrl(companyUuid)} />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

EditPrices.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	cell: object,
};
