import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.white,
		color: theme.palette.text[1],
		padding: '10px 20px',
		'&.MuiButton-outlined': {
			borderColor: theme.palette.background[5],
		},
	},
	menuList: {
		'& .MuiPaper-root': {
			minWidth: '248px',
			boxShadow: '0px 4px 8px rgba(38, 50, 56, 0.16)',
			padding: theme.spacing(2),
			borderRadius: '12px',
			marginTop: '8px',

			'& .MuiList-root': {
				padding: '0',
			},
		},
	},
	menuItem: {
		color: theme.palette.text[2],
		display: 'flex',
		alignItems: 'center',
		gap: '12px',
		fontSize: '14px',
		fontWeight: '600',
	},
}));
