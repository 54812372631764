import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	formControl: {
		position: 'relative',
		minWidth: 80,
		width: '100%',
		overflow: 'hidden',
	},
	labels: {
		display: 'flex',
		flexWrap: 'wrap',
		gap: 4,
	},
	outlinedInput: {
		overflow: 'hidden',
	},
	openOutlinedInput: {
		borderBottomRightRadius: 0,
		borderBottomLeftRadius: 0,
	},
	searchBox: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		marginBottom: '32px',
	},
	deselectButton: {
		justifyContent: 'flex-start',
	},
}));
