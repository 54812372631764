import i18n from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const DISCOUNT_SELECT_OPTIONS = [5, 10, 20, 30, 50];

export const MAX_AMOUNT_SELECT_OPTIONS = [50, 100, 150, 200];

export const DISCOUNT_FIELDS = [
	{
		type: FIELD_TYPES.SELECT,
		label: 'campaigns.form.gratification.discount',
		name: 'gratification.discount',
		options: DISCOUNT_SELECT_OPTIONS,
		keyExtractor: (value) => value,
		labelExtractor: (value) => `${value} %`,
		md: 4,
	},
	{
		type: FIELD_TYPES.SELECT,
		label: 'campaigns.form.gratification.max_amount',
		name: 'gratification.max_amount_nett_price',
		options: MAX_AMOUNT_SELECT_OPTIONS,
		keyExtractor: (value) => value,
		labelExtractor: (value) => `${value} ${i18n.t('currency.sar')}`,
		md: 4,
	},
];

const pointsLabel = 'campaigns.form.gratification.points';

export const POINTS_FIELDS = ({ t }) => [
	{
		type: FIELD_TYPES.NUMBER,
		label: pointsLabel,
		name: 'gratification.point',
		endAdornment: t(pointsLabel),
		required: true,
		min: 0.01,
		step: 0.01,
		md: 4,
	},
];

export const TYPES_FIELDS = ({ options }) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'campaigns.form.gratification.type',
		name: 'gratification.type',
		options: options,
		keyExtractor: (value) => value,
		labelExtractor: (type) => i18n.t(`campaigns.gratification_types.${type}`),
		md: 4,
	},
];
