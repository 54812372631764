import React from 'react';

// Import store
import { fetchCampaignsExportData } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { TableExport, TableExportPopup } from 'components/elements';
import { TableExportProvider } from 'components/context';
import { ExportFilters } from '../../components';

export const Export = () => (
	<TableExportProvider
		fetchDataTableAction={fetchCampaignsExportData}
		columns={columns}
	>
		<TableExportPopup />
		<TableExport renderFilters={() => <ExportFilters />} />
	</TableExportProvider>
);
