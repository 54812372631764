import React from 'react';
import { Typography } from '@material-ui/core';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './TopBar.styles';

export const TopBar = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Typography variant="h5" className={classes.title}>
			{t('bookings.edit_booking.title')}
		</Typography>
	);
};
