import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: '48px 24px',
	},
	title: {
		fontSize: '28px',
		fontWeight: 500,
		color: theme.palette.text[4],
	},
}));
