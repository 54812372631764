import React from 'react';
import clsx from 'clsx';
import { Button, Grid } from '@material-ui/core';

// Import assets
import { CheckmarkIcon } from 'assets/icons';
import CloseIcon from '@material-ui/icons/Close';

// Import utilities and context
import { useTranslations } from 'components/utilities';
import { useBookingStatusActionsProvider } from 'components/context';

// Import components
import { ExtensionInfoDialog } from 'views/Bookings/BookingDetails/components';
import { NotificationAlert, ButtonProgress } from 'components/elements';
import { ButtonDanger } from './components';
import { CancelAndRejectBookingForm } from '../CancelAndRejectBookingForm';
import { AssignDriverDialog } from '../AssignDriverDialog';

// Import styles
import useStyles from './styles';

export const BookingStatusActionsContent = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		error,
		canCancel,
		canAccept,
		canReject,
		isRejection,
		isCancellation,
		canApproveCancellation,
		clearError,
		handleClose,
		handleCancel,
		handleAccept,
		handleReject,
		handleChange,
		handleApproveCancellation,
		handleAcceptExtension,
		handleRejectExtension,
		isRejectExtensionLoading,
		isAcceptExtensionLoading,
		isExtensionStatusPending,
		isAssignDriverDialogOpen,
		isCorporateCoordinator,
		toggleAssignDriverDialog,
		isButtonDisabled,
		isExtensionDialog,
		uuid,
		booking,
	} = useBookingStatusActionsProvider();

	return (
		<>
			{error && <NotificationAlert open onClose={clearError} message={error} />}
			{isRejection && !isCorporateCoordinator && (
				<CancelAndRejectBookingForm
					booking={booking}
					onChange={handleChange}
					open={isRejection}
					onClose={handleClose}
					dialogTitle="bookings.reject_booking_form.title"
					isRejection
				/>
			)}
			{isCancellation && !isCorporateCoordinator && (
				<CancelAndRejectBookingForm
					booking={booking}
					onChange={handleChange}
					open={isCancellation}
					onClose={handleClose}
					dialogTitle="bookings.cancel_booking_form.title"
				/>
			)}
			<Grid container spacing={1} alignItems="center" justifyContent="center">
				{canCancel && (
					<Grid item xs={12}>
						<ButtonDanger
							variant="contained"
							size="small"
							onClick={handleCancel}
							className={classes.button}
						>
							{t('bookings.state_actions.cancel')}
						</ButtonDanger>
					</Grid>
				)}
				{canAccept && !isCorporateCoordinator && (
					<Grid item xs={12}>
						<Button
							startIcon={<CheckmarkIcon />}
							variant="contained"
							color="primary"
							size="small"
							onClick={handleAccept}
							className={classes.button}
						>
							{t('bookings.state_actions.accept')}
						</Button>
					</Grid>
				)}
				{canReject && !isCorporateCoordinator && (
					<Grid item xs={12}>
						<Button
							startIcon={<CloseIcon />}
							variant="outlined"
							color="inherit"
							size="small"
							onClick={handleReject}
							className={clsx([classes.button, classes.rejected])}
						>
							{t('bookings.state_actions.reject')}
						</Button>
					</Grid>
				)}
				{canApproveCancellation && !isCorporateCoordinator && (
					<Grid item xs={12}>
						<Button
							variant="contained"
							color="primary"
							size="small"
							onClick={handleApproveCancellation}
							className={classes.button}
						>
							{t('bookings.state_actions.approve_cancellation')}
						</Button>
					</Grid>
				)}

				{isExtensionStatusPending && !isCorporateCoordinator && (
					<Grid container item spacing={1}>
						<Grid item xs={12}>
							<ButtonProgress
								variant="contained"
								color="primary"
								size="small"
								className={classes.button}
								isLoading={isAcceptExtensionLoading}
								disabled={isButtonDisabled}
								onClick={handleAcceptExtension}
								circularProgressSize={24}
							>
								{t('bookings.state_actions.accept_extension')}
							</ButtonProgress>
						</Grid>
						<Grid item xs={12}>
							<ButtonProgress
								startIcon={<CloseIcon />}
								variant="outlined"
								color="inherit"
								size="small"
								className={clsx([classes.button, classes.rejected])}
								isLoading={isRejectExtensionLoading}
								disabled={isButtonDisabled}
								onClick={handleRejectExtension}
								circularProgressSize={24}
							>
								{t('bookings.state_actions.reject_extension')}
							</ButtonProgress>
						</Grid>
					</Grid>
				)}
				{isExtensionDialog && (
					<Grid container item xs={12} justifyContent="flex-start">
						<Grid item>
							<ExtensionInfoDialog />
						</Grid>
					</Grid>
				)}
				<AssignDriverDialog
					open={isAssignDriverDialogOpen}
					toggle={toggleAssignDriverDialog}
					bookingUuid={uuid}
				/>
			</Grid>
		</>
	);
};
