import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const StoreIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<svg width="20" height="20" viewBox="0 0 20 20" fill="none">
			<path
				d="M2.5 17.5007H17.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M2.5 17.5007H17.5"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M3.33337 17.4994L3.33346 9.12891"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M3.33337 17.4994L3.33346 9.12891"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M16.7278 17.4996L16.7279 9.11719"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M16.7278 17.4996L16.7279 9.11719"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.1035 6.23138C18.3494 6.69455 18.3983 7.23728 18.2391 7.73694C17.9637 8.60377 17.1503 9.18563 16.241 9.16619C15.3316 9.14675 14.5438 8.53065 14.3057 7.65284C14.2906 7.60186 14.2438 7.56687 14.1906 7.56687C14.1374 7.56687 14.0906 7.60186 14.0755 7.65284C13.8324 8.54629 13.021 9.16626 12.0951 9.16616C11.1692 9.16606 10.358 8.54592 10.115 7.65242C10.1 7.60141 10.0531 7.5664 9.99996 7.5664C9.94677 7.5664 9.89993 7.60141 9.88487 7.65242C9.64182 8.54592 8.83052 9.16598 7.90455 9.16595C6.97858 9.16592 6.16733 8.5458 5.92434 7.65228C5.90923 7.6013 5.8624 7.56634 5.80924 7.56634C5.75607 7.56634 5.70924 7.6013 5.69413 7.65228C5.45601 8.52999 4.66823 9.14601 3.759 9.16548C2.84977 9.18495 2.03633 8.60322 1.76086 7.73651C1.60158 7.23677 1.65046 6.69392 1.89646 6.23067L3.31873 3.41518C3.60225 2.85391 4.17754 2.5 4.80636 2.5H15.1933C15.8221 2.5 16.3974 2.85392 16.6809 3.41518L18.1035 6.23138Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.1035 6.23138C18.3494 6.69455 18.3983 7.23728 18.2391 7.73694C17.9637 8.60377 17.1503 9.18563 16.241 9.16619C15.3316 9.14675 14.5438 8.53065 14.3057 7.65284C14.2906 7.60186 14.2438 7.56687 14.1906 7.56687C14.1374 7.56687 14.0906 7.60186 14.0755 7.65284C13.8324 8.54629 13.021 9.16626 12.0951 9.16616C11.1692 9.16606 10.358 8.54592 10.115 7.65242C10.1 7.60141 10.0531 7.5664 9.99996 7.5664C9.94677 7.5664 9.89993 7.60141 9.88487 7.65242C9.64182 8.54592 8.83052 9.16598 7.90455 9.16595C6.97858 9.16592 6.16733 8.5458 5.92434 7.65228C5.90923 7.6013 5.8624 7.56634 5.80924 7.56634C5.75607 7.56634 5.70924 7.6013 5.69413 7.65228C5.45601 8.52999 4.66823 9.14601 3.759 9.16548C2.84977 9.18495 2.03633 8.60322 1.76086 7.73651C1.60158 7.23677 1.65046 6.69392 1.89646 6.23067L3.31873 3.41518C3.60225 2.85391 4.17754 2.5 4.80636 2.5H15.1933C15.8221 2.5 16.3974 2.85392 16.6809 3.41518L18.1035 6.23138Z"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M11.6667 17.4996V9.11719"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M11.6667 17.4996V9.11719"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M11.6667 13.3327H3.33337"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
			<path
				d="M11.6667 13.3327H3.33337"
				stroke="currentColor"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
				fill="none"
			/>
		</svg>
	</SvgIcon>
);
