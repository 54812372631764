import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils nad helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { replaceOfficeDetailsUrl } from 'helpers';
import { activeList } from './helpers';
import { useCell } from './useCell';

// Import components
import {
	NewActionsMenuTableCell,
	AvailableCarTableCell,
	CommonStatusTableCell,
	EditTableCell,
} from 'components/elements';

const { NAME, ACTIONS, AVAILABLE, BOOKINGS, ACCEPTED, STATUS } =
	COLUMNS_ACCESSORS;

export const Cell = ({ cell, isHovered }) => {
	const { actionMenuFields, editViewUrl } = useCell(cell);

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			uuid,
			active,
			name,
			regular_stats: regularStats,
			leasing_stats: leasingStats,
		} = cell.row.original;

		switch (cell.column.id) {
			case NAME:
				return (
					<EditTableCell
						key={cell.column.id}
						cell={cell}
						isIcon={false}
						originalText={name}
						editLinkPath={replaceOfficeDetailsUrl(uuid)}
					/>
				);

			case AVAILABLE:
				return (
					<AvailableCarTableCell
						amount={regularStats.available}
						leasingAmount={leasingStats.available}
						key={cell.column.id}
						cell={cell}
						showIcon
					/>
				);

			case BOOKINGS:
				return (
					<AvailableCarTableCell
						amount={regularStats.taken}
						leasingAmount={leasingStats.taken}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ACCEPTED:
				return (
					<AvailableCarTableCell
						amount={regularStats.taken_accepted}
						leasingAmount={leasingStats.taken_accepted}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case STATUS:
				return (
					<CommonStatusTableCell
						currentStatus={active ? 'active' : 'inactive'}
						statusList={activeList}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						isHovered={isHovered}
						key={cell.column.id}
						cell={cell}
						extraActions={{
							editAction: true,
							editPath: editViewUrl,
						}}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
