import React from 'react';
import { useSelector } from 'react-redux';
import { bool, string } from 'prop-types';
import { Field } from 'react-final-form';

// Import components
import { ImagePreviewLabelAdapter, PreviewField } from 'components/elements';

// Import helpers
import { tokenSelector } from 'store/selectors';

export const LicenseModalField = ({
	accessor,
	filePath,
	licenseExists = false,
}) => {
	const token = useSelector(tokenSelector);

	const imgUrl = `${filePath}${token ? '?token=' + token : ''}`;

	return (
		<PreviewField label="common.fields.license_photo">
			{licenseExists && (
				<Field
					component={ImagePreviewLabelAdapter}
					imgUrl={imgUrl}
					name={accessor}
				/>
			)}
		</PreviewField>
	);
};

LicenseModalField.propTypes = {
	filePath: string.isRequired,
	accessor: string.isRequired,
	licenseExists: bool,
};
