import i18next from 'i18next';

export const columns = [
	{
		Header: 'common.fields.name',
		accessor: 'full_name',
	},
	{
		Header: 'common.fields.phone_number',
		accessor: 'phone',
	},
	{
		Header: 'common.fields.email',
		accessor: 'email',
	},
	{
		Header: 'common.fields.role',
		accessor: 'role',
	},
	{
		Header: 'common.fields.offices',
		accessor: 'office_count',
		valueExtractor: ({ office_count }) => {
			const officeCount = office_count !== null ? office_count.toString() : '0';
			return officeCount;
		},
	},
	{
		Header: 'common.fields.status',
		accessor: 'active',
		valueExtractor: ({ active }) =>
			i18next.t(`common.${active ? 'yes' : 'no'}`),
	},
];
