import React from 'react';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utils
import { useCampaignConditionsCard } from './useCampaignConditionsCard';
import { useCommonFields, useTranslations } from 'components/utilities';

export const CampaignConditionsCard = () => {
	const { t } = useTranslations();

	const { FIELDS } = useCampaignConditionsCard();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader title={t('campaigns.form.condition_card_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS.map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
