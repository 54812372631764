import React from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	Grid,
	Typography,
} from '@material-ui/core';
import { bool, func, string } from 'prop-types';

// Import components
import { ClosableDialogTitle } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import assets
import { CheckMarkDoneIcon } from 'assets/icons';

// Import styles
import { useStyles } from './SuccessDialog.styles';

export const SuccessDialog = ({
	isOpen,
	toggle,
	handleOk,
	title,
	description,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
			<ClosableDialogTitle
				title={title}
				icon={<CheckMarkDoneIcon className={classes.icon} />}
				onClose={toggle}
				titleClassName={classes.title}
			/>
			<DialogContent>
				<Typography variant="body1" className={classes.contentText}>
					{description}
				</Typography>

				<Grid
					container
					justifyContent="flex-end"
					spacing={2}
					className={classes.buttonsContainer}
				>
					<Grid item>
						<Button
							color="primary"
							variant="contained"
							className={classes.button}
							onClick={handleOk}
						>
							{t('common.buttons.ok')}
						</Button>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

SuccessDialog.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired,
	handleOk: func.isRequired,
	title: string.isRequired,
	description: string.isRequired,
};
