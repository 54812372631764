import React from 'react';
import { Box, Chip, Tooltip, Link } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/Power';

// Import utils and helpers
import {
	useTranslations,
	useNoteStates,
	useToggle,
} from 'components/utilities';
import { NOTE_SIZE_TYPE } from 'helpers';

// Import styles
import { useStyles } from './Notes.styles';

// Import assets
import { AirplaneIcon, AssignedDriverIcon, CarDeliveryIcon } from 'icons';
import {
	CarCollectionIcon,
	TimeClockInfoIcon,
	PickupCarIcon,
	PercentIcon,
	CarInfoIcon,
	WalletIcon,
	CardsIcon,
	PinIcon,
	AgentIcon,
} from 'assets/icons';
import {
	subscription,
	masterCard,
	applepay,
	elmLogo,
	mada,
	visa,
} from 'assets/images';

export const useNotes = (cell) => {
	const { on: moreNotesVisible, toggle: handleOnToggleButton } = useToggle();
	const { t } = useTranslations();

	const notes = [];

	const {
		adminPriceDifferenceTooltip,
		paymentForPartnersTooltip,
		adminPriceDifference,
		externalPartner,
		corporateName,
		extendedLabel,
		changedLabel,
		delivery,
		isDeliveryLateAssignment,
		isNegativeDifference,
		customerOrganizationLabel,
		customerOrganization,
		isCustomerLocation,
		isPriceDifference,
		isExternalPartern,
		isPickupDelivery,
		isDriverAssigned,
		isExtendedLabel,
		isDeliveryLate,
		isDropDelivery,
		isSubscription,
		isChangedLabel,
		isPaidOnline,
		isTelganiPay,
		isMasterCard,
		isExternal,
		isDiscount,
		isDelivery,
		isApplePay,
		isKiosk,
		isVisa,
		isCard,
		isVIP,
		isElm,
		isB2B,
		isAgent,
	} = useNoteStates(cell.row.original);

	const classes = useStyles({ isNegativeDifference });

	const addNote = ({ isVisible, component, sizeType }) =>
		isVisible && notes.push({ component, sizeType });

	addNote({
		isVisible: isSubscription,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.subscription')}>
				<img
					className={classes.image}
					src={subscription}
					alt={t('bookings.table.notes.subscription')}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isExternal,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.is_external')}>
				<Chip
					className={classes.chip}
					label={<PowerIcon className={classes.chipIcon} />}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isElm,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.verified_by_elm')}>
				<img className={classes.image} src={elmLogo} alt="Elm" />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isKiosk,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.kiosk')}>
				<Chip
					label={<AirplaneIcon className={classes.chipIcon} />}
					className={classes.chip}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isDiscount,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('common.icon_names.discount')}>
				<Chip
					color="primary"
					label={<PercentIcon className={classes.chipIcon} />}
					className={classes.chip}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isVIP,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Tooltip title={t('common.icon_names.vip')}>
				<Chip className={classes.transparentChip} label="VIP" />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isDelivery,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.car_delivery')}>
				<Chip
					label={<CarDeliveryIcon className={classes.chipIcon} />}
					className={classes.chip}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isPriceDifference,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Tooltip title={adminPriceDifferenceTooltip}>
				<Chip
					className={classes.priceDifferenceChip}
					label={adminPriceDifference}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isDeliveryLateAssignment,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip
				title={t(
					'bookings.preview_booking.delivery_information.icon_tooltips.late_assignment'
				)}
			>
				<Box className={classes.redChip}>
					<TimeClockInfoIcon className={classes.redIcon} />
				</Box>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isDeliveryLate,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip
				title={t(
					'bookings.preview_booking.delivery_information.icon_tooltips.late_delivery'
				)}
			>
				<Box className={classes.redChip}>
					<CarInfoIcon className={classes.redIcon} />
				</Box>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isPickupDelivery,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip
				title={t(
					'bookings.preview_booking.delivery_information.icon_tooltips.pick_up'
				)}
			>
				<Box className={classes.greenChip}>
					<PickupCarIcon className={classes.greenIcon} />
				</Box>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isDropDelivery,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip
				title={t(
					'bookings.preview_booking.delivery_information.icon_tooltips.return'
				)}
			>
				<Box className={classes.greenChip}>
					<CarCollectionIcon className={classes.greenIcon} />
				</Box>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isCustomerLocation,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip
				title={t(
					'bookings.preview_booking.delivery_information.icon_tooltips.delivery'
				)}
			>
				<Link
					href={`https://www.google.com/maps/place/${
						delivery?.latitude || delivery?.lat
					},${delivery?.longitude || delivery?.lng}`}
					target="_blank"
					rel="noopener"
					className={classes.link}
				>
					<Box className={classes.greenChip}>
						<PinIcon className={classes.greenIcon} />
					</Box>
				</Link>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isDriverAssigned,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.preview_booking.car.assign_driver')}>
				<Box className={classes.greenChip}>
					<AssignedDriverIcon className={classes.greenIcon} />
				</Box>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isB2B,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Tooltip title={corporateName}>
				<Chip className={classes.transparentChip} label={t('b2b.b2b')} />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isPaidOnline,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={paymentForPartnersTooltip}>
				<Chip
					className={classes.chip}
					label={<CardsIcon className={classes.chipIcon} />}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isCard,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.mada')}>
				<img className={classes.image} src={mada} alt="Mada" />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isTelganiPay,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.wallet')}>
				<Chip
					className={classes.chip}
					label={<WalletIcon className={classes.chipIcon} />}
				/>
			</Tooltip>
		),
	});

	addNote({
		isVisible: isApplePay,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.applepay')}>
				<img className={classes.image} src={applepay} alt="Applepay" />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isMasterCard,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.master_card')}>
				<img className={classes.image} src={masterCard} alt="MasterCard" />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isVisa,
		sizeType: NOTE_SIZE_TYPE.SQUARE,
		component: (
			<Tooltip title={t('bookings.table.notes.visa')}>
				<img className={classes.image} src={visa} alt="VISA" />
			</Tooltip>
		),
	});

	addNote({
		isVisible: isExtendedLabel,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Chip className={classes.transparentChip} label={extendedLabel} />
		),
	});

	addNote({
		isVisible: isChangedLabel,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Chip className={classes.transparentChip} label={changedLabel} />
		),
	});

	addNote({
		isVisible: isExternalPartern,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Chip className={classes.transparentChip} label={externalPartner} />
		),
	});

	addNote({
		isVisible: customerOrganization,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Chip
				className={classes.transparentChip}
				label={t(customerOrganizationLabel)}
			/>
		),
	});

	addNote({
		isVisible: isExternalPartern,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Chip className={classes.transparentChip} label={externalPartner} />
		),
	});

	addNote({
		isVisible: isAgent,
		sizeType: NOTE_SIZE_TYPE.NORMAL,
		component: (
			<Tooltip title={t('Agent')}>
				<Box>
					<AgentIcon className={classes.agentIcon} />
				</Box>
			</Tooltip>
		),
	});

	const convertedNotes = moreNotesVisible ? notes : notes.slice(0, 6);
	const isToggleButtonVisible = notes.length > 6;
	const toggleButtonTitle = moreNotesVisible
		? t('common.buttons.less')
		: t('common.buttons.more');

	return {
		notes: convertedNotes,
		isToggleButtonVisible,
		handleOnToggleButton,
		toggleButtonTitle,
	};
};
