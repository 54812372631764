import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		minHeight: '80px',
	},
	textWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '16px',
	},
	title: {
		fontSize: '28px',
		fontWeight: '500',
	},
}));
