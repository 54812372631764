import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import store
import { setCompanyContext } from 'store/actions';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useCompanyDetails } from 'queries';

export const useOffices = () => {
	const dispatch = useDispatch();
	const { id } = useParams();
	const { data: companyData } = useCompanyDetails(id, !!id);

	const {
		i18n: { language },
	} = useTranslations();

	useEffect(() => {
		setCompanyContext(dispatch, id);
	}, [dispatch, id]);

	const tableTitle =
		companyData?.details?.name?.[language] || 'nav.companies.offices';

	return {
		tableTitle,
		companyData,
	};
};
