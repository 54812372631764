import React, { useRef, useEffect } from 'react';
import { string, func, element, bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import _ from 'lodash';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { SearchInput } from 'components/elements';

export const SearchToolbar = ({
	setGlobalFilter,
	showSearchInput = true,
	globalFilter,
	filtersBar,
	searchBarPlaceholder = 'common.placeholders.booking_id_or_mobile_number',
	TableInnerToolbar,
	...rest
}) => {
	const { t } = useTranslations();
	const [query, setQuery] = React.useState(globalFilter);
	const delayedQuery = useRef(
		_.debounce((value) => setGlobalFilter(value), 400)
	).current;

	const handleOnChangeQuery = (e) => {
		e.persist();
		const value = e.target.value;
		setQuery(value);
		delayedQuery(value);
	};

	const handleClearQuery = () => {
		setQuery('');
		setGlobalFilter('');
	};

	useEffect(() => {
		setQuery(globalFilter || '');
	}, [globalFilter]);

	if (!filtersBar && !showSearchInput) return null;

	return (
		<Grid
			container
			alignItems="center"
			justifyContent="space-between"
			spacing={2}
			{...rest}
		>
			{filtersBar && <Grid item>{filtersBar}</Grid>}
			{showSearchInput && (
				<Grid item>
					<SearchInput
						setGlobalFilter={handleOnChangeQuery}
						clearGlobalFilters={handleClearQuery}
						globalFilter={query}
						placeholder={t(searchBarPlaceholder)}
					/>
				</Grid>
			)}
			{TableInnerToolbar && <Grid item>{TableInnerToolbar}</Grid>}
		</Grid>
	);
};

SearchToolbar.propTypes = {
	className: string,
	setGlobalFilter: func.isRequired,
	globalFilter: string,
	filtersBar: element,
	searchBarPlaceholder: string,
	showSearchInput: bool,
	TableInnerToolbar: element,
};
