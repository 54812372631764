import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useSupplierVehicleList = ({ vehicleMakerUuid, year }) => {
	const resultYear = year === 'all' ? null : year;

	const body = {
		vehicle_maker: {
			uuid: vehicleMakerUuid,
		},
		vehicle: {
			year: resultYear,
		},
	};

	const enabled = vehicleMakerUuid && year;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CARS.SUPPLIER_VEHICLE_LIST(JSON.stringify(body)),
		async () => await services.post(ENDPOINTS.CARS.SUPPLIER_VEHICLE_LIST, body),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
