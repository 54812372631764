import React from 'react';
import { object, string } from 'prop-types';
import { Box, Tooltip, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import clsx from 'clsx';
import { truncate } from 'lodash';

// Import utils
import { useTableContextProvider } from 'components/context';
import { useAssignmentStatus } from './useAssignmentStatus';

// Import styles
import { useStyles } from './AssignmentStatus.styles';

// Import components
import { DriverDialog } from 'components/elements';

// Import assets
import { UserWithKeyIcon } from 'assets/icons';

export const AssignmentStatus = ({ cell, deliveryType }) => {
	const {
		assignDate,
		assignTime,
		driverName,
		lateAssignment,
		hoursLeftText,
		delivery,
		uuid,
	} = useAssignmentStatus({
		deliveryType,
		cell,
	});
	const { fetchData } = useTableContextProvider();
	const classes = useStyles();

	const assignedLateClassName = lateAssignment && classes.assignedLate;

	return (
		<>
			{driverName && (
				<Box className={classes.driverWrapper}>
					<UserWithKeyIcon className={classes.icon} />
					<Tooltip title={driverName}>
						<Typography className={assignedLateClassName}>
							{truncate(driverName, { length: 20 })}
						</Typography>
					</Tooltip>
					<Form
						onSubmit={() => {}}
						render={() => (
							<DriverDialog
								deliveryType={deliveryType}
								externalBookingUuid={uuid}
								externalDelivery={delivery}
								changeButtonOutlined
								refetchCallback={fetchData}
								isIconButton
							/>
						)}
					/>
				</Box>
			)}

			{hoursLeftText && (
				<Typography className={clsx(classes.mb, classes.assignedLate)}>
					{hoursLeftText}
				</Typography>
			)}

			{assignDate && (
				<Typography className={clsx(classes.mb, assignedLateClassName)}>
					{assignDate} {assignTime}
				</Typography>
			)}
		</>
	);
};

AssignmentStatus.propTypes = {
	cell: object.isRequired,
	deliveryType: string.isRequired,
};
