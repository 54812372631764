import React from 'react';

// Import helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { TableFiltersToolbar } from 'components/elements';
import {
	NextAdditionalFilterFields,
	PrevAdditionalFilterFields,
} from './components';

export const Filters = () => (
	<TableFiltersToolbar
		initialValues={initialValues}
		formatValues={formatValues}
		prevAdditionalFilters={<PrevAdditionalFilterFields />}
		nextAdditionalFilters={<NextAdditionalFilterFields />}
	/>
);
