import moment from 'moment';
// Import helpers
import services from 'services/services';
import { ENDPOINTS, YMD_DASH_FORMAT } from 'helpers';

export const handleSubmit = ({ values, bookingUuid, successCallback }) => {
	const data = { drop_off_date: moment(values.date).format(YMD_DASH_FORMAT) };
	return services
		.post(ENDPOINTS.RETURN_PAGE.EXTEND_CONTRACT({ bookingUuid }), data)
		.then(successCallback);
};
