import React from 'react';

// Import helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { TableFiltersToolbar } from 'components/elements';
import { AdditionalFilterFields } from './components';

export const Filters = () => {
	return (
		<TableFiltersToolbar
			initialValues={initialValues}
			formatValues={formatValues}
			prevAdditionalFilters={<AdditionalFilterFields />}
		/>
	);
};
