import { useForm } from 'react-final-form';

// Import utils and helpers
import { useToggle } from 'components/utilities';

export const useDynamicField = ({ FIELDS }) => {
	const { change } = useForm();
	const { on, toggle } = useToggle();

	const onCheckboxChange = (e) => {
		if (e.target.checked) {
			toggle();
		} else {
			FIELDS.forEach(({ name }) => {
				change(name, null);
			});
			toggle();
		}
	};

	return { on, onCheckboxChange };
};
