import React from 'react';
import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { string } from 'prop-types';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './AddNewButton.styles';

export const AddNewButton = ({ text, to }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Link to={to}>
			<Button
				className={classes.button}
				color="primary"
				variant="contained"
				startIcon={<AddCircleOutlineRoundedIcon className={classes.icon} />}
			>
				{t(text)}
			</Button>
		</Link>
	);
};

AddNewButton.propTypes = {
	text: string.isRequired,
	to: string.isRequired,
};
