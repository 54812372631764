import React from 'react';
import { bool, func } from 'prop-types';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import {
	CompensationDialogTitle,
	MakeCompensationForm,
} from 'components/elements';

export const EditCompensationModal = ({
	open,
	onClose,
	onCustomSubmit,
	onInvalid = () => {},
}) => {
	const { t } = useTranslations();

	return (
		<Dialog disableEscapeKeyDown onClose={onClose} open={open} fullWidth>
			<DialogTitle>
				<CompensationDialogTitle
					title={t('waiting_customer_info.modal.title')}
					onClose={onClose}
				/>
			</DialogTitle>
			<DialogContent>
				<MakeCompensationForm
					onClose={onClose}
					onCustomSubmit={onCustomSubmit}
					onInvalid={onInvalid}
				/>
			</DialogContent>
		</Dialog>
	);
};

EditCompensationModal.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
	onCustomSubmit: func,
	onInvalid: func,
};
