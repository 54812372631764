import React from 'react';
import { object } from 'prop-types';
import {
	Stepper as MuiStepper,
	Typography,
	StepLabel,
	Step,
	Box,
	Grid,
} from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { CustomConnector, useStyles } from '../../Steps.styles';

// Import utils
import { useTranslations } from 'components/utilities';
import { useStepper } from './useStepper';

export const Stepper = ({ deliveryData }) => {
	const {
		isDeliveryCancelled,
		cancelReasonIndex,
		cancelReason,
		activeSteps,
		steps,
		getUpdatedAtDateFormatted,
		isLabelIconRed,
	} = useStepper({ deliveryData });

	const { t } = useTranslations();

	const classes = useStyles({ stepsAmount: steps?.length, cancelReasonIndex });

	const renderStepIcon = (state, index) => (
		<Box
			className={clsx({
				[classes.stepIcon]: true,
				[classes.stepIconRed]: index === 0 && isLabelIconRed(state),
				[classes.notActiveStepIcon]: index !== 0,
			})}
		></Box>
	);

	return (
		<>
			<MuiStepper
				activeStep={activeSteps}
				connector={<CustomConnector />}
				className={classes.stepper}
				orientation="vertical"
			>
				{steps?.map(({ state, updated_at }, index) => (
					<Step key={state}>
						<StepLabel
							error={Boolean(isDeliveryCancelled(state))}
							StepIconComponent={() => renderStepIcon(state, index)}
						>
							<Grid container alignItems="center">
								<Grid item xs={12} md={6}>
									<Typography
										className={clsx([
											classes.optionalLabel,
											index !== 0 ? classes.notActiveLabel : '',
										])}
									>
										{t(`bookings.preview_booking.car.step_captions.${state}`)}
									</Typography>
								</Grid>
								<Grid item xs={12} md={6}>
									<Typography
										className={index !== 0 ? classes.notActiveLabel : ''}
										key={updated_at}
									>
										{getUpdatedAtDateFormatted(updated_at)}
									</Typography>
								</Grid>
							</Grid>
						</StepLabel>
					</Step>
				))}
			</MuiStepper>

			{cancelReason && (
				<Box className={classes.cancelReasonBox}>
					<Typography className={classes.cancelReason}>
						{cancelReason}
					</Typography>
				</Box>
			)}
		</>
	);
};

Stepper.propTypes = {
	deliveryData: object,
};
