import React from 'react';
import { object } from 'prop-types';
import { Form } from 'react-final-form';
import { DialogContent, Dialog, Tooltip } from '@material-ui/core';

// Import utilities and helpers
import { useMarkAsCompensatedDialog } from './useMarkAsCompensatedDialog';
import { useTranslations } from 'components/utilities';
import { INITIAL_VALUES } from './helpers';

// Import components
import { Fields } from './components';
import {
	CompensationHistoryFields,
	ClosableDialogTitle,
	IconButton,
} from 'components/elements';

// Import assets
import { CheckMarkDoneIcon } from 'assets/icons';

export const MarkAsCompensatedDialog = ({ cell }) => {
	const { t } = useTranslations();
	const { compensation, onSubmit, isOpen, toggle } =
		useMarkAsCompensatedDialog(cell);

	return (
		<>
			<Tooltip title={t('validation.table.action.mark_as_compensated')}>
				<IconButton size="small" onClick={toggle}>
					<CheckMarkDoneIcon color="primary" />
				</IconButton>
			</Tooltip>
			<Dialog fullWidth open={isOpen} onClose={toggle}>
				<ClosableDialogTitle
					onClose={toggle}
					title={t('bookings.close_compensation_form.title')}
				/>
				<DialogContent>
					<Form
						onSubmit={onSubmit}
						initialValues={INITIAL_VALUES}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<CompensationHistoryFields
									compensation={compensation}
									isInAccountingValidationPage
								/>
								<Fields toggle={toggle} />
							</form>
						)}
					/>
				</DialogContent>
			</Dialog>
		</>
	);
};

MarkAsCompensatedDialog.propTypes = {
	cell: object.isRequired,
};
