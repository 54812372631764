import React from 'react';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components';
import { CarImagesDialog } from './components';
import { Card } from 'components/elements';

// Import helpers
import { DELIVERY_INFORMATION_FIELDS } from './helpers';

export const DeliveryInformationCard = () => {
	const { t } = useTranslations();

	return (
		<Card
			title={t('bookings.preview_booking.delivery_information.title')}
			lg={12}
			actions={<CarImagesDialog />}
		>
			<PreviewFields fields={DELIVERY_INFORMATION_FIELDS} />
		</Card>
	);
};
