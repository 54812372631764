import React from 'react';
import { Box, Dialog, DialogContent, Grid } from '@material-ui/core';
import { bool, func, node, object } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';

// Import components
import { ClosableDialogTitle } from 'components/elements/organisms/Booking';
import { Button } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import assets
import { FilterIcon } from 'assets/icons';

// Import styles
import { useStyles } from './FiltersDialog.styles';

export const FiltersDialog = ({
	handleOnResetFilters,
	dialogFilters,
	isOpen,
	toggle,
}) => {
	const { submitting } = useFormState();
	const { submit } = useForm();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<>
			<Button
				className={classes.filterButton}
				startIcon={<FilterIcon />}
				onClick={toggle}
				text={t('common.buttons.filters')}
			/>

			<Dialog onClose={toggle} open={isOpen} fullWidth maxWidth="md">
				<ClosableDialogTitle
					onClose={toggle}
					title={t('common.buttons.filters')}
				/>
				<DialogContent>
					<Box>{dialogFilters}</Box>
					<Grid
						container
						justifyContent="flex-end"
						alignItems="center"
						spacing={3}
						className={classes.buttonsWrapper}
					>
						<Grid item>
							<Button
								className={classes.clearAllButton}
								onClick={handleOnResetFilters}
								text={t('common.buttons.clear_all')}
							/>
						</Grid>
						<Grid item>
							<Button
								className={classes.submitButton}
								disabled={submitting}
								onClick={submit}
								variant="contained"
								color="primary"
								text={t('common.buttons.submit')}
							/>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		</>
	);
};

FiltersDialog.propTypes = {
	handleOnResetFilters: func,
	initialValues: object,
	dialogFilters: node,
	toggle: func,
	isOpen: bool,
	onClear: func,
};
