import React, { forwardRef } from 'react';
import MuiGrid from '@material-ui/core/Grid';
import {
	elementType,
	oneOfType,
	number,
	string,
	oneOf,
	bool,
	node,
} from 'prop-types';

/**
 * @typedef {object} GridProps
 *
 * @property {("center" | "flex-end" | "flex-start" | "space-between" | "space-around" | "space-evenly" )} justifyContent
 * @property {("center" | "flex-end" | "flex-start" | "baseline" | "stretch")} alignItems
 * @property {("column" | "row" | "column-reverse" | "row-reverse")} direction
 * @property {("nowrap" | "wrap" | "wrap-reverse")} wrap
 * @property {string} compnent
 * @property {number} spacing
 * @property {boolean} container
 * @property {boolean} item
 * @property {boolean} xs
 * @property {boolean} md
 * @property {boolean} lg
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLElement, GridProps>}
 */

// eslint-disable-next-line react/display-name
export const Grid = forwardRef((props, ref) => {
	const {
		justifyContent = 'flex-start',
		alignItems = 'stretch',
		direction = 'row',
		component = 'div',
		wrap = 'wrap',
		container = false,
		item = false,
		xs = false,
		md = false,
		lg = false,
		spacing = 0,
	} = props;

	if (item) {
		return (
			<MuiGrid ref={ref} item xs={xs} md={md} lg={lg} {...props}>
				{props.children}
			</MuiGrid>
		);
	}

	return (
		<MuiGrid
			container={container}
			direction={direction}
			justifyContent={justifyContent}
			alignItems={alignItems}
			component={component}
			spacing={spacing}
			wrap={wrap}
			ref={ref}
			{...props}
		>
			{props.children}
		</MuiGrid>
	);
});

Grid.propTypes = {
	children: node,
	container: bool,
	item: bool,
	wrap: string,
	component: elementType,
	xs: oneOfType([number, string, bool]),
	md: oneOfType([number, string, bool]),
	lg: oneOfType([number, string, bool]),
	direction: oneOf(['column', 'row', 'column-reverse', 'row-reverse']),
	justifyContent: oneOf([
		'center',
		'flex-end',
		'flex-start',
		'space-between',
		'space-around',
		'space-evenly',
	]),
	alignItems: oneOf([
		'center',
		'flex-end',
		'flex-start',
		'baseline',
		'stretch',
	]),
	spacing: number,
};
