import React from 'react';
import { string } from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import components
import { OptionField } from '../OptionField';
import useDeliveryOptionField from './useDeliveryOptionField';
import { DeliveryMap } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';

export const DeliveryOptionField = ({ label }) => {
	const { isMapVisible, toggleMapVisible } = useDeliveryOptionField();

	const { submitting } = useFormState();

	const { t } = useTranslations();

	return (
		<>
			<Grid container justifyContent="space-between">
				<Grid item>
					<OptionField type="delivery" label={label} />
				</Grid>
				<Grid item>
					<Button
						onClick={toggleMapVisible}
						color="primary"
						disabled={submitting}
					>
						{t('bookings.edit_booking.delivery.show_map')}
					</Button>
				</Grid>
			</Grid>
			{isMapVisible && (
				<DeliveryMap open={isMapVisible} onClose={toggleMapVisible} />
			)}
		</>
	);
};

DeliveryOptionField.propTypes = {
	label: string,
};
