import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: '20px',
	},
	header: {
		fontSize: '28px',
		fontWeight: 500,
		margin: '28px 0px',
		color: theme.palette.text.inputLabel,
	},
}));
