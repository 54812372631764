import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const useStyles = makeStyles(() => ({
	popperContent: {
		position: 'relative',
		border: '1px solid',
		borderColor: palette.background[3],
		backgroundColor: palette.white,
		borderRadius: '8px',
		maxWidth: '400px',
		overflow: 'visible',
	},
	arrow: {
		position: 'absolute',
		width: '10px',
		height: '10px',
		borderLeft: '10px solid transparent',
		borderRight: '10px solid transparent',
		borderTop: `10px solid ${palette.background[3]}`,

		'&::before': {
			content: '""',
			position: 'absolute',
			left: '-9px',
			top: '-11px',
			width: '0',
			height: '0',
			borderLeft: '9px solid transparent',
			borderRight: '9px solid transparent',
			borderTop: `9px solid ${palette.white}`,
		},
	},
}));
