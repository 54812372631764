import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		'& label': {
			color: theme.palette.text.inputLabel,
		},
		'& .MuiGrid-item': {
			height: '100px',
		},
		'& .MuiFormControl-marginDense': {
			marginTop: 0,
		},
	},
}));
