import React from 'react';
import { object, array, func, node, oneOfType } from 'prop-types';

// Import components
import { TableExportProvider } from 'components/context';
import {
	TableExportFilters,
	TableExport,
	TableExportPopup,
} from 'components/elements';

const ExportFilters = ({
	fetchDataTableAction,
	initialValues,
	filterFields: FilterFields,
	formatValues,
	columns,
}) => {
	return (
		<TableExportProvider
			fetchDataTableAction={fetchDataTableAction}
			columns={columns}
		>
			<TableExportPopup />
			<TableExport
				renderFilters={() => (
					<TableExportFilters
						initialValues={initialValues}
						formatValues={formatValues}
					>
						<FilterFields />
					</TableExportFilters>
				)}
			/>
		</TableExportProvider>
	);
};

ExportFilters.propTypes = {
	fetchDataTableAction: func,
	initialValues: object,
	filterFields: oneOfType([node, func]),
	formatValues: func,
	columns: oneOfType([array, object]),
};

export default ExportFilters;
