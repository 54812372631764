// Import helpers
import { BOOKING_COMPENSATION_STATES } from 'helpers';

const { REVIEW, APPROVED, COMPENSATED, WAITING, INVALID } =
	BOOKING_COMPENSATION_STATES;

export const buttons = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.common_states.review',
		status: REVIEW,
	},
	{
		label: 'common.common_states.validated',
		status: APPROVED,
	},
	{
		label: 'common.common_states.compensated',
		status: COMPENSATED,
	},
	{
		label: 'common.common_states.invalid',
		status: INVALID,
	},
	{
		label: 'common.common_states.waiting_info',
		status: WAITING,
	},
];
