import React from 'react';
import { object } from 'prop-types';
import { useSelector } from 'react-redux';

// Import components
import { Tabs } from 'components/elements';

// Import helpers
import { getDeliveryTabs, getTab } from './helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import styles
import { useStyles } from './DeliveryTabs.styles';

export const DeliveryTabs = ({ outerDeliveryData }) => {
	const classes = useStyles();
	const booking = useSelector(previewBookingSelector);

	return (
		<Tabs
			tabs={getDeliveryTabs(booking)}
			cardClassName={classes.card}
			textColor="inherit"
			getTab={(currentTab) => getTab(currentTab, outerDeliveryData)}
			transKey="bookings.preview_booking.delivery_information"
		/>
	);
};

DeliveryTabs.propTypes = {
	outerDeliveryData: object,
};
