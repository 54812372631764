import { useSelector } from 'react-redux';

// Import helpers
import { MAX_URGENT_REASONS } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useAssignUrgent = () => {
	const booking = useSelector(previewBookingSelector);

	const { urgentReasons } = booking || {};

	const isMaxUrgentReasonsSize = urgentReasons?.length === MAX_URGENT_REASONS;

	return { isMaxUrgentReasonsSize };
};
