import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	paginationContainer: {
		width: '100%',
		display: 'flex',
		justifyContent: 'flex-end',
		marginTop: '32px',
	},
}));
