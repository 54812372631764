import React from 'react';
import { bool, object } from 'prop-types';

// Import helpers and utils
import { useBookingStatusActionsProvider } from 'components/context';
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { convertVehicleName } from 'helpers';
import { actionMenuFields } from './helpers';

// Import components
import {
	OfficeWithPreviewTableCell,
	StateWithActionsTableCell,
	NewActionsMenuTableCell,
	BookingIdTableCell,
	AssignDriverDialog,
	ApprovedTableCell,
	PeriodTableCell,
	TextTableCell,
	Notes,
	TableCell,
} from 'components/elements';

const { OFFICE, APPROVED, CUSTOMER, ACTIONS, STATUS, PERIOD, ID, CAR, BY } =
	COLUMNS_ACCESSORS;

export const Cell = ({ isHovered, cell }) => {
	const { uuid: bookingUuid } = cell.row.original;

	const { toggleAssignDriverDialog, isAssignDriverDialogOpen } =
		useBookingStatusActionsProvider();

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			customer_phone: customerPhone,
			customer_name: customerName,
			action_by: actionBy,
			vehicle,
		} = cell.row.original;

		const { name, year } = convertVehicleName(vehicle);

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case OFFICE:
				return <OfficeWithPreviewTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<StateWithActionsTableCell
						key={cell.column.id}
						cell={cell}
						isHovered={isHovered}
					/>
				);

			case CUSTOMER:
				return (
					<TextTableCell
						additionalText={customerPhone}
						text={customerName}
						minWidth={180}
						cell={cell}
					/>
				);

			case CAR:
				return (
					<TextTableCell
						text={name}
						additionalText={year}
						minWidth={180}
						cell={cell}
					/>
				);

			case APPROVED:
				return <ApprovedTableCell key={cell.column.id} cell={cell} />;

			case PERIOD:
				return <PeriodTableCell key={cell.column.id} cell={cell} />;

			case BY:
				return (
					<TextTableCell cell={cell} key={cell.column.id} text={actionBy} />
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						key={cell.column.id}
						cell={cell}
						previewPopupFields={actionMenuFields}
					/>
				);

			default:
				return (
					<TableCell cell={cell} key={cell.column.id}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return (
		<>
			<AssignDriverDialog
				toggle={toggleAssignDriverDialog}
				open={isAssignDriverDialogOpen}
				bookingUuid={bookingUuid}
			/>
			{renderCell(cell)}
		</>
	);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
