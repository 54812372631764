import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCustomerSummaryData = ({ customerUuid, isOpen }) => {
	const enabled = isOpen && !!customerUuid;

	const { data, isFetching } = useQuery(
		QUERY_KEYS.CUSTOMERS.SUMMARY_DATA({ customerUuid }),
		async () =>
			await services.get(ENDPOINTS.CUSTOMER.SUMMARY_DATA({ customerUuid })),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching };
};
