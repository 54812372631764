import React from 'react';
import { func, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import components
import { AddDialog, ClearDialog, HistoryTable } from './components';
import { Alert, LoadingWrapper } from 'components/elements';

// Import utilities
import { useCustomerWalletContent } from './useCustomerWalletContent';

// Import styles
import { useStyles } from './CustomerWalletContent.styles';
import { useProfile } from 'components/utilities';

export const CustomerWalletContent = ({
	customerUuid,
	setCardActions = null,
}) => {
	const { isCustomerWalletManager } = useProfile();
	const classes = useStyles();
	const {
		isBalanceError,
		isBalanceVisible,
		isHistoryError,
		balanceError,
		historyError,
		isLoaded,
		balance,
		history,
	} = useCustomerWalletContent({ customerUuid, setCardActions });

	return (
		<>
			{isLoaded ? (
				<Grid container direction="column" spacing={2}>
					<Grid item>
						<Grid container>
							{!isHistoryError ? (
								<HistoryTable rows={history} />
							) : (
								<Grid item xs={12}>
									<Alert message={historyError} />
								</Grid>
							)}
						</Grid>
					</Grid>
				</Grid>
			) : (
				<Grid item>
					<LoadingWrapper className={classes.loadingWrapper} />
				</Grid>
			)}
			<Grid container alignItems="center" justifyContent="flex-end" spacing={2}>
				{isCustomerWalletManager && (
					<>
						<Grid item>
							<AddDialog customerUuid={customerUuid} />
						</Grid>
						<Grid item>
							<ClearDialog customerUuid={customerUuid} />
						</Grid>
					</>
				)}
				<Grid item className={classes.balanceWrapper}>
					{!isBalanceError ? (
						isBalanceVisible && (
							<Typography variant="h6" color="primary">
								{balance}
							</Typography>
						)
					) : (
						<Alert message={balanceError} />
					)}
				</Grid>
			</Grid>
		</>
	);
};

CustomerWalletContent.propTypes = {
	customerUuid: string,
	setCardActions: func,
};
