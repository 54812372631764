import React from 'react';
import { array } from 'prop-types';

import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import helpers and utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { FIELDS } from './helpers';

export const CampaignCustomerCard = ({
	customerTypes,
	customerOrganizations,
	selectedTypes,
	selectedOrganizations,
}) => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	return (
		<Card>
			<CardHeader title={t('campaigns.form.customer_card_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({
						customerOrganizations,
						customerTypes,
						selectedTypes,
						selectedOrganizations,
					}).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CampaignCustomerCard.propTypes = {
	customerTypes: array.isRequired,
	customerOrganizations: array.isRequired,
	selectedTypes: array.isRequired,
	selectedOrganizations: array.isRequired,
};
