import React from 'react';
import { object, string } from 'prop-types';

// Import components
import { StatusChip, Steps } from './components';
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import context
import { DeliveryLocationProvider } from './context';

// Import utils
import { useDeliveryTabContent } from './useDeliveryTabContent';

export const DeliveryTabContent = ({ type, outerDeliveryData }) => {
	const {
		deliveryUuid,
		data,
		available,
		hasLateDelivery,
		showDeliverySteps,
		DELIVERY_INFO_FIELDS,
	} = useDeliveryTabContent({ type, outerDeliveryData });

	return (
		<DeliveryLocationProvider deliveryUuid={deliveryUuid} type={type}>
			{available && (
				<PreviewFields fields={DELIVERY_INFO_FIELDS} customData={data} />
			)}

			<StatusChip
				hasLateDelivery={hasLateDelivery}
				outerDeliveryData={outerDeliveryData}
			/>
			{showDeliverySteps && (
				<Steps type={type} outerDeliveryData={outerDeliveryData} />
			)}
		</DeliveryLocationProvider>
	);
};

DeliveryTabContent.propTypes = {
	type: string.isRequired,
	outerDeliveryData: object,
};
