import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core';

// Import styles
import palette from 'theme/palette';

// Import helpers
import { SCHEDULED_BOOKING_DATE_TYPES } from 'helpers';

const { TODAY, TOMORROW, AFTER_TOMORROW } = SCHEDULED_BOOKING_DATE_TYPES;

const getColor = (tab) => {
	switch (tab) {
		case TODAY:
			return palette.uncategorized.pink[1];
		case TOMORROW:
			return palette.uncategorized.blue[1];
		case AFTER_TOMORROW:
			return palette.primary.main;
		default:
			return palette.gray.medium;
	}
};

const getBackgroundColor = ({ tab, staticDateType }) =>
	tab === staticDateType ? alpha(getColor(staticDateType), 0.2) : 'transparent';

export const useStyles = makeStyles((theme) => ({
	button: ({ tab }) => ({
		backgroundColor: tab === null ? theme.palette.success.light : 'transparent',
		color: tab === null ? theme.palette.primary.main : getColor(),
		borderRadius: '12px',
		padding: '8px 16px',
		fontWeight: '600',
		fontSize: '14px',
		'& :hover': {
			backgroundColor: 'transparent',
		},
	}),
	[TODAY]: ({ tab }) => ({
		color: getColor(TODAY),
		backgroundColor: getBackgroundColor({ tab, staticDateType: TODAY }),
	}),
	[TOMORROW]: ({ tab }) => ({
		color: getColor(TOMORROW),
		backgroundColor: getBackgroundColor({ tab, staticDateType: TOMORROW }),
	}),
	[AFTER_TOMORROW]: ({ tab }) => ({
		color: getColor(AFTER_TOMORROW),
		backgroundColor: getBackgroundColor({
			tab,
			staticDateType: AFTER_TOMORROW,
		}),
	}),
}));
