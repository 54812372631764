import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginTop: '48px',
	},
	title: {
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(4),
		textTransform: 'capitalize',
	},
	reasonLabel: {
		fontSize: 14,
	},
	assignedByLabel: {
		fontWeight: 400,
		fontSize: 12,
		color: theme.palette.text[2],
	},
	button: {
		padding: '12px 24px',
		fontSize: 14,
		marginTop: theme.spacing(4),

		'& svg': {
			width: '24px',
			height: '24px',
		},
	},
	historyButton: {
		borderColor: theme.palette.background[3],
	},
}));
