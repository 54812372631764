import { useParams, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// Import helpers and utils
import { getCampaignType } from '../helpers';
import { useMessage, useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import store
import { campaignsFormSelector } from 'store/selectors';

export const useCampaignsForm = () => {
	const { type } = useParams();
	const { push } = useHistory();
	const { message } = useMessage();
	const { t } = useTranslations();

	const { item } = useSelector(campaignsFormSelector);

	const possibleType = type || item?.details?.campaign_type;

	const campaignType = getCampaignType(possibleType);

	const handleCallbackFormSuccess = () => {
		message.success(t('common.messages.successfully_created'));
		push(URLS.campaignsUrl);
	};

	return { campaignType, handleCallbackFormSuccess };
};
