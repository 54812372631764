import React from 'react';
import { Box, Button, Dialog } from '@material-ui/core';
import { bool, func } from 'prop-types';

// Import utils and helpers
import { useTranslations } from 'components/utilities';

// Import components
import { ClosableDialogTitle } from 'components/elements';
import { DialogContent } from './components';

// Import styles
import { useStyles } from './RatingHistoryDialog.styles';

// Import assets
import { HistoryIcon } from 'assets/icons';

export const RatingHistoryDialog = ({ isOpen, toggle }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<>
			<Button
				onClick={toggle}
				className={classes.button}
				startIcon={<HistoryIcon className={classes.icon} />}
			>
				{t('bookings.preview_booking.ratings.history')}
			</Button>
			<Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
				<ClosableDialogTitle
					title={t('bookings.preview_booking.ratings.rating_history')}
					onClose={toggle}
				/>
				<Box className={classes.dialogContent}>
					<DialogContent />
				</Box>
			</Dialog>
		</>
	);
};

RatingHistoryDialog.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
};
