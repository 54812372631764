import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		width: '100%',
	},
	inputLabel: {
		fontSize: '12px',
		fontWeight: 600,
		marginBottom: '8px',
		color: theme.palette.text.inputLabel,
	},
	input: {
		'& .MuiInputBase-root': {
			width: '100%',
			borderRadius: '8px',
			marginTop: '8px',
			padding: '0 16px',
		},
		'& .MuiInput-underline:before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:hover:not(.Mui-disabled):before': {
			borderBottom: 'none',
		},
		'& .MuiInput-underline:after': {
			borderBottom: 'none',
		},
	},
}));
