import React from 'react';
import clsx from 'clsx';
import {
	Stepper as MuiStepper,
	StepLabel,
	Step,
	Box,
	Grid,
	Typography,
	StepContent,
} from '@material-ui/core';
import { array, string } from 'prop-types';

// Import utils
import { useStepper } from './useStepper';

// Import styles
import { CustomConnector, useStyles } from './Steps.styles';

export const Steps = ({ steps, stepIconClassName }) => {
	const { activeSteps } = useStepper(steps);
	const classes = useStyles({ stepsAmount: steps?.length });

	const renderStepIcon = (index) => (
		<Box
			className={clsx({
				[classes.stepIcon]: true,
				[classes.notActiveStepIcon]: index !== 0,
				[stepIconClassName]: index === 0,
			})}
		></Box>
	);

	return (
		<MuiStepper
			activeStep={activeSteps}
			connector={<CustomConnector />}
			className={classes.stepper}
			orientation="vertical"
		>
			{steps?.map(({ label, chip, date, description }, index) => (
				<Step key={index} expanded>
					<StepLabel
						StepIconComponent={() => renderStepIcon(index)}
						className={classes.labelRoot}
					>
						<Grid
							container
							justifyContent="space-between"
							className={clsx({
								[classes.optionalLabel]: true,
								[classes.notActiveLabel]: index !== 0,
							})}
						>
							<Grid item>{label}</Grid>
							<Grid item>
								<Typography
									variant="body1"
									color="inherit"
									className={classes.date}
								>
									{date}
								</Typography>
							</Grid>
						</Grid>
					</StepLabel>
					<StepContent
						className={clsx({
							[classes.stepContent]: true,
							[classes.notActiveLabel]: index !== 0,
						})}
					>
						{description && description}
						{chip && chip}
					</StepContent>
				</Step>
			))}
		</MuiStepper>
	);
};

Steps.propTypes = {
	stepIconClassName: string,
	steps: array,
};
