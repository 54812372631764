import React from 'react';
import { Grid } from '@material-ui/core';
import { Field } from 'react-final-form';
import { bool } from 'prop-types';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';
import { FIELDS } from './helpers';

// Import components
import { ButtonProgress, RatingFieldAdapter } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = ({ isLoading }) => {
	const { isSubmitButtonDisabled, submitting } = useFormContent();
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Field
					component={RatingFieldAdapter}
					disabled={submitting}
					name="rating"
					size="large"
				/>
			</Grid>
			{FIELDS.map(renderFields)}
			<Grid item xs={12} className={classes.buttonsWrapper}>
				<ButtonProgress
					disabled={isSubmitButtonDisabled}
					isLoading={isLoading}
					variant="contained"
					color="primary"
					type="submit"
					size="large"
				>
					{t('common.buttons.submit')}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};

FormContent.propTypes = {
	isLoading: bool,
};
