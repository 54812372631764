import React from 'react';
import { Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import components
import { Car, Company } from '../../components';

export const CarAndCompanyContent = ({ isHistoryDialog = false }) => {
	return (
		<Grid container spacing={6}>
			<Grid item md={6} sm={12}>
				<Car isHistoryDialog={isHistoryDialog} />
			</Grid>
			<Grid item md={6} sm={12}>
				<Company isHistoryDialog={isHistoryDialog} />
			</Grid>
		</Grid>
	);
};

CarAndCompanyContent.propTypes = {
	isHistoryDialog: bool,
};
