import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core';

// Import helpers
import { SCHEDULED_BOOKING_DATE_TYPES } from 'helpers';

const { TODAY, TOMORROW, AFTER_TOMORROW } = SCHEDULED_BOOKING_DATE_TYPES;

export const useStyles = makeStyles((theme) => ({
	legendContainer: {
		padding: '30px 32px',
	},
	periodItem: {
		padding: '6px 18px',
		borderLeft: ({ isBodyRow }) => `${isBodyRow ? '14' : '7'}px solid`,
	},

	[TODAY]: {
		backgroundColor: alpha(theme.palette.uncategorized.pink[1], 0.2),
		color: theme.palette.uncategorized.pink[1],
	},
	[TOMORROW]: {
		backgroundColor: alpha(theme.palette.uncategorized.blue[1], 0.1),
		color: theme.palette.secondary.main,
	},
	[AFTER_TOMORROW]: {
		backgroundColor: theme.palette.primary.light,
		color: theme.palette.primary.main,
	},
}));
