import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }) => ({
	uploadWrapper: {
		width: '100%',
		position: 'relative',
		'& div': {
			width: '100%',
		},
		'& button': {
			backgroundColor: palette.background[6],
			color: palette.text[1],
			minWidth: '100%',
			display: 'flex',
			justifyContent: 'flex-start',
			borderRadius: '8px',
			padding: '12px',
		},
		'& button:hover': {
			backgroundColor: palette.background[6],
			boxShadow: 'none',
		},
	},
	icon: {
		position: 'absolute',
		top: '40px',
		right: '22px',
	},
	switch: {
		'& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track': {
			backgroundColor: palette.primary.main,
		},
	},
	switchContainer: {
		marginTop: '40px',
		display: 'flex',
		alignItems: 'flex-start',
		gap: '12px',
		'& .MuiSwitch-root': {
			margin: 0,
		},
	},
	switchHeader: {
		fontSize: '14px',
		fontWeight: 600,
		color: palette.text[4],
	},
	switchText: {
		marginTop: '16px',
		fontSize: '12px',
		fontWeight: 400,
		color: palette.text[2],
	},
	dynamicContent: {
		width: '100%',
	},
	totalFieldWrapper: {
		width: '100%',
		'& .MuiFormControl-root': {
			flexDirection: 'column',
			alignItems: 'flex-start',
			'& label': {
				width: '100%',
				color: palette.text[4],
			},
		},
		'& .MuiInputBase-root': {
			border: 'none',
			width: '100%',
			backgroundColor: palette.background[6],
			'& fieldset': {
				border: 'none',
			},
		},
		'& input': {
			backgroundColor: palette.background[6],
			width: '100%',
			color: palette.text[4],
			fontWeight: 500,
		},
		'& input::placeholder': {
			color: palette.text[1],
			fontWeight: 400,
			opacity: '1',
			zIndex: 1000,
		},
	},
	btnsContainer: {
		marginTop: '40px',
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
		marginBottom: '20px',
	},
	cancel: {
		color: palette.text[1],
		padding: '19px 24px 20px 24px',
		height: '48px',
	},
	close: {
		color: palette.white,
		backgroundColor: palette.error.medium,
		padding: '19px 24px 20px 24px',
		borderRadius: '12px',
		height: '48px',
		'&:hover': {
			backgroundColor: palette.error.medium,
		},
		'&:disabled': {
			backgroundColor: palette.background[7],
		},
	},
	optional: {
		color: palette.text[5],
	},
}));
