import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { Comments, ExtraActions } from './components';

// Import styles
import { useStyles } from './CommentsMenu.styles';

export const CommentsMenu = () => {
	const classes = useStyles();
	return (
		<Grid container className={classes.container}>
			<Grid item xs={12}>
				<Comments />
				<ExtraActions />
			</Grid>
		</Grid>
	);
};
