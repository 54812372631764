import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	container: {
		'& .MuiListItem-root': {
			paddingLeft: 0,
			paddingRight: 0,
		},
	},
}));
