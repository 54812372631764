import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: '20px',
		fontWeight: '600',
		color: theme.palette.primary.main,
	},
	card: {
		border: 'none',
		padding: 0,
		'& .MuiTabs-flexContainer': {
			justifyContent: 'flex-start',
		},
	},
	tab: {
		fontSize: '14px',
		padding: '0 16px',
		height: '40px',
	},
}));
