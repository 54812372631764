import React from 'react';

// Import components
import { Summary, Comments, Rating, BankInfo, Extension } from './components';

export const BOOKING_DETAILS_DIALOG_TABS = {
	SUMMARY: 'summary',
	RATING: 'rating',
	COMMENTS: 'comments',
	BANK_INFO: 'bank_info',
};

export const getTab = (currentTab) => {
	switch (currentTab) {
		case 0:
			return <Summary />;
		case 1:
			return <Rating />;

		case 2:
			return <Comments />;

		case 3:
			return <BankInfo />;

		default:
			return <Extension />;
	}
};
