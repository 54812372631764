import React from 'react';
import { bool, func, object } from 'prop-types';
import { Dialog, Slide } from '@material-ui/core';

// import components
import { OfficesDialogContent } from './components';

const Transition = React.forwardRef(function Transition(props, ref) {
	return <Slide direction="up" ref={ref} {...props} />;
});

export const AssignOfficesDialog = ({ cell, isOpen, toggle }) => (
	<Dialog
		aria-labelledby="alert-dialog-slide-title"
		aria-describedby="alert-dialog-slide-description"
		TransitionComponent={Transition}
		open={isOpen}
		onClose={toggle}
		fullWidth
	>
		<OfficesDialogContent
			user={cell.row.original}
			toggleDialog={toggle}
			isOpen={isOpen}
		/>
	</Dialog>
);

AssignOfficesDialog.propTypes = {
	cell: object.isRequired,
	toggle: func.isRequired,
	isOpen: bool.isRequired,
};
