import React from 'react';

// Import components
import {
	AcceptedBookingsNumberFilter,
	DateRangeInput,
	Grid,
} from 'components/elements';

export const AdditionalFilterFields = () => {
	return (
		<>
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="created_at"
					nameUntil="created_at_to"
					label="customers.filters.titles.sign_up_date"
					combinedInputs
					isToggleButton
				/>
			</Grid>
			<AcceptedBookingsNumberFilter
				name="accepted_booking_counter"
				smallFieldStyle
				xs="auto"
				md="auto"
			/>
		</>
	);
};
