import React from 'react';
import { bool, number, object, oneOfType, string } from 'prop-types';
import { TableCell, Grid, Tooltip, Chip, Box, Link } from '@material-ui/core';
import PowerIcon from '@material-ui/icons/Power';

// Import components
import { NotesForAdmins, NotesForOthers } from './components';
import RoleProtected from 'components/hoc/RoleProtected';

// Import helpers
import { CUSTOMER_TYPES, BOOKING_SOURCE, VERIFY_TYPES, ADMIN } from 'helpers';

// Import utilities
import { useTranslations } from 'components/utilities';
import useNoteTableCell from './useNoteTableCell';

// Import styles
import { useStyles } from '../../../Booking/styles';

// Import icons
import { AirplaneIcon, AssignedDriverIcon, CarDeliveryIcon } from 'icons';
import { elmLogo, subscription } from 'assets/images';

// Import assets
import {
	CarInfoIcon,
	CarCollectionIcon,
	TimeClockInfoIcon,
	PickupCarIcon,
	PinIcon,
} from 'assets/icons';

const NoteTableCell = ({
	cell,
	isTwoInRow = false,
	showCustomerLocation = false,
	showAdminNotes = true,
	showAssignedDriver = false,
	minWidth = 'auto',
}) => {
	const { t } = useTranslations();

	const {
		adminPriceDifference,
		adminPriceDifferenceTooltip,
		isNegativeDifference,
		customer_verify_type,
		showPriceDifference,
		showExtendedLabel,
		showChangedLabel,
		customer_type,
		extendedLabel,
		changedLabel,
		isDeliveryLateAssignment,
		isPickupDelivery,
		isDropDelivery,
		isDeliveryLate,
		isDriverAssigned,
		is_subscription,
		is_external,
		is_discount,
		is_delivery,
		delivery,
		source,
		payment,
		corporateName,
	} = useNoteTableCell({ cell });

	const classes = useStyles({ isNegativeDifference });

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			style={{ minWidth }}
		>
			<Grid
				container
				direction={isTwoInRow ? 'row' : 'column'}
				alignItems={isTwoInRow ? 'center' : 'flex-start'}
				spacing={1}
			>
				{showAdminNotes && (
					<RoleProtected
						roles={[ADMIN]}
						fallback={<NotesForOthers classes={classes} cell={cell} />}
					>
						<NotesForAdmins classes={classes} payment={payment} />
					</RoleProtected>
				)}

				{is_subscription && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip title={t('bookings.table.notes.subscription')}>
							<img
								className={classes.subscriptionImage}
								src={subscription}
								alt={t('bookings.table.notes.subscription')}
							/>
						</Tooltip>
					</Grid>
				)}

				{is_external && (
					<Grid item xs={12}>
						<Tooltip title={t('bookings.table.notes.is_external')}>
							<Chip
								className={classes.chip}
								size="small"
								label={<PowerIcon fontSize="small" />}
							/>
						</Tooltip>
					</Grid>
				)}

				{customer_verify_type === VERIFY_TYPES.ELM && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip title={t('bookings.table.notes.verified_by_elm')}>
							<img className={classes.image} src={elmLogo} alt="Elm" />
						</Tooltip>
					</Grid>
				)}

				{source === BOOKING_SOURCE.KIOSK && (
					<Grid item xs={12}>
						<Tooltip title={t('bookings.table.notes.kiosk')}>
							<Chip
								label={<AirplaneIcon className={classes.chipIcon} />}
								className={classes.chip}
								size="small"
							/>
						</Tooltip>
					</Grid>
				)}

				{is_discount && (
					<Tooltip title={t('common.icon_names.discount')}>
						<Grid item xs={12}>
							<Chip
								className={classes.chip}
								color="primary"
								size="small"
								label="%"
							/>
						</Grid>
					</Tooltip>
				)}

				{customer_type === CUSTOMER_TYPES.TRUSTED && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip title={t('common.icon_names.vip')}>
							<Chip
								className={classes.chip}
								color="primary"
								size="small"
								label="VIP"
							/>
						</Tooltip>
					</Grid>
				)}

				{is_delivery && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip title={t('bookings.table.notes.car_delivery')}>
							<Chip
								label={<CarDeliveryIcon fontSize="small" />}
								className={classes.chip}
								size="small"
							/>
						</Tooltip>
					</Grid>
				)}

				{showExtendedLabel && (
					<Grid item xs={12}>
						<Chip
							className={classes.blueChip}
							label={extendedLabel}
							size="small"
							mx={1}
						/>
					</Grid>
				)}

				{showChangedLabel && (
					<Grid item xs={12}>
						<Chip
							className={classes.blueChip}
							label={changedLabel}
							size="small"
						/>
					</Grid>
				)}

				{showPriceDifference && (
					<Grid item xs={12}>
						<Tooltip title={adminPriceDifferenceTooltip}>
							<Chip
								className={classes.priceDifferenceChip}
								label={adminPriceDifference}
								size="small"
							/>
						</Tooltip>
					</Grid>
				)}

				{isDeliveryLateAssignment && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip
							title={t(
								'bookings.preview_booking.delivery_information.icon_tooltips.late_assignment'
							)}
						>
							<Box className={classes.redDeliveryChip}>
								<TimeClockInfoIcon className={classes.redIcon} />
							</Box>
						</Tooltip>
					</Grid>
				)}

				{isDeliveryLate && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip
							title={t(
								'bookings.preview_booking.delivery_information.icon_tooltips.late_delivery'
							)}
						>
							<Box className={classes.redDeliveryChip}>
								<CarInfoIcon className={classes.redIcon} />
							</Box>
						</Tooltip>
					</Grid>
				)}

				{isPickupDelivery && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip
							title={t(
								'bookings.preview_booking.delivery_information.icon_tooltips.pick_up'
							)}
						>
							<Box className={classes.greenDeliveryChip}>
								<PickupCarIcon className={classes.greenIcon} />
							</Box>
						</Tooltip>
					</Grid>
				)}

				{isDropDelivery && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip
							title={t(
								'bookings.preview_booking.delivery_information.icon_tooltips.return'
							)}
						>
							<Box className={classes.greenDeliveryChip}>
								<CarCollectionIcon className={classes.greenIcon} />
							</Box>
						</Tooltip>
					</Grid>
				)}

				{showCustomerLocation && (delivery?.latitude || delivery?.lat) ? (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip
							title={t(
								'bookings.preview_booking.delivery_information.icon_tooltips.delivery'
							)}
						>
							<Link
								href={`https://www.google.com/maps/place/${
									delivery?.latitude || delivery?.lat
								},${delivery?.longitude || delivery?.lng}`}
								target="_blank"
								rel="noopener"
							>
								<Box className={classes.greenDeliveryChip}>
									<PinIcon className={classes.greenIcon} />
								</Box>
							</Link>
						</Tooltip>
					</Grid>
				) : null}
				{showAssignedDriver && isDriverAssigned && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip title={t('bookings.preview_booking.car.assign_driver')}>
							<Box className={classes.greenDeliveryChip}>
								<AssignedDriverIcon className={classes.greenIcon} />
							</Box>
						</Tooltip>
					</Grid>
				)}
				{corporateName && (
					<Grid item xs={isTwoInRow ? 5 : 12}>
						<Tooltip title={corporateName}>
							<Chip
								className={classes.chip}
								label={t('b2b.b2b')}
								size="small"
							/>
						</Tooltip>
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

NoteTableCell.propTypes = {
	cell: object.isRequired,
	isTwoInRow: bool,
	showCustomerLocation: bool,
	showAdminNotes: bool,
	showAssignedDriver: bool,
	minWidth: oneOfType([number, string]),
};

export default NoteTableCell;
