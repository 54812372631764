import { makeStyles } from '@material-ui/styles';

import { BOOKING_STATES_TYPES } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: '100px',
		textTransform: 'uppercase',
		fontSize: '12px',
		fontWeight: '600',
		minWidth: theme.layout.width.button,
	},
	popper: {
		'& .MuiTooltip-tooltip': {
			backgroundColor: theme.palette.error.lighter,
			borderRadius: '8px',
			border: '1px solid',
			borderColor: theme.palette.error.darkBorder,
		},
	},
	[BOOKING_STATES_TYPES.SUCCESS]: {
		backgroundColor: theme.palette.success.light,
		color: theme.palette.success.medium,
	},
	[BOOKING_STATES_TYPES.ERROR]: {
		backgroundColor: theme.palette.error.light,
		color: theme.palette.error.medium,
	},
	[BOOKING_STATES_TYPES.WARNING]: {
		backgroundColor: theme.palette.warning.light,
		color: theme.palette.warning.mid,
	},
	[BOOKING_STATES_TYPES.INFO]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},
	[BOOKING_STATES_TYPES.DEFAULT]: {
		backgroundColor: theme.palette.info.light,
		color: theme.palette.info.main,
	},
}));
