import { convertFormDates } from 'helpers';

export const initialValues = {
	due_date: null,
	due_date_to: null,
	company_uuid: 'all',
};

export const formatValues = (values) => {
	return {
		...values,
		...convertFormDates(values, ['due_date']),
	};
};
