import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BookingDetailsStatusActions } from 'components/elements';

// Import styles
import { useStyles } from './BottomBar.styles';

export const BottomBar = () => {
	const classes = useStyles();

	return (
		<Grid container spacing={3} className={classes.container}>
			<BookingDetailsStatusActions />
		</Grid>
	);
};
