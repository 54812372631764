import React from 'react';
import { object } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import components
import { PreviewField } from 'components/elements';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';

// Import helpers
import { PAYMENT_METHODS } from 'helpers';

// Import styles
import { useStyles } from './PaymentMethodsPreviewField.styles';

export const PaymentMethodsPreviewField = ({ customData }) => {
	const { payment_methods: paymentMethods } = customData || {};
	const { isAdmin } = useProfile();
	const { t } = useTranslations();
	const classes = useStyles();

	const renderPayment = (payment, index) => {
		const { gateway, gateway_method_type, gateway_method } = payment;
		const label = index === 0 ? t('bookings.table.headers.payment_method') : '';
		const sectionName = 'bookings.preview_booking.invoice';

		const getTextForAdmins = () => {
			if (gateway === 'corporate_limit') {
				return t('b2b.b2b');
			}

			return (
				t(`${sectionName}.${gateway_method}`) +
				' - ' +
				t(`${sectionName}.method_type.${gateway_method_type}`)
			);
		};

		const getTextForOthers = () => {
			if (gateway === 'corporate_limit') {
				return t('b2b.b2b');
			}

			return gateway_method === PAYMENT_METHODS.CASH
				? t(`${sectionName}.method_type.cash`)
				: t(`${sectionName}.pre_paid`);
		};

		const textForAdmins = getTextForAdmins();
		const textForOthers = getTextForOthers();

		return (
			<Grid key={index} item xs={12} className={classes.paymentMethod}>
				<PreviewField label={label}>
					{isAdmin ? textForAdmins : textForOthers}
				</PreviewField>
			</Grid>
		);
	};

	return (
		<Grid item xs={12}>
			<Grid container alignItems="center">
				{paymentMethods.map((payment, index) => renderPayment(payment, index))}
			</Grid>
		</Grid>
	);
};

PaymentMethodsPreviewField.propTypes = {
	customData: object,
};
