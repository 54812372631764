import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	button: {
		backgroundColor: theme.palette.white,
		border: '1px solid',
		borderColor: theme.palette.background[5],
		color: theme.palette.text[2],
		padding: '7px 16px',
		borderRadius: '8px',
	},
	dialogContent: {
		overflow: 'visible',
	},
	dialogActions: {
		padding: theme.spacing(3),
	},
}));
