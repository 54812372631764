import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useBookingInvoiceUuidQuery = ({ bookingUuid }) => {
	const path = ENDPOINTS.BOOKINGS.BOOKING_INVOICE_UUID({ bookingUuid });

	const { data, isLoading, isError } = useQuery(
		QUERY_KEYS.BOOKINGS.INVOICE_UUID(path),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, retry: false }
	);

	const fetchedData = data ? data.data : {};

	return { data: fetchedData, isLoading, isError };
};
