import { makeStyles } from '@material-ui/styles';

export const useSharedStyles = makeStyles((theme) => ({
	hoverRow: {
		backgroundColor: theme.palette.primary.medium,
	},
	smallField: {
		margin: 0,
		'& .MuiOutlinedInput-root': {
			minHeight: '36px',
		},
		'&.MuiOutlinedInput-root.MuiOutlinedInput-root': {
			minHeight: '36px',
		},
		'& .MuiSelect-select': {
			display: 'flex',
			alignItems: 'center',
			background: theme.palette.white,
			border: '1px solid',
			borderColor: theme.palette.background[3],
			minHeight: '36px',
			padding: '0 52px 0 18px',
			color: theme.palette.text[2],
		},
		'& .Mui-disabled': {
			opacity: 0.8,
			cursor: 'not-allowed',
		},
	},
	error: {
		color: theme.palette.error.medium,
	},
}));
