import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: theme.spacing(4),
	},
	title: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(1),
		color: 'inherit',
	},
	date: {
		fontSize: 14,
		color: theme.palette.text[2],
	},
}));
