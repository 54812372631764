import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const popoverStyle = {
	backgroundColor: palette.white,
	borderRadius: '12px',
	padding: 0,
};

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-end',
		width: '100%',
		gap: '16px',
	},
	popoverContent: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'flex-start',
		height: '100%',
		minHeight: '82px',
		gap: '8px',
		padding: '24px',
		minWidth: '250px',
	},
	confirmRejectionButton: {
		color: theme.palette.error.medium,
		justifyContent: 'flex-start',
	},
	confirmRejectionIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.error.medium,
	},
	reAcceptButton: {
		color: theme.palette.primary.main,
		justifyContent: 'flex-start',
	},
	reAcceptIcon: {
		width: '24px',
		height: '24px',
		color: theme.palette.primary.main,
	},
}));
