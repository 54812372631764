import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PhoneNumberIcon = (props) => (
	<SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
		<path
			id="Path"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M7.23678 9.26274C6.45678 8.48274 5.86878 7.60941 5.47811 6.72341C5.39545 6.53608 5.44411 6.31674 5.58878 6.17208L6.13478 5.62674C6.58211 5.17941 6.58211 4.54674 6.19145 4.15608L5.40878 3.37341C4.88811 2.85274 4.04411 2.85274 3.52345 3.37341L3.08878 3.80808C2.59478 4.30208 2.38878 5.01474 2.52211 5.72141C2.85145 7.46341 3.86345 9.37074 5.49611 11.0034C7.12878 12.6361 9.03611 13.6481 10.7781 13.9774C11.4848 14.1107 12.1974 13.9047 12.6914 13.4107L13.1254 12.9767C13.6461 12.4561 13.6461 11.6121 13.1254 11.0914L12.3434 10.3094C11.9528 9.91874 11.3194 9.91874 10.9294 10.3094L10.3274 10.9121C10.1828 11.0567 9.96345 11.1054 9.77611 11.0227C8.89011 10.6314 8.01678 10.0427 7.23678 9.26274Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
