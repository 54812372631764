import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	label: {
		fontFamily: 'Inter',
		fontSize: '14px',
		fontWeight: 600,
	},
	subLabel: {
		fontFamily: 'Inter',
		fontSize: '12px',
		fontWeight: 400,
		color: theme.palette.text[1],
	},
	value: {
		fontFamily: 'Inter',
		fontSize: '14px',
		fontWeight: 400,
		color: theme.palette.text[2],
	},
}));
