import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		margin: '24px 0px',
	},
	oldPrice: {
		fontSize: '14px',
		fontWeight: 510,
		color: theme.palette.text[1],
		textDecoration: 'line-through',
	},
	price: {
		color: theme.palette.primary.main,
		fontSize: '20px',
		fontWeight: 500,
		'& span': {
			fontSize: '14px',
			color: theme.palette.primary.main,
			fontWeight: 500,
		},
	},
	imgContainer: {
		minHeight: '68px',
		maxWidth: '135px',
		maxHeight: '68px',
	},
	img: {
		width: '100%',
	},
}));
