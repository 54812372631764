export const COLUMNS_ACCESSORS = {
	COMPENSATION_AMOUNT: 'compensation_amount',
	TRANSACTION_HISTORY: 'transaction_history',
	BOOKING_HISTORY: 'booking_history',
	OFFICE_NAME: 'office_name',
	CREATED_AT: 'created_at',
	PICK_DATE: 'pick_date',
	CUSTOMER: 'customer',
	ACTIONS: 'actions',
	COMPANY: 'company',
	ID: 'booking_id',
	REASON: 'reason',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'common.fields.cancellation_reason',
		accessor: COLUMNS_ACCESSORS.REASON,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.compensation_amount',
		accessor: COLUMNS_ACCESSORS.COMPENSATION_AMOUNT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.created_at',
		accessor: COLUMNS_ACCESSORS.CREATED_AT,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
	},
	{
		Header: 'common.fields.company_name',
		accessor: COLUMNS_ACCESSORS.COMPANY,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
