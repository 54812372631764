/* eslint-disable no-mixed-spaces-and-tabs */
import i18next from 'i18next';
import { format } from 'date-fns';

// import helpers
import { DEFAULT_DATE_FORMAT, EMPTY_VALUE, getPaymentDay } from 'helpers';

const sectionName = 'customer_balance.table.headers';

export const columns = [
	{
		Header: `${sectionName}.customer`,
		accessor: 'customer',
		valueExtractor: ({ customer_name, customer_phone }) =>
			`${customer_name} ${customer_phone}`,
	},
	{
		Header: `${sectionName}.booking_id`,
		accessor: 'booking_individual_number',
	},
	{
		Header: `common.fields.pickup_date`,
		accessor: 'pickup_date',
	},
	{
		Header: `common.fields.dropoff_date`,
		accessor: 'dropoff_date',
		valueExtractor: ({ dropoff_date }) =>
			format(new Date(dropoff_date), DEFAULT_DATE_FORMAT),
	},
	{
		Header: `common.fields.early_return_date`,
		accessor: 'early_return_date',
		valueExtractor: ({ early_return_details: earlyReturnDetails }) => {
			const { return_date: earlyReturnDate } = earlyReturnDetails || {};
			return earlyReturnDate || i18next.t('common.not_available_shorthand');
		},
	},
	{
		Header: `common.fields.early_return_fees`,
		accessor: 'early_return_fees',
		valueExtractor: ({ early_return_transaction }) => {
			const { amount_gross: amountGross } = early_return_transaction || {};
			return amountGross || i18next.t('common.not_available_shorthand');
		},
	},
	{
		Header: `common.fields.number_of_months`,
		accessor: 'number_of_months',
		valueExtractor: ({
			has_early_return_state: hasEarlyReturnState,
			early_return_details: earlyReturnDetails,
			period_duration: periodDuration,
		}) => {
			const {
				old_subscription_period: oldSubscriptionPeriod,
				new_subscription_period: newSubscriptionPeriod,
			} = earlyReturnDetails || {};

			return hasEarlyReturnState
				? `${i18next.t('common.previous')}: ${
						oldSubscriptionPeriod || EMPTY_VALUE
				  }, ${i18next.t('common.new')}: ${
						newSubscriptionPeriod || EMPTY_VALUE
				  }`
				: periodDuration;
		},
	},
	{
		Header: `common.fields.status`,
		accessor: 'booking_status',
		valueExtractor: ({ booking_status: status }) =>
			i18next.t(`bookings.booking_states.${status}`),
	},
	{
		Header: `${sectionName}.payment_success`,
		accessor: 'last_month_payment_success',
		valueExtractor: ({ last_month_payment_success: isSuccess }) =>
			i18next.t(`common.${isSuccess ? 'yes' : 'no'}`),
	},
	{
		Header: `${sectionName}.no_of_tries`,
		accessor: 'last_month_payment_tries',
	},
	{
		Header: `${sectionName}.out_standing`,
		accessor: 'debt_balance',
		valueExtractor: ({ debt_balance: debtBalance }) =>
			`${i18next.t('currency.sar')} ${debtBalance}`,
	},
	{
		Header: `${sectionName}.total`,
		accessor: 'new_price',
		valueExtractor: ({
			has_early_return_state: hasEarlyReturnState,
			early_return_details: earlyReturnDetails,
			debt_balance: debtBalance,
		}) => {
			const totalPrice =
				Number(debtBalance) +
				Number(earlyReturnDetails?.early_return_transaction?.amount_gross);

			return i18next.t('currency.price', {
				amount: hasEarlyReturnState ? totalPrice : debtBalance,
			});
		},
	},
	{
		Header: `${sectionName}.company`,
		accessor: 'company_name',
	},
	{
		Header: `${sectionName}.is_settled`,
		accessor: 'is_settled',
		valueExtractor: ({ is_settled: isSettled }) =>
			i18next.t(`common.${isSettled ? 'yes' : 'no'}`),
	},
	{
		Header: `${sectionName}.settled_dues`,
		accessor: 'success_payments_count',
		valueExtractor: ({
			success_payments_count: successPaymentsCount,
			total_payments_count: totalPaymentsCount,
		}) => `${successPaymentsCount}/${totalPaymentsCount}`,
	},
	{
		Header: 'customer_balance.table.headers.next_payment_due',
		accessor: 'payment_day',
		valueExtractor: ({ days_to_payment: daysToPayment }) =>
			getPaymentDay({ daysToPayment, isNextPayment: true }),
	},
	{
		Header: `${sectionName}.not_settled_dues`,
		accessor: 'failed_payments_count',
		valueExtractor: ({
			failed_payments_count: failedPaymentsCount,
			total_payments_count: totalPaymentsCount,
		}) => `${failedPaymentsCount}/${totalPaymentsCount}`,
	},
	{
		Header: 'common.fields.cancellation_reason',
		accessor: 'cancellation_reason',
	},
	{
		Header: 'common.fields.cancellation_fees',
		accessor: 'cancellation_fees',
	},
	{
		Header: 'common.fields.total_amount',
		accessor: 'total_amount_paid',
	},
	{
		Header: 'common.fields.city',
		accessor: 'city',
	},
	{
		Header: 'common.fields.vehicle',
		accessor: 'vehicle',
	},
	{
		Header: 'common.fields.monthly_payment_amount',
		accessor: 'amount_gross',
	},
	{
		Header: 'common.fields.period',
		accessor: 'period',
		valueExtractor: ({
			pickup_date: pickupDate,
			pickup_time: pickTime,
			dropoff_date: dropoffDate,
		}) => `${pickupDate} ${pickTime} - ${dropoffDate}`,
	},
	{
		Header: 'common.fields.next_payment_date',
		accessor: 'next_payment_date',
	},
];
