import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	newStyleContainer: {
		width: '100%',
		maxWidth: '100%',
		minWidth: '100%',
	},
	buttonContainer: {
		display: 'flex',
		width: '100%',
		gap: '16px',
	},
	newStyleButton: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		backgroundColor: theme.palette.gray.input,
		color: theme.palette.text[2],
		cursor: 'pointer',
		padding: '14px',
		borderRadius: '6px',
		marginTop: '8px',
		transform: 'none !important',
		height: '49px',
	},
	buttonContent: {
		position: 'relative',
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-around',
		minWidth: '155px',
		width: '100%',
		cursor: 'pointer',
		padding: '10px',
		background: theme.palette.primary.main,
		color: theme.palette.white,
		borderRadius: '6px',
	},
	buttonContentLarge: {
		width: '100%',
		padding: '12px 24px',
		color: theme.palette.text[3],
		backgroundColor: theme.palette.background[6],
		minHeight: '48px',
		transform: 'scale(1) !important',
		justifyContent: 'center',

		'& svg': {
			marginRight: '8px',
		},
	},
	inputFile: {
		opacity: 0,
		pointerEvents: 'none',
	},
	label: {
		color: theme.palette.gray.inputLabel,
		fontSize: '12px',
		fontWeight: 600,
		marginBottom: '8px',
	},
	error: {
		marginTop: theme.spacing(1),
		color: theme.palette.error.main,
	},
	loadingWrapper: {
		position: 'absolute',
		width: '100%',
		height: '100%',
		display: 'grid',
		placeItems: 'center',
	},
	progress: {
		color: theme.palette.text[2],
	},
	previewWrapper: {
		display: 'flex',
		alignItems: 'center',
		gap: '10px',
	},
	closeIcon: {
		color: theme.palette.error.medium,
	},
}));
