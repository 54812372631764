import React from 'react';

// Import store
import { fetchReturnBookings } from 'store/actions';

// Import utils and hepers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';

// Import components
import { InnerNavbarElements, TableBodyRow, Filters } from './components';

export const ReturnBookings = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchReturnBookings}
			title="nav.customer_care.return_bookings"
			filtersBar={<Filters />}
			tableNavigation={<InnerNavbarElements />}
		/>
	);
};
