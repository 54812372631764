import React from 'react';
import { func, object } from 'prop-types';

// Import context
import { BookingStatusActionsProvider } from 'components/context';

// Import components
import { BookingStatusActionsContent } from './BookingStatusActionsContent';

export const BookingStatusActions = ({ booking, onChange }) => {
	return (
		<BookingStatusActionsProvider booking={booking} onChange={onChange}>
			<BookingStatusActionsContent />
		</BookingStatusActionsProvider>
	);
};

BookingStatusActions.propTypes = {
	booking: object.isRequired,
	onChange: func.isRequired,
};
