import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		color: theme.palette.text.primary,
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(4),
		textTransform: 'capitalize',
	},
	emptyState: {
		color: theme.palette.gray.medium,
		fontSize: 16,
	},
}));
