import i18next from 'i18next';

// Import helpers
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';

export const getBookingInfoFields = () => [
	{ label: i18next.t(`${transPrefix}.rental_type`), accessor: 'rental_type' },
	{
		label: i18next.t(`${transPrefix}.service_method`),
		accessor: 'service_method',
	},
	{ label: i18next.t(`${transPrefix}.start_date`), accessor: 'start_date' },
	{ label: i18next.t(`${transPrefix}.end_date`), accessor: 'end_date' },
	{
		label: i18next.t(`${transPrefix}.pickup_and_dropoff_time`),
		accessor: 'pick_and_drop_time',
	},
	{ label: i18next.t(`${transPrefix}.period`), accessor: '_period' },
	{
		label: i18next.t(`${transPrefix}.customer_name`),
		accessor: 'customer_name',
	},
	{
		label: i18next.t(`${transPrefix}.mobile_number`),
		accessor: 'mobile_number',
	},
	{
		label: i18next.t(`${transPrefix}.license_number`),
		accessor: 'license_number',
	},
	{
		label: i18next.t(`${transPrefix}.license_expiray_date`),
		accessor: 'license_expiray_date',
	},
	{
		label: i18next.t(`${transPrefix}.license_photo`),
		accessor: 'license_photo',
	},
	{
		label: i18next.t(`${transPrefix}.customer_location`),
		accessor: 'customer_location',
	},
	{
		label: i18next.t(`${transPrefix}.payment_method`),
		accessor: 'payment_method',
	},
];

export const getCarAndCompanyFields = () => [
	{
		label: i18next.t(`${transPrefix}.rental_company`),
		accessor: 'rental_company',
	},
	{ label: i18next.t(`${transPrefix}.office`), accessor: 'office' },
	{ label: i18next.t(`${transPrefix}.city`), accessor: 'city' },
	{ label: i18next.t(`${transPrefix}.car`), accessor: 'car' },
	{
		label: i18next.t(`${transPrefix}.additional_services`),
		accessor: 'additional_services',
	},
];

export const getCostSummaryFields = (extraFields) => [
	{
		label: i18next.t(`${transPrefix}.daily_rent_price`),
		accessor: '_daily_rent_price',
	},
	{
		label: i18next.t(`${transPrefix}.total_rent_price`),
		accessor: 'total_rent_price',
	},
	...extraFields,
	{ label: i18next.t(`${transPrefix}.vat`), accessor: '_vat' },
	{ label: i18next.t(`${transPrefix}.total_cost`), accessor: 'total_cost' },
];

export const getSelectedExtraServicesStr = (services) => {
	if (!services) return '';

	return services
		.filter(({ selected }) => selected)
		.map(({ label }) => label)
		.join(' - ');
};

export const getExtraCostSummaryFields = ({ services }) => {
	if (!services) {
		return { summaryExtraFields: [], summaryExtraFieldsData: {} };
	} else {
		const filteredServices = services.filter((service) => service.selected);
		const summaryExtraFieldsData = {};
		const summaryExtraFields = filteredServices.map(({ label, type }) => ({
			label,
			accessor: type,
		}));

		filteredServices.forEach(({ type, price }) => {
			summaryExtraFieldsData[type] = price.gross;
		});
		return { summaryExtraFields, summaryExtraFieldsData };
	}
};
