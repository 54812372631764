import { useQuery } from 'react-query';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useLeasingCarsImport = ({
	isImportAllowed,
	importDataFunction,
	handleImportError,
	handleImportSuccess,
	handleImportSettled,
}) => {
	const { isLoading: isImportLoading } = useQuery(
		QUERY_KEYS.LEASING.OFFICE_LEASING_CARS_IMPORT_QUERY,
		importDataFunction,
		{
			refetchOnWindowFocus: false,
			enabled: isImportAllowed,
			onError: handleImportError,
			onSuccess: handleImportSuccess,
			onSettled: handleImportSettled,
		}
	);

	return { isImportLoading };
};
