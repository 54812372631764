import i18next from 'i18next';

// Import assets
import { InsuranceIcon, SpeedMeter } from 'assets/icons';

export const getServices = (car) => {
	const { free_kms: freeKms, insurance } = car;
	const services = [
		insurance && {
			text: i18next.t('bookings.create_booking.full_insurance'),
			icon: InsuranceIcon,
		},
		{
			text: `${freeKms} ${i18next.t('bookings.create_booking.km')}`,
			icon: SpeedMeter,
		},
	];
	return services.filter((item) => item);
};
