import React from 'react';
import { object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import utils
import { getPaymentDay } from 'helpers';

export const PaymentDayTableCell = ({ cell }) => {
	const { days_to_payment: daysToPayment, next_payment_date: nextPaymentDate } =
		cell.row.original;

	const daysToPaymentText = getPaymentDay({ daysToPayment, shortText: true });

	return (
		<TableCell {...cell.getCellProps()}>
			<Typography>{nextPaymentDate}</Typography>
			<Typography>{daysToPaymentText}</Typography>
		</TableCell>
	);
};

PaymentDayTableCell.propTypes = {
	cell: object.isRequired,
};
