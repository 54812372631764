import React from 'react';
import { Grid } from '@material-ui/core';
import { bool, string } from 'prop-types';

// Import utilities
import { useSelects } from './useSelects';

// Import components
import { LoadingWrapper } from 'components/elements/molecules';
import { DriversSelect } from '../DriversSelect';

export const Selects = ({ bookingUuid, open }) => {
	const { isFetching, selects } = useSelects({ bookingUuid, open });

	if (isFetching) {
		return <LoadingWrapper />;
	}

	return selects.map(({ deliveryType, drivers, isVisible }) =>
		isVisible ? (
			<Grid key={deliveryType} item xs={12}>
				<DriversSelect drivers={drivers} deliveryType={deliveryType} />
			</Grid>
		) : null
	);
};

Selects.propTypes = {
	bookingUuid: string,
	open: bool,
};
