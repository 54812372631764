import React from 'react';
import { element } from 'prop-types';
import { Grid } from '@material-ui/core';

export const InnerNavbar = ({
	additionalNavigationElements,
	tableNavigation,
}) => {
	return (
		<Grid container alignItems="center" justifyContent="space-between">
			<Grid item>{tableNavigation}</Grid>
			<Grid item>{additionalNavigationElements}</Grid>
		</Grid>
	);
};

InnerNavbar.propTypes = {
	tableNavigation: element,
	additionalNavigationElements: element,
};
