import React from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { ChipLabel } from 'components/elements';

// Import utils
import { useCompensationsRecords } from './useCompensationsRecords';
import { useTranslations } from 'components/utilities';

// Import context
import { useCompensationFormProvider } from '../../context';

// Import styles
import { useStyles } from './CompensationsRecords.styles';
import { useStyles as useGeneralStyles } from '../../Compensation.styles';

export const CompensationsRecords = () => {
	const classes = useStyles();

	const generalStyles = useGeneralStyles();

	const { t } = useTranslations();

	const { formattedCompensations } = useCompensationsRecords();

	const { editCompensation } = useCompensationFormProvider();

	return (
		<Box className={classes.container}>
			{formattedCompensations.map((compensation) => {
				const {
					uuid,
					type,
					amountWithMethod,
					status,
					allow_edit: allowEdit,
					reason,
				} = compensation;

				return (
					<Grid
						container
						key={uuid}
						className={classes.recordContainer}
						spacing={2}
					>
						<Grid item xs={3}>
							<Typography variant="body1" className={generalStyles.type}>
								{type}
							</Typography>
							{reason && (
								<Typography variant="body1" className={generalStyles.reason}>
									{t(`bookings.compensation_tab.reasons.${reason}`)}
								</Typography>
							)}
						</Grid>
						<Grid item xs={3}>
							<ChipLabel
								label={t(`bookings.customer_compensation.statuses.${status}`)}
								className={clsx(
									generalStyles.statusChip,
									generalStyles[status]
								)}
							/>
						</Grid>
						<Grid item xs={3} className={classes.amountContainer}>
							<Typography variant="body1" className={classes.amount}>
								{amountWithMethod}
							</Typography>
						</Grid>
						<Grid item xs={3} className={generalStyles.editContainer}>
							{allowEdit && (
								<Button
									variant="outlined"
									color="primary"
									className={generalStyles.editButton}
									onClick={() => editCompensation(compensation)}
								>
									{t('common.buttons.edit')}
								</Button>
							)}
						</Grid>
					</Grid>
				);
			})}
		</Box>
	);
};
