import React, { forwardRef } from 'react';
import { IconButton as MuiIconButton } from '@material-ui/core';
import { arrayOf, element, oneOfType, string } from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from './IconButton.styles';

// eslint-disable-next-line react/display-name
const IconButton = forwardRef((props, ref) => {
	const classes = useStyles();

	return (
		<MuiIconButton
			className={clsx([classes.icon, props.className])}
			data-testid="icon_button"
			ref={ref}
			{...props}
		>
			{props.children}
		</MuiIconButton>
	);
});

IconButton.propTypes = {
	className: string,
	children: oneOfType([element, arrayOf(element)]),
};

export default IconButton;
