import React from 'react';

// Import components
import { Button, Grid } from 'components/elements';
import { LeasingImportExportDialog } from './components';

// Import utils
import { useOuterToolbarElements } from './useOuterToolbarElements';

export const OuterToolbarElements = () => {
	const { isDialogOpen, toggleDialog, officeId, goToAddCar } =
		useOuterToolbarElements();

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Button
					text="common.buttons.add_car"
					variant="contained"
					onClick={goToAddCar}
				/>
			</Grid>
			<Grid item>
				<Button
					text="common.buttons.upload_car"
					variant="contained"
					onClick={toggleDialog}
				/>
			</Grid>

			<LeasingImportExportDialog
				isDialogOpen={isDialogOpen}
				handleClose={toggleDialog}
				officeId={officeId}
			/>
		</Grid>
	);
};
