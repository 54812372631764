// Import helpers
import { DELIVERY_STATUS, YMD_SLASH_TIME_FORMAT } from 'helpers';
import { format } from 'date-fns';

export const useStepper = ({ deliveryData }) => {
	const { PENDING, CANCELLED, PROLONGED } = DELIVERY_STATUS;

	const { states } = deliveryData || {};

	const getFilteredSteps = () =>
		states?.filter(({ state }) => state !== PENDING)?.reverse();

	const steps = getFilteredSteps();

	const isDeliveryCancelled = (state) => state === CANCELLED;

	const getCancelReason = () =>
		steps?.find(({ state }) => state === CANCELLED)?.description;

	const cancelReasonIndex = steps?.findIndex(
		({ state }) => state === CANCELLED
	);

	const cancelReason = getCancelReason();

	const activeSteps = steps?.length;

	const getUpdatedAtDateFormatted = (date) =>
		format(new Date(date), YMD_SLASH_TIME_FORMAT);

	const isLabelIconRed = (state) => state === CANCELLED || state === PROLONGED;

	return {
		isDeliveryCancelled,
		cancelReasonIndex,
		cancelReason,
		activeSteps,
		steps,
		getUpdatedAtDateFormatted,
		isLabelIconRed,
	};
};
