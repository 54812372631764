// Import helpers
import { FIELD_TYPES } from 'helpers';

const serviceIsSelectedByDefault = (availServiceType, services) => {
	return services.some(
		({ type, selected }) => selected && type === availServiceType
	);
};

export const getFields = ({
	availableServices,
	isPickup,
	isSelected,
	services,
}) => {
	const fields = availableServices.map(
		({ base_nett_price: price, label, type }) => {
			if (isPickup && (type === 'delivery' || type === 'delivery_drop')) {
				return null;
			} else if (serviceIsSelectedByDefault(type, services)) {
				return {
					price: price,
					type: FIELD_TYPES.CHECKBOX,
					name: `additional_services.${type}`,
					label: label,
					checked: true,
				};
			} else if (type === 'dropCity') return null;

			return {
				price: price,
				type: FIELD_TYPES.CHECKBOX,
				name: `additional_services.${type}`,
				label: label,
				checked: isSelected(`additional_services.${type}`),
			};
		}
	);
	return fields.filter((item) => item);
};
