import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	arrowIcon: {
		color: theme.palette.text[2],
		width: '14px',
		height: '14px',
	},
	arrowButton: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		backgroundColor: theme.palette.white,
		borderRadius: '50%',
		width: '38px',
		height: '38px',
		position: 'absolute',
		border: `1px solid ${theme.palette.background[3]}`,
		top: '-70px',

		'&:hover': {
			backgroundColor: theme.palette.white,
		},
	},

	arrowNext: {
		right: 0,
	},

	arrowPrev: {
		right: '45px',
	},

	disabled: {
		opacity: 0.6,
	},
}));
