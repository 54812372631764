import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const OfficeIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			id="Path"
			d="M3 21H21"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_2"
			d="M3.99995 20.9999L4.00005 10.9553"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_3"
			d="M20.0742 21.0001L20.0743 10.9412"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_4"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M21.7236 7.47765C22.0187 8.03345 22.0774 8.68474 21.8863 9.28433C21.5559 10.3245 20.5797 11.0228 19.4885 10.9994C18.3973 10.9761 17.4519 10.2368 17.1662 9.18341C17.1481 9.12223 17.0919 9.08025 17.0281 9.08025C16.9643 9.08025 16.9081 9.12223 16.89 9.18341C16.5982 10.2555 15.6246 10.9995 14.5135 10.9994C13.4024 10.9993 12.4289 10.2551 12.1374 9.1829C12.1193 9.12169 12.0631 9.07968 11.9993 9.07968C11.9355 9.07968 11.8793 9.12169 11.8612 9.1829C11.5695 10.2551 10.596 10.9992 9.48483 10.9991C8.37366 10.9991 7.40016 10.255 7.10858 9.18273C7.09045 9.12156 7.03425 9.0796 6.97045 9.0796C6.90665 9.0796 6.85045 9.12156 6.83232 9.18273C6.54658 10.236 5.60124 10.9752 4.51016 10.9986C3.41908 11.0219 2.44296 10.3239 2.1124 9.28381C1.92126 8.68412 1.97992 8.0327 2.27512 7.47681L3.98184 4.09822C4.32207 3.4247 5.01242 3 5.767 3H18.2313C18.9859 3 19.6762 3.4247 20.0165 4.09822L21.7236 7.47765Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_5"
			d="M14 21.0001V10.9412"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_6"
			d="M14 16H4"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
