import React, { createContext, useState } from 'react';
import { node } from 'prop-types';

// Import utils
import { useToggle } from 'components/utilities';

export const OfficeRatingContext = createContext(null);

export const OfficeRatingProvider = ({ children }) => {
	const { on: isSubmitDialogOpen, toggle: toggleSubmitDialog } = useToggle();
	const [isSuccessSubmit, setSuccessSubmit] = useState(false);
	const [isSubmittingLoading, setSubmittingLoading] = useState(false);

	return (
		<OfficeRatingContext.Provider
			value={{
				toggleSubmitDialog,
				setSubmittingLoading,
				setSuccessSubmit,
				isSubmittingLoading,
				isSubmitDialogOpen,
				isSuccessSubmit,
			}}
		>
			{children}
		</OfficeRatingContext.Provider>
	);
};

OfficeRatingProvider.propTypes = {
	children: node,
};
