import React from 'react';
import { object } from 'prop-types';
import { TableCell, Box, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './OfficeWithPreviewTableCell.styles';

// Import components
import { PreviewPopup } from './components';

export const OfficeWithPreviewTableCell = ({ cell }) => {
	const classes = useStyles();

	const { office_name: officeName } = cell.row.original;

	return (
		<TableCell {...cell.getCellProps()} style={{ minWidth: '170px' }}>
			<Box className={classes.container}>
				<PreviewPopup cell={cell} />
				<Typography className={classes.officeName}>{officeName}</Typography>
			</Box>
		</TableCell>
	);
};

OfficeWithPreviewTableCell.propTypes = {
	cell: object.isRequired,
};
