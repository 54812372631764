import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const InformationIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M7.99929 5.33268C7.90729 5.33268 7.83263 5.40735 7.83329 5.49935C7.83329 5.59135 7.90796 5.66602 7.99996 5.66602C8.09196 5.66602 8.16663 5.59135 8.16663 5.49935C8.16663 5.40735 8.09196 5.33268 7.99929 5.33268"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M8 14V14C4.686 14 2 11.314 2 8V8C2 4.686 4.686 2 8 2V2C11.314 2 14 4.686 14 8V8C14 11.314 11.314 14 8 14Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.99996 8V11.3333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
