import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: '32px 24px',
	},
	strong: {
		fontSize: '12px',
		fontWeight: 600,
		color: theme.palette.text[4],
		marginRight: '4px',
	},
	description: {
		fontSize: '12px',
		fontWeight: 400,
		color: theme.palette.text[2],
	},
	icon: {
		color: theme.palette.warning.mid,
	},
}));
