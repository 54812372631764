import * as yup from 'yup';
import { t } from 'i18next';

// Import helpers
import { FAQ_PAGE_LOCATION } from 'helpers';

const requiredText = t('common.fields.required_field');
const numberText = t('common.validation.number');
const minimumText = (value) => t('common.fields.minimum', { value });

const hideServiceTypesIfPageValue = [FAQ_PAGE_LOCATION.CAR_DETAILS, 'all'];

export const validationSchema = yup.object({
	details: yup.object({
		name: yup.object({
			en: yup.string().required(requiredText),
			ar: yup.string().required(requiredText),
		}),
		description: yup.object({
			en: yup.string().required(requiredText),
			ar: yup.string().required(requiredText),
		}),
	}),
	order: yup
		.number()
		.typeError(numberText)
		.integer()
		.required(requiredText)
		.min(1, minimumText(1)),
	image: yup.object({
		file_name: yup.string().nullable(),
		file_path: yup.string().required(requiredText),
	}),
	faq_location: yup
		.array()
		.min(1, requiredText)
		.typeError(requiredText)
		.of(yup.string())
		.required(requiredText),
	service_type: yup.array().when('faq_location', {
		is: (faq_location) =>
			faq_location.some((service) =>
				hideServiceTypesIfPageValue.includes(service)
			),
		then: yup
			.array()
			.min(1, requiredText)
			.typeError(requiredText)
			.of(yup.string())
			.required(requiredText),
		otherwise: yup.array().nullable().notRequired(),
	}),
});
