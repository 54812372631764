import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	dialog: {
		'& .MuiPaper-root': {
			padding: '16px 16px 24px 0',
			width: '50%',
			maxWidth: '510px',
		},
	},
	title: {
		color: theme.palette.text.inputLabel,
		fontSize: '20px',
		fontWeight: 600,
	},
	content: {
		color: theme.palette.text.inputLabel,
		fontSize: '14px',
		fontWeight: 400,
		paddingTop: '8px',
		paddingBottom: '40px',
	},
}));
