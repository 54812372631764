import React from 'react';
import { TableCell } from '@material-ui/core';
import { object } from 'prop-types';

// Import components
import { ChipLabel } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

export const IsSubscriptionSettledTableCell = ({ cell }) => {
	const { t } = useTranslations();

	const { is_settled: isSettled } = cell.row.original;

	const label = t(
		`customer_balance.table.content.${isSettled ? 'settled' : 'unsettled'}`
	);

	const color = isSettled ? 'success' : 'error';

	return (
		<TableCell {...cell.getCellProps()}>
			<ChipLabel label={label} color={color} />
		</TableCell>
	);
};

IsSubscriptionSettledTableCell.propTypes = {
	cell: object.isRequired,
};
