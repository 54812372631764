import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: '10px',
		border: 'solid 1px',
		borderRadius: '8px',
		padding: '3px',
		margin: '8px',
		whiteSpace: 'nowrap',
		borderColor: theme.palette.background[3],
		fontWeight: 600,
		minWidth: '20px',
		display: 'flex',
		justifyContent: 'center',
	},
}));
