export const useCarCard = (car) => {
	const {
		vehicle_maker: vehicleMaker,
		vehicle_model: vehicleModel,
		vehicle_year: vehicleYear,
		price,
		company_name: companyName,
		uuid,
		office_name: officeName,
	} = car || {};

	const carName = `${vehicleMaker} ${vehicleModel} ${vehicleYear}`;

	return {
		uuid,
		companyName,
		carName,
		price,
		officeName,
	};
};
