import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { Field, useForm } from 'react-final-form';

// Import utils and helpers
import {
	useCommonFields,
	useProfile,
	useTranslations,
} from 'components/utilities';
import { useFormContent } from './useFormContent';
import { FIELDS } from './helpers';

// Import components
import { RatingFieldAdapter } from 'components/elements';
import {
	RatingHistoryDialog,
	ConfirmationDialog,
	ResetButton,
} from '../../components';

// Import styles
import { useStyles } from './FormContent.styles';

// Import assets
import { ErrorIcon } from 'assets/icons';

export const FormContent = () => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const { submit } = useForm();
	const classes = useStyles();
	const { isAdmin } = useProfile();
	const {
		isRatingHistorDialogOpen,
		isSubmitButtonDisabled,
		isDescriptionRequired,
		isRatingFieldDisabled,
		isRatingFieldReadOnly,
		isSubmittingLoading,
		isSubmitDialogOpen,
		isSuccessSubmit,
		isReset,
		toggleRatingHistoryDialog,
		toggleSubmitDialog,
		clearDialogState,
		officeRating,
		caption,
		reasons,
	} = useFormContent();

	return (
		<Grid container spacing={2} className={classes.container}>
			{officeRating && (
				<Grid item xs={12}>
					<Grid container justifyContent="space-between" alignItems="center">
						<Grid item>
							<Grid container spacing={2}>
								{isReset && (
									<Grid item>
										<ErrorIcon className={classes.warningIcon} />
									</Grid>
								)}
								<Grid item>
									<Typography className={classes.caption} variant="h6">
										{caption}
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item>
							<RatingHistoryDialog
								toggle={toggleRatingHistoryDialog}
								isOpen={isRatingHistorDialogOpen}
							/>
						</Grid>
					</Grid>
				</Grid>
			)}
			<Grid item xs={12}>
				<Field
					component={RatingFieldAdapter}
					name="rating"
					size="large"
					disabled={isRatingFieldDisabled}
					readOnly={isRatingFieldReadOnly}
				/>
			</Grid>

			{FIELDS({ reasons, isDescriptionRequired, isAdmin }).map(renderFields)}

			<Grid item xs={12} className={classes.buttonsWrapper}>
				{!isReset && <ResetButton />}
				<Button
					color="primary"
					variant="contained"
					type="button"
					size="large"
					onClick={toggleSubmitDialog}
					disabled={isSubmitButtonDisabled}
				>
					{t('common.buttons.submit')}
				</Button>
			</Grid>

			<ConfirmationDialog
				isLoading={isSubmittingLoading}
				isSuccess={isSuccessSubmit}
				isOpen={isSubmitDialogOpen}
				closeAction={clearDialogState}
				toggle={toggleSubmitDialog}
				submitAction={submit}
			/>
		</Grid>
	);
};
