import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '@material-ui/core';

// Import components
import { Grid } from 'components/elements';
import { Export } from '../../components';

// Import utils
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

// Import assets
import { CarIcon } from 'assets/icons';

export const OuterToolbarElements = () => {
	const { t } = useTranslations();
	const { push } = useHistory();

	const goToAddCarListUrl = () => push(URLS.addCarListUrl);

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Export />
			</Grid>
			<Grid item>
				<Button
					onClick={goToAddCarListUrl}
					startIcon={<CarIcon />}
					variant="contained"
					color="primary"
				>
					{t('common.buttons.new_car')}
				</Button>
			</Grid>
		</Grid>
	);
};
