import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import selectors
import { bookingBillingsSelector } from 'store/selectors';

// Import components
import { CollapsableCard } from 'components/elements';
import { SubSection, BottomBar } from './components';

// Import styles
import { useStyles } from './ExtensionInvoice.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const ExtensionInvoice = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const billings = useSelector(bookingBillingsSelector);

	const convertedBillings = billings
		.filter(({ type }) => type === 'booking_extend')
		.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));

	if (!convertedBillings.length) return null;

	return (
		<CollapsableCard
			headerClassName={classes.title}
			cardClassName={classes.card}
			contentClassName={classes.content}
			title={t('bookings.preview_booking.invoice.extension_invoice')}
			initialOpen={false}
		>
			<Grid container>
				{convertedBillings.map((data, index) => (
					<Grid item xs={12} key={data.uuid}>
						<SubSection data={data} index={index} />
					</Grid>
				))}
				<Grid item xs={12}>
					<BottomBar />
				</Grid>
			</Grid>
		</CollapsableCard>
	);
};
