import React from 'react';

// Import utils and helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BOOKING_CANCELLATION_STATES } from 'helpers';
import { TableRPC } from 'components/utilities';
import { BUTTONS } from './helpers';

// Import store
import { fetchLeasingCancellation } from 'store/actions';

// Import components
import { Filters, TableBodyRow } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

export const LeasingCancellation = () => {
	const { REVIEW } = BOOKING_CANCELLATION_STATES;
	const defaultParams = `?columns[59][search][value]=${REVIEW}`;

	return (
		<TableRPC
			fetchDataTableAction={fetchLeasingCancellation}
			title="nav.operations.leasing_cancellation"
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			filtersBar={<Filters />}
			columns={columns}
			tableNavigation={
				<InnerNavbarElementsButtons
					filterPath="cancel_reason_status"
					buttons={BUTTONS}
					defaultFilterState={REVIEW}
				/>
			}
			defaultQueryParams={defaultParams}
		/>
	);
};
