import React from 'react';
import PropTypes from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import helpers
import { AR } from 'helpers';

const RatingReasonTableCell = ({ cell }) => {
	const {
		i18n: { language },
	} = useTranslations();

	const ratingReasons = cell.row.original.rating_reasons || [];

	const ratingReasonsString = ratingReasons
		.map(({ reason_en, reason_ar }) =>
			language === AR ? reason_ar : reason_en
		)
		.join(', ');

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			style={{ minWidth: 200 }}
		>
			{ratingReasonsString}
		</TableCell>
	);
};

RatingReasonTableCell.propTypes = {
	cell: PropTypes.object.isRequired,
};

export default RatingReasonTableCell;
