import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

export const CancelReasonTableCell = ({ cell }) => {
	const {
		i18n: { language },
	} = useTranslations();

	const cancelReason = cell.row.original.cancel_reason;

	const cancelReasonName = cancelReason?.name[language];

	return (
		<TableCell
			style={{ minWidth: '280px' }}
			key={cell.column.id}
			{...cell.getCellProps()}
		>
			{cancelReasonName}
		</TableCell>
	);
};

CancelReasonTableCell.propTypes = {
	cell: object.isRequired,
};
