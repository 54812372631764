// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FILTER_FIELDS = [
	{
		type: FIELD_TYPES.SWITCH,
		label: 'common.fields.rated',
		name: 'has_rating',
		md: 'auto',
		xs: 'auto',
	},
	{
		type: FIELD_TYPES.SWITCH,
		label: 'common.fields.assigned',
		name: 'has_driver_assigned',
		md: 'auto',
		xs: 'auto',
	},
];
