import React from 'react';
import { number, oneOfType, string } from 'prop-types';

// Import components
import { DateRangeInput, Grid } from 'components/elements';

// Import helpers
import { conditionallyRequired } from 'helpers';

const DateRangeFilter = (props) => {
	const { xs = 12, md = 6 } = props;

	return (
		<Grid item md={md} xs={xs}>
			<DateRangeInput {...props} />
		</Grid>
	);
};

DateRangeFilter.propTypes = {
	nameFrom: string.isRequired,
	nameUntil: string.isRequired,
	combinedInputs: conditionallyRequired,
	label: conditionallyRequired,
	labelFrom: conditionallyRequired,
	labelUntil: conditionallyRequired,
	xs: oneOfType([number, string]),
	md: oneOfType([number, string]),
};

export default DateRangeFilter;
