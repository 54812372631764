import { useFormState } from 'react-final-form';

// Import helpers and utils
import { getMinDateFrom, getMinDateTo } from './helpers';
import { FIELD_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';

export const useCampaignConditionsCard = () => {
	const { values } = useFormState();
	const { t } = useTranslations();

	const {
		duration_from: currentDurationFrom,
		booking_created_from: currentBookingCreatedFrom,
		booking_pick_date_from: currentBookingPickDateFrom,
	} = values.condition;

	const sectionName = 'condition';

	const labelSectionName = 'campaigns.form.condition';

	const FIELDS = [
		{
			type: FIELD_TYPES.DATE,
			name: `${sectionName}.duration_from`,
			label: `${labelSectionName}.duration_from`,
			required: true,
			md: 6,
			minDate: getMinDateFrom(),
		},
		{
			type: FIELD_TYPES.DATE,
			name: `${sectionName}.duration_to`,
			label: `${labelSectionName}.duration_to`,
			required: true,
			md: 6,
			minDate: getMinDateTo(currentDurationFrom),
		},
		{
			type: FIELD_TYPES.DATE,
			name: `${sectionName}.booking_created_from`,
			label: `${labelSectionName}.booking_created_from`,
			required: true,
			md: 6,
			minDate: getMinDateFrom(),
		},
		{
			type: FIELD_TYPES.DATE,
			name: `${sectionName}.booking_created_to`,
			label: `${labelSectionName}.booking_created_to`,
			required: true,
			md: 6,
			minDate: getMinDateTo(currentBookingCreatedFrom),
		},

		{
			type: FIELD_TYPES.DATE,
			name: `${sectionName}.booking_pick_date_from`,
			label: `${labelSectionName}.booking_pick_date_from`,
			required: true,
			md: 6,
			minDate: getMinDateFrom(),
		},
		{
			type: FIELD_TYPES.DATE,
			name: `${sectionName}.booking_pick_date_to`,
			label: `${labelSectionName}.booking_pick_date_to`,
			required: true,
			md: 6,
			minDate: getMinDateTo(currentBookingPickDateFrom),
		},
		{
			type: FIELD_TYPES.NUMBER,
			label: 'campaigns.form.condition.total_budget',
			name: `${sectionName}.total_budget_amount.nett_price`,
			required: true,
			md: 6,
			endAdornment: t('currency.sar'),
			min: 0.01,
			step: 0.01,
		},
	];

	return { sectionName, FIELDS };
};
