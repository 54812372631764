import React from 'react';
import { Typography } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

export const PermissionFallback = () => {
	const { t } = useTranslations();

	return (
		<Typography variant="h5">
			{t('bookings.assign_urgent_form.urgent_not_provided')}
		</Typography>
	);
};
