import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		height: '60px',
	},
	titleContainer: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
	},
	officeContainer: {
		display: 'flex',
		gap: '8px',
		alignItems: 'center',
	},
	title: {
		color: theme.palette.text.inputLabel,
		fontSize: '18px',
		fontWeight: 500,
	},
	offer: {
		display: 'flex',
		alignItems: 'center',
		gap: '6px',
		backgroundColor: theme.palette.gold[10],
		padding: '6px 8px',
		borderRadius: '6px',
	},
	offerText: {
		color: theme.palette.gold[60],
		fontSize: '14px',
		fontWeight: 500,
	},
	discountIcon: {
		color: 'white',
	},
	officeText: {
		color: theme.palette.text.inputLabel,
		fontSize: '14px',
		fontWeight: 400,
	},
	starIcon: {
		color: theme.palette.gold[50],
		height: '12px',
		width: '12px',
	},
	logoContainer: {
		maxWidth: '16px',
		maxHeight: '16px',
	},
	logo: {
		width: '100%',
	},
}));
