import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import components
import RoleProtected from 'components/hoc/RoleProtected';
import {
	AddCompensation,
	CompensationForm,
	CompensationHistory,
	CompensationsActions,
	CompensationsRecords,
	PermissionFallback,
} from './components';

// Import helpers and utils
import { PERMISSION_COMPENSATION } from './helpers';
import { useTranslations } from 'components/utilities';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import context
import { CompensationFormProvider } from './context';

// Import styles
import { useStyles } from './Compensation.styles';

export const Compensation = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { compensation } = useSelector(previewBookingSelector);

	return (
		<Box>
			<RoleProtected
				roles={PERMISSION_COMPENSATION}
				fallback={<PermissionFallback />}
			>
				<Typography variant="h4" className={classes.title}>
					{t('bookings.compensation_tab.title')}
				</Typography>
				{!compensation && (
					<Typography variant="body1" className={classes.emptyRequests}>
						{t('bookings.compensation_tab.no_compensations_requests')}
					</Typography>
				)}

				<CompensationFormProvider>
					<AddCompensation />
					<CompensationsRecords />
					<CompensationsActions />
					<CompensationHistory />
					<CompensationForm />
				</CompensationFormProvider>
			</RoleProtected>
		</Box>
	);
};
