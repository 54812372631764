export const COLUMNS_ACCESSORS = {
	ID: 'id',
	CUSTOMER_NAME: 'customer_name',
	PERIOD: 'period',
	STATUS: 'status',
	ACTIONS: 'actions',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount_discount',
		accessor: 'amount_discount',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount_paid',
		accessor: 'amount_paid',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.no_bookings',
		accessor: 'customer_no_booking',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.period',
		accessor: COLUMNS_ACCESSORS.PERIOD,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
];
