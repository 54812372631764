import { useDispatch } from 'react-redux';

// Import utilities
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

export const useActiveTableCell = ({
	id,
	isActive,
	activeText,
	inactiveText,
	activatedAlertMessage,
	deactivatedAlertMessage,
	activeAction,
}) => {
	const { t } = useTranslations();
	const { fetchData } = useTableContextProvider();
	const { message } = useMessage();

	const dispatch = useDispatch();

	const text = isActive ? activeText : inactiveText;
	const alertMessage = !isActive
		? activatedAlertMessage
		: deactivatedAlertMessage;

	const handleActiveItemClick = async () => {
		try {
			await activeAction({ isActive, id })(dispatch);
			message.success(t(alertMessage));
			await fetchData();
		} catch (error) {
			if (error.response) {
				if (error.response.status === 403) {
					message.error(t('errors.not_authorized'));
				} else {
					message.error(t('common.messages.error_message'));
				}
			} else if (error.message) {
				message.error(error.message);
			} else {
				message.error(t('errors.unknown'));
				throw error;
			}
		}
	};

	return {
		onActiveItemClick: handleActiveItemClick,
		text,
		alertMessage,
	};
};
