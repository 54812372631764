import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FilterIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M12 3.33333H14"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M2 3.33333H9.33333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.66675 7.99935H14.0001"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M2 7.99935H4"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12 12.6673H14"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M2 12.6673H9.33333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.6094 2.39052C12.1301 2.91122 12.1301 3.75544 11.6094 4.27614C11.0887 4.79684 10.2445 4.79684 9.72378 4.27614C9.20308 3.75545 9.20308 2.91122 9.72378 2.39052C10.2445 1.86983 11.0887 1.86983 11.6094 2.39052"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M6.27614 7.05654C6.79684 7.57724 6.79684 8.42146 6.27614 8.94216C5.75545 9.46286 4.91122 9.46286 4.39052 8.94216C3.86983 8.42146 3.86983 7.57724 4.39052 7.05654C4.91122 6.53584 5.75544 6.53584 6.27614 7.05654"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.6094 11.7245C12.1301 12.2452 12.1301 13.0894 11.6094 13.6101C11.0887 14.1308 10.2445 14.1308 9.72378 13.6101C9.20308 13.0894 9.20308 12.2452 9.72378 11.7245C10.2445 11.2038 11.0887 11.2038 11.6094 11.7245"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
