import React from 'react';
import { number, object, string } from 'prop-types';

// Import components
import { Grid, TableCell, Typography } from 'components/elements';

export const TextTableCell = ({
	minWidth = 120,
	additionalText,
	text,
	cell,
}) => (
	<TableCell
		style={{ minWidth: `${minWidth}px` }}
		key={cell.column.id}
		cell={cell}
	>
		<Grid container>
			{text && (
				<Grid item xs={12}>
					<Typography fontSize={12} color="neutral90">
						{text}
					</Typography>
				</Grid>
			)}
			{additionalText && (
				<Grid item xs={12}>
					<Typography fontSize={12} color="neutral90">
						{additionalText}
					</Typography>
				</Grid>
			)}
		</Grid>
	</TableCell>
);

TextTableCell.propTypes = {
	cell: object.isRequired,
	additionalText: string,
	text: string,
	minWidth: number,
};
