import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useUrgentReasons = () => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.URGENT.REASONS,
		async () => await services.get(ENDPOINTS.URGENT.REASONS),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};
