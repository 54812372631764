import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme) => ({
	root: {
		padding: theme.spacing(4),
	},
	carAvailableIsGood: {
		background: theme.palette.carAvailableIsGood,
	},
	carAvailableIsBad: {
		background: theme.palette.carAvailableIsBad,
	},
}));

export default useStyles;
