export const COLUMNS_ACCESSORS = {
	OFFICES: 'offices',
	ROLE: 'role',
	STATUS: 'status',
	ACTIONS: 'actions',
	EMAIL: 'email',
};

export const columns = [
	{
		Header: 'common.fields.name',
		accessor: 'full_name',
	},
	{
		Header: 'common.fields.phone_number',
		accessor: 'phone',
	},
	{
		Header: 'common.fields.email',
		accessor: COLUMNS_ACCESSORS.EMAIL,
	},
	{
		Header: 'common.fields.role',
		accessor: COLUMNS_ACCESSORS.ROLE,
	},
	{
		Header: 'common.fields.offices',
		accessor: COLUMNS_ACCESSORS.OFFICES,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
