import { useEffect, useState } from 'react';
import { useCampaignConditions } from 'queries';
import { CAMPAIGN_TYPES } from 'helpers';
import { useFormState } from 'react-final-form';
import { forIn } from 'lodash';

export const useCustomerRegisteredFields = () => {
	const campaignType = CAMPAIGN_TYPES.CUSTOMER_REGISTERED;

	const { values } = useFormState();

	const [options, setOptions] = useState(values);

	const { data, isFetching } = useCampaignConditions({ campaignType, options });

	const selectedTypes = values?.condition?.customer?.customer_type || [];
	const selectedOrganizations = values?.condition?.customer?.organization || [];

	const customerTypesData = data?.customer?.customer_type || {};
	const customerOrganizationsData = data?.customer?.organization || {};

	const customerTypes = [];
	const customerOrganizations = [];

	forIn(customerTypesData, (value, key) => {
		customerTypes.push({
			uuid: key,
			value,
		});
	});

	forIn(customerOrganizationsData, (value, key) => {
		customerOrganizations.push({
			uuid: key,
			value,
		});
	});

	const customerCardProps = {
		customerTypes,
		customerOrganizations,
		selectedTypes,
		selectedOrganizations,
	};

	const selectedCompanies = values?.condition?.supplier?.company || [];
	const selectedOffices = values?.condition?.supplier?.office || [];

	const companiesData = data?.supplier?.company || {};
	const officesData = data?.supplier?.office || {};

	const companies = [];
	const offices = [];

	forIn(companiesData, (value, key) => {
		companies.push({
			uuid: key,
			value,
		});
	});

	forIn(officesData, (value, key) => {
		offices.push({
			uuid: key,
			value,
		});
	});

	const handleOnCompanySelectClose = () => {
		setOptions(values);
	};

	useEffect(() => {
		values.uuid && setOptions(values);

		// eslint-disable-next-line
	}, [values.uuid]);

	const supplierCardProps = {
		companies,
		offices,
		selectedCompanies,
		selectedOffices,
		isFetching,
		onCompanySelectClose: handleOnCompanySelectClose,
	};

	return {
		customerCardProps,
		supplierCardProps,
	};
};
