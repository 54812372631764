import * as yup from 'yup';
import i18n from 'i18next';

export const validationSchema = () => {
	const minimumValueFieldText = i18n.t('common.fields.minimum', { value: 0 });
	const requiredFieldText = i18n.t('common.fields.required_field');

	return yup.object({
		details: yup.object({
			position: yup
				.number()
				.required(requiredFieldText)
				.min(0, minimumValueFieldText),
			locale: yup.string().required(requiredFieldText),
			title: yup.string().required(requiredFieldText),
			name: yup.string().required(requiredFieldText),
			description: yup
				.string()
				.required(requiredFieldText)
				.max(180, i18n.t('common.fields.maximum_characters', { value: 180 })),
		}),
		image: yup.object({
			file_path: yup.string().required(requiredFieldText),
			file_name: yup.string().required(requiredFieldText),
		}),
	});
};
