import moment from 'moment';

// Import utilities
import { useTranslations } from 'components/utilities';

export const useBookingInfo = (booking) => {
	const { t } = useTranslations();
	const {
		individual_number: individualNum,
		customer_name: custName,
		pick_date: pickDate,
		drop_date: dropDate,
		vehicle_model: carModel,
		vehicle_maker: carMaker,
		vehicle_year: carYear,
	} = booking;

	const car = `${carMaker} ${carModel} ${carYear}`;
	const period = moment(dropDate).diff(moment(pickDate), 'days');

	const infoList = [
		{ label: t('return_page.booking_id'), value: individualNum },
		{ label: t('return_page.customer'), value: custName },
		{ label: t('return_page.picked_up_date'), value: pickDate },
		{ label: t('return_page.drop_off_date'), value: dropDate },
		{ label: t('return_page.period'), value: period },
		{ label: t('return_page.car'), value: car },
	];

	return { infoList };
};
