import React from 'react';
import { Box, Button, Card, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import utils and helpers
import { CARDS } from './helpers';
import { useTranslations } from 'components/utilities';
import { useQuickFinderProvider } from 'views/Company/CompaniesList/context';

// Import components
import { Grid } from 'components/elements';

// Import styles
import { useStyles } from './GoalSelector.styles';

export const GoalSelector = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { selectedGoal, handleSelectGoal, goalComponent } =
		useQuickFinderProvider();

	return (
		<Box>
			<Typography className={classes.title}>
				{t('company.quick_finder.what_is_your_goal')}
			</Typography>
			<Grid container spacing={3}>
				{CARDS.map(({ title, body, type }) => (
					<Grid item xs={12} md={4} key={title}>
						<Card className={classes.card}>
							<Button
								className={clsx({
									[classes.button]: true,
									[classes.active]: selectedGoal === type,
								})}
								onClick={() => handleSelectGoal(type)}
							>
								<Typography className={classes.cardTitle}>
									{t(title)}
								</Typography>
								<Typography className={classes.cardBody}>{t(body)}</Typography>
							</Button>
						</Card>
					</Grid>
				))}
			</Grid>
			{selectedGoal && (
				<Box className={classes.goalContainer}>{goalComponent?.component}</Box>
			)}
		</Box>
	);
};
