import i18next from 'i18next';

export const sortByName = (items) => {
	const lng = i18next.language;
	return items.sort((a, b) => a.name[lng].localeCompare(b.name[lng]));
};

export const sortAdminsByName = (items) =>
	items
		.map(({ full_name, email, uuid }) => ({ name: full_name || email, uuid }))
		.sort((a, b) => a.name.localeCompare(b.name));
