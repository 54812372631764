import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useFormState, useForm } from 'react-final-form';
import moment from 'moment';

// Import helpers
import {
	FIELD_TYPES,
	generateArray,
	NONE_VALUE,
	PROMOCODE_SERVICE_TYPES,
} from 'helpers';

// Import utils
import { useTranslations } from 'components/utilities';
import { useCompanies } from 'queries';

export const useFormContent = () => {
	const {
		t,
		i18n: { language },
	} = useTranslations();
	const { pathname } = useLocation();
	const { data: companies, isFetching } = useCompanies(true);

	const { values } = useFormState();

	const { change } = useForm();

	const DateFromObj = moment(values.period.from);

	const { SUBSCRIPTION, DAILY, ALL } = PROMOCODE_SERVICE_TYPES;

	const isAddView = pathname.includes('add');
	const prevBookingNumbers = generateArray(0, 20).map((number) => ({
		label: number,
		value: number,
	}));

	const selectOptions = [
		{ label: t('common.none'), value: NONE_VALUE },
		...prevBookingNumbers,
	];

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[language];

	const serviceTypes = [
		{
			id: ALL,
			name: t('promo_codes.form.maximum_usage.all'),
		},
		{
			id: DAILY,
			name: t('promo_codes.form.maximum_usage.daily'),
		},
		{
			id: SUBSCRIPTION,
			name: t('promo_codes.form.maximum_usage.subscription'),
		},
	];

	const fields = [
		{
			type: FIELD_TYPES.TEXT,
			name: 'details.code',
			label: 'promo_codes.form.promo_code.code',
			required: isAddView,
			disabled: !isAddView,
		},
		{
			type: FIELD_TYPES.NUMBER,
			name: 'discount.discount',
			label: 'promo_codes.form.discount.discount',
			md: 12,
			min: 1,
			max: 100,
			required: true,
		},
		{
			type: FIELD_TYPES.NUMBER,
			name: 'discount.max_discount',
			label: 'promo_codes.form.discount.max_discount',
			md: 12,
			min: 1,
		},
		{
			type: FIELD_TYPES.DATE,
			name: 'period.from',
			label: 'promo_codes.form.period.from',
			md: 12,
			minDate: new Date(),
			required: true,
		},
		{
			type: FIELD_TYPES.DATE,
			name: 'period.until',
			label: 'promo_codes.form.period.until',
			md: 12,
			disabled: !values.period.from,
			minDate: values.period.from && DateFromObj.add(1, 'day'),
			required: true,
		},
		{
			type: FIELD_TYPES.NUMBER,
			name: 'max_usage',
			label: 'promo_codes.form.maximum_usage.max_usage',
			md: 12,
			min: 1,
			required: true,
		},
		{
			type: FIELD_TYPES.MULTI_SELECT,
			name: 'rental_companies',
			label: 'promo_codes.form.maximum_usage.rental_company',
			md: 12,
			currentMultiSelectValues: values?.rental_companies,
			disabled: isFetching,
			options: companies,
			showAllOption: true,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[language],
			multiSelectLabelExtractor,
			required: true,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'conditions.allowed_previous_bookings_number',
			label: 'promo_codes.form.maximum_usage.number_of_previous_booking',
			md: 12,
			options: selectOptions,
			keyExtractor: ({ value }) => value,
			labelExtractor: ({ label }) => label,
		},
		{
			type: FIELD_TYPES.SELECT,
			name: 'service_type',
			label: 'promo_codes.form.maximum_usage.service_type',
			md: 12,
			options: serviceTypes,
			required: true,
			keyExtractor: ({ id }) => id,
			labelExtractor: ({ name }) => name,
		},
		{
			type: FIELD_TYPES.NUMBER,
			name: 'budget.max',
			label: 'promo_codes.form.maximum_usage.max_budget',
			md: 12,
			min: 1,
			required: true,
		},
		{
			type: FIELD_TYPES.CHECKBOX,
			name: 'conditions.one_time_use',
			label: 'promo_codes.form.maximum_usage.one_time_use',
			md: 12,
		},
		{
			type: FIELD_TYPES.CHECKBOX,
			name: 'conditions.only_for_delivery',
			label: 'promo_codes.form.maximum_usage.only_for_delivery',
			md: 12,
		},
	];

	useEffect(() => {
		if (isAddView) {
			change('period.until', '');
		}
		// eslint-disable-next-line
	}, [values.period.from]);

	return {
		fields,
	};
};
