import React from 'react';
import { bool, object } from 'prop-types';
import { useFormState } from 'react-final-form';
import { Grid } from '@material-ui/core';

// Import components
import { SelectOffices } from 'views/Cars/components';
import * as CarFields from './Fields';
import useCarDetailsFields from './useCarDetailsFields';

const CarDetailsFields = ({
	queryFetchStates,
	fieldsData,
	isGlobalEdit,
	isGlobalAdd,
}) => {
	const { submitting } = useFormState();

	const {
		lng,
		change,
		vehicleList,
		years,
		year,
		models,
		carModel,
		manufacturerFieldName,
		modelFieldName,
		yearFieldName,
		carUuidFieldName,
		carBrand,
		isYearsLoaded,
		isModelsLoaded,
		handleManufacturerChange,
	} = useCarDetailsFields({ queryFetchStates, fieldsData, isGlobalEdit });

	const renderYearsField = () => (
		<CarFields.Year
			years={years}
			fieldName={yearFieldName}
			label="cars.form.car_details.year"
			isGlobalEdit={isGlobalEdit}
			carModel={carModel}
			carBrand={carBrand}
			yearsLoaded={isYearsLoaded}
		/>
	);

	const renderModelField = () => (
		<CarFields.Model
			models={models}
			fieldName={modelFieldName}
			submitting={submitting}
			label="cars.form.car_details.model"
			isGlobalEdit={isGlobalEdit}
			year={year}
			carBrand={carBrand}
			modelsLoaded={isModelsLoaded}
			carUuidFieldName={carUuidFieldName}
			lng={lng}
		/>
	);

	const renderFields = () => (
		<>
			{isGlobalAdd && (
				<Grid item md={12} xs={12}>
					<SelectOffices change={change} />
				</Grid>
			)}
			<CarFields.Manufacturer
				vehicles={vehicleList}
				fieldName={manufacturerFieldName}
				label="cars.form.car_details.manufacturer"
				onChange={handleManufacturerChange}
			/>
			{isGlobalEdit ? (
				<>
					{renderModelField()}
					{renderYearsField()}
				</>
			) : (
				<>
					{renderYearsField()}
					{renderModelField()}
				</>
			)}
		</>
	);

	return renderFields();
};

CarDetailsFields.propTypes = {
	queryFetchStates: object.isRequired,
	fieldsData: object.isRequired,
	isGlobalEdit: bool,
	isGlobalAdd: bool,
};

export default CarDetailsFields;
