// Import helpers
import { SCHEDULED_BOOKING_DATE_TYPES } from 'helpers';

const { TODAY, TOMORROW, AFTER_TOMORROW } = SCHEDULED_BOOKING_DATE_TYPES;

export const navFilters = [
	{
		label: 'common.all',
		value: null,
	},
	{
		label: 'scheduled_bookings.table.legend.periods.today',
		value: TODAY,
	},
	{
		label: 'scheduled_bookings.table.legend.periods.tomorrow',
		value: TOMORROW,
	},
	{
		label: 'scheduled_bookings.table.legend.periods.after_tomorrow',
		value: AFTER_TOMORROW,
	},
];
