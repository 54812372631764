import React from 'react';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Link } from '@material-ui/core';

// Import components
import { Button } from 'components/elements';

// import utils and helpers
import { useTranslations } from 'components/utilities';
import { URLS } from 'components/routes';

export const AddDriverButton = () => {
	const { t } = useTranslations();

	return (
		<Link href={URLS.addDriverUrl}>
			<Button text={t('users.table.toolbar.add_driver')} icon={PersonAddIcon} />
		</Link>
	);
};
