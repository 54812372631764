import { BOOKING_URGENT_STATES } from 'helpers';

const { UNSOLVED, SOLVED, REVIEW } = BOOKING_URGENT_STATES;

export const OPTIONS = [
	{
		value: 'all',
		label: 'common.all',
	},
	{
		value: REVIEW,
		label: 'common.common_states.review',
	},
	{
		value: SOLVED,
		label: 'common.common_states.solved',
	},
	{
		value: UNSOLVED,
		label: 'common.common_states.unsolved',
	},
];
