export const INITIAL_VALUES = {
	details: {
		name: {
			ar: '',
			en: '',
		},
		description: {
			ar: '',
			en: '',
		},
	},
	order: null,
	is_active: true,
	image: {
		file_path: '',
		file_name: '',
	},
	faq_location: [],
	service_type: [],
};

export const INITIAL_ADD_STATE_SELECTOR = () => ({
	item: null,
	isLoaded: true,
});

export const INITIAL_EDIT_STATE_SELECTOR = (store) => store.faq.form;
