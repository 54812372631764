import React from 'react';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { RADIO_FIELDS } from './helpers';

// Import components
import { Button, Grid } from 'components/elements';

export const FormContent = () => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	return (
		<Grid container spacing={4}>
			{RADIO_FIELDS.map(renderFields)}
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end">
					<Grid item>
						<Button buttonType="submit" text={t('common.buttons.submit')} />
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
