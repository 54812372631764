import { makeStyles } from '@material-ui/styles';

const shardStyle = (theme) => ({
	position: 'sticky',
	left: 0,
	zIndex: 1,
	backgroundColor: theme.palette.white,
});

export const useStyles = makeStyles((theme) => ({
	root: {
		'& thead': {
			'& tr': {
				'& th:first-child': {
					...shardStyle(theme),
				},
			},
		},
		'& tbody': {
			'& tr': {
				'& td:first-child': {
					...shardStyle(theme),
				},
			},
		},
	},
}));
