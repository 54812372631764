import React from 'react';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';
import { format } from 'date-fns';

// Import components
import {
	BookingIdTableCell,
	CompanyTableCell,
	NewActionsMenuTableCell,
	Notes,
	TwoRowsTableCell,
} from 'components/elements';
import { StatusChip } from './components';

// Import helpers
import { DLY_DATE_FORMAT } from 'helpers';
import { COLUMNS_ACCESSORS } from 'views/LeasingCancellation/columns';
import { actionMenuFields } from './helpers';

const {
	CANCELLATION_DATE,
	CUSTOMER_NAME,
	BOOKING_ID,
	PICK_DATE,
	COMPANY,
	ACTIONS,
	STATUS,
} = COLUMNS_ACCESSORS;

export const Cell = ({ cell, isHovered }) => {
	const {
		cancellation_date: cancellationDate,
		cancellation_time: cancellationTime,
		customer_phone: customerPhone,
		customer_name: customerName,
		pick_date: pickDate,
		pick_time: pickTime,
	} = cell.row.original;

	const formattedPickDate = format(new Date(pickDate), DLY_DATE_FORMAT);
	const formattedCancellationDate =
		cancellationDate && format(new Date(cancellationDate), DLY_DATE_FORMAT);

	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		switch (cell.column.id) {
			case PICK_DATE:
				return (
					<TwoRowsTableCell
						title={formattedPickDate}
						key={cell.column.id}
						subtitle={pickTime}
						cell={cell}
					/>
				);

			case CANCELLATION_DATE:
				return (
					<TwoRowsTableCell
						title={formattedCancellationDate}
						key={cell.column.id}
						subtitle={cancellationTime}
						cell={cell}
					/>
				);

			case CUSTOMER_NAME:
				return (
					<TwoRowsTableCell
						subtitle={customerPhone}
						title={customerName}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case BOOKING_ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						notes={<Notes cell={cell} />}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			case COMPANY:
				return <CompanyTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return <StatusChip key={cell.column.id} cell={cell} />;

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						key={cell.column.id}
						cell={cell}
						extraActions={{ leasingValidation: true }}
						isHovered={isHovered}
						minWidth="150px"
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
