import React from 'react';
import { useForm, useFormState } from 'react-final-form';

// Import components
import { Button, Grid } from 'components/elements';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { usePickupCitiesCardProvider } from '../../context';
import { BUTTON_PARAMS } from 'helpers';
import { getFields, handleOnClear } from './helpers';

// Import styles
import { useStyles } from './Fields.styles';

// Import assets
import { CircleCloseIcon } from 'assets/icons';

export const Fields = () => {
	const { citiesWithoutSelectedCity } = usePickupCitiesCardProvider();
	const { renderFields } = useCommonFields();
	const { values } = useFormState();
	const { change } = useForm();
	const { t } = useTranslations();
	const classes = useStyles();

	const fields = getFields({ cities: citiesWithoutSelectedCity, values });
	const isClearButtonVisible = !!values.out_data[values.selected_city_in];

	return (
		<Grid
			className={classes.container}
			direction="column"
			spacing={4}
			container
		>
			{fields.map(renderFields)}
			{isClearButtonVisible && (
				<Grid item xs={8}>
					<Button
						onClick={() => handleOnClear({ values, change })}
						text={t('common.buttons.clear_changes')}
						type={BUTTON_PARAMS.TYPE.ERROR}
						icon={CircleCloseIcon}
					/>
				</Grid>
			)}
		</Grid>
	);
};
