import React from 'react';
import { Typography } from '@material-ui/core';
import { bool, number, string } from 'prop-types';
import clsx from 'clsx';

// Import styles
import { useStyles } from './Row.styles';

// Import assets
import { CarIcon, CircleArrowsIcon } from 'assets/icons';

// Import helpers
import { useToggle } from 'components/utilities';
import { getColorClass } from './helpers';

// Import components
import {
	CarsLogHistory,
	CarsLogHistoryProvider,
	Grid,
	IconButton,
} from 'components/elements';

export const Row = ({ showIcon, amount, eventStoreUuid }) => {
	const { on: isOpen, toggle } = useToggle();
	const classes = useStyles();

	return (
		<Grid item xs={12}>
			<Grid container alignItems="center" spacing={2}>
				{showIcon && (
					<Grid item className={classes.iconWrapper}>
						<CarIcon
							className={clsx({
								[classes[getColorClass(amount)]]: showIcon,
								[classes.carIcon]: true,
							})}
						/>
					</Grid>
				)}
				<Grid item>
					<Typography
						className={showIcon ? classes[getColorClass(amount)] : ''}
					>
						{amount}
					</Typography>
				</Grid>
				{eventStoreUuid && (
					<Grid item>
						<IconButton size="small" onClick={toggle}>
							<CircleArrowsIcon className={classes.carLogHistoryIcon} />
						</IconButton>
						{isOpen && (
							<CarsLogHistoryProvider uuid={eventStoreUuid}>
								<CarsLogHistory open={isOpen} onClose={toggle} />
							</CarsLogHistoryProvider>
						)}
					</Grid>
				)}
			</Grid>
		</Grid>
	);
};

Row.propTypes = {
	amount: number.isRequired,
	eventStoreUuid: string,
	showIcon: bool,
};
