import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useBookingHistory = ({ bookingId }) => {
	const { data, isFetching, isError, error } = useQuery(
		QUERY_KEYS.BOOKINGS.HISTORY(bookingId),
		async () =>
			await services.get(ENDPOINTS.BOOKINGS.BOOKING_HISTORY({ bookingId })),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError, error };
};

export default useBookingHistory;
