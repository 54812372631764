import { useSelector } from 'react-redux';
import { previewBookingSelector } from 'store/selectors';
import { useLocation } from 'react-router';

// Import helpers
import { BOOKING_VALIDATION_TOPICS } from 'helpers';
import { URLS } from 'components/routes';

const { URGENT, COMPENSATION, CANCELLATION } = BOOKING_VALIDATION_TOPICS;

export const useValidationModal = () => {
	const { operation_topics } = useSelector(previewBookingSelector);

	const { pathname } = useLocation();

	const isUrgentBooking = pathname === URLS.urgentBookingsUrl;

	const isCompensationValidation = pathname === URLS.compensationValidationUrl;

	const isUrgent = isUrgentBooking || operation_topics?.includes(URGENT);
	const isCompensation =
		isCompensationValidation || operation_topics?.includes(COMPENSATION);
	const isCancellation =
		operation_topics?.includes(CANCELLATION) && !isCompensationValidation;

	return {
		isUrgent,
		isCancellation,
		isCompensation,
	};
};
