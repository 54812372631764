import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CircularLocationPin = (props) => (
	<SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
		<path
			d="M9.116 14.1875C5.562 14.6555 3 15.9605 3 17.5005C3 19.4335 7.029 21.0005 12 21.0005C16.971 21.0005 21 19.4335 21 17.5005C21 15.9605 18.438 14.6555 14.884 14.1875"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18 7.833C18 4.612 15.314 2 12 2C8.686 2 6 4.612 6 7.833C6 12.208 12 17 12 17C12 17 18 12.208 18 7.833Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.4142 6.58579C14.1953 7.36684 14.1953 8.63317 13.4142 9.41422C12.6332 10.1953 11.3668 10.1953 10.5858 9.41422C9.80474 8.63317 9.80474 7.36684 10.5858 6.58579C11.3668 5.80474 12.6332 5.80474 13.4142 6.58579"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
