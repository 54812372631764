const emptyValues = {
	rating_reason_uuid: null,
	description: '',
	rating: 0,
};

export const getInitialValues = (officeRating) => ({
	...emptyValues,
	...officeRating,
	...(officeRating && { rating_reason_uuid: officeRating.rating_reasons?.[0] }),
});
