import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	imageWrapper: {
		width: '160px',
		height: '128px',
		borderRadius: '10px',
		backgroundColor: theme.palette.background[6],
		marginBottom: theme.spacing(3),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	image: {
		width: '125px',
	},
	carName: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(2),
	},
	companyInfo: {
		marginBottom: theme.spacing(3),
	},
	companyInfoText: {
		fontSize: 14,
		color: theme.palette.text[1],
		marginBottom: '6px',
	},
	options: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
		marginBottom: '16px',
	},
	option: {
		color: theme.palette.text[3],
		fontSize: 14,
	},
	icon: {
		color: theme.palette.gray.medium,
	},
}));
