import React from 'react';

// Import utils and helpers
import { formatValues } from './helpers';
import { useFilters } from './useFilters';

// Import components
import { AdditionalFilterFields, FilterFields } from './components';
import { TableFiltersToolbar } from 'components/elements';

export const Filters = () => {
	const { initialValues } = useFilters();

	return (
		<TableFiltersToolbar
			initialValues={initialValues}
			formatValues={formatValues}
			dialogFilters={<FilterFields />}
			prevAdditionalFilters={<AdditionalFilterFields />}
		/>
	);
};
