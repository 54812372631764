import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	card: {
		borderRadius: 0,
		padding: 0,
	},
	title: {
		'& .MuiCardHeader-title': {
			color: theme.palette.primary.main,
		},
		padding: '16px 0',
	},
	content: {
		marginTop: '16px',
	},
}));
