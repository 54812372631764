import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const ClockIcon = (props) => (
	<SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
		<path
			id="Path"
			d="M8 2.5C11.3137 2.5 14 5.18629 14 8.5C14 11.8137 11.3137 14.5 8 14.5C4.68629 14.5 2 11.8137 2 8.5C2 5.18629 4.68629 2.5 8 2.5"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_2"
			d="M8.33398 5.16675V8.83341H5.33398"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
