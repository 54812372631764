import { useSelector } from 'react-redux';

// Import helpers
import { previewBookingSelector } from 'store/selectors';
import { DELIVERY_STATUS, DELIVERY_TYPES } from 'helpers';

export const useDeliveryTabContent = ({ type, outerDeliveryData }) => {
	const { PICK_UP_DELIVERY } = DELIVERY_TYPES;
	const { PENDING } = DELIVERY_STATUS;

	const booking = useSelector(previewBookingSelector);

	const { delivery } = booking || {};

	const deliveryData = outerDeliveryData || delivery[type];
	const {
		driver_name: driverName,
		delivery_uuid: deliveryUuid,
		available,
		has_late_delivery: hasLateDelivery,
		delivery_state: deliveryState,
	} = deliveryData || {};

	const { lat, lng, address } = outerDeliveryData
		? outerDeliveryData
		: delivery;

	const deliveryLink = `https://www.google.com/maps/place/${lat},${lng}`;

	const showDeliverySteps = null != deliveryState && deliveryState !== PENDING;

	const getAddressLabel = () =>
		type === PICK_UP_DELIVERY
			? 'common.delivery_terms.pick_address'
			: 'common.delivery_terms.return_address';

	const getAssignedLabel = () =>
		type === PICK_UP_DELIVERY
			? 'common.delivery_terms.assigned_to_pickup'
			: 'common.delivery_terms.assigned_to_return';

	const COLUMN_ACCESSORS = {
		ADDRESS_LINK: 'address_link',
		ASSIGN_DRIVER: 'assign_driver',
	};

	const DELIVERY_INFO_FIELDS = [
		{
			accessor: COLUMN_ACCESSORS.ADDRESS_LINK,
			label: getAddressLabel(),
			address,
			link: deliveryLink,
		},
		{
			accessor: COLUMN_ACCESSORS.ASSIGN_DRIVER,
			label: getAssignedLabel(),
			driverName,
			deliveryType: type,
			hideButton: !!outerDeliveryData,
		},
		{
			accessor: 'selectedDeliveryData.statistics.fuel_level',
			label: 'common.delivery_terms.fuel_level',
		},
		{
			accessor: 'selectedDeliveryData.statistics.number_of_kilometers',
			label: 'common.delivery_terms.number_of_km',
		},
	];

	return {
		showDeliverySteps,
		deliveryUuid,
		deliveryLink,
		address,
		available,
		hasLateDelivery,
		DELIVERY_INFO_FIELDS,
		data: { ...booking, selectedDeliveryData: deliveryData },
	};
};
