import { useForm, useFormState } from 'react-final-form';

// Import  utilities and helpers
import { useCompanyActiveCollection } from 'queries';
import { useProfile } from 'components/utilities';

export const useTopBar = () => {
	const { data: companies, isFetched } = useCompanyActiveCollection();
	const { values, submitting } = useFormState();
	const { isAdmin } = useProfile();
	const { change } = useForm();

	const handleOnCheckboxClick = ({ target }) => {
		const isChecked = target.checked;
		const convertedCompanies = companies.map(({ uuid }) => uuid);
		change('company_uuids', isChecked ? convertedCompanies : []);
	};

	const companiesDisabled = !isFetched || submitting || !isAdmin;
	const allCompaniesCheckboxDisabled = submitting || !isAdmin;

	return {
		handleOnCheckboxClick,
		allCompaniesCheckboxDisabled,
		companiesDisabled,
		values,
		companies,
	};
};
