import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { MenuItem } from '@material-ui/core';

// Import components
import { SelectFieldAdapter } from 'components/elements';
import { useDropCityField } from './useDropCityField';

export const DropCityField = () => {
	const {
		submitting,
		values: {
			options: { drop_city },
		},
	} = useFormState();

	const { locations } = useDropCityField();

	return (
		<Field
			fullWidth
			margin="dense"
			component={SelectFieldAdapter}
			name="options.drop_city_uuid"
			variant="outlined"
			disabled={submitting || !drop_city}
		>
			{locations.map(({ name, uuid }) => (
				<MenuItem key={uuid} value={uuid}>
					{name}
				</MenuItem>
			))}
		</Field>
	);
};

DropCityField.propTypes = {
	label: string,
};
