import React from 'react';
import { useParams } from 'react-router-dom';

// Import components
import { BaseSelectFilter, CompanyFilter } from 'components/elements';

// Import utils and helpers
import { useProfile } from 'components/utilities';
import { ROLE_FILTER_OPTIONS } from 'helpers';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();
	const { id } = useParams();

	const isCompanyFilterVisible = isAdmin && !id;

	return (
		<>
			{isCompanyFilterVisible && (
				<CompanyFilter
					label={null}
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					withSearch
				/>
			)}
			<BaseSelectFilter
				allLabel="employee_rewarding.table.filters.role"
				options={ROLE_FILTER_OPTIONS}
				smallFieldStyle
				name="role"
				xs="auto"
				md="auto"
			/>
		</>
	);
};
