import axios from 'axios';
import localStorage from 'services/localStorage';

// import helpers
import { ENDPOINTS } from 'helpers';
import services from 'services/services';
import { AR, EN } from 'helpers';

export const getCustomerDetails = async ({
	phoneNumber,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.get(
			ENDPOINTS.BOOKINGS.CUSTOMER_DETAILS(phoneNumber)
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const createCustomer = async ({
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.post(ENDPOINTS.BOOKINGS.CREATE_CUSTOMER, data);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const getCarBookingDetails = async ({
	uuid,
	data,
	isRTL,
	successCallback,
	errorCallback,
}) => {
	try {
		const url = `${
			process.env.REACT_APP_API_URL
		}${ENDPOINTS.BOOKINGS.CAR_DETAILS({ uuid })}`;

		const headers = {
			language: isRTL ? AR : EN,
		};

		const resp = await axios.post(url, data, { headers });
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const createBooking = async ({
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const url = `${process.env.REACT_APP_API_URL}${ENDPOINTS.BOOKINGS.CREATE_BOOKING}`;
		let user = localStorage.storedLsValue();
		const headers = {
			platform: 'dashboard',
			booking_owner_uuid: data.booking_owner_uuid,
			Authorization: `Bearer ${user.data.meta.access_token}`,
		};

		const resp = await axios.post(url, data, { headers });
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};

export const createBookingContract = async ({
	uuid,
	data,
	successCallback,
	errorCallback,
}) => {
	try {
		const resp = await services.post(
			ENDPOINTS.BOOKINGS.CREATE_BOOKING_CONTRACT({ uuid }),
			data
		);
		successCallback(resp);
	} catch (error) {
		errorCallback(error);
	}
};
