import React from 'react';
import { object } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './RejectTooltip.styles';

// Import utils
import { useTranslations } from 'components/utilities';

export const RejectTooltip = ({ booking }) => {
	const classes = useStyles();
	const {
		i18n: { language },
	} = useTranslations();

	const {
		name,
		created_by: createdBy,
		created_at: createdAt,
		description,
	} = booking?.cancel_reason || {};

	return (
		<Grid container spacing={3} className={classes.container}>
			<Grid item xs={12}>
				<Grid container justifyContent="space-between">
					<Grid item xs="auto">
						<Typography className={classes.grayText}>{createdBy}</Typography>
					</Grid>
					<Grid item xs="auto">
						<Typography className={classes.grayText}>Telgani</Typography>
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={12}>
				<Typography className={classes.reasonText}>
					{name?.[language] || description}
				</Typography>
			</Grid>
			<Grid item>
				<Typography className={classes.grayText}>{createdAt}</Typography>
			</Grid>
		</Grid>
	);
};

RejectTooltip.propTypes = {
	booking: object,
};
