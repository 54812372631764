import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import context
import { BookingStatusActionsProvider } from 'components/context';

// Import components
import { BookingDetailsStatusActionsContent } from './BookingDetailsStatusActionsContent';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { fetchBookingPreview } from 'store/actions';

export const BookingDetailsStatusActions = () => {
	const dispatch = useDispatch();
	const booking = useSelector(previewBookingSelector);

	const onChange = () => {
		fetchBookingPreview({
			id: booking.uuid,
		})(dispatch);
	};

	return (
		<BookingStatusActionsProvider booking={booking} onChange={onChange}>
			<BookingDetailsStatusActionsContent />
		</BookingStatusActionsProvider>
	);
};
