import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	title: {
		fontSize: 18,
		fontWeight: 500,
		marginBottom: theme.spacing(1),
	},
	message: {
		fontSize: 12,
		color: theme.palette.text[4],
		marginBottom: '24px',
	},
	arrowButton: {
		width: '32px',
		height: '32px',
		borderRadius: '50%',
		borderColor: theme.palette.background[3],
		padding: 0,
		minWidth: 'unset',
		position: 'absolute',
		top: '-75px',
	},
	arrowIcon: {
		width: '14px',
		height: '14px',
	},
	container: {
		scrollMarginTop: '150px',
		position: 'relative',

		'& .alice-carousel__slide-info': {
			position: 'absolute',
			top: '-75px',
			backgroundColor: 'transparent',
			right: '105px',
		},
		'& .alice-carousel__wrapper': {
			paddingTop: '10px',
		},
	},
	slideCounter: {
		fontSize: 16,
		fontWeight: 500,
		marginTop: '12px',
	},
	prevButton: {
		right: '65px',

		'& svg': {
			right: '-3px',
			position: 'relative',
		},
	},
	nextButton: {
		right: '25px',
	},
	carouselContainer: {
		overflow: 'visible',
	},
	headingContainer: {
		maxWidth: '88%',
	},
}));
