import React from 'react';
import { dialogMessageSelector } from 'store/selectors';
import { useSelector } from 'react-redux';
import { CardContent, CardHeader, Typography, Card } from '@material-ui/core';

// Import components
import { MakeCompensationForm } from 'components/elements';
import { useValidateCompensationForm } from './useValidateCompensationForm';

// Import translations
import { useTranslations } from 'components/utilities';

export const ValidateCompensationForm = () => {
	const { t } = useTranslations();

	const { isSubmitted, onClose } = useValidateCompensationForm();
	const dialogMessage = useSelector(dialogMessageSelector);

	if (isSubmitted) {
		return <Typography>{t(dialogMessage)}</Typography>;
	}

	return (
		<Card>
			<CardHeader title={t('validation.table.modal.compensation_title')} />
			<CardContent>
				<MakeCompensationForm
					isInModal
					onClose={onClose}
					location="validation"
					isInvalidButtonVisible
				/>
			</CardContent>
		</Card>
	);
};
