import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const BaseEditPenIcon = (props) => (
	<SvgIcon width="20" height="21" viewBox="0 0 20 21" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M4.40823 13.5128L13.0132 4.90781C13.3382 4.58281 13.8657 4.58281 14.1907 4.90781L15.5874 6.30448C15.9124 6.62948 15.9124 7.15698 15.5874 7.48198L6.98156 16.0861C6.82573 16.2428 6.61406 16.3303 6.39323 16.3303H4.16406V14.1011C4.16406 13.8803 4.25156 13.6686 4.40823 13.5128Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M11.4609 6.46875L14.0359 9.04375"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
