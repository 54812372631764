import React from 'react';
import { object } from 'prop-types';

// Import components
import { IconButton } from 'components/elements';

// Import assets
import { DownloadIconCurved } from 'assets/icons';

// Import utils
import { useActionsTableCell } from './useActionsTableCell';

export const ActionsTableCell = ({ row }) => {
	const { handleOnDownload } = useActionsTableCell({ row });

	return (
		<IconButton onClick={handleOnDownload}>
			<DownloadIconCurved />
		</IconButton>
	);
};

ActionsTableCell.propTypes = {
	row: object.isRequired,
};
