import { makeStyles } from '@material-ui/styles';
import { alpha } from '@material-ui/core';

// Import styles
import palette from 'theme/palette';

// Import helpers
import { SCHEDULED_BOOKING_DATE_TYPES } from 'helpers';

const { TODAY, TOMORROW, AFTER_TOMORROW } = SCHEDULED_BOOKING_DATE_TYPES;

const getColor = (period) => {
	switch (period) {
		case TODAY:
			return palette.uncategorized.pink[1];
		case TOMORROW:
			return palette.uncategorized.blue[1];
		case AFTER_TOMORROW:
			return palette.primary.main;
		default:
			return palette.gray.medium;
	}
};

const getBackgroundColor = ({ period, staticDateType }) =>
	period === staticDateType
		? alpha(getColor(staticDateType), 0.2)
		: 'transparent';

export const useStyles = makeStyles((theme) => ({
	button: ({ period }) => ({
		backgroundColor:
			period === null ? theme.palette.success.light : 'transparent',
		color: period === null ? theme.palette.primary.main : getColor(),
		borderRadius: '12px',
		padding: '8px 16px',
		fontWeight: '600',
		fontSize: '14px',
		'& :hover': {
			backgroundColor: 'transparent',
		},
	}),
	[TODAY]: ({ period }) => ({
		color: getColor(TODAY),
		backgroundColor: getBackgroundColor({ period, staticDateType: TODAY }),
	}),
	[TOMORROW]: ({ period }) => ({
		color: getColor(TOMORROW),
		backgroundColor: getBackgroundColor({ period, staticDateType: TOMORROW }),
	}),
	[AFTER_TOMORROW]: ({ period }) => ({
		color: getColor(AFTER_TOMORROW),
		backgroundColor: getBackgroundColor({
			period,
			staticDateType: AFTER_TOMORROW,
		}),
	}),
}));
