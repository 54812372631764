import React from 'react';
import { CircularProgress } from '@material-ui/core';

// import components
import { Grid, Card, Typography } from 'components/elements';
import { PreviewFields } from 'views/Bookings/BookingDetails/components';

// import styles
import { useStyles } from './ReviewBooking.styles';

// import utilities and helpers
import { useReviewBooking } from './useReviewBooking';
import { useTranslations } from 'components/utilities';

export const ReviewBooking = () => {
	const classes = useStyles();
	const {
		bookingInfoFields,
		carAndCompanyFields,
		costSummaryFields,
		data,
		loading,
	} = useReviewBooking();
	const { t } = useTranslations();

	if (loading) return <CircularProgress />;

	return (
		<>
			<Typography className={classes.title}>
				{t('bookings.create_booking.review_booking')}
			</Typography>
			<Grid container spacing={3}>
				<Grid item md={6}>
					<Card lg={12}>
						<Typography className={classes.sectionTitle}>
							{t('bookings.create_booking.booking_info')}
						</Typography>
						<PreviewFields fields={bookingInfoFields} customData={data} />
					</Card>
				</Grid>
				<Grid item md={6} className={classes.flex}>
					<Card lg={12}>
						<Typography className={classes.sectionTitle}>
							{t('bookings.create_booking.car_and_company')}
						</Typography>
						<PreviewFields fields={carAndCompanyFields} customData={data} />
					</Card>
					<Card lg={12}>
						<Typography className={classes.sectionTitle}>
							{t('bookings.create_booking.cost_summary')}
						</Typography>
						<PreviewFields fields={costSummaryFields} customData={data} />
					</Card>
				</Grid>
			</Grid>
		</>
	);
};
