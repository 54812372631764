import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: theme.spacing(3),
	},
	card: {
		display: 'flex',
		flexDirection: 'column',
		gap: '32px',
		padding: '28px',
	},
	fieldValue: {
		fontSize: '28px',
		fontWeight: '500',
		color: theme.palette.primary.main,
	},
	label: {
		fontSize: '14px',
		fontWeight: '500',
	},
}));
