import React from 'react';
import { Link } from 'react-router-dom';
import { bool, string } from 'prop-types';
import { Tooltip } from '@material-ui/core';
import clsx from 'clsx';

// Import icons
import { BrandNameIcon, BrandIcon } from 'assets/icons';

// Import styles
import { useStyles } from './LogoWrapper.styles';

// Import helpers
import { URLS } from 'components/routes';

// Import utilities
import { useProfile, useTranslations } from 'components/utilities';

export const LogoWrapper = ({ className, isOpen }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { isCorporateCoordinator } = useProfile();

	const url = isCorporateCoordinator
		? URLS.corporateOverviewUrl
		: URLS.dashboardUrl;

	return (
		<Link to={url} className={clsx(className, classes.link)}>
			<Tooltip
				title={t('common.icon_names.logo')}
				aria-label={t('common.icon_names.logo')}
			>
				<>
					{isOpen ? (
						<BrandNameIcon className={classes.logo} />
					) : (
						<BrandIcon className={classes.logoShorthand} />
					)}
				</>
			</Tooltip>
		</Link>
	);
};

LogoWrapper.propTypes = {
	className: string,
	isOpen: bool.isRequired,
};
