// ********************  table  ********************

export const fetchSlidersUrl = (queryParams) => `/v2/slider/list${queryParams}`;

export const updateActiveSliderUrl = (id, isActive) =>
	`/v2/slider/${id}/${isActive ? 'deactivate' : 'activate'}`;

export const deleteSliderUrl = (id) => `/v2/slider/${id}/remove`;

// ********************  form  ********************

export const submitSliderFormUrl = (itemId) =>
	`/v2/slider/${itemId ? `${itemId}/update` : `create`}`;
