import React from 'react';
import { object } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CarFilterItem.styles';

export const CarFilterItem = ({ car }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid item xs={6}>
				<Typography variant="h6" component="span">
					{car.vehicle.name} {car.vehicle.year}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography align="right" variant="h6">
					{t('bookings.edit_booking.select_car.card.price_for_1_day')}:{' '}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				<Typography
					align="right"
					color="primary"
					variant="h6"
					className={classes.price}
				>
					{t('currency.price', { amount: car.details.discounted_nett_price })}
				</Typography>
			</Grid>
		</Grid>
	);
};

CarFilterItem.propTypes = {
	car: object,
};
