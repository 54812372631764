import React from 'react';
import { Button, Grid } from '@material-ui/core';
import { useFormState } from 'react-final-form';
import { func } from 'prop-types';

// Import components
import { ButtonProgress } from 'components/elements';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { FIELDS } from './helpers';

export const Fields = ({ toggle }) => {
	const { renderFields } = useCommonFields();
	const { submitting } = useFormState();
	const { t } = useTranslations();

	return (
		<Grid container spacing={2}>
			{FIELDS.map(renderFields)}
			<Grid item xs={12}>
				<Grid container justifyContent="flex-end" spacing={2}>
					<Grid item>
						<Button onClick={toggle} color="primary">
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							color="primary"
							variant="contained"
							type="submit"
							disabled={submitting}
							isLoading={submitting}
						>
							{t('bookings.close_compensation_form.button_title')}
						</ButtonProgress>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

Fields.propTypes = {
	toggle: func,
};
