import React from 'react';
import { object } from 'prop-types';

// Import styles
import { useStyles } from './TotalPaidPreviewField.styles';
import { PreviewField } from 'components/elements';

// Import components

export const TotalPaidPreviewField = ({ customData }) => {
	const classes = useStyles();

	const { total } = customData || {};
	const { amount_gross: price } = total || {};

	return (
		<PreviewField
			label="common.fields.total_paid"
			valueClassName={classes.value}
			labelClassName={classes.label}
		>
			{price}
		</PreviewField>
	);
};

TotalPaidPreviewField.propTypes = {
	customData: object.isRequired,
};
