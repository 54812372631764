import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	label: {
		color: theme.palette.gray.label,
		fontWeight: 500,
		marginBottom: '8px',
	},
	fileUploaderButton: {
		height: '56px',
		backgroundColor: theme.palette.background[6],
		color: theme.palette.text[3],
		'&:hover': {
			backgroundColor: theme.palette.background[3],
		},
	},
}));
