import React from 'react';
import { Typography } from '@material-ui/core';
import { string } from 'prop-types';
import { format } from 'date-fns';

// Import helpers
import { YMD_SLASH_TIME_FORMAT } from 'helpers';

// Import components
import { PreviewField } from 'components/elements';

// Import styles
import { useStyles } from './DateField.styles';

export const DateField = ({ date, label, labelClassName }) => {
	const classes = useStyles();

	const formattedDate = format(new Date(date), YMD_SLASH_TIME_FORMAT);

	return (
		<PreviewField label={label} labelClassName={labelClassName}>
			<Typography variant="body1" className={classes.date}>
				{formattedDate}
			</Typography>
		</PreviewField>
	);
};

DateField.propTypes = {
	date: string,
	label: string,
	labelClassName: string,
};
