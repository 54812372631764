import React from 'react';
import { shape, string } from 'prop-types';
import { Box, TableCell, Typography } from '@material-ui/core';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './HeadCell.styles';

export const HeadCell = ({ column }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<TableCell>
			<Box className={classes.container}>
				<Box className={classes.headerWrapper}>
					<Typography noWrap className={classes.tableHeader}>
						{t(column.header)}
					</Typography>
				</Box>
			</Box>
		</TableCell>
	);
};

HeadCell.propTypes = {
	column: shape({
		header: string.isRequired,
		accessor: string.isRequired,
	}),
};
