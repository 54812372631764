import React from 'react';
import { fetchWaitingCustomerInfoBookings } from 'store/actions';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, Filters } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

// Import utils and helpers
import { TableRPC } from 'components/utilities';
import { buttons } from './helpers';

export const WaitingCustomersInfo = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchWaitingCustomerInfoBookings}
			title="nav.customer_care.waiting_info"
			filtersBar={<Filters />}
			tableNavigation={
				<InnerNavbarElementsButtons
					filterPath="compensation_status"
					buttons={buttons}
				/>
			}
		/>
	);
};
