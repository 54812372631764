import { makeStyles } from '@material-ui/styles';
import { getTypographyColor } from './helpers';

export const useStyles = makeStyles(() => ({
	typography: ({ fontSize, fontWeight, color }) => ({
		color: getTypographyColor({ color }),
		fontSize: `${fontSize}px`,
		fontWeight,
	}),
}));
