import React, { createContext } from 'react';
import { node, string } from 'prop-types';

// Import queries
import { useDeliveryLocationQuery } from 'queries';

// Import helpers
import { DELIVERY_TYPES } from 'helpers';

export const DeliveryLocationContext = createContext(null);

export const DeliveryLocationProvider = ({ deliveryUuid, type, children }) => {
	const { PICK_UP_DELIVERY, DROP_DELIVERY } = DELIVERY_TYPES;

	const isPickupDelivery = type === PICK_UP_DELIVERY;
	const isDropDelivery = type === DROP_DELIVERY;

	const {
		data: pickupDeliveryData,
		isFetching: isPickupDeliveryFetching,
		isFetched: isPickupDeliveryFetched,
		isError: isPickupDeliveryError,
		refetch: refetchPickupDeliveryData,
	} = useDeliveryLocationQuery({
		deliveryUuid: deliveryUuid,
	});

	const {
		data: dropDeliveryData,
		isFetching: isDropDeliveryFetching,
		isFetched: isDropDeliveryFetched,
		isError: isDropDeliveryError,
		refetch: refetchDropDeliveryData,
	} = useDeliveryLocationQuery({
		deliveryUuid: deliveryUuid,
	});

	const getValues = () => {
		let values = {};

		if (isPickupDelivery) {
			values = {
				...pickupDeliveryData,
				refetch: refetchPickupDeliveryData,
				isFetching: isPickupDeliveryFetching,
				isFetched: isPickupDeliveryFetched,
				isError: isPickupDeliveryError,
			};
		}

		if (isDropDelivery) {
			values = {
				...dropDeliveryData,
				refetch: refetchDropDeliveryData,
				isFetching: isDropDeliveryFetching,
				isFetched: isDropDeliveryFetched,
				isError: isDropDeliveryError,
			};
		}

		return values;
	};

	const values = getValues();

	return (
		<DeliveryLocationContext.Provider value={values}>
			{children}
		</DeliveryLocationContext.Provider>
	);
};

DeliveryLocationProvider.propTypes = {
	deliveryUuid: string,
	children: node,
	type: string,
};
