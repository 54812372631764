import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	title: {
		fontSize: '28px',
		fontWeight: 500,
		margin: '24px 0 32px 0',
	},
	card: {
		'& .MuiCardContent-root': {
			padding: '8px',
		},
	},
	divider: {
		margin: '40px',
		marginLeft: '0px',
		marginRight: '0px',
	},
}));
