/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';

// Import utilities and helpers
import { useOfficeRatingProvider } from '../../../../context';
import { useProfile, useToggle, useTranslations } from 'components/utilities';
import { YMD_SLASH_TIME_FORMAT } from 'helpers';
import { useRatingReasons } from 'queries';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { fetchBookingPreview } from 'store/actions';

export const useFormContent = () => {
	const { on: isRatingHistorDialogOpen, toggle: toggleRatingHistoryDialog } =
		useToggle();
	const booking = useSelector(previewBookingSelector);
	const { data: reasons } = useRatingReasons({});
	const { values, submitting } = useFormState();
	const { t } = useTranslations();
	const dispatch = useDispatch();
	const { change } = useForm();
	const { isAdmin } = useProfile();

	const {
		setSubmittingLoading,
		toggleSubmitDialog,
		setSuccessSubmit,
		isSubmittingLoading,
		isSubmitDialogOpen,
		isSuccessSubmit,
	} = useOfficeRatingProvider();

	const officeRating = booking?.ratings?.office;

	const { rating_reason_uuid: ratingReasonUuid, rating } = values || {};

	const {
		rating_author_name: createdBy,
		created_at: createdAt,
		deleted_at: resetAt,
		deletion_author_name: resetBy,
	} = officeRating || {};

	const isReset = !!resetBy;

	const formatDate = (date) =>
		date && format(new Date(date), YMD_SLASH_TIME_FORMAT);

	const chosenRating = reasons.find(
		({ uuid }) => uuid === ratingReasonUuid
	)?.rating_number;

	const isRatingFieldDisabled = submitting || !ratingReasonUuid || !isAdmin;
	const isRatingFieldReadOnly = !!chosenRating;

	const isDescriptionRequired = useMemo(() => {
		const reasonOther = reasons.find((reason) => reason.reason.en === 'Other');

		if (!reasonOther) {
			return false;
		}

		return ratingReasonUuid === reasonOther.uuid;
	}, [ratingReasonUuid, reasons]);

	const isSubmitButtonDisabled =
		!ratingReasonUuid || !rating || submitting || !isAdmin;

	const convertedCreatedAt = formatDate(createdAt);
	const convertedResetAt = formatDate(resetAt);
	const caption = isReset
		? `${t(
				'bookings.preview_booking.ratings.reset_by'
		  )} ${resetBy} ${convertedResetAt}`
		: `${t(
				'bookings.preview_booking.ratings.rated_by'
		  )} ${createdBy} ${convertedCreatedAt}`;

	useEffect(() => {
		if (chosenRating) {
			change('rating', chosenRating);
		}
		//eslint-disable-next-line
	}, [chosenRating]);

	const clearDialogState = async () => {
		toggleSubmitDialog();
		setSubmittingLoading(false);
		setSuccessSubmit(false);
		await fetchBookingPreview({
			id: booking.uuid,
		})(dispatch);
	};

	return {
		isRatingHistorDialogOpen,
		isSubmitButtonDisabled,
		isDescriptionRequired,
		isRatingFieldDisabled,
		isRatingFieldReadOnly,
		isSubmittingLoading,
		isSubmitDialogOpen,
		isSuccessSubmit,
		isReset,
		toggleRatingHistoryDialog,
		toggleSubmitDialog,
		clearDialogState,
		officeRating,
		caption,
		createdBy,
		reasons,
	};
};
