import React from 'react';
import { format } from 'date-fns';
import { Typography } from '@material-ui/core';

// Import helpers and utils
import { useTranslations } from 'components/utilities';
import { YMD_SLASH_TIME_FORMAT } from 'helpers';
import { useEditBookingFormProvider } from '../../context';

// Import components
import { OldAndNewDates } from '../OldAndNewDates';

// Import styles
import { useStyles } from './EditHistoryDialog.styles';

export const useEditHistoryDialog = () => {
	const { t } = useTranslations();

	const {
		isEditHistoryOpen,
		toggleEditHistory,
		initialHistoryValues = [],
		isEditHistoryLoading,
	} = useEditBookingFormProvider();

	const classes = useStyles();

	const steps = initialHistoryValues?.map((record) => {
		const {
			created_by: createdBy,
			created_at: createdAt,
			status,
			period,
			new_period: newPeriod,
		} = record || {};

		const {
			pick_date: oldPickDate,
			drop_date: oldDropDate,
			pick_time: oldPickTime,
			days: oldDays,
		} = period || {};

		const {
			pick_date: newPickDate,
			drop_date: newDropDate,
			pick_time: newPickTime,
			days: newDays,
		} = newPeriod || {};

		const oldDate = `${oldPickDate} ${t(
			'common.to'
		)} ${oldDropDate} (${oldDays} ${t(
			`common.${oldDays === 1 ? 'day' : 'days'}`
		)}) - ${oldPickTime}`;

		const newDate = `${newPickDate} ${t(
			'common.to'
		)} ${newDropDate} (${newDays} ${t(
			`common.${newDays === 1 ? 'day' : 'days'}`
		)}) - ${newPickTime}`;

		const byLabel = t('common.fields.date_and_time_changed_by', {
			name: createdBy,
		});

		return {
			label: (
				<Typography variant="body1" color="inherit" className={classes.by}>
					{byLabel}
				</Typography>
			),
			description: (
				<OldAndNewDates
					currentDate={newDate}
					prevDate={oldDate}
					currentDateTitle={t('common.fields.new_date_and_time')}
					prevDateTitle={t('common.fields.previous_date_and_time')}
					containerClassName={classes.datesContainer}
				/>
			),
			date: format(new Date(createdAt), YMD_SLASH_TIME_FORMAT),
			status,
		};
	});

	return {
		steps,
		isEditHistoryOpen,
		toggleEditHistory,
		initialHistoryValues,
		isEditHistoryLoading,
	};
};
