import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	divider: {
		margin: '48px 0',
		width: '100%',
	},
	bottomBarContainer: {
		position: 'fixed',
		bottom: 0,
		left: 0,
		width: 'calc(100% - 360px)',
		backgroundColor: theme.palette.white,
		padding: theme.spacing(3),
	},
}));
