import React from 'react';
import { fetchCarList } from 'store/actions';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';
import { columns } from './columns';

// Import components
import { ExportFilters } from 'components/elements';
import { FilterFields } from '../Filters/components';

export const Export = () => (
	<ExportFilters
		fetchDataTableAction={fetchCarList}
		filterFields={() => <FilterFields />}
		initialValues={initialValues}
		formatValues={formatValues}
		columns={columns}
	/>
);
