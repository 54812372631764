import React from 'react';
import { Box, Typography } from '@material-ui/core';

// Import components
import { OptionsField } from 'views/Bookings/EditBooking/components';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './AdditionalServices.styles';

export const AdditionalServices = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box id="additional-service" className={classes.container}>
			<Typography className={classes.title}>
				{t('bookings.edit_booking.additional_services')}
			</Typography>
			<OptionsField />
		</Box>
	);
};
