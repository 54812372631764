import React from 'react';

// Import components
import { TableRPC } from 'components/utilities';
import { AdditionalInfo, Export, Filters, TableBodyRow } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

// Import helpers
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BUTTONS } from './helpers';

// Import store
import { fetchCustomers } from 'store/actions';

export const CustomersList = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchCustomers}
			title="nav.administrative.customers.customers_list"
			outerToolbarElements={<Export />}
			filtersBar={<Filters />}
			additionalNavigationElements={<AdditionalInfo />}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="state" buttons={BUTTONS} />
			}
			searchBarPlaceholder="common.placeholders.booking_id_or_mobile_number"
		/>
	);
};
