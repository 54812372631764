import React from 'react';
import { Form } from 'react-final-form';

// Import components
import { FormContent } from './components';

// Import helpers
import { SELECT_CAR_FILTER_INITIAL_VALUES } from './helpers';

export const SelectCarFilters = () => {
	return (
		<Form
			initialValues={SELECT_CAR_FILTER_INITIAL_VALUES}
			keepDirtyOnReinitialize
			onSubmit={() => {}}
		>
			{() => <FormContent />}
		</Form>
	);
};
