import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	accordion: {
		width: '100%',
		boxShadow: 'none',
		'&:before': {
			display: 'none',
		},
	},
	detailsContainer: {
		flexDirection: 'column',
	},
	title: {
		fontWeight: 600,
	},
}));
