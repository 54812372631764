import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import { find } from 'lodash';

// Import components
import { Grid, SelectFieldAdapter } from 'components/elements';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { OPTIONS } from './helpers';

export const BaseYesNoAllFilter = ({
	name,
	label = '',
	md = 3,
	xs = 6,
	smallFieldStyle = false,
	allLabel = 'common.all',
}) => {
	const { submitting } = useFormState();

	const { t } = useTranslations();

	const renderValue = (value) =>
		value === 'all' ? t(allLabel) : t(find(OPTIONS, { value }).label);

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={SelectFieldAdapter}
				label={label ? t(label) : ''}
				name={name}
				variant="outlined"
				disabled={submitting}
				smallfieldstyle={smallFieldStyle}
				SelectProps={{ renderValue }}
				margin="dense"
			>
				{OPTIONS.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{t(label)}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

BaseYesNoAllFilter.propTypes = {
	name: string.isRequired,
	label: string,
	md: oneOfType([number, string]),
	xs: oneOfType([number, string]),
	smallFieldStyle: oneOfType([string, bool]),
	allLabel: string,
};
