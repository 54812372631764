import React from 'react';
import { object } from 'prop-types';

// Import components
import { PreviewField } from 'components/elements';

export const VatPreviewField = ({ customData }) => {
	return (
		<PreviewField
			label="common.fields.vat_with_value"
			labelOptions={{ value: customData.total.amount_tax_rate }}
		>
			{customData.total.amount_tax_diff}
		</PreviewField>
	);
};

VatPreviewField.propTypes = {
	customData: object.isRequired,
};
