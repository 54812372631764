import React from 'react';
import { fetchCompanyList } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { ExportFilters } from 'components/elements';
import { FilterFields } from '../Filters/components';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';

export const Export = () => {
	return (
		<ExportFilters
			fetchDataTableAction={fetchCompanyList}
			filterFields={() => <FilterFields />}
			initialValues={initialValues}
			formatValues={formatValues}
			columns={columns}
		/>
	);
};
