import React from 'react';
import { Box } from '@material-ui/core';
import { object } from 'prop-types';

// Import components
import { Typography } from 'components/elements';

// Import styles
import { useStyles } from './ServicesSection.styles';

// Import helpers
import { getServices } from './helpers';

export const ServicesSection = ({ car }) => {
	const classes = useStyles();
	const services = getServices(car);

	return (
		<Box className={classes.container}>
			{services.map(({ text, icon: Icon }) => (
				<Box className={classes.serviceContainer} key={text}>
					<Icon className={classes.icon} />
					<Typography className={classes.text}>{text}</Typography>
				</Box>
			))}
		</Box>
	);
};

ServicesSection.propTypes = {
	car: object.isRequired,
};
