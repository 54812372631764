// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	ACTIVE_CELL_STATES,
	replaceEditCustomerUrl,
} from 'helpers';

// Import store
import { deleteCustomer, updateStateCustomer } from 'store/actions';

export const useCell = (cell) => {
	const { state, uuid: id } = cell.row.original;

	const { EDIT, DELETE, ACTIVE } = ACTION_MENU_POPUP_FIELD_TYPES;

	const action = state === ACTIVE_CELL_STATES.ACTIVE ? 'blocked' : 'activate';

	const isActive = state === ACTIVE_CELL_STATES.ACTIVE ? true : false;

	const activeAction = () => updateStateCustomer({ action, id });
	const editViewUrl = replaceEditCustomerUrl(id);
	const deleteAction = () => deleteCustomer({ id });

	const actionMenuFields = [
		{
			type: ACTIVE,
			isDivider: true,
			activeState: isActive,
			activeAction,
		},
		{ type: EDIT, editViewUrl },
		{ type: DELETE, deleteAction },
	];

	return { actionMenuFields };
};
