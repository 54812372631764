import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCoordinator = (corporateUuid) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.B2B.COORDINATOR(corporateUuid),
		async () =>
			await services.get(ENDPOINTS.B2B.COORDINATOR({ corporateUuid })),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data || null;

	return { data: fetchedData, isFetching, isError };
};
