import React from 'react';
import { array, bool, object, oneOfType, string } from 'prop-types';
import { Grid, TableCell } from '@material-ui/core';

// Import components
import {
	AssignOfficesDialog,
	ExtraActions,
	PreviewPopup,
	CloseContractDialog,
	ExtendBookingDialog,
} from './components';
import {
	BookingHistory,
	BookingHistoryProvider,
	TransactionHistory,
	TransactionHistoryProvider,
} from 'components/elements/organisms/Booking';
import {
	SubscriptionHistory,
	EditCompensationModal,
	MarkUrgentDialog,
	CarsLogHistoryProvider,
	CarsLogHistory,
	EditPrices,
} from 'components/elements';

// Import utils
import { useNewActionsMenuTableCell } from './useNewActionsMenuTableCell';
import {
	BookingStatusActionsProvider,
	MarkUrgentProvider,
} from 'components/context';
import { AvailabilityTableCellProvider } from '../AvailabilityTableCell/context';
import { AvailabilityDialog } from '../AvailabilityTableCell/components';

export const NewActionsMenuTableCell = (props) => {
	const {
		extraActions = false,
		minWidth = 'auto',
		bookingUuid = '',
		carAvailability = {},
		cell,
	} = props;

	const {
		handleOnCompensationClose,
		toggleCarAvailabilityDialog,
		toggleCarsLogHistoryDialog,
		toggleSubscriptionsHistory,
		toggleTransactionsHistory,
		toggleCompensationDialog,
		toggleAssignOfficesDialog,
		toggleBookingHistory,
		toggleCloseContractDialog,
		toggleExtendBookingDialog,
		isExtendBookingDialogOpen,
		isCarAvailabilityDialogOpen,
		isCarsLogHistoryDialogOpen,
		isTransactionsHistoryOpen,
		isSubscriptionHistoryOpen,
		isAssignOfficesDialogOpen,
		isCompensationDialogOpen,
		isEditPriceDialogOpen,
		toggleEditPriceDialog,
		isBookingHistoryOpen,
		isCloseContractDialogOpen,
		previewPopupProps,
		extraActionsProps,
		urgentAssistance,
		fetchData,
		booking,
		rowUuid,
		uuid,
	} = useNewActionsMenuTableCell({
		extraActions,
		bookingUuid,
		props,
		cell,
	});

	return (
		<TableCell
			key={cell.column.id}
			width={150}
			style={{ minWidth }}
			{...cell.getCellProps()}
		>
			<MarkUrgentProvider bookingUuid={rowUuid}>
				<Grid container alignItems="center" wrap="nowrap" spacing={1}>
					<Grid item>
						{extraActions && (
							<ExtraActions {...extraActionsProps} cell={cell} />
						)}
					</Grid>
					<Grid item>
						<BookingStatusActionsProvider
							previewPopupProps={previewPopupProps}
							onChange={fetchData}
							booking={booking}
						>
							<PreviewPopup {...previewPopupProps} />
						</BookingStatusActionsProvider>
					</Grid>
				</Grid>

				{isTransactionsHistoryOpen && (
					<TransactionHistoryProvider bookingId={rowUuid}>
						<TransactionHistory
							open={isTransactionsHistoryOpen}
							onClose={toggleTransactionsHistory}
						/>
					</TransactionHistoryProvider>
				)}

				{isBookingHistoryOpen && (
					<BookingHistoryProvider bookingId={rowUuid}>
						<BookingHistory
							open={isBookingHistoryOpen}
							onClose={toggleBookingHistory}
						/>
					</BookingHistoryProvider>
				)}

				{isSubscriptionHistoryOpen && (
					<SubscriptionHistory
						uuid={uuid}
						on={isSubscriptionHistoryOpen}
						onClose={toggleSubscriptionsHistory}
					/>
				)}

				{isCompensationDialogOpen && (
					<EditCompensationModal
						open={isCompensationDialogOpen}
						onClose={toggleCompensationDialog}
						onInvalid={fetchData}
						onCustomSubmit={handleOnCompensationClose}
					/>
				)}

				<MarkUrgentDialog urgentReasons={[urgentAssistance]} />
			</MarkUrgentProvider>

			<EditPrices
				toggle={toggleEditPriceDialog}
				isOpen={isEditPriceDialogOpen}
				cell={cell}
			/>
			{isAssignOfficesDialogOpen && (
				<AssignOfficesDialog
					cell={cell}
					isOpen={isAssignOfficesDialogOpen}
					toggle={toggleAssignOfficesDialog}
				/>
			)}
			{isCarsLogHistoryDialogOpen && (
				<CarsLogHistoryProvider uuid={cell.row.original.uuid}>
					<CarsLogHistory
						onClose={toggleCarsLogHistoryDialog}
						open={isCarsLogHistoryDialogOpen}
					/>
				</CarsLogHistoryProvider>
			)}

			{isCarAvailabilityDialogOpen && (
				<AvailabilityTableCellProvider
					columnAccessors={carAvailability.columnAccessors}
					tableData={carAvailability.tableData}
					carData={carAvailability.carData}
					columns={carAvailability.columns}
					toggleDialog={toggleCarAvailabilityDialog}
					isOpen={isCarAvailabilityDialogOpen}
				>
					<AvailabilityDialog />
				</AvailabilityTableCellProvider>
			)}
			{isCloseContractDialogOpen && (
				<CloseContractDialog
					isOpen={isCloseContractDialogOpen}
					toggle={toggleCloseContractDialog}
					booking={cell?.row?.original}
				/>
			)}
			{isExtendBookingDialogOpen && (
				<ExtendBookingDialog
					isOpen={isExtendBookingDialogOpen}
					toggle={toggleExtendBookingDialog}
					booking={cell?.row?.original}
				/>
			)}
		</TableCell>
	);
};

NewActionsMenuTableCell.propTypes = {
	extraActions: oneOfType([object, bool]),
	previewPopupFields: array,
	cell: object.isRequired,
	bookingUuid: string,
	minWidth: string,
	isHovered: bool,
	carAvailability: object,
};
