export const DATE_TYPES = {
	DROP_DATE: 'drop_date',
	PICK_DATE: 'pick_date',
};

export const CALENDAR_TYPES = {
	HIJRI: 'hijri',
	GREGORIAN: 'gregorian',
};

export const DAY_NAMES = [
	{ en: 'Monday', ar: 'الاثنين' },
	{ en: 'Tuesday', ar: 'الثلاثاء' },
	{ en: 'Wednesday', ar: 'الأربعاء' },
	{ en: 'Thursday', ar: 'الخميس' },
	{ en: 'Friday', ar: 'الجمعة' },
	{ en: 'Saturday', ar: 'السبت' },
	{ en: 'Sunday', ar: 'الأحد' },
];
