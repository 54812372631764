import React from 'react';
import { Box, Button, Popover } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

// Import utilities
import TableExportButtons from '../TableExportButtons';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './TableExportPopup.styles';

// Import assets
import { DownloadDocumentIcon } from 'assets/icons';

export const TableExportPopup = () => {
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<Box>
					<Button
						startIcon={<DownloadDocumentIcon />}
						className={classes.button}
						{...bindTrigger(popupState)}
					>
						{t('common.buttons.export')}
					</Button>
					<Popover
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'center',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'center',
						}}
						{...bindPopover(popupState)}
					>
						<TableExportButtons />
					</Popover>
				</Box>
			)}
		</PopupState>
	);
};
