import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CancellationStatusFilter,
	CompanyFilter,
	DateRangeFilter,
	BaseYesNoAllFilter,
} from 'components/elements';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<CompanyFilter
				name="company_uuid"
				label="waiting_customer_info.filters.company_name"
				md={6}
			/>
			<DateRangeFilter
				nameFrom="created_at"
				nameUntil="created_at_to"
				label="bookings.filters.created_at"
				combinedInputs
				md={6}
			/>
			<DateRangeFilter
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
				md={6}
			/>
			<DateRangeFilter
				nameFrom="drop_date"
				nameUntil="drop_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
				md={6}
			/>
			<BaseYesNoAllFilter
				name="is_compensation"
				label="bookings.filters.compensation"
				md={6}
			/>
			<CancellationStatusFilter md={6} />
		</Grid>
	);
};
