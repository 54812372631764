import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers
import { splitText } from 'helpers';

// Import components
import { DateBody } from 'components/elements';

export const ExtendedAtTableCell = ({ cell, showTime = true }) => {
	const dates = splitText(cell.row.original.extension_created_at, ',');

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			{dates &&
				dates.map((item) => {
					const [date, time] = splitText(item, ' ');
					return (
						<DateBody key={item} date={date} time={showTime ? time : null} />
					);
				})}
		</TableCell>
	);
};

ExtendedAtTableCell.propTypes = {
	cell: object,
	showTime: bool,
};
