// Import helpers
import { ACTION_MENU_POPUP_FIELD_TYPES, replaceEditFaqUrl } from 'helpers';

// Import store
import { deleteFaq } from 'store/actions';

const { EDIT, DELETE } = ACTION_MENU_POPUP_FIELD_TYPES;

const deleteAction = ({ id }) => deleteFaq({ id });

export const getActionMenuFields = ({ uuid }) => [
	{ type: EDIT, editViewUrl: replaceEditFaqUrl(uuid) },
	{ type: DELETE, deleteAction: () => deleteAction({ id: uuid }) },
];
