import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import services from 'services/services';
import { supplierUuidSelector, supplierPeriodSelector } from 'store/selectors';

// Import helpers
import {
	QUERY_KEYS,
	DEFAULT_DATE_FORMAT,
	convertDate,
	ENDPOINTS,
} from 'helpers';

const useCompanyCollectionCarFilter = ({ values, enabled }) => {
	const { year, model_uuid, maker_uuid } = values?.vehicle || {};

	const { pickup_date, pick_time, drop_date } = values || {};

	const previous_office_uuid = useSelector(supplierUuidSelector);
	const {
		drop_date: dropDateSupplier,
		pick_date: pickDateSupplier,
		pick_time: pickTimeSupplier,
	} = useSelector(supplierPeriodSelector);

	const body = {
		vehicle_year: year,
		car_model_uuid: model_uuid,
		car_manufacturer_uuid: maker_uuid,
		previous_office_uuid,
		pickup_date: convertDate(
			`${convertDate(pickup_date || pickDateSupplier, DEFAULT_DATE_FORMAT)} ${
				pick_time || pickTimeSupplier
			}`
		),
		drop_date: convertDate(drop_date || dropDateSupplier, DEFAULT_DATE_FORMAT),
	};

	const { data, isFetching } = useQuery(
		QUERY_KEYS.COMPANIES.COLLECTION_CAR_FILTER(JSON.stringify(body)),
		async () => await services.post(ENDPOINTS.CARS.COMPANY_CAR_FILTER, body),
		{ refetchOnWindowFocus: false, enabled }
	);
	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default useCompanyCollectionCarFilter;
