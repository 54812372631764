import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	buttonsContainer: {
		display: 'flex',
		width: '100%',
		alignItems: 'center',
		justifyContent: 'flex-end',
		flexWrap: 'wrap',
		gap: '8px',
	},
	button: {
		backgroundColor: theme.palette.white,
		border: '1px solid',
		borderColor: theme.palette.background[5],
		color: theme.palette.text[2],
		borderRadius: '8px',
		padding: '7px 16px',
	},
	addOfficeButton: {
		backgroundColor: theme.palette.primary.main,
		border: 'none',
		color: theme.palette.white,
		'&:hover': {
			backgroundColor: theme.palette.primary.dark,
		},
	},
	icon: {
		width: 17,
		height: 17,
		marginRight: theme.spacing(1),
	},
}));
