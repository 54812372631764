import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useCityCollection = ({ companyUuid }) => {
	const enabled = companyUuid && companyUuid !== 'all' && companyUuid !== '';

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CITIES.COLLECTION(companyUuid),
		async () =>
			await services.get(
				ENDPOINTS.CITIES.COMPANY_CITIES_COLLECTION({ companyUuid })
			),
		{ refetchOnWindowFocus: false, enabled }
	);
	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};

export default useCityCollection;
