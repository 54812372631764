import React from 'react';
import { bool } from 'prop-types';

// Import components
import { AdditionalColumnsDialog } from 'components/elements';

// Import helpers
import { getAdditionalInfoFields } from './helpers';

export const AdditionalInfo = ({
	showAssignedDriverAdditional = false,
	showDeliveryStatusAdditional = false,
}) => (
	<AdditionalColumnsDialog
		fields={getAdditionalInfoFields({
			showAssignedDriverAdditional,
			showDeliveryStatusAdditional,
		})}
	/>
);

AdditionalInfo.propTypes = {
	showAssignedDriverAdditional: bool,
	showDeliveryStatusAdditional: bool,
};
