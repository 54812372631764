import React, { useState } from 'react';
import { createContext } from 'react';
import { node } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import { useHistory } from 'react-router-dom';

// Import components
import {
	CarCatalog,
	CompanySelector,
} from '../components/QuickFinderDialog/components/GoalSelector/components';

// Import helpers and utils
import { GOALS_TYPES } from './helpers';
import {
	replaceAddCarUrl,
	replaceCompanyUsersUrl,
	replaceEditCompanyUrl,
} from 'helpers';
import { useToggle } from 'components/utilities';

const { CAR_CATALOG, COMPANY_PROFILES, MANAGERS_PROFILES } = GOALS_TYPES;

export const QuickFinderProviderContext = createContext(null);

export const QuickFinderProvider = ({ children }) => {
	const { push } = useHistory();

	const [selectedGoal, setSelectedGoal] = useState(null);

	const { on: isQuickFinderDialogOpen, toggle: toggleQuickFinderDialog } =
		useToggle();

	const { values } = useFormState();
	const { reset } = useForm();

	const { company, office, model, maker, year } = values || {};

	const handleSelectGoal = (goal) => {
		reset();
		setSelectedGoal(goal);
	};

	const handleCloseDialog = () => {
		reset();
		setSelectedGoal(null);
		toggleQuickFinderDialog();
	};

	const handleAddNewCar = () => push(replaceAddCarUrl(office));
	const goToCompanyProfile = () => push(replaceEditCompanyUrl(company));
	const goToManagerProfile = () => push(replaceCompanyUsersUrl(company));

	const renderGoalComponent = (goal) => {
		switch (goal) {
			case CAR_CATALOG:
				return {
					component: <CarCatalog />,
					actionButtonText: 'common.buttons.edit_car',
					isButtonDisabled: !model || !maker || !year,
					action: () => {},
				};

			case COMPANY_PROFILES:
				return {
					component: <CompanySelector />,
					actionButtonText: 'company.quick_finder.company_profiles',
					isButtonDisabled: !company,
					action: goToCompanyProfile,
				};

			case MANAGERS_PROFILES:
				return {
					component: <CompanySelector />,
					actionButtonText: 'company.quick_finder.manager_profiles',
					isButtonDisabled: !company,
					action: goToManagerProfile,
				};

			default:
				return null;
		}
	};

	const goalComponent = renderGoalComponent(selectedGoal);

	const isEditButtonVisible = !!selectedGoal;

	const isAddButtonVisible = selectedGoal === CAR_CATALOG && company && office;

	return (
		<QuickFinderProviderContext.Provider
			value={{
				selectedGoal,
				goalComponent,
				handleAddNewCar,
				handleSelectGoal,
				handleCloseDialog,
				goToCompanyProfile,
				goToManagerProfile,
				isAddButtonVisible,
				isEditButtonVisible,
				isQuickFinderDialogOpen,
				toggleQuickFinderDialog,
			}}
		>
			{children}
		</QuickFinderProviderContext.Provider>
	);
};

QuickFinderProvider.propTypes = {
	children: node,
};
