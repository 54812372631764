import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const PercentIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M15.1313 12.869C15.7561 13.4939 15.7561 14.5069 15.1313 15.1318C14.5064 15.7566 13.4934 15.7566 12.8685 15.1318C12.2437 14.5069 12.2437 13.4939 12.8685 12.869C13.4934 12.2442 14.5064 12.2442 15.1313 12.869"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M7.13128 4.86902C7.75612 5.49386 7.75612 6.50692 7.13128 7.13176C6.50644 7.7566 5.49337 7.7566 4.86853 7.13176C4.24369 6.50692 4.24369 5.49386 4.86853 4.86902C5.49337 4.24418 6.50644 4.24418 7.13128 4.86902"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14 6L6 14"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
