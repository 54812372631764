import React from 'react';
import { TableCell } from '@material-ui/core';
import { bool, object } from 'prop-types';

// Import helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';

// Import components
import {
	BookingIdTableCell,
	PeriodTableCell,
	StateWithActionsTableCell,
} from 'components/elements';

const { ID, CUSTOMER_NAME, STATUS, PERIOD } = COLUMNS_ACCESSORS;

export const Cell = ({ isHovered, cell }) => {
	const renderCell = (cell) => {
		if (cell.column.isHidden) return null;

		const {
			uuid: bookingUuid,
			customer_phone: customerPhone,
			customer_name: customerName,
		} = cell.row.original;

		switch (cell.column.id) {
			case ID:
				return (
					<BookingIdTableCell
						key={cell.column.id}
						isHovered={isHovered}
						cell={cell}
						uuid={bookingUuid}
					/>
				);

			case CUSTOMER_NAME:
				return (
					<TableCell key={cell.column.id}>
						{customerName}
						<br /> {customerPhone}
					</TableCell>
				);

			case STATUS:
				return (
					<StateWithActionsTableCell
						key={cell.column.id}
						cell={cell}
						isHovered={isHovered}
					/>
				);

			case PERIOD:
				return <PeriodTableCell key={cell.column.id} cell={cell} />;

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
