import React from 'react';
import { object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils and helpers
import { useReturnStatus } from './useReturnStatus';
import { useTranslations } from 'components/utilities';

// Import components
import { ChipLabel } from 'components/elements/atoms';

// Import styles
import { useStyles } from './ReturnStatusTableCell.styles';

export const ReturnStatusTableCell = ({ cell }) => {
	const { chipLabel, chipColors, state } = useReturnStatus({ cell });
	const classes = useStyles({ chipColors });
	const { t } = useTranslations();

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			style={{ minWidth: '170px' }}
		>
			{state && (
				<ChipLabel
					fullWidth
					label={t(chipLabel)}
					color="gray"
					className={classes.chip}
				/>
			)}
		</TableCell>
	);
};

ReturnStatusTableCell.propTypes = {
	cell: object.isRequired,
};
