import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BookingStatusFilter, DateRangeInput } from 'components/elements';

export const AdditionalFilterFields = () => (
	<>
		<BookingStatusFilter
			name="state"
			xs="auto"
			md="auto"
			smallFieldStyle
			withSearch
		/>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="created_at"
				nameUntil="created_at_to"
				label="bookings.filters.created_at"
				combinedInputs
				isToggleButton
			/>
		</Grid>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
				isToggleButton
			/>
		</Grid>
	</>
);
