import { useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import moment from 'moment';

// Import helpers
import { YMD_DASH_FORMAT, TIME_FORMAT } from 'helpers';
import { SERVICE_TYPES } from 'views/Bookings/CreateBooking/helpers';
import { getCarBookingDetails } from 'store/actions';
import { getFields } from './helpers';
import { useIsRTL } from 'components/utilities';

export const useAdditionalServices = () => {
	const {
		values: {
			item: { uuid },
			period: { pick_date: pickDate, drop_date: dropDate, pick_time: pickTime },
			service_type: serviceType,
			location,
			delivery,
			additional_services: additionalServices,
		},
	} = useFormState();

	const [availableServices, setAvailableServices] = useState([]);
	const [services, setServices] = useState([]);
	const [loading, setLoading] = useState(false);

	const isSelected = (name) => {
		const type = name.split('.')[1];
		return !!additionalServices[type];
	};

	const isRTL = useIsRTL();

	const isPickup = serviceType === SERVICE_TYPES.PICKUP;

	const getPayload = () => {
		const payload = {
			period: {
				pick_date: moment(pickDate).format(YMD_DASH_FORMAT),
				drop_date: moment(dropDate).format(YMD_DASH_FORMAT),
				pick_time: moment(pickTime).format(TIME_FORMAT),
			},
			options: {
				...(!isPickup && {
					delivery: true,
					delivery_drop: true,
					delivery_location: {
						delivery_lat: delivery.lat,
						delivery_lng: delivery.lng,
					},
				}),
			},
			...(isPickup && { location }),
		};
		return payload;
	};

	useEffect(() => {
		if (uuid && pickDate && dropDate && pickTime) {
			setLoading(true);
			const data = getPayload();
			getCarBookingDetails({
				isRTL,
				uuid,
				data,
				successCallback: (resp) => {
					setAvailableServices(resp.data?.data?.available_services);
					setServices(resp.data?.data?.services);
					setLoading(false);
				},
				// eslint-disable-next-line
				errorCallback: () => setLoading(false),
			});
		}
		// eslint-disable-next-line
	}, [uuid, pickDate, dropDate, pickTime, isRTL, location, delivery]);

	const FIELDS = getFields({
		availableServices,
		isPickup,
		isSelected,
		services,
	});

	return { FIELDS, isSelected, loading };
};
