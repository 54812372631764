/* eslint-disable */
import { format } from 'date-fns';

// Import utils and helpers
import { FIELDS_ACCESSORS } from 'views/Bookings/BookingDetails/components/helpers';
import { useTranslations } from 'components/utilities';
import { useInvoiceDetails } from 'queries';
import { getAdditionalServices, getInvoiceStatusChipColor } from './helpers';
import {
	DEFAULT_DATE_TIME_COMMA_FORMAT_12_HOUR,
	reverseAndSplitString,
} from 'helpers';

export const useReceiptDetailsDialog = ({ row, isOpen }) => {
	const { t } = useTranslations();

	const transKey = 'bookings.preview_booking';

	const { uuid, created_at: createdAt } = row || {};

	const { data, isLoading } = useInvoiceDetails({
		uuid,
		enabled: !!isOpen,
	});

	const {
		number = '',
		purpose,
		status,
		items,
		tax_rate: taxRate,
		seller,
		payments,
		discounts = [],
	} = data || {};

	const paymentGatwayMethod = payments?.[0]?.gateway;

	const { company_name: companyName } = seller || {};

	const [bookingId] = number?.split('/');

	const bookingKey =
		items && Object.keys(items).find((key) => key.startsWith('booking'));
	const booking = items?.[bookingKey] || {};

	const {
		quantity,
		metadata,
		sum_price: sumPrice,
		unit_price: unitPrice,
	} = booking || {};

	const { drop_date: dropDate, pickup_datetime: pickDate = '' } =
		metadata || {};

	const title = `${t(`${transKey}.receipt`)} #${bookingId.toUpperCase()} (${t(
		`${transKey}.booking_purpose.${purpose}`
	)})`;

	const [, totalRentPriceNett] = reverseAndSplitString({
		string: sumPrice?.nett || '',
	});

	const [, totalRentPriceGross] = reverseAndSplitString({
		string: sumPrice?.gross || '',
	});

	const [unitCurrency, unitPriceNum] = reverseAndSplitString({
		string: unitPrice?.nett || '',
	});

	const additionalServices = getAdditionalServices();

	const customData = {
		issueDate: format(
			new Date(createdAt),
			DEFAULT_DATE_TIME_COMMA_FORMAT_12_HOUR
		),
		period: { pickDate: pickDate.split(' ')?.[0], dropDate, days: quantity },
		totalRentPriceNett,
		additionalFees: '',
		taxRate: (Number(totalRentPriceGross) - Number(totalRentPriceNett)).toFixed(
			2
		),
		totalPaid: totalRentPriceGross,
		companyName,
		paymentMethod: t(`common.payment_methods.${paymentGatwayMethod}`),
		discounts,
	};

	additionalServices?.length &&
		additionalServices.map(
			({ type, price }) => (customData[`service_${type}`] = price)
		);

	const RECEIPT_INFO_FIELDS = [
		{
			accessor: 'issueDate',
			label: `${transKey}.receipt_details.issue_date`,
		},
		{
			accessor: 'companyName',
			label: `${transKey}.receipt_details.company`,
		},
		{
			accessor: FIELDS_ACCESSORS.PERIOD,
			label: `${transKey}.receipt_details.period`,
		},
		{
			accessor: 'paymentMethod',
			label: `${transKey}.receipt_details.payment_method`,
		},
	];

	const couponText = discounts?.length
		? `${discounts?.[0]?.quantity}% ${t(
				`${transKey}.receipt_details.coupon`
		  )} "${discounts?.[0]?.metadata.code}"`
		: '';

	const COST_BREAKDOWN_FIELDS = [
		{
			accessor: 'totalRentPriceNett',
			label: `${transKey}.receipt_details.total_rent_price`,
			labelOptions: { price: `${unitPriceNum} ${unitCurrency}`, quantity },
		},
		{
			accessor: 'taxRate',
			label: `${transKey}.receipt_details.vat`,
			labelOptions: { precentage: taxRate },
		},
		{
			accessor: 'totalPaid',
			label: `${transKey}.receipt_details.total_paid`,
		},
	];

	discounts?.length &&
		COST_BREAKDOWN_FIELDS.splice(COST_BREAKDOWN_FIELDS.length - 2, 0, {
			accessor: 'total_discount',
			label: `${transKey}.receipt_details.total_discount`,
			labelSubText: couponText,
		});

	additionalServices?.length &&
		additionalServices.map(({ type }) => {
			COST_BREAKDOWN_FIELDS.splice(1, 0, {
				accessor: `service_${type}`,
				label: `common.services.${type}`,
			});
		});

	customData.additionalFees &&
		COST_BREAKDOWN_FIELDS.splice(1, 0, {
			accessor: 'additionalFees',
			label: `${transKey}.receipt_details.additional_fees`,
		});

	const statusChipColor = getInvoiceStatusChipColor(status);
	const statusChipLabel = t(`${transKey}.booking_invoice_status.${status}`);

	return {
		RECEIPT_INFO_FIELDS,
		COST_BREAKDOWN_FIELDS,
		isLoading,
		title,
		statusChipColor,
		statusChipLabel,
		customData,
	};
};
