import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import { useSelector } from 'react-redux';

// Import utils and helpers
import { useAllCities, useSpecialOfferCompanyCars } from 'queries';
import { useMessage, useTranslations } from 'components/utilities';

export const useSpecialOfferDetails = () => {
	const { companyList } = useSelector((store) => store.specialOffer.form);
	const { values, modified } = useFormState();
	const { change } = useForm();
	const { message } = useMessage();
	const { t } = useTranslations();
	const { data: cities } = useAllCities();
	const { data: companyCars, isFetching } = useSpecialOfferCompanyCars(
		values?.company_uuid
	);

	const filteredCities = cities.filter(({ uuid }) => {
		const companyCarsCityUuids = companyCars.map(({ city_uuid }) => city_uuid);
		return companyCarsCityUuids.includes(uuid);
	});

	const availableCars = companyCars.filter(({ city_uuid }) =>
		values.city_uuids.includes(city_uuid)
	);

	useEffect(() => {
		if (modified.company_uuid) {
			change('vehicle_uuids', []);
			change('city_uuids', []);
		}
		//eslint-disable-next-line
	}, [values.company_uuid]);

	useEffect(() => {
		change('vehicle_uuids', []);

		//eslint-disable-next-line
	}, [values.city_uuids]);

	useEffect(() => {
		if (values.company_uuid && !filteredCities.length && !isFetching) {
			message.warning(t('special_offer.form.messages.no_available_cities'));
		}
		//eslint-disable-next-line
	}, [isFetching]);

	return {
		filteredCities,
		availableCars,
		companyList,
		values,
	};
};
