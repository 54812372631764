import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() => ({
	container: {
		maxWidth: '70%',
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '16px',
	},
}));
