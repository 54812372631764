import { Button, withStyles } from '@material-ui/core';

export const ButtonDanger = withStyles((theme) => ({
	root: {
		background: theme.palette.error.main,
		color: theme.palette.white,

		'&:hover': {
			background: theme.palette.error.main,
		},
	},
}))(Button);
