// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { ADMIN } from 'helpers';

export const useComments = (booking) => {
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	const comments = booking?.discuss;

	const adminComments =
		comments?.filter((comment) => comment.user_type === ADMIN) ?? [];

	const agentComments =
		comments?.filter((comment) => comment.user_type !== ADMIN) ?? [];

	const commentsSections = [
		{
			title: t('bookings.preview_booking.comments.telgani_comments'),
			comments: adminComments,
			isEmpty: !adminComments.length,
			isVisible: isAdmin,
			type: 'admin',
		},
		{
			title: t('bookings.preview_booking.comments.company_comments'),
			comments: agentComments,
			isEmpty: !agentComments.length,
			isVisible: true,
			type: 'supplier',
		},
	];

	const noCommentsMessage = t('bookings.preview_booking.comments.no_comments');

	return {
		commentsSections,
		noCommentsMessage,
	};
};
