import React from 'react';
import { fetchPromoCodeBookingList } from 'store/actions';
import { useParams } from 'react-router-dom';

// Import helpers and utils
import { TableRPC } from 'components/utilities';
import { buttons, TOOLBAR_CONTENT } from './helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, Export, Filters } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

export const PromoCodePreview = () => {
	const { id } = useParams();

	return (
		<TableRPC
			fetchDataTableAction={fetchPromoCodeBookingList}
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			export={<Export />}
			columns={columns}
			title={id}
			filtersBar={<Filters />}
			toolBarContent={TOOLBAR_CONTENT}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="state" buttons={buttons} />
			}
		/>
	);
};
