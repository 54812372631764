import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useDriverUsersListQuery = ({
	bookingUuid,
	deliveryType,
	enabled = true,
	externalKey,
}) => {
	const path = `/v2/booking/${bookingUuid}/driver/list?delivery_type=${deliveryType}`;

	const { data, isLoading, isFetched, isFetching, isError } = useQuery(
		externalKey || QUERY_KEYS.USERS.DRIVERS_LIST(bookingUuid),
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !!bookingUuid && enabled,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isLoading, isError, isFetched, isFetching };
};
