import React from 'react';
import { object } from 'prop-types';
import { Box, Grid } from '@material-ui/core';

// Import styles
import { useStyles } from './BookingInfoSection.styles';

// Import helpers
import { useBookingInfo } from './useBookingInfo';

export const BookingInfoSection = ({ booking }) => {
	const classes = useStyles();
	const { infoList } = useBookingInfo(booking);
	return (
		<Box className={classes.container}>
			{infoList.map(({ label, value }) => (
				<Grid container justifyContent="space-between" key={label}>
					<Grid item className={classes.label}>
						{label}
					</Grid>
					<Grid item className={classes.value}>
						{value}
					</Grid>
				</Grid>
			))}
		</Box>
	);
};

BookingInfoSection.propTypes = {
	booking: object.isRequired,
};
