import { useDispatch, useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { submitOfficeRatingForm } from 'store/actions';

// Import utils
import { useOfficeRatingProvider } from '../../context';

export const useOfficeRatingForm = () => {
	const dispatch = useDispatch();

	const { setSuccessSubmit, setSubmittingLoading } = useOfficeRatingProvider();

	const { uuid: bookingUuid } = useSelector(previewBookingSelector);

	const onSubmit = async (values) => {
		let apiErrors = {};

		setSubmittingLoading(true);
		setSuccessSubmit(false);

		await submitOfficeRatingForm({
			bookingUuid,
			dispatch,
			values,
			callbackSuccess: () => {
				setSuccessSubmit(true);
				setSubmittingLoading(false);
			},
			errorCallback: (error) => {
				setSubmittingLoading(false);
				apiErrors = error;
			},
		});

		return apiErrors;
	};

	return {
		onSubmit,
	};
};
