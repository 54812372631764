import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_TIME_COMMA_FORMAT } from 'helpers';

export const usePeriodTableCell = ({
	cell,
	outerDateAfter,
	outerDateBefore,
	isExtended,
}) => {
	const {
		pick_date: pickDate,
		pick_time: pickTime,
		drop_date: dropDate,
		extension,
	} = cell.row.original || {};

	const { extension_request_date: extensionRequestDate } = extension || {};

	const extensionText =
		extensionRequestDate &&
		format(new Date(extensionRequestDate), DEFAULT_DATE_TIME_COMMA_FORMAT);

	const getPrevText = () => {
		if (outerDateBefore) {
			return outerDateBefore;
		}

		if (isExtended) {
			return extensionText;
		}

		return `${pickDate}, ${pickTime}`;
	};

	const prevText = getPrevText();

	const nextText = outerDateAfter ? outerDateAfter : dropDate;

	return {
		prevText,
		nextText,
	};
};
