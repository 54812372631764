import React from 'react';
import { Box, Button, Popover } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

// Import utils
import { useBookingStatusActionsProvider } from 'components/context';
import { useTranslations } from 'components/utilities';

// Import styles
import { popoverStyle, useStyles } from './ManageButton.styles';

// Import assets
import { CheckMarkDoneIcon, CircleCloseIcon } from 'assets/icons';

export const ManageButton = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { handleApproveCancellation, handleAccept } =
		useBookingStatusActionsProvider();

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<Box className={classes.container}>
					<Button
						variant="contained"
						color="primary"
						size="small"
						fullWidth
						endIcon={
							popupState.isOpen ? (
								<KeyboardArrowUpIcon />
							) : (
								<KeyboardArrowDownIcon />
							)
						}
						{...bindTrigger(popupState)}
					>
						{t('common.buttons.manage')}
					</Button>
					<Popover
						PaperProps={{
							style: popoverStyle,
							elevation: 1,
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: -10,
							horizontal: 'right',
						}}
						{...bindPopover(popupState)}
					>
						<Box className={classes.popoverContent}>
							<Button
								className={classes.reAcceptButton}
								fullWidth
								startIcon={
									<CheckMarkDoneIcon className={classes.reAcceptIcon} />
								}
								onClick={handleAccept}
							>
								{t('common.buttons.re_accept_booking')}
							</Button>
							<Button
								className={classes.confirmRejectionButton}
								fullWidth
								onClick={handleApproveCancellation}
								startIcon={
									<CircleCloseIcon className={classes.confirmRejectionIcon} />
								}
							>
								{t('common.buttons.confirm_rejection')}
							</Button>
						</Box>
					</Popover>
				</Box>
			)}
		</PopupState>
	);
};
