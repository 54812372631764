import React from 'react';

// Import helpers
import { formatValues, initialValues } from '../Filters/helpers';

// Import components
import { FilterFields } from '../Filters/components/FilterFields';
import { TableExportFilters } from 'components/elements';

export const ExportFilters = () => (
	<TableExportFilters initialValues={initialValues} formatValues={formatValues}>
		<FilterFields />
	</TableExportFilters>
);
