import { useState, useEffect, useRef } from 'react';
import { useForm, useFormState } from 'react-final-form';
import moment from 'moment';

// Import utils and  helpers
import { useCars } from 'queries';
import { SERVICE_TYPES } from 'views/Bookings/CreateBooking/helpers';
import { resetCarInfo } from '../../../helpers';
import { useProfile } from 'components/utilities';
import { YMD_DASH_FORMAT, DEFAULT_TIME_FORMAT } from 'helpers';

export const useCarSelectionDialog = () => {
	const isInitialMount = useRef(true);
	const { change } = useForm();
	const {
		values: {
			service_type: serviceType,
			location,
			delivery,
			chosenCar,
			period: { pick_date: pickDate, pick_time: pickTime, drop_date: dropDate },
		},
	} = useFormState();

	const { isAdmin, companyUuid } = useProfile();

	const isPickup = serviceType === SERVICE_TYPES.PICKUP;

	const [payload, setPayload] = useState(location);

	const setChosenCar = (data) => {
		change('chosenCar', data);
	};

	const { data: cars, isFetching } = useCars({ payload });

	const noCars = cars.length === 0;

	const setFilter = ({ key, value }) =>
		setPayload((prev) => ({ ...prev, [key]: value }));

	const datesAvailable = pickDate && pickTime && dropDate;

	const periodPayload = {
		pickup_date: moment(pickDate).format(YMD_DASH_FORMAT),
		pickup_time: moment(pickTime).format(DEFAULT_TIME_FORMAT),
		drop_date: moment(dropDate).format(YMD_DASH_FORMAT),
	};

	const pickupPayload = {
		lat: location.lat,
		lng: location.lng,
		sorting_type: 'price',
		...(datesAvailable && periodPayload),
		delivery: 0,
		...(!isAdmin && { company_uuid: companyUuid }),
	};

	const deliveryAndReturnPayload = {
		lat: delivery.lat,
		lng: delivery.lng,
		sorting_type: 'price',
		...(datesAvailable && periodPayload),
		delivery: 1,
		delivery_drop: 1,
		...(!isAdmin && { company_uuid: companyUuid }),
	};

	const resetFilters = () => {
		if (isPickup) {
			setPayload(pickupPayload);
		} else {
			setPayload(deliveryAndReturnPayload);
		}
	};

	const isSaveDisabled = !chosenCar;

	const handleDialogCancel = () => {
		resetFilters();
	};

	const handleRemoveSelectedCar = () => {
		resetCarInfo(change);
		setChosenCar(null);
	};

	const handleDialogSave = () => {
		change('item.uuid', chosenCar.id);
		change('city', chosenCar.office.city);
		change('company', chosenCar.office.company.name);
		change('office', chosenCar.office.name);
	};

	useEffect(() => {
		if (isPickup) {
			setPayload(pickupPayload);
		} else {
			setPayload(deliveryAndReturnPayload);
		}
		// eslint-disable-next-line
	}, [
		location.lat,
		location.lng,
		delivery.lat,
		delivery.lng,
		serviceType,
		datesAvailable,
	]);

	useEffect(() => {
		if (isInitialMount.current === true) {
			isInitialMount.current = false;
		} else {
			resetCarInfo(change);
		}
		// eslint-disable-next-line
	}, [serviceType]);

	return {
		cars,
		isFetching,
		noCars,
		resetFilters,
		handleDialogCancel,
		chosenCar,
		setChosenCar,
		isSaveDisabled,
		handleDialogSave,
		handleRemoveSelectedCar,
		setFilter,
	};
};
