import { useState, useEffect } from 'react';

// import utils and helpers
import { useCompanies, useCompanyOffices } from 'queries';
import { useCarSelectionDialogProvider } from '../../../../context';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';
import { useTranslations, useProfile } from 'components/utilities';

export const useCarFilters = () => {
	const [companyUuid, setCompanyUuid] = useState(null);
	const { data: companies } = useCompanies();
	const { data: offices } = useCompanyOffices(companyUuid);
	const { resetFilters, setFilter } = useCarSelectionDialogProvider();
	const { t } = useTranslations();

	const { isAdmin, companyUuid: agentCompanyUuid } = useProfile();

	const FILTERS = [
		{
			label: t(`${transPrefix}.companies`),
			options: companies ? companies : [],
			onChange: (e) => {
				resetFilters();
				setFilter({ key: 'company_uuid', value: e.target.value });
				setCompanyUuid(e.target.value);
			},
			labelExtractor: ({ name }) => name.en,
			valueExtractor: ({ uuid }) => uuid,
			value: companyUuid,
			disabled: !isAdmin,
		},
		{
			label: t(`${transPrefix}.offices`),
			options: offices ? offices : [],
			disabled: !companyUuid,
			onChange: (e) => {
				setFilter({ key: 'office_id', value: e.target.value });
			},
			labelExtractor: ({ name }) => name.en,
			valueExtractor: ({ uuid }) => uuid,
		},
	];

	useEffect(() => {
		if (!isAdmin && companies.length) {
			setCompanyUuid(agentCompanyUuid);
		}
		// eslint-disable-next-line
	}, [companies]);

	return { FILTERS };
};
