import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useEarlyReturnCalculations = ({ uuid, enabled, returnDate }) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.EARLY_RETURN.CALCULATIONS(returnDate),
		async () =>
			await services.get(
				ENDPOINTS.EARLY_RETURN.CALCULATIONS({ uuid, returnDate })
			),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
