import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	icon: {
		marginTop: '8px',
		color: theme.palette.text[3],
	},
	text: {
		color: theme.palette.text[3],
	},
	error: {
		color: theme.palette.error.medium,
	},
}));
