import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	dialogContent: {
		display: 'flex',
		flexDirection: 'column',
		padding: '16px 29px',
		minHeight: '150px',
		justifyContent: 'space-between',
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '16px',
	},
	submitButton: {
		border: '1px solid',
		borderColor: theme.palette.divider,
		padding: '10px 26px',
		borderRadius: '12px',
	},
	resetButton: {
		padding: '10px 26px',
		borderRadius: '12px',
		backgroundColor: theme.palette.error.medium,
		color: theme.palette.white,
		'&:hover': {
			backgroundColor: theme.palette.error.dark,
		},
	},
	cancelButton: {
		padding: '10px 26px',
		borderRadius: '12px',
		color: theme.palette.gray.dark,
	},
	subtitle: {
		fontSize: '16px',
	},
	successDialogTitle: {
		color: theme.palette.primary.main,
	},
	icon: {
		color: theme.palette.primary.main,
		width: '40px',
		height: '40px',
	},
}));
