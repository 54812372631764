import services from 'services/services';
import {
	getCitiesUrl,
	getCompanyDropCitiesUrl,
	updateCompanyDropCitiesUrl,
} from 'store/paths';

/**
 * Get cities from TELGANI REST API
 * @param {object} params
 * @param {object} params.body
 * @param {number} [params.body.start] Start from 1 if undefined
 * @param {number} [params.body.length] Returns all if undefined
 * @param {string} [params.queryParams] query string
 * @param {any} [params.cancelToken] Axios CancelToken
 */

export const getCities = async (params) =>
	await services.post(getCitiesUrl(params), params.body, params.cancelToken);

/**
 * Get company drop cities from TELGANI REST API
 * @param {object} params
 * @param {string} params.companyId
 */

export const getCompanyDropCities = (params) =>
	services.get(getCompanyDropCitiesUrl(params));

/**
 * Update company drop cities
 * @param {object} params
 * @param {string} params.companyId
 * @param {object} params.body
 * @param {array} params.body.data
 */

export const updateCompanyDropCities = (params) =>
	services.post(updateCompanyDropCitiesUrl(params), params.body);
