import React from 'react';
import { any, bool, func, node, object } from 'prop-types';
import { TableRow as MuiTableRow } from '@material-ui/core';

// Import styles
import { useSharedStyles } from 'theme/styles';

export const TableRow = ({
	onMouseLeave,
	onMouseEnter,
	isHovered,
	children,
	row,
	ref,
}) => {
	const classes = useSharedStyles();

	return (
		<MuiTableRow
			className={isHovered ? classes.hoverRow : ''}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			key={row.id}
			ref={ref}
			{...row.getRowProps()}
		>
			{children}
		</MuiTableRow>
	);
};

TableRow.propTypes = {
	children: node.isRequired,
	row: object.isRequired,
	onMouseLeave: func,
	onMouseEnter: func,
	isHovered: bool,
	ref: any,
};
