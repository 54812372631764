import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeInput, MultiCompaniesFilter } from 'components/elements';

export const AdditionalFilterFields = () => (
	<>
		<Grid item xs="auto">
			<DateRangeInput
				nameFrom="pick_date"
				nameUntil="pick_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
				isToggleButton
			/>
		</Grid>
		<Grid item xs="auto">
			<MultiCompaniesFilter
				name="company_uuid"
				smallFieldStyle
				showEmptyLabel
				xs="auto"
				md="auto"
			/>
		</Grid>
	</>
);
