import React from 'react';

// Import helpers
import { BOOKINGS_PAGES_TYPES } from 'helpers';
// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { AdditionalInfo, Export } from '../Bookings/components';
import { TableRPC } from 'components/utilities';
import { Filters } from '../Bookings/components';
import { InnerNavbarElements } from 'components/elements';
import { TableBodyRow } from './components';

// Import actions
import { fetchExtendedBookings } from 'store/actions';

export const ExtendedBookings = () => (
	<TableRPC
		columnsAccessor={COLUMNS_ACCESSORS}
		columns={columns}
		tableBodyRow={TableBodyRow}
		fetchDataTableAction={fetchExtendedBookings}
		title="nav.extended"
		outerToolbarElements={
			<Export
				pageType={BOOKINGS_PAGES_TYPES.EXTENDED}
				fetchDataTableAction={fetchExtendedBookings}
			/>
		}
		filtersBar={<Filters />}
		tableNavigation={<InnerNavbarElements />}
		additionalNavigationElements={
			<AdditionalInfo
				showAssignedDriverAdditional
				showDeliveryStatusAdditional
			/>
		}
	/>
);
