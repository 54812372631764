import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

// Import utilities
import { previewBookingSelector } from 'store/selectors';
import { useTranslations } from 'components/utilities';

// Import components
import { PreviewField } from 'components/elements';

export const TotalExtensionAmountPreviewField = ({ label }) => {
	const { t } = useTranslations();

	const {
		extension: { extension_amount_gross: amount },
	} = useSelector(previewBookingSelector);

	return (
		<PreviewField label={label}>{t('currency.price', { amount })}</PreviewField>
	);
};

TotalExtensionAmountPreviewField.propTypes = {
	label: string,
};
