import React from 'react';
import { string } from 'prop-types';
import { Redirect } from 'react-router-dom';

// Import components
import {
	CustomerExperienceSharedFields,
	CustomerRegisteredFields,
	PartnershipFields,
	CashbackFields,
} from './components';

// Import helpers
import { CAMPAIGN_TYPES } from 'helpers';
import { URLS } from 'components/routes';

export const CampaignFormFields = ({ campaignType }) => {
	switch (campaignType) {
		case CAMPAIGN_TYPES.CUSTOMER_EXPERIENCE_SHARED:
			return <CustomerExperienceSharedFields />;

		case CAMPAIGN_TYPES.CUSTOMER_REGISTERED:
			return <CustomerRegisteredFields />;

		case CAMPAIGN_TYPES.PARTNERSHIP:
			return <PartnershipFields />;

		case CAMPAIGN_TYPES.BOOKING_CASHBACK:
			return <CashbackFields />;

		default:
			return <Redirect to={URLS.campaignsUrl} />;
	}
};

CampaignFormFields.propTypes = {
	campaignType: string,
};
