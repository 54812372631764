import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES, generateArray } from 'helpers';

const getPrices = () => {
	const numbers = generateArray(100, 111, 50).map((number) => ({
		value: number,
		label: number,
	}));

	return [
		{
			value: 0,
			label: i18next.t('common.free'),
		},
		...numbers,
	];
};

const filterOutData = ({ outData, selectedCityIn }) =>
	Object.entries(outData).reduce((acc, [key, object]) => {
		if (key === selectedCityIn) {
			return acc;
		} else {
			const newObject = {
				[key]: object,
			};
			return { ...acc, ...newObject };
		}
	}, {});

export const handleOnClear = ({ values, change }) => {
	const { out_data: outData, selected_city_in: selectedCityIn } = values;
	const filteredOutData = filterOutData({ selectedCityIn, outData });

	change(`out_data`, filteredOutData);
};

export const getFields = ({ values, cities }) => {
	const costOptions = getPrices();

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name;

	return [
		{
			type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
			currentMultiSelectValues:
				values?.out_data?.[values?.selected_city_in]?.drop_cities,
			label: 'common.fields.drop_off_city',
			options: cities,
			labelExtractor: ({ name }) => name,
			keyExtractor: ({ uuid }) => uuid,
			searchExtractor: 'children[1]',
			multiSelectLabelExtractor,
			showAllOption: true,
			name: `out_data.${values?.selected_city_in}.drop_cities`,
			required: true,
			disabled: !values.selected_city_in,
			md: 8,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.cost',
			labelExtractor: ({ label }) => label,
			keyExtractor: ({ value }) => value,
			options: costOptions,
			disabled: !values.selected_city_in,
			name: `out_data.${values?.selected_city_in}.price`,
			md: 8,
		},
	];
};
