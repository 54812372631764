import React from 'react';
import { Button, IconButton, Tooltip } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { useForm, useFormState } from 'react-final-form';
import { isEqual } from 'lodash';
import { object } from 'prop-types';

// Import components
import { Grid } from 'components/elements';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useTableRPCWithQueryProvider } from 'components/utilities/rpc/TableRPCWithQuery/context';

// Import styles
import { useStyles } from './FiltersButtons.styles';

export const FiltersButtons = ({ filtersInitialValues = {} }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const { loading, handleFiltersReset } = useTableRPCWithQueryProvider();

	const { values } = useFormState();

	const { reset } = useForm();

	const valuesChanged = !isEqual(filtersInitialValues, values);

	return (
		valuesChanged && (
			<>
				<Grid item xs="auto">
					<Button
						variant="contained"
						type="submit"
						disabled={loading}
						color="primary"
					>
						{t('common.buttons.submit')}
					</Button>
				</Grid>
				<Grid item xs="auto">
					<Tooltip title={t('common.buttons.clear')}>
						<span>
							<IconButton
								disabled={loading}
								className={classes.clearIconButton}
								onClick={() => handleFiltersReset(reset)}
							>
								<ClearIcon className={classes.icon} />
							</IconButton>
						</span>
					</Tooltip>
				</Grid>
			</>
		)
	);
};

FiltersButtons.propTypes = {
	filtersInitialValues: object,
};
