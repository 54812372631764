import React from 'react';
import { Box, Divider, Popover, Grid } from '@material-ui/core';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import { array, bool, func, object, string } from 'prop-types';

// Import components
import { IconButton } from 'components/elements';

// Import utils
import { usePreviewPopupFields } from './usePreviewPopupFields';

// Import styles
import { popoverStyle, useStyles } from './PreviewPopup.styles';

// Import assets
import { DotsMenuIcon } from 'assets/icons';

export const PreviewPopup = ({ previewPopupFields, ...props }) => {
	const { renderField } = usePreviewPopupFields(props);
	const classes = useStyles();

	return (
		<PopupState variant="popover" popupId="demo-popup-menu">
			{(popupState) => (
				<Box className={classes.container}>
					<IconButton
						className={classes.dotsButton}
						{...bindTrigger(popupState)}
					>
						<DotsMenuIcon className={classes.mainIcon} />
					</IconButton>
					<Popover
						PaperProps={{
							style: popoverStyle,
							elevation: 1,
						}}
						anchorOrigin={{
							vertical: 'bottom',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: -10,
							horizontal: 'right',
						}}
						{...bindPopover(popupState)}
					>
						<Box className={classes.popoverContent}>
							{previewPopupFields.map((field, index) => (
								<Grid key={index} container spacing={1}>
									{renderField(field, popupState)}
									{field.isDivider && (
										<Grid item xs={12}>
											<Divider />
										</Grid>
									)}
								</Grid>
							))}
						</Box>
					</Popover>
				</Box>
			)}
		</PopupState>
	);
};

PreviewPopup.propTypes = {
	toggleSubscriptionsHistory: func,
	toggleTransactionsHistory: func,
	toggleBookingHistory: func,
	hideBookingStatusActions: bool,
	previewPopupFields: array,
	rowUuid: string,
	cell: object,
};
