import React from 'react';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { bool, func } from 'prop-types';
import { useForm } from 'react-final-form';

// import components
import { Dialog, Typography, Button } from 'components/elements';

// import utilities
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './SubmitConfirmationDialog.styles';

export const SubmitConfirmationDialog = ({ open, onClose }) => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { submit } = useForm();

	return (
		<Dialog open={open} onClose={onClose} className={classes.dialog}>
			<DialogTitle>
				<Typography className={classes.title}>
					{t('bookings.create_booking.buttons.submit_booking')}
				</Typography>
			</DialogTitle>

			<DialogContent>
				<Typography className={classes.content}>
					{t('bookings.create_booking.submit_confirmation_text')}
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button
					text={t('common.buttons.cancel')}
					type="dark_text"
					onClick={onClose}
					size="large"
				/>
				<Button
					text={t('bookings.create_booking.buttons.submit_booking')}
					buttonType="submit"
					onClick={submit}
					size="large"
				/>
			</DialogActions>
		</Dialog>
	);
};

SubmitConfirmationDialog.propTypes = {
	open: bool.isRequired,
	onClose: func.isRequired,
};
