import React from 'react';
import { bool, func, node, oneOf } from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';

export const Dialog = ({
	children,
	open = false,
	onClose = () => {},
	fullWidth = false,
	maxWidth,
	...props
}) => {
	return (
		<MuiDialog
			onClose={onClose}
			open={open}
			fullWidth={fullWidth}
			maxWidth={maxWidth}
			{...props}
		>
			{children}
		</MuiDialog>
	);
};

Dialog.propTypes = {
	maxWidth: oneOf(['lg', 'xl', 'md', 'sm', 'xs', false]),
	onClose: func,
	children: node,
	open: bool,
	fullWidth: bool,
};
