import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	subtitle: {
		fontSize: '16px',
		fontWeight: 600,
		color: theme.palette.text.inputLabel,
		marginBottom: '32px',
	},
	uploadFileBtn: {
		position: 'relative',
		backgroundColor: theme.palette.gray.input,
		color: theme.palette.text[2],
		cursor: 'pointer',
		padding: '14px',
		borderRadius: '6px',
		marginTop: '8px',
		height: '49px',
		display: 'flex',
		justifyContent: 'space-between',
		'&:hover': {
			backgroundColor: theme.palette.gray.input,
			boxShadow: 'none',
		},
	},
}));
