import React from 'react';

// Import store
import { fetchCancellationBookingsByCustomer } from 'store/actions';

// Import helpers and utils
import { columns, COLUMNS_ACCESSORS } from './columns';
import { BOOKING_CANCELLATION_STATES } from 'helpers';
import { BUTTONS } from './helpers';
import { TableRPC } from 'components/utilities';

// Import components
import { InnerNavbarElementsButtons } from 'components/elements';
import { Filters, TableBodyRow } from './components';

export const CancellationByCustomer = () => {
	const { REVIEW } = BOOKING_CANCELLATION_STATES;
	const defaultQueryParams = `?columns[8][search][value]=${REVIEW}`;

	return (
		<TableRPC
			fetchDataTableAction={fetchCancellationBookingsByCustomer}
			title="nav.operations.cancellation_by_customer"
			columnsAccessor={COLUMNS_ACCESSORS}
			tableBodyRow={TableBodyRow}
			filtersBar={<Filters />}
			defaultQueryParams={defaultQueryParams}
			columns={columns}
			tableNavigation={
				<InnerNavbarElementsButtons
					defaultFilterState={BOOKING_CANCELLATION_STATES.REVIEW}
					filterPath="cancel_reason_status"
					buttons={BUTTONS}
				/>
			}
		/>
	);
};
