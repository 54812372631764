import React from 'react';
import { func, object } from 'prop-types';
import { GoogleMap, Marker, Circle } from '@react-google-maps/api';

// Import theme
import theme from 'theme';

// Import utils
import { useMap } from './useMap';

export const Map = ({ location, onChange }) => {
	const {
		mapCenter,
		markerPosition,
		handleMarkerLoad,
		handleMarkerDragEnd,
		draggableArea,
	} = useMap({ location, onChange });

	return (
		<GoogleMap
			zoom={10}
			mapContainerStyle={{
				width: '100%',
			}}
			center={mapCenter}
		>
			<Marker
				draggable
				position={markerPosition}
				onLoad={handleMarkerLoad}
				onDragEnd={handleMarkerDragEnd}
			/>
			<Circle
				center={draggableArea.center}
				radius={draggableArea.radius}
				options={{
					strokeColor: theme?.palette?.primary?.map,
					strokeOpacity: 0.8,
					strokeWeight: 1,
					fillColor: theme?.palette?.primary?.map,
					fillOpacity: 0.35,
				}}
			/>
		</GoogleMap>
	);
};

Map.propTypes = {
	location: object.isRequired,
	onChange: func.isRequired,
};
