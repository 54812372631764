export const COLUMNS_ACCESSORS = {
	DELIVERY_ASSIGNMENT: 'delivery_assignment',
	DELIVERY_STATUS: 'delivery_status',
	PICK_DATE: 'pick_date',
	DROP_DATE_ACCESSOR: 'drop_date',
	COMPANY: 'company',
	CUSTOMER: 'customer',
	ACTIONS: 'actions',
	CAR: 'car',
	ID: 'ID',
	TOPIC: 'topic',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'common.fields.id',
		accessor: COLUMNS_ACCESSORS.ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.topic',
		accessor: COLUMNS_ACCESSORS.TOPIC,
		disableSortBy: true,
	},

	{
		Header: 'common.fields.customer',
		accessor: COLUMNS_ACCESSORS.CUSTOMER,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.car',
		accessor: COLUMNS_ACCESSORS.CAR,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.drop_date',
		accessor: COLUMNS_ACCESSORS.DROP_DATE_ACCESSOR,
		disableSortBy: true,
	},

	{
		Header: 'common.delivery_terms.delv_assignment',
		accessor: COLUMNS_ACCESSORS.DELIVERY_ASSIGNMENT,
		disableSortBy: true,
	},
	{
		Header: 'common.delivery_terms.delv_status',
		accessor: COLUMNS_ACCESSORS.DELIVERY_STATUS,
		disableSortBy: true,
	},
	{
		Header: 'bookings.table.headers.booking_status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
