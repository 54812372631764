// Import helpers
import { CUSTOMER_ORGANIZATION_TYPES } from 'helpers';

export const getCustomerOrganizationLabel = (customerOrganization) => {
	switch (customerOrganization?.toLowerCase()) {
		case CUSTOMER_ORGANIZATION_TYPES.TELGANI:
			return 'common.fields.internal';

		default:
			return null;
	}
};
