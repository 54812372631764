import { array } from 'prop-types';

// Import utils and helpers
import { useCommonFields } from 'components/utilities';
import { TYPES_FIELDS } from './helpers';

export const GratificationTypeField = ({ options = [] }) => {
	const { renderFields } = useCommonFields();

	return TYPES_FIELDS({ options }).map(renderFields);
};

GratificationTypeField.propTypes = {
	options: array.isRequired,
};
