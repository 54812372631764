import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useSliderQuery = ({ sliderUuid }) => {
	const path = `/v2/slider/${sliderUuid}/show`;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.SLIDERS.SHOW(sliderUuid),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled: !!sliderUuid }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
