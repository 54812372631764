import React from 'react';
import { array } from 'prop-types';
import {
	Card,
	CardHeader,
	Divider,
	CardContent,
	Grid,
} from '@material-ui/core';

// Import translations
import { useCommonFields, useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './Section.styles';

export const Section = ({ fields }) => {
	const { t } = useTranslations();

	const { renderFields } = useCommonFields();

	const classes = useStyles();

	return (
		<Card>
			<CardHeader component="h5" title={t('promo_codes.form.title')} />
			<Divider />
			<CardContent>
				<Grid container className={classes.container}>
					<Grid item md={6} container spacing={3}>
						{fields.map(renderFields)}
					</Grid>
				</Grid>
			</CardContent>
		</Card>
	);
};

Section.propTypes = {
	fields: array.isRequired,
};
