import { makeStyles } from '@material-ui/styles';
import palette from 'theme/palette';

export const popoverStyle = {
	border: '1px solid',
	backgroundColor: palette.text[4],
	borderRadius: '8px',
	minWidth: '320px',
	minHeight: '82px',
	padding: 0,
	overflow: 'visible',
};

export const useStyles = makeStyles((theme) => ({
	popoverContent: {
		position: 'relative',
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		height: '100%',
		minHeight: '82px',
		gap: '8px',
		padding: '16px',

		'&:before': {
			content: '""',
			position: 'absolute',
			bottom: '-11px',
			left: '39px',
			borderWidth: '10px 10px 0',
			borderStyle: 'solid',
			borderColor: `${theme.palette.text[4]} transparent transparent`,
		},
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		gap: '8px',
		width: '100%',
	},
	column: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
	mainIcon: {
		width: '16px',
		height: '16px',
		color: theme.palette.primary.main,
	},
	squareIcon: {
		width: '20px',
		height: '20px',
		color: theme.palette.background[3],
	},
	starIcon: {
		width: '12x',
		height: '12px',
		color: theme.palette.background[3],
	},
	text: {
		fontSize: '14px',
		fontWeight: '500',
		color: theme.palette.white,
	},
}));
