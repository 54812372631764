import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TrashIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15.5379 21.0038H8.45209C7.2759 21.0038 6.298 20.0982 6.20779 18.9255L5.24219 6.37268H18.7478L17.7822 18.9255C17.692 20.0982 16.7141 21.0038 15.5379 21.0038V21.0038Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9989 6.37264H3.99219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M9.18016 2.99622H14.8075C15.4291 2.99622 15.933 3.50011 15.933 4.12168V6.37262H8.05469V4.12168C8.05469 3.50011 8.55858 2.99622 9.18016 2.99622Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.969 10.8745V16.5019"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.0315 10.8745V16.5019"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
