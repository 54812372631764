import React from 'react';
import { object } from 'prop-types';
import {
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from '@material-ui/core';

// import components
import { Typography } from 'components/elements';
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import styles
import { useStyles } from './ClosePaymentDetails.styles';

// import assets
import { TriangleRoundedDownArrowIcon } from 'assets/icons';

// import utils and helpers
import { useClosePaymentDetails } from './useClosePaymentDetails';
import { useTranslations } from 'components/utilities';

export const ClosePaymentDetails = ({ booking }) => {
	const classes = useStyles();

	const { fields, data } = useClosePaymentDetails({ booking });

	const { t } = useTranslations();

	return (
		<Accordion className={classes.accordion}>
			<AccordionSummary expandIcon={<TriangleRoundedDownArrowIcon />}>
				<Typography className={classes.title}>
					{t('bookings.preview_booking.invoice.close_payment_details')}
				</Typography>
			</AccordionSummary>
			<AccordionDetails className={classes.detailsContainer}>
				<PreviewFields fields={fields} customData={data} />
			</AccordionDetails>
		</Accordion>
	);
};

ClosePaymentDetails.propTypes = {
	booking: object.isRequired,
};
