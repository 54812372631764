import React from 'react';

// Import components
import {
	CustomerDetails,
	BookingInfoTab,
	Ratings,
	Urgent,
	Compensation,
	CarAndCompany,
	Receipts,
} from './components';

export const getTab = (currentTab) => {
	switch (currentTab) {
		case 0:
			return <BookingInfoTab />;
		case 1:
			return <Urgent />;
		case 2:
			return <CustomerDetails />;
		case 3:
			return <CarAndCompany />;
		case 4:
			return <Receipts />;
		case 5:
			return <Ratings />;
		default:
			return <Compensation />;
	}
};
