// Import helpers
import { BOOKING_STATES } from 'helpers';

const { EARLY_RETURN } = BOOKING_STATES;

export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.fields.late_payment',
		status: 'late_payment',
	},
	{
		label: 'bookings.booking_states.early_return',
		status: EARLY_RETURN,
	},
];
