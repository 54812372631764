import React from 'react';
import { bool, object } from 'prop-types';

// Import components
import { TotalDepositCounterTableCell } from '../../components';
import {
	NewActionsMenuTableCell,
	CommonStatusTableCell,
	TableCell,
} from 'components/elements';

// Import helpers and utils
import { useTranslations } from 'components/utilities';
import { statusList } from './helpers';
import { useCell } from './useCell';

export const Cell = ({ cell, columnsAccessor, isHovered }) => {
	const { t } = useTranslations();

	const { STATUS, CAMPAIGN_TYPE, TOTAL_DEPOSIT_COUNTER, CREATED, ACTIONS } =
		columnsAccessor;

	const { actionMenuFields } = useCell({ cell });

	const renderCell = (cell) => {
		const columnId = cell.column.id;
		const {
			campaign_type: campaignType,
			created_at: createdAt,
			blameable,
			active,
		} = cell.row.original;

		switch (columnId) {
			case TOTAL_DEPOSIT_COUNTER:
				return <TotalDepositCounterTableCell key={columnId} cell={cell} />;

			case CAMPAIGN_TYPE:
				return (
					<TableCell key={columnId} cell={cell}>
						{t(`campaigns.types.${campaignType}`)}
					</TableCell>
				);

			case CREATED:
				return (
					<TableCell key={columnId} cell={cell}>
						{createdAt}
						<br />
						{blameable}
					</TableCell>
				);

			case STATUS:
				return (
					<CommonStatusTableCell
						currentStatus={active ? 'active' : 'inactive'}
						statusList={statusList}
						key={columnId}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						isHovered={isHovered}
						key={columnId}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={columnId} cell={cell}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
