import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		paddingLeft: theme.spacing(3),
		paddingRight: theme.spacing(3),
	},
	button: {
		textAlign: 'left',
		fontSize: '14px',
		color: theme.palette.text[2],
		padding: '0px',
		marginBottom: theme.spacing(3),

		'&:hover': {
			backgroundColor: 'transparent',
		},
	},
	icon: {
		marginRight: theme.spacing(1),
	},
}));
