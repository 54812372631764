import React from 'react';
import { Field, useFormState } from 'react-final-form';
import { bool } from 'prop-types';
import { Grid } from '@material-ui/core';

// Import translations
import { useTranslations } from 'components/utilities';

// Import components
import { ButtonProgress, TextFieldAdapter } from 'components/elements';

// Import assets
import { SendMessageIcon } from 'assets/icons';

// Import styles
import { useStyles } from './Comment.styles';

export const Comment = ({ isLoading }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const { values } = useFormState();

	const isButtonDisabled = !values.content || isLoading;

	return (
		<Grid container className={classes.container}>
			<Grid item>
				<Field
					placeholder={t('bookings.preview_booking.comments.add_comment')}
					component={TextFieldAdapter}
					name="content"
					type="text"
					variant="outlined"
				/>
			</Grid>
			<Grid item>
				<ButtonProgress
					color="primary"
					className={classes.button}
					isLoading={isLoading}
					disabled={isButtonDisabled}
					type="submit"
					aria-label="send-comment-icon"
				>
					<SendMessageIcon />
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};

Comment.propTypes = {
	isLoading: bool.isRequired,
};
