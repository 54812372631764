import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		'& .MuiGrid-root': {
			padding: 0,
		},
	},
	icon: {
		width: '20px',
		height: '20px',
		color: theme.palette.primary.main,
	},
	linkAsButton: {
		backgroundColor: theme.palette.primary.main,
		color: theme.palette.white,
		padding: '10px 12px',
		borderRadius: '8px',
		minWidth: '48px',
		minHeight: '28px',

		'&:hover': {
			textDecoration: 'none',
		},
	},
}));
