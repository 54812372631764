import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	card: {
		padding: 0,
		border: 0,
		borderRadius: 0,
		width: '100%',

		'& .MuiTab-wrapper': {
			color: theme.palette.text[2],
		},

		'& .Mui-selected': {
			backgroundColor: theme.palette.background[6],
		},

		'& .MuiTabs-flexContainer': {
			justifyContent: 'flex-start',
		},
	},
}));
