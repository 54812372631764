import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const UserPerformanceIcon = (props) => (
	<SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
		<path
			d="M20 10V5C20 3.89543 19.1046 3 18 3H6C4.89543 3 4 3.89543 4 5V19C4 20.1046 4.89543 21 6 21H11"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M12 14H10C8.89543 14 8 14.8954 8 16"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<circle
			cx="12"
			cy="9.25"
			r="2.25"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M18 21C15.7909 21 14 19.2091 14 17C14 14.7909 15.7909 13 18 13C20.2091 13 22 14.7909 22 17C22 19.2091 20.2091 21 18 21"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M20.8284 19.828L18 16.9996V13.0059"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
