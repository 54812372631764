import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useBookingInvoice = ({ bookingUuid, isTransferred = false }) => {
	const path = ENDPOINTS.BOOKINGS.BOOKING_INVOICE({ bookingUuid });

	const url = isTransferred ? `${path}?is_transferred_invoice=true` : path;
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.BOOKINGS.INVOICE(url),
		async () => await services.get(url),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : {};

	return { data: fetchedData, isFetching, isError };
};
