import { useQuery } from 'react-query';
import services from 'services/services';
import _ from 'lodash';

// Import helpers
import { spreadArray } from './helpers';
import { QUERY_KEYS } from 'helpers';

export const useCompanyPublicHolidaysList = ({ companyUuids }) => {
	const enabled = _.isArray(companyUuids) && companyUuids.length;
	const companyUuidsArray = spreadArray(companyUuids);

	const path = `/dashboard/company/public-holidays/list?${companyUuidsArray}`;

	const { data, isFetched } = useQuery(
		QUERY_KEYS.PUBLIC_HOLIDAYS.LIST(companyUuids),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data?.data || {};

	return { data: fetchedData, isFetched };
};
