export const RATING_FIELDS = [
	{
		accessor: 'rating',
		label: 'common.fields.rating',
	},
	{
		accessor: 'reason',
		label: 'common.fields.rating_reason',
	},
	{
		accessor: 'author',
		label: 'common.fields.rated_by',
	},
	{
		accessor: 'created_at',
		label: 'common.fields.date',
	},
];
