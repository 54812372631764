import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	label: {
		fontFamily: 'Inter',
		fontSize: '14px',
		fontWeight: 600,
	},
	container: {
		flexWrap: 'nowrap',
	},
	labelSubText: {
		color: theme.palette.text[1],
		fontSize: '12px',
		fontWeight: 400,
	},
}));
