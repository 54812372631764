export const initialValues = {
	company_uuid: ['all'],
	manufacturers: ['all'],
	years: ['all'],
	models: ['all'],
};

export const formatValues = (values) => {
	return {
		...values,
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
		manufacturers: values.manufacturers === 'all' ? null : values.manufacturers,
		models: values.models === 'all' ? null : values.models,
		years: values.years === 'all' ? null : values.years,
	};
};
