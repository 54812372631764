// Import components
import { Statistics } from './components';

// Import helpers
import { BOOKING_STATES, TOOLBAR_VARIANTS } from 'helpers';

const { PENDING, ACCEPTED, REJECTED, EARLY_RETURN } = BOOKING_STATES;

export const TOOLBAR_CONTENT = [
	{
		variant: TOOLBAR_VARIANTS.CUSTOM,
		component: Statistics,
		customKey: 'stats',
	},
];

export const buttons = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.common_states.pending',
		status: PENDING,
	},
	{
		label: 'common.common_states.accepted',
		status: ACCEPTED,
	},
	{
		label: 'common.common_states.rejected',
		status: REJECTED,
	},
	{
		label: 'common.common_states.early_return',
		status: EARLY_RETURN,
	},
];
