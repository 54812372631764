import React from 'react';
import { string } from 'prop-types';
import { Box, Tooltip } from '@material-ui/core';

// Import assets
// import BookingCountChip from '../BookingCountChip';
import { InformationIcon } from 'assets/icons';

// Import queries
import { useBookingCounters } from 'queries';

// Import styles
import { useStyles } from './SideMenuCountChip.styles';

export const SideMenuCountChip = ({ reference }) => {
	const classes = useStyles();

	const { data: count } = useBookingCounters(reference);
	if (typeof count !== 'number') {
		return null;
	}

	return (
		<Tooltip title={count} placement="top">
			<Box className={classes.iconWrapper}>
				<InformationIcon className={classes.icon} />
			</Box>
		</Tooltip>
	);
};

SideMenuCountChip.propTypes = {
	reference: string,
};
