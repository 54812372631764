import React from 'react';
import { string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';

// Import components
import { CheckboxFieldAdapter } from 'components/elements';

export const OptionField = ({ type, label }) => {
	const { submitting } = useFormState();

	return (
		<Field
			component={CheckboxFieldAdapter}
			type="checkbox"
			labelText={label}
			name={`options.${type}`}
			disabled={submitting}
			color="primary"
		/>
	);
};

OptionField.propTypes = {
	type: string,
	label: string,
};
