import React from 'react';
import { string } from 'prop-types';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Field } from 'react-final-form';
import { Box, IconButton } from '@material-ui/core';

// Import assets
import { CircularLocationPin, LocateIcon } from 'assets/icons';

// Import helpers
import { useCollapsableMap } from './useCollapsableMap';

// Import components
import {
	LocationAutocompleteFieldAdapter,
	GoogleMapsScriptLoader,
	Typography,
	Grid,
} from 'components/elements';

// Import styles
import { useStyles } from './CollapsibleMap.styles';

export const CollapsibleMap = ({ section, label }) => {
	const classes = useStyles();

	const {
		t,
		fieldDisabled,
		markerLocation,
		setAutocompleteValue,
		onDragEnd,
		mapVisible,
		toggle,
		hideText,
		handleSetCurrentLocation,
		onMapChange,
	} = useCollapsableMap({ section });

	return (
		<Box className={classes.container}>
			<Grid container spacing={3} alignItems="center">
				<Grid item md={12} xs={12}>
					<Field
						fullWidth
						component={LocationAutocompleteFieldAdapter}
						type="text"
						name={`${section}.address`}
						label={t(label)}
						margin="dense"
						variant="outlined"
						setAutocompleteValue={setAutocompleteValue}
						onChange={onMapChange}
						placeholder={t('kiosk.airports.form.location.address')}
						disabled={fieldDisabled()}
						required
					/>
				</Grid>
				<Grid container className={classes.hideContainer} onClick={toggle}>
					<CircularLocationPin className={classes.icon} />
					<Typography className={classes.hideText}>{t(hideText)}</Typography>
				</Grid>
				{mapVisible && (
					<Grid item md={12} xs={12}>
						<GoogleMapsScriptLoader>
							<GoogleMap
								mapContainerClassName={classes.mapContainer}
								center={markerLocation}
								zoom={15}
							>
								<Marker
									position={markerLocation}
									draggable
									onDragEnd={onDragEnd}
								/>
								<IconButton
									onClick={handleSetCurrentLocation}
									className={classes.locateBtn}
								>
									<LocateIcon />
								</IconButton>
							</GoogleMap>
						</GoogleMapsScriptLoader>
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

CollapsibleMap.propTypes = {
	section: string.isRequired,
	label: string.isRequired,
};
