import React from 'react';
import { number, string } from 'prop-types';
import { CircularProgress, Grid } from '@material-ui/core';

// Import styles
import { useStyles } from './LoadingWrapper.styles';
import clsx from 'clsx';

export const LoadingWrapper = ({
	justifyContent = 'center',
	alignItems = 'center',
	size = 32,
	className,
}) => {
	const classes = useStyles();

	return (
		<Grid
			container
			className={clsx(classes.container, className)}
			justifyContent={justifyContent}
			alignItems={alignItems}
		>
			<Grid item>
				<CircularProgress size={size} color="primary" />
			</Grid>
		</Grid>
	);
};

LoadingWrapper.propTypes = {
	justifyContent: string,
	alignItems: string,
	className: string,
	size: number,
};
