import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
		padding: '0px 12px',
	},
	dialogsWrapper: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'flex-start',
		gap: '16px',
		width: '100%',
	},
	icon: {
		color: theme.palette.primary.main,
	},
	button: {
		whiteSpace: 'nowrap',
	},
}));
