// Import helpers
import { URLS } from 'components/routes';

export const links = (isAdmin) => {
	const links = [
		{
			label: 'common.buttons.all_bookings',
			url: URLS.bookingsUrl,
		},
		{
			label: 'nav.extended',
			url: URLS.extendedBookingsUrl,
		},
	];

	if (isAdmin)
		links.push({
			label: 'nav.transferred',
			url: URLS.transferredBookingsUrl,
		});

	return links;
};
