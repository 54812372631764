import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

// Import utils
import { useTranslations } from 'components/utilities';

// Import store
import {
	editBookingFormSelector,
	previewBookingSelector,
} from 'store/selectors';

// Import context
import { useEditBookingFormProvider } from '../../../../context';

export const useOldAndNewCar = () => {
	const { selectedCar, initialHistoryValues } = useEditBookingFormProvider();

	const booking = useSelector(previewBookingSelector);

	const { item: newFormItem } = useSelector(editBookingFormSelector);

	const { item: newItem } = newFormItem || {};

	const {
		name: newCarName,
		base_gross_price: newBaseGrossPrice,
		total_discounted_gross_price: currentTotal,
	} = newItem || {};

	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const { item, company, office } = booking || {};

	const { name: companyName } = company || {};

	const { name: officeName } = office || {};

	const { company: newCompany, office: newOffice } = selectedCar || {};

	const { name: newCompanyName } = newCompany || {};

	const { name: newOfficeName } = newOffice || {};

	const {
		name: carName,
		base_gross_price: baseGrossPrice,
		base_currency: baseCurrency,
		total_discount_gross_price: initialTotal,
	} = item || {};

	const { item: historyItem } = initialHistoryValues || {};

	const { total_discounted_gross_price: initialHistoryTotal } =
		historyItem || {};

	const oldCarName = `${carName}, ${baseGrossPrice} ${baseCurrency} / ${t(
		'common.fields.daily'
	)}`;
	const oldCompanyAndOffice = `${companyName} , ${officeName}`;

	const newFullCarName = `${newCarName}, ${newBaseGrossPrice} ${baseCurrency} / ${t(
		'common.fields.daily'
	)}`;
	const newCompanyAndOffice = `${newCompanyName?.[lang] ?? newCompanyName} , ${
		newOfficeName?.[lang] ?? newOfficeName
	}`;

	const newDifferencePrice =
		Number(currentTotal) - Number(initialHistoryTotal || initialTotal);

	const differencePrice = `${newDifferencePrice} ${baseCurrency}`;

	const isPriceDecreased = newDifferencePrice < 0;

	const isCarChanged = !isEmpty(selectedCar) || selectedCar;

	return {
		oldCarName,
		oldCompanyAndOffice,
		newFullCarName,
		newCompanyAndOffice,
		differencePrice,
		isPriceDecreased,
		isCarChanged,
	};
};
