import { useState } from 'react';
import { useSelector } from 'react-redux';

// Import utils
import { useAlternativeCars as useAlternativeCarsQuery } from 'queries';
import { useEditBookingFormProvider } from '../../context';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useAlternativeCars = () => {
	const { uuid } = useSelector(previewBookingSelector);

	const [activePage, setActivePage] = useState(1);

	const { data: cars, isLoading } = useAlternativeCarsQuery(uuid);

	const { changeMainEditForm, setSelectedCar, selectedCar, currentCarUuid } =
		useEditBookingFormProvider();

	const handleSelectCar = (car) => {
		const isCarSelectedBefore = car.uuid === selectedCar?.uuid;

		if (isCarSelectedBefore) {
			setSelectedCar(null);
			changeMainEditForm('item.uuid', currentCarUuid);
		} else {
			const {
				vehicle_model: model,
				vehicle_maker: maker,
				vehicle_year: year,
				company_name: companyName,
				office_name: officeName,
			} = car || {};
			setSelectedCar({
				...car,
				model,
				maker,
				year,
				company: { name: companyName },
				office: { name: officeName },
			});
			changeMainEditForm('item.uuid', car?.uuid);
		}
	};

	const pagesCount = Math.ceil(cars.length / 4);

	const handleOnSlide = (isNext) =>
		setActivePage(isNext ? activePage + 1 : activePage - 1);

	return {
		selectedCar,
		handleSelectCar,
		uuid,
		pagesCount,
		cars,
		isLoading,
		handleOnSlide,
		activePage,
	};
};
