import React, { useRef, useState } from 'react';
import { Box, Button, Tooltip, Typography } from '@material-ui/core';
import { func, object } from 'prop-types';
import clsx from 'clsx';
import CloseIcon from '@material-ui/icons/Close';

// Import assets
import {
	CheckMarkFilledIcon,
	ExclamationMarkWithCircleIcon,
} from 'assets/icons';

// Import utils
import { useCarCard } from './useCarCard';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CarCard.styles';

export const CarCard = ({ car, selectedCar = null, onClick }) => {
	const classes = useStyles();

	const { t } = useTranslations();

	const [isHovered, setIsHovered] = useState(null);

	const { uuid, companyName, carName, price, officeName } = useCarCard(car);

	const isActive = selectedCar?.uuid === uuid;

	const buttonRef = useRef(null);

	return (
		<Button
			className={clsx({
				[classes.carBox]: true,
				[classes.active]: isActive,
			})}
			onClick={onClick}
			ref={buttonRef}
			onMouseEnter={() => setIsHovered(buttonRef.current)}
			onMouseLeave={() => setIsHovered(null)}
		>
			{isActive && (
				<Box className={classes.checkMarkBox}>
					{isHovered ? (
						<Box className={classes.cancelIconBox}>
							<CloseIcon className={classes.cancelIcon} />
						</Box>
					) : (
						<CheckMarkFilledIcon />
					)}
				</Box>
			)}
			<Typography variant="body1" className={classes.text}>
				{carName}
			</Typography>
			<Typography variant="body1" className={classes.text}>
				<span className={classes.bold}>{price}</span> /{' '}
				{t('common.fields.daily')}
			</Typography>
			<Box className={classes.companyBox}>
				<Tooltip title={officeName}>
					<span>
						<ExclamationMarkWithCircleIcon
							className={classes.icon}
							color="primary"
						/>
					</span>
				</Tooltip>
				<Typography variant="body1">{companyName}</Typography>
			</Box>
		</Button>
	);
};

CarCard.propTypes = {
	car: object.isRequired,
	selectedCar: object,
	onClick: func.isRequired,
};
