import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';
import usePrevious from '@rooks/use-previous';
import { useSelector } from 'react-redux';
import _ from 'lodash';

// Import store
import { recalculationErrorsSelector } from 'store/selectors';

export const useOptionsField = () => {
	const {
		values: {
			available_options,
			options: { delivery, driver },
		},
		submitting,
	} = useFormState();

	const { change } = useForm();

	const prevDelivery = usePrevious(delivery);
	const prevDriver = usePrevious(driver);

	useEffect(() => {
		// Driver option is not available when delivery option is selected
		if (delivery && prevDelivery === false) {
			change('options.driver', false);
		}
	}, [delivery, prevDelivery, change]);

	useEffect(() => {
		// Delivery option is not available when driver option is selected
		if (driver && prevDriver === false) {
			change('options.delivery', false);
		}
	}, [driver, prevDriver, change]);

	const errors = useSelector(recalculationErrorsSelector);

	const isErrorsArray = _.isArray(errors);

	const convertedErrors = isErrorsArray ? errors?.flat() : null;

	return {
		available_options: available_options || [],
		submitting,
		convertedErrors,
	};
};
