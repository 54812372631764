import React, { createContext } from 'react';
import { arrayOf, bool, func, node, object } from 'prop-types';

export const AvailabilityTableCellContext = createContext();

export const AvailabilityTableCellProvider = ({
	columnAccessors,
	toggleDialog,
	tableData,
	readOnly,
	children,
	carData,
	columns,
	isOpen,
}) => {
	return (
		<AvailabilityTableCellContext.Provider
			value={{
				columnAccessors,
				toggleDialog,
				tableData,
				readOnly,
				columns,
				carData,
				isOpen,
			}}
		>
			{children}
		</AvailabilityTableCellContext.Provider>
	);
};

AvailabilityTableCellProvider.propTypes = {
	tableData: arrayOf(object),
	columns: arrayOf(object),
	columnAccessors: object,
	carData: object,
	toggleDialog: func,
	children: node,
	readOnly: bool,
	isOpen: bool,
};
