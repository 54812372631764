import React from 'react';
import { object, string } from 'prop-types';
import { Typography, TableCell } from '@material-ui/core';

// Import helpers
import { getRole } from 'helpers';

// Import utils
import { useTranslations } from 'components/utilities';

export const RoleTableCell = ({ cell, userRole }) => {
	const { t } = useTranslations();

	const role = getRole(userRole);

	return (
		<TableCell
			key={cell.column.id}
			style={{ minWidth: 220 }}
			{...cell.getCellProps()}
		>
			<Typography>{t(role)}</Typography>
		</TableCell>
	);
};

RoleTableCell.propTypes = {
	cell: object,
	userRole: string,
};
