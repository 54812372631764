import React from 'react';
import { object, string } from 'prop-types';
import { Box } from '@material-ui/core';

// Import styles
import { useStyles } from './Steps.styles';

// Import components
import { LocationContainer } from './components';

export const Steps = ({ type, outerDeliveryData }) => {
	const classes = useStyles();

	return (
		<Box className={classes.innerWrapper} key={type}>
			<LocationContainer type={type} outerDeliveryData={outerDeliveryData} />
		</Box>
	);
};

Steps.propTypes = {
	type: string,
	outerDeliveryData: object,
};
