import React from 'react';
import { useSelector } from 'react-redux';
import { string } from 'prop-types';

// Import components
import { PreviewField } from 'components/elements';

// Import helpers
import { previewBookingSelector } from 'store/selectors';

export const PartialPaymentPreviewField = ({ accessor, label }) => {
	const { partial_payment_transactions } = useSelector(previewBookingSelector);

	const paymentType = accessor.split('_')[0];

	const totalAmount = partial_payment_transactions?.find(
		({ gateway }) => gateway === paymentType
	)?.amount_gross;

	if (!partial_payment_transactions?.length) return null;

	return <PreviewField label={label}> {totalAmount} </PreviewField>;
};

PartialPaymentPreviewField.propTypes = {
	accessor: string,
	label: string,
};
