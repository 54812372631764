import React from 'react';
import { format } from 'date-fns';
import { Box, Typography } from '@material-ui/core';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { YMD_SLASH_TIME_FORMAT } from 'helpers';

// Import context
import { useUrgentFormsProvider } from '../../../../context';

// Import components
import { UrgentRequestStatusField } from 'views/Bookings/BookingDetails/components/UrgentRequestStatusField';

// Import styles
import { useStyles } from './UrgentHistoryDialog.styles';

export const useUrgentHistoryDialog = () => {
	const classes = useStyles();
	const { urgentReasons } = useUrgentFormsProvider();

	const {
		t,
		i18n: { language: lang },
	} = useTranslations();

	const steps = urgentReasons
		?.map((reason) => {
			const {
				name,
				blamable_user_name: assignedBy,
				created_at: createdAt,
				status,
				marked_by_name: markedBy,
				marked_at: markedAt,
			} = reason || {};

			const assignedByLabel = t('common.fields.assigned_by', {
				name: assignedBy,
			});

			const markedByLabel = t('common.fields.marked_by', {
				name: markedBy,
			});

			const getFormattedDate = (date) =>
				format(new Date(date), YMD_SLASH_TIME_FORMAT);

			const description = reason && (
				<Typography variant="body1" color="inherit" className={classes.reason}>
					{name[lang]}
				</Typography>
			);

			const chip = (status) => (
				<Box className={classes.chip}>
					<UrgentRequestStatusField chipLabel={status} />
				</Box>
			);

			const getLabel = (label) => (
				<Typography variant="body1" color="inherit" className={classes.by}>
					{label}
				</Typography>
			);

			const result = [
				{
					label: assignedBy && getLabel(assignedByLabel),
					description,
					chip: chip('review'),
					date: getFormattedDate(createdAt),
					status,
				},
			];

			if (markedBy)
				result.unshift({
					label: markedBy && getLabel(markedByLabel),
					description,
					chip: chip(status),
					date: getFormattedDate(markedAt),
					status,
				});

			return result;
		})
		.flat();

	return { steps };
};
