import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	chip: ({ chipColors }) => ({
		backgroundColor: chipColors.backgroundColor,
		color: chipColors.color,
		maxWidth: 'fit-content',
		borderRadius: '100px',
		padding: '10px',
	}),
}));
