import React from 'react';
import { Button, Grid } from '@material-ui/core';

// Import components
import { useUrgentAttentionWarningCard } from './useUrgentAttentionWarningCard';
import { AlertMessage } from 'components/elements';
import { TimeElapsed } from './components';

// Import translation
import { useTranslations } from 'components/utilities';

// Import context
import { useMarkUrgentProvider, useTabsProvider } from 'components/context';

// Import helpers
import { BOOKING_DETAILS_TABS, getTabIndexBookingDetails } from 'helpers';

// Import styles
import { useStyles } from './UrgentAttentionWarningCard.styles';

export const UrgentAttentionWarningCard = () => {
	const { toggleMarkUrgent } = useMarkUrgentProvider();
	const { setCurrentTab } = useTabsProvider();

	const classes = useStyles();

	const { t } = useTranslations();

	const { isWarningVisible } = useUrgentAttentionWarningCard();

	if (!isWarningVisible) {
		return null;
	}

	const handleResolve = () => {
		setCurrentTab(getTabIndexBookingDetails(BOOKING_DETAILS_TABS.URGENT));
		toggleMarkUrgent();
	};

	return (
		<Grid item xs={12}>
			<AlertMessage
				iconClassName={classes.icon}
				containerClassName={classes.container}
				infoClassName={classes.info}
				labelsClassName={classes.labels}
				title={
					<Grid container spacing={1}>
						<Grid item>{`${t('common.buttons.urgent')}: `}</Grid>
						<Grid item>
							<TimeElapsed />
						</Grid>
					</Grid>
				}
				variant="error"
				description=""
				action={
					<Button
						variant="outlined"
						className={classes.button}
						onClick={handleResolve}
					>
						{t('common.buttons.resolve')}
					</Button>
				}
			/>
		</Grid>
	);
};
