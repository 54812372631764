// Import helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';

const { APPROVED, REVIEW, REJECTED, COMPENSATED } = BOOKING_CANCELLATION_STATES;

export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.common_states.review',
		status: REVIEW,
	},
	{
		label: 'common.common_states.approved',
		status: APPROVED,
	},
	{
		label: 'common.common_states.rejected',
		status: REJECTED,
	},
	{
		label: 'common.common_states.compensated',
		status: COMPENSATED,
	},
];
