import React from 'react';
import { Box, Button } from '@material-ui/core';
import { string } from 'prop-types';
import { useHistory } from 'react-router-dom';

// Import utils and helpers
import { useToggle, useTranslations } from 'components/utilities';
import { replacePreviewBookingUrl } from 'helpers';

// Import components
import {
	TransactionHistoryProvider,
	TransactionHistory,
	BookingHistoryProvider,
	BookingHistory,
} from 'components/elements/organisms/Booking';

// Import styles
import { useStyles } from './BottomBar.styles';

// Import assets
import { HistoryIcon, WalletIcon } from 'assets/icons';

export const BottomBar = ({ bookingUuid }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const history = useHistory();

	const { on: isBookingHistoryOpen, toggle: toggleBookingHistory } =
		useToggle();

	const { on: isTransactionsHistoryOpen, toggle: toggleTransactionsHistory } =
		useToggle();

	const goToBookingDetails = () =>
		history.push(replacePreviewBookingUrl(bookingUuid));

	return (
		<Box className={classes.container}>
			<Box className={classes.dialogsWrapper}>
				<Button
					color="primary"
					startIcon={<HistoryIcon className={classes.icon} />}
					onClick={toggleBookingHistory}
				>
					{t('common.buttons.booking_history')}
				</Button>
				<Button
					color="primary"
					startIcon={<WalletIcon className={classes.icon} />}
					onClick={toggleTransactionsHistory}
					className={classes.button}
				>
					{t('common.buttons.transactions_history')}
				</Button>

				{isTransactionsHistoryOpen && (
					<TransactionHistoryProvider bookingId={bookingUuid}>
						<TransactionHistory
							open={isTransactionsHistoryOpen}
							onClose={toggleTransactionsHistory}
						/>
					</TransactionHistoryProvider>
				)}
				{isBookingHistoryOpen && (
					<BookingHistoryProvider bookingId={bookingUuid}>
						<BookingHistory
							open={isBookingHistoryOpen}
							onClose={toggleBookingHistory}
						/>
					</BookingHistoryProvider>
				)}
			</Box>
			<Button
				className={classes.button}
				variant="contained"
				color="primary"
				size="large"
				onClick={goToBookingDetails}
			>
				{t('common.booking_details')}
			</Button>
		</Box>
	);
};

BottomBar.propTypes = {
	bookingUuid: string,
};
