import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '225px',
	},
	button: {
		fontSize: '13px',
		fontWeight: '600',
		justifyContent: 'flex-start',
		color: theme.palette.text[2],
	},
	text: {
		color: theme.palette.text[2],
		fontSize: '13px',
		fontWeight: '600',
	},
	divider: {
		backgroundColor: theme.palette.background[5],
		width: '100%',
	},
	icon: {
		width: '24px',
		height: '24px',
		color: theme.palette.text[2],
	},
}));
