import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useOffice = ({ officeUuid }) => {
	const path = `/v2/supplier/business/office/${officeUuid}/show`;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.OFFICES.SHOW(officeUuid),
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};
