import React from 'react';
import { array, bool, func, node, object } from 'prop-types';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import _ from 'lodash';

// Import helpers
import { setQueryParams, setFilterParams } from 'helpers';
import { useQueryStringParams } from 'components/utilities';

import { INITIAL_STATE, tableCache } from './helpers';

// Import reducer
import * as actions from './store/actions';
import reducer from './store/reducer';

// Create context
export const TableContext = React.createContext();

const {
	setLoading,
	setOptions,
	setTableData,
	setInputColumns,
	updateInputColumns,
	setFilters,
	setError,
} = actions;

const TableProvider = ({
	columns,
	fetchDataTableAction,
	children,
	initialResources = {},
	stopFilteringUpdates,
	customColumnsSettings = [],
	isDataTable = true,
}) => {
	const fetchIdRef = React.useRef(0);

	const [state, dispatchAction] = React.useReducer(reducer, {
		...INITIAL_STATE,
		tableColumns: columns,
		resources: initialResources,
	});

	const { parsedFilters, parsedOptions, stringifyParams } =
		useQueryStringParams();

	const history = useHistory();

	const dispatch = useDispatch();

	const { id: itemId } = useParams();

	const {
		pageSize: pageSizeParameter,
		pageIndex: pageIndexParameter,
		globalFilter: globalFilterParameter,
		sortBy: sortByParameter,
	} = state.options;

	const { filters } = state;

	// eslint-disable-next-line
	const fetchData = React.useCallback(
		// eslint-disable-next-line
		async (
			{
				pageSize,
				pageIndex,
				sortBy,
				globalFilter,
				externalFilters,
			} = state.options,
			inputTableColumns = state.inputColumns,
			tableFilters,
			cancelToken
		) => {
			const tableOptions = { pageSize, pageIndex, sortBy, globalFilter };
			// Dispatch options
			setOptions(tableOptions)(dispatchAction);

			// Give this fetch an ID
			const fetchId = ++fetchIdRef.current;

			// Dispatch loading state
			setLoading(true)(dispatchAction);

			if (fetchId === fetchIdRef.current) {
				try {
					const persistedInputTableColumns = _.isEmpty(inputTableColumns)
						? tableCache.getTableColumns()
						: inputTableColumns;

					// Get query params
					const queryParams = setQueryParams(
						tableOptions,
						persistedInputTableColumns,
						isDataTable
					);

					const filterParams = setFilterParams(
						filters,
						persistedInputTableColumns,
						queryParams
					);

					const params = `${queryParams}${filterParams}`;

					const tableData = await fetchDataTableAction({
						updateColumns: handleUpdateInputColumns,
						options: tableOptions,
						queryParams: params,
						externalFilters,
						filters,
						columns,
						itemId,
						cancelToken,
					})(dispatch);

					const { data: originalData, resources, rolesData } = tableData;

					const { data, recordsFiltered, input } = originalData || {};

					const isCustomTable = !!customColumnsSettings?.length;

					const tableColumns = isCustomTable
						? customColumnsSettings
						: input?.columns;

					tableCache.setTableColumns(tableColumns);

					/* eslint-disable */
					const tableDataPayload = isCustomTable
						? {
								data: tableData?.data,
						  }
						: {
								data,
								pageCount: Math.ceil(recordsFiltered / pageSize),
								resources,
								rolesData,
								recordsFiltered,
						  };

					// Dispatch table actions
					setTableData(tableDataPayload)(dispatchAction);
					setInputColumns(tableColumns)(dispatchAction);
					setLoading(false)(dispatchAction);

					// Update query params
					const isTheSameOptions = _.isEqual(parsedOptions, tableOptions);
					const isTheSameFilters = _.isEqual(parsedFilters, filters);

					if (!isTheSameOptions || !isTheSameFilters) {
						const urlParams = stringifyParams({
							options: tableOptions,
							filters: state.filters,
						});

						!stopFilteringUpdates && history.push(urlParams);
					}
				} catch (error) {
					if (error && error.response) {
						const message =
							error.response.data.message ?? error.response.data.error;

						setError(message)(dispatchAction);
					}
				}
			}
		},
		// eslint-disable-next-line
		[
			pageSizeParameter,
			pageIndexParameter,
			globalFilterParameter,
			sortByParameter,
			filters,
		]
	);

	const handleSetFilters = (values) => setFilters(values)(dispatchAction);

	const handleUpdateInputColumns = (columns) =>
		updateInputColumns(columns)(dispatchAction);

	return (
		<TableContext.Provider
			value={{
				...state,
				columns: state.tableColumns,
				setFilters: handleSetFilters,
				updateColumns: handleUpdateInputColumns,
				fetchData,
			}}
		>
			{children}
		</TableContext.Provider>
	);
};

TableProvider.propTypes = {
	columns: array.isRequired,
	fetchDataTableAction: func.isRequired,
	initialResources: object,
	children: node,
	stopFilteringUpdates: bool,
	customColumnsSettings: array,
	isDataTable: bool,
};

export default TableProvider;
