import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeInput, MultiCompaniesFilter } from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<>
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="due_date"
					nameUntil="due_date_to"
					label="common.filters.due_date"
					combinedInputs
					isToggleButton
				/>
			</Grid>

			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			)}
		</>
	);
};
