export const INITIAL_VALUES = {
	company_uuids: [],
	public_holidays: {
		eid_al_fitr_holiday: {
			date_from: null,
			date_to: null,
			selected: false,
		},
		eid_al_adha_holiday: {
			date_from: null,
			date_to: null,
			selected: false,
		},
		national_day_holiday: {
			date_from: null,
			date_to: null,
			selected: false,
		},
	},
};
