import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

// Import helpers and utils
import { replaceEditPromoCodeUrl } from 'helpers';
import { useMessage, useTranslations } from 'components/utilities';
import { useTableContextProvider } from 'components/context';

// Imports store
import { updateActivePromoCode } from 'store/actions';

export const useActionsPreviewPopupContent = ({ cell }) => {
	const [isStatusLoading, setIsStatusLoading] = useState(false);

	const history = useHistory();

	const dispatch = useDispatch();

	const { t } = useTranslations();

	const { message } = useMessage();

	const { fetchData } = useTableContextProvider();

	const { code, active } = cell.row.original;

	const successMessage = `table.actions.user_${
		!active ? 'activated' : 'deactivated'
	}`;

	const handleOnSwitchChange = async () => {
		setIsStatusLoading(true);
		try {
			await updateActivePromoCode({ isActive: active, id: code })(dispatch);
			message.success(t(successMessage));
			await fetchData();
		} catch (error) {
			if (error.response) {
				if (error.response.status === 403) {
					message.error(t('errors.not_authorized'));
				} else {
					message.error(t('common.messages.error_message'));
				}
			} else if (error.message) {
				message.error(error.message);
			} else {
				message.error(t('errors.unknown'));
				throw error;
			}
		}
		setIsStatusLoading(false);
	};

	const handleOnEdit = () => history.push(replaceEditPromoCodeUrl(code));

	return { handleOnEdit, active, handleOnSwitchChange, isStatusLoading };
};
