import React from 'react';

// Import components
import { TableFiltersToolbar } from 'components/elements';
import { FilterFields } from '../../components';

// Import helpers and utils
import { useCarsAvailability } from 'views/CarsAvailability/useCarsAvailability';
import { formatValues } from './helpers';

export const Filters = () => {
	const { filtersInitialValues } = useCarsAvailability();

	return (
		<TableFiltersToolbar
			initialValues={filtersInitialValues}
			formatValues={formatValues}
			dialogFilters={<FilterFields />}
			showOuterFilterActionButtons={false}
		/>
	);
};
