export const convertCustomData = (data) => {
	let totalPaid = Number(data.refunded_amount);
	if (data.fees) {
		totalPaid += Number(data.fees);
	}

	return {
		...data,
		totalPaid: totalPaid.toFixed(2),
	};
};

export const CANCELLATION_FIELDS = ({
	accessorClassName,
	labelClassName,
	data,
}) => {
	const isPartialRefund = !!Number(data?.fees);

	const baseFields = [
		{
			accessor: 'booking_cancelled_at',
			label: 'common.fields.cancellation_date',
		},
		{
			accessor: 'totalPaid',
			label: 'common.fields.total_paid',
		},
		{
			accessor: 'refunded_amount',
			label: 'common.fields.refund_to_customer',
			labelClassName,
			accessorClassName,
		},
	];

	if (isPartialRefund) {
		baseFields.splice(2, 0, {
			accessor: 'fees',
			label: 'common.fields.cancellation_fee',
		});
	}

	return baseFields;
};
