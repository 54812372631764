import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import components
import {
	NewActionsMenuTableCell,
	AvailableCarTableCell,
	CommonStatusTableCell,
} from 'components/elements';

// Import helpers and utils
import { COLUMN_ACCESSORS } from 'views/Cars/columns';
import { statusList } from './helpers';
import { useCell } from './useCell';

const { ACTIONS, STATUS, AVAILABLE } = COLUMN_ACCESSORS;

export const Cell = ({ cell, isHovered }) => {
	const { actionMenuFields, carAvailability, editUrl } = useCell({ cell });

	const renderCell = (cell) => {
		const {
			column: { id: columnId },
			row: {
				original: { active, available, uuid },
			},
		} = cell;

		switch (columnId) {
			case STATUS: {
				return (
					<CommonStatusTableCell
						currentStatus={active ? 'active' : 'inactive'}
						statusList={statusList}
						key={columnId}
						cell={cell}
					/>
				);
			}

			case AVAILABLE: {
				return (
					<AvailableCarTableCell
						amount={available}
						key={columnId}
						showIcon
						cell={cell}
						eventStoreUuid={uuid}
					/>
				);
			}

			case ACTIONS: {
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						carAvailability={carAvailability}
						extraActions={{
							editPath: editUrl,
							editAction: true,
						}}
						key={columnId}
						cell={cell}
						isHovered={isHovered}
					/>
				);
			}

			default: {
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
			}
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
