import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell, Typography } from '@material-ui/core';

// Import helpers and utils
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { replaceEditCompanyManagersUrl } from 'helpers';
import { companyManagerStatusList } from './helpers';
import { useCell } from './useCell';

// Import components
import {
	NewActionsMenuTableCell,
	CommonStatusTableCell,
	RoleTableCell,
	LinkWithIconTableCell,
} from 'components/elements';

// Import assets
import { OfficeIcon } from 'assets/icons';

const { ROLE, STATUS, ACTIONS, OFFICES, EMAIL } = COLUMNS_ACCESSORS;

export const Cell = ({ cell, isHovered }) => {
	const { actionMenuFields } = useCell({ cell });

	const renderCell = (cell) => {
		const {
			column: { id: columnId },
			row: {
				original: { uuid, active, role, email, office_count: officeCount },
			},
		} = cell;

		switch (columnId) {
			case ROLE:
				return <RoleTableCell key={columnId} cell={cell} userRole={role} />;

			case EMAIL: {
				return (
					<TableCell key={cell.column.id} style={{ minWidth: 280 }}>
						<Typography>{email}</Typography>
					</TableCell>
				);
			}

			// TODO: link has to be implemented when backend add its part
			case OFFICES:
				return (
					<LinkWithIconTableCell
						text={officeCount}
						key={cell.column.id}
						icon={OfficeIcon}
						cell={cell}
						to="#"
					/>
				);

			case STATUS:
				return (
					<CommonStatusTableCell
						key={columnId}
						cell={cell}
						statusList={companyManagerStatusList}
						currentStatus={active ? 'active' : 'inactive'}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						extraActions={{
							editPath: replaceEditCompanyManagersUrl(uuid),
							editAction: true,
						}}
						key={columnId}
						isHovered={isHovered}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={columnId} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
