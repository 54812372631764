// Import helpers
import { FIELD_TYPES } from 'helpers';

export const FIELDS = [
	{
		type: FIELD_TYPES.TEXT,
		label: 'bookings.close_compensation_form.rrn',
		name: 'rrn',
	},
	{
		type: FIELD_TYPES.IMAGE_FILE,
		label: 'bookings.customer_compensation.form.transaction_copy',
		name: 'transaction_file.file_path',
		previewFileState: 'transaction_file',
		fileNameField: 'transaction_file.file_name',
	},
];
