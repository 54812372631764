import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

// Import components
import { Button, Grid } from 'components/elements';

// Import utils and helpers
import { useProfile } from 'components/utilities';
import {
	replaceOfficeExternalPartnerCarsUrl,
	replaceAddCarUrl,
	BUTTON_PARAMS,
} from 'helpers';

// Import assets
import { AddCarIcon, CarIcon } from 'assets/icons';

export const CarsOuterToolbarElements = () => {
	const { isOfficeManager } = useProfile();
	const { push } = useHistory();
	const { id } = useParams();

	const goToAddCar = () => push(replaceAddCarUrl(id));
	const goToExternalPartnerCars = () =>
		push(replaceOfficeExternalPartnerCarsUrl(id));

	if (isOfficeManager) return null;

	return (
		<Grid container spacing={2}>
			<Grid item>
				<Button
					text="common.buttons.map_external_partner"
					type={BUTTON_PARAMS.TYPE.WHITE_FILLED}
					onClick={goToExternalPartnerCars}
					icon={CarIcon}
				/>
			</Grid>
			<Grid item>
				<Button
					text="common.buttons.add_car"
					onClick={goToAddCar}
					icon={AddCarIcon}
				/>
			</Grid>
		</Grid>
	);
};
