import { every, identity } from 'lodash';

// Import helpers
import { SERVICE_TYPES } from '../../helpers';

const checkCustomerDetailsAdded = (values) => {
	const { customerDetails, booking_owner_uuid } = values;
	if (customerDetails.isNew) {
		return every(customerDetails, identity);
	} else {
		return !!booking_owner_uuid;
	}
};

const checkLocationExists = (values) => {
	const {
		service_type: serviceType,
		location,
		delivery,
		return: returnLocation,
	} = values;

	const isPickup = serviceType === SERVICE_TYPES.PICKUP;

	if (isPickup) {
		return !!location.lat && !!location.lng;
	} else {
		return (
			!!delivery.lat &&
			!!delivery.lng &&
			!!returnLocation.lat &&
			!!returnLocation.lng
		);
	}
};

const checkCarDataAvailable = (values) => !!values.chosenCar;

const checkDateAndTimeDataAdded = (values) => {
	const { period } = values;
	return every(period, identity);
};

export const isAllDataFilled = (values) => {
	const customerDetails = checkCustomerDetailsAdded(values);
	const locations = checkLocationExists(values);
	const carData = checkCarDataAvailable(values);
	const dateAndTime = checkDateAndTimeDataAdded(values);

	return customerDetails && locations && carData && dateAndTime;
};
