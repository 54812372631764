import React from 'react';
import { object, func } from 'prop-types';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@react-google-maps/api';

// Import translations
import { useTranslation } from 'react-i18next';

// Import components
import { GoogleMapsScriptLoader } from 'components/elements';

const LocationAutocompleteFieldAdapter = ({
	input,
	meta,
	setAutocompleteValue,
	onChange,
	...rest
}) => {
	const { t } = useTranslation();
	const error = meta.touched && (meta.error || meta.submitError) ? true : false;
	const errorText =
		meta.touched && (meta.error || meta.submitError)
			? t(meta.error) || meta.submitError
			: '';

	const onLoad = (autocomplete) => setAutocompleteValue(autocomplete);

	return (
		<GoogleMapsScriptLoader>
			<Autocomplete onLoad={onLoad} onPlaceChanged={onChange}>
				<TextField {...input} {...rest} error={error} helperText={errorText} />
			</Autocomplete>
		</GoogleMapsScriptLoader>
	);
};

LocationAutocompleteFieldAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	setAutocompleteValue: func.isRequired,
	onChange: func.isRequired,
};

export default LocationAutocompleteFieldAdapter;
