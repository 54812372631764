// Import helpers
import { reverseAndSplitString } from 'helpers';

const CASH_FLOW_COLORS = {
	GREEN: 'green',
	GREY: 'grey',
	RED: 'red',
};

export const getCashFlowColor = ({ cashFlow }) => {
	const [currency, amount] = reverseAndSplitString({ string: cashFlow });

	if (amount === 0) {
		return CASH_FLOW_COLORS.GREY;
	} else if (currency.includes('-')) {
		return CASH_FLOW_COLORS.RED;
	} else {
		return CASH_FLOW_COLORS.GREEN;
	}
};
