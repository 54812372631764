import React, { Fragment } from 'react';
import { array } from 'prop-types';
import { Divider, Grid, Typography } from '@material-ui/core';

// Import helpers
import { getCreatedAtDateFormatted } from './helpers';

// Import styles
import { useStyles } from './CommentsContent.styles';

export const CommentsContent = ({ comments = [] }) => {
	const classes = useStyles();

	if (!comments.length) return null;

	return (
		<>
			{comments.map(({ author, content, created_at }, index) => (
				<Fragment key={index}>
					<Grid
						container
						alignItems="center"
						justifyContent="space-between"
						className={classes.container}
					>
						<Grid item>
							<Typography variant="body1" className={classes.name}>
								{author}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1" className={classes.date}>
								{getCreatedAtDateFormatted(created_at)}
							</Typography>
						</Grid>
					</Grid>
					<Typography variant="body1" className={classes.comment}>
						{content}
					</Typography>
					{index !== comments.length - 1 && (
						<Divider className={classes.divider} />
					)}
				</Fragment>
			))}
		</>
	);
};

CommentsContent.propTypes = {
	comments: array,
};
