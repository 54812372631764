import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		minWidth: theme.layout.width.button,
		padding: '12px 24px',
		borderColor: theme.palette.background[3],
	},
	rejected: { color: theme.palette.error.medium },
	contractOpened: {
		color: theme.palette.text.secondary,
		borderColor: theme.palette.text.secondary,
		'&:hover': {
			borderColor: theme.palette.text.secondary,
		},
	},
}));
