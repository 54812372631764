// Import helpers
import { FIELD_TYPES } from 'helpers';

export const UPDATE_REASON_FIELDS = ({ reasons, lang }) => [
	{
		label: `common.fields.select_new_reason`,
		name: 'cancellationReason',
		type: FIELD_TYPES.SELECT,
		required: true,
		options: reasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ details }) => details.name[lang],
	},
];
