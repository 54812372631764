import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	button: {
		padding: '7px 16px',
		borderRadius: '8px',
	},
	icon: {
		width: 24,
		height: 24,
	},
}));
