import React from 'react';
import { string, func, object, node, oneOfType } from 'prop-types';
import { DialogTitle, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { CloseButton } from 'components/elements';

// Import styles
import { useStyles } from './ClosableDialogTitle.styles';

const ClosableDialogTitle = ({
	title,
	onClose,
	icon,
	titleClassName,
	actions,
}) => {
	const classes = useStyles();

	return (
		<DialogTitle>
			<Grid
				container
				spacing={2}
				justifyContent="space-between"
				alignItems="center"
				className={classes.container}
			>
				<Grid item className={classes.textWrapper}>
					{icon && icon}
					<Typography className={clsx([classes.title, titleClassName])}>
						{title}
					</Typography>
					{actions && actions}
				</Grid>
				<Grid item>
					<CloseButton onClick={onClose} />
				</Grid>
			</Grid>
		</DialogTitle>
	);
};

ClosableDialogTitle.propTypes = {
	title: string.isRequired,
	onClose: func.isRequired,
	titleClassName: oneOfType([string, object]),
	icon: node,
	actions: node,
};

export default ClosableDialogTitle;
