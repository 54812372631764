import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useCarModels = () => {
	const body = { start: 0, length: -1 };

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CARS.MODELS,
		async () => await services.post(ENDPOINTS.CARS.ALL_MODELS, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};

export default useCarModels;
