// Import helpers
import { capitalize, FIELD_TYPES } from 'helpers';

export const ADD_AMOUNT_DIALOG_FIELDS = ({
	reasons,
	isDescriptionInputVisible,
	minDate,
}) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'customers.preview.wallet.add_dialog.add_reason',
		name: 'reason_uuid',
		required: true,
		md: isDescriptionInputVisible ? 6 : 12,
		options: reasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ description }) => capitalize(description),
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'customers.preview.wallet.description',
		name: 'description',
		required: true,
		isHidden: !isDescriptionInputVisible,
		md: isDescriptionInputVisible ? 6 : 12,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'customers.preview.wallet.add_dialog.amount',
		name: 'amount',
		required: true,
		md: 6,
		options: reasons,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ description }) => capitalize(description),
	},
	{
		type: FIELD_TYPES.DATE,
		label: 'customers.preview.wallet.add_dialog.expiry_date',
		name: 'expiry_date',
		required: true,
		md: 6,
		minDate,
	},
];
