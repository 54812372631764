import { bool, number, oneOfType, string } from 'prop-types';
import { useFormState } from 'react-final-form';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useVehicleModelList } from 'queries';
import { FIELD_TYPES } from 'helpers';

export const MultiModelFilter = ({
	label = '',
	name,
	xs = 3,
	md = 3,
	disabled = false,
	required = false,
	smallFieldStyle = false,
	showEmptyLabel = false,
	manufacturerUuid,
}) => {
	const { values } = useFormState();

	const { renderFields } = useCommonFields();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data: models } = useVehicleModelList({
		manufacturerUuid,
	});

	if (!models) {
		return null;
	}

	const multiSelectLabelExtractor = (options, currentUuid) =>
		currentUuid === 'all'
			? null
			: options.find(({ uuid }) => uuid === currentUuid)?.name[language];

	return renderFields({
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH_FILTER,
		name,
		label,
		options: models,
		showAllOption: true,
		multiSelectLabelExtractor,
		currentMultiSelectValues: values?.[name],
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ name }) => name[language],
		required,
		md,
		xs,
		smallFieldStyle,
		emptyLabel: showEmptyLabel ? t('common.fields.model') : '',
		searchExtractor: 'children[1]',
		disabled,
	});
};

MultiModelFilter.propTypes = {
	name: string.isRequired,
	label: string,
	showFetching: bool,
	disabled: bool,
	required: bool,
	md: oneOfType([string, number]),
	xs: oneOfType([string, number]),
	smallFieldStyle: oneOfType([bool, string]),
	manufacturerUuid: string,
	showEmptyLabel: bool,
};
