import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { MultiCompaniesFilter } from 'components/elements';
import { useProfile } from 'components/utilities';

export const FilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3}>
			{isAdmin && (
				<MultiCompaniesFilter
					label="common.fields.company"
					name="company_uuid"
					xs={6}
					md={6}
				/>
			)}
		</Grid>
	);
};
