import React from 'react';
import { bool } from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useFormState } from 'react-final-form';

// Import components
import { BaseYesNoAllFilter } from 'components/elements';
import { ActiveCompaniesFilter } from 'components/elements';

// Import utilities and helpers
import { useCommonFields } from 'components/utilities';
import { useCompanies } from 'queries';
import { FIELDS } from './helpers';
import { isAdminSelector } from 'store/selectors';

export const FilterFields = ({ isExport }) => {
	const { renderFields } = useCommonFields();
	const { data: companies } = useCompanies();
	const isAdmin = useSelector(isAdminSelector);

	const { values } = useFormState();

	return (
		<Grid container spacing={3} alignItems="center">
			<BaseYesNoAllFilter
				name="active"
				label={`${
					isExport
						? 'offices.table.export_filters.office_is_active'
						: 'offices.table.headers.is_active'
				}`}
			/>
			{isExport && (
				<ActiveCompaniesFilter
					name="company_is_active"
					label="offices.table.export_filters.company_is_active"
				/>
			)}

			{isAdmin && isExport && FIELDS({ companies, values }).map(renderFields)}
		</Grid>
	);
};

FilterFields.propTypes = {
	isExport: bool,
};

FilterFields.defaultProps = {
	isExport: false,
};
