import React from 'react';
import {
	oneOfType,
	arrayOf,
	number,
	object,
	string,
	bool,
	shape,
} from 'prop-types';
import { Button, TableCell, Typography } from '@material-ui/core';

// Import assets
import { InformationIcon } from 'assets/icons';

// Import utils
import { AvailabilityTableCellProvider } from './context';
import { useToggle } from 'components/utilities';

// Import components
import { AvailabilityDialog } from './components';
import { Grid } from 'components/elements';

// Import styles
import { useStyles } from './AvailabilityTableCell.styles';

export const AvailabilityTableCell = ({
	columnAccessors,
	availability,
	tableData,
	readOnly,
	carData,
	columns,
	cell,
}) => {
	const classes = useStyles();

	const { on: isOpen, toggle } = useToggle(false);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Button onClick={toggle}>
				<Grid container alignItems="center" justifyContent="center" spacing={1}>
					<Grid item>
						<InformationIcon className={classes.icon} />
					</Grid>
					<Grid item>
						<Typography className={classes.text}>{availability}</Typography>
					</Grid>
				</Grid>
			</Button>
			<AvailabilityTableCellProvider
				columnAccessors={columnAccessors}
				toggleDialog={toggle}
				tableData={tableData}
				readOnly={readOnly}
				carData={carData}
				columns={columns}
				isOpen={isOpen}
			>
				<AvailabilityDialog />
			</AvailabilityTableCellProvider>
		</TableCell>
	);
};

AvailabilityTableCell.propTypes = {
	carData: shape({
		availability: oneOfType([number, string]),
		modelAndYear: string,
		image: string,
		name: string,
	}),
	availability: oneOfType([number, string]),
	tableData: arrayOf(object),
	columns: arrayOf(object),
	cell: object.isRequired,
	columnAccessors: object,
	readOnly: bool,
};
