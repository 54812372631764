import React from 'react';
import { Button } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useResetButton } from './useResetButton';

// Import styles
import { useStyles } from './ResetButton.styles';

// Import components
import { ConfirmationDialog } from '../ConfirmationDialog';

export const ResetButton = () => {
	const { t } = useTranslations();

	const {
		handleOnResetBookingRating,
		toggleResetDialog,
		clearDialogState,
		isResetDialogOpen,
		isButtonLoading,
		isButtonHidden,
		isSuccessReset,
	} = useResetButton();

	const classes = useStyles();

	if (isButtonHidden) return null;

	return (
		<>
			<Button
				onClick={toggleResetDialog}
				className={classes.button}
				type="button"
			>
				{t('bookings.preview_booking.ratings.reset_rating')}
			</Button>

			<ConfirmationDialog
				submitAction={handleOnResetBookingRating}
				closeAction={clearDialogState}
				toggle={clearDialogState}
				isLoading={isButtonLoading}
				isSuccess={isSuccessReset}
				isOpen={isResetDialogOpen}
				isReset
			/>
		</>
	);
};
