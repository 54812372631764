import React from 'react';
import { object, string } from 'prop-types';
import { DatePicker } from '@material-ui/pickers';
import { IconButton, Typography, Box } from '@material-ui/core';
import clsx from 'clsx';

// Import helpers
import { DEFAULT_SLASH_DATE_FORMAT } from 'helpers';

// Import styles
import { useStyles } from './DatePickerWithIconFieldAdapter.styles';

// Import assets
import { CalendarIcon } from 'assets/icons';

// Import components
import { InputError } from 'components/elements/atoms';

export const DatePickerFieldWithIconAdapter = ({
	input,
	meta,
	pickerFormat = DEFAULT_SLASH_DATE_FORMAT,
	placeholder = '',
	inputLabel = '',
	...rest
}) => {
	const classes = useStyles();

	const convertInputDate = (value) => {
		const [day, month, year] = value.split('/');

		return new Date(year, month - 1, day);
	};

	const date =
		typeof input.value === 'string' && input.value.includes('/')
			? convertInputDate(input.value)
			: input.value;

	const isError = !!(meta.touched && (meta.error || meta.submitError));

	return (
		<Box className={classes.container}>
			{inputLabel && (
				<Typography className={classes.inputLabel}>{inputLabel}</Typography>
			)}
			<DatePicker
				{...input}
				{...rest}
				value={date || null}
				format={pickerFormat}
				error={isError}
				required={rest.required}
				InputProps={{
					endAdornment: (
						<IconButton size="small">
							<CalendarIcon
								className={clsx({
									[classes.icon]: true,
									[classes.disabledIcon]: rest.disabled,
									[classes.errorIcon]: isError,
								})}
							/>
						</IconButton>
					),
				}}
			/>
			{placeholder && !date && (
				<Typography className={classes.placeholder}>{placeholder}</Typography>
			)}
			<InputError meta={meta} />
		</Box>
	);
};

DatePickerFieldWithIconAdapter.propTypes = {
	input: object.isRequired,
	meta: object.isRequired,
	pickerFormat: string,
	placeholder: string,
	inputLabel: string,
};
