import React from 'react';

// Import helpers
import { useCommonFields } from 'components/utilities';
import { useTopBar } from './useTopBar';
import { FIELDS } from './helpers';

// Import components
import { Grid } from 'components/elements';

export const TopBar = () => {
	const { renderFields } = useCommonFields();
	const {
		allCompaniesCheckboxDisabled,
		handleOnCheckboxClick,
		companiesDisabled,
		companies,
		values,
	} = useTopBar();

	return (
		<Grid container spacing={4}>
			{FIELDS({
				allCompaniesCheckboxDisabled,
				handleOnCheckboxClick,
				companiesDisabled,
				companies,
				values,
			}).map(renderFields)}
		</Grid>
	);
};
