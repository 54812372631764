import React from 'react';
import { Box, Button, Dialog, DialogContent, Grid } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import components
import { ButtonProgress, ClosableDialogTitle } from 'components/elements';
import { FormContent } from './components';

// Import utils and helpers
import { useCompensationFormProvider } from '../../context';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CompensationForm.styles';

export const CompensationForm = () => {
	const classes = useStyles();

	const { t } = useTranslations();
	const {
		isCompensationFormOpen,
		toggleCompensationForm,
		handleOnSubmitCompensation,
		isLoading,
		compensationFormInitialValues,
		compensationFormTitle,
	} = useCompensationFormProvider();

	return (
		<Dialog
			open={isCompensationFormOpen}
			onClose={toggleCompensationForm}
			maxWidth="sm"
			fullWidth
		>
			<ClosableDialogTitle
				title={t(compensationFormTitle)}
				onClose={toggleCompensationForm}
			/>
			<DialogContent>
				<Form
					onSubmit={handleOnSubmitCompensation}
					initialValues={compensationFormInitialValues}
					render={({ handleSubmit }) => (
						<Box
							id="compensation-form"
							component="form"
							onSubmit={handleSubmit}
						>
							<FormContent />
						</Box>
					)}
				/>

				<Grid
					container
					justifyContent="flex-end"
					spacing={2}
					className={classes.buttonsContainer}
				>
					<Grid item>
						<Button
							onClick={toggleCompensationForm}
							color="default"
							className={classes.button}
							disabled={isLoading}
							size="large"
						>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							color="primary"
							variant="outlined"
							className={classes.button}
							type="submit"
							form="compensation-form"
							disabled={isLoading}
							isLoading={isLoading}
							size="large"
						>
							{t(compensationFormTitle)}
						</ButtonProgress>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};
