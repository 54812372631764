// Import helpers
import { ENDPOINTS } from 'helpers';
import services from 'services/services';

export const INITIAL_VALUES = {
	file: null,
	is_outstanding_amount: false,
	extra_days: null,
	extra_days_price: null,
	car_damages_price: null,
	fuel_refill_price: null,
	extra_hours: null,
	extra_hours_price: null,
	violations_price: null,
	extra_kilometer: null,
	extra_kilometer_price: null,
	total: 0,
};

const convertValues = (values) => {
	const {
		file,
		is_outstanding_amount,
		extra_days,
		extra_days_price,
		car_damages_price,
		fuel_refill_price,
		extra_hours,
		extra_hours_price,
		violations_price,
		extra_kilometer,
		extra_kilometer_price,
	} = values;
	const formData = new FormData();
	file && formData.append('file', file);

	if (is_outstanding_amount) {
		formData.append('extra_days', Number(extra_days));
		formData.append('extra_days_price', Number(extra_days_price));
		formData.append('car_damages_price', Number(car_damages_price));
		formData.append('fuel_refill_price', Number(fuel_refill_price));
		formData.append('extra_hours', Number(extra_hours));
		formData.append('extra_hours_price', Number(extra_hours_price));
		formData.append('violations_price', Number(violations_price));
		formData.append('extra_kilometer', Number(extra_kilometer));
		formData.append('extra_kilometer_price', Number(extra_kilometer_price));
	}
	return formData;
};

export const handleSubmit = ({ values, booking, successCallback }) => {
	const bookingUuid = booking?.uuid;
	if (!values.file && !values.is_outstanding_amount) {
		return services
			.post(ENDPOINTS.RETURN_PAGE.CLOSE_CONTRACT({ bookingUuid }))
			.then(() => {
				successCallback();
			});
	} else {
		const convertedValues = convertValues(values);
		return services
			.post(
				ENDPOINTS.RETURN_PAGE.CLOSE_CONTRACT({ bookingUuid }),
				convertedValues
			)
			.then(() => {
				successCallback();
			});
	}
};
