import { useForm, useFormState } from 'react-final-form';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { useField } from 'react-final-form';

import {
	rejectCustomerCompensation,
	calculateCompensation,
	updateDialogMessage,
} from 'store/actions';

// Import utilities
import { useMessage } from 'components/utilities';
import { POPULAR_BANKS_LIST, getBankNameOptions } from 'helpers';

import {
	BOOKING_COMPENSATION_TYPES,
	BOOKING_COMPENSATION_METHODS,
	BOOKING_COMPENSATION_STATES,
	LOCATIONS,
} from 'helpers';

const { CANCELLATION } = BOOKING_COMPENSATION_TYPES;
const { BANK } = BOOKING_COMPENSATION_METHODS;

const useMakeCompensationFields = ({
	booking,
	isInModal,
	isInvalidButtonVisible,
	onClose,
	outerCompensation,
	location,
	onInvalid,
}) => {
	const [isLoading, setIsLoading] = useState(false);
	const [isInvalidButtonLoading, setInvalidButtonLoading] = useState(false);
	const [isBasePriceRequested, setIsBasePriceRequested] = useState(false);
	const { change } = useForm();
	const { submitting, values } = useFormState();

	const isCancellation =
		!!booking?.operation_topics?.includes(CANCELLATION) ||
		outerCompensation?.type === CANCELLATION;

	const compensationAmountOptions = {
		step: 0.01,
		min: 0.01,
		max: isCancellation ? null : +booking.item.total_discount_gross_price,
	};

	const {
		input: bankNameInput,
		meta: { modified },
	} = useField('bank_name');

	const dispatch = useDispatch();

	const { message } = useMessage();

	const sectionName = 'bookings.customer_compensation';

	const isBankPaymentMethod = values.method === BANK;

	const submitButtonLabel = `common.buttons.${
		isInModal ? 'validate' : 'submit'
	}`;
	const invalidButtonLabel = `common.buttons.invalid`;

	const isWaitingCustomerInfoStatus =
		(outerCompensation?.status || booking?.compensation?.status) ===
		BOOKING_COMPENSATION_STATES.WAITING;

	const getInvalidButtonVisibility = () => {
		if (isInvalidButtonVisible) {
			return true;
		} else if (location === LOCATIONS.BOOKING_PREVIEW) {
			return false;
		} else if (isWaitingCustomerInfoStatus) {
			return true;
		} else {
			return false;
		}
	};

	const showInvalidButton = getInvalidButtonVisibility();

	const token = useSelector(
		(state) => state.auth.authenticate?.data?.meta?.access_token
	);

	const source = axios.CancelToken.source();
	const cancelToken = source.token;
	const compensationUuid =
		outerCompensation?.uuid || booking?.compensation?.uuid;

	const getCalculatedAmount = async (values = {}) => {
		setIsLoading(true);

		try {
			const { gross } = await calculateCompensation({
				values,
				booking,
				cancelToken,
			});

			change('compensation_amount', gross);
			setIsBasePriceRequested(false);
			setIsLoading(false);
		} catch (error) {
			setIsBasePriceRequested(!!error.response?.data?.errors?.base_nett_price);
			setIsLoading(false);
		}
	};

	const handleOnInvalidButton = async () => {
		setInvalidButtonLoading(true);
		try {
			await rejectCustomerCompensation(compensationUuid)(dispatch);
			setInvalidButtonLoading(false);
			updateDialogMessage('validation.table.modal.compensation_rejected')(
				dispatch
			);
			onClose();
			onInvalid();
		} catch (error) {
			const errorMessage =
				error.response.data.error_header ||
				error.response.data.error ||
				error.message;
			setInvalidButtonLoading(false);
			message.error(errorMessage);
		}
	};

	const handleOnBasePriceSubmit = async () => {
		const { base_nett_price } = values;

		await getCalculatedAmount({ base_nett_price });
	};

	const bankNameOptions = getBankNameOptions({
		options: POPULAR_BANKS_LIST,
		modified,
		value: bankNameInput.value,
	});

	const handleBankNameChange = (_, option) => bankNameInput.onChange(option);

	const getInitialAmount = () => {
		if (location === LOCATIONS.BOOKING_PREVIEW) {
			return null;
		} else {
			return (
				outerCompensation?.compensation_amount?.nett ||
				outerCompensation?.compensation_amount ||
				booking?.compensation?.compensation_amount?.nett ||
				booking?.compensation?.compensation_amount
			);
		}
	};

	useEffect(() => {
		const initialAmount = getInitialAmount();

		if (initialAmount) {
			change('compensation_amount', initialAmount);
		} else {
			getCalculatedAmount();
		}

		// eslint-disable-next-line
	}, [booking, outerCompensation]);

	return {
		submitButtonLabel,
		invalidButtonLabel,
		sectionName,
		token,
		submitting,
		isLoading,
		isBasePriceRequested,
		isInvalidButtonLoading,
		isBankPaymentMethod,
		isWaitingCustomerInfoStatus,
		bankNameInput,
		bankNameOptions,
		compensationAmountOptions,
		handleOnInvalidButton,
		showInvalidButton,
		onBasePriceSubmit: handleOnBasePriceSubmit,
		onBankNameChange: handleBankNameChange,
	};
};

export default useMakeCompensationFields;
