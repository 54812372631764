import React from 'react';
import { Card, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useLocation } from 'react-router-dom';

// Import utils
import { FormRPC } from 'components/utilities';
import { useEditForm } from './useEditForm';

// Import store
import { fetchBookingsFormResources, submitBookingForm } from 'store/actions';
import { editBookingFormSelector } from 'store/selectors';

// Import components
import { FormContent } from './components';

// Import styles
import { useStyles } from './EditForm.styles';

export const EditForm = () => {
	const classes = useStyles();
	const { errorCallback, links, isRejected } = useEditForm();

	const { hash } = useLocation();

	return (
		<Card
			className={clsx({
				[classes.card]: true,
				[classes.rejectedBookingCard]: isRejected,
			})}
		>
			{isRejected && (
				<Grid
					container
					alignItems="center"
					spacing={3}
					className={classes.headerTabsContainer}
				>
					{links.map(({ label, path }) => (
						<Grid item key={label}>
							<a href={path}>
								<Typography
									variant="body1"
									className={clsx({
										[classes.transferBookingStyle]: true,
										[classes.activeTitle]: hash
											? hash === path
											: path === links[0].path,
									})}
								>
									{label}
								</Typography>
							</a>
						</Grid>
					))}
				</Grid>
			)}

			<FormRPC
				fetchFormResAction={fetchBookingsFormResources}
				submitFormAction={(options) =>
					submitBookingForm({ ...options, errorCallback })
				}
				store={editBookingFormSelector}
				isActionButtonsHidden
				isCardLayout={false}
				goBackPath=""
				title=""
				id="edit-booking-form"
			>
				{() => <FormContent isRejected={isRejected} />}
			</FormRPC>
		</Card>
	);
};
