import { convertFormDates } from 'helpers';

export const initialValues = {
	active: 'all',
	period: null,
	period_to: null,
	created_at: null,
	created_at_to: null,
	created_by: 'all',
};

export const formatValues = (values) => {
	return {
		active: values.active === 'all' ? null : values.active,
		created_by: values.created_by === 'all' ? null : values.created_by,
		...convertFormDates(values, ['period', 'created_at']),
		...values,
	};
};
