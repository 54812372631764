import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useExtendedCoverages = () => {
	const path = '/econtract/extended-coverages';

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CONTRACTS.EXTENDED_COVERAGE,
		async () => await services.get(path),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError };
};
