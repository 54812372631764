// Import helpers
import { FAQ_PAGE_LOCATION, FAQ_SERVICE_TYPE } from 'helpers';

export const convertSubmitValues = (values) => {
	const isCarDetailsSelected =
		values?.faq_location?.includes('all') ||
		values?.faq_location?.includes(FAQ_PAGE_LOCATION.CAR_DETAILS);

	return {
		...values,
		order: +values.order,
		faq_location: values?.faq_location?.includes('all')
			? Object.values(FAQ_PAGE_LOCATION)
			: values.faq_location,
		service_type: !isCarDetailsSelected
			? []
			: values?.service_type?.includes('all')
			? Object.values(FAQ_SERVICE_TYPE)
			: values.service_type,
	};
};

export const getFormattedData = (data = {}) => ({
	...data,
	data: {
		...data?.data,
		image: {
			file_name: '',
			file_path: data?.data?.image || '',
		},
	},
});
