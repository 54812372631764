import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const UpdateIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			id="Path"
			d="M4.5 12.75L3 14.25L1.5 12.75"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_2"
			d="M19.5 11.25L21 9.75L22.5 11.25"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_3"
			d="M19.371 6.845C17.744 4.522 15.052 3 12 3C7.029 3 3 7.029 3 12C3 12.694 3.086 13.366 3.235 14.015"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_4"
			d="M4.62891 17.155C6.25591 19.478 8.94791 21 11.9999 21C16.9709 21 20.9999 16.971 20.9999 12C20.9999 11.306 20.9139 10.634 20.7649 9.98499"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_5"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M15 10.653V15.111C15 15.602 14.602 16 14.111 16H9.889C9.398 16 9 15.602 9 15.111V8.889C9 8.398 9.398 8 9.889 8H12.401C12.642 8 12.872 8.098 13.039 8.27L14.749 10.034C14.91 10.201 15 10.422 15 10.653V10.653Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
