import React from 'react';
import { array } from 'prop-types';
import { Select, MenuItem, Typography, Box, Grid } from '@material-ui/core';
import { Pagination as MuiPagination } from '@material-ui/lab';
import { Trans } from 'react-i18next';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useTableRPCWithQueryProvider } from 'components/utilities/rpc/TableRPCWithQuery/context';

// Import components
import { PaginationItem } from './components';

// Import helpers
import { TABLE_OPTIONS } from 'helpers';

// Import styles
import { useStyles } from './Pagination.styles';

const { ROWS_PER_PAGE } = TABLE_OPTIONS;

export const Pagination = ({ rowsPerPageOptions }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const perPageOptions = rowsPerPageOptions || ROWS_PER_PAGE;

	const {
		currentPageRowsCount,
		recordsFiltered,
		onChangeSize,
		currentPage,
		pagesCount,
		onPaginationChange,
		perPage,
	} = useTableRPCWithQueryProvider();

	return (
		<Grid container justifyContent="space-between" className={classes.wrapper}>
			<Grid item>
				{!!currentPageRowsCount && (
					<Typography component="span" variant="subtitle2">
						<Trans
							i18nKey="table.controllers.info_text"
							currentPageRowsCount={currentPageRowsCount}
							recordsFiltered={recordsFiltered}
						>
							Showing <strong>{{ currentPageRowsCount }} items</strong> out of{' '}
							<strong>{{ recordsFiltered }} results</strong> found
						</Trans>
					</Typography>
				)}
			</Grid>
			<Grid item className={classes.paginationActions}>
				<Box display="flex" gridGap={10} alignItems="center">
					<Typography component="span" variant="subtitle2">
						{t('table.controllers.rows_per_page')}
					</Typography>
					<Select
						className={classes.select}
						value={perPage}
						onChange={onChangeSize}
					>
						{perPageOptions.map((pageSize) => (
							<MenuItem key={pageSize} value={pageSize}>
								<Typography variant="subtitle2">{pageSize}</Typography>
							</MenuItem>
						))}
					</Select>
				</Box>
				<Box>
					<MuiPagination
						count={pagesCount}
						page={currentPage}
						onChange={onPaginationChange}
						color="primary"
						shape="rounded"
						showFirstButton
						showLastButton
						renderItem={(props) => <PaginationItem {...props} />}
					/>
				</Box>
			</Grid>
		</Grid>
	);
};

Pagination.propTypes = {
	rowsPerPageOptions: array,
};
