import React from 'react';
import { func, bool } from 'prop-types';
import {
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Dialog,
	Table,
	DialogContent,
} from '@material-ui/core';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { useCarsLogHistory } from './context';
import { columns } from './columns';

// Import components
import { CarsLogHistoryBody } from './components';
import {
	ClosableDialogTitle,
	LoadingWrapper,
	Alert,
} from 'components/elements';

export const CarsLogHistory = ({ open, onClose }) => {
	const { t } = useTranslations();

	const { isError, error, isFetching } = useCarsLogHistory();

	return (
		<Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
			<ClosableDialogTitle
				title={t('cars.car_logs_history.title')}
				onClose={onClose}
			/>
			<DialogContent>
				{isError && <Alert message={error} />}
				{isFetching ? (
					<LoadingWrapper />
				) : (
					<Table>
						<TableHead>
							<TableRow>
								{columns.map(({ header, accessor }) => (
									<TableCell key={accessor}>
										{header ? t(header) : ''}
									</TableCell>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							<CarsLogHistoryBody />
						</TableBody>
					</Table>
				)}
			</DialogContent>
		</Dialog>
	);
};

CarsLogHistory.propTypes = {
	onClose: func,
	open: bool,
};
