import React from 'react';
import { useSelector } from 'react-redux';
import { bool, object } from 'prop-types';

//Import store
import { previewBookingSelector } from 'store/selectors';

// Import utils and helpers
import { FIELDS_INVOICE } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';
import { LoadingWrapper, AlertMessage } from 'components/elements';
import { ExtensionInvoice } from './components';

// Import styles
import { useStyles } from './CurrentCompanyInvoice.styles';

export const CurrentCompanyInvoice = ({ data, isFetching, isError }) => {
	const booking = useSelector(previewBookingSelector);
	const classes = useStyles();

	if (isFetching) {
		return <LoadingWrapper className={classes.loadingWrapper} />;
	}

	if (isError) {
		return <AlertMessage />;
	}

	const convertedCustomData = {
		...data,
		transferred_at: booking.transferred_at,
		company_name: booking.company.name,
		period: {
			pickDate: booking.period.pick_date,
			dropDate: booking.period.drop_date,
			days: booking.period.days,
		},
		created_at: booking.item.created_at,
	};

	return (
		<>
			<PreviewFields
				customData={convertedCustomData}
				fields={FIELDS_INVOICE(!!booking.transferred_at)}
			/>
			<ExtensionInvoice />
		</>
	);
};

CurrentCompanyInvoice.propTypes = {
	data: object.isRequired,
	isFetching: bool.isRequired,
	isError: bool.isRequired,
};
