export const validation = (values) => {
	let errors = {};

	const { leasing, cars } = values || {};
	const { base_rent } = leasing || {};
	const { m12, m24, m36 } = base_rent?.months || {};
	if (Number(m12) < Number(m24)) {
		errors = {
			...errors,
			leasing: {
				base_rent: {
					months: {
						...(errors?.leasing?.base_rent?.months || {}),
						m24: 'cars.form.leasing.validations.2years',
					},
				},
			},
		};
	}

	if (Number(m24) < Number(m36)) {
		errors = {
			...errors,
			leasing: {
				base_rent: {
					months: {
						...(errors?.leasing?.base_rent?.months || {}),
						m36: 'cars.form.leasing.validations.3years',
					},
				},
			},
		};
	}

	if (cars.length === 0) {
		errors = {
			...errors,
			cars: 'errors.at_least_one_car',
		};
	}

	return errors;
};

export const INITIAL_VALUES = {
	carInfo: {
		details: {
			quantity: '',
			description: {
				ar: '',
				en: '',
			},
		},
		prices: {
			annual: {
				days: '',
			},
			monthly: {
				days: '',
			},
			daily: {
				days: '',
			},
			weekly: {
				days: '',
			},
		},
		vehicle: {
			maker_name: {
				en: '',
				ar: '',
			},
			model_name: {
				en: '',
				ar: '',
			},
			year: null,
			uuid: '',
		},
		options: {
			is_unlimited: false,
			unlimited_nett_price: '',
			free_kms: '',
			extra_km_nett_price: '',
			is_insurance: false,
			insurance_nett_price: '',
			is_no_smoking_car: false,
			no_smoking_prices: {
				daily: {
					nett_price: 0,
				},
				weekly: {
					nett_price: 0,
				},
				monthly: {
					nett_price: 0,
				},
			},
		},
		equipment: {
			navigation: false,
		},
		subscription: {
			is_enabled: false,
			prices: {
				3: {
					gross_price: 0,
				},
				6: {
					gross_price: 0,
				},
				9: {
					gross_price: 0,
				},
				12: {
					gross_price: 0,
				},
			},
		},
	},
	cars: [],
	models: [],
};
