import React from 'react';
import { number, string } from 'prop-types';

// Import components
import { Typography } from 'components/elements';

export const ReceiptIdTableCell = ({
	text,
	color = 'primary',
	fontWeight = 600,
}) => {
	return (
		<Typography fontSize={14} fontWeight={fontWeight} color={color}>
			{text}
		</Typography>
	);
};

ReceiptIdTableCell.propTypes = {
	text: string,
	color: string,
	fontWeight: number,
};
