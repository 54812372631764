import React from 'react';
import { fetchScheduledBookings } from 'store/actions';

// Import columns
import { columns } from './columns';

// Import components
import { TableExportProvider } from 'components/context';
import { TableExport, TableExportPopup } from 'components/elements';
import { ExportFilters } from '../ExportFilters';

export const Export = () => {
	return (
		<TableExportProvider
			columns={columns}
			fetchDataTableAction={fetchScheduledBookings}
		>
			<TableExportPopup />
			<TableExport renderFilters={() => <ExportFilters />} />
		</TableExportProvider>
	);
};
