import React from 'react';
import { Grid } from '@material-ui/core';

// Import utils
import { useFormContent } from './useFormContent';
import { useTranslations } from 'components/utilities';

// Import components
import { Section } from '../../components';
import { Typography } from 'components/elements';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = () => {
	const { fields } = useFormContent();

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Grid container spacing={4} className={classes.container}>
			<Typography className={classes.header}>
				{t('promo_codes.form.add_new_promo_code')}
			</Typography>
			<Section fields={fields} />
		</Grid>
	);
};
