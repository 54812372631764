import React from 'react';
import { useParams } from 'react-router-dom';

// Import Actions and Selectors
import { fetchEmployeePerformanceUsers } from 'store/actions';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { TableBodyRow, Export, Filters } from './components';

// Import utils
import { TableRPC, useProfile } from 'components/utilities';
import { TableActionsProvider } from './context';

export const EmployeePerformanceUsers = () => {
	const { companyUuid: profileCompanyUuid } = useProfile();
	const { id } = useParams();

	const companyUuid = profileCompanyUuid || id;

	return (
		<TableActionsProvider>
			<TableRPC
				columnsAccessor={COLUMNS_ACCESSORS}
				columns={columns}
				tableBodyRow={TableBodyRow}
				fetchDataTableAction={(options) =>
					fetchEmployeePerformanceUsers({
						...options,
						companyUuid,
					})
				}
				title="nav.companies.employee_performance"
				outerToolbarElements={<Export companyUuid={companyUuid} />}
				filtersBar={<Filters />}
			/>
		</TableActionsProvider>
	);
};
