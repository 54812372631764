import React from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { bool } from 'prop-types';

// Import utils
import { useEditBookingPeriod } from './useEditBookingPeriod';
import { useCommonFields, useTranslations } from 'components/utilities';

// Import components
import { ActionConfirmDialog, SuccessDialog } from 'components/elements';
import { OldAndNewDates } from '../OldAndNewDates';

// Import assets
import { HistoryIcon } from 'assets/icons';

// Import styles
import { useStyles } from './EditBookingPeriod.styles';

export const EditBookingPeriod = ({ isRejected = false }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const {
		fields,
		dropoffDate,
		resetPeriod,
		toggleChangePeriodConfirm,
		isChangePeriodConfirmOpen,
		isPeriodValuesChanged,
		submitting,
		isSuccessEditPeriod,
		toggleSuccessEditPeriod,
		handleSuccessOk,
		toggleEditHistory,
		prevDate,
		currentDate,
		isHistory,
	} = useEditBookingPeriod();

	const { renderFields } = useCommonFields();

	return (
		<Grid container spacing={2} id="date-time" className={classes.container}>
			<Typography variant="body1" className={classes.title}>
				{t('common.fields.date_time')}
			</Typography>

			{fields.map(renderFields)}

			<Grid item xs={12}>
				<Grid container spacing={2} className={classes.dropoffDateContainer}>
					<Grid item xs={12}>
						<Typography variant="body1" className={classes.dropoffDate}>
							{t('common.fields.dropoff_date')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography
							variant="body1"
							className={clsx({
								[classes.date]: true,
								[classes.dateChanged]: isPeriodValuesChanged,
							})}
						>
							{dropoffDate}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="body1" className={classes.date}>
							{t('bookings.edit_booking.drop_off_auto_calculated')}
						</Typography>
					</Grid>
				</Grid>
			</Grid>

			<Grid item xs={12} className={classes.actionsContainer}>
				{isHistory && (
					<Button
						onClick={toggleEditHistory}
						color="primary"
						variant="outlined"
						className={classes.historyButton}
						size="large"
						startIcon={<HistoryIcon />}
					>
						{t('common.buttons.edit_history')}
					</Button>
				)}

				{isPeriodValuesChanged && !isRejected && (
					<Grid container justifyContent="flex-end" spacing={3}>
						<Grid item>
							<Button
								onClick={resetPeriod}
								color="default"
								className={classes.clearButton}
								size="large"
							>
								{t('common.buttons.clear')}
							</Button>
						</Grid>
						<Grid item>
							<Button
								color="primary"
								variant="contained"
								type="button"
								onClick={toggleChangePeriodConfirm}
								size="large"
							>
								{t('common.buttons.save')}
							</Button>
						</Grid>
					</Grid>
				)}
			</Grid>

			<ActionConfirmDialog
				isOpen={isChangePeriodConfirmOpen}
				toggle={toggleChangePeriodConfirm}
				title={t('bookings.edit_booking.change_booking_date_time')}
				description={t('bookings.edit_booking.confirm_change_period')}
				formId="edit-booking-form"
				actionButtonText="common.buttons.change"
				actionButtonVariant="contained"
				extraContent={
					<OldAndNewDates
						currentDate={currentDate}
						prevDate={prevDate}
						currentDateTitle={t('common.fields.new_date_and_time')}
						prevDateTitle={t('common.fields.current_date_and_time')}
					/>
				}
				form="edit-booking-form"
				isLoading={submitting}
			/>

			<SuccessDialog
				isOpen={isSuccessEditPeriod}
				toggle={toggleSuccessEditPeriod}
				handleOk={handleSuccessOk}
				title={t('bookings.edit_booking.booking_date_time_changed')}
				description={t('bookings.edit_booking.booking_date_updated')}
			/>
		</Grid>
	);
};

EditBookingPeriod.propTypes = {
	isRejected: bool,
};
