import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { string } from 'prop-types';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './OldAndNewReasons.styles';

export const OldAndNewReasons = ({
	oldReason,
	newReason,
	newReasonTitle,
	validatedByName,
	validatedDate,
}) => {
	const classes = useStyles();

	const { t } = useTranslations();

	return (
		<>
			{oldReason && (
				<Grid item>
					<Typography variant="body1" className={classes.reasonTitle}>
						{t('bookings.preview_booking.reject_reasons.old_reason')}:
					</Typography>
					<Typography variant="body1" className={classes.reason}>
						{oldReason}
					</Typography>
				</Grid>
			)}
			<Grid item>
				<Typography variant="body1" className={classes.reasonTitle}>
					{t(
						newReasonTitle
							? newReasonTitle
							: 'bookings.preview_booking.reject_reasons.new_reason'
					)}
					:
				</Typography>
				<Typography variant="body1" className={classes.reason}>
					{newReason}
				</Typography>
			</Grid>

			{validatedByName && (
				<Grid item>
					<Grid container alignItems="center" justifyContent="space-between">
						<Grid item>
							<Typography variant="body1" className={classes.validation}>
								{t('common.fields.validated_by', { name: validatedByName })}
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="body1" className={classes.validation}>
								{validatedDate}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			)}
		</>
	);
};

OldAndNewReasons.propTypes = {
	newReason: string.isRequired,
	oldReason: string,
	newReasonTitle: string,
	validatedByName: string,
	validatedDate: string,
};
