import React, { createContext, useEffect, useState } from 'react';
import { node } from 'prop-types';
import { useForm, useFormState } from 'react-final-form';
import usePrevious from '@rooks/use-previous';
import { isEqual } from 'lodash';
import { useDebouncedCallback } from 'use-debounce/lib';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Import utilities and helpers
import { useMessage, useToggle, useTranslations } from 'components/utilities';
import { BOOKING_STATES } from 'helpers';
import { useEditBookingHistory } from 'queries';

// Import store
import {
	fetchBookingPreview,
	recalculateBooking,
	submitBookingForm,
} from 'store/actions';
import { previewBookingSelector } from 'store/selectors';

export const EditBookingFormProviderContext = createContext();

export const EditBookingFormProvider = ({ children }) => {
	const { t } = useTranslations();

	const { state, item } = useSelector(previewBookingSelector);

	const { uuid: currentCarUuid } = item || {};

	const isRejected = state === BOOKING_STATES.REJECTED;

	const { message } = useMessage();

	const { on: isChangePeriodConfirmOpen, toggle: toggleChangePeriodConfirm } =
		useToggle();

	const dispatch = useDispatch();

	const { id: itemId } = useParams();

	const { data: initialHistoryValues } = useEditBookingHistory({
		uuid: itemId,
	});

	const { values } = useFormState();

	const { change: changeMainEditForm } = useForm();

	const [isPeriodValuesChanged, setIsPeriodValuesChanged] = useState(false);

	const { on: isEditHistoryOpen, toggle: toggleEditHistory } = useToggle();

	const { on: isSuccessDialogOpen, toggle: toggleSuccessDialog } = useToggle();

	const { on: isConfirmChangeDialogOpen, toggle: toggleConfirmChangeDialog } =
		useToggle();

	const [selectedCar, setSelectedCar] = useState(null);

	const [isSubmitLoading, setSubmitLoading] = useState(false);

	const prevValues = usePrevious(values);

	const debounceRecalculation = useDebouncedCallback(() => {
		recalculateBooking({ values, itemId })(dispatch);
	}, 300);

	useEffect(() => {
		const isValuesEqual = isEqual(prevValues, values);
		const isValuesChanged = !isValuesEqual && !!prevValues;
		if (isValuesChanged) {
			setIsPeriodValuesChanged(isValuesChanged);
			isRejected && debounceRecalculation();
		}
		// eslint-disable-next-line
	}, [values, prevValues, itemId, dispatch, debounceRecalculation]);

	const errorCallback = () => {
		setSubmitLoading(false);
		message.error(t('common.messages.error_message'));
	};

	const callbackSuccess = () => {
		setSubmitLoading(false);
		toggleConfirmChangeDialog();
		toggleSuccessDialog();
	};

	const handleSubmitTransferBooking = async () => {
		setSubmitLoading(true);
		await submitBookingForm({ values, itemId, callbackSuccess, errorCallback })(
			dispatch
		);
	};

	const handleOkAfterSuccess = async () => {
		await fetchBookingPreview({ id: itemId })(dispatch);
	};

	return (
		<EditBookingFormProviderContext.Provider
			value={{
				isChangePeriodConfirmOpen,
				toggleChangePeriodConfirm,
				isPeriodValuesChanged,
				setIsPeriodValuesChanged,
				isEditHistoryOpen,
				toggleEditHistory,
				isConfirmChangeDialogOpen,
				toggleConfirmChangeDialog,
				isSuccessDialogOpen,
				toggleSuccessDialog,
				selectedCar,
				setSelectedCar,
				changeMainEditForm,
				handleSubmitTransferBooking,
				isSubmitLoading,
				handleOkAfterSuccess,
				currentCarUuid,
				initialHistoryValues,
			}}
		>
			{children}
		</EditBookingFormProviderContext.Provider>
	);
};

EditBookingFormProvider.propTypes = {
	children: node,
};
