import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'column',
		width: '100%',
	},
	topBar: {
		display: 'flex',
		justifyContent: 'space-between',
		alignItems: 'center',
		width: '100%',
	},
	settlementBox: {
		display: 'flex',
		alignItems: 'center',
		gap: '8px',
	},
	collapse: {
		width: '100%',
		marginTop: '16px',
	},
	innerCollapseBox: {
		marginBottom: '16px',
	},
	button: {
		backgroundColor: 'none',
		color: theme.palette.text[4],
		padding: 0,
		'&:hover': {
			backgroundColor: theme.palette.white,
		},
	},
}));
