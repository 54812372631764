import React from 'react';
import { useFormState } from 'react-final-form';

// Import components
import {
	MultiManufacturerFilter,
	MultiYearsFilter,
	Grid,
} from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

export const FilterFields = () => {
	const { values } = useFormState();
	const { t } = useTranslations();

	const { manufacturers } = values || {};

	return (
		<Grid container spacing={3}>
			<MultiManufacturerFilter
				label={t('common.fields.car_brands')}
				name="manufacturers"
				xs={6}
				md={6}
			/>
			<MultiYearsFilter
				disabled={!manufacturers || manufacturers === 'all'}
				manufacturerUuid={manufacturers}
				label={t('common.fields.years')}
				name="years"
				xs={6}
				md={6}
			/>
		</Grid>
	);
};
