import React from 'react';

// Import helpers
import { formatValues, initialValues } from './helpers';

// Import components
import { PrevAdditionalFilterFields } from './components';
import { TableFiltersToolbar } from 'components/elements';

export const Filters = () => (
	<TableFiltersToolbar
		initialValues={initialValues}
		formatValues={formatValues}
		prevAdditionalFilters={<PrevAdditionalFilterFields />}
	/>
);
