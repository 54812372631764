import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useRentalCompanies = () => {
	const { data, isError, isFetching, refetch } = useQuery(
		QUERY_KEYS.B2B.RENTAL_COMPANIES,
		async () => await services.get(ENDPOINTS.B2B.RENTAL_COMPANIES),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data : [];

	return { data: fetchedData, isFetching, isError, refetch };
};
