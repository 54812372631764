import { object } from 'prop-types';

// Import helpers
import { reverseAndSplitString } from 'helpers';

export const AmountTableCell = ({ row }) => {
	const { regular_amount: regularAmount } = row || {};

	const [currency, amount] = reverseAndSplitString({ string: regularAmount });

	return `${amount} ${currency}`;
};

AmountTableCell.propTypes = {
	row: object.isRequired,
};
