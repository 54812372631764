import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import helpers and utils
import {
	AVAILABILITY_TABLE_COLUMN_ACCESSORS,
	availabilityTableColumns,
	actionMenuFields,
	getCarData,
} from './helpers';
import { COLUMNS_ACCESSORS } from '../../columns';
import { replaceEditCarListUrl } from 'helpers';

// Import components
import {
	NewActionsMenuTableCell,
	AvailabilityTableCell,
	ImageTableCell,
	TableRow,
} from 'components/elements';

const { IMAGE, AVAILABILITY, ACTIONS } = COLUMNS_ACCESSORS;

export const TableBodyRow = (props) => {
	const { row, isHovered } = props;

	const renderCell = (cell) => {
		const {
			plate_availability: plateAvailability,
			media,
			uuid,
		} = cell.row.original;
		const { id, isHidden } = cell.column;

		if (isHidden) return null;

		switch (id) {
			case IMAGE:
				return <ImageTableCell cell={cell} key={id} imgUrl={media} />;

			case AVAILABILITY:
				return (
					<AvailabilityTableCell
						columnAccessors={AVAILABILITY_TABLE_COLUMN_ACCESSORS}
						carData={getCarData(cell.row.original)}
						columns={availabilityTableColumns}
						availability={plateAvailability}
						key={id}
						tableData={[]}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields(uuid)}
						isHovered={isHovered}
						extraActions={{
							editPath: replaceEditCarListUrl(uuid),
							editAction: true,
						}}
						key={id}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return <TableRow {...props}>{row.cells.map(renderCell)}</TableRow>;
};

TableBodyRow.propTypes = {
	row: object.isRequired,
	isHovered: bool,
};
