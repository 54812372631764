import React, { useState } from 'react';
import { Button, Grid, Typography } from '@material-ui/core';
import { string } from 'prop-types';

// Import utils
import { PaymentProvider } from './components/Payment/context';
import { useTranslations } from 'components/utilities';
import { useSubscriptionPaymentsQuery } from 'queries';

// Import components
import { AlertMessage, LoadingWrapper } from 'components/elements';
import { Payment } from './components';

// Import helpers
import { getCompletePaymentText, getSettledPaymentsSum } from './helpers';

// Import styles
import { useStyles } from './SubscriptionInvoice.styles';

export const SubscriptionInvoice = ({ subscriptionUuid }) => {
	const [allPaymentsShown, setAllPaymentsShow] = useState(false);
	const { t } = useTranslations();
	const classes = useStyles();

	const {
		data: payments,
		isLoading,
		isError,
	} = useSubscriptionPaymentsQuery({
		uuid: subscriptionUuid,
	});

	const completePaymentText = getCompletePaymentText(payments);
	const settledPaymentsSum = getSettledPaymentsSum(payments);
	const convertedPayments = allPaymentsShown ? payments : payments.slice(0, 3);
	const isButtonVisible = payments.length > 3 && !allPaymentsShown;

	if (isLoading) return <LoadingWrapper className={classes.loadingWrapper} />;
	if (isError) return <AlertMessage />;

	return (
		<Grid container direction="column">
			{convertedPayments.map((payment, index) => (
				<Grid item xs key={index}>
					<PaymentProvider
						subscriptionUuid={subscriptionUuid}
						payments={payments}
						payment={payment}
						index={index}
					>
						<Payment
							title={`${t(
								'bookings.preview_booking.subscription_invoice.invoice'
							)} (${index + 1}/${payments.length})`}
						/>
					</PaymentProvider>
				</Grid>
			))}
			{isButtonVisible && (
				<Grid item onClick={() => setAllPaymentsShow(true)}>
					<Button variant="outlined" size="large">
						{t('common.buttons.show_all_months')}
					</Button>
				</Grid>
			)}
			<Grid item>
				<Grid container className={classes.bottomBox}>
					<Grid item>
						<Typography variant="h5">{completePaymentText}</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h5">{settledPaymentsSum}</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

SubscriptionInvoice.propTypes = {
	subscriptionUuid: string,
};
