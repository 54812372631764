export const BUTTONS = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.filters.active',
		status: 'active',
	},
	{
		label: 'common.filters.inactive',
		status: 'inactive',
	},
];
