import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const FlagIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<g id="Interface, Essential/Flag">
			<g id="Group">
				<g id="Group_2">
					<path
						id="Path"
						d="M4.16667 17.5V3.275"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Path_2"
						d="M15.8334 11.6833V3.33333"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Path_3"
						d="M4.16675 11.6667C4.16675 11.6667 4.89591 11.0608 7.08341 11.0608C9.27091 11.0608 10.7292 12.5 12.9167 12.5C15.1042 12.5 15.8334 11.6858 15.8334 11.6858"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
					<path
						id="Path_4"
						d="M4.16675 3.27667C4.16675 3.27667 4.89591 2.5 7.08341 2.5C9.27091 2.5 10.7292 3.93917 12.9167 3.93917C15.1042 3.93917 15.8334 3.33333 15.8334 3.33333"
						stroke="currentColor"
						strokeWidth="1.5"
						strokeLinecap="round"
						strokeLinejoin="round"
					/>
				</g>
			</g>
		</g>
	</SvgIcon>
);
