import React from 'react';
import { bool, func } from 'prop-types';
import { Field } from 'react-final-form';
import {
	CircularProgress,
	MenuItem,
	Grid,
	Box,
	Tooltip,
} from '@material-ui/core';

// Import utils and helpers
import { useValidateCancellationForm } from '../../useValidateCancellationForm';
import { useProfile, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import components
import {
	SelectFieldAdapter,
	ImagePreviewButton,
	ButtonProgress,
} from 'components/elements';

export const FormContent = ({ isLoading, approve }) => {
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const { cancel_reason_uuid, reasons, cancelReasonAttachment, isFetched } =
		useValidateCancellationForm({ lng });

	const { hasReasonChanged } = useFormContent({ cancel_reason_uuid });

	const { token } = useProfile();

	return (
		<Grid
			container
			spacing={1}
			alignItems="center"
			justifyContent="space-between"
		>
			<Grid item xs={12}>
				{isFetched ? (
					<Grid container>
						<Grid item xs={12}>
							<Field
								fullWidth
								component={SelectFieldAdapter}
								label={t(
									'bookings.reject_booking_form.label.cancellation_reason'
								)}
								name="cancellationReason"
								variant="outlined"
								margin="dense"
							>
								{reasons.map(({ uuid, name }) => (
									<MenuItem key={uuid} value={uuid}>
										{name[lng]}
									</MenuItem>
								))}
							</Field>
						</Grid>
					</Grid>
				) : (
					<Box>
						<CircularProgress size={35} />
					</Box>
				)}
			</Grid>
			<Grid container justifyContent="space-between" alignItems="center">
				<Grid item>
					<Grid container spacing={1}>
						<Grid item>
							<ButtonProgress
								circularProgressSize={24}
								isLoading={isLoading}
								disabled={isLoading}
								variant="contained"
								onClick={approve}
								color="primary"
								type="submit"
								size="small"
							>
								{t('bookings.state_actions.approve_cancellation')}
							</ButtonProgress>
						</Grid>
						<Grid item>
							<Tooltip
								title={
									!hasReasonChanged
										? t('validation.table.modal.reject_button_tooltip')
										: ''
								}
							>
								<span>
									<ButtonProgress
										circularProgressSize={24}
										isLoading={isLoading}
										disabled={isLoading || !hasReasonChanged}
										variant="contained"
										color="secondary"
										type="submit"
										size="small"
									>
										{t('bookings.state_actions.reject')}
									</ButtonProgress>
								</span>
							</Tooltip>
						</Grid>
					</Grid>
				</Grid>
				<Grid item>
					{cancelReasonAttachment && (
						<ImagePreviewButton imgUrl={cancelReasonAttachment} token={token} />
					)}
				</Grid>
			</Grid>
		</Grid>
	);
};
FormContent.propTypes = {
	isLoading: bool,
	approve: func,
};
