import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }) => ({
	button: {
		position: 'relative',
		borderRadius: '12px',
		'&.Mui-disabled': {
			background: palette.background[3],
			color: palette.gray.medium,
		},
	},

	primary_filled: {
		backgroundColor: palette.primary.main,
		color: palette.white,
		'&:hover': {
			backgroundColor: palette.primary.dark,
		},
	},
	white_filled: {
		backgroundColor: palette.white,
		color: palette.text[1],
		border: '1px solid',
		borderColor: palette.background[5],
	},
	outlined_and_primary: {
		backgroundColor: 'transparent',
		border: '1px solid',
		borderColor: palette.background[5],
		color: palette.primary.main,
	},
	outlined_and_dark: {
		backgroundColor: 'transparent',
		color: palette.text[1],
		border: '1px solid',
		borderColor: palette.background[5],
	},
	primary_text: {
		backgroundColor: 'transparent',
		color: palette.primary.main,
	},
	dark_text: {
		backgroundColor: 'transparent',
		color: palette.text[2],
	},
	error: {
		backgroundColor: 'transparent',
		color: palette.error.medium,
	},

	large: {
		minHeight: '48px',
		maxHeight: '48px',
		padding: '0 24px',
	},
	medium: {
		minHeight: '40px',
		maxHeight: '40px',
		padding: '0 20px',
	},
	small: {
		minHeight: '36px',
		maxHeight: '36px',
		padding: '0 16px',
	},
	tiny: {
		minHeight: '28px',
		maxHeight: '28px',
		padding: '0 12px',
		borderRadius: '8px',
	},

	large_icon: {
		height: '24px',
		width: '24px',
	},
	medium_icon: {
		height: '20px',
		width: '20px',
	},
	small_icon: {
		height: '16px',
		width: '16px',
	},
	tiny_icon: {
		height: '12px',
		width: '12px',
	},

	circularProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		color: palette.text[1],
	},
	circularProgressIndeterminate: {
		animation: 'none',
	},
}));
