import { colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DIMENSIONS } from 'helpers';

const { sideMenuDrawerOpened, sideMenuDrawerClosed } = DIMENSIONS;

export const useStyles = makeStyles((theme) => ({
	drawer: {
		width: sideMenuDrawerOpened,
		flexShrink: 0,
		whiteSpace: 'nowrap',
	},
	drawerOpen: {
		width: sideMenuDrawerOpened,
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create('width', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: 'hidden',
		width: sideMenuDrawerClosed,
	},
	root: {
		display: 'flex',
		flexDirection: 'column',
		height: '100%',
		backgroundColor: theme.palette.white,
		padding: theme.spacing(0),
	},
	nav: {
		marginBottom: theme.spacing(2),
	},
	logoWrapper: {
		marginLeft: 34,
	},
	collapseButton: {
		marginBottom: theme.spacing(2),
	},
	icon: {
		color: colors.blueGrey[400],
		width: 24,
		height: 24,
		display: 'flex',
		alignItems: 'center',
		marginRight: 20,
	},
	button: {
		color: colors.blueGrey[400],
		padding: '18px 34px',
		justifyContent: 'flex-start',
		textTransform: 'none',
		letterSpacing: 0,
		width: '100%',
		fontWeight: '500',
		textAlign: 'left',
	},
}));
