import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	CompensationStatusFilter,
	MultiCompaniesFilter,
	UrgentStatusFilter,
	DateRangeFilter,
} from 'components/elements';

export const FilterFields = () => (
	<Grid container spacing={3} alignItems="flex-end">
		<MultiCompaniesFilter
			name="company_uuid"
			label="common.fields.company"
			xs={12}
			md={6}
		/>
		<CompensationStatusFilter
			name="compensation_status"
			label="waiting_customer_info.filters.compensation_status"
			md={3}
			xs={6}
		/>
		<UrgentStatusFilter
			name="urgent_assistance_status"
			label="waiting_customer_info.filters.urgent_status"
			md={3}
			xs={6}
		/>
		<DateRangeFilter
			nameFrom="pick_date"
			nameUntil="pick_date_to"
			label="bookings.filters.pick_date"
			combinedInputs
		/>
		<DateRangeFilter
			nameFrom="drop_date"
			nameUntil="drop_date_to"
			label="bookings.filters.drop_date"
			combinedInputs
		/>
		<DateRangeFilter
			nameFrom="compensation_created_at_date"
			nameUntil="compensation_created_at_date_to"
			label="common.fields.assigned_date"
			combinedInputs
		/>
	</Grid>
);
