import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	dialog: {
		'& .MuiDialog-paper': {
			width: '60vw',
		},
	},
	title: {
		fontSize: '18px',
		fontWeight: '600',
		color: 'text.4',
	},
}));
