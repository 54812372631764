import React from 'react';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components';

// Import utils and helpers
import { useTabsProvider } from 'components/context';
import { RATING_FIELDS } from './helpers';

export const Rating = () => {
	const { data: booking } = useTabsProvider();

	const customData = booking?.ratingSummary;

	return <PreviewFields fields={RATING_FIELDS} customData={customData} />;
};
