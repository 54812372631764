export default {
	root: {
		transform: 'scale(0.9) !important',
		fontWeight: 500,
		letterSpacing: 0.3,
		lineHeight: '16px',

		'& .MuiInputLabel-formControl': {
			position: 'relative',
		},

		'@media (max-width: 960px)': {
			color: '#2F353B',
			lineHeight: '28px',
			fontWeight: 600,
		},
	},
};
