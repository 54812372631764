import { useState } from 'react';
import { queryCache } from 'react-query';

// Import actions
import { updateDriverLicense } from 'store/actions/customers/driver_license_table';

// Import helpers
import { convertApiFormErrors, QUERY_KEYS } from 'helpers';

// Import utils
import { useTableContextProvider } from 'components/context';

export const useDriverForm = ({ customerUuid, toggle }) => {
	const [isLoading, setIsLoading] = useState(false);

	const { fetchData } = useTableContextProvider();

	const handleOnSubmit = async (values) => {
		let apiErrors = {};

		const options = {
			values,
			customerUuid,
			successCallback: async (licenseUuid) => {
				await fetchData();
				await queryCache.refetchQueries(
					QUERY_KEYS.CUSTOMERS.DRIVER_LICENSE(licenseUuid)
				);
				setIsLoading(false);
				toggle();
			},
			errorCallback: (errors) => {
				apiErrors = convertApiFormErrors(errors);
				setIsLoading(false);
			},
		};

		setIsLoading(true);

		await updateDriverLicense(options);

		return apiErrors;
	};

	return {
		handleOnSubmit,
		isLoading,
	};
};
