// Import utilities and helpers
import { useProfile, useTranslations } from 'components/utilities';
import {
	BOOKING_EXTENSION_STATUS,
	numberWithCommas,
	PAYMENT_METHODS,
	METHOD_TYPES,
	CARD_SCHEME,
	VERIFY_TYPES,
	BOOKING_SOURCE,
	CUSTOMER_TYPES,
} from 'helpers';
import { getCustomerOrganizationLabel } from './helpers';

const { ACCEPTED, PENDING } = BOOKING_EXTENSION_STATUS;
const { APPLE_PAY, TELGANI_PAY } = METHOD_TYPES;
const { MASTER_CARD, VISA } = CARD_SCHEME;
const { WALLET, CARD } = PAYMENT_METHODS;

export const useNoteStates = (data) => {
	const { isAdmin } = useProfile();
	const { t } = useTranslations();

	const {
		admin_edit_booking_gross_price_diff: adminPriceDifference,
		customer_edit_booking_gross_price_diff: customerPriceDifference,
		total_discounted_gross_price: totalDiscountedGrossPrice,
		customer_edited_booking: isEditedByCustomer,
		customer_organization: customerOrganization,
		admin_edited_booking: isEditedByAdmin,
		corporate_name: corporateName,
		customer_verify_type,
		customer_type,
		is_subscription: isSubscription,
		is_external: isExternal,
		is_discount: isDiscount,
		is_delivery: isDelivery,
		payment,
		delivery,
		source,
		extension,
		external_partner: externalPartner,
		is_created_by_agent: isAgent,
	} = data;

	const { method, method_type, card_scheme } = payment || {};

	const {
		extension_amount_gross: extendedPrice,
		extension_state: extendedStatus,
	} = extension || {};

	const customerPriceDiff = +customerPriceDifference;
	const adminPriceDiff = +adminPriceDifference;

	const getAmount = (amount) => Math.abs(amount);
	const isNegativeDifference = (diff) => diff < 0;
	const convertDifference = (diff) =>
		(isNegativeDifference(diff) ? '' : '+') + numberWithCommas(diff);

	const convertedAdminPriceDiff = convertDifference(adminPriceDiff);
	const isPriceDifference = !!adminPriceDiff && isEditedByAdmin;
	const adminPriceDifferenceTooltip = t(
		`bookings.table.notes.${
			isNegativeDifference(adminPriceDiff) ? 'negative' : 'positive'
		}_price_difference`,
		{ amount: numberWithCommas(getAmount(adminPriceDiff)) }
	);

	const convertedCustomerPriceDiff = convertDifference(customerPriceDiff);
	const isChangedLabel = isEditedByCustomer;
	const isChangedPriceDifference =
		!isNaN(getAmount(customerPriceDiff)) && getAmount(customerPriceDiff) !== 0;
	const changedLabel = `${t('bookings.table.notes.changed')} ${
		isChangedPriceDifference ? convertedCustomerPriceDiff : ''
	}`;

	const isExtendedLabel =
		extendedStatus === ACCEPTED || extendedStatus === PENDING;
	const isExtendedPrice = extendedPrice && extendedPrice !== 0;
	const extendedLabel = `${t('bookings.table.notes.extended')} ${
		isExtendedPrice ? `+${extendedPrice}` : ''
	}`;

	const pickupDelivery = delivery?.pick_up_delivery;
	const dropDelivery = delivery?.drop_up_delivery;
	const isDeliveryLateAssignment =
		pickupDelivery?.has_late_assigment || dropDelivery?.has_late_assigment;
	const isDeliveryLate =
		pickupDelivery?.has_late_delivery || dropDelivery?.has_late_delivery;
	const isDriverAssigned =
		pickupDelivery?.is_available || dropDelivery?.is_available;
	const isPickupDelivery = pickupDelivery?.has_driver_assigned;
	const isDropDelivery = dropDelivery?.has_driver_assigned;
	const isPaidOnline =
		(payment?.method === WALLET || payment?.method === CARD) && !isAdmin;
	const isCustomerLocation = delivery?.latitude || delivery?.lat;
	const isElm = customer_verify_type === VERIFY_TYPES.ELM;
	const isKiosk = source === BOOKING_SOURCE.KIOSK;
	const isVIP = customer_type === CUSTOMER_TYPES.TRUSTED;
	const isTelganiPay = method_type === TELGANI_PAY;
	const isMasterCard = card_scheme === MASTER_CARD;
	const isApplePay = method_type === APPLE_PAY;
	const isVisa = card_scheme === VISA;
	const isCard = method === CARD;
	const isB2B = null != corporateName;
	const isExternalPartern = !!externalPartner;

	const paymentForPartnersTooltip = t(
		'bookings.table.notes.payment_for_partners',
		{
			amount: totalDiscountedGrossPrice,
		}
	);

	const customerOrganizationLabel =
		getCustomerOrganizationLabel(customerOrganization);

	return {
		adminPriceDifference: convertedAdminPriceDiff,
		adminPriceDifferenceTooltip,
		paymentForPartnersTooltip,
		customer_verify_type,
		externalPartner,
		customer_type,
		corporateName,
		extendedLabel,
		changedLabel,
		delivery,
		source,
		payment,
		isDeliveryLateAssignment,
		customerOrganizationLabel,
		customerOrganization,
		isNegativeDifference,
		isCustomerLocation,
		isPriceDifference,
		isExternalPartern,
		isPickupDelivery,
		isDriverAssigned,
		isExtendedLabel,
		isChangedLabel,
		isDeliveryLate,
		isDropDelivery,
		isPaidOnline,
		isSubscription,
		isExternal,
		isDiscount,
		isDelivery,
		isTelganiPay,
		isMasterCard,
		isApplePay,
		isKiosk,
		isVisa,
		isCard,
		isElm,
		isVIP,
		isB2B,
		isAgent,
	};
};
