import { useQuery } from 'react-query';
import { CAMPAIGN_TYPES, ENDPOINTS } from 'helpers';

import { QUERY_KEYS } from 'helpers';
import services from 'services/services';

export const useCampaignCustomers = ({ campaignUuid, campaignType }) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CAMPAIGNS.CUSTOMERS(campaignUuid),
		async () =>
			await services.post(ENDPOINTS.CAMPAIGNS.CUSTOMERS({ campaignUuid })),
		{
			refetchOnWindowFocus: false,
			staleTime: 60 * 1000,
			enabled: campaignType === CAMPAIGN_TYPES.CUSTOMER_REGISTERED,
		}
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, isError };
};
