import React from 'react';
import { Box, Button } from '@material-ui/core';
import { object } from 'prop-types';
import clsx from 'clsx';

// Import utils and helpers
import { useNotes } from './useNotes';
import { SIZE_TYPE } from './helpers';

// Import styles
import { useStyles } from './Notes.styles';

export const Notes = ({ cell }) => {
	const {
		isToggleButtonVisible,
		handleOnToggleButton,
		toggleButtonTitle,
		notes,
	} = useNotes(cell);

	const classes = useStyles();

	if (!notes.length) return null;

	return (
		<Box className={classes.container}>
			<Box className={classes.notesContainer}>
				{notes.map(({ component, sizeType }, index) => (
					<Box
						key={index}
						className={clsx({
							[classes.note]: true,
							[classes.squareNote]: sizeType === SIZE_TYPE.SQUARE,
						})}
					>
						{component}
					</Box>
				))}
			</Box>
			<Box>
				{isToggleButtonVisible && (
					<Button color="primary" onClick={handleOnToggleButton}>
						{toggleButtonTitle}
					</Button>
				)}
			</Box>
		</Box>
	);
};

Notes.propTypes = {
	cell: object,
};
