import services from 'services/services';
import {
	fetchEmployeePerformanceUsersUrl,
	updateEmployeePerformanceUserUrl,
	fetchEmployeePerformanceBookingsUrl,
} from 'store/paths';

// Import helpers
import {
	combineQueryAndPaginationParams,
	DEFAULT_DATE_FORMAT,
	firstDateOfMonth,
	lastDateOfMonth,
} from 'helpers';
import { format } from 'date-fns';

export const fetchEmployeePerformanceBookings =
	({ options, queryParams, cancelToken, itemId, officeManagerUuid }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;

			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const params = combineQueryAndPaginationParams({ queryParams, body });

			// Get response
			const uuid = officeManagerUuid || itemId;
			const { data } = await services.get(
				fetchEmployeePerformanceBookingsUrl(params, uuid),
				cancelToken
			);
			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const fetchEmployeePerformanceUsers =
	({ options, queryParams, cancelToken, companyUuid }) =>
	async () => {
		try {
			const { pageIndex, pageSize, isExport } = options;
			const body = {
				start: pageIndex * pageSize,
				length: pageSize,
				is_export: isExport,
			};

			const defaultQueryParams =
				queryParams ||
				`?columns[6][search][value]=["${format(
					new Date(firstDateOfMonth),
					DEFAULT_DATE_FORMAT
				)}","${lastDateOfMonth}"]`;

			const params = combineQueryAndPaginationParams({
				queryParams: defaultQueryParams,
				body,
			});

			const { data } = await services.get(
				fetchEmployeePerformanceUsersUrl(params, companyUuid),
				cancelToken
			);
			return Promise.resolve({ data });
		} catch (error) {
			return Promise.reject(error);
		}
	};

export const updateActiveEmployeePerformanceUser =
	({ isActive, id }) =>
	async () => {
		await services.patch(updateEmployeePerformanceUserUrl(id, isActive));
	};
