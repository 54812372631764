import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import { useFormState } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';
import { refreshHolidays } from 'store/actions/helpers';
import { OFFICE_FORM_REFRESH_HOLIDAYS } from 'store/types';

// Import helpers
import { fields } from './helpers';

const BaseDetails = (props) => {
	const { className, section, ...rest } = props;
	const { submitting, values, modified } = useFormState();
	const { prefixes, companyList } = useSelector((store) => store.offices.form);

	const { t } = useTranslations();
	const dispatch = useDispatch();

	useEffect(() => {
		if (modified['company.uuid']) {
			refreshHolidays(
				OFFICE_FORM_REFRESH_HOLIDAYS,
				values?.company?.uuid,
				dispatch
			);
		}
		// eslint-disable-next-line
	}, [values?.company?.uuid]);

	return (
		<Card {...rest} className={className}>
			<CardHeader title={t('offices.form.details.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{fields(section, prefixes, companyList, values.showCompanySelect).map(
						(field) =>
							field.type === 'select' ? (
								<Grid key={field.name} item md={field.md} xs={field.xs}>
									<Field
										fullWidth
										component={field.component}
										type={field.type}
										label={t(field.label)}
										name={field.name}
										margin="dense"
										variant="outlined"
										disabled={submitting}
										required
									>
										{field.children}
									</Field>
								</Grid>
							) : (
								<Grid key={field.name} item md={field.md} xs={field.xs}>
									<Field
										fullWidth
										component={field.component}
										type={field.type}
										label={t(field.label)}
										name={field.name}
										margin="dense"
										variant="outlined"
										disabled={submitting}
										inputProps={{ min: 0 }}
										required
									/>
								</Grid>
							)
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

BaseDetails.propTypes = {
	className: PropTypes.string,
	section: PropTypes.string.isRequired,
};

export default BaseDetails;
