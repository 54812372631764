import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: '70%',
	},
	buttonsWrapper: {
		display: 'flex',
		justifyContent: 'flex-end',
		gap: '16px',
	},
	ratedByText: {
		color: theme.palette.text[1],
		fontWeight: '400',
	},
	deleteLabel: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
		borderRadius: '100px',
		padding: '6px 20px',
		fontSize: '12px',
		fontWeight: 600,
		marginTop: '16px',
	},
	ratingBox: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		padding: '16px 0',
	},
	description: {
		fontSize: '14px',
		fontWeight: 300,
		color: theme.palette.text[1],
	},
	ratingReason: {
		fontSize: '14px',
		fontWeight: 600,
		color: theme.palette.text[1],
		textTransform: 'capitalize',
	},
	author: {
		color: theme.palette.text[4],
	},
	resetStepIcon: {
		borderColor: theme.palette.error.medium,
	},
	ratedStepIcon: {
		borderColor: theme.palette.success.medium,
	},
	label: {
		fontSize: '12px',
		fontWeight: 400,
		color: theme.palette.text.inputLabel,
	},
}));
