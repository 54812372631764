import { useParams } from 'react-router-dom';

// Import helpers
import {
	ACTION_MENU_POPUP_FIELD_TYPES,
	replaceEditOfficeCarUrl,
} from 'helpers';
import {
	AVAILABILITY_TABLE_COLUMN_ACCESSORS,
	availabilityTableColumns,
	getCarData,
} from './helpers';

// Import assets
import { BaseEditPenIcon } from 'assets/icons';

// Import store
import { updateActiveCar } from 'store/actions';

const { ACTIVE, REDIRECT, CARS_LOG_HISTORY, CAR_AVAILABILITY } =
	ACTION_MENU_POPUP_FIELD_TYPES;

export const useCell = ({ cell }) => {
	const { id: officeId } = useParams();
	const { active: isActive, uuid: carId } = cell.row.original;

	const handleOnActive = () => updateActiveCar({ isActive, id: carId });

	const editUrl = replaceEditOfficeCarUrl({ officeId, carId });

	const carAvailability = {
		columnAccessors: AVAILABILITY_TABLE_COLUMN_ACCESSORS,
		carData: getCarData(cell.row.original),
		columns: availabilityTableColumns,
		tableData: [],
	};

	const actionMenuFields = [
		{
			type: ACTIVE,
			activeAction: handleOnActive,
			activeState: isActive,
			isDivider: true,
		},
		{
			type: REDIRECT,
			redirectText: 'common.buttons.edit_car_info',
			redirectUrl: editUrl,
			redirectIcon: BaseEditPenIcon,
		},
		{
			type: CAR_AVAILABILITY,
		},
		{
			type: CARS_LOG_HISTORY,
		},
	];

	return {
		actionMenuFields,
		carAvailability,
		editUrl,
	};
};
