import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	tabledHead: {
		backgroundColor: theme.palette.white,
	},
	table: {
		borderRadius: '12px',
	},
	header: {
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: '400',
		color: theme.palette.text[1],
	},
}));
