import React from 'react';
import { array, bool, func } from 'prop-types';
import {
	Card,
	CardContent,
	CardHeader,
	Divider,
	Grid,
} from '@material-ui/core';

// Import helpers and utils
import { useCommonFields, useTranslations } from 'components/utilities';
import { FIELDS } from './helpers';

export const CampaignSupplierCard = ({
	companies,
	offices,
	selectedCompanies,
	selectedOffices,
	isFetching,
	onCompanySelectClose,
}) => {
	const { t } = useTranslations();
	const { renderFields } = useCommonFields();

	const isOfficesSelectDisabled = Object.keys(offices).length === 0;

	return (
		<Card>
			<CardHeader title={t('campaigns.form.supplier_card_title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={3}>
					{FIELDS({
						companies,
						selectedCompanies,
						selectedOffices,
						offices,
						isOfficesSelectDisabled,
						onCompanySelectClose,
						isLoading: isFetching,
					}).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};

CampaignSupplierCard.propTypes = {
	companies: array.isRequired,
	offices: array.isRequired,
	selectedCompanies: array.isRequired,
	selectedOffices: array.isRequired,
	isFetching: bool,
	onCompanySelectClose: func.isRequired,
};
