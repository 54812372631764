import palette from 'theme/palette';

export const capitalize = (text) =>
	text.charAt(0).toUpperCase() + text.slice(1);

export const splitText = (text, separator) => text?.split(separator);

export const TYPOGRAPHY_COLORS = {
	BASIC: palette.text[4],
};
