import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	red: {
		color: theme.palette.error.dark,
	},
	green: {
		color: theme.palette.primary.main,
	},
	grey: {
		color: theme.palette.gray.main,
	},
}));
