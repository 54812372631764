import React from 'react';
import { Grid } from '@material-ui/core';

// Import utils
import { useCommonFields } from 'components/utilities';
import { useFormContent } from './useFormContent';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = () => {
	const classes = useStyles();
	const { renderFields } = useCommonFields();

	const { SELECT_CAR_FILTERS_FIELDS } = useFormContent();

	return (
		<Grid container className={classes.container}>
			{SELECT_CAR_FILTERS_FIELDS.map(renderFields)}
		</Grid>
	);
};
