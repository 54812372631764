import React from 'react';
import { func } from 'prop-types';
import { Box } from '@material-ui/core';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useStyles } from './FormContent.styles';
import { useFormContent } from './useFormContent';

// import assets
import { PaperPin } from 'assets/icons';

// Import components
import { CustomSwitch, Typography, Button, Grid } from 'components/elements';
import { DynamicField } from './components';

export const FormContent = ({ toggle }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();

	const {
		FILE_FIELD,
		EXTRA_FIELDS,
		TOTAL_FIELD,
		extraFieldsVisible,
		handleSwitchChange,
		isCloseBtnDisabled,
		SwitchHelperText,
		submitting,
	} = useFormContent();
	const classes = useStyles();

	return (
		<Grid container>
			<Box className={classes.uploadWrapper}>
				{renderFields(FILE_FIELD)}
				<PaperPin className={classes.icon} />
			</Box>
			<Box className={classes.switchContainer}>
				<CustomSwitch
					onChange={handleSwitchChange}
					className={classes.switch}
				/>
				<Box>
					<Typography className={classes.switchHeader}>
						{t('return_page.add_extra_charges')}{' '}
						<Typography component="span" className={classes.optional}>
							({t('return_page.optional')})
						</Typography>
					</Typography>
					<Typography className={classes.switchText}>
						{SwitchHelperText}
					</Typography>
				</Box>
			</Box>
			{extraFieldsVisible && (
				<Box className={classes.dynamicContent}>
					{EXTRA_FIELDS.map((field) => (
						<DynamicField {...field} key={field.label} />
					))}
					<Box className={classes.totalFieldWrapper}>
						{renderFields(TOTAL_FIELD)}
					</Box>
				</Box>
			)}
			<Box className={classes.btnsContainer}>
				<Button className={classes.cancel} onClick={toggle}>
					{t('common.buttons.cancel')}
				</Button>
				<Button
					className={classes.close}
					buttonType="submit"
					text="return_page.close_contract"
					disabled={isCloseBtnDisabled}
					isLoading={submitting}
				/>
			</Box>
		</Grid>
	);
};

FormContent.propTypes = {
	toggle: func.isRequired,
};
