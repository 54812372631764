/* eslint-disable no-mixed-spaces-and-tabs */
import i18next from 'i18next';
import { format } from 'date-fns';

// import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

const sectionName = 'customer_balance.table.headers';

export const columns = [
	{
		Header: `${sectionName}.customer`,
		accessor: 'customer',
		valueExtractor: ({ customer_name, customer_phone }) =>
			`${customer_name} ${customer_phone}`,
	},
	{
		Header: `${sectionName}.booking_id`,
		accessor: 'booking_individual_number',
	},
	{
		Header: `common.fields.pickup_date`,
		accessor: 'pickup_date',
	},
	{
		Header: `common.fields.dropoff_date`,
		accessor: 'dropoff_date',
		valueExtractor: ({ dropoff_date }) =>
			format(new Date(dropoff_date), DEFAULT_DATE_FORMAT),
	},
	{
		Header: `common.fields.late_payment`,
		accessor: 'late_payment',
	},
	{
		Header: `common.fields.due_date`,
		accessor: 'due_date',
		valueExtractor: ({ due_date }) =>
			format(new Date(due_date), DEFAULT_DATE_FORMAT),
	},
	{
		Header: `common.fields.status`,
		accessor: 'booking_status',
		valueExtractor: ({ booking_status: status }) =>
			i18next.t(`bookings.booking_states.${status}`),
	},
	{
		Header: `${sectionName}.company`,
		accessor: 'company_name',
	},
];
