import React from 'react';

// Import assets
import { CopyIcon } from 'assets/icons';

export const getCopyActions = ({ individualNumber, uuid }) => [
	{
		value: individualNumber,
		buttonText: 'copy_id',
		startIcon: <CopyIcon />,
		endIcon: <></>,
		color: 'primary',
	},
	{
		value: uuid,
		buttonText: 'copy_uuid',
		startIcon: <CopyIcon />,
		endIcon: <></>,
		color: 'primary',
	},
];
