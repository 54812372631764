import React from 'react';
import { Form } from 'react-final-form';

// Import components
import { QuickFinderDialog } from '../QuickFinderDialog';

// Import helpers and context
import { INITIAL_VALUES } from './helpers';
import { QuickFinderProvider } from '../../context';

export const QuickFinderForm = () => {
	return (
		<Form
			onSubmit={() => {}}
			initialValues={INITIAL_VALUES}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit} noValidate>
					<QuickFinderProvider>
						<QuickFinderDialog />
					</QuickFinderProvider>
				</form>
			)}
		/>
	);
};
