import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	groupTitle: {
		fontSize: '18px',
		fontWeight: '500',
	},
	submitButton: {
		backgroundColor: theme.palette.background[3],
		color: theme.palette.gray.medium,
		borderRadius: '8px',
		padding: '10px 24px',
	},
	clearAllButton: {
		padding: '10px 24px',
	},
}));
