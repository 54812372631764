import { t } from 'i18next';

export const SUMMARY_FIELDS = [
	{
		accessor: 'service',
		label: 'common.fields.service',
	},
	{
		accessor: 'customer_name',
		label: 'common.fields.customer',
	},
	{
		accessor: 'pick_date',
		label: 'common.fields.pick_up_date',
	},
	{
		accessor: 'pick_time',
		label: 'common.fields.pick_up_time',
	},
	{
		accessor: 'number_of_days',
		label: 'common.fields.period',
	},
	{
		accessor: 'vehicle',
		label: 'common.fields.car',
	},
	{
		accessor: 'price',
		label: 'common.fields.price',
	},
	{
		accessor: 'company_name',
		label: 'common.fields.company',
	},
	{
		accessor: 'office',
		label: 'common.fields.office',
	},
	{
		accessor: 'paymentMethod',
		label: 'common.fields.payment_method',
	},
	{
		accessor: 'noAcceptedBookings',
		label: 'common.fields.no_accepted_bookings',
	},
	{
		accessor: 'noCancelledBooking',
		label: 'common.fields.no_cancelled_booking',
	},
	{
		accessor: 'noCompensated',
		label: 'common.fields.no_compensated',
	},
];

const getService = ({ booking }) => {
	const delivery = booking?.delivery;
	const isPickUpDelivery = delivery?.pick_up_delivery?.is_available;
	const isDropDelivery = delivery?.drop_delivery?.is_available;

	const pickupDeliveryTrans = t('common.fields.delivery');
	const dropDeliveryTrans = t('common.fields.return');

	if (isPickUpDelivery && isDropDelivery) {
		return pickupDeliveryTrans + ' & ' + dropDeliveryTrans;
	} else if (isPickUpDelivery && !isDropDelivery) {
		return pickupDeliveryTrans;
	} else if (!isPickUpDelivery && isDropDelivery) {
		return dropDeliveryTrans;
	} else {
		return '-';
	}
};

const getPrice = ({ booking }) => {
	const { subscription_uuid: isSubscription, price_per_period: price } =
		booking || {};

	return `${price} / ${
		isSubscription ? t('common.monthly') : t('common.daily')
	}`;
};

export const getSummaryCustomData = ({ booking }) => {
	const service = getService({ booking });
	const price = getPrice({ booking });

	return {
		...booking,
		service,
		price,
		office: `${booking?.office_name} - ${booking?.office_city}`,
		paymentMethod: t(`common.payment_methods.${booking?.payment?.method}`),
		noAcceptedBookings: booking?.customerSummary?.accepted_bookings_count,
		noCancelledBooking: booking?.customerSummary?.cancelled_bookings_count,
		noCompensated: booking?.customerSummary?.compensated_bookings_count,
	};
};
