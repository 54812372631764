import React from 'react';
import { Form } from 'react-final-form';
import { CardContent, CardHeader, Typography, Card } from '@material-ui/core';

// Import helpers
import { initialValues } from './helpers';

// Import utils
import { useValidateCancellationForm } from './useValidateCancellationForm';
import { useTranslations } from 'components/utilities';

// Import components
import { Alert } from 'components/elements';
import { FormContent } from './components';

export const ValidateCancellationForm = () => {
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const {
		handleValidateCancellation,
		cancel_reason_uuid,
		isSubmitted,
		isError,
		isLoading,
		error,
		approve,
	} = useValidateCancellationForm({ lng });

	if (isSubmitted) {
		return (
			<Typography>
				{t('validation.table.modal.cancellation_validated')}
			</Typography>
		);
	}

	if (isError) {
		return <Alert message={error.message} />;
	}

	return (
		<Card>
			<CardHeader title={t('validation.table.modal.cancellation_title')} />
			<CardContent>
				<Form
					onSubmit={handleValidateCancellation}
					initialValues={initialValues(cancel_reason_uuid)}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormContent isLoading={isLoading} approve={approve} />
						</form>
					)}
				/>
			</CardContent>
		</Card>
	);
};
