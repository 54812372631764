import React from 'react';
import { Box } from '@material-ui/core';

// import styles
import { useStyles } from './BottomActions.styles';

// Import components
import { Button } from 'components/elements';
import { SubmitConfirmationDialog } from './components';

// Import utils and helpers
import { useBottomActions } from './useBottomActions';
import { useTranslations } from 'components/utilities';

export const BottomActions = () => {
	const classes = useStyles();
	const {
		isStepCreate,
		changeStepToCreate,
		changeStepToReview,
		continueBtnDisabled,
		confirmationDialogOpen,
		toggleConfirmationDialog,
	} = useBottomActions();

	const { t } = useTranslations();

	return (
		<Box className={classes.container}>
			{isStepCreate && (
				<Button
					text={t('common.buttons.continue')}
					onClick={changeStepToReview}
					disabled={continueBtnDisabled}
					size="large"
				/>
			)}

			{!isStepCreate && (
				<>
					<Button
						text={t('common.buttons.edit')}
						type="dark_text"
						onClick={changeStepToCreate}
						size="large"
					/>
					<Button
						text={t('bookings.create_booking.buttons.submit_booking')}
						onClick={toggleConfirmationDialog}
						size="large"
					/>
					<SubmitConfirmationDialog
						open={confirmationDialogOpen}
						onClose={toggleConfirmationDialog}
					/>
				</>
			)}
		</Box>
	);
};
