import { useForm, useFormState } from 'react-final-form';

// import assets
import { PaperPin } from 'assets/icons';

// Import utilities and helpers
import { FIELD_TYPES } from 'helpers';
import { transPrefix } from 'views/Bookings/CreateBooking/helpers';
import { debouncedGetCustomer } from './helpers';
import { useMessage } from 'components/utilities';

// Import styles
import { useStyles } from './CustomerDetails.styles';

export const useCustomerDetails = () => {
	const { message } = useMessage();
	const classes = useStyles();
	const { change } = useForm();
	const {
		values: {
			customerDetails: { isNew },
		},
	} = useFormState();

	const today = new Date();
	const eighteenYearsAgo = new Date();
	eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

	const FIELDS = [
		{
			type: FIELD_TYPES.NUMBER,
			name: 'customerDetails.phone_number',
			inputLabel: `${transPrefix}.phone_number`,
			placeholder: `${transPrefix}.enter_phone_number`,
			onChange: (e) =>
				debouncedGetCustomer({
					phone: e.target.value,
					change,
					message,
				}),
			md: 6,
		},
		{
			type: FIELD_TYPES.NUMBER,
			name: 'customerDetails.license_number',
			inputLabel: `${transPrefix}.license_number`,
			placeholder: `${transPrefix}.enter_license_number`,
			disabled: !isNew,
			md: 6,
		},
		{
			type: FIELD_TYPES.TEXT,
			name: 'customerDetails.full_name',
			inputLabel: `${transPrefix}.full_name_english`,
			placeholder: `${transPrefix}.enter_english_name`,
			disabled: !isNew,
			md: 6,
		},
		{
			type: FIELD_TYPES.DATE,
			name: 'customerDetails.license_expire_date',
			inputLabel: `${transPrefix}.license_expiray_date`,
			disabled: !isNew,
			md: 6,
		},
		{
			type: FIELD_TYPES.DATE,
			name: 'customerDetails.birth_date',
			inputLabel: `${transPrefix}.birth_date`,
			minDate: new Date('1-1-1900'),
			maxDate: eighteenYearsAgo,
			disabled: !isNew,
			md: 6,
		},
		{
			type: FIELD_TYPES.FILE_UPLOADER,
			name: 'customerDetails.license_file',
			label: `${transPrefix}.license_photo`,
			fileUploadBtnFullWidth: true,
			required: false,
			styles: classes.uploadFileBtn,
			uploadFileEndIcon: PaperPin,
			disabled: !isNew,
			md: 6,
			handleOnFileUploader: (file) =>
				change('customerDetails.license_file', file),
		},
	];
	return { FIELDS };
};
