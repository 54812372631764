import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	noteContainer: {
		display: 'flex',
		gap: '12px',
	},
	icon: {
		color: theme.palette.text[2],
	},
	note: {
		color: theme.palette.text[2],
		fontSize: '14px',
		fontWeight: 400,
	},
}));
