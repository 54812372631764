import { t } from 'i18next';
import { find, omit } from 'lodash';

// Import helpers
import { BOOKING_DETAILS_TABS, BOOKING_OPTION_TYPES } from './variables';

export const isRejectVisible = (cell, resources) => {
	if (resources?.cancelReasons) {
		const cancelReasonId = resources.cancelReasons.find(
			({ uuid }) => uuid === cell.row.original.uuid
		);
		return !!cancelReasonId;
	} else {
		return false;
	}
};

export const getTabIndexBookingDetails = (tabName) =>
	Object.values(BOOKING_DETAILS_TABS).indexOf(tabName);

export const getServiceType = (booking) => {
	const { options } = booking || {};

	const isDelivery = !!find(options, ['type', BOOKING_OPTION_TYPES.DELIVERY]);

	const isDeliveryReturn = !!find(options, [
		'type',
		BOOKING_OPTION_TYPES.DELIVERY_DROP,
	]);

	const service = isDeliveryReturn
		? t('common.delivery_terms.delivery_return')
		: isDelivery
		? t('common.delivery_terms.delivery')
		: t('common.delivery_terms.pickup');

	return service;
};

export const getCheckedOptions = (options) => {
	const optionsWithoutDelivery = omit(BOOKING_OPTION_TYPES, [
		'DELIVERY',
		'DELIVERY_DROP',
	]);

	const formattedOptions = Object.values(optionsWithoutDelivery).reduce(
		(acc, type) => {
			const newOption = {
				type,
				checked: !!find(options, ['type', type]),
			};

			return [...acc, newOption];
		},
		[]
	);

	return formattedOptions;
};
