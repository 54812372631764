import React from 'react';
import { object } from 'prop-types';
import { Box } from '@material-ui/core';

// Import assets
import { DiscountIcon, FilledStarIcon } from 'assets/icons';

// Import components
import { Typography } from 'components/elements';

// Import styles
import { useStyles } from './CarAndCompanyInfo.styles';

// Import utilities
import { useIsRTL } from 'components/utilities';

export const CarAndCompanyInfo = ({ car }) => {
	const classes = useStyles();
	const isRTL = useIsRTL();
	const specialOffer = isRTL ? car.label_ar : car.label_en;

	return (
		<Box className={classes.container}>
			<Box className={classes.titleContainer}>
				<Typography className={classes.title}>{car.name}</Typography>

				{specialOffer && (
					<Box className={classes.offer}>
						<DiscountIcon className={classes.discountIcon} />
						<Typography className={classes.offerText}>
							{specialOffer}
						</Typography>
					</Box>
				)}
			</Box>
			<Box className={classes.officeContainer}>
				<Box className={classes.logoContainer}>
					<Box component="img" src={car.office.logo} className={classes.logo} />
				</Box>
				<Typography className={classes.officeText}>
					{car.office.name}
				</Typography>
				<FilledStarIcon className={classes.starIcon} />
				<Typography className={classes.officeText}>
					{car.office.rating}
				</Typography>
			</Box>
		</Box>
	);
};

CarAndCompanyInfo.propTypes = {
	car: object.isRequired,
};
