import React from 'react';
import { bool, object, string } from 'prop-types';
import { Chip, Tooltip } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './BookingStatusChip.styles';

// Import utilities
import { useBookingStatusChip } from './useBookingStatusChip';
import { useTranslations } from 'components/utilities';

const BookingStatusChip = ({ booking, state, showRejectTooltip = false }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { statusType, status, title } = useBookingStatusChip({
		showRejectTooltip,
		booking,
		state,
	});

	const popperClasses = {
		popper: classes.popper,
	};

	return (
		<Tooltip
			placement="top-end"
			classes={showRejectTooltip ? popperClasses : {}}
			title={title}
		>
			<Chip
				label={t(`bookings.booking_states.${status}`)}
				className={clsx(classes.chip, classes[statusType])}
			/>
		</Tooltip>
	);
};

BookingStatusChip.propTypes = {
	showRejectTooltip: bool,
	booking: object,
	state: string,
};

export default BookingStatusChip;
