export const buttons = [
	{
		label: 'common.all',
		status: 'all',
	},
	{
		label: 'common.filters.active',
		status: true,
	},
	{
		label: 'common.filters.inactive',
		status: false,
	},
];
