import { StepConnector, withStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	header: {
		display: 'flex',
		justifyContent: 'space-between',
	},
	heading: {
		display: 'flex',
		alignItems: 'center',
		gap: '16px',
	},
	optionalLabel: {
		fontSize: 16,
		fontWeight: 600,
		marginLeft: '24px',
	},
	cancelReasonBox: ({ stepsAmount }) => ({
		display: 'grid',
		placeItems: 'center',
		gridTemplateColumns: `repeat(${stepsAmount}, 1fr)`,
		padding: '0 24px',
	}),
	cancelReason: ({ cancelReasonIndex }) => ({
		color: theme.palette.error.main,
		gridArea: `1 / ${cancelReasonIndex + 1} / 2 / ${cancelReasonIndex + 2}`,
		textAlign: 'center',
		fontWeight: 'bold',
		fontSize: '12px',
	}),
	locationContainer: {
		display: 'flex',
		flexDirection: 'column',
		gap: '16px',
		marginTop: theme.spacing(5),

		'& .MuiStepConnector-vertical': {
			padding: 0,
			marginLeft: '8px',
		},
	},
	stepIcon: {
		width: '16px',
		height: '16px',
		borderRadius: '50%',
		border: `2px solid ${theme.palette.success.medium}`,
	},
	stepIconRed: {
		borderColor: theme.palette.error.medium,
	},
	notActiveStepIcon: {
		borderColor: theme.palette.background[3],
	},
	stepper: {
		padding: 0,
		marginBottom: '40px',
	},
	notActiveLabel: {
		color: theme.palette.text[3],
		fontSize: 14,
	},
}));

export const CustomConnector = withStyles((theme) => ({
	active: {
		'& $line': {
			borderColor: theme.palette.background[3],
		},
	},
	completed: {
		'& $line': {
			borderColor: theme.palette.background[3],
		},
	},
	line: {
		borderLeftWidth: '1px',
		borderStyle: 'solid',
		borderBottom: 0,
		borderTop: 0,
		borderRight: 0,
		minHeight: '44px',
	},
}))(StepConnector);
