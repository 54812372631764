import React from 'react';

// Import helpers
import { initialValues, formatValues } from './helpers';

// Import components
import { TableFiltersToolbar } from 'components/elements';
import { FilterFields } from '../../components';
import { AdditionalFilterFields } from './components';

export const Filters = () => (
	<TableFiltersToolbar
		initialValues={initialValues}
		formatValues={formatValues}
		dialogFilters={<FilterFields />}
		prevAdditionalFilters={<AdditionalFilterFields />}
	/>
);
