import React from 'react';
import { bool, string } from 'prop-types';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

// Import components
import {
	DateRangeModal,
	SeparatedDateInputs,
	CombinedDateInputs,
} from './components';

// Import utilities
import useDateRangeInput from './useDateRangeInput';

// Import helpers
import { conditionallyRequired } from 'helpers';
import { ToggleButton } from './components/ToggleButton';

const DateRangeInput = ({
	label,
	nameFrom,
	labelFrom,
	nameUntil,
	labelUntil,
	combinedInputs,
	isToggleButton = false,
	required = false,
}) => {
	const {
		open,
		errorText,
		initialPeriod,
		combinedDates,
		validationError,
		updateValues,
		toggleOpenModal,
		onCombinedInputChange,
	} = useDateRangeInput({
		nameFrom,
		nameUntil,
	});

	return (
		<MuiPickersUtilsProvider utils={DateFnsUtils}>
			<DateRangeModal
				isOpen={open}
				onClose={toggleOpenModal}
				updateValues={updateValues}
				initialPeriod={initialPeriod}
			/>

			{isToggleButton ? (
				<ToggleButton toggle={toggleOpenModal} label={label} isOpen={open} />
			) : (
				<>
					{combinedInputs ? (
						<CombinedDateInputs
							label={label}
							value={combinedDates}
							error={validationError}
							errorText={errorText}
							toggle={toggleOpenModal}
							onChange={onCombinedInputChange}
						/>
					) : (
						<SeparatedDateInputs
							nameFrom={nameFrom}
							labelFrom={labelFrom}
							nameUntil={nameUntil}
							labelUntil={labelUntil}
							toggle={toggleOpenModal}
							required={required}
						/>
					)}
				</>
			)}
		</MuiPickersUtilsProvider>
	);
};

DateRangeInput.propTypes = {
	nameFrom: string.isRequired,
	nameUntil: string.isRequired,
	combinedInputs: conditionallyRequired,
	label: conditionallyRequired,
	labelFrom: conditionallyRequired,
	labelUntil: conditionallyRequired,
	isToggleButton: bool,
	required: bool,
};

export default DateRangeInput;
