import React from 'react';
import { Box } from '@material-ui/core';

// Import components
import RoleProtected from 'components/hoc/RoleProtected';
import { AssignUrgent, PermissionFallback } from './components';

// Import helpers
import { PERMISSION_URGENT_ASSIGN } from './helpers';

export const Urgent = () => {
	return (
		<Box>
			<RoleProtected
				roles={PERMISSION_URGENT_ASSIGN}
				fallback={<PermissionFallback />}
			>
				<AssignUrgent />
			</RoleProtected>
		</Box>
	);
};
