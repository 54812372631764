import React from 'react';
import {
	Button,
	Dialog,
	DialogContent,
	Grid,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';
import { bool, func, node, string } from 'prop-types';

// Import components
import { ButtonProgress, ClosableDialogTitle } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './ActionConfirmDialog.styles';

export const ActionConfirmDialog = ({
	isOpen,
	toggle,
	confirmAction,
	formId,
	actionButtonText,
	isLoading,
	title,
	description,
	startIcon,
	actionButtonClassName,
	actionButtonVariant,
	extraContent,
	maxWidth,
}) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Dialog open={isOpen} onClose={toggle} maxWidth={maxWidth} fullWidth>
			<ClosableDialogTitle title={title} onClose={toggle} />
			<DialogContent>
				{description && (
					<Typography
						variant="body1"
						className={clsx({
							[classes.contentText]: true,
							[classes.addSpace]: !extraContent,
						})}
					>
						{description}
					</Typography>
				)}

				{extraContent && extraContent}

				<Grid
					container
					justifyContent="flex-end"
					spacing={2}
					className={classes.buttonsContainer}
				>
					<Grid item>
						<Button
							onClick={toggle}
							color="default"
							className={classes.button}
							size="large"
						>
							{t('common.buttons.cancel')}
						</Button>
					</Grid>
					<Grid item>
						<ButtonProgress
							color="primary"
							variant={actionButtonVariant}
							startIcon={startIcon}
							className={clsx([
								classes.button,
								classes.actionButton,
								actionButtonClassName,
							])}
							type={confirmAction ? 'button' : 'submit'}
							form={formId}
							disabled={isLoading}
							isLoading={isLoading}
							onClick={confirmAction}
							size="large"
						>
							{t(actionButtonText)}
						</ButtonProgress>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

ActionConfirmDialog.propTypes = {
	isOpen: bool.isRequired,
	toggle: func.isRequired,
	isLoading: bool.isRequired,
	confirmAction: func,
	actionButtonText: string.isRequired,
	formId: string,
	title: string.isRequired,
	description: string,
	startIcon: node,
	actionButtonClassName: string,
	actionButtonVariant: string,
	extraContent: node,
	maxWidth: string,
};

ActionConfirmDialog.defaultProps = {
	formId: '',
	confirmAction: null,
	actionButtonClassName: '',
	actionButtonVariant: 'outlined',
	maxWidth: 'sm',
};
