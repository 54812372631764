import React from 'react';
import { useFormState } from 'react-final-form';

// Import components
import {
	MultiManufacturerFilter,
	MultiCompaniesFilter,
	MultiModelFilter,
	MultiYearsFilter,
} from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();
	const { values } = useFormState();

	const { manufacturers } = values || {};

	return (
		<>
			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
				/>
			)}
			<MultiManufacturerFilter
				name="manufacturers"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
			/>
			<MultiModelFilter
				name="models"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
				disabled={!manufacturers || manufacturers === 'all'}
				manufacturerUuid={manufacturers}
			/>
			<MultiYearsFilter
				name="years"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
				disabled={!manufacturers || manufacturers === 'all'}
				manufacturerUuid={manufacturers}
			/>
		</>
	);
};
