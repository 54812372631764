import React from 'react';
import {
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@material-ui/core';

// Import components
import { AvailabilityBodyRow } from './components';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './AvailabilityTable.styles';
import { useAvailabilityTableCellProvider } from '../../../../context';

export const AvailabilityTable = () => {
	const { columns } = useAvailabilityTableCellProvider();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<TableContainer>
			<Table className={classes.table}>
				<TableHead className={classes.tableHead}>
					<TableRow>
						{columns.map(({ header, accessor }) => (
							<TableCell key={accessor}>{header ? t(header) : ''}</TableCell>
						))}
					</TableRow>
				</TableHead>
				<TableBody>
					<AvailabilityBodyRow />
				</TableBody>
			</Table>
		</TableContainer>
	);
};
