import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: '16px',
	},
	grayText: {
		color: theme.palette.text[3],
		fontSize: '12px',
	},
	reasonText: {
		color: theme.palette.text[4],
		fontSize: '12px',
	},
}));
