import React from 'react';
import { useParams } from 'react-router-dom';

// Import components
import {
	MultiCompaniesFilter,
	BaseSelectFilter,
	Grid,
} from 'components/elements';

// Import utils and helpers
import { useProfile } from 'components/utilities';
import { ROLE_FILTER_OPTIONS } from 'helpers';

export const FilterFields = () => {
	const { isAdmin } = useProfile();
	const { id } = useParams();

	const isCompanyFilterVisible = isAdmin && !id;

	return (
		<Grid container spacing={3}>
			{isCompanyFilterVisible && (
				<MultiCompaniesFilter
					name="companies"
					label="bookings.filters.company"
					xs={12}
					md={6}
				/>
			)}
			<BaseSelectFilter
				label="employee_rewarding.table.filters.role"
				options={ROLE_FILTER_OPTIONS}
				name="role"
				xs={12}
				md={6}
			/>
		</Grid>
	);
};
