import React from 'react';
import { bool, func, object } from 'prop-types';
import { DialogContent, DialogTitle } from '@material-ui/core';

// Import components
import {
	Button,
	ChipLabel,
	Dialog,
	Divider,
	Grid,
	LoadingWrapper,
	Typography,
} from 'components/elements';
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import utils
import { useReceiptDetailsDialog } from './useReceiptDetailsDialog';
import { useTranslations } from 'components/utilities';

// Import assets
import { DownloadIcon } from 'assets/icons';

// Import styles
import { useStyles } from './ReceiptDetailsDialog.styles';

export const ReceiptDetailsDialog = ({ toggle, isOpen, row }) => {
	const {
		RECEIPT_INFO_FIELDS,
		COST_BREAKDOWN_FIELDS,
		isLoading,
		title,
		statusChipColor,
		statusChipLabel,
		customData,
	} = useReceiptDetailsDialog({ row, isOpen });

	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<Dialog open={isOpen} onClose={toggle} fullWidth>
			{isLoading ? (
				<LoadingWrapper />
			) : (
				<>
					<DialogTitle className={classes.dialogTitle}>
						<Typography fontSize={20} fontWeight={600}>
							{title}
						</Typography>
						<ChipLabel label={statusChipLabel} color={statusChipColor} />
					</DialogTitle>
					<DialogContent>
						<Typography
							fontSize={14}
							fontWeight={600}
							className={classes.subtitle}
						>
							{t('bookings.preview_booking.receipt_details.receipt_info')}
						</Typography>
						<PreviewFields
							customData={customData}
							fields={RECEIPT_INFO_FIELDS}
						/>
						<Divider styleOverride={classes.divider} />
						<Typography
							fontSize={14}
							fontWeight={600}
							className={classes.subtitle}
						>
							{t('bookings.preview_booking.receipt_details.cost_breakdown')}
						</Typography>
						<PreviewFields
							customData={customData}
							fields={COST_BREAKDOWN_FIELDS}
						/>
						<Grid
							container
							justifyContent="flex-end"
							spacing={2}
							className={classes.buttonsContainer}
						>
							<Grid item>
								<Button
									onClick={toggle}
									type="dark_text"
									size="large"
									text={t('common.buttons.close')}
								/>
							</Grid>
							<Grid item>
								<Button
									type="outlined_and_primary"
									icon={DownloadIcon}
									text={t('common.buttons.download_receipt')}
									/* TODO: Immplement download function in another task */
									// isLoading={isLoading}
									// onClick={confirmAction}
									size="large"
								/>
							</Grid>
						</Grid>
					</DialogContent>
				</>
			)}
		</Dialog>
	);
};

ReceiptDetailsDialog.propTypes = {
	toggle: func.isRequired,
	isOpen: bool.isRequired,
	row: object.isRequired,
};
