import React from 'react';
import { Divider, Grid } from '@material-ui/core';
import { bool } from 'prop-types';

// Import components
import {
	AdditionalServices,
	AlternativeCars,
	BottomBar,
	ChangeConfirmationDialog,
	EditBookingPeriod,
	EditHistoryDialog,
	FindMoreCars,
	SuccessDialog,
} from '../../components';

// Import context
import { EditBookingFormProvider } from '../../context';

// Import styles
import { useStyles } from './FormContent.styles';

export const FormContent = ({ isRejected = false }) => {
	const classes = useStyles();

	return (
		<EditBookingFormProvider>
			<Grid container spacing={4}>
				<Grid item xs={12}>
					<EditBookingPeriod isRejected={isRejected} />
				</Grid>
				{isRejected && (
					<>
						<Divider className={classes.divider} />
						<Grid item xs={12}>
							<AlternativeCars />
						</Grid>
						<Grid item xs={12}>
							<FindMoreCars />
						</Grid>
						<Divider className={classes.divider} />
						<Grid item xs={12}>
							<AdditionalServices />
						</Grid>
					</>
				)}
			</Grid>

			<EditHistoryDialog />

			<ChangeConfirmationDialog />

			<SuccessDialog />

			{isRejected && <BottomBar />}
		</EditBookingFormProvider>
	);
};

FormContent.propTypes = {
	isRejected: bool,
};
