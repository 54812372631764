import { useEffect } from 'react';

// Import utils
import { useDeliveryLocationProvider } from '../../../../context';

// Import helpers
import { DELIVERY_STATUS } from 'helpers';

const { FINISHED } = DELIVERY_STATUS;

export const useLocationContainer = ({ customStepsData }) => {
	const deliveryLocation = useDeliveryLocationProvider();

	const deliveryLocationData = customStepsData || deliveryLocation;

	const {
		is_real_time,
		isFetching,
		isFetched,
		isError,
		refetch,
		states,
		state,
	} = deliveryLocationData || {};

	const intervalTime = is_real_time ? 10000 : 30000;

	const allowInterval = state !== FINISHED;

	useEffect(() => {
		const timerId = allowInterval && setInterval(refetch, intervalTime);

		return () => clearInterval(timerId);
		//eslint-disable-next-line
	}, [allowInterval, intervalTime]);

	return {
		deliveryLocationData,
		isFetching,
		isFetched,
		isError,
		states,
	};
};
