export const VAT_NUMBER = '310159436100003';

export const BOOKING_INVOICE_STATUS = {
	DRAFT: 'draft',
	PAID: 'paid',
	SETTLED: 'settled',
	MODIFIED: 'modified',
	REFUNDED: 'refunded',
	CANCELED: 'canceled',
};
