import React from 'react';
import { t } from 'i18next';

import { InputAdornment } from '@material-ui/core';

// Import utils and helpers
import { FIELD_TYPES } from 'helpers';

const { NUMBER, CHECKBOX } = FIELD_TYPES;

export const MONTHLY_RATE_FIELD = ({
	isAdmin,
	isOneMonthDisabled,
	isLeasing,
}) => [
	{
		type: CHECKBOX,
		name: 'is_leasing',
		label: 'cars.form.leasing.active_leasing_modal',
		disabled: !isAdmin,
	},
	{
		type: NUMBER,
		name: 'leasing.base_rent.months.m1',
		label: 'leasing_cars.form.rates_model.monthly_rate',
		required: true,
		xs: 12,
		min: 1,
		step: 0.01,
		endAdornment: (
			<InputAdornment position="end">{t('currency.sar_month')}</InputAdornment>
		),
		disabled: !!isOneMonthDisabled || !isLeasing,
	},
];

export const SELL_PRICE_FIELDS = ({
	leasingCheckboxName,
	leasingSellPriceName,
	handleOnCheckboxClick,
	isLeasingSellActive,
	isAdmin,
	isLeasing,
}) => [
	{
		type: CHECKBOX,
		name: leasingCheckboxName,
		label: 'leasing_cars.form.rates_model.sell_checkbox',
		handleOnCheckboxClick,
		disabled: !isAdmin || !isLeasing,
	},
	{
		type: NUMBER,
		name: leasingSellPriceName,
		label: 'leasing_cars.form.rates_model.sell_checkbox_label',
		required: true,
		xs: 12,
		min: 1,
		step: 0.01,
		isHidden: !isLeasingSellActive,
		endAdornment: (
			<InputAdornment position="end">{t('currency.sar')}</InputAdornment>
		),
		disabled: !isAdmin || !isLeasing,
	},
];
