import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: ({ navbarMinHeight }) => ({
		minHeight: `${navbarMinHeight}px`,
	}),
	title: {
		fontSize: '28px',
		fontWeight: 500,
		color: theme.palette.text.inputLabel,
	},
}));
