import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		padding: '32px 24px',
	},
	title: {
		fontSize: '18px',
		fontWeight: 600,
		color: theme.palette.text[4],
	},
	searchIcon: {
		color: theme.palette.text[1],
		marginRight: theme.spacing(2),
	},
	searchPaper: {
		display: 'flex',
		alignItems: 'center',
		padding: '4px 16px',
		backgroundColor: theme.palette.white,
		minWidth: '324px',
		borderRadius: '8px',
		height: '42px',

		'& .MuiInputBase-root': {
			backgroundColor: theme.palette.white,
			minHeight: '40px',
		},

		'& .MuiInputBase-input': {
			backgroundColor: theme.palette.white,
		},
	},
}));
