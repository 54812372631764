import React from 'react';
import { useFormState } from 'react-final-form';

// Import components
import { CitiesList, Fields, TopBar } from './components';
import { Grid, LoadingWrapper } from 'components/elements';

// Import styles
import { useStyles } from './PickupCitiesCard.styles';

// Import utils
import { PickupCitiesCardProvider } from './context/PickupCitiesCardProvider';

export const PickupCitiesCard = () => {
	const { values } = useFormState();
	const classes = useStyles();

	const companyUuid = values.company_uuid;

	return (
		<PickupCitiesCardProvider>
			{companyUuid ? (
				<Grid container className={classes.container}>
					<Grid item xs={12} className={classes.topBarWrapper}>
						<TopBar />
					</Grid>
					<Grid item xs={12}>
						<Grid container>
							<Grid item xs={4}>
								<CitiesList />
							</Grid>
							<Grid item xs={8}>
								<Fields />
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			) : (
				<LoadingWrapper className={classes.loadingWrapper} />
			)}
		</PickupCitiesCardProvider>
	);
};
