import React from 'react';

// Import components
import {
	BaseYesNoAllFilter,
	BookingStatusFilter,
	CitiesFilter,
	DateRangeFilter,
	ServiceTypeFilter,
	MultiCompaniesFilter,
} from 'components/elements';

// Import helpers
import { bookingStates } from './helpers';

export const RevenueFilters = () => {
	return (
		<>
			<ServiceTypeFilter
				name="service_type"
				label="common.filters.service_type"
				md={3}
			/>
			<DateRangeFilter
				nameFrom="created_at.from"
				nameUntil="created_at.until"
				label="common.filters.created_at_date"
				combinedInputs
				md={3}
			/>
			<DateRangeFilter
				nameFrom="pick_up_at.from"
				nameUntil="pick_up_at.until"
				label="bookings.filters.pick_date"
				combinedInputs
				md={3}
			/>
			<DateRangeFilter
				nameFrom="drop_off_at.from"
				nameUntil="drop_off_at.until"
				label="bookings.filters.drop_date"
				combinedInputs
				md={3}
			/>

			<DateRangeFilter
				nameFrom="extended_at.from"
				nameUntil="extended_at.until"
				label="common.fields.extended_at"
				combinedInputs
				md={3}
			/>

			<MultiCompaniesFilter name="companies" label="common.fields.company" />

			<CitiesFilter name="cities" label="common.fields.city" md={3} />

			<BaseYesNoAllFilter name="is_extension" label="common.fields.extension" />
			<DateRangeFilter
				nameFrom="paid_at.from"
				nameUntil="paid_at.until"
				label="common.fields.paid_at"
				combinedInputs
				md={3}
			/>

			<BookingStatusFilter
				name="booking_status"
				label="common.fields.booking_status"
				neededStates={bookingStates}
				md={3}
			/>
		</>
	);
};
