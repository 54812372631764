import React from 'react';
import { useParams } from 'react-router-dom';

// Import store
import { fetchCars } from 'store/actions';

// Import columns
import { columns, COLUMN_ACCESSORS } from './columns';

// Import utilities
import { TableRPC, useTranslations } from 'components/utilities';
import { useOffice } from 'queries';
import { BUTTONS } from './helpers';

// Import components
import { InnerNavbarElementsButtons } from 'components/elements';
import {
	CarsOuterToolbarElements,
	TableCarsBodyRow,
	CarsFilters,
} from './components';

export const Cars = () => {
	const {
		i18n: { language },
	} = useTranslations();

	const { id: officeUuid } = useParams();
	const { data, isFetching } = useOffice({ officeUuid });

	const title = data?.details?.name?.[language];

	return (
		<TableRPC
			columnsAccessor={COLUMN_ACCESSORS}
			columns={columns}
			tableBodyRow={TableCarsBodyRow}
			fetchDataTableAction={fetchCars}
			title={title}
			titleLoading={isFetching}
			outerToolbarElements={<CarsOuterToolbarElements />}
			filtersBar={<CarsFilters />}
			tableNavigation={
				<InnerNavbarElementsButtons filterPath="active" buttons={BUTTONS} />
			}
			searchBarPlaceholder="common.fields.company_name"
		/>
	);
};
