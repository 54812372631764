import { useFormState } from 'react-final-form';

// Import utilities and helpers
import { useToggle } from 'components/utilities';
import { SERVICE_TYPES } from 'views/Bookings/CreateBooking/helpers';
import { useMessage, useTranslations } from 'components/utilities';

export const useSelectCar = () => {
	const {
		values: {
			service_type: serviceType,
			location,
			delivery,
			item,
			period: { pick_date: pickDate, pick_time: pickTime, drop_date: dropDate },
		},
	} = useFormState();

	const { t } = useTranslations();

	const { message } = useMessage();

	const isPickup = serviceType === SERVICE_TYPES.PICKUP;

	const locationRef = isPickup ? location : delivery;

	const dateAvailable = pickDate && pickTime && dropDate;

	const { on: isCarSelectionDialogOpen, toggle: toggleCarSelectionDialog } =
		useToggle();

	const handleDialogOpen = () => {
		if (locationRef.lat && locationRef.lng && dateAvailable) {
			toggleCarSelectionDialog();
		} else {
			message.warning(
				t('bookings.create_booking.please_select_location_and_date_first')
			);
		}
	};

	const isPreview = !!item.uuid;

	return {
		isCarSelectionDialogOpen,
		toggleCarSelectionDialog,
		handleDialogOpen,
		isPreview,
	};
};
