import React from 'react';

// Import components
import { ActionConfirmDialog } from 'components/elements';
import { AvailabilityTable, CarInfo } from './components';

// Import utils
import { useAvailabilityTableCellProvider } from '../../context';
import { useTranslations } from 'components/utilities';

export const AvailabilityDialog = () => {
	const { isOpen, toggleDialog } = useAvailabilityTableCellProvider();
	const { t } = useTranslations();
	const handleSubmit = () => {};
	const isLoading = false;

	return (
		<ActionConfirmDialog
			isOpen={isOpen}
			toggle={toggleDialog}
			title={t('nav.operations.cars_availability')}
			actionButtonText="common.buttons.save"
			actionButtonVariant="contained"
			isLoading={isLoading}
			confirmAction={handleSubmit}
			maxWidth="md"
			extraContent={
				<>
					<CarInfo />
					<AvailabilityTable />
				</>
			}
		/>
	);
};
