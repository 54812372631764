import { useDispatch } from 'react-redux';

// Import utils
import { useMarkUrgentProvider } from 'components/context';

// Import store
import {
	setBookingPreviewItem,
	setValidationTableChangedState,
} from 'store/actions';

export const useExtraActions = ({ cell, toggleCompensationDialog }) => {
	const dispatch = useDispatch();

	const { toggleMarkUrgent } = useMarkUrgentProvider();

	const handleOnOpenCompensationModal = () => {
		const booking = cell.row.original;
		setBookingPreviewItem({ booking, dispatch });

		setValidationTableChangedState({ isTableChanged: false, dispatch });

		toggleCompensationDialog();
	};

	return { handleOnOpenCompensationModal, toggleMarkUrgent };
};
