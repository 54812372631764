import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { CURRENT_BOOKING_FIELDS, getConvertedCustomData } from './helpers';

// Import components
import { PreviewFields } from 'views/Bookings/EditBooking/components';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import styles
import { useStyles } from '../../BookingInfo.styles';

export const CurrentBooking = () => {
	const { t } = useTranslations();

	const classes = useStyles();

	const booking = useSelector(previewBookingSelector);

	const customData = getConvertedCustomData(booking);

	return (
		<Box className={classes.infoContainer}>
			<Typography variant="body1" className={classes.title}>
				{t('common.fields.current_booking')}
			</Typography>

			<PreviewFields fields={CURRENT_BOOKING_FIELDS} customData={customData} />
		</Box>
	);
};
