import { useState } from 'react';

// Import utils and helpers
import { useTranslations } from 'components/utilities';

export const useOfficeItem = ({
	setCheckedOffices,
	checkedOffices,
	office,
}) => {
	const {
		i18n: { language },
	} = useTranslations();
	const { uuid, name, is_manager_assigned } = office;
	const [isChecked, setIsChecked] = useState(is_manager_assigned);
	const officeName = name[language];

	const handleCheckboxChange = ({ target }) => {
		setIsChecked((prev) => !prev);

		if (target.checked) {
			setCheckedOffices([...checkedOffices, office]);
		} else {
			const newOffices = checkedOffices.filter((item) => item.uuid !== uuid);
			setCheckedOffices(newOffices);
		}
	};

	return {
		handleCheckboxChange,
		officeName,
		isChecked,
	};
};
