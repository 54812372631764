import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		alignItems: 'center',
		justifyContent: 'space-between',
		borderRadius: '8px',
		backgroundColor: theme.palette.background[6],
		minHeight: '56px',
		marginBottom: theme.spacing(3),
		flexWrap: 'nowrap',

		'& .MuiInputBase-input': {
			backgroundColor: 'transparent',
			fontSize: 13,
		},

		'& fieldset': { border: 'none' },
	},

	button: {
		minWidth: 'auto',
	},
}));
