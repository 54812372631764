import { useState, useEffect } from 'react';
import { useForm, useFormState, useField } from 'react-final-form';
import { subDays, parse } from 'date-fns';
import _ from 'lodash';

import { validateDate } from 'helpers';
import {
	formatDate,
	validateInputDates,
	getDatesFromString,
	COMBINED_INPUT_MASK,
} from './helpers';

const useDateRangeInput = ({ nameFrom, nameUntil }) => {
	const { change } = useForm();

	const { values } = useFormState();

	const {
		meta: { submitError: submitErrorFrom },
	} = useField(nameFrom);
	const {
		meta: { submitError: submitErrorUntil },
	} = useField(nameUntil);

	const dateFrom = _.get(values, nameFrom);
	const dateUntil = _.get(values, nameUntil);

	const [open, setOpen] = useState(false);
	const [combinedDates, setCombinedDates] = useState('');
	const [validationError, setValidationError] = useState(false);
	const [errorText, setErrorText] = useState('');

	const [initialPeriod, setInitialPeriod] = useState({
		startDate: subDays(new Date(), 7),
		endDate: new Date(),
		key: 'selection',
	});

	useEffect(() => {
		if (submitErrorFrom || submitErrorUntil) {
			setValidationError(true);
			setErrorText(submitErrorFrom || submitErrorUntil);
		} else {
			setValidationError(false);
			setErrorText('');
		}
	}, [submitErrorFrom, submitErrorUntil]);

	useEffect(() => {
		let newPeriod = {};

		// Table filers dates parsing
		if (_.isString(dateFrom)) {
			const parsedDates = JSON.parse(dateFrom);

			if (_.isArray(parsedDates)) {
				newPeriod = {
					startDate: parse(parsedDates[0], 'yyyy/MM/dd', new Date()),
					endDate: parse(parsedDates[1], 'yyyy/MM/dd', new Date()),
				};
			}
		} else {
			newPeriod = {
				startDate: validateDate(dateFrom) ? dateFrom : subDays(new Date(), 7),
				endDate: validateDate(dateUntil) ? dateUntil : new Date(),
			};
		}

		setInitialPeriod((prevPeriod) => ({ ...prevPeriod, ...newPeriod }));

		if (!dateFrom && !dateUntil) {
			setCombinedDates('');
		}

		if (dateFrom && dateUntil) {
			updateCombinedDatesField(newPeriod);
		}
	}, [dateFrom, dateUntil, nameFrom, nameUntil]);

	const toggleOpenModal = () => setOpen((o) => !o);

	const resetValidationError = () => {
		setErrorText('');
		setValidationError(false);
	};

	const applyValidationError = () => {
		setErrorText('Invalid Date Format');
		setValidationError(true);
	};

	const changeFormFields = ({ startDate, endDate }) => {
		change(nameFrom, startDate);
		change(nameUntil, endDate);
	};

	const updateCombinedDatesField = ({ startDate, endDate }) => {
		const startDateString = formatDate(startDate);
		const endDateString = formatDate(endDate);

		setCombinedDates(startDateString + ' - ' + endDateString);
	};

	const handleUpdateValues = (ranges) => {
		const { startDate, endDate } = ranges[0];

		changeFormFields({ startDate, endDate });

		updateCombinedDatesField({ startDate, endDate });

		resetValidationError();

		toggleOpenModal();
	};

	const handleOnCombinedInputChange = ({ target: { value } }) => {
		setCombinedDates(value);

		const isEmptyInput =
			!value || value === COMBINED_INPUT_MASK.replaceAll('9', '_');

		if (isEmptyInput) {
			resetValidationError();
			return;
		}

		const isValid = validateInputDates(value);

		if (isValid) {
			resetValidationError();

			const { startDate, endDate } = getDatesFromString(value);
			changeFormFields({ startDate, endDate });
		} else {
			applyValidationError();
		}
	};

	return {
		open,
		errorText,
		initialPeriod,
		combinedDates,
		validationError,
		toggleOpenModal,
		updateValues: handleUpdateValues,
		onCombinedInputChange: handleOnCombinedInputChange,
	};
};

export default useDateRangeInput;
