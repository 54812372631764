import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const CarWithKeyIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			d="M8.24302 11.5002H6.24219"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.8828 8.29284L20.0007 7.2334"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M5.11107 8.29284L2.99219 7.2334"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.9524 8.65398L16.9883 4.54036C16.7763 3.63573 15.9694 2.99609 15.0402 2.99609H7.95905C7.02991 2.99609 6.22303 3.63573 6.01101 4.54036L5.04688 8.65398"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M19.9993 12.0005V11.5003C19.9993 9.84273 18.6556 8.49902 16.998 8.49902H5.99344C4.33589 8.49902 2.99219 9.84273 2.99219 11.5003V16.0021C2.99219 16.5547 3.44009 17.0026 3.9926 17.0026H6.22954C6.78205 17.0026 7.22995 16.5547 7.22995 16.0021V14.5015H10.9955"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.75 11.5002H16.7508"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0978 17.7178L12.7577 19.0617C12.5931 19.2277 12.5005 19.4519 12.5 19.6858V20.6209C12.5 21.1085 12.8952 21.5038 13.3828 21.5038H14.322C14.5564 21.5041 14.7811 21.4105 14.9461 21.244L16.2816 19.9047"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0969 17.7184C13.7712 16.4094 14.3587 15.044 15.5331 14.3805C16.7075 13.717 18.1802 13.9184 19.1333 14.8729C20.0865 15.8273 20.2858 17.3004 19.6206 18.4738C18.9555 19.6473 17.5892 20.2328 16.2807 19.9053"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M17.0502 17.0022C17.0502 17.0298 17.0278 17.0522 17.0002 17.0522C16.9726 17.0522 16.9502 17.0298 16.9502 17.0022C16.9502 16.9746 16.9725 16.9522 17.0002 16.9521C17.0134 16.9521 17.0262 16.9574 17.0356 16.9668C17.045 16.9762 17.0502 16.9889 17.0502 17.0022"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
