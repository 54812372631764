import { useEffect } from 'react';
import { useFormState, useForm } from 'react-final-form';

export const useAdditionalFilterFields = () => {
	const {
		values: { company_uuid },
	} = useFormState();
	const { change } = useForm();

	useEffect(() => {
		if (typeof company_uuid === 'string') {
			change('company_uuid', company_uuid.split(' '));
		}
		// eslint-disable-next-line
	}, [company_uuid]);
};
