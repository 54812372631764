import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	tableCell: ({ isDescriptionCell }) => ({
		fontSize: '12px',
		fontStyle: 'normal',
		fontWeight: isDescriptionCell ? '600' : '400',
		color: `${
			isDescriptionCell ? theme.palette.text.inputText : theme.palette.text[1]
		}`,
	}),
}));
