import React from 'react';

// Import components
import { CarAndCompanyContent, CarAndCompanyHistory } from './components';
import RoleProtected from 'components/hoc/RoleProtected';

// Import helpers
import { PERMISSION_OLD_CAR_AND_COMPANY_INFORMATION } from './helpers';

// Import context
import { CarAndCompanyProvider } from './context';

export const CarAndCompany = () => {
	return (
		<CarAndCompanyProvider>
			<CarAndCompanyContent />
			<RoleProtected roles={PERMISSION_OLD_CAR_AND_COMPANY_INFORMATION}>
				<CarAndCompanyHistory />
			</RoleProtected>
		</CarAndCompanyProvider>
	);
};
