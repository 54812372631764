import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	BaseYesNoAllFilter,
	DateRangeInput,
	MultiCompaniesFilter,
} from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<>
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="pickup_date"
					nameUntil="pickup_date_to"
					label="bookings.filters.pick_date"
					combinedInputs
					isToggleButton
				/>
			</Grid>

			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
				/>
			)}

			<BaseYesNoAllFilter
				name="is_settled"
				allLabel="common.fields.settlement"
				xs="auto"
				md="auto"
				smallFieldStyle
			/>
		</>
	);
};
