import React from 'react';
import { Grid, Typography } from '@material-ui/core';

// Import components
import { BookingStatus, CustomerInfo } from './components';

// Import utils
import { useCustomerAndBookingInfo } from './useCustomerAndBookingInfo';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './CustomerAndBookingInfo.styles';

export const CustomerAndBookingInfo = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { isExtended, customerName, bookingCreatedAt } =
		useCustomerAndBookingInfo();

	return (
		<>
			<Grid
				container
				justifyContent="space-between"
				className={classes.topInfo}
			>
				<Grid item>
					<Typography variant="body1" className={classes.customerName}>
						{customerName}
					</Typography>
					<Typography variant="body1" className={classes.createdAt}>
						{t('bookings.table.headers.created_at')} {bookingCreatedAt}
					</Typography>
				</Grid>
				<Grid item>
					<BookingStatus isExtended={isExtended} />
				</Grid>
			</Grid>
			<CustomerInfo />
		</>
	);
};
