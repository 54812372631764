import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const LocateIcon = (props) => (
	<SvgIcon width="28" height="28" viewBox="0 0 28 28" fill="none" {...props}>
		<path
			d="M19.5041 8.50462C22.5415 11.542 22.5415 16.4666 19.5041 19.5041C16.4666 22.5415 11.542 22.5415 8.50462 19.5041C5.46721 16.4666 5.46721 11.542 8.50462 8.50462C11.542 5.46721 16.4666 5.46721 19.5041 8.50462"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M15.5826 12.4399C16.4505 13.3078 16.4505 14.7148 15.5826 15.5826C14.7148 16.4505 13.3078 16.4505 12.4399 15.5826C11.5721 14.7148 11.5721 13.3078 12.4399 12.4399C13.3078 11.5721 14.7148 11.5721 15.5826 12.4399"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0087 24.0069V19.5625"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.0087 4.00781V8.45226"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M8.45226 14.0087H4.00781"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M24.0069 14.0087H19.5625"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
