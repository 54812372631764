import React from 'react';
import { element, object } from 'prop-types';
import { Form } from 'react-final-form';

// Import components
import { Grid } from 'components/elements';
import { FiltersButtons } from './components';

// Import utils
import { useTableRPCWithQueryProvider } from 'components/utilities/rpc/TableRPCWithQuery/context';

export const Filters = ({ filters, filtersInitialValues = {} }) => {
	const { handleFiltersSubmit } = useTableRPCWithQueryProvider();

	return (
		<Form
			onSubmit={handleFiltersSubmit}
			initialValues={filtersInitialValues}
			render={({ handleSubmit }) => (
				<form onSubmit={handleSubmit}>
					<Grid container alignItems="center" spacing={2}>
						{filters}
						<FiltersButtons filtersInitialValues={filtersInitialValues} />
					</Grid>
				</form>
			)}
		/>
	);
};

Filters.propTypes = {
	filters: element,
	filtersInitialValues: object,
};
