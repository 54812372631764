import { format } from 'date-fns';
import services from 'services/services';

// Import helpers
import { updatePublicHolidaysUrl } from 'store/paths/publicHolidays';
import { DEFAULT_DATE_FORMAT } from 'helpers';

export const updatePublicHolidays = async ({
	successCallback,
	errorCallback,
	values,
}) => {
	try {
		const { public_holidays: publicHolidays } = values;

		const convertedPublicHolidays = Object.entries(publicHolidays).reduce(
			(acc, [holidayName, value]) => {
				const newObject = {
					[holidayName]: {
						...value,
						date_from: format(new Date(value.date_from), DEFAULT_DATE_FORMAT),
						date_to: format(new Date(value.date_to), DEFAULT_DATE_FORMAT),
					},
				};

				if (value.selected) {
					return {
						...acc,
						...newObject,
					};
				} else {
					return acc;
				}
			},
			{}
		);

		const body = {
			...values,
			public_holidays: convertedPublicHolidays,
		};

		await services.post(updatePublicHolidaysUrl, body);

		successCallback();
	} catch (error) {
		error.response && errorCallback();
	}
};
