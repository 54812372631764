import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	button: {
		padding: 0,
		color: theme.palette.primary.main,
		fontWeight: 600,
		fontSize: 14,
	},
}));
