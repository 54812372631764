import React from 'react';

// Import utils and helpers
import { BANK_INFO_FIELDS } from './helpers';
import { useTabsProvider } from 'components/context';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components';

export const BankInfo = () => {
	const { data: booking } = useTabsProvider();

	const customData = booking?.customerSummary;

	return <PreviewFields fields={BANK_INFO_FIELDS} customData={customData} />;
};
