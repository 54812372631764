import { makeStyles } from '@material-ui/core';

// Import helpers
import { BOOKING_URGENT_STATES } from 'helpers';

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: '100px',
		textTransform: 'uppercase',
		fontWeight: '600',
		padding: '8px 16px',
		fontSize: 12,
	},

	[BOOKING_URGENT_STATES.SOLVED]: {
		backgroundColor: theme.palette.success.lighter,
		color: theme.palette.success.medium,
	},

	[BOOKING_URGENT_STATES.UNSOLVED]: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},

	[BOOKING_URGENT_STATES.REVIEW]: {
		backgroundColor: theme.palette.warning.medium,
		color: theme.palette.warning.dark,
	},
}));
