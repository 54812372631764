import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BaseYesNoAllFilter, MultiCompaniesFilter } from 'components/elements';

export const FilterFields = () => {
	return (
		<Grid container spacing={3}>
			<BaseYesNoAllFilter name="active" label="common.filters.is_active" />

			<MultiCompaniesFilter label="common.company" name="company_uuid" />
		</Grid>
	);
};
