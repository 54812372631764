import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { array, string } from 'prop-types';

// Import styles
import { useStyles } from './SliderSection.styles';

// Import utils and helpers
import { useCommonFields, useTranslations } from 'components/utilities';

export const SliderSection = ({ title, fields }) => {
	const { renderFields } = useCommonFields();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid container spacing={5} className={classes.container}>
			<Grid item xs={12}>
				<Typography className={classes.title}>{t(title)}</Typography>
			</Grid>
			<Grid item xs={12}>
				<Grid container spacing={2}>
					{fields.map(renderFields)}
				</Grid>
			</Grid>
		</Grid>
	);
};

SliderSection.propTypes = {
	title: string.isRequired,
	fields: array.isRequired,
};
