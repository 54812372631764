import React from 'react';
import { object } from 'prop-types';
import { Grid, TableCell, Typography } from '@material-ui/core';
import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT } from 'helpers';

// Import styles
import { useStyles } from './CreatedAtTableCell.styles';

export const CreatedAtTableCell = ({ cell }) => {
	const classes = useStyles();

	const { created_at: createdAt, created_by: createdBy } = cell.row.original;
	const formatteCreatedAt = format(new Date(createdAt), DEFAULT_DATE_FORMAT);

	return (
		<TableCell key={cell.column.id} {...cell.getCellProps()}>
			<Grid container alignItems="center" spacing={2}>
				<Grid item xs="auto">
					<Typography className={classes.paragraph}>
						{formatteCreatedAt}
					</Typography>
				</Grid>
				{createdBy && (
					<Grid item xs="auto">
						<Typography className={classes.paragraph}>{createdBy}</Typography>
					</Grid>
				)}
			</Grid>
		</TableCell>
	);
};

CreatedAtTableCell.propTypes = {
	cell: object,
};
