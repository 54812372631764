import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		width: 'fit-content',
		borderRadius: 8,
		overflow: 'hidden',
		borderTop: `1px solid ${theme.palette.background[3]}`,
		borderBottom: `1px solid ${theme.palette.background[3]}`,
	},
	removeUpAndDownArrows: {
		'& input[type=number]': {
			'-moz-appearance': 'textfield',
		},
		'& input[type=number]::-webkit-outer-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
		'& input[type=number]::-webkit-inner-spin-button': {
			'-webkit-appearance': 'none',
			margin: 0,
		},
	},
	square: {
		width: 40,
		height: 40,
		backgroundColor: theme.palette.white,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',

		'& .MuiOutlinedInput-root': {
			minHeight: 'unset',
			height: '100%',
		},

		'& .MuiOutlinedInput-root.MuiOutlinedInput-root, & .MuiInputBase-input': {
			backgroundColor: theme.palette.white,
		},
	},
	button: {
		backgroundColor: theme.palette.background[6],
		borderRadius: 0,
		borderLeft: `1px solid ${theme.palette.background[3]}`,
		borderRight: `1px solid ${theme.palette.background[3]}`,
	},
}));
