import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(({ palette }) => ({
	heading: {
		fontSize: '18px',
		fontWeight: '600',
		color: palette.text[4],
		marginBottom: '24px',
	},
	btnsContainer: {
		marginTop: '40px',
		display: 'flex',
		justifyContent: 'flex-end',
		width: '100%',
	},
	cancel: {
		color: palette.text[1],
		padding: '19px 24px 20px 24px',
		height: '48px',
	},
	extend: {
		color: palette.white,
		backgroundColor: palette.primary.main,
		padding: '19px 24px 20px 24px',
		borderRadius: '12px',
		height: '48px',
		'&:hover': {
			backgroundColor: palette.primary.main,
		},
		'&:disabled': {
			backgroundColor: palette.background[7],
		},
	},
}));
