import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { BookingActions, BookingId } from './components';

export const TopBar = () => {
	return (
		<Grid container justifyContent="space-between" alignItems="center">
			<Grid item>
				<BookingId />
			</Grid>
			<Grid item>
				<BookingActions />
			</Grid>
		</Grid>
	);
};
