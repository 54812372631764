import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { CarAndCompany, CustomerAndBookingInfo } from './components';

export const Info = () => {
	return (
		<Grid container spacing={5}>
			<Grid item xs={12} md={4} lg={3}>
				<CarAndCompany />
			</Grid>
			<Grid item xs={12} md={8} lg={9}>
				<CustomerAndBookingInfo />
			</Grid>
		</Grid>
	);
};
