import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	icon: {
		width: '18px',
		height: '18px',
		color: theme.palette.primary.main,
		marginTop: theme.spacing(1),
	},
	text: {
		fontSize: '14px',
		fontWeight: '600',
		color: theme.palette.primary.main,
	},
}));
