import { useSelector } from 'react-redux';

// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { ADMIN } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useComments = () => {
	const { discussions } = useSelector(previewBookingSelector);
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	const adminComments =
		discussions?.filter((comment) => comment.type === ADMIN) ?? [];
	const agentComments =
		discussions?.filter((comment) => comment.type !== ADMIN) ?? [];

	const commentsSections = [
		{
			title: t('bookings.preview_booking.comments.telgani_comments'),
			comments: adminComments,
			isEmpty: !adminComments.length,
			isVisible: isAdmin,
			type: 'admin',
		},
		{
			title: t('bookings.preview_booking.comments.company_comments'),
			comments: agentComments,
			isEmpty: !agentComments.length,
			type: 'supplier',
		},
	];

	const noCommentsMessage = t('bookings.preview_booking.comments.no_comments');

	return {
		commentsSections,
		noCommentsMessage,
	};
};
