// Import helpers
import {
	convertFormDates,
	getFilterIndex,
	spreadFilterArray,
	ADMINS,
	COMPANY_MANAGERS_WITHOUT_ADMINS,
} from 'helpers';

export const initialValues = {
	service_type: 'all',
	pick_date: null,
	pick_date_to: null,
	city_uuid: ['all'],
	company_uuid: 'all',
	office_uuid: 'all',
};

export const formatValues = (values, { inputColumns }) => {
	const cityFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'city_uuid',
		defaultFilterIndex: 33,
	});

	return {
		...values,
		service_type: values.service_type === 'all' ? null : values.service_type,
		office_uuid: values.office_uuid === 'all' ? null : values.office_uuid,
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
		city_uuid: values.city_uuid?.[0] === 'all' ? null : values.city_uuid,
		...spreadFilterArray({
			arrayElements: values.city_uuid,
			filterIndex: cityFilterIndex,
			defaultFilterIndex: 33,
			inputColumns,
		}),
		...convertFormDates(values, ['pick_date']),
	};
};

export const PERMISSION_COMPANY_FILTER = ADMINS;
export const PERMISSION_OFFICE_FILTER = COMPANY_MANAGERS_WITHOUT_ADMINS;
