import React from 'react';

// Import store
import { fetchFaqs } from 'store/actions';

// Import components
import { OuterToolbarElements, TableBodyRow } from './components';

// Import helpers and utils
import { columns, COLUMNS_ACCESSORS } from './columns';
import { TableRPC } from 'components/utilities';

export const FaqsList = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchFaqs}
			title="faq.table.title"
			outerToolbarElements={<OuterToolbarElements />}
		/>
	);
};
