import {
	convertFormDates,
	getFilterIndex,
	removePreviousFilterValues,
	spreadFilterArray,
} from 'helpers';

export const initialValues = {
	urgent_assistance_status: 'all',
	compensation_status: 'all',
	company_uuid: ['all'],
	pick_date: null,
	pick_date_to: null,
	drop_date: null,
	drop_date_to: null,
};

export const formatValues = (values, { inputColumns }) => {
	const companyFilterIndex = getFilterIndex({
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: 18,
	});

	const newValues = removePreviousFilterValues({
		values,
		inputColumns,
		filterName: 'company_uuid',
		defaultFilterIndex: 18,
	});

	return {
		...newValues,
		...spreadFilterArray({
			arrayElements: values.company_uuid,
			filterIndex: companyFilterIndex,
			defaultFilterIndex: 18,
			inputColumns,
		}),
		urgent_assistance_status: values.urgent_assistance_status,
		compensation_status:
			values.compensation_status === 'all' ? null : values.compensation_status,
		...convertFormDates(values, ['pick_date', 'drop_date']),
		company_uuid: values.company_uuid === 'all' ? null : values.company_uuid,
	};
};
