import React from 'react';
import { Grid } from '@material-ui/core';

//Import styles
import { useStyles } from './ServiceType.styles';

// Import utilities and helpers
import { FIELDS } from './helpers';
import { useCommonFields } from 'components/utilities';

export const ServiceType = () => {
	const classes = useStyles();
	const { renderFields } = useCommonFields();

	return (
		<Grid container className={classes.radioWrapper}>
			{FIELDS.map(renderFields)}
		</Grid>
	);
};
