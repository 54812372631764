import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	AirportFilter,
	BaseTextFilter,
	BaseYesNoAllFilter,
	ServiceTypeFilter,
} from 'components/elements';

// Import helpers
import { SERVICES_TYPES } from 'helpers';

// Import styles
import { useStyles } from './FilterFields.styles';

export const FilterFields = () => {
	const classes = useStyles();

	return (
		<Grid
			container
			spacing={3}
			alignItems="center"
			className={classes.container}
		>
			<BaseTextFilter
				name="license_number"
				label="bookings.filters.license_number"
			/>
			<BaseYesNoAllFilter name="is_kiosk" label="bookings.filters.kiosk" />
			<ServiceTypeFilter
				name="service_type"
				label="bookings.filters.service_type"
			/>

			<AirportFilter
				name="airport_uuid"
				label="bookings.filters.airports"
				dependName="service_type"
				dependValue={SERVICES_TYPES.AIRPORT}
			/>
		</Grid>
	);
};
