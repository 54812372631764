import React from 'react';
import { Box } from '@material-ui/core';

// Import components
import { Grid, Typography } from 'components/elements';

// Import assets
import { ExclamationMarkWithCircleIcon } from 'assets/icons';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { PAYMENT_FIELD } from './helpers';

// Import styles
import { useStyles } from './PaymentMethod.styles';

export const PaymentMethod = () => {
	const { renderFields } = useCommonFields();
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box>
			<Grid container>{renderFields(PAYMENT_FIELD)}</Grid>
			<Box className={classes.noteContainer}>
				<ExclamationMarkWithCircleIcon className={classes.icon} />
				<Typography className={classes.note}>
					{t('bookings.create_booking.payment_sms_alert')}
				</Typography>
			</Box>
		</Box>
	);
};
