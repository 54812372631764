import React from 'react';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
} from '@material-ui/core';

// Import components
import { RateCard } from './components';

//Import utilities and helpers
import { useTranslations, useCommonFields } from 'components/utilities';
import { MONTHLY_RATE_FIELD, SELL_PRICE_FIELDS } from './helpers';
import { useLeasingRateModel } from './useLeasingRateModel';

// Import styles
import { useStyles } from './styles';

export const LeasingRateModel = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { renderFields } = useCommonFields();

	const {
		onActiveCheckboxChange,
		leasingCheckboxName,
		leasingRateCards,
		depositCard,
		leasingSellPriceName,
		isLeasingSellActive,
		isAdmin,
		isOneMonthDisabled,
		isLeasing,
	} = useLeasingRateModel();

	return (
		<Card>
			<CardHeader title={t('cars.form.leasing.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					{MONTHLY_RATE_FIELD({ isAdmin, isLeasing, isOneMonthDisabled }).map(
						renderFields
					)}

					<Grid container item xs={12} className={classes.cardsWrapper}>
						<Grid container item spacing={2}>
							{leasingRateCards.map(
								({ countedValue, label, name, disabled, required }) => (
									<Grid item key={name} xs={12} sm={4}>
										<RateCard
											countedValue={countedValue}
											label={label}
											name={name}
											disabled={disabled}
											required={required}
										/>
									</Grid>
								)
							)}
							<Grid item xs={12} sm={4}>
								<RateCard
									countedValue={depositCard.countedValue}
									label={depositCard.label}
									name={depositCard.name}
									disabled={depositCard.disabled}
									required={depositCard.required}
								/>
							</Grid>
						</Grid>
					</Grid>

					{SELL_PRICE_FIELDS({
						leasingCheckboxName,
						handleOnCheckboxClick: onActiveCheckboxChange,
						isLeasingSellActive,
						leasingSellPriceName,
						isAdmin,
						isLeasing,
					}).map(renderFields)}
				</Grid>
			</CardContent>
		</Card>
	);
};
