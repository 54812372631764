import React from 'react';

// Import utilities and helpers
import { useProfile, useTranslations } from 'components/utilities';
import { useFormContent } from './useFormContent';
import { BUTTON_PARAMS } from 'helpers';

// Import components
import { Button, Divider, Grid, Typography } from 'components/elements';
import { TopBar, DateFields } from './components';

export const FormContent = () => {
	const { isButtonDisabled, submitting, handleCancelButton } = useFormContent();
	const { t } = useTranslations();
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={7}>
			{isAdmin && (
				<>
					<Grid item xs={12}>
						<TopBar />
					</Grid>
					<Grid item xs={12}>
						<Divider />
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				<Typography fontWeight="600" fontSize={18}>
					{t('public_holidays.form.title')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<DateFields />
			</Grid>
			<Grid item xs={12}>
				<Grid
					container
					justifyContent="flex-end"
					alignItems="center"
					spacing={2}
				>
					<Grid item xs="auto">
						<Button
							type={BUTTON_PARAMS.TYPE.WHITE_FILLED} // TODO change type after marging another task
							text={t('common.buttons.cancel')}
							onClick={handleCancelButton}
						/>
					</Grid>
					<Grid item xs="auto">
						<Button
							text={t('common.buttons.submit')}
							disabled={isButtonDisabled}
							isLoading={submitting}
							buttonType="submit"
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
