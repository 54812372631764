import moment from 'moment';
import i18next from 'i18next';

// Import helpers
import { DMY_SLASH_FORMAT, YMD_DASH_FORMAT, TIME_FORMAT } from 'helpers';
import {
	createCustomer,
	createBooking,
	createBookingContract,
} from 'store/actions';
import { URLS } from 'components/routes';

export const transPrefix = 'bookings.create_booking.fields';

export const SERVICE_TYPES = {
	PICKUP: 'pickup',
	DELIVERY_AND_RETURN: 'delivery_and_return',
};

export const BOOKING_STEPS = {
	CREATE: 'create',
	REVIEW: 'review',
};

export const INITIAL_VALUES = {
	step: BOOKING_STEPS.CREATE,
	customerDetails: {
		phone_number: '',
		license_number: '',
		full_name: '',
		license_expire_date: null,
		birth_date: '',
		license_file: '',
		isNew: false,
	},
	service_type: SERVICE_TYPES.PICKUP,
	delivery: { lat: '', lng: '', address: '' },
	return: { lat: '', lng: '', address: '' },
	location: { lat: '', lng: '', address: '' },
	booking_owner_uuid: '',
	options: {
		delivery: null,
	},
	item: {
		uuid: '',
	},
	city: '',
	company: '',
	office: '',
	period: {
		pick_date: '',
		pick_time: '',
		drop_date: '',
		drop_time: '',
	},
	additional_services: {},
	chosenCar: null,
	licenseFileLink: '',
};

const convertCustomerData = (data) => {
	const formData = new FormData();
	formData.append('phone_number', '+' + data.phone_number);
	formData.append('license_number', data.license_number);
	formData.append('full_name', data.full_name);
	formData.append(
		'birth_date',
		moment(data.birth_date).format(DMY_SLASH_FORMAT)
	);
	formData.append(
		'license_expire_date',
		moment(data.license_expire_date).format(DMY_SLASH_FORMAT)
	);
	formData.append('license_file', data.license_file);
	return formData;
};

const getPeriodPayload = ({ pickDate, dropDate, pickTime }) => ({
	pick_date: moment(pickDate).format(YMD_DASH_FORMAT),
	drop_date: moment(dropDate).format(YMD_DASH_FORMAT),
	pick_time: moment(pickTime).format(TIME_FORMAT),
});

const getBookingPayload = (values) => {
	const {
		delivery,
		return: returnLocation,
		location,
		item,
		additional_services: additionalServices,
		booking_owner_uuid,
		service_type: serviceType,
		period: { pick_date: pickDate, drop_date: dropDate, pick_time: pickTime },
	} = values;
	const isPickup = serviceType === SERVICE_TYPES.PICKUP;

	if (isPickup) {
		return {
			location,
			item,
			booking_owner_uuid,
			options: {
				...additionalServices,
			},
			period: getPeriodPayload({ pickDate, dropDate, pickTime }),
		};
	} else {
		return {
			item,
			booking_owner_uuid,
			location: {
				lat: delivery.lat,
				lng: delivery.lng,
				address: delivery.address,
			},
			options: {
				delivery_location: {
					delivery_lat: delivery.lat,
					delivery_lng: delivery.lng,
				},
				drop_location: {
					delivery_lat: returnLocation.lat,
					delivery_lng: returnLocation.lng,
				},
				...additionalServices,
			},
			period: getPeriodPayload({ pickDate, dropDate, pickTime }),
		};
	}
};

const createBookingSuccessCallback = ({ resp, history, message }) => {
	const uuid = resp.data.data.uuid;
	const data = {
		billing_uuid: resp.data.data.billing_uuid,
	};
	return createBookingContract({
		uuid,
		data,
		successCallback: () => {
			history.push(URLS.previewBookingUrl.replace(':id', resp.data.data.uuid));
		},
		errorCallback: (error) => createBookErrorCallback({ error, message }),
	});
};

const createBookErrorCallback = ({ error, message }) => {
	if (error?.response?.data?.error) {
		message.error(error.response.data.error);
	} else {
		message.error(i18next.t('common.messages.error_message'));
	}
};

export const handleSubmit = ({ values, history, message }) => {
	if (values.customerDetails.isNew) {
		const data = convertCustomerData(values.customerDetails);
		createCustomer({
			data,
			successCallback: (resp) => {
				values.booking_owner_uuid = resp.data.user_uuid;
				const data = getBookingPayload(values);
				createBooking({
					data,
					successCallback: (resp) =>
						createBookingSuccessCallback({ resp, history, message }),
					errorCallback: (error) => createBookErrorCallback({ error, message }),
				});
			},
			errorCallback: (error) => createBookErrorCallback({ error, message }),
		});
	} else {
		const data = getBookingPayload(values);
		createBooking({
			data,
			successCallback: (resp) => {
				createBookingSuccessCallback({ resp, history, message });
			},
			errorCallback: (error) => createBookErrorCallback({ error, message }),
		});
	}
};
