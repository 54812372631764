import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useBookingRatingSummary = ({ bookingUuid, isOpen }) => {
	const enabled = isOpen && !!bookingUuid;

	const { data, isFetching } = useQuery(
		QUERY_KEYS.BOOKINGS.RATING_SUMMARY({ bookingUuid }),
		async () =>
			await services.get(ENDPOINTS.BOOKINGS.RATING_SUMMARY({ bookingUuid })),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : null;

	return { data: fetchedData, isFetching };
};
