import React from 'react';

// Import components
import { Export, AddCampaignDialog } from '../../components';
import { Grid } from 'components/elements';

export const OuterToolbarElements = () => {
	return (
		<Grid container spacing={2}>
			<Grid item>
				<AddCampaignDialog />
			</Grid>
			<Grid item>
				<Export />
			</Grid>
		</Grid>
	);
};
