import React, { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import { Box, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { LoadingWrapper } from 'components/elements';

// Import styles
import { useStyles } from './CitiesList.styles';

// Import utils
import { usePickupCitiesCardProvider } from '../../context';
import { useTranslations } from 'components/utilities';

export const CitiesList = () => {
	const { filteredCities, citiesLoading, isCityUnsaved, selectCity } =
		usePickupCitiesCardProvider();
	const { values } = useFormState();
	const { change } = useForm();
	const classes = useStyles();
	const { t } = useTranslations();

	useEffect(() => {
		change('selected_city_in', null);
		change('out_data', {});

		// eslint-disable-next-line
	}, [values.company_uuid]);

	if (citiesLoading) {
		return <LoadingWrapper className={classes.loadingWrapper} />;
	}

	return (
		<Box className={classes.container}>
			<Box component="ul" className={classes.list}>
				{filteredCities.map(({ uuid, name }) => (
					<Box
						onClick={() => selectCity(uuid)}
						component="li"
						key={uuid}
						className={clsx({
							[classes.selectedCityWrapper]: values.selected_city_in === uuid,
							[classes.cityWrapper]: true,
						})}
					>
						<Typography
							className={clsx({
								[classes.selectedCityText]: values.selected_city_in === uuid,
								[classes.unsavedCityText]: isCityUnsaved(uuid),
								[classes.cityText]: true,
							})}
						>
							{name}
						</Typography>
						{isCityUnsaved(uuid) && (
							<Typography className={classes.unsaved}>
								{t('common.unsaved')}
							</Typography>
						)}
					</Box>
				))}
			</Box>
		</Box>
	);
};
