import React from 'react';
import { Box } from '@material-ui/core';

// Import components
import { Grid, Typography } from 'components/elements';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useCustomerDetails } from './useCustomerDetails';

// Import styles
import { useStyles } from './CustomerDetails.styles';

export const CustomerDetails = () => {
	const { renderFields } = useCommonFields();
	const { FIELDS } = useCustomerDetails();
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box>
			<Typography className={classes.subtitle}>
				{t('bookings.create_booking.personal_info')}
			</Typography>
			<Grid container spacing={3}>
				{FIELDS.map(renderFields)}
			</Grid>
		</Box>
	);
};
