import React from 'react';
import PropTypes from 'prop-types';
import { TableCell, IconButton } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

// Import components
import {
	TransactionHistory,
	TransactionHistoryProvider,
} from 'components/elements';

// Import utilities
import { useToggle } from 'components/utilities';

const TransactionHistoryTableCell = ({ cell }) => {
	const { on, toggle } = useToggle();

	return (
		<TableCell>
			{on && (
				<TransactionHistoryProvider bookingId={cell.row.original.uuid}>
					<TransactionHistory open={on} onClose={toggle} />
				</TransactionHistoryProvider>
			)}
			<IconButton onClick={toggle}>
				<AccountBalanceWalletIcon color="primary" />
			</IconButton>
		</TableCell>
	);
};

TransactionHistoryTableCell.propTypes = {
	cell: PropTypes.object,
};

export default TransactionHistoryTableCell;
