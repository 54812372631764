import { useEffect } from 'react';
import { useForm, useFormState } from 'react-final-form';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';

// Import utils and helpers
import { useCompanyPublicHolidaysList } from 'queries';
import { INITIAL_VALUES } from '../../helpers';
import { useProfile } from 'components/utilities';
import { URLS } from 'components/routes';

export const useFormContent = () => {
	const { values, submitting } = useFormState();
	const { change } = useForm();
	const { isAdmin, companyUuid } = useProfile();
	const history = useHistory();

	const {
		public_holidays: publicHolidaysFormValues,
		company_uuids: companyUuids,
	} = values;

	useEffect(() => {
		!isAdmin && change('company_uuids', [companyUuid]);
		// eslint-disable-next-line
	}, [isAdmin]);

	const { data: publicHolidays } = useCompanyPublicHolidaysList({
		companyUuids,
	});

	const getButtonDisabledState = () => {
		const someRowSelected = Object.values(publicHolidaysFormValues)
			.map(({ selected }) => selected)
			.some((selected) => selected);

		return !someRowSelected || (isAdmin && !companyUuids.length);
	};

	const isButtonDisabled = getButtonDisabledState();

	useEffect(() => {
		if (!_.isEmpty(publicHolidays)) {
			change('public_holidays', publicHolidays);
		}

		// eslint-disable-next-line
	}, [publicHolidays]);

	useEffect(() => {
		if (!companyUuids.length) {
			change('public_holidays', INITIAL_VALUES.public_holidays);
		}

		// eslint-disable-next-line
	}, [companyUuids.length]);

	const handleCancelButton = () => history.push(URLS.dashboardUrl);

	return {
		isButtonDisabled,
		submitting,
		handleCancelButton,
	};
};
