import React from 'react';
import { Grid, CircularProgress, MenuItem, Checkbox } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';
import { number, string } from 'prop-types';

// Import components
import { MultiselectFiledAdapter } from 'components/elements/molecules';

// Import utils
import { useTranslations } from 'components/utilities';

// Import queries
import { usePromoCodesLookup } from 'queries';

export const PromoCodesFilter = ({ name, xs = 12, md = 6 }) => {
	const { submitting, values } = useFormState();

	const { t } = useTranslations();

	const { data: promoCodes, isFetching } = usePromoCodesLookup();

	const currentPromoCodes = values[name] || [];

	if (!promoCodes.length) return null;

	const activePromoCodes = promoCodes.filter((code) => code.active);

	return (
		<Grid container item xs={xs} md={md} alignItems="center">
			{isFetching ? (
				<Grid item xs>
					<CircularProgress size={27} />
				</Grid>
			) : (
				<Grid item xs>
					<Field
						format={(value) => (Array.isArray(value) ? value : [])}
						label={t('promo_codes.filters.promo_code')}
						component={MultiselectFiledAdapter}
						disabled={submitting}
						variant="outlined"
						margin="dense"
						type="select"
						name={name}
						fullWidth
					>
						{activePromoCodes.map(({ code }) => (
							<MenuItem key={code} value={code}>
								<Checkbox
									checked={currentPromoCodes.includes(code)}
									color="primary"
								/>
								{code}
							</MenuItem>
						))}
					</Field>
				</Grid>
			)}
		</Grid>
	);
};

PromoCodesFilter.propTypes = {
	name: string.isRequired,
	xs: number,
	md: number,
};
