import React from 'react';
import { Divider } from '@material-ui/core';

//Import components
import { Card, Typography } from 'components/elements';
import { TitleWrapper, ReviewBooking } from './components';

// import styles
import { useStyles } from './FormContent.styles';

// Import utilities and helpers
import { useFormContent } from './useFormContent';
import { useTranslations } from 'components/utilities';

export const FormContent = () => {
	const { formContent, isStepCreate } = useFormContent();
	const classes = useStyles();
	const { t } = useTranslations();

	if (!isStepCreate) return <ReviewBooking />;

	return (
		<>
			<Typography className={classes.title}>
				{t('bookings.create_booking.title')}
			</Typography>
			<Card lg={12} className={classes.card}>
				{formContent.map(
					({ id, component: Component, isDivider, title, info }) => (
						<TitleWrapper key={id} title={title} info={info}>
							<Component />
							{isDivider && <Divider className={classes.divider} />}
						</TitleWrapper>
					)
				)}
			</Card>
		</>
	);
};
