import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const UploadFileIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<path
			id="Path"
			d="M3 15H7.382C7.76093 15 8.10731 15.2142 8.27662 15.5532L8.72294 16.4468C8.89225 16.7858 9.23861 17 9.61753 17H14.3815C14.7602 17 15.1065 16.786 15.2759 16.4472L15.7231 15.5528C15.8925 15.214 16.2388 15 16.6175 15H21"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_2"
			d="M12 7.44458V12.4446"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_3"
			d="M14 9.44495L12 7.44495L10 9.44495"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			id="Path_4"
			fillRule="evenodd"
			clipRule="evenodd"
			d="M18 3C19.6569 3 21 4.34315 21 6V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18V6C3 4.34315 4.34315 3 6 3H18Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
