import React from 'react';
import { array, bool, string } from 'prop-types';
import { Field, useFormState } from 'react-final-form';
import { Grid, InputAdornment } from '@material-ui/core';

// Import components
import { TextFieldAdapter, CheckboxFieldAdapter } from 'components/elements';

// Import translations
import { useTranslations } from 'components/utilities';

const PeriodPaymentsField = ({ fields, checkboxName, labelText, isOpen }) => {
	const { t } = useTranslations();

	const { submitting } = useFormState();

	return (
		<>
			<Grid item xs={12}>
				<Field
					type="checkbox"
					component={CheckboxFieldAdapter}
					name={checkboxName}
					disabled={submitting}
					labelText={t(labelText)}
					color="primary"
				/>
			</Grid>

			{isOpen && (
				<Grid container spacing={2}>
					{fields.map(({ label, name }) => (
						<Grid item xs={6} key={name}>
							<Field
								fullWidth
								component={TextFieldAdapter}
								type="number"
								label={t(label)}
								name={name}
								margin="dense"
								variant="outlined"
								disabled={submitting}
								required
								inputProps={{ step: 0.01, min: 0 }}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{t('currency.sar')}
										</InputAdornment>
									),
								}}
							/>
						</Grid>
					))}
				</Grid>
			)}
		</>
	);
};

PeriodPaymentsField.propTypes = {
	labelText: string.isRequired,
	isOpen: bool,
	fields: array.isRequired,
	checkboxName: string.isRequired,
};

export default PeriodPaymentsField;
