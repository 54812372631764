// Import utils and helpers
import { ACTION_MENU_POPUP_FIELD_TYPES, replaceEditSliderUrl } from 'helpers';

// Import store
import { deleteSlider, updateActiveSlider } from 'store/actions';

const { ACTIVE, EDIT, DELETE } = ACTION_MENU_POPUP_FIELD_TYPES;

export const useCell = (cell) => {
	const { active: isActive, uuid: id, show_in: showIn } = cell.row.original;

	const editViewUrl = `${replaceEditSliderUrl(id)}?show_in=${showIn}`;

	const activeAction = () => updateActiveSlider({ isActive, id });
	const deleteAction = () => deleteSlider({ id });

	const actionMenuFields = [
		{
			type: ACTIVE,
			isDivider: true,
			activeState: isActive,
			activeAction,
		},

		{ type: DELETE, deleteAction, isDivider: true },
		{ type: EDIT, editViewUrl },
	];

	return {
		actionMenuFields,
		editViewUrl,
		isActive,
	};
};
