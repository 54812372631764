import React from 'react';
import { bool, object } from 'prop-types';
import { TableCell } from '@material-ui/core';

// Import utils and helpers
import { COLUMNS_ACCESSORS } from '../../../../columns';
import { useCell } from './useCell';
import { activeList } from './helpers';

// Import components
import {
	CommonStatusTableCell,
	ImageTableCell,
	NewActionsMenuTableCell,
} from 'components/elements';
import { CreatedAtTableCell } from '../../../../components';

const { IMAGE, ACTIONS, CREATED_AT, STATUS } = COLUMNS_ACCESSORS;

export const Cell = ({ isHovered, cell }) => {
	const { actionMenuFields, isActive, editViewUrl } = useCell(cell);

	const renderCell = (cell) => {
		switch (cell.column.id) {
			case IMAGE:
				return (
					<ImageTableCell
						cell={cell}
						key={cell.column.id}
						imgUrl={cell.row.original.media}
					/>
				);

			case CREATED_AT:
				return <CreatedAtTableCell key={cell.column.id} cell={cell} />;

			case STATUS:
				return (
					<CommonStatusTableCell
						currentStatus={isActive ? 'active' : 'inactive'}
						statusList={activeList}
						key={cell.column.id}
						cell={cell}
					/>
				);

			case ACTIONS:
				return (
					<NewActionsMenuTableCell
						previewPopupFields={actionMenuFields}
						isHovered={isHovered}
						key={cell.column.id}
						extraActions={{
							editAction: true,
							editPath: editViewUrl,
						}}
						cell={cell}
					/>
				);

			default:
				return (
					<TableCell key={cell.column.id} {...cell.getCellProps()}>
						{cell.render('Cell')}
					</TableCell>
				);
		}
	};

	return renderCell(cell);
};

Cell.propTypes = {
	cell: object.isRequired,
	isHovered: bool,
};
