import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';

// Import styles
import { useStyles } from './DropCities.styles';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { useDropCities } from './useDropCities';
import { INITIAL_VALUES } from './helpers';

// Import components
import { Card, Grid } from 'components/elements';
import { FormContent } from './components';

export const DropCities = () => {
	const { handleOnSubmit } = useDropCities();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Box className={classes.container}>
			<Grid container spacing={4}>
				<Grid item>
					<Typography className={classes.title}>
						{t('nav.companies.drop_cities')}
					</Typography>
				</Grid>
				<Card lg={12}>
					<Form
						onSubmit={handleOnSubmit}
						initialValues={INITIAL_VALUES}
						render={({ handleSubmit }) => (
							<form onSubmit={handleSubmit}>
								<FormContent />
							</form>
						)}
					/>
				</Card>
			</Grid>
		</Box>
	);
};
