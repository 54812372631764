import React from 'react';
import { bool, node, number, string } from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

// Import styles
import { useStyles } from './TableNavbar.styles';

// Import utils
import { useTranslations } from 'components/utilities';
import { LoadingWrapper } from 'components/elements';

export const TableNavbar = ({
	title,
	outerToolbarElements,
	titleLoading,
	navbarMinHeight = 100,
	titleStyles = '',
}) => {
	const { t } = useTranslations();
	const classes = useStyles({ navbarMinHeight });

	return (
		<Grid
			className={classes.container}
			justifyContent="space-between"
			alignItems="center"
			container
		>
			<Grid item xs="auto">
				{titleLoading ? (
					<LoadingWrapper size={24} />
				) : (
					<Typography className={clsx([classes.title, titleStyles])}>
						{t(title)}
					</Typography>
				)}
			</Grid>
			{outerToolbarElements && (
				<Grid item xs="auto">
					{outerToolbarElements}
				</Grid>
			)}
		</Grid>
	);
};

TableNavbar.propTypes = {
	outerToolbarElements: node,
	titleLoading: bool,
	title: string,
	navbarMinHeight: number,
	titleStyles: string,
};
