import React from 'react';
import { string, node } from 'prop-types';
import { Box } from '@material-ui/core';

// Import components
import { Typography } from 'components/elements';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './TitleWrapper.styles';

export const TitleWrapper = ({ children, title, info = '' }) => {
	const classes = useStyles();
	const { t } = useTranslations();

	return (
		<Box>
			<Typography className={info ? classes.titleWithInfo : classes.title}>
				{t(title)}
			</Typography>
			{info && <Typography className={classes.info}>{t(info)}</Typography>}
			{children}
		</Box>
	);
};

TitleWrapper.propTypes = {
	title: string.isRequired,
	info: string,
	children: node.isRequired,
};
