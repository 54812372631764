// Import helpers
import { getCheckedOptions, getServiceType } from 'helpers';

export const COLUMN_ACCESSORS = {
	OPTIONS: 'options',
};

export const CAR_INFO_FIELDS = [
	{
		accessor: 'manufacturer',
		label: 'common.fields.manufacture',
	},
	{
		accessor: 'service',
		label: 'common.fields.service',
	},
	{
		accessor: COLUMN_ACCESSORS.OPTIONS,
		label: '',
	},
];

export const convertCarDetails = ({ booking }) => {
	const { item, options } = booking || {};
	const { name: manufacturer } = item || {};

	const service = getServiceType(booking);

	const formattedOptions = getCheckedOptions(options);

	return {
		manufacturer,
		service,
		options: formattedOptions,
	};
};
