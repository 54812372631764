import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	title: {
		fontSize: '28px',
		fontWeight: 500,
		margin: '24px 0 32px 0',
	},
	flex: {
		display: 'flex',
		flexDirection: 'column',
		gap: '24px',
	},
	sectionTitle: {
		fontSize: '18px',
		fontWeight: 600,
		marginBottom: '32px',
	},
}));
