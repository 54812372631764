export const COLUMNS_ACCESSORS = {
	CANCELLATION_DATE: 'cancellation_date',
	CUSTOMER_NAME: 'customer_name',
	BOOKING_ID: 'booking_id',
	PICK_DATE: 'pick_date',
	COMPANY: 'company',
	ACTIONS: 'actions',
	STATUS: 'status',
};

export const columns = [
	{
		Header: 'common.fields.cancellation_reason',
		accessor: 'cancel_description',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.pick_date',
		accessor: COLUMNS_ACCESSORS.PICK_DATE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.amount',
		accessor: 'price',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.booking_id',
		accessor: COLUMNS_ACCESSORS.BOOKING_ID,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.company_name',
		accessor: COLUMNS_ACCESSORS.COMPANY,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.customer_name',
		accessor: COLUMNS_ACCESSORS.CUSTOMER_NAME,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.cancellation_date',
		accessor: COLUMNS_ACCESSORS.CANCELLATION_DATE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.office_rating',
		accessor: 'last_booking_office_rating',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.security_deposit',
		accessor: 'security_deposit',
		disableSortBy: true,
	},
	{
		Header: 'common.fields.status',
		accessor: COLUMNS_ACCESSORS.STATUS,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
