import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useCompanyDropCitiesQuery = (companyUuid) => {
	const path = `/v2/supplier/business/company/${companyUuid}/drop-city/list`;

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.COMPANIES.DROP_CITIES_LIST(companyUuid),
		async () => await services.get(path),
		{
			refetchOnWindowFocus: false,
			enabled: !!companyUuid,
		}
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching, isError };
};
