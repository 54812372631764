import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		width: '360px',
		backgroundColor: theme.palette.white,
		position: 'fixed',
		right: '16px',
		bottom: 0,
		borderLeft: `1px solid ${theme.palette.background[5]}`,
		height: `calc(100% - 80px)`,
	},
	title: {
		fontSize: 14,
		fontWeight: 500,
		color: theme.palette.text[3],
		marginBottom: theme.spacing(4),
	},
	infoContainer: {
		padding: '24px',
	},
	boldLabel: {
		fontWeight: 600,
		color: theme.palette.text[4],
	},
	divider: {
		margin: theme.spacing(2, 0),
		width: '100%',
		backgroundColor: theme.palette.background[6],
	},
}));
