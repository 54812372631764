import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	divider: {
		margin: '48px 0',
		width: '100%',
	},
	infoContainer: {
		marginBottom: '48px',
	},
	oldDeliveryCard: {
		padding: '10px',
		marginBottom: theme.spacing(2),
		width: '100%',
	},
	oldDeliveryHeader: {
		'& .MuiGrid-item': {
			padding: 0,
		},
	},
}));
