import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const StarIcon = (props) => (
	<SvgIcon width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.86562 10.4163C3.61208 10.5486 3.30536 10.5253 3.07473 10.3562C2.84409 10.1871 2.72966 9.90153 2.77967 9.61994L3.18434 7.30047L1.48063 5.66779C1.27238 5.46919 1.19606 5.16899 1.28417 4.89505C1.37229 4.62111 1.60932 4.4217 1.8943 4.38176L4.25978 4.04361L5.32773 1.91323C5.45435 1.65773 5.71486 1.49609 6.00001 1.49609C6.28516 1.49609 6.54567 1.65773 6.67229 1.91323L7.74023 4.04361L10.1057 4.38176C10.3907 4.4217 10.6277 4.62111 10.7158 4.89505C10.804 5.16899 10.7276 5.46919 10.5194 5.66779L8.81568 7.30047L9.22035 9.62044C9.27035 9.90203 9.15592 10.1876 8.92529 10.3567C8.69466 10.5258 8.38793 10.5491 8.1344 10.4168L6.00001 9.31381L3.86562 10.4163Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			fillRule="evenodd"
			clipRule="evenodd"
			d="M3.86562 10.4163C3.61208 10.5486 3.30536 10.5253 3.07473 10.3562C2.84409 10.1871 2.72966 9.90153 2.77967 9.61994L3.18434 7.30047L1.48063 5.66779C1.27238 5.46919 1.19606 5.16899 1.28417 4.89505C1.37229 4.62111 1.60932 4.4217 1.8943 4.38176L4.25978 4.04361L5.32773 1.91323C5.45435 1.65773 5.71486 1.49609 6.00001 1.49609C6.28516 1.49609 6.54567 1.65773 6.67229 1.91323L7.74023 4.04361L10.1057 4.38176C10.3907 4.4217 10.6277 4.62111 10.7158 4.89505C10.804 5.16899 10.7276 5.46919 10.5194 5.66779L8.81568 7.30047L9.22035 9.62044C9.27035 9.90203 9.15592 10.1876 8.92529 10.3567C8.69466 10.5258 8.38793 10.5491 8.1344 10.4168L6.00001 9.31381L3.86562 10.4163Z"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
