import React from 'react';
import { useFormState } from 'react-final-form';

// Import components
import { MultiManufacturerFilter, MultiYearsFilter } from 'components/elements';

export const AdditionalFilterFields = () => {
	const { values } = useFormState();

	const { manufacturers } = values || {};

	return (
		<>
			<MultiManufacturerFilter
				name="manufacturers"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
			/>
			<MultiYearsFilter
				name="years"
				xs="auto"
				md="auto"
				showEmptyLabel
				smallFieldStyle
				disabled={!manufacturers || manufacturers === 'all'}
				manufacturerUuid={manufacturers}
			/>
		</>
	);
};
