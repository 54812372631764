import React from 'react';
import { Field } from 'react-final-form';
import { string } from 'prop-types';
import {
	Card,
	CardHeader,
	CardContent,
	Divider,
	Grid,
	CircularProgress,
	Typography,
} from '@material-ui/core';

// Import components
import { Alert, CheckboxFieldAdapter } from 'components/elements';
import { RateCard } from './components';

//Import utilities
import { useTranslations } from 'components/utilities';
import { useSubscriptionModel } from './useSubscriptionModel';

// Import styles
import { useStyles } from './styles';

export const SubscriptionModel = ({ section, ...props }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		onActiveCheckboxChange,
		activeCheckboxName,
		submitting,
		cards,
		isActiveCheckboxDisabled,
		isSubscriptionActive,
		isCardContainerShown,
		isFetching,
		isAdmin,
		isError,
	} = useSubscriptionModel({ section });

	return (
		<Card {...props}>
			<CardHeader title={t('cars.form.subscription_model.title')} />
			<Divider />
			<CardContent>
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<Field
							component={CheckboxFieldAdapter}
							type="checkbox"
							labelText={t(
								'cars.form.subscription_model.active_subscription_checkbox_label'
							)}
							name={activeCheckboxName}
							disabled={!isAdmin || submitting || isActiveCheckboxDisabled}
							color="primary"
							checked={isSubscriptionActive}
							onChange={onActiveCheckboxChange}
						/>
					</Grid>

					{isActiveCheckboxDisabled && (
						<Grid item xs={12}>
							<Typography color="error">
								{t('cars.form.subscription_model.checkbox_message')}
							</Typography>
						</Grid>
					)}

					{isCardContainerShown && (
						<Grid container item xs={12} className={classes.cardsWrapper}>
							{isFetching ? (
								<CircularProgress />
							) : isError ? (
								<Grid item xs={12}>
									<Alert message={t('common.messages.error_message')} />
								</Grid>
							) : (
								<Grid container item spacing={2}>
									{cards.map(
										({
											calculationsChanged,
											countedValue,
											label,
											name,
											disabled,
											required,
										}) => (
											<Grid item key={name} xs={12} sm={4}>
												<RateCard
													calculationsChanged={calculationsChanged}
													countedValue={countedValue}
													label={label}
													name={name}
													disabled={disabled}
													required={required}
												/>
											</Grid>
										)
									)}
								</Grid>
							)}
						</Grid>
					)}
				</Grid>
			</CardContent>
		</Card>
	);
};

SubscriptionModel.propTypes = {
	section: string,
};
