import { useParams, useHistory } from 'react-router-dom';

// Import utils and helpers
import { useToggle } from 'components/utilities';
import { replaceAddLeasingCarUrl } from 'helpers';

export const useOuterToolbarElements = () => {
	const { id: officeId } = useParams();
	const { push } = useHistory();
	const { on: isDialogOpen, toggle: toggleDialog } = useToggle();

	const goToAddCar = () => push(replaceAddLeasingCarUrl(officeId));

	return { isDialogOpen, toggleDialog, officeId, goToAddCar };
};
