import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const SingleUserIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M16.6693 16.6667V16.0417C16.6693 14.0857 15.0836 12.5 13.1276 12.5H6.8776C4.9216 12.5 3.33594 14.0857 3.33594 16.0417V16.6667"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<ellipse
			cx="9.9974"
			cy="5.83333"
			rx="3.33333"
			ry="3.33333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
