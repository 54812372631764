import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useBookingStates = () => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.BOOKINGS.STATUSES,
		async () => await services.get(ENDPOINTS.BOOKINGS.BOOKING_STATES),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data ?? [];

	return { data: fetchedData, isFetching };
};

export default useBookingStates;
