import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

// Import components
import { Grid, Typography } from 'components/elements';

// Import utilities and helpers
import { useCommonFields, useTranslations } from 'components/utilities';
import { useAdditionalServices } from './useAdditionalServices';

// Import styles
import { useStyles } from './AdditionalServices.styles';

export const AdditionalServices = () => {
	const { renderFields } = useCommonFields();
	const classes = useStyles();
	const { t } = useTranslations();
	const { FIELDS, isSelected, loading } = useAdditionalServices();

	if (loading) return <CircularProgress />;

	return (
		<Box>
			{FIELDS.map(({ price, ...rest }) => {
				const selected = isSelected(rest.name);
				return (
					<Grid container className={classes.fieldsContainer} key={rest.name}>
						{renderFields(rest)}
						<Typography
							className={selected ? classes.selectedPrice : classes.price}
						>
							({t('common.fields.sr', { value: price })})
						</Typography>
					</Grid>
				);
			})}
		</Box>
	);
};
