import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	iconWrapper: {
		display: 'flex',
	},
	icon: {
		color: theme.palette.primary.main,
	},
}));
