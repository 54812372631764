import React from 'react';
import { object, string } from 'prop-types';
import {
	Box,
	Button,
	Dialog,
	DialogContent,
	Typography,
	Grid,
} from '@material-ui/core';

// Import components
import { ClosableDialogTitle, PreviewField } from 'components/elements';

// Import utils
import { useWorkingHoursPreviewField } from './useWorkingHoursPreviewField';
import { useTranslations } from 'components/utilities';

// Import assets
import { ClockIcon, OfficeIcon } from 'assets/icons';

// Import styles
import { useStyles } from './WorkingHoursPreviewField.styles';
import clsx from 'clsx';

export const WorkingHoursPreviewField = ({ customData, label }) => {
	const classes = useStyles();
	const { t } = useTranslations();
	const { isOpen, toggle, officeName, officeHours, todayDay } =
		useWorkingHoursPreviewField(customData);

	return (
		<PreviewField label={label}>
			<Button
				className={classes.button}
				color="primary"
				startIcon={<ClockIcon />}
				onClick={toggle}
			>
				{t('common.buttons.schedule')}
			</Button>
			<Dialog open={isOpen} onClose={toggle} maxWidth="sm" fullWidth>
				<ClosableDialogTitle
					title={t('common.fields.work_hours')}
					onClose={toggle}
				/>
				<DialogContent className={classes.dialogContent}>
					<Box className={classes.officeNameContainer}>
						<OfficeIcon />
						<Typography
							variant="body1"
							color="inherit"
							className={classes.officeName}
						>
							{officeName}
						</Typography>
					</Box>
					{officeHours.map(({ day, hours }) => (
						<Grid container key={day}>
							<Grid item xs={8}>
								<Grid
									container
									alignItems="center"
									justifyContent="space-between"
									className={classes.hoursContainer}
								>
									<Grid item>
										<Typography
											variant="body1"
											className={clsx({
												[classes.day]: true,
												[classes.today]: day === todayDay,
											})}
										>
											{day}
										</Typography>
									</Grid>
									<Grid item>
										<Typography
											variant="body1"
											className={clsx({
												[classes.hours]: true,
												[classes.today]: day === todayDay,
											})}
										>
											{hours}
										</Typography>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					))}
				</DialogContent>
			</Dialog>
		</PreviewField>
	);
};

WorkingHoursPreviewField.propTypes = {
	customData: object,
	label: string,
};
