import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const UserWithKeyIcon = (props) => (
	<SvgIcon width="16" height="17" viewBox="0 0 16 17" fill="none" {...props}>
		<path
			d="M7.33333 10.5H4.66667C3.19391 10.5 2 11.6939 2 13.1667V13.8333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<circle
			cx="7.33073"
			cy="5.16667"
			r="2.66667"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.6639 11.5C14.6639 12.7887 13.6193 13.8333 12.3306 13.8333C12.0988 13.8301 11.8688 13.7919 11.6484 13.7201"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.3359 9.16406C13.6246 9.16406 14.6693 10.2087 14.6693 11.4974"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.1126 12.1776C10.0412 11.9578 10.0032 11.7285 10 11.4974C10 10.2087 11.0447 9.16406 12.3333 9.16406"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M10.11 12.1797L8.84558 13.446C8.7296 13.563 8.66438 13.7209 8.66406 13.8856V14.5443C8.66407 14.8877 8.94246 15.1661 9.28589 15.1661H9.9474C10.1125 15.1663 10.2708 15.1005 10.387 14.9832L11.6486 13.7196"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.3667 11.4974C12.3666 11.5158 12.3517 11.5307 12.3333 11.5307C12.3149 11.5307 12.3 11.5158 12.3 11.4974C12.3 11.479 12.3149 11.4641 12.3333 11.4641C12.3421 11.4641 12.3506 11.4676 12.3569 11.4738C12.3632 11.4801 12.3667 11.4886 12.3667 11.4974"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
