import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	divider: {
		margin: '48px 0',
		width: '100%',
	},
	title: {
		fontSize: '18px',
		fontWeight: 500,
		marginBottom: theme.spacing(4),
	},
}));
