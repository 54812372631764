import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { object, string, bool } from 'prop-types';
import { TableCell, Grid, Button, Link } from '@material-ui/core';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import components
import { TransactionHistoryPart, PrintPart } from './components';
import { BookingHistoryActionButton } from 'components/elements';

// Import styles
import { useStyles } from './styles';

const ActionsTableCell = ({
	cell,
	editLinkPath,
	canEdit = true,
	minWidth = 'auto',
}) => {
	const { t } = useTranslations();

	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			{...cell.getCellProps()}
			width={150}
			style={{ minWidth }}
		>
			{canEdit && (
				<Link component={RouterLink} to={editLinkPath}>
					<Button
						color="primary"
						variant="outlined"
						size="small"
						className={classes.editButton}
					>
						{t('table.headers.edit')}
					</Button>
				</Link>
			)}
			<Grid container spacing={1} className={classes.actionIcons}>
				<Grid item xs={4}>
					<BookingHistoryActionButton cell={cell} />
				</Grid>
				<Grid item xs={4}>
					<TransactionHistoryPart cell={cell} />
				</Grid>
				<Grid item xs={4}>
					<PrintPart cell={cell} />
				</Grid>
			</Grid>
		</TableCell>
	);
};

ActionsTableCell.propTypes = {
	cell: object.isRequired,
	editLinkPath: string.isRequired,
	canEdit: bool,
	minWidth: string,
};
export default ActionsTableCell;
