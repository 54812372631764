import { useEffect } from 'react';
import { useQuery } from 'react-query';

// Import helpers
import { fetchOfficesCars } from './helpers';
import { QUERY_KEYS } from 'helpers';
import { useTranslations } from 'components/utilities';

const useOfficesCars = ({ model_name, models, year, managerProfileUuid }) => {
	const { i18n } = useTranslations();

	const lng = i18n.language;

	useEffect(() => {
		refetch();
		//eslint-disable-next-line
	}, [model_name[lng] && year, model_name[lng]]);

	const body = {
		models,
		model_name,
		year,
		managerProfileUuid,
		lng,
	};

	const { data, isError, isFetching, refetch } = useQuery(
		QUERY_KEYS.CARS.OFFICE_CARS(JSON.stringify(body)),
		async () => {
			return await fetchOfficesCars(body);
		},
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};

export default useOfficesCars;
