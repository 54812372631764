import React from 'react';
import { object } from 'prop-types';

// Import components
import { ReceiptDetailsDialog } from './components';
import { Button } from 'components/elements';

// Import utils
import { useReceiptDetailsTableCell } from './useReceiptIdTableCell';

// Import styles
import { useStyles } from './ReceiptIdTableCell.styles';

export const ReceiptIdTableCell = ({ row }) => {
	const { number } = row || {};

	const { isReceiptDialogOpen, toggleReceiptDialog } =
		useReceiptDetailsTableCell();

	const classes = useStyles();

	return (
		<>
			{number && (
				<Button
					text={number}
					onClick={toggleReceiptDialog}
					type="primary_text"
					className={classes.button}
				/>
			)}
			<ReceiptDetailsDialog
				isOpen={isReceiptDialogOpen}
				toggle={toggleReceiptDialog}
				row={row}
			/>
		</>
	);
};

ReceiptIdTableCell.propTypes = {
	row: object.isRequired,
};
