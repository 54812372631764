import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		width: 42,
		height: 24,
		padding: 0,
		margin: theme.spacing(1),
	},
	switchBase: {
		padding: 4,
		color: theme.palette.white,
		'&$checked': {
			transform: 'translateX(18px)',
			color: theme.palette.white,
			backgroundColor: theme.palette.primary.main,

			'& + $track': {
				opacity: 1,
			},
		},
	},
	thumb: {
		width: 16,
		height: 16,
		backgroundColor: theme.palette.white,
	},
	track: {
		borderRadius: 24 / 2,
		opacity: 1,
		backgroundColor: theme.palette.background[3],
	},
	checked: {},
}));
