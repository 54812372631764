import React from 'react';
import { Box, Button, Dialog, IconButton } from '@material-ui/core';
import { bool, func, object, string } from 'prop-types';

// Import components
import { DialogForm } from './components';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useDriverDialog } from './useDriverDialog';

// Import styles
import { useStyles } from './DriverDialog.styles';

// Import assets
import { BaseEditPenIcon } from 'assets/icons';

export const DriverDialog = ({
	deliveryType,
	externalBookingUuid,
	externalDelivery = null,
	changeButtonOutlined = false,
	refetchCallback = () => {},
	buttonClassName = '',
	buttonVariant = 'contained',
	hideButton = false,
	isIconButton = false,
}) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { buttonText, isButtonVisible, toggle, on, isDriverAssigned } =
		useDriverDialog({
			deliveryType,
			t,
			externalDelivery,
			hideButton,
		});

	return (
		<>
			<Box className={classes.contentBox}>
				{isButtonVisible &&
					(isIconButton ? (
						<IconButton size="small" onClick={toggle}>
							<BaseEditPenIcon className={classes.editIcon} />
						</IconButton>
					) : (
						<Button
							className={buttonClassName}
							color="primary"
							variant={
								changeButtonOutlined && isDriverAssigned
									? 'outlined'
									: buttonVariant
							}
							onClick={toggle}
							size="small"
						>
							{buttonText}
						</Button>
					))}
			</Box>
			<Dialog open={on} onClose={toggle} fullWidth>
				<DialogForm
					toggle={toggle}
					deliveryType={deliveryType}
					externalBookingUuid={externalBookingUuid}
					refetchCallback={refetchCallback}
					externalDelivery={externalDelivery}
				/>
			</Dialog>
		</>
	);
};

DriverDialog.propTypes = {
	deliveryType: string,
	externalBookingUuid: string,
	externalDelivery: object,
	changeButtonOutlined: bool,
	refetchCallback: func,
	buttonClassName: string,
	buttonVariant: string,
	hideButton: bool,
	isIconButton: bool,
};
