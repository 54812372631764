// Import helpers
import { FIELD_TYPES } from 'helpers';

const multiSelectLabelExtractor = (options, currentUuid) =>
	options.find(({ uuid }) => uuid === currentUuid)?.value;

export const FIELDS = ({
	customerOrganizations,
	customerTypes,
	selectedTypes,
	selectedOrganizations,
}) => [
	{
		type: FIELD_TYPES.MULTI_SELECT,
		label: 'campaigns.form.customer.organization',
		name: 'condition.customer.organization',
		options: customerOrganizations,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ value }) => value,
		md: 4,
		currentMultiSelectValues: selectedOrganizations,
		multiSelectLabelExtractor,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT,
		label: 'campaigns.form.customer.types',
		name: 'condition.customer.customer_type',
		options: customerTypes,
		keyExtractor: ({ uuid }) => uuid,
		labelExtractor: ({ value }) => value,
		md: 4,
		currentMultiSelectValues: selectedTypes,
		multiSelectLabelExtractor,
	},
];
