import React from 'react';
import { Typography } from '@material-ui/core';
import { useFormState } from 'react-final-form';

// Import components
import { Button, Grid } from 'components/elements';

// Import utils
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './BottomBar.styles';

// Import assets
import { TriangleExclamationIcon } from 'assets/icons';

export const BottomBar = () => {
	const { t } = useTranslations();
	const classes = useStyles();
	const { values, submitting } = useFormState();

	const unsavedChanges = Object.values(values.out_data).filter(
		({ price, drop_cities: dropCities }) => !price || !dropCities?.length
	).length;

	const isButtonDisabled =
		!values.company_uuid || submitting || !!unsavedChanges;

	return (
		<Grid
			container
			justifyContent="space-between"
			className={classes.container}
		>
			<Grid item xs="auto">
				{!!unsavedChanges && (
					<Grid container spacing={1}>
						<Grid item xs="auto">
							<TriangleExclamationIcon className={classes.icon} />
						</Grid>
						<Grid item xs="auto">
							<Typography className={classes.description}>
								<Typography component="strong" className={classes.strong}>
									{unsavedChanges} {t('drop_cities.unsaved_changes')}
								</Typography>
								{t('drop_cities.additional_description')}
							</Typography>
						</Grid>
					</Grid>
				)}
			</Grid>
			<Grid item xs="auto">
				<Grid container spacing={2}>
					<Button
						text={t('common.buttons.save')}
						disabled={isButtonDisabled}
						isLoading={submitting}
						buttonType="submit"
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};
