import ExcelJS from 'exceljs';
import i18next from 'i18next';
import get from 'lodash/get';
import saveAs from 'file-saver';

const exportToExcel = ({ data, columns }) => ({
	async download(filename = 'TELGANI.xls') {
		const workbook = new ExcelJS.Workbook();

		const sheet = workbook.addWorksheet();

		sheet.columns = columns.map(({ Header, accessor, hidden = false }) => ({
			header: i18next.t(Header),
			key: accessor,
			width: 25,
			hidden,
		}));

		data.forEach((row, i) => {
			sheet.getRow(i + 2).values = columns.map(
				({ accessor, valueExtractor }) => {
					return valueExtractor ? valueExtractor(row) : get(row, accessor);
				}
			);
		});

		columns.forEach((column, index) => {
			if (column.options) {
				const options = column.options
					.map((options) => i18next.t(options).toLowerCase())
					.join(',');
				sheet.columns[index].eachCell((cell, cellIndex) => {
					if (cellIndex !== 1) {
						cell.dataValidation = {
							type: column.type,
							formulae: ['"' + options + '"'],
						};
					}
				});
			}
		});

		const buffer = await workbook.xlsx.writeBuffer();

		saveAs(new Blob([buffer], { type: 'application/octet-stream' }), filename);
	},
});

export default exportToExcel;
