import i18next from 'i18next';

// Import helpers
import { FIELD_TYPES } from 'helpers';

export const companyCarsMultiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.car_full_name;

export const citiesMultiSelectLabelExtractor = (options, currentUuid) =>
	currentUuid === 'all'
		? null
		: options.find(({ uuid }) => uuid === currentUuid)?.name;

export const FIELDS = ({ companyList, companyCars, cities, values }) => [
	{
		type: FIELD_TYPES.SELECT,
		label: 'special_offer.form.details.company_name',
		options: companyList,
		name: 'company_uuid',
		required: true,
		md: 6,
		labelExtractor: ({ name }) => name[i18next.language],
		keyExtractor: ({ uuid }) => uuid,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		name: 'city_uuids',
		label: 'common.fields.city',
		multiSelectLabelExtractor: citiesMultiSelectLabelExtractor,
		currentMultiSelectValues: values.city_uuids,
		options: cities,
		searchExtractor: 'children[1]',
		showAllOption: true,
		required: true,
		disabled: !cities.length,
		md: 6,
		labelExtractor: ({ name }) => name,
		keyExtractor: ({ uuid }) => uuid,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'special_offer.form.details.label_en',
		name: 'label_en',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.TEXT,
		label: 'special_offer.form.details.label_ar',
		name: 'label_ar',
		required: true,
		md: 6,
	},
	{
		type: FIELD_TYPES.DATE,
		name: 'date_from',
		label: 'special_offer.form.details.date_from',
		md: 6,
		minDate: new Date(),
	},
	{
		type: FIELD_TYPES.DATE,
		name: 'date_to',
		label: 'special_offer.form.details.date_to',
		md: 6,
	},
	{
		type: FIELD_TYPES.NUMBER,
		label: 'special_offer.form.details.position',
		name: 'position',
		required: true,
		md: 6,
		min: 1,
	},
	{
		type: FIELD_TYPES.MULTI_SELECT_WITH_SEARCH,
		name: 'vehicle_uuids',
		label: 'special_offer.form.details.cars',
		multiSelectLabelExtractor: companyCarsMultiSelectLabelExtractor,
		currentMultiSelectValues: values?.vehicle_uuids,
		disabled: !companyCars.length || !values.city_uuids.length,
		options: companyCars,
		searchExtractor: 'children[1]',
		showAllOption: true,
		required: true,
		labelExtractor: ({ car_full_name }) => car_full_name,
		keyExtractor: ({ uuid }) => uuid,
	},
];
