// Import helpers
import { numberWithCommas } from 'helpers';

export const getAmount = (amount) => Math.abs(amount);

export const isNegativeDifference = (diff) => diff < 0;

export const convertDifference = (diff) =>
	(isNegativeDifference(diff) ? '' : '+') + numberWithCommas(diff);

export const SIZE_TYPE = {
	NORMAL: 'normal',
	SQUARE: 'square',
};
