export const fetchBookingPreviewUrl = (id) => `/v2/booking/${id}/preview`;
export const fetchUrgentAssistanceListUrl = (bookingId) =>
	`v2/booking/${bookingId}/urgent-assistance/list`;

export const submitOfficeRatingFormUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/rate`;

export const submitCustomerRatingFormUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/rate-customer`;

export const approveCustomerCompensationUrl = (uuid) =>
	`/v2/booking/compensation/${uuid}/approve`;

export const editCustomerCompensationUrl = (uuid) =>
	`/v2/booking/compensation/${uuid}/edit`;

export const addCustomerCompensationUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/compensation/add`;

export const updateRatingUrl = (id) => `/v2/booking/${id}/rate`;
export const updateSupplierCommentsUrl = (id) => `/v2/booking/${id}/discuss`;
export const updateAdminCommentUrl = (id) => `/v2/booking/${id}/comment`;

// DRIVER DELIVERY
export const assignDriverUrl = (driverUuid) =>
	`/v2/driver/${driverUuid}/delivery/assign`;

export const changeDriverUrl = (deliveryUuid) =>
	`/v2/delivery/${deliveryUuid}/change-driver`;

export const driverAvailabilityUrl = (driverUuid) =>
	`/v2/driver/${driverUuid}/availability`;

export const settleOfflineUrl = (subscriptionUuid) =>
	`/v2/subscription/${subscriptionUuid}/pay-offline`;

export const deleteBookingRatingUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/rate`;

export const earlyCancellationUrl = (bookingUuid) =>
	`/v2/booking/${bookingUuid}/early-return`;

export const extendCorporateBookingUrl = (bookingUuid) =>
	`/v2/coordinator/booking/${bookingUuid}/extend`;

export const cancelCorporateBookingUrl = (bookingUuid) =>
	`/dashboard/b2b/booking/${bookingUuid}/cancel`;
