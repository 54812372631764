import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	valueText: {
		fontSize: 14,
		color: theme.palette.text[2],
	},
	assignButton: {
		backgroundColor: 'transparent',
		border: `1px solid ${theme.palette.background[3]}`,
		borderRadius: '12px',
		padding: '6px 16px',
		fontSize: 12,
	},
}));
