import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const AddCarIcon = (props) => (
	<SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" {...props}>
		<path
			d="M6.8776 9.58366H5.21094"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.9062 6.9118L16.6704 6.0293"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M4.265 6.9118L2.5 6.0293"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.9611 7.21294L14.158 3.78635C13.9814 3.03281 13.3092 2.5 12.5353 2.5H6.63674C5.86278 2.5 5.19066 3.03281 5.01405 3.78635L4.21094 7.21294"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M12.2891 9.58366H13.9557"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M16.6667 9.99967V9.58301C16.6667 8.2023 15.5474 7.08301 14.1667 7.08301H5C3.61929 7.08301 2.5 8.2023 2.5 9.58301V13.333C2.5 13.7932 2.8731 14.1663 3.33333 14.1663H5.19667C5.6569 14.1663 6.03 13.7932 6.03 13.333V12.083H10.8333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<ellipse
			cx="14.9974"
			cy="15.8333"
			rx="3.33333"
			ry="3.33333"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M13.9531 15.8337H16.0365"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
		<path
			d="M14.9948 16.8753V14.792"
			stroke="currentColor"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
			fill="none"
		/>
	</SvgIcon>
);
