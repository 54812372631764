import React from 'react';

// Import helpers
import { BOOKINGS_PAGES_TYPES } from 'helpers';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { AdditionalInfo, Export, Filters } from '../Bookings/components';
import { TableRPC } from 'components/utilities';
import { TableBodyRow } from './components';

// Import actions
import { fetchTransferredBookings } from 'store/actions';
import { InnerNavbarElements } from 'components/elements';

export const TransferredBookings = () => {
	return (
		<TableRPC
			columnsAccessor={COLUMNS_ACCESSORS}
			columns={columns}
			tableBodyRow={TableBodyRow}
			fetchDataTableAction={fetchTransferredBookings}
			title="nav.transferred"
			outerToolbarElements={
				<Export
					pageType={BOOKINGS_PAGES_TYPES.TRANSFERRED}
					fetchDataTableAction={fetchTransferredBookings}
				/>
			}
			filtersBar={<Filters />}
			tableNavigation={<InnerNavbarElements />}
			additionalNavigationElements={
				<AdditionalInfo
					showAssignedDriverAdditional
					showDeliveryStatusAdditional
				/>
			}
		/>
	);
};
