import React from 'react';
import {
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	Typography,
} from '@material-ui/core';

// Import styles
import { useStyles } from './AdditionalFilters.styles';

// Import utils
import { useAdditionalFilters } from './useAdditionalFilters';
import { useTranslations } from 'components/utilities';

// Import helpers
import { RADIO_FIELDS } from './helpers';

export const AdditionalFilters = () => {
	const { handleOnChange, defaultValue } = useAdditionalFilters();
	const { t } = useTranslations();
	const classes = useStyles();

	return (
		<Grid item xs="auto" className={classes.container}>
			<Typography variant="h5">
				{t('bookings.filters.sort_by_filter')}
			</Typography>
			{defaultValue && (
				<FormControl component="fieldset">
					<RadioGroup
						onChange={handleOnChange}
						value={defaultValue}
						aria-label={t('bookings.filters.sort_by_filter')}
						name="date_type"
						row
					>
						{RADIO_FIELDS.map(({ value, label }) => (
							<FormControlLabel
								control={<Radio color="primary" />}
								label={t(label)}
								value={value}
								key={value}
							/>
						))}
					</RadioGroup>
				</FormControl>
			)}
		</Grid>
	);
};
