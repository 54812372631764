import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import {
	BaseYesNoAllFilter,
	DateRangeFilter,
	MultiCompaniesFilter,
} from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';

export const FilterFields = () => {
	const { isAdmin } = useProfile();

	return (
		<Grid container spacing={3}>
			<DateRangeFilter
				nameFrom="created_at"
				nameUntil="created_at_to"
				label="bookings.filters.created_at"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="pickup_date"
				nameUntil="pickup_date_to"
				label="bookings.filters.pick_date"
				combinedInputs
			/>
			<DateRangeFilter
				nameFrom="dropoff_date"
				nameUntil="dropoff_date_to"
				label="bookings.filters.drop_date"
				combinedInputs
			/>
			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					label="bookings.filters.company"
					xs={12}
					md={6}
				/>
			)}
			<BaseYesNoAllFilter
				name="is_settled"
				label="common.fields.settlement"
				md={6}
				xs={12}
			/>
			<BaseYesNoAllFilter
				name="has_early_return_state"
				label="bookings.filters.early_return"
				md={6}
				xs={12}
			/>
		</Grid>
	);
};
