import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: '20px',
		minWidth: '49%',
	},
	mapContainer: {
		height: 200,
	},
	hideContainer: {
		paddingLeft: '10px',
		cursor: 'pointer',
	},
	icon: {
		color: theme.palette.primary.main,
	},
	hideText: {
		color: theme.palette.primary.main,
		fontSize: '13px',
		fontWeight: 600,
	},
	locateBtn: {
		position: 'absolute',
		right: 60,
		bottom: 30,
		backgroundColor: 'white',
		padding: '5px',
	},
}));
