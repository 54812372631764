import { convertFormDates } from 'helpers';

export const initialValues = {
	transaction_created_at: null,
	company_uuid: ['all'],
};

export const formatValues = (values) => {
	return {
		...values,
		...convertFormDates(values, ['transaction_created_at']),
	};
};
