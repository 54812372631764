import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useAdmins = () => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.USERS.ADMINS_LIST,
		async () => await services.post(ENDPOINTS.ADMIN.USERS_LIST),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching };
};

export default useAdmins;
