import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

// Import utils
import { useTranslations } from 'components/utilities';

// Import context
import { useEditBookingFormProvider } from '../../context';

// Import store
import { editBookingFormSelector } from 'store/selectors';

// Import styles
import { useStyles } from './BottomBar.styles';
import { ButtonProgress } from 'components/elements';

export const BottomBar = () => {
	const classes = useStyles();
	const { t } = useTranslations();

	const { toggleConfirmChangeDialog } = useEditBookingFormProvider();

	const {
		recalculate: { isRecalculated, isLoading },
	} = useSelector(editBookingFormSelector);

	return (
		<Grid
			container
			justifyContent="flex-end"
			alignItems="center"
			spacing={3}
			className={classes.bottomBarContainer}
		>
			<Grid item>
				<ButtonProgress
					color="primary"
					variant="contained"
					type="button"
					onClick={toggleConfirmChangeDialog}
					size="large"
					disabled={!isRecalculated || isLoading}
					isLoading={isLoading}
				>
					{t(`common.buttons.${isLoading ? 'recalculating' : 'save'}`)}
				</ButtonProgress>
			</Grid>
		</Grid>
	);
};
