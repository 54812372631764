import React from 'react';
import { Button, Box } from '@material-ui/core';
import BusinessIcon from '@material-ui/icons/Business';
import clsx from 'clsx';
import { object } from 'prop-types';

// Import utilities
import { useOuterToolbarElements } from './useOuterToolbarElements';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './OuterToolbarElements.styles';

// Import components
import { EditPrices } from 'components/elements';
import { Export } from '../../components';

export const OuterToolbarElements = ({ companyData }) => {
	const { t } = useTranslations();

	const classes = useStyles();

	const {
		toggleEditPriceDialog,
		goToAddOfficeView,
		actionButtons,
		isAdmin,
		isOpen,
	} = useOuterToolbarElements({ companyData });

	// eslint-disable-next-line
	const renderButton = ({ text, icon: Icon, onClick, className }) => (
		<Button
			className={clsx([classes.button, className])}
			onClick={onClick}
			key={text}
		>
			{Icon && <Icon className={classes.icon} />}
			{t(text)}
		</Button>
	);

	return (
		<Box className={classes.buttonsContainer}>
			{actionButtons.map(({ icon, text, onClick }) => (
				<Box key={text}>{renderButton({ text, icon, onClick })}</Box>
			))}
			<Export />
			{isAdmin &&
				renderButton({
					className: classes.addOfficeButton,
					text: 'common.buttons.add_office',
					onClick: goToAddOfficeView,
					icon: BusinessIcon,
				})}
			<EditPrices toggle={toggleEditPriceDialog} isOpen={isOpen} />
		</Box>
	);
};

OuterToolbarElements.propTypes = {
	companyData: object,
};
