import React from 'react';
import { Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { bool } from 'prop-types';

// Import components
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';
import { LoadingWrapper } from 'components/elements';

// Import utils and helpers
import { useProfile, useTranslations } from 'components/utilities';
import {
	COMPANY_INFO_FIELDS,
	convertCompanyDetails,
	convertOldCompanyDetails,
} from './helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

// Import context
import { useCarAndCompanyProvider } from '../../context';

// Import styles
import { useStyles } from '../../CarAndCompany.styles';

export const Company = ({ isHistoryDialog = false }) => {
	const { t } = useTranslations();
	const classes = useStyles();

	const { isAdmin } = useProfile();

	const booking = useSelector(previewBookingSelector);

	const { editBookingHistory, isFetching } = useCarAndCompanyProvider();

	if (isFetching) return <LoadingWrapper />;

	const currentCompanyData = convertCompanyDetails(booking);

	const oldCompanyData = convertOldCompanyDetails(editBookingHistory);

	const companyData = isHistoryDialog ? oldCompanyData : currentCompanyData;

	return (
		<>
			<Typography variant="h5" className={classes.title}>
				{t('common.company')}
			</Typography>
			<PreviewFields
				fields={COMPANY_INFO_FIELDS({ ...companyData, isAdmin })}
				customData={companyData}
			/>
		</>
	);
};

Company.propTypes = {
	isHistoryDialog: bool,
};
