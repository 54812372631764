import React from 'react';
import { Input, Paper, Typography } from '@material-ui/core';

// Import utils
import { usePickupCitiesCardProvider } from '../../context';
import { useTranslations } from 'components/utilities';

// Import styles
import { useStyles } from './TopBar.styles';

// Import components
import { Grid } from 'components/elements';

// Import assets
import { SearchIcon } from 'assets/icons';

export const TopBar = () => {
	const { handleOnSearch } = usePickupCitiesCardProvider();
	const { t } = useTranslations();
	const classes = useStyles();

	const handleOnKeyDown = (e) => e.stopPropagation();

	return (
		<Grid
			container
			justifyContent="space-between"
			alignItems="center"
			className={classes.container}
		>
			<Grid item xs="auto">
				<Typography className={classes.title}>
					{t('drop_cities.pickup_cities')}
				</Typography>
			</Grid>
			<Grid item xs="auto">
				<Paper className={classes.searchPaper}>
					<Input
						startAdornment={<SearchIcon className={classes.searchIcon} />}
						placeholder={t('common.search')}
						onKeyDown={handleOnKeyDown}
						onChange={handleOnSearch}
						disableUnderline
					/>
				</Paper>
			</Grid>
		</Grid>
	);
};
