import { format } from 'date-fns';

// Import helpers
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from 'helpers';

export const convertSubscriptionCell = (cell) => {
	const {
		booking_individual_number: bookingIndividualNumber,
		pickup_date: pickupDate,
		pickup_time: pickupTime,
		dropoff_date: dropoffDate,
		booking_status: bookingStatus,
	} = cell.row.original;

	return {
		...cell,
		row: {
			...cell.row,
			original: {
				...cell.row.original,
				individual_number: bookingIndividualNumber,
				pick_date: pickupDate,
				pick_time: format(
					new Date(`${pickupDate} ${pickupTime}`),
					DEFAULT_TIME_FORMAT
				),
				drop_date: format(new Date(dropoffDate), DEFAULT_DATE_FORMAT),
				state: bookingStatus,
			},
		},
	};
};
