import { useFormState } from 'react-final-form';

export const useFormContent = ({ cancel_reason_uuid }) => {
	const {
		values: { cancellationReason: currentReasonUuid },
	} = useFormState();

	const hasReasonChanged = currentReasonUuid !== cancel_reason_uuid;

	return { hasReasonChanged };
};
