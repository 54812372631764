import React from 'react';
import {
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Grid,
	Typography,
} from '@material-ui/core';

// Import utils and helpers
import { useValidateUrgentForm } from './useValidateUrgentForm';
import { useTranslations } from 'components/utilities';

// Import components
import {
	MakeCompensationDialog,
	ButtonProgress,
	ButtonDanger,
	Alert,
} from 'components/elements';

export const ValidateUrgentForm = () => {
	const { t, i18n } = useTranslations();
	const lng = i18n.language;

	const {
		handleResolveBooking,
		handleUnresolveBooking,
		status,
		name,
		updatedAt,
		isError,
		error,
		isSubmitted,
		isLoading,
		translationEnd,
	} = useValidateUrgentForm();

	if (isSubmitted) {
		return (
			<Typography>
				{t(`validation.table.modal.urgent_${translationEnd}`)}
			</Typography>
		);
	}

	if (isError) {
		return <Alert message={error.message} />;
	}

	return (
		<>
			<Card>
				<CardHeader title={t('validation.table.modal.urgent_title')} />
				<CardContent>
					<Grid container spacing={2}>
						<Grid item md={3}>
							<Typography>
								{t('validation.table.modal.urgent_reason')}:
							</Typography>
						</Grid>
						<Grid item md={9}>
							<Typography variant="h5">{name[lng]}</Typography>
						</Grid>
						<Grid item md={3}>
							<Typography>
								{t('validation.table.modal.urgent_status')}:
							</Typography>
						</Grid>
						<Grid item md={9}>
							<Typography variant="h5">{status}</Typography>
						</Grid>
						<Grid item md={3}>
							<Typography>
								{t('validation.table.modal.urgent_updated_at')}:
							</Typography>
						</Grid>
						<Grid item md={9}>
							<Typography variant="h5">{updatedAt}</Typography>
						</Grid>
					</Grid>
				</CardContent>
				<CardActions>
					<Grid container spacing={2}>
						<Grid item xs={4}>
							<MakeCompensationDialog />
						</Grid>
						<Grid item xs={8} container spacing={2} justifyContent="flex-end">
							<Grid item>
								<Typography>
									{t('bookings.single_booking.details.mark_as')}
								</Typography>
							</Grid>
							<Grid item>
								<ButtonProgress
									color="primary"
									variant="contained"
									size="small"
									isLoading={isLoading}
									disabled={isLoading}
									onClick={handleResolveBooking}
								>
									{t('bookings.single_booking.details.mark_as_solved')}
								</ButtonProgress>
							</Grid>
							<Grid item>
								<ButtonDanger
									color="primary"
									variant="contained"
									size="small"
									disabled={isLoading}
									onClick={handleUnresolveBooking}
								>
									{t('bookings.single_booking.details.mark_as_unsolved')}
								</ButtonDanger>
							</Grid>
						</Grid>
					</Grid>
				</CardActions>
			</Card>
		</>
	);
};
