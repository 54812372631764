export const COLUMNS_ACCESSORS = {
	NAME: 'name',
	ACTIONS: 'actions',
	ACTIVE: 'active',
};

export const columns = [
	{
		Header: 'faq.table.headers.title',
		accessor: COLUMNS_ACCESSORS.NAME,
		disableSortBy: true,
	},
	{
		Header: 'faq.table.headers.order',
		accessor: `order`,
		disableSortBy: true,
	},
	{
		Header: 'table.headers.active',
		accessor: COLUMNS_ACCESSORS.ACTIVE,
		disableSortBy: true,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: true,
	},
];
