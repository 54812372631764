import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

const useAutoVehicleMakerCollection = ({ name }) => {
	const { data, isFetching } = useQuery(
		QUERY_KEYS.CARS.AUTO_MAKER_COLLECTION(name),
		async () =>
			await services.post(ENDPOINTS.CARS.AUTO_MAKER_COLLECTION, { name }),
		{ refetchOnWindowFocus: false, cacheTime: 400, enabled: !!name }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching };
};

export default useAutoVehicleMakerCollection;
