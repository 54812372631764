import React from 'react';
import {
	elementType,
	node,
	number,
	oneOf,
	oneOfType,
	string,
} from 'prop-types';
import MuiTypgraphy from '@material-ui/core/Typography';

// Import styles
import { useStyles } from './Typography.styles';

// Import helpers
import clsx from 'clsx';

/**
 *
 * @typedef {object} TypographyProps
 * @property {("inherit" | "left" | "center" | "right" | "justify")} align
 * @property {("neutral50" | "neutral70" | "neutral90" | "primary" | "text.4")} color
 * @property {number} fontWeight
 * @property {number} fontSize
 * @property {string} component
 */

/**
 * @type {React.ForwardRefRenderFunction<HTMLElement, TypographyProps>}
 */

export const Typography = ({
	align = 'inherit',
	fontWeight = 400,
	fontSize = 16,
	component,
	children,
	className,
	color,
	...props
}) => {
	const classes = useStyles({ fontSize, fontWeight, color });

	return (
		<MuiTypgraphy
			className={clsx([classes.typography, className])}
			component={component}
			align={align}
			{...props}
		>
			{children}
		</MuiTypgraphy>
	);
};

Typography.propTypes = {
	children: node,
	component: elementType,
	className: string,
	fontWeight: oneOfType([string, number]),
	fontSize: number,
	color: oneOf(['neutral50', 'neutral90', 'neutral90', 'primary', 'text.4']),
	align: oneOf(['inherit', 'left', 'center', 'right', 'justify']),
};
