import React from 'react';
import { fetchCarList } from 'store/actions';

// Import columns
import { columns, COLUMNS_ACCESSORS } from './columns';

// Import components
import { Filters, OuterToolbarElements, TableBodyRow } from './components';
import { InnerNavbarElementsButtons } from 'components/elements';

// Import utils and helpers
import { TableRPC } from 'components/utilities';
import { BUTTONS } from './helpers';

export const CarList = () => (
	<TableRPC
		tableNavigation={
			<InnerNavbarElementsButtons filterPath="active" buttons={BUTTONS} />
		}
		searchBarPlaceholder="common.fields.company_name"
		outerToolbarElements={<OuterToolbarElements />}
		fetchDataTableAction={fetchCarList}
		title="nav.companies.cars.car_list"
		columnsAccessor={COLUMNS_ACCESSORS}
		tableBodyRow={TableBodyRow}
		filtersBar={<Filters />}
		columns={columns}
	/>
);
