import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { Box, Grid } from '@material-ui/core';
import {
	fetchSpecialOfferFormResources,
	submitSpecialOfferForm,
} from 'store/actions';

// Import components
import { FormRPC } from 'components/utilities';
import { SpecialOfferDetails } from './components';

// Import helpers
import { specialOfferServiceTypes } from 'helpers';
import { INITIAL_VALUES } from './helpers';
import { URLS } from 'components/routes';

// Import styles
import useStyles from './styles';

export const SpecialOfferForm = () => {
	const classes = useStyles();

	const { type } = useParams();

	const isTypeIncorrect = (type) => !specialOfferServiceTypes.includes(type);

	if (isTypeIncorrect(type)) {
		return <Redirect to={URLS.specialOfferUrl} />;
	}

	return (
		<Box className={classes.root}>
			<FormRPC
				fetchFormResAction={fetchSpecialOfferFormResources}
				submitFormAction={(props) =>
					submitSpecialOfferForm({ ...props, serviceType: type })
				}
				isCardLayout={false}
				goBackPath={URLS.specialOfferUrl}
				store={(store) => store.specialOffer.form}
				initialValues={INITIAL_VALUES}
			>
				{() => (
					<Grid container spacing={4}>
						<Grid item lg={9} xs={12}>
							<SpecialOfferDetails classes={classes} />
						</Grid>
					</Grid>
				)}
			</FormRPC>
		</Box>
	);
};
