import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCompanies = (isActive) => {
	const companyCollectionUrl = ENDPOINTS.COMPANIES.COLLECTION({ isActive });

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.COMPANIES.COLLECTION(companyCollectionUrl),
		async () => await services.get(companyCollectionUrl),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data.data || [];

	return { data: fetchedData, isFetching, isError };
};
