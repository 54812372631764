import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles(() => ({
	container: {
		maxWidth: '50%',
	},
	title: {
		fontSize: '18px',
		fontWeight: 600,
	},
}));
