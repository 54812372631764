import { useFormState } from 'react-final-form';

// Import helpers and utils
import { FIELD_TYPES } from 'helpers';
import { useToggle, useTranslations } from 'components/utilities';

// Import queries
import { useUrgentReasons } from 'queries';

export const useFormContent = () => {
	const { on: isFormVisible, toggle: toggleForm } = useToggle();
	const { submitting, values } = useFormState();

	const { data: urgentReasons = [] } = useUrgentReasons();

	const {
		i18n: { language: lang },
	} = useTranslations();

	const ASSIGN_URGENT_FIELDS = [
		{
			type: FIELD_TYPES.SELECT,
			label: 'bookings.assign_urgent_form.label',
			name: 'urgentReasonId',
			required: true,
			options: urgentReasons,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ details }) => details.name[lang],
		},
	];

	const isReasonSelected = !!values.urgentReasonId;

	const isSubmitDisabled = !isReasonSelected || submitting;

	return {
		ASSIGN_URGENT_FIELDS,
		isFormVisible,
		toggleForm,
		isSubmitDisabled,
		submitting,
		isReasonSelected,
	};
};
