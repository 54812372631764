/* eslint-disable no-mixed-spaces-and-tabs */
import React from 'react';
import { arrayOf, object, string } from 'prop-types';

// Import helpers and utils
import { useAdditionalColumnsDialog } from './useAdditionalColumnsDialog';
import { getInitialValues } from './helpers';

// Import components
import { AdditionalDialog } from './components/AdditionalDialog';
import { IconButton } from 'components/elements';

// Import assets
import { EditColumnIcon } from 'assets/icons';

export const AdditionalColumnsDialog = ({
	fields,
	groupedFields,
	transKey,
}) => {
	const convertedFields = groupedFields
		? groupedFields.reduce(
				(acc, { innerFields }) => [...acc, ...innerFields],
				[]
		  )
		: fields;

	const { onSubmit, onClear, isOpen, toggle } = useAdditionalColumnsDialog({
		fields: convertedFields,
	});

	return (
		<>
			<IconButton onClick={toggle}>
				<EditColumnIcon />
			</IconButton>
			<AdditionalDialog
				initialValues={getInitialValues(convertedFields)}
				groupedFields={groupedFields}
				fields={fields}
				onSubmit={onSubmit}
				onClear={onClear}
				isOpen={isOpen}
				toggle={toggle}
				transKey={transKey}
			/>
		</>
	);
};

AdditionalColumnsDialog.propTypes = {
	groupedFields: arrayOf(object),
	fields: arrayOf(string),
	transKey: string,
};
