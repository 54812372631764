import React from 'react';
import { bool, object, string } from 'prop-types';
import { Box, TableCell, Typography } from '@material-ui/core';

// Import styles
import { useStyles } from './PeriodTableCell.styles';

// Import assets
import { LongRightArrowIcon } from 'assets/icons';

// Import helpers and utils
import { usePeriodTableCell } from './usePeriodTableCell';

export const PeriodTableCell = ({
	cell,
	isExtended = false,
	outerDateBefore = '',
	outerDateAfter = '',
	isOneLine = false,
}) => {
	const { prevText, nextText } = usePeriodTableCell({
		cell,
		outerDateAfter,
		outerDateBefore,
		isExtended,
	});

	const classes = useStyles();

	return (
		<TableCell
			key={cell.column.id}
			className={classes.tableCell}
			{...cell.getCellProps()}
		>
			<Box className={isOneLine ? classes.oneLine : ''}>
				<Typography className={classes.text}>{prevText}</Typography>
				<Box className={classes.bottomBox}>
					<LongRightArrowIcon className={classes.icon} />
					<Typography className={classes.text}>{nextText}</Typography>
				</Box>
			</Box>
		</TableCell>
	);
};

PeriodTableCell.propTypes = {
	cell: object.isRequired,
	isExtended: bool,
	outerDateBefore: string,
	outerDateAfter: string,
	isOneLine: bool,
};
