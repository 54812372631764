import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { ENDPOINTS, QUERY_KEYS } from 'helpers';

export const useCorporationCustomersList = ({ corporateUuid }) => {
	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.B2B.CORPORATION_CUSTOMERS_LIST(corporateUuid),
		async () =>
			await services.get(
				ENDPOINTS.B2B.CORPORATE_CUSTOMERS_LIST({ corporateUuid })
			),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || [];

	return { data: fetchedData, isFetching, isError };
};
