import React from 'react';
import {
	Button,
	Card,
	Dialog,
	DialogContent,
	Grid,
	Typography,
} from '@material-ui/core';
import clsx from 'clsx';

// Import components
import { ChipLabel, ClosableDialogTitle } from 'components/elements';
import { PreviewFields } from 'views/Bookings/BookingDetails/components/PreviewFields';

// Import utils
import { useTranslations } from 'components/utilities';
import { useCompensationHistory } from './useCompensationHistory';

// Import context
import { useCompensationFormProvider } from '../../context';

// Import styles
import { useStyles as useGeneralStyles } from '../../Compensation.styles';
import { useStyles } from './CompensationHistory.styles';

export const CompensationHistory = () => {
	const { t } = useTranslations();

	const generalStyles = useGeneralStyles();

	const classes = useStyles();

	const {
		isCompensationHistoryOpen,
		toggleCompensationHistory,
		compensations,
		editCompensation,
	} = useCompensationFormProvider();

	const { formattedCompensations } = useCompensationHistory(compensations);

	if (!formattedCompensations?.length) return null;

	return (
		<Dialog
			open={isCompensationHistoryOpen}
			onClose={toggleCompensationHistory}
			maxWidth="sm"
			fullWidth
		>
			<ClosableDialogTitle
				title={t('bookings.compensation_tab.compensation_history')}
				onClose={toggleCompensationHistory}
			/>
			<DialogContent>
				{formattedCompensations.map((compensation) => {
					const {
						type,
						status,
						allow_edit: allowEdit,
						fields,
						reason,
						...rest
					} = compensation;
					return (
						<Card className={classes.card} key={type}>
							<Grid
								container
								spacing={2}
								alignItems="center"
								justifyContent="space-between"
								className={classes.statusRecord}
							>
								<Grid item>
									<Typography variant="body1" className={generalStyles.type}>
										{type}
									</Typography>
									{reason && (
										<Typography
											variant="body1"
											className={generalStyles.reason}
										>
											{t(`bookings.compensation_tab.reasons.${reason}`)}
										</Typography>
									)}
								</Grid>
								<Grid item>
									<ChipLabel
										label={status}
										className={clsx(
											generalStyles.statusChip,
											generalStyles[status]
										)}
									/>
								</Grid>
								<Grid item>
									{allowEdit && (
										<Button
											variant="outlined"
											color="primary"
											className={generalStyles.editButton}
											onClick={() => editCompensation(compensation)}
										>
											{t('common.buttons.edit')}
										</Button>
									)}
								</Grid>
							</Grid>

							<PreviewFields fields={fields} customData={rest} />
						</Card>
					);
				})}
			</DialogContent>
		</Dialog>
	);
};
