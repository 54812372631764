import i18next from 'i18next';

export const COLUMNS_ACCESSORS = {
	IMAGE: 'media',
	ACTIONS: 'actions',
	AVAILABILITY: 'availability',
};

export const columns = [
	{
		Header: 'common.cars.headers.image',
		accessor: COLUMNS_ACCESSORS.IMAGE,
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.maker',
		accessor: `maker_name.${i18next.language}`,
	},
	{
		Header: 'common.cars.headers.model',
		accessor: `model_name.${i18next.language}`,
	},
	{
		Header: 'common.cars.headers.year',
		accessor: 'year',
		disableSortBy: false,
	},
	{
		Header: 'common.cars.headers.availability',
		accessor: COLUMNS_ACCESSORS.AVAILABILITY,
		disableSortBy: false,
	},
	{
		Header: 'common.fields.actions',
		accessor: COLUMNS_ACCESSORS.ACTIONS,
		disableSortBy: false,
	},
];
