export const resetCarInfo = (change) => {
	change('item.uuid', '');
	change('city', '');
	change('company', '');
	change('office', '');
	change('chosenCar', null);
};

export const SELECT_CAR_TITLES = {
	PREVIEWED: { title: 'Selected Car' },
	DEFAULT: {
		title: 'bookings.create_booking.select_a_car',
		info: 'bookings.create_booking.select_car_info',
	},
};
