import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import { QUERY_KEYS } from 'helpers';

export const useVehiclePlates = (bookingUuid) => {
	const path = '/v2/vehicle/plates';

	const body = {
		booking_uuid: bookingUuid,
	};

	const { data, isFetching, isError } = useQuery(
		QUERY_KEYS.CONTRACTS.PLATES(JSON.stringify(body)),
		async () => await services.post(path, body),
		{ refetchOnWindowFocus: false }
	);

	const fetchedData = data?.data?.data || {};

	return { data: fetchedData, isFetching, isError };
};
