import { useSelector } from 'react-redux';

// Import utilities and helpers
import { DELIVERY_STATUS, getConvertedDelivery } from 'helpers';
import { useToggle } from 'components/utilities';

// Import store
import { previewBookingSelector } from 'store/selectors';

const { CANCELLED, FINISHED } = DELIVERY_STATUS;
export const useDriverDialog = ({
	t,
	externalDelivery,
	deliveryType,
	hideButton,
}) => {
	const { delivery } = useSelector(previewBookingSelector);

	const { on, toggle } = useToggle();

	const convertedDelivery = getConvertedDelivery(
		externalDelivery ? externalDelivery : delivery
	);

	const deliveryData = convertedDelivery.find(
		({ deliveryType: type }) => type === deliveryType
	);

	const {
		delivery_state,
		has_driver_assigned: hasDriverAssigned,
		assigned,
	} = deliveryData || {};

	const isDriverAssigned = hasDriverAssigned || assigned;

	const getButtonText = () => {
		const keyPrefix = 'bookings.preview_booking.car';
		const ending = isDriverAssigned ? 'change_driver' : 'assign_to_driver';

		return t(`${keyPrefix}.${ending}`);
	};

	const isButtonVisible =
		!hideButton && delivery_state !== FINISHED && delivery_state !== CANCELLED;

	const buttonText = getButtonText();

	return {
		convertedDelivery,
		isButtonVisible,
		buttonText,
		toggle,
		on,
		isDriverAssigned,
	};
};
