import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: (spaceBetween) => spaceBetween && 'space-between',
	},
	label: {
		fontWeight: 'bold',
		marginRight: theme.spacing(2),
		display: 'inline-block',
	},
	button: {
		border: `1px solid ${theme.palette.gray.light}`,
		borderRadius: '12px',
		padding: '6px 14px',
	},
}));
