import i18next from 'i18next';

// Import helpers
import { ACTION_MENU_POPUP_FIELD_TYPES, replaceEditCarListUrl } from 'helpers';

export const actionMenuFields = (uuid) => [
	{
		type: ACTION_MENU_POPUP_FIELD_TYPES.EDIT,
		editViewUrl: replaceEditCarListUrl(uuid),
	},
];

export const getCarData = (data) => {
	const language = i18next.language;
	const {
		plate_availability: plateAvailability,
		model_name: modelName,
		maker_name: makerName,
		year,
		media,
	} = data;

	return {
		modelAndYear: `${modelName[language]} ${year}`,
		availability: plateAvailability,
		name: makerName[language],
		image: media,
	};
};

export const AVAILABILITY_TABLE_COLUMN_ACCESSORS = {
	OFFICES: 'offices',
};

export const availabilityTableColumns = [
	{
		header: 'common.fields.company',
		accessor: 'company',
		disableSortBy: true,
	},
	{
		header: 'common.fields.available_cars',
		accessor: 'office',
		disableSortBy: true,
	},
	{
		header: 'common.fields.offices',
		accessor: AVAILABILITY_TABLE_COLUMN_ACCESSORS.OFFICES,
		disableSortBy: true,
	},
];
