import React, { createContext, useContext } from 'react';

import { array, element, number, func, object, string } from 'prop-types';

// import helpers
import { useTableWithQueryProvider } from './useTableWithQueryProvider';

const TableWithQueryContext = createContext();

export const TableWithQueryProvider = ({
	queryKey,
	children,
	url,
	columns,
	cacheTime,
	rowsPerPageOptions,
	getFormattedFilterValues,
	filtersInitialValues,
}) => {
	const values = useTableWithQueryProvider({
		url,
		queryKey,
		columns,
		cacheTime,
		rowsPerPageOptions,
		getFormattedFilterValues,
		filtersInitialValues,
	});

	return (
		<TableWithQueryContext.Provider value={values}>
			{children}
		</TableWithQueryContext.Provider>
	);
};

TableWithQueryProvider.propTypes = {
	children: element.isRequired,
	queryKey: string,
	url: string.isRequired,
	columns: array,
	cacheTime: number,
	rowsPerPageOptions: array,
	getFormattedFilterValues: func,
	filtersInitialValues: object,
};

export const useTableRPCWithQueryProvider = () => {
	const context = useContext(TableWithQueryContext);

	if (!context) {
		throw new Error(
			'useTableRPCProvider must be used within a TableRPCProvider'
		);
	}

	return context;
};
