import React from 'react';
import { Grid } from '@material-ui/core';

// Import components
import { DateRangeInput, MultiCompaniesFilter } from 'components/elements';

// Import utils
import { useProfile } from 'components/utilities';
import { useAdditionalFilterFields } from './useAdditionalFilterFields';

export const AdditionalFilterFields = () => {
	const { isAdmin } = useProfile();

	useAdditionalFilterFields();

	return (
		<>
			{isAdmin && (
				<MultiCompaniesFilter
					name="company_uuid"
					smallFieldStyle
					xs="auto"
					md="auto"
					showEmptyLabel
				/>
			)}
			<Grid item xs="auto">
				<DateRangeInput
					nameFrom="compensation_created_at_date"
					nameUntil="compensation_created_at_date_to"
					label="common.fields.assigned_date"
					combinedInputs
					isToggleButton
				/>
			</Grid>
		</>
	);
};
