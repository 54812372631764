import { useQuery } from 'react-query';
import services from 'services/services';

// Import helpers
import {
	QUERY_KEYS,
	convertDate,
	DEFAULT_DATE_FORMAT,
	ENDPOINTS,
} from 'helpers';

const useCars = ({ bookingUuid, filters, values, enabled = true }) => {
	const { pick_date, pick_time, drop_date } = values?.period || {};

	const body = {
		...filters,
		pickup_date: convertDate(
			`${convertDate(pick_date, DEFAULT_DATE_FORMAT)} ${pick_time}`
		),
		drop_date: convertDate(drop_date, DEFAULT_DATE_FORMAT),
	};

	const { data, isFetching, refetch } = useQuery(
		QUERY_KEYS.CARS.SEARCH(JSON.stringify(body)),
		async () =>
			await services.post(ENDPOINTS.CARS.SEARCH({ bookingUuid }), body),
		{ refetchOnWindowFocus: false, enabled }
	);

	const fetchedData = data ? data.data.data : [];

	return { data: fetchedData, isFetching, refetch };
};

export default useCars;
