import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Form } from 'react-final-form';
import { useSelector } from 'react-redux';

// Import components
import { FormContent } from './components';

// Import utils helpers
import { useOfficeRatingForm } from './useOfficeRatingForm';
import { useTranslations } from 'components/utilities';
import { getInitialValues } from './helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const OfficeRatingForm = () => {
	const booking = useSelector(previewBookingSelector);
	const { onSubmit } = useOfficeRatingForm();
	const { t } = useTranslations();

	const officeRating = booking?.ratings?.office;

	const initialValues = getInitialValues(officeRating);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h5" gutterBottom>
					{t('bookings.preview_booking.ratings.customer_rating_of_office')}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Form
					initialValues={initialValues}
					onSubmit={onSubmit}
					render={({ handleSubmit }) => (
						<form onSubmit={handleSubmit}>
							<FormContent />
						</form>
					)}
				/>
			</Grid>
		</Grid>
	);
};
