import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const StarOutlinedIcon = (props) => (
	<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
		<g id="Interface, Essential/star-favorite">
			<g id="Group">
				<path
					id="Path"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M7.73136 20.833C7.22429 21.0977 6.61084 21.0511 6.14958 20.7129C5.68831 20.3746 5.45945 19.8035 5.55946 19.2404L6.36879 14.6014L2.96137 11.3361C2.54488 10.9389 2.39224 10.3385 2.56847 9.79059C2.7447 9.24271 3.21876 8.84388 3.78872 8.764L8.51969 8.08772L10.6556 3.82694C10.9088 3.31595 11.4298 2.99268 12.0001 2.99268C12.5704 2.99268 13.0915 3.31595 13.3447 3.82694L15.4806 8.08772L20.2116 8.764C20.7815 8.84388 21.2556 9.24271 21.4318 9.79059C21.608 10.3385 21.4554 10.9389 21.0389 11.3361L17.6315 14.6014L18.4408 19.2414C18.5408 19.8045 18.312 20.3756 17.8507 20.7139C17.3894 21.0521 16.776 21.0987 16.2689 20.834L12.0001 18.6281L7.73136 20.833Z"
					stroke="currentColor"
					strokeWidth="1.5"
					strokeLinecap="round"
					strokeLinejoin="round"
					fill="none"
				/>
			</g>
		</g>
	</SvgIcon>
);
