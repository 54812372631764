import React from 'react';
import { string } from 'prop-types';

// Import components
import RoleProtected from 'components/hoc/RoleProtected';
import {
	CustomerWalletPreviewField,
	LicenseModalField,
	AddressLinkPreviewField,
	AssignDriver,
	ViewAttachmentPreviewField,
	VatPreviewField,
	PartialPaymentPreviewField,
	PaymentMethodsPreviewField,
	PayerPreviewField,
	InvoiceOptionsPreviewField,
	DiscountPreviewField,
	PeriodPreviewField,
	DailyRentPricePreviewField,
	TotalPaidPreviewField,
	PhonePreviewField,
	WorkingHoursPreviewField,
	DateField,
	UrgentRequestStatusField,
	LinkPreviewField,
	TotalExtensionAmountPreviewField,
	NewDropDatePreviewField,
	PickUpAddressPreviewField,
} from '../components';
import { DefaultPreviewField, OptionsPreviewField } from 'components/elements';

// Import helpers
import {
	CUSTOMER_WALLET_PREVIEW_PERMISSION,
	FIELDS_ACCESSORS,
} from './helpers';
import { PERMISSION_PARTIAL_PAYMENT } from './BookingsTabs/components/BookingInfoTab/helpers';

export const PreviewFieldFactory = (props) => {
	switch (props.accessor) {
		case FIELDS_ACCESSORS.WALLET:
			return (
				<RoleProtected roles={CUSTOMER_WALLET_PREVIEW_PERMISSION}>
					<CustomerWalletPreviewField {...props} />
				</RoleProtected>
			);

		case FIELDS_ACCESSORS.LICENSE_MODAL:
			return <LicenseModalField {...props} />;

		case FIELDS_ACCESSORS.ADDRESS_LINK:
			return <AddressLinkPreviewField {...props} />;

		case FIELDS_ACCESSORS.ASSIGN_DRIVER:
			return <AssignDriver {...props} />;

		case FIELDS_ACCESSORS.VIEW_ATTACHMENT:
			return <ViewAttachmentPreviewField {...props} />;

		case FIELDS_ACCESSORS.PAYMENT_METHODS:
			return <PaymentMethodsPreviewField {...props} />;

		case FIELDS_ACCESSORS.PAYER:
			return <PayerPreviewField {...props} />;

		case FIELDS_ACCESSORS.PERIOD:
			return <PeriodPreviewField {...props} />;

		case FIELDS_ACCESSORS.INVOICE_OPTIONS:
			return <InvoiceOptionsPreviewField {...props} />;

		case FIELDS_ACCESSORS.DISCOUNT:
			return <DiscountPreviewField {...props} />;

		case FIELDS_ACCESSORS.TOTAL_AMOUNT_PAID_ONLINE:
			return (
				<RoleProtected roles={PERMISSION_PARTIAL_PAYMENT}>
					<PartialPaymentPreviewField {...props} />
				</RoleProtected>
			);

		case FIELDS_ACCESSORS.TOTAL_AMOUNT_PAID_WALLET:
			return (
				<RoleProtected roles={PERMISSION_PARTIAL_PAYMENT}>
					<PartialPaymentPreviewField {...props} />
				</RoleProtected>
			);

		case FIELDS_ACCESSORS.VAT:
			return <VatPreviewField {...props} />;

		case FIELDS_ACCESSORS.DAILY_RENT_PRICE:
			return <DailyRentPricePreviewField {...props} />;

		case FIELDS_ACCESSORS.TOTAL_PAID:
			return <TotalPaidPreviewField {...props} />;

		case FIELDS_ACCESSORS.OPTIONS:
			return <OptionsPreviewField {...props} />;

		case FIELDS_ACCESSORS.PHONE_NUMBER:
			return <PhonePreviewField {...props} />;

		case FIELDS_ACCESSORS.WORKING_HOURS:
			return <WorkingHoursPreviewField {...props} />;

		case FIELDS_ACCESSORS.DATE:
			return <DateField {...props} />;

		case FIELDS_ACCESSORS.URGENT_REQUEST_STATUS:
			return <UrgentRequestStatusField {...props} />;

		case FIELDS_ACCESSORS.LINK:
			return <LinkPreviewField {...props} />;

		case FIELDS_ACCESSORS.NEW_DROP_DATE:
			return <NewDropDatePreviewField {...props} />;

		case FIELDS_ACCESSORS.TOTAL_EXTENSION_AMOUNT:
			return <TotalExtensionAmountPreviewField {...props} />;

		case FIELDS_ACCESSORS.PICK_UP_ADDRESS:
			return <PickUpAddressPreviewField {...props} />;

		default:
			return <DefaultPreviewField {...props} />;
	}
};

PreviewFieldFactory.propTypes = {
	accessor: string,
};
