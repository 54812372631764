import { makeStyles } from '@material-ui/styles';

/// Import helpers
import { BOOKING_CANCELLATION_STATES } from 'helpers';

const { APPROVED, REJECTED, REVIEW } = BOOKING_CANCELLATION_STATES;

export const useStyles = makeStyles((theme) => ({
	chip: {
		borderRadius: '100px',
		textTransform: 'uppercase',
		fontWeight: 600,
		fontSize: 12,
		padding: theme.spacing(1, 2),

		'& .MuiChip-label': {
			padding: 0,
		},
	},
	[REVIEW]: {
		backgroundColor: theme.palette.warning.medium,
		color: theme.palette.warning.mid,
	},
	[APPROVED]: {
		backgroundColor: theme.palette.success.lighter,
		color: theme.palette.success.medium,
	},
	[REJECTED]: {
		backgroundColor: theme.palette.error.lighter,
		color: theme.palette.error.medium,
	},
}));
