import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
	container: {
		marginBottom: theme.spacing(4),
	},
	imageWrapper: {
		width: '114px',
		height: '72px',
		borderRadius: '8px',
		backgroundColor: theme.palette.background[6],
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		overflow: 'hidden',
	},
	image: {
		width: '66px',
	},
	carName: {
		fontSize: 14,
		fontWeight: 600,
		marginBottom: theme.spacing(1),
		color: theme.palette.text[2],
	},
	carModel: {
		fontSize: 16,
		fontWeight: 600,
	},
	availabilityBox: {
		width: '114px',
		height: '72px',
		borderRadius: '8px',
		padding: theme.spacing(2),
		backgroundColor: theme.palette.primary.medium,
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
	},
	availability: {
		color: theme.palette.primary.main,
		marginBottom: theme.spacing(1),
		fontWeight: 600,
		fontSize: 20,
		textAlign: 'center',
	},
	totalAvailability: {
		fontSize: 12,
		fontWeight: 400,
		textAlign: 'center',
	},
}));
