import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Import store
import { previewBookingSelector } from 'store/selectors';
import { submitCustomerRatingForm } from 'store/actions';

// Import utils
import { useMessage, useTranslations } from 'components/utilities';
import { getInitialValues } from './helpers';

export const useCustomerRatingForm = () => {
	const booking = useSelector(previewBookingSelector);
	const [isLoading, setLoading] = useState(false);
	const { message } = useMessage();
	const { t } = useTranslations();
	const dispatch = useDispatch();

	const { uuid: bookingUuid } = booking || {};
	const initialValues = getInitialValues(booking?.ratings?.customer);

	const onSubmit = async (values) => {
		let apiErrors = {};

		setLoading(true);

		await submitCustomerRatingForm({
			bookingUuid,
			dispatch,
			values,
			callbackSuccess: () => {
				message.success(t('common.messages.successfully_done'));
				setLoading(false);
			},
			errorCallback: (error) => {
				setLoading(false);
				message.error(t('common.messages.error_message'));
				apiErrors = error;
			},
		});

		return apiErrors;
	};

	return {
		initialValues,
		isLoading,
		onSubmit,
	};
};
