import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field, useFormState } from 'react-final-form';

// Import components
import {
	SelectFieldAdapter,
	SelectFieldWithSearchAdapter,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';

// Import queries
import { useOfficesAssignedToUser } from 'queries';

// Import helpers
import { sortByName } from '../helpers';

export const OfficeFilter = ({
	label,
	name,
	xs = 3,
	md = 3,
	smallFieldStyle = false,
	withSearch = false,
	showEmptyLabel = false,
	disabled = false,
}) => {
	const { submitting } = useFormState();

	const {
		t,
		i18n: { language },
	} = useTranslations();

	const { data } = useOfficesAssignedToUser();

	if (!data) {
		return null;
	}

	const sortedOffices = sortByName(data);

	return (
		<Grid item md={md} xs={xs}>
			<Field
				fullWidth
				component={
					withSearch ? SelectFieldWithSearchAdapter : SelectFieldAdapter
				}
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={disabled || submitting}
				smallfieldstyle={smallFieldStyle && smallFieldStyle.toString()}
				emptyLabel={showEmptyLabel ? t('common.fields.office') : ''}
			>
				<MenuItem value="all">{t('common.all')}</MenuItem>
				{sortedOffices.map(({ uuid, name }) => (
					<MenuItem key={uuid} value={uuid}>
						{name[language]}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

OfficeFilter.propTypes = {
	label: string.isRequired,
	name: string.isRequired,
	xs: oneOfType([number, string]),
	md: oneOfType([number, string]),
	smallFieldStyle: bool,
	withSearch: bool,
	showEmptyLabel: bool,
	disabled: bool,
};
