import { useFormState } from 'react-final-form';

// Import helpers and utils
import { FIELD_TYPES } from 'helpers';
import { useTranslations } from 'components/utilities';

// Import queries
import {
	useCompanies,
	useCompanyOffices,
	useManufacturers,
	useModelYears,
	useVehicleModelList,
} from 'queries';

export const useCarCatalog = () => {
	const {
		i18n: { language: lang },
	} = useTranslations();

	const { values } = useFormState();

	const { company, office, maker, model } = values || {};

	const { data: companies, isFetching: isCompaniesFetching } =
		useCompanies(true);
	const { data: offices, isFetching: isOfficesFetching } =
		useCompanyOffices(company);
	const { data: manufacturers, isFetching: isManufacturesFetching } =
		useManufacturers();
	const { data: models, isFetching: isModelsFetching } = useVehicleModelList({
		manufacturerUuid: maker,
	});
	const { data: years, isFetching: isYearsFetching } = useModelYears({
		modelUuid: model,
	});

	const FIELDS = [
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.company',
			name: 'company',
			options: companies,
			isLoading: isCompaniesFetching,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			md: 6,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.office',
			name: 'office',
			options: offices,
			isLoading: isOfficesFetching,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			md: 6,
			disabled: !company,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.manufacturer',
			name: 'maker',
			isLoading: isManufacturesFetching,
			options: manufacturers,
			keyExtractor: ({ uuid }) => uuid,
			labelExtractor: ({ name }) => name[lang],
			md: 4,
			isHidden: !office,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.model',
			name: 'model',
			isLoading: isModelsFetching,
			options: models,
			keyExtractor: ({ model_uuid }) => model_uuid,
			labelExtractor: ({ model_name }) => model_name[lang],
			md: 4,
			isHidden: !office,
			disabled: !maker,
		},
		{
			type: FIELD_TYPES.SELECT,
			label: 'common.fields.year',
			name: 'year',
			isLoading: isYearsFetching,
			options: years,
			keyExtractor: (year) => year,
			labelExtractor: (year) => year,
			md: 4,
			isHidden: !office,
			disabled: !model,
		},
	];

	return { FIELDS };
};
