import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
	divider: {
		margin: theme.spacing(4, 0),
	},
	dialogTitle: {
		'& *': {
			display: 'flex',
			alignItems: 'center',
			gap: theme.spacing(2),
		},
	},
	subtitle: {
		color: theme.palette.text[1],
		marginBottom: theme.spacing(2),
	},
	buttonsContainer: {
		padding: '48px 0 16px 0',
	},
}));
