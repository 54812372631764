import React from 'react';
import { bool, number, oneOfType, string } from 'prop-types';
import { Grid, MenuItem } from '@material-ui/core';
import { Field } from 'react-final-form';

// Import components
import {
	SelectFieldAdapter,
	SelectFieldWithSearchAdapter,
} from 'components/elements';

// Import utilities
import { useTranslations } from 'components/utilities';
import { useBookingStatusFilter } from './useBookingStatusFilter';

export const BookingStatusFilter = ({
	name,
	label,
	xs,
	md,
	withSearch = false,
	smallFieldStyle = false,
}) => {
	const { t } = useTranslations();

	const { submitting, bookingStates } = useBookingStatusFilter();

	const options = ['all', ...bookingStates];

	if (!bookingStates) {
		return null;
	}

	return (
		<Grid item md={md} xs={xs}>
			<Field
				component={
					withSearch ? SelectFieldWithSearchAdapter : SelectFieldAdapter
				}
				fullWidth
				label={t(label)}
				name={name}
				variant="outlined"
				margin="dense"
				disabled={submitting}
				smallfieldstyle={smallFieldStyle}
			>
				{options.map((state) => (
					<MenuItem key={state} value={state}>
						{state === 'all'
							? t('common.all')
							: t(`bookings.booking_states.${state}`).toUpperCase()}
					</MenuItem>
				))}
			</Field>
		</Grid>
	);
};

BookingStatusFilter.propTypes = {
	name: string,
	label: string,
	xs: oneOfType([number, string]),
	md: oneOfType([number, string]),
	withSearch: bool,
	smallFieldStyle: oneOfType([bool, string]),
};
