import { useSelector } from 'react-redux';
import { format } from 'date-fns';

// Import utils and helpers
import { useTranslations } from 'components/utilities';
import { YMD_SLASH_TIME_FORMAT } from 'helpers';

// Import store
import { previewBookingSelector } from 'store/selectors';

export const useRejectReason = () => {
	const {
		i18n: { language: lang },
	} = useTranslations();
	const booking = useSelector(previewBookingSelector);

	const { cancel_reason: cancelReason } = booking || {};

	const {
		name,
		resolved_by: resolvedBy,
		resolved_at: resolvedAt,
		old_name: oldName,
	} = cancelReason || {};

	const cancelReasonName = name[lang];

	const oldCancellationReason = oldName?.[lang];

	const validatedDate =
		resolvedAt && format(new Date(resolvedAt), YMD_SLASH_TIME_FORMAT);

	return {
		cancelReasonName,
		oldCancellationReason,
		validatedByName: resolvedBy,
		validatedDate,
	};
};
