import { createSelector } from 'reselect';

export const createHasAnyRoleSelector = (allowedRoles) =>
	createSelector(
		(state) =>
			state.auth.authenticate ? state.auth.authenticate.data.role : null,
		(roles) =>
			roles ? roles.some((role) => allowedRoles.includes(role)) : false
	);

export const userRolesSelector = (state) => state.auth.authenticate.data.role;
export const roleSelector = (state) => state.auth.authenticate?.data?.role;
export const profileDataSelector = (state) => state.auth.authenticate.data;
export const isCustomerWalletManagerSelector = (state) =>
	state.auth.authenticate?.data?.customer_wallet?.is_manager;
export const tokenSelector = (state) =>
	state.auth.authenticate?.data?.meta?.access_token;

export const isForcePasswordReset = (state) =>
	state.auth.authenticate?.data?.force_password_reset;

export const isAuthenticatedSelector = (state) => state.auth.isAuthenticated;

export const isAdminSelector = (state) =>
	state.auth.authenticate.data.role.includes('admin');
