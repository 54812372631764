import React from 'react';
import { string } from 'prop-types';
import { Typography, Link } from '@material-ui/core';

// Import assets
import { LocationArrowIcon } from 'assets/icons';

// Import components
import { PreviewField } from 'components/elements';

// Import styles
import { useStyles } from './AddressLinkPreviewField.styles';

export const AddressLinkPreviewField = ({ address, link, label }) => {
	const classes = useStyles();

	return (
		<PreviewField label={label}>
			<Link href={link} target="_blank" rel="noopener">
				<Typography
					className={classes.linkText}
					variant="body1"
					color="primary"
				>
					{address && <LocationArrowIcon />}
					{address}
				</Typography>
			</Link>
		</PreviewField>
	);
};

AddressLinkPreviewField.propTypes = {
	address: string.isRequired,
	link: string.isRequired,
	label: string.isRequired,
};
