/* eslint-disable react/prop-types */
/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import {
	List,
	ListItem,
	Button,
	Collapse,
	Box,
	Grid,
	Tooltip,
} from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { array, bool, func } from 'prop-types';
import clsx from 'clsx';

// Import icons
import ArrowRightIcon from '@material-ui/icons/NavigateNext';
import ArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import ArrowLeftIcon from '@material-ui/icons/NavigateBefore';

// Import utilities and helpers
import { useTranslations } from 'components/utilities';
import { AR } from 'helpers';
import useSidebarNav from './useSidebarNav';

const CustomRouterLink = forwardRef((props, ref) => (
	<Box ref={ref} style={{ flexGrow: 1 }}>
		<RouterLink {...props} />
	</Box>
));

const SidebarNav = ({ pages, isOpen, toggleDrawer, ...rest }) => {
	const {
		t,
		i18n: { language },
	} = useTranslations();
	const isRTL = language === AR;

	const {
		collapsedHeadListItemClasses,
		collapsedHeadButtonClasses,
		listItemButtonClasses,
		clearDropdownTabs,
		handleClick,
		isNestedItem,
		titleClass,
		hasAccess,
		classes,
		navigationDropdownTabs,
		isItemWithoutCollapsed,
	} = useSidebarNav({ pages, toggleDrawer, isOpen });

	const renderListItem = ({ title, icon, href, chip, collapsed, bold }) => (
		<Tooltip title={!isOpen ? t(title) : ''} key={title}>
			<ListItem
				className={clsx(classes.item, titleClass(title))}
				disableGutters
			>
				{!collapsed ? (
					<Button
						onClick={() => clearDropdownTabs(title)}
						activeClassName={clsx(classes.active, bold && classes.boldText)}
						component={CustomRouterLink}
						className={clsx(
							bold && classes.boldText,
							listItemButtonClasses(icon),
							isNestedItem(title) && classes.nestedItem,
							isItemWithoutCollapsed(title) && classes.horizontalBar
						)}
						to={href}
					>
						{icon && <Box className={classes.icon}>{icon}</Box>}

						{isOpen && t(title)}

						{isOpen && chip && <Box pl={1}>{chip}</Box>}
					</Button>
				) : (
					<List disablePadding className={clsx(classes.list)}>
						{renderCollapsedList({
							title,
							icon,
							href,
							chip,
							collapsed,
							bold,
						})}
					</List>
				)}
			</ListItem>
		</Tooltip>
	);

	const renderCollapsedList = ({ title, icon, collapsed, chip, bold }) => {
		const isCollapsed = navigationDropdownTabs.includes(title);

		return (
			<ListItem
				disableGutters
				className={clsx(collapsedHeadListItemClasses(title))}
			>
				<Grid container direction="column">
					<Grid item xs={12}>
						<Button
							className={clsx(
								collapsedHeadButtonClasses(title),
								titleClass(title),
								bold && classes.boldText
							)}
							onClick={handleClick(title)}
						>
							<Box className={classes.icon}>{icon}</Box>
							{isOpen && t(title)}
							{isOpen && chip && <Box pl={1}>{chip}</Box>}
							<Box component="span" className={classes.spacer} />
							{isOpen && (
								<>
									{isCollapsed ? (
										<ArrowDownIcon />
									) : isRTL ? (
										<ArrowLeftIcon />
									) : (
										<ArrowRightIcon />
									)}
								</>
							)}
						</Button>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={isCollapsed} timeout="auto" unmountOnExit>
							<List className={classes.collapsedList} disablePadding {...rest}>
								{collapsed.map(
									({ role, ...rest }) =>
										hasAccess(role) &&
										renderListItem({
											...rest,
										})
								)}
							</List>
						</Collapse>
					</Grid>
				</Grid>
			</ListItem>
		);
	};

	return (
		<PerfectScrollbar>
			<List {...rest} className={classes.root}>
				{pages.map(
					({ role, title, icon, href, chip, collapsed }) =>
						hasAccess(role) &&
						renderListItem({ title, icon, href, chip, collapsed })
				)}
			</List>
		</PerfectScrollbar>
	);
};

SidebarNav.propTypes = {
	pages: array.isRequired,
	isOpen: bool.isRequired,
	toggleDrawer: func.isRequired,
};

export default SidebarNav;
