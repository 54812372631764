import React from 'react';
import { SvgIcon } from '@material-ui/core';

export const TwoUsersIcon = (props) => (
	<SvgIcon width="16" height="16" viewBox="0 0 16 16" fill="none" {...props}>
		<path
			d="M10 12.6667V12.3333C10 11.0447 8.95533 10 7.66667 10H4.33333C3.04467 10 2 11.0447 2 12.3333V12.6667"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<ellipse
			cx="5.9974"
			cy="5.00033"
			rx="2.33333"
			ry="2.33333"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M14.6667 12.6667V12.3333C14.6667 11.0447 13.622 10 12.3333 10H12"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
		<path
			d="M10.6641 2.66699C11.9527 2.66699 12.9974 3.71166 12.9974 5.00033C12.9974 6.28899 11.9527 7.33366 10.6641 7.33366"
			stroke="currentColor"
			fill="none"
			strokeWidth="1.5"
			strokeLinecap="round"
			strokeLinejoin="round"
		/>
	</SvgIcon>
);
