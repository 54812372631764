export const getAdditionalInfoFields = ({
	showAssignedDriverAdditional,
	showDeliveryStatusAdditional,
}) => {
	const sortedFields = [
		{
			label: 'bookings.table.add_columns_dialog.customer_details',
			innerFields: [
				'customer_nationality',
				'gender',
				'license_number',
				'customer_email',
				'customer_no_booking',
				'office_city',
			],
		},
		{
			label: 'bookings.table.add_columns_dialog.car_and_company',
			innerFields: ['company_name', 'vehicle'],
		},
		{
			label: 'bookings.table.add_columns_dialog.booking_details',
			innerFields: [
				'respond_time',
				'drop_date',
				'updated_at',
				'response_time',
				'pick_location',
				'drop_location',
				'payment_method',
				'total_base_nett_price',
				'total_discounted_gross_price',
				'approved',
				'admin_comment',
				'office_rating',
				'rating_reason',
				'compensated',
				'settlement_status',
			],
		},
	];

	showAssignedDriverAdditional &&
		sortedFields[2].innerFields.push('assigned_driver');

	showDeliveryStatusAdditional &&
		sortedFields[2].innerFields.push('delivery_status');

	return sortedFields;
};
